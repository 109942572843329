// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Axios from 'axios';

const PrivateRoute = ({ component: Component, ...rest }) => {

/* getting params from url */
var pathArray = window.location.pathname.split('/');
if(pathArray[1] === 'fna'){localStorage.setItem('eventId',556);} 
if(pathArray[1] === 'msc'){localStorage.setItem('eventId',524);} 
if(pathArray[1] === 'csda'){localStorage.setItem('eventId',605);} 
if(pathArray[1] === 'vcd'){localStorage.setItem('eventId',590);} 
if(pathArray[1] === 'goma'){localStorage.setItem('eventId',697);} 
if(pathArray[1] === 'fna2024'){localStorage.setItem('eventId',1114);} 
if(pathArray[1] === 'fna2022'){localStorage.setItem('eventId',713);} 
if(pathArray[1] === 'navigate2022'){localStorage.setItem('eventId',846);} 
var redirectPath = '/'+pathArray[1]+'/login/'+localStorage.getItem('eventId');


/* code when token expired so logout the user */
var eventId = 0;
var emailId = '';
if(localStorage.getItem('exhibitorToken')){
    const token = localStorage.exhibitorToken;
    const decoded = jwt_decode(token);
    if (Date.now() >= decoded.exp * 1000) {
      eventId = localStorage.getItem('eventId');
      emailId = localStorage.getItem('userName');
      localStorage.clear();
      localStorage.setItem('eventId',eventId);
      localStorage.setItem('eventName',pathArray[1]);
      const logoutData = {
        email: emailId,
        eventId:eventId,
       }
      Axios.post(`${global.config.requestUrl}user/logoutUser`, logoutData,
      {headers: { "auth-token": token }}
      ).then(res => {
        
      })
      .catch(err => {
        console.log(err);
      });
    }
}

/* code when path other then defined so logout user */

if(pathArray[1] !== localStorage.getItem('eventName') && pathArray[1] !== 'chat' && pathArray[1] !== 'video-conference' && pathArray[1] !== 'video-call' && pathArray[1] !== 'conference-meeting'  ){
  const token = localStorage.exhibitorToken;
  eventId = localStorage.getItem('eventId');
  emailId = localStorage.getItem('userName');
  localStorage.clear();
  localStorage.setItem('eventId',eventId);
  localStorage.setItem('eventName',pathArray[1]);
  const logoutData = {
    email: emailId,
    eventId: eventId,
  }
  Axios.post(`${global.config.requestUrl}user/logoutUser`, logoutData,
      {headers: { "auth-token": token }}
  ).then(res => {
        
  }).catch(err => {
      console.log(err);
  });

}

  return (
    <Route
      {...rest}
      render={props =>
        localStorage.getItem('exhibitorToken') ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: `${redirectPath}`, state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute;