import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import Axios from "axios";
import { Accordion, Card, Button } from "react-bootstrap";
import MySchedule from "./MySchedule";
import MessageModal from "./modals/MessageModal";
import HelpDeskModal from "./modals/HelpDeskModal";
import YouTubeModal from "./modals/YouTubeModal";
import firebase from "../../Firebase/index";

const Lobby = () => {
  const [sponserImg, setSponserImg] = useState([]);
  const [slideToShowImgCount, setSlideToShowImgCount] = useState(1);
  const { id } = useParams();
  const [myScheduleModal, setMyScheduleModal] = useState(false);
  const [helpDeskModal, setHelpDeskModal] = useState(false);
  const [helpDeskContents, setHelpDeskContents] = useState([]);
  const [messageModal, setMessageModal] = useState(false);
  const [youTubeModal, setYouTubeModal] = useState(false);
  const [youTubeTitle, setYouTubeTitle] = useState(false);
  
  const [youTubeLink, setYouTubeLink] = useState(false);
  const eventId = localStorage.getItem("eventId");
  const [userMessages, setUserMessages] = useState([]);
  const [localAttraction, setLocalAttraction] = useState(null)
  const [allowAsExhibitor, setAllowAsExhibitor] = useState(false);
  const [allowAsSpeaker, setAllowAsSpeaker] = useState(false);
  const [allowAttendee, setAllowAttendee] = useState(false);


  //console.log(id)

  useEffect(() => {
    getSliderData();
    getLocalAttraction();
    var rolesArr = localStorage.getItem("currentRole").split(",");
    if (rolesArr.indexOf("-1") !== -1) {
      setAllowAsExhibitor(true);
    }
    if (rolesArr.indexOf("-6") !== -1) {
      setAllowAsSpeaker(true);
    }
    if (rolesArr.indexOf("111") !== -1) {
      setAllowAttendee(true);
    }
  }, []);

  useEffect(() => {
    if (helpDeskModal) getHelpDeskData();
    if (messageModal) getEventMessages();
    // return () => {
    //   setHelpDeskContents([])
    // }
  }, [helpDeskModal, messageModal]);

  
  useEffect(() => {
    if (/iPhone|iPad|Mac|Macintosh|iPod/i.test(navigator.userAgent)) {
      console.log("yes apple");
    } else {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        const messaging = firebase.messaging();
        messaging
          .getToken()
          .then((token) => {
            saveFirebasePushNotificationToken(token);
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            const messaging = firebase.messaging();
            messaging
              .getToken()
              .then((token) => {
                const tokData = {
                  token: token,
                  email: localStorage.getItem("username"),
                };

                Axios.post(
                  `${global.config.requestUrl}saveFirebaseToken`,
                  tokData,
                  {
                    headers: {
                      "auth-token": localStorage.getItem("exhibitorToken"),
                    },
                  }
                )
                  .then((res) => {})
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        });
      }
    }
  }, []);
  const saveFirebasePushNotificationToken = (token) => {
    const tokData = {
      token: token,
      email: localStorage.getItem("username"),
    };

    Axios.post(`${global.config.requestUrl}saveFirebaseToken`, tokData, {
      headers: { "auth-token": localStorage.getItem("exhibitorToken") },
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  //Get slider images data
  const getSliderData = () => {
    Axios.get(
      `${global.config.requestUrl}exhibitor?eventId=${eventId}&type=3`
    ).then((res) => {
      if (res.data.errorCode === 0) {
        setSponserImg(res.data.data);
        if (res.data.data.length > 1) setSlideToShowImgCount(2);
      } else {
        setSponserImg([
          {
            logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
            exhibitor_id: null,
          },
        ]);
      }
    });
  };

  //Get Information data
  const getHelpDeskData = () => {
    Axios.get(
      `${global.config.requestUrl}user/getHelpDeskContent?eventId=${eventId}`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {
        if (!res.data.errorCode) setHelpDeskContents(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
 //Get Event Messages data
  const getEventMessages = () => {
    Axios.get(
      `${global.config.requestUrl}user/getEventMessages?eventId=${eventId}`,
      {
        headers: { "auth-token": localStorage.exhibitorToken },
      }
    )
      .then((res) => {
        if (res.data.errorCode === 0) setUserMessages(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getLocalAttraction = () => {
    Axios.get(
      `${global.config.requestUrl}getLocalAttraction?eventId=${eventId}`,
      {
        headers: { "auth-token": localStorage.exhibitorToken },
      }
    )
      .then((res) => {
        if (res.data.errorCode === 0) setLocalAttraction(res.data.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Handle My Schedule modal
  const myScheduleModalHandler = (e) => {
    e.preventDefault();
    setMyScheduleModal(!myScheduleModal);
  };

  //Handle Information modal
  const helpDeskModalHandler = (e) => {
    e.preventDefault();
    setHelpDeskModal(!helpDeskModal);
  };

  //Handle Event Messages Modal
  const messageModalHandler = (e) => {
    e.preventDefault();
    setMessageModal(!messageModal);
  };

   //Handle Event Messages Modal
   const youTubeModalHandler = (e,linkUrl,title) => {
    e.preventDefault();
    setYouTubeLink(linkUrl)
    setYouTubeTitle(title);
    setYouTubeModal(!youTubeModal);
    
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slideToShowImgCount,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    vertical: false,
  };
  return (
    <React.Fragment>
      <div className="p-3">
        {/* <div className="card py-2 px-3 border-none box-shadow mt-3">
          <div className="scroller">
            <Slider {...settings}>
              {sponserImg.map((sponsor, index) => (
                <div className="card" key={index}>
                  <img src={sponsor.logo} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </div> */}
        <div className="card p-3 border-none box-shadow">
        <div className="twoSideList mt-3">
          <Link to="/fna2024/conference" className="customBtn1 fna24 mt-1 halfBtn">
            Conference Schedule
          </Link>
          <Link to="/fna2024/sponsors" className="customBtn1 fna24 mt-1 halfBtn">
            Sponsors
          </Link>
          {/* <Link to="/fna2022/speakers" className="customBtn mt-1 halfBtn">
            Speakers
          </Link> */}
          <Link
            // to="/fna2024/attendee/exhibitHall"
            to={{ pathname:"https://homebase.map-dynamics.com/mtic2024/floorplan" }}
            target="_blank"
            className="customBtn1 mt-1 halfBtn"
          >
            Exhibit Floor Plan
          </Link>
          <Link 
           to={{ pathname:"https://mobile.map-dynamics.com/exhibitors.php?Show_ID=7830" }} 
           target="_blank"
           className="customBtn1 fna24 mt-1 halfBtn">
            Exhibitor list
          </Link>
          <Link to="/fna2024/attendees" className="customBtn1 mt-1 halfBtn">
            Attendees
          </Link>
          <Link
              to={
                allowAsExhibitor
                  ? "exhibitor/profile"
                  : allowAsSpeaker
                  ? "speaker/profile"
                  : "/fna2024/user/profile"
              }
              className="customBtn1 fna24 mt-1 halfBtn"
            >
              Profile
            </Link>
          <a rel="noopener noreferrer" className="customBtn1 fna24 mt-1 halfBtn" href={localAttraction ? localAttraction?.url : '/fna2024/lobby'} target="_blank">Local Attractions</a>
          {/* <Link to={localAttraction ? localAttraction?.url : '/fna2022/lobby'} className="customBtn mt-1 halfBtn">
            Local Attractions
          </Link> */}

          <Link to="/fna2024/maps" className="customBtn1 fna24 mt-1 halfBtn">
            Maps
          </Link>
          <Link
            to="/"
            onClick={helpDeskModalHandler}
            className="customBtn1 fna24 mt-1 halfBtn"
          >
            Information
          </Link>
          <Link
            to="/"
            onClick={myScheduleModalHandler}
            className="customBtn1 fna24 mt-1 halfBtn"
          >
            My Schedule
          </Link>
          <Link
           to={{ pathname:"https://www.heattreatcareers.com/jobs-of-tomorrow" }} 
           target="_blank"
           className="customBtn1 fna24 mt-1 halfBtn">
            Jobs of Tomorrow
          </Link>
          <Link 
           to={{ pathname:"https://heattreat.careerwebsite.com/" }} 
           target="_blank"
           className="customBtn1 fna24 mt-1 halfBtn">
            MTI NEW Career Site
          </Link>

          <Link 
             to="/"
             onClick={(event)=>youTubeModalHandler(event,"https://www.youtube.com/embed/bRytI6O3Wus?si=EbsRZxcDNfUnyMEZ?rel=0&autoplay=1","Why Join MTI")}
             className="customBtn1 fna24 mt-1 halfBtn">
            Why Join MTI
          </Link>
          
          <Link 
            to="/"
            onClick={(event)=>youTubeModalHandler(event,"https://www.youtube.com/embed/le7YfPAFE_I?si=9kZBI6gP5RdrTebT?rel=0&autoplay=1","Why MTI Online Training")}
            className="customBtn1 fna24 mt-1 halfBtn">
            Why MTI Online Training
          </Link>

          <Link
            to="/"
            onClick={messageModalHandler}
            className="customBtn1 fna24 mt-2 halfBtn"
          >
            Event Messages
          </Link>
        </div>
        </div>
        {myScheduleModal ? (
          <div className="midModal">
            {/* <div className="modalOverlay" onClick={myScheduleModalHandler}></div> */}
            <div className="midContentWrp">
              <div
                className="modalPopUpClose"
                id="myschedulepopup"
                onClick={myScheduleModalHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <MySchedule />
            </div>
          </div>
        ) : null}
        {helpDeskModal ? (
          <HelpDeskModal
            closeModal={helpDeskModalHandler}
            helpDeskContents={helpDeskContents}
          />
        ) : null}
        {messageModal ? (
          <MessageModal
            closeModal={messageModalHandler}
            userMessages={userMessages}
          />
        ) : null}
         {youTubeModal && youTubeLink && youTubeTitle ? (
          <YouTubeModal
            closeModal={youTubeModalHandler}
            youTubeTitle={youTubeTitle}
            youTubeLink={youTubeLink}
          />
        ) : null}
         <div className="border-none mt-3">
          <div className="twoSideList">
            {sponserImg.map((sponsor, index) => (
              <div className="card1" key={index}>
                {sponsor.link ? (
                  <a href={sponsor.link} target="_blank">
                    <img src={sponsor.logo} alt="" />
                  </a>
                ) : (
                  <img src={sponsor.logo} alt="" />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Lobby;
