import React, { Component } from 'react';
/* import {Link} from 'react-router-dom' */
import jwt_decode from 'jwt-decode'
import LeftNavBar from './LeftNavBar'
import ChatIcon from '../../../TmChat/paos/chat/ChatIcon'
import ExhibitorChat from '../../../TmChat/paos/exhibitorChat/exhibitorChat'
import Axios from 'axios';
import {Link} from "react-router-dom";
import { CSVLink } from "react-csv";

const csvVisitorsHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "credentials", key: "credentials" },
    { label: "Title", key: "title" },
    { label: "Company", key: "company" },
    { label: "Add1", key: "address1" },
    { label: "Add2", key: "address2" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Postal Code", key: "zip" },
    { label: "Phone", key: "phone" },
    { label: "Country", key: "country" },
    { label: "Visit Date & Time", key: "created_date" }
  ];
  const csvLeadsHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "credentials", key: "credentials" },
    { label: "Title", key: "title" },
    { label: "Company", key: "company" },
    { label: "Add1", key: "address1" },
    { label: "Add2", key: "address2" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Postal Code", key: "zip" },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Country", key: "country" },
    { label: "Lead Date & Time", key: "created_date" }
  ];

  const csvFollowUpsHeaders = [
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "credentials", key: "credentials" },
    { label: "Title", key: "title" },
    { label: "Company", key: "company" },
    { label: "Add1", key: "address1" },
    { label: "Add2", key: "address2" },
    { label: "City", key: "city" },
    { label: "State", key: "state" },
    { label: "Postal Code", key: "zip" },
    { label: "Phone", key: "phone" },
    { label: "Email", key: "email" },
    { label: "Country", key: "country" },
    { label: "Followup", key: "followUp" }
  ];
 
// const csvData = [
//     { name: "Ahmed", company: "Tomi", address: "ah@smthing.co.com" },
//     { name: "Raed", company: "Labes", address: "rl@smthing.co.com" },
//     { name: "Yezzi", company: "Min l3b", address: "ymin@cocococo.com" }
//   ];

class Dashboard extends Component {
    constructor(){
        super();
        

        this.state={
            visitorsTable:true,
            leadsTable:false,
            totalTable:false,
            tableListDetailsModal:false,
            email : "",
            visitorList:[],
            visitorCount:0,
            leadCount:0,
            csvData:[],
            followUpCsvData:[],
            followUpHeaders:csvFollowUpsHeaders,
            csvHeaders:csvVisitorsHeaders,
            leadAndVisitorCount:0,
            showChatIcon:false,
            csvfilename:'visitors.csv',
            sectionLoader:true,
            pageLoader:true,
            videoTutorialModal:false,
        }
        
        
    }


    
    videoTutorialModalHandeler = () => {
        this.setState({videoTutorialModal:!this.state.videoTutorialModal})
    }
    visitorsTableHandler = () => {
        this.setState({
            visitorsTable:true,
            leadsTable:false,
            totalTable:false,
            visitorList:[],
            csvfilename:'visitors.csv',
            csvHeaders:csvVisitorsHeaders,
            sectionLoader:true,
        })
        this.CountAnalytics();
        this.ListAnalytics(0,3); /* 0 = visitors, 1 = for grid visibility */
    }
    leadsTableHandler = () => {
        this.setState({
            visitorsTable:false,
            leadsTable:true,
            totalTable:false,
            visitorList:[],
            csvfilename:'leads.csv',
            csvHeaders:csvLeadsHeaders,
            sectionLoader:true,
        })
        this.CountAnalytics();
        this.ListAnalytics(1,3); /* 1 = leads, 1 = for grid visibility */

    }
    // totalTableHandler = () => {
    //     this.setState({
    //         visitorsTable:false,
    //         leadsTable:false,
    //         totalTable:true,
    //         visitorList:[],
    //         csvfilename:'visitors_and_leads.csv',
    //         sectionLoader:true,
    //     })
    //     this.ListAnalytics(2);
    // }
    
    
    tableListDetailsModalHandler = () => {
        this.setState({tableListDetailsModal:!this.state.tableListDetailsModal})
    }
    refreshPage = () =>{
        window.location.reload(false);
    }
    componentDidMount(){
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1){
                this.props.history.push('/notfound');
                return false;
            }
        }
        this.updateDashboardDom();
        
    }

    updateDashboardDom = () => {
        this.setState({sectionLoader:true});
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = '';
            if(rolesArr.indexOf('-7') !== -1){
                currentRole = -1
            }
            else if(rolesArr.indexOf('-1') !== -1){
                currentRole = -1
            }
            else if(rolesArr.indexOf('-6') !== -1){
                currentRole = -6
            }
            else{
                currentRole = -1
            }
             
        }

        const token = localStorage.exhibitorToken;
        const decoded = jwt_decode(token);
        const details = {
            username: decoded.email,
            eventId:localStorage.getItem('eventId'),
            role:currentRole.toString()
        }

        Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            .then(res => {
                //console.log(res.data.data[0],'ddddd');
                 if(res.data.errorCode === 0){
                    localStorage.setItem('companyContactUrl',res.data.data[0].profilePic)
                    localStorage.setItem('useraccname',res.data.data[0].name)
                    localStorage.setItem('userId',res.data.data[0].exhibitorId)
                    if(res.data.data[0].linkId){
                        localStorage.setItem('userId',res.data.data[0].exhibitorId)
                        localStorage.setItem('exhibitorId',res.data.data[0].linkId)
                    }
                    
                    localStorage.setItem('company',res.data.data[0].company)
                    localStorage.setItem('currentRole',currentRole)
                    if(res.data.data[0].profilePic !==""){
                        document.getElementById("profile_img").src = res.data.data[0].profilePic;
                    }else{
                        document.getElementById("profile_img").src = '/images/placeholder-profile.jpg';;  
                    }
                    document.getElementById("left_bar_profile_img").src = res.data.data[0].profilePic;
                    document.getElementById("useraccname").innerHTML = res.data.data[0].name;
                    this.CountAnalytics();
                    this.ListAnalytics(0,3); /* 0 = visitors, 1 = for grid visibility */
                    this.FollowUpList();
                    
                    
                }else{
                    
                }
                this.setState({pageLoader:false,showChatIcon:true});
                return true;
                
            })
            .catch(err => {
                console.log(err);
            });
    }

    FollowUpList = () => {
        var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
        Axios.get(`${global.config.requestUrl}user/getFollowUpVisitors?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            if(res.data.errorCode === 0 ){
                this.setState({followUpCsvData:res.data.data})
            }
         
        })
        .catch(err => {
            console.log(err);
        });
    }

    CountAnalytics = () => {
        var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
        Axios.get(`${global.config.requestUrl}user/getBoothVisitorsCount?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
        this.setState({visitorCount:res.data.data[0].visitor_count,leadCount:res.data.data[0].lead_count,leadAndVisitorCount:res.data.data[0].total})  
        })
        .catch(err => {
            console.log(err);
        });
    }

    ListAnalytics = (isLead,visibility) => {
        var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
        
        Axios.get(`${global.config.requestUrl}user/getBoothVisitors?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}&isLead=${isLead}&visibility=${visibility}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
        if(res.data.errorCode === 0 ){
            this.setState({visitorList:res.data.data,sectionLoader:false,csvData:res.data.data})
        }else{
            this.setState({sectionLoader:false,csvData:[]})
        }
        })
        .catch(err => {
            console.log(err);
        });
    }
    
    render() {
        return (
            <React.Fragment>
                <section className="bg8 signleScreen stickyDash height1100">
                    <div className="dashboardWrapper">
                        <LeftNavBar />
                        <div className="rightContentAera d-flex">
                        <div className="dashboardDataTable">
                            {/* <div className="mainDashboardHeading">
                                <h3>Main Dashboard</h3>
                                <h4>Company Name: { localStorage.getItem('company')}</h4>
                            </div> */}
                            <div className="mainDashboardLeadsData">
                                <ul>
                                    <li>
                                        <div className="dashboardCard visitorCard" onClick={this.visitorsTableHandler}>
                                            <div className="dashboardCardIco">
                                                <img src={process.env.PUBLIC_URL + '/images/dashboard-visitor.png'} alt="" />
                                            </div>
                                            <div className="dashboardCardContent">
                                                <h2>{this.state.visitorCount} <i class="fa fa-refresh" aria-hidden="true"></i></h2>
                                                <h5>Visitors</h5>
                                            </div>
                                            <div className="hoverContent">
                                                Click here to display the list of attendees who have visited your booth. Info will include name, company, city, state.
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dashboardCard leadsCard"  onClick={this.leadsTableHandler}>
                                            <div className="dashboardCardIco">
                                                <img src={process.env.PUBLIC_URL + '/images/dashboard-leads.png'} alt="" />
                                            </div>
                                            <div className="dashboardCardContent">
                                                <h2>{this.state.leadCount} <i class="fa fa-refresh" aria-hidden="true"></i></h2>
                                                <h5>Leads</h5>
                                            </div>
                                            <div className="hoverContent">
                                                Click here to display the list of attendees who have interacted in the virtual booth by clicking on documents, images, and videos. Info will include complete contact information for each attendee. 
                                            </div>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                            {this.state.visitorsTable 
                                ?
                                (
                                    <div className="mainDashboardTable">
                                                
                                        <h3>Visitors List</h3>
                                        <div className="mainDashboardTableWrp">
                                        
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Company</th>
                                                        {/* <th scope="col">City</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Country</th> */}
                                                    </tr>
                                                </thead>
                                                 <tbody>
                                                 {
                                                     this.state.sectionLoader
                                                    ?
                                                    <React.Fragment>
                                                   
                                                          <tr>
                                                            <td colSpan="5">
                                                                <div className="sectionLoader text-center mt-3 mb-3">
                                                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                                </div>
                                                            </td>
                                                          </tr>
                                                       
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>

                                                    {
                                                      this.state.visitorList.length !== 0
                                                    ?
                                                    (
                                                        this.state.visitorList.map(visitor => (
                                                             <tr key={visitor.company}>
                                                                <td >{visitor.firstName} {visitor.lastName}</td>
                                                                <td>{visitor.company}</td>
                                                                {/* <td>{visitor.city}</td>
                                                                <td>{visitor.state}</td>
                                                                <td>{visitor.country}</td> */}
                                                             </tr>
                                                        ))
                                                    )
                                                    :
                                                    <tr>
                                                     <td colSpan="2">No Data Found</td>
                                                    </tr>
                                                    }
                                                    
                                                    </React.Fragment>
                                                    
                                                 }
                                                    
                                                </tbody> 
                                            </table>
                                        </div>
                                    </div>
                                )
                                :
                                null
                             }
                             {this.state.leadsTable
                                ?
                                (
                                    <div className="mainDashboardTable">
                                        <h3>Leads List</h3>
                                        <div className="mainDashboardTableWrp">
                                            <table className="table">
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">Company</th>
                                                        {/* <th scope="col">City</th>
                                                        <th scope="col">State</th>
                                                        <th scope="col">Country</th> */}
                                                    </tr>
                                                </thead>
                                                 <tbody>
                                                 {
                                                     this.state.sectionLoader
                                                    ?
                                                    <React.Fragment>
                                                   
                                                          <tr>
                                                            <td colSpan="3">
                                                                <div className="sectionLoader text-center mt-3 mb-3">
                                                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                                </div>
                                                            </td>
                                                          </tr>
                                                       
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>

                                                    {
                                                      this.state.visitorList.length !== 0
                                                    ?
                                                    (
                                                        this.state.visitorList.map(visitor => (
                                                             <tr key={visitor.company}>
                                                                <td >{visitor.firstName} {visitor.lastName}</td>
                                                                <td>{visitor.company}</td>
                                                                {/* <td>{visitor.city}</td>
                                                                <td>{visitor.state}</td>
                                                                <td>{visitor.country}</td> */}
                                                             </tr>
                                                        ))
                                                    )
                                                    :
                                                    <tr>
                                                        <td colSpan="2">No Data Found</td>
                                                    </tr>
                                                    }
                                                    
                                                    </React.Fragment>
                                                    
                                                 }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                                :
                                null
                             }
                             <div className="pairBtnWrpd">
                                <div className="pairButton">
                                    <CSVLink data={this.state.csvData} headers={this.state.csvHeaders} filename={this.state.csvfilename} className="btn btn-primary">
                                        <span>Export List</span>
                                        <i className="fa fa-long-arrow-right ml-2"></i>
                                    </CSVLink>
                                    <div className="hoverContent">
                                        Click here to Export your current list to .csv, .txt or Excel.  
                                    </div>
                                </div>

                                <div className="pairButton">
                                    <CSVLink data={this.state.followUpCsvData} headers={this.state.followUpHeaders} filename={"Myfollowups.csv"} className="btn btn-primary btn2">
                                        <span>Export Followups</span>
                                        <i className="fa fa-long-arrow-right ml-2"></i>
                                    </CSVLink>
                                    <div className="hoverContent">
                                        Click here to Export your current Followup list to .csv, .txt or Excel.  
                                    </div>
                                </div>
                             </div>
                             <div className="tutorialVdo">
                                <a href="https://firebasestorage.googleapis.com/v0/b/tslvirtual.appspot.com/o/pdf_doc%2FPAOS%2FVirtual%20Conference%20Exhibitor%20Manual-PAOS.pdf?alt=media&token=7bf1e7b5-d323-40d3-9ffe-ed0f3de26af2" target="_blank">
                                    <span>Tutorial</span>
                                    <i class="fa fa-play"></i>
                                </a>
                             </div>
                             </div>
                            {
                                this.state.showChatIcon
                                    ?<ExhibitorChat/>:''}
                             {/*{
                                 this.state.showChatIcon
                                 ?
                                 <div className="chatIcon">

                                        <ChatIcon/>
                                         <img src={process.env.PUBLIC_URL + '/images/chat-ico.png'} alt="" />

                                 </div>
                                 :
                                 null
                             }*/}
                             
                             {/*Change PassWord Modal*/}
                             
                            {/*Table List Details Modal*/}
                            {
                                this.state.tableListDetailsModal 
                                ?
                                <div className="modalPopUp">
                                    <div className="modalPopUpInr">
                                        <div className="modalPopUpClose" onClick={this.tableListDetailsModalHandler}><i className="fa fa-times"></i></div>
                                        <div className="modalPopUpFormBx">
                                            <div className="modalContent">
                                                <h4>BILL  JONES</h4>
                                                <p>Senior Director<br />
                                                ACME Inc.<br />
                                                12345 1st Ave<br />
                                                Goodyear, AZ 85338<br />
                                                6666666666<br />
                                                bjones@acme.com</p>
                                                <p><b>Contact me about : A500 Laser, B20 Widget</b></p>
                                                <div className="modalFooterColor">
                                                    Call me after the event as we are in the process of building a new facility
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                :
                                null
                            }
                        </div>
                    </div>
                </section>
                {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                {/*=============video-Tutorial-modal==============*/}
                {
                    this.state.videoTutorialModal 
                    ? 
                    <div className="modalPopUp">
                        <div className="modalPopUpInr width1100">
                            <div className="modalPopUpClose" onClick={this.videoTutorialModalHandeler}>
                                <i className="fa fa-times"></i>
                            </div>
                            <div className="modalVideoWrapper">
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/Fan64PkRlWs?rel=0&autoplay=1" frameborder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            
                        </div>
                    </div> 
                    : null
                }
            </React.Fragment>
        )
    }
}
export default Dashboard;