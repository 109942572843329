import React, { Component } from 'react';
import {Link} from 'react-router-dom';
//import Axios from 'axios';

class LinkToSessionRenderer extends Component {

    constructor(props){
        super(props);

        this.state={
            //pageLoader : true,
        }
        
    }

    

    render() {
        const sessName = this.props.valueFormatted ? this.props.valueFormatted : this.props.value;
        const sessId = this.props.data.session_id;
            return (
                <React.Fragment>
               
                    <section >
                    <div className="tooltipWrp">
                    <Link to={`/ihca-als2021/attendee/conferenceCenterDetails/${sessId}`}>{sessName}</Link>
                    <div className="toolTipContent">
                        {sessName}
                    </div>
                    </div>
                                                    
                                
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default LinkToSessionRenderer;
