import React, { Component } from 'react';
class StartTimeRenderer extends Component {

    constructor(props){
        super(props);

        this.state={
        }
    }
    render() {
        
        var startTime = this.props.data.start_time;

        if(startTime === '12:00 AM'){
            startTime = '';
        }
            return (
                <React.Fragment>


               {startTime}
               
                </React.Fragment>
            )
        
        
         
    }
}
export default StartTimeRenderer;
