import React, { Component } from 'react';
import { withRouter, Link} from 'react-router-dom';
const initialState = {
   
    pageLoader:true
}



class LoginSingleSignOn extends Component {
    constructor(props){
        super(props);
        this.state = initialState
    }


  
    componentDidMount(){
        
    }

   


    render() {
            
            return (
                <React.Fragment>
               
                    <section className="bg2 signleScreen overflow-hidden">
                            <div className="loginWrp">
                                <div className="logInForm">
                                    <div className="loginFormBx text-center"> 
                                        <div className="tp_logo">
                                            <img src={process.env.PUBLIC_URL + '/images/powerup2021/powerup2021.png'} alt="" />
                                            <p className="mb-3">October 4-7, 2021</p>
                                        </div>
                                        
                                        <div className="form-group mb-0 text-center">
                                            
                                            <div className="cursor-pointer">
                                            Please use the URL provided by the event manager to login into the conference.
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                    </section>

                </React.Fragment>
            )
         
        
         
    }
}
export default withRouter(LoginSingleSignOn);
