import React, { Component, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import SessionListGrid from "./TechnicalSessions/SessionListGrid";
import ChatIcon from '../../../TmChat/focus2022/chat/ChatIcon'


class TechnicalSessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: true,
    };
  }

  componentDidMount(){
    if(!localStorage.getItem('exhibitorToken')){
      localStorage.setItem('singleSignOn', "0" );
    }
  }
  render() {
    
    return (
      <React.Fragment>
        <section className="sessionScreen signleScreen paddingTop">
          <div
            className="loaderWrp"
            id="fordatatable"
            style={{ display: "none" }}
          >
            <div className="loaderInr">
              <img src={process.env.PUBLIC_URL + "/images/loader.gif"} alt="" />
            </div>
          </div>
          <div className="midTable"  style={{'maxWidth':'1300px', 'width':'100%'}}>
            <div className="midTableWrp">
              <SessionListGrid />
            </div>
          </div>
          <div className="chatIco">
              <ChatIcon chatIconImage={process.env.PUBLIC_URL + '/images/chat-ico2.png'} />
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default TechnicalSessions;
