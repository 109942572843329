import React from "react";

const MessageModal = React.memo(props => {
    return (
        <>
            <div className="modalPopUp">
            <div className="modalPopUpInr p-0">
              <div
                className="modalPopUpClose"
                onClick={props.closeModal}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading">Messages</h3>
              <div className="modalContent p-4">
                <div className="msgListWrp">
                  <ul>
                    {props.userMessages.map((message) => (
                      <li className="msg" key={message.msg_id}>
                        <h4>{message.title}</h4>
                        <p>
                          {message.description
                            .split("\n")
                            .map(function (item, key) {
                              return (
                                <span key={key}>
                                  {item}
                                  <br />
                                </span>
                              );
                            })}
                        </p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </>
    )
});

export default MessageModal;