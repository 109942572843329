import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from './PrivateRoute'

import Homepage from './Homepage'
import Header from './Components/navigate2022/Header';
import TechnicalSessions from './Components/navigate2022/Attendee/TechnicalSessions';

import NotFound from './Components/navigate2022/NotFound';

import WorkProgress from './Components/navigate2022/WorkProgress';
import Login from './Components/navigate2022/Login';
import LoginSingleSignOn from './Components/navigate2022/LoginSingleSignOn';
import Register from './Components/navigate2022/Register';

import AboutSession from './Components/navigate2022/Attendee/AboutSession';
import ResetPassword from './Components/navigate2022/ResetPassword';
import DashboardSpeaker from './Components/navigate2022/Speaker/Dashboard';
import ProfileDetails from './Components/navigate2022/Speaker/ProfileDetails';

import ExhibitorDetails from './Components/navigate2022/Exhibitor/ExhibitorDetails';
import ExhibitorBooth from './Components/navigate2022/Exhibitor/ExhibitorBooth';

import Dashboard from './Components/navigate2022/Exhibitor/Dashboard';
import LoginSplash from './Components/navigate2022/LoginSplash';
import SingleSignOn from './Components/navigate2022/SingleSignOn';

import Lobby from './Components/navigate2022/Attendee/Lobby';
import ExhibitHall from './Components/navigate2022/Attendee/ExhibitHall';
import NetworkingLounge from './Components/navigate2022/Attendee/NetworkingLounge';
import ConferenceCenter from './Components/navigate2022/Attendee/ConferenceCenter';
import ConferenceCenterPage from './Components/navigate2022/Attendee/ConferenceCenterPage';
import TrackConferenceDetail from './Components/navigate2022/Attendee/TrackConferenceDetail';
import ExhibitList from './Components/navigate2022/Attendee/ExhibitList';
import Session from './Components/navigate2022/Attendee/Session';

import HelpDesk from './Components/navigate2022/Attendee/HelpDesk';
import GoldenTicket from './Components/navigate2022/Attendee/GoldenTicket';
import AttendeeExhibitorBooth from './Components/navigate2022/Attendee/AttendeeExhibitorBooth';

import UserProfile from './Components/navigate2022/UserProfile';

import ChatPage from "./TmChat/navigate2022/chat/ChatPage";
import VideoCall from "./TmChat/navigate2022/video/VideoCall";
import ConferenceMeeting from "./TmChat/navigate2022/videoConference/conferenceMeeting";
import Meetings from "./TmChat/navigate2022/videoConference/meetings";
/* import test from './Components/navigate2022/Exhibitor/test'; */

class navigate2022 extends Component {
constructor(){
  super();

  this.state={
    homePage:""
  }
}
componentDidMount(){
  //console.log(window.location.href)
  
}

  render() {

    const domain = window.location.href
    if(domain === ("http://localhost:3000/" || "https://affectionate-bohr-4d306b.netlify.app/")){
      return(
        <div className="App">
            <Router>
              <Route path="/" exact component={Homepage} />
              {/* <Route path="/home" exact component={Homepage} /> */}
            </Router>
        </div>
      )
    }
    else {
      return (
      <Router>
          <div className="App">
            
            <Header/>
            <Switch>
                
                <Route path="/navigate2022/sessionList" component={TechnicalSessions} />
                <Route path="/navigate2022/login/:loginId" component={Login} />
                <Route path="/navigate2022/loginSingleSignOn/:loginId" component={LoginSingleSignOn} />
                <Route path="/navigate2022/resetPassword" component={ResetPassword} />
                <Route path="/navigate2022/register" component={Register} />
                <Route path="/navigate2022/single-signon/:eventId/:emailId/:tokenpass" exact component={SingleSignOn} />
                <Route path="/navigate2022/attendee/aboutsession/:sessionId" component={AboutSession} />
                <Route path="/navigate2022/workProgress" exact component={WorkProgress} />
                <PrivateRoute path="/navigate2022/loginSplash" exact component={LoginSplash} />
                <PrivateRoute path="/navigate2022/speaker/dashboard" exact component={DashboardSpeaker} />
                <PrivateRoute path="/navigate2022/speaker/profile" exact component={ProfileDetails} />
                <PrivateRoute path="/navigate2022/exhibitor/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/navigate2022/exhibitor/profile" exact component={ExhibitorDetails} />
                <PrivateRoute path="/navigate2022/exhibitor/booth" component={ExhibitorBooth} />

                <PrivateRoute path="/navigate2022/chat" exact component={ChatPage} />
                <PrivateRoute path="/navigate2022/video-call" exact component={VideoCall} />
                <PrivateRoute path="/navigate2022/video-conference" exact component={Meetings} />
                <PrivateRoute path="/navigate2022/conference-meeting/:id" exact component={ConferenceMeeting} />

                <PrivateRoute path="/navigate2022/attendee/lobby" component={Lobby} />
                <PrivateRoute path="/navigate2022/attendee/exhibitHall" component={ExhibitHall} />
                <PrivateRoute path="/navigate2022/attendee/networkingLounge" component={NetworkingLounge} />
                <PrivateRoute path="/navigate2022/attendee/conferenceCenter" component={ConferenceCenter} />
                <PrivateRoute path="/navigate2022/attendee/session" component={Session} />
                <PrivateRoute path="/navigate2022/attendee/conferenceCenterDetails/:sessionId" component={ConferenceCenterPage} />
                <PrivateRoute path="/navigate2022/attendee/trackConferenceDetail/:trackId" component={TrackConferenceDetail} />
                <PrivateRoute path="/navigate2022/attendee/technicalSessions" component={TechnicalSessions} />
                <PrivateRoute path="/navigate2022/attendee/exhibitList" component={ExhibitList} />
                <PrivateRoute path="/navigate2022/attendee/helpDesk" component={HelpDesk} />
                <PrivateRoute path="/navigate2022/user/profile" exact component={UserProfile} />
                <PrivateRoute path="/navigate2022/attendee/goldenTicket" component={GoldenTicket} />
                <PrivateRoute path="/navigate2022/attendee/exhibitorBooth/:exhibitorId" component={AttendeeExhibitorBooth} />
                <Route path="*" component={NotFound} />
              </Switch>
         
          </div>
      </Router>
    );}
  }  
}

export default navigate2022;
