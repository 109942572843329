import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import profileImage from "../../../assets/images/no-image.png"; 

const SpeakerList = () => {
  const history = useHistory();
  const [speakerList, setSpeakerList] = useState([]);
  useEffect(() => {
    Axios.get(
      `${
        global.config.requestUrl
      }speaker/getSpeakersList?eventId=${localStorage.getItem("eventId")}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    ).then((res) => {
      //console.log(res.data.data);
      if (res.data.errorCode === 0) {
        setSpeakerList(res.data.data);
      }
    });
  }, []);
  return (
    <React.Fragment>
      <div className="p-3">
        <div className="card p-3 border-none box-shadow">
          <div className="pageHeading mb-4">
            <i
              className="fa fa-arrow-left backBtn"
              onClick={() => history.goBack()}
            ></i>
            <h4 className="text-center">Speakers List</h4>
          </div>
          <ul className="borderList speakers-list">
            {speakerList
              .sort((a, b) => (a.lastName > b.lastName ? 1 : -1))
              .map((speaker, index) => (
                <li key={index}>
                  <Link to={`/tobi2022/speakers/${speaker.speaker_id}`}>
                    <div className="userPic ml-0">
                      <img src={speaker.image ? speaker.image : profileImage} alt="" />
                    </div>
                    <div className="userName">
                      <p className="m-0">
                        {speaker.firstName} {speaker.lastName}
                      </p>
                    </div>
                    <i className="fa fa-angle-right rightCorner"></i>
                  </Link>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SpeakerList;