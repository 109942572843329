import React, { Component } from "react";
import Axios from "axios";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import firebase from "../../Firebase/index";

class LoginSplash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsersImgList: [],
      sponsersUrl: "",
      pageUrlVarify: false,
      pageLoader: true,
      allowAsExhibitor: false,
      allowAsSpeaker: false,
      allowAsLobbier: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("currentRole")) {
      var rolesArr = localStorage.getItem("currentRole").split(",");
      if (rolesArr.indexOf("-1") !== -1) {
        this.setState({ allowAsExhibitor: true });
      }
      if (rolesArr.indexOf("-6") !== -1) {
        this.setState({ allowAsSpeaker: true });
      }
      if (rolesArr.indexOf("-7") !== -1) {
        this.setState({ allowAsExhibitor: true });
      }
    }

    // if (/iPhone|iPad|Mac|Macintosh|iPod/i.test(navigator.userAgent)) {
    //   console.log("yes apple");
    // } else {
    //   const messaging = firebase.messaging();
    //   messaging
    //     .requestPermission()
    //     .then(() => {
    //       return messaging.getToken();
    //     })
    //     .then((token) => {
    //       this.saveFirebasePushNotificationToken(token);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // }

    if (/iPhone|iPad|Mac|Macintosh|iPod/i.test(navigator.userAgent)) {
      console.log("yes apple");
    } else {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      }
      else if (Notification.permission === "granted") {
        const messaging = firebase.messaging();
            messaging
            .getToken()
              .then((token) => {
                this.saveFirebasePushNotificationToken(token);
              })
              .catch((err) => {
                console.log(err);
              });
      }
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            const messaging = firebase.messaging();
            messaging
            .getToken()
              .then((token) => {
                const tokData = {
                  token: token,
                  email: localStorage.getItem("username"),
                };
            
                Axios.post(`${global.config.requestUrl}saveFirebaseToken`, tokData, {
                  headers: { "auth-token": localStorage.getItem("exhibitorToken") },
                })
                  .then((res) => {})
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
      } 
    }

    Axios.get(
      `${global.config.requestUrl}exhibitor?eventId=${localStorage.getItem(
        "eventId"
      )}&type=2`
    )
      .then((res) => {
        if (res.data.errorCode === 0) {
          this.setState({
            pageUrlVarify: true,
            pageLoader: false,
            sponsersImgList: res.data.data,
            sponsersUrl: res.data.logo,
          });
        } else {
          this.setState({
            sponsersImgList: [
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
            ],
          });
          this.setState({ pageLoader: false });
        }

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  saveFirebasePushNotificationToken = (token) => {
    const tokData = {
      token: token,
      email: localStorage.getItem("username"),
    };

    Axios.post(`${global.config.requestUrl}saveFirebaseToken`, tokData, {
      headers: { "auth-token": localStorage.getItem("exhibitorToken") },
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
    };
    const mobileSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <React.Fragment>
          <div className="p-3">
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="splashWrp">
              <div className="splashListWrp">
                {/* <div className="tp_logo">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/focus2022/logo-title.png"
                    }
                    alt=""
                  />
                  <div style={{fontSize:'16px'}}><strong>February 22-24, 2022</strong></div>
                </div> */}
                {this.state.allowAsSpeaker ? (
                  <div className="splashList">
                    <div className="splashBx">
                      <Link to="/mdapa2024/speaker/profile">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/splashSpeaker.png"
                          }
                          alt=""
                        />
                        <h3>Speaker Dashboard</h3>
                      </Link>
                    </div>
                  </div>
                ): null }
                {this.state.allowAsExhibitor ? (
                  <div className="splashList">
                    <div className="splashBx">
                      <Link to="/mdapa2024/exhibitor/profile">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/splashExhibit.png"
                          }
                          alt=""
                        />
                        <h3>Exhibitor Dashboard</h3>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {this.state.allowAsLobbier ? (
                  <div className="splashList">
                    <div className="splashBx">
                      <Link to="/mdapa2024/lobby">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/splashAtandee.png"
                          }
                          alt=""
                        />
                        <h3>Attendee Lobby</h3>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
              {window.innerWidth > 767 ? (
                <div className="sponsersSlideBx">
                  <Slider {...settings}>
                    {this.state.sponsersImgList.map((sponsers) => (
                      <div className="sponserBx" key={sponsers.logo}>
                        <img src={sponsers.logo} alt="" className="" />
                          <p>
                              {sponsers.logo_level === 1?"Diamond ": ""}
                              {sponsers.logo_level === 2?"Platinum ": ""}
                              {sponsers.logo_level === 3?"Gold ": ""}
                              {sponsers.logo_level === 4?"Bronze ": ""}
                              {sponsers.logo_level === 5?"Nickel ": ""}
                              Sponsor
                          </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <div className="sponsersSlideBx">
                  <Slider {...mobileSettings}>
                    {this.state.sponsersImgList.map((sponsers) => (
                      <div className="sponserBx" key={sponsers.logo}>
                        <img src={sponsers.logo} alt="" className="" />
                        <p>
                            {sponsers.logo_level === 1?"Diamond ": ""}
                            {sponsers.logo_level === 2?"Platinum ": ""}
                            {sponsers.logo_level === 3?"Gold ": ""}
                            {sponsers.logo_level === 4?"Bronze ": ""}
                            {sponsers.logo_level === 5?"Nickel ": ""}
                            Sponsor
                        </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              )}
            </div>
          )}
          </div>
      </React.Fragment>
    );
  }
}
export default LoginSplash;
