
import  VideoConference from './VideoConference';
import  VideoChat from './VideoChat';
import React, { Component } from 'react'

class VideoPage extends Component{


    render() {
        return (
            <React.Fragment>

                <div className="startmeeting-screen d-lg-flex">
                    <VideoConference callUserInfo={this.props.callUserInfo} toggleAudio={this.props.toggleAudio} toggleVideo={this.props.toggleVideo} toggleAudioValue={this.props.toggleAudioValue} endCall={this.props.endCall} onAnswered={this.props.onAnswered}/>

                    {this.props && this.props.onAnswered?<VideoChat
                        userInfo={this.props.callUserInfo}
                        loginUserDetails={this.props.userDetails}
                        onSendMessage={this.props.onSendMessage}
                        chatConversations={this.props.chatConversations}
                        troopConnection={(this.props.troopConnection ? this.props.troopConnection : {})}
                        closeChat={this.props.closeChat}
                        />:''}
                </div>

            </React.Fragment>
        )
    }
}
export default VideoPage