import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export default class Disabled extends Component {
    render() {
        
        return (
            <React.Fragment>
                <div className="brokenLink">
                    { <img src={process.env.PUBLIC_URL + '/images/Disable_page_img.jpg'} alt="" />}
                    <div className="col-12 col-md-12 text-center mt-3">
                                        <div className="form-group">
                                        <Link style={{fontSize: "16px", display: "block", background: "#088fcf", padding: "10px", color: "#fff"}} to={`/${localStorage.getItem('eventName')}/loginSplash`}>Back</Link>
                                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
