import React, { Component } from 'react';
import BottomNav from './BottomNav'
import Axios from 'axios';
import {Link} from 'react-router-dom';

import Slider from "react-slick";

import AboutSession from './AboutSession';
import AboutSpeaker from './AboutSpeaker';
import SessionDocuments from './SessionDocuments';
import jwt_decode from 'jwt-decode'


class Session extends Component {
    constructor(props){
        super(props);
        var dateNow = new Date();
        this.state={
            sponsersImgList:[],
            mediaPartnerList:[],
            producedByList:[],
            sectionLoader:true,
            aboutSessionModal:false,
            aboutSpeakerModal:false,
            sessionDocumentsModal:false,
            liveVideoUrl:[],
            liveVideoUrlNotFound:"",
            
            liveUrlNull:"",
            ip:'127.0.0.1',
            pageLoader:true,
            iframeLoader:true,
            sessionSurveyModal:false,
            timeSpendInSeconds:0,
            minAttendance:0, // in seconds
            infoBoxModal:false,
            timeWhenConfPageLoadedInSec: dateNow.getTime() / 1000,
            alreadySavedOnAutoLogout:0,
        }
        
        const {match : {params}} = props;
        this.scannedData = {
            regId: localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            sessionId: params.sessionId,
            accountId:localStorage.getItem('accountId'),
            serialNumber:this.state.ip,
            duration: this.state.timeSpendInSeconds.toString(),
            role:localStorage.getItem('currentRole'),
        }
        this.token = localStorage.getItem('exhibitorToken');
        
        
    }
    infoBoxModalHandlar = (e) => {
        e.preventDefault();
        this.setState({infoBoxModal:!this.state.infoBoxModal})
    }
    componentWillUnmount = () => {
        this.insertScannedDataHere();
        console.log(this.state.timeSpendInSeconds);
        // if(this.state.timeSpendInSeconds >= this.state.minAttendance){
        //   this.updateScannedData();
        // }
    }


    componentWillReceiveProps(nextProps){
        document.getElementById('myschedulepopup').click();
        this.getSessionData(nextProps);
        this.updateGoldenTicketStatus(nextProps);
        this.saveAuthForSurvey(nextProps);
   }
   sessionSurveyModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionSurveyModal:!this.state.sessionSurveyModal,iframeLoader:true})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSessionModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSessionModal:!this.state.aboutSessionModal})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSpeakerModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSpeakerModal:!this.state.aboutSpeakerModal})
        localStorage.setItem('conferenceClicked',1)
    }
    sessionDocumentsModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionDocumentsModal:!this.state.sessionDocumentsModal})
        localStorage.setItem('conferenceClicked',1)
    }
    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }

    /** updateScannedData is become useless as node.js will itself check if record already there. For now I am not commenting this. May be we will use this function in future. */
    updateScannedData = () => {

        
        const {match : {params}} = this.props;
            const scannedData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role:localStorage.getItem('currentRole'),
            }

            //console.log(scannedData);return false;
            //Check before insert scanned data

            Axios.post(`${global.config.requestUrl}user/checkScanDataForUser`, scannedData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode === 0){  // Error case
                            this.insertScannedDataHere();
                        }
                })
                .catch(err => {
                    console.log(err);
                });


            
    }

    insertScannedDataWhenAutoLogOut = () => {
        var eventId = localStorage.getItem('eventId');
        var eventName = localStorage.getItem('eventName');
        this.scannedData.duration = this.state.timeSpendInSeconds.toString();
        Axios.post(`${global.config.requestUrl}user/insertScanData`, this.scannedData,
                    {headers: { "auth-token": this.token }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }else{
                           
                            localStorage.clear();
                            //this.props.history.push(`/common-powerup2021/login/${eventId}`)
                            window.location.href = window.location.protocol+'//'+window.location.host+'/'+eventName+'/login/'+eventId;
                         
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    insertScannedDataHere = () => {
        this.scannedData.duration = this.state.timeSpendInSeconds.toString();
        Axios.post(`${global.config.requestUrl}user/insertScanData`, this.scannedData,
                    {headers: { "auth-token": this.token }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }else{
                            
                         this.setState({timeSpendInSeconds: 0});
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    updateGoldenTicketStatus = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        const ticketStatusData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:0,
            linkPage:'sessionRoom',
            sessionId:params.sessionId,
            exhibitorId:0
            
        }
        Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(res.data.errorCode){
            document.getElementById('goldenTkt').classList.add("greyScale");
            this.setState({pageLoader:false});
        }else{
            this.setState({pageLoader:false});
            document.getElementById('goldenTkt').classList.remove("greyScale");
        }
    })
    .catch(err => {
        console.log(err);
    });
}
    componentDidMount(){
        

        // -1,-6,1441  => -10
// -1          => -1
// -6          => -6
// 1441        => -10
// -1,1441     => -10
// -6,1441     => -10
// -1,-6     => -1 
if(localStorage.getItem('roles')){
    var rolesArr = localStorage.getItem('roles').split(",");
    var currentRole = -10;

    if(rolesArr.length > 2 ){
        currentRole = -10;
    }
    if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
        currentRole = -1;
    }
    if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
        currentRole = -6;
    }
    if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
        currentRole = -1;
    }
    if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                currentRole = -1;
    }
    localStorage.setItem('currentRole',currentRole);
    this.scannedData.role = localStorage.getItem('currentRole').toString();
    
}
        const {match : {params}} = this.props;
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?eventId=${localStorage.getItem('eventId')}&type=3`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, mediaPartnerList:res.data.mediaPartner, producedByList:res.data.producedBy,sectionLoader:false});
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({sectionLoader:false});
            }
        })
        .catch(err => {
            console.log(err);
        });

        this.getSessionData(this.props);
        this.saveAuthForSurvey(this.props);
        this.updateGoldenTicketStatus(this.props);
        fetch('https://geoip-db.com/json/')
           .then(res => res.json())
           .then(json => this.setState({ip: json.IPv4}));
        
        
    }

    countDown = () => {
        
        /** This code will redirect the user to login splash page before token expires */
        const token = localStorage.exhibitorToken;
        const decoded = jwt_decode(token, {complete: true});
        var clkb = this.state.timeWhenConfPageLoadedInSec;
        this.setState({timeWhenConfPageLoadedInSec: clkb + 1});
        if(decoded.exp-10 <= this.state.timeWhenConfPageLoadedInSec  && this.state.alreadySavedOnAutoLogout === 0){
            //this.props.history.push(`/common-powerup2021/login/590`)
            this.setState({
                alreadySavedOnAutoLogout: 1
            }, () => {
                this.insertScannedDataWhenAutoLogOut();
            });
           
        }
       /** This code will save the scanned data if timespend is equal to the minattendance */
      if(this.state.timeSpendInSeconds == this.state.minAttendance && this.state.minAttendance !== 0 ){
        //this.updateScannedData();
        this.insertScannedDataHere();
      }
      var clk = this.state.timeSpendInSeconds;
      this.setState({timeSpendInSeconds: clk + 1});
       
    }

    getSessionData = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        Axios.get(`${global.config.requestUrl}user/getSessionVideo?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({liveVideoUrl:res.data.data, liveVideoUrlNotFound:res.data.errorCode, liveUrlNull:res.data.data[0].video_url, minAttendance:res.data.data[0].min_attendence*60, pageLoader:false})
            if(res.data.errorCode === 0){
                this.setState({})
                setInterval(this.countDown, 1000);
                if(res.data.data[0].video_url.includes('https://success.zoom.us/wc/join/') ){
                    document.getElementById('videoconf').innerHTML = '<div class="confWrap">'+res.data.data[0].video_text+' <p><a class="btn btn-warning" target="_blank" href ="'+res.data.data[0].video_url+'" > Click here </a></p></div>';
                }

                if( res.data.data[0].video_url.includes('https://teams.microsoft.com/') ){
                    document.getElementById('videoconf').innerHTML = '<div class="confWrap"><p><a class="btn btn-warning" target="_blank" href ="'+res.data.data[0].video_url+'" > Click here to launch Teams meeting </a></p></div>';
                }
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader:false});
        });
    }


        saveAuthForSurvey = (myprop) => {
            const {match : {params}} = myprop;
            const authSurveyData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
            }

            Axios.post(`${global.config.requestUrl}user/insertSessionForSurvey`, authSurveyData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
          };
            return (

                <React.Fragment>  
                    <section className="bg12 signleScreen paddingTop height1100 conference">
                        {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                        <div className="container">
                            <div className="conferenceContainer">
                                <div className="conferenceLinks">
                                    <Link to="/" className="fullBtn">
                                        <span>Click Here to view Current Live Session</span>
                                        <i className="fa fa-eye"></i>
                                    </Link>
                                    <h3>Main</h3>
                                    <ul>
                                        <li><Link onClick={this.aboutSessionModalHandler} to="/"><i class="fa fa-info"></i> About this session</Link></li>
                                        <li><Link onClick={this.aboutSpeakerModalHandler} to="/"><i class="fa fa-volume-up"></i> About the speaker</Link></li>
                                        <li><Link onClick={this.sessionDocumentsModalHandler} to="/"><i class="fa fa-file"></i> Session documents</Link></li>
                                        {/* <li><Link onClick={this.sessionSurveyModalHandler} to="/"><i class="fa fa-copy"></i> Session survey</Link></li> */}
                                    </ul>
                                    
                                </div>
                                <div className="conferenceVideoWrp">
                                    <div className="conferenceSchedule" style={{width:"100%"}}>
                                        <h3>Schedule of Live Events</h3>
                                            <div className="scheduleTable">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <td>Session Name</td>
                                                            <td>Date/Time</td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Dummy Paragraph The first time you enter click on the link in the session screen and a new window will open.</td>
                                                            <td>10/19/2020, <br/>07:45 AM</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dummy Paragraph The first time you enter click on the link in the session screen and a new window will open.</td>
                                                            <td>10/19/2020, <br/>07:45 AM</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dummy Paragraph The first time you enter click on the link in the session screen and a new window will open.</td>
                                                            <td>10/19/2020, <br/>07:45 AM</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dummy Paragraph The first time you enter click on the link in the session screen and a new window will open.</td>
                                                            <td>10/19/2020, <br/>07:45 AM</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dummy Paragraph The first time you enter click on the link in the session screen and a new window will open.</td>
                                                            <td>10/19/2020, <br/>07:45 AM</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dummy Paragraph The first time you enter click on the link in the session screen and a new window will open.</td>
                                                            <td>10/19/2020, <br/>07:45 AM</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dummy Paragraph The first time you enter click on the link in the session screen and a new window will open.</td>
                                                            <td>10/19/2020, <br/>07:45 AM</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>  
                        {
                            this.state.infoBoxModal
                            ?
                            <div className="midModal">
                                <div className="midModalArea width800">
                                    <div className="modalPopUpClose" onClick={this.infoBoxModalHandlar}><i className="fa fa-times"></i></div>
                                    <h3 className="modalHeading">Viewing Live Sessions with Microsoft Teams </h3>
                                    <div className="modalContent p-4">
                                        <p>The first time you enter click on the link in the session screen and a new window will open. If you already have the Microsoft Teams app installed on your computer and are logged in choose “Launch it Now” and go right to the session.</p>
                                        <p>If you would like to view without installing the app choose watch on web, and join the meeting anonymously.</p>
                                        <p>If you would like to install the Teams app, you will need to log out of the virtual conference and install the app using the link on the log in page.</p>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        <div className="stickyBtn">
                            <button  onClick={this.infoBoxModalHandlar}>
                                <i className="fa fa-user mr-2"></i>
                                <span>Live Session Help</span>
                            </button>
                            <a href="http://minnesotasafetycouncil.org/conf/2020%20Conference%20CEU%20Form.docx" target="_blank">
                                <i className="fa fa-edit mr-2"></i>
                                <span>CEU Request Form</span>
                            </a>
                        </div> 
                                <div className="bottomScrollerSponsers">
                                    <div className="bottomScrollerSponsersBx" style={{width:"100%"}}>
                                    {
                                        this.state.sectionLoader
                                        ?
                                        (
                                            <div className="sectionLoader">
                                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                            </div>
                                        )
                                        :
                                        null
                                    }

                                    <Slider {...settings}>
                                    { 
                                        this.state.sponsersImgList.map(sponsers => (
                                            <div className="" key={sponsers.exhibitor_id}>
                                                <Link to={`/common-powerup2021/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                    <img src={sponsers.logo} alt=""/>
                                                </Link>
                                            </div>
                                            
                                        ))
                                        
                                    }            
                                    </Slider>

                                    
                                    </div>
                                </div>
                        
                        <BottomNav />

                    </section>
                    {/*=====About Session Modal=====*/}
                    {
                        this.state.aboutSessionModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSessionModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Session</h3>
                                <div className="midModalAreaContent">
                                    <AboutSession passprops= {this.props}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====About Speaker Modal=====*/}
                    {
                        this.state.aboutSpeakerModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSpeakerModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Speaker</h3>
                                <div className="midModalAreaContent">
                                    <AboutSpeaker passprops= {this.props}/>
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Documents Modal=====*/}
                    {
                        this.state.sessionDocumentsModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.sessionDocumentsModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">Session Documents</h3>
                                <div className="midModalAreaContent">
                                    <SessionDocuments passprops= {this.props} />
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Survey Modal=====*/}
                    {
                        this.state.sessionSurveyModal
                        ?
                        <div className="midModal verticleCenterModal">
                            <div className="midContentWrp">
                                <div className="modalPopUpClose" onClick={this.sessionSurveyModalHandler}><i className="fa fa-times"></i></div>
                                <h3>Session Survey</h3>
                                <div className="midContentBx">
                                {
                            this.state.iframeLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                                    <iframe width="100%" height="450" src={`https://www.tslwebreg.com/sessionSurvey2.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&role=${localStorage.getItem('currentRole')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                                </div>                               
                            </div>
                        </div>
                        :
                        null
                    }
                    
                </React.Fragment>
            )
        
        
         
    }
}
export default Session;
