import React, { Component,useState } from 'react'
import utility from "../utility/utility";
class VideoConference extends Component {
    state={
        'audioState':true,
        volume:1,
        audioControl:false,
        videoState:true,
        showUserInfo:false
    };

    constructor(props) {
        super(props);
        this.remoteVideo=React.createRef();
        this.toggleAudio=this.toggleAudio.bind(this);
        this.toggleVideo=this.toggleVideo.bind(this);
        this.setVolume=this.setVolume.bind(this);
    }
    setVolume(event){
        this.setState({'volume':event.target.valueAsNumber});
    }
    toggleAudio(){
        this.props.toggleAudio();
        this.setState({'audioState':!this.state.audioState});
    }
    toggleVideo(){
        const activeClass=this.state.videoState;
        this.setState({'videoState':!activeClass});
        this.props.toggleVideo();

    }
    showAudioControl=()=>{
        this.setState({'audioControl':true});
    }
    hideAudioControl=()=>{
        this.setState({'audioControl':false});
    }
    handleOnChange = (value) => {
        this.setState({
            volume: value
        })
    }

    render(){
        return ( <React.Fragment>
                <div className="startmeeting-leftsection">
                    <div className="startmeeting-beforescreen position-relative">

                        <div className="startmeeting-videoon position-relative">
                            <div className={(this.props.onAnswered?'':'d-none')+" h-100"}>
                                <div className="startmeeting-hostdetails text-center">

                                    <div className="startmeeting-hostpic mb-3">
                                        <span className={this.state.videoState?'d-none':''}>Me</span>

                                        <video autoPlay id='localVideo'  muted  className={this.state.videoState?'':'d-none'}></video>
                                    </div>
                                    <div className="text-white roboto-bold">
                                        Me
                                    </div>
                                </div>
                                <div className={" text-center position-relative h-100"}>

                                    <div className="startmeeting-talking text-white d-none">
                                        Talking: James
                                    </div>
                                    <video autoPlay id='remoteVideo' className='w-100 h-100'></video>

                                </div>
                            </div>
                            {!this.props.onAnswered || (!this.state.videoState || !this.state.audioState)? <div
                                className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                                <div className="d-flex justify-content-center align-items-center flex-column">
                                    <div className="startmeeting-userpic mb-3">
                                        {this.props.callUserInfo?utility.acronym(this.props.callUserInfo.name)
                                            .toUpperCase()
                                            .slice(0, 2):''}
                                    </div>
                                    <div className="startmeeting-name">
                                        {this.props.callUserInfo?this.props.callUserInfo.name:''}
                                    </div>
                                </div>
                            </div>:''}

                            {this.props.onAnswered?
                                <div className="startmeeting-actionbtns startmeeting-videocall col-sm-9 col-md-7 col-lg-4 col-xl-4 mx-auto">
                                <button className="btn startmeeting-btn startmeeting-btn--record" onClick={this.toggleAudio}>
                                    <img className={this.state.audioState ? '' : 'd-none'} src={process.env.PUBLIC_URL +"/images/tmChat/microphone.png"} />
                                    <img src={process.env.PUBLIC_URL + "/images/tmChat/microphone-off.png"} className={this.state.audioState ? 'd-none' : ''}/>
                                </button>
                                <button className="btn startmeeting-btn " onClick={this.toggleVideo}>
                                    <img className={this.state.videoState ? '' : 'd-none'} src={process.env.PUBLIC_URL +"/images/tmChat/video.png"}/>
                                    <img className={this.state.videoState ? 'd-none' : ''} src={process.env.PUBLIC_URL +"/images/tmChat/video_off.png"}/>
                                </button>
                                <button className="btn startmeeting-btn d-none">
                                    <img src={process.env.PUBLIC_URL +"/images/tmChat/screenshare.png"}/>
                                </button>
                                <button className={(this.state.audioState?'':'d-none')+" btn startmeeting-btn startmeeting-volumebtn"}>
                                    <img src={process.env.PUBLIC_URL +"/images/tmChat/speaker.png"}/>
                                    <span className="position-absolute speaker-range">
                                    <input type="range"
                                           min={0}
                                           max={1}
                                           step={0.02}
                                           value={this.state.volume}
                                           onChange={this.setVolume}
                                    />
                                </span>
                                </button>
                                <button className="btn startmeeting-btn d-none" data-toggle="modal"
                                        data-target="#addusersModal">
                                    <img src={process.env.PUBLIC_URL +"/images/tmChat/add-user.png"}/>
                                </button>
                                <button className="btn startmeeting-btn d-none">
                                    <img src={process.env.PUBLIC_URL +"/images/tmChat/recording.png"}/>
                                </button>
                                <button onClick={this.props.endCall} className="btn startmeeting-btn startmeeting-btn--end">
                                    <img src={process.env.PUBLIC_URL +"/images/tmChat/endcall.png"}/>
                                </button>
                                    <div className="dropdown d-none">
                                        <button className="btn p-0 meetings-cam--arrowbtn dropdown-toggle" type="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i className="fas fa-chevron-down color-888"></i>
                                        </button>
                                        <div className="dropdown-menu meetings-cam--dropdown mt-2 dropdown-menu-right"
                                             aria-labelledby="dropdownMenuButton">
                                            <div
                                                className="d-flex align-items-center justify-content-between px-3 py-2">
                                                <div className="settings-subsection color-lightgreen mb-0">
                                                    Select Camera Device
                                                </div>
                                                <span className="d-inline-block">
                                            <img src="img/icons/headphones-green.svg" width="16"/>
                                        </span>
                                            </div>
                                            <div className="dropdown-item px-3 py-2 pointer">
                                                <div className="d-flex justify-content-between">
                                                    Dell Camera
                                                    <i className="fas fa-check-circle"></i>
                                                </div>
                                            </div>
                                            <div className="dropdown-item px-3 py-2 pointer">Default Camera</div>
                                        </div>
                                    </div>
                            </div>:''}
                        </div>
                        <div className="tsl-logo">
                            <img src={process.env.PUBLIC_URL +"/images/tmChat/tsl.png"} className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
    );
    }
}
export default VideoConference