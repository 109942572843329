import dateTimeFormat from "../utility/dateTimeFormat";
import React, {Component} from "react";
import utility from "../utility/utility";
import Linkify from 'react-linkify';

const EXTENSIONS = {
    IMAGES: ["jpg", "png", "jpeg", "ico", "bmp", "gif", "icns", "svg"],
    AUDIO: ["mp3", "wav", "ogg"],
    VIDEO: ["mp4", "3gp", "mkv", "mov", "flv", "avi", "wmv", "webm", "3gpp", "mpeg14"],
    DOCUMENT: ["doc", "docx", "xls", "xlsx", "pdf", "txt", "csv", "eps", "odf", "ppt", "pptx14"]

};

function getAttachmentType(url) {
    let parseUrl = removeURLParameter(url);
    var ext = parseUrl.split(".");
    return ext[ext.length - 1].toLowerCase();

}

function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {


        return urlparts[0];
    }
    return url;
}



let userAvatar;
let oldDate;
class ChatMessages extends Component {

    constructor() {
        super();
        this.state={'openAttachmentModal':false,attachmentUrl:'',attachmentType:''}
    }
    openAttachment=(url,type)=>{
        console.log(url,type);
        this.setState({'openAttachmentModal':!this.state.openAttachmentModal,attachmentUrl:url,attachmentType:type});
    }
    showHideAttachmentModal=()=>{
        this.setState({'openAttachmentModal':false});
    }
     componentDecorator = (href, text, key) => (
        <a href={href} key={key} target="_blank" className='text-primary'>
            {text}
        </a>
    );
    render() {
        return (
            <React.Fragment>
                { this.props.chatConversations && this.props.chatConversations.length?
                    this.props.chatConversations.map((conversion, index) => {
                        let currentDate = dateTimeFormat.getDateSeprator(conversion.created_at);
                        let messageTime = this.props.troopConnection.getMessageTime(conversion.created_at);
                        let user = conversion.sender_id + '' + conversion.receiver_id + '' + currentDate + '' + messageTime;
                        let chatHtml = '';
                        if (index === 0) {
                            userAvatar = '';
                            oldDate = '';
                        }
                        if (currentDate !== oldDate) {
                            oldDate = currentDate;
                            chatHtml = <div className="devider px-4 position-relative my-4" key={index + 1}>
                                <hr/>
                                <span>{oldDate}</span></div>;
                            index = index + 1;
                        }

                        if (conversion.message_type === this.props.troopConnection.messageType().TEXT || conversion.message_type === this.props.troopConnection.messageType().ATTACHMENT){

                            conversion.sender_id = parseInt(conversion.sender_id);
                            index = index + 1;
                            let message;
                            //console.log(String.fromCharCode(),"parseSite");
                            if (parseInt(conversion.message_type) === 0) {
                                // console.log(parser.parseFromString(this.props.troopConnection.parseMessage(conversion.message), 'text/html'),"console");
                                message = <div className="message"><Linkify componentDecorator={this.componentDecorator} properties={{target: '_blank', style: {color: 'blue !important', fontWeight: 'bold'}}}> {conversion.message}</Linkify></div>
                            } else {
                                let attachmentType = getAttachmentType(conversion.attachment);
                                if (EXTENSIONS.IMAGES.indexOf(attachmentType) !== -1) {
                                    message =


                                        <img  src={conversion.attachment} className="rounded overflow-hidden  img-fluid message-attachments" onClick={e=>this.openAttachment(conversion.attachment,1)} alt='attachemnt'/>


                                } else if (EXTENSIONS.VIDEO.indexOf(attachmentType) !== -1) {
                                    message =
                                        <video width="320" height="240" className='rounded overflow-hidden message-attachments'
                                               controlsList="nodownload"  onClick={e=>this.openAttachment(conversion.attachment,2)}>
                                            <source src={conversion.attachment} type="video/mp4"/>
                                        </video>


                                } else if (EXTENSIONS.AUDIO.indexOf(attachmentType) !== -1) {
                                    message =

                                        <audio width="320" height="240" controls className='message-attachments'
                                               controlsList="nodownload">
                                            <source src={conversion.attachment} type="video/mp4"/>
                                        </audio>


                                } else {
                                    message = <a className="chat-attachment-type" href={conversion.attachment} target='_blank' rel="noopener">
                                        <img  alt='' src={process.env.PUBLIC_URL + utility.getFileAttachmentIcon(attachmentType)} className="img-fluid"/>
                                        <span className="chat-attachment-name text-truncate">
                                    {utility.getTrimmedAttachmentNameString(utility.getFileName(removeURLParameter(conversion.attachment)))}
                                </span>
                                    </a>


                                }

                            }


                            if (conversion.sender_id === this.props.loginUserDetails.tm_user_id) {

                                chatHtml = [chatHtml, <div
                                    className={((userAvatar !== user) ? '' : '') + "chat-widget-messages-opp  d-flex justify-content-end "}
                                    key={index + 1}>
                                    <div className="mr-3 w-100">
                                        {(userAvatar !== user) ?
                                            <div className="time mb-2">
                                                {this.props.loginUserDetails.name}, {messageTime}
                                            </div> : ''}
                                        <div className="messages-opp text-right d-flex justify-content-end">
                                            {(parseInt(conversion.message_type) === 0)?'':<a href={conversion.attachment} className="download-file" download target='_blank' rel="noopener"><img  alt='' src={process.env.PUBLIC_URL +'/images/tmChat/attachment_download_icon.svg'}/></a>}

                                            {message}

                                        </div>
                                    </div>

                                    <div
                                        className={((userAvatar !== user) ? 'user_img' : 'user_img_none') + " d-flex justify-content-center"}>
                                        {(userAvatar !== user) ? <img  alt='' src={this.props.loginUserDetails.profilePic}
                                                                      className="img-fluid rounded-pill"/> : ''}
                                    </div>
                                </div>]
                                ;
                            } else {
                                chatHtml = [chatHtml, <div
                                    className={((userAvatar !== user) ? '' : '') + "chat-widget-messages  d-flex"}
                                    key={index + 1}>
                                    <div
                                        className={((userAvatar !== user) ? 'user_img' : 'user_img_none') + " d-flex justify-content-center"}>
                                        {(userAvatar !== user) ? <img  alt='' src={conversion.profilePic?conversion.profilePic:(this.props.userInfo?this.props.userInfo.profilePic:'') }
                                                                      className="img-fluid rounded-pill"/> : ''}
                                    </div>
                                    <div className="ml-3 w-100">
                                        {(userAvatar !== user) ?
                                            <div className="time mb-2">
                                                { conversion.name ?  conversion.name:(this.props.userInfo?this.props.userInfo.name:'') }, {this.props.troopConnection.getMessageTime(conversion.created_at)}
                                            </div> : ''}
                                        <div className='d-flex'>   {message}
                                            {(parseInt(conversion.message_type) === 0)?'':<a href={conversion.attachment} className="download-file" download target='_blank' rel="noopener"><img  alt='' src={process.env.PUBLIC_URL +'/images/tmChat/attachment_download_icon.svg'}/></a>}
                                        </div>
                                    </div>
                                </div>];

                            }
                            if (userAvatar !== user) {
                                userAvatar = user;
                            }
                            chatHtml = chatHtml.filter(item => item !== undefined && item !== null && item !== "");


                            return (
                                chatHtml
                            );
                        }else{
                            let messageDisplay='';
                            if(conversion.message_type===this.props.troopConnection.messageType().GROUP_MEMBER_ROLE_UPDATED){
                                let formatMessage=JSON.parse(conversion.message);
                                let newRole= parseInt(formatMessage.new_role);
                                let message='';
                                if(newRole===this.props.troopConnection.groupMemberRole().MODERATOR){
                                    if(conversion.sender_id===this.props.loginUserDetails.tm_user_id){
                                        message='You Made '+formatMessage.name +"as moderator";
                                    }else{
                                        message=conversion.member_name+' Made '+formatMessage.name +"as moderator";

                                    }
                                }else if(newRole===this.props.troopConnection.groupMemberRole().ADMIN){
                                    message=(conversion.sender_id===this.props.loginUserDetails.tm_user_id)?'You Made':conversion.sender_name+' Made as Admin';
                                    if(conversion.sender_id===this.props.loginUserDetails.tm_user_id){
                                        message='You Made '+formatMessage.name +" as Admin";
                                    }else{
                                        message=conversion.member_name+' Made '+formatMessage.name +" as Admin";

                                    }
                                }
                                messageDisplay=message;

                            }else if(conversion.message_type===this.props.troopConnection.messageType().GROUP_CREATED){
                                messageDisplay='Group Created';
                            }
                            chatHtml=[chatHtml,<div key={index+1}><div className="group-notification-wrapper px-4 position-relative" key={index + 1}>
                                <span className='group-notification-wrapper-span'>{messageDisplay}</span></div></div>]

                            chatHtml = chatHtml.filter(item => item !== undefined && item !== null && item !== "");

                            return  (chatHtml);
                        }
                    })
                    :''}
                {this.state.openAttachmentModal?
                    <React.Fragment>
                        <div className="modal-backdrop show"></div>
                        <div className="modal chat-open-attachment-modal d-block" >
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header p-0">
                                        <button type="button" className="close close-attachments-modal"  data-dismiss="modal" aria-label="Close" onClick={this.showHideAttachmentModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body p-0 text-center">
                                        {this.state.attachmentType===1?<img  alt='' src={this.state.attachmentUrl} className='img-fluid'/>:<video className="" controls ><source src={this.state.attachmentUrl}/></video>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    :''}
            </React.Fragment>
        );
    };
}

export default ChatMessages;
