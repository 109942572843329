import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import BoothContactsTable from './BoothContactsTable';
import FollowUpsTable from './ContactAndFollowUpsTable';
import BannerEditor from './BannerEditor';
import AddDocumentsVideos from './AddDocumentsVideos';
import {storage} from '../../../Firebase'
import Axios from 'axios';
import LeftNavBar from './LeftNavBar';

import ExhibitorBoothFullAccess from './ExhibitorBoothFullAccess';
import ExhibitorBoothLimitedAccess from './ExhibitorBoothLimitedAccess';
import ExhibitorBoothFullAccess2 from './ExhibitorBoothFullAccess2';
import ExhibitorBoothFullAccess3 from './ExhibitorBoothFullAccess3';
//import { stat } from 'react-native-fs';


class ExhibitorBooth extends Component {
    constructor(props){
        super(props);
        this.state = {
           pageLoader:true,
           showTemplate:false,
           templateId:0,

        }
    }

     componentDidMount (){
      var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
      Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorInfo?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
        if(!res.data.errorCode){

         this.setState({ showTemplate:true,templateId:res.data.data[0].template})
        }else{

        }
        this.setState({ pageLoader:false})
    })
    .catch(err => {
        console.log(err);
    });

    }
    render() {
       const passprops = this.props;
        
        if(this.state.showTemplate){

          if(this.state.templateId === 1){
             return (
                <ExhibitorBoothFullAccess passprops= {passprops}/>
             )
          }else if(this.state.templateId === 2){
            return (
                <ExhibitorBoothFullAccess2 passprops= {passprops}/>
             )
          }else if(this.state.templateId === 3){
            return (
                <ExhibitorBoothFullAccess3 passprops= {passprops}/>
             )
          }else{
            return (
               <ExhibitorBoothLimitedAccess passprops= {passprops}/>
           )
          }
       }else{
         return (
                    <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>

            )
       } 



    }
}
export default ExhibitorBooth;
