import React from "react";

const YouTubeModal = React.memo(props => {
    return (
        <>
            <div className="modalPopUp">
            <div className="modalPopUpInr p-0">
              <div
                className="modalPopUpClose"
                onClick={props.closeModal}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading">{props.youTubeTitle}</h3>
              <div className="modalContent p-4">
                     <div className="modalVideoWrapper">
                            <iframe width="560" height="315" title={props.youTubeTitle} src={props.youTubeLink} frameborder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                      </div>
              </div>
            </div>
          </div>
        </>
    )
});

export default YouTubeModal;