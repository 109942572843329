import React, { Component, useState } from "react";
import Axios from "axios";
import jwt_decode from 'jwt-decode';
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
//import AddToScheduleRenderer from "./AddToScheduleRenderer";
import LinkToSessionRenderer from "./LinkToSessionRenderer";
import SpeakerDataRenderer from "./SpeakerDataRenderer";
import StartTimeRenderer from "./StartTimeRenderer";
import CustomDateFilter from "./CustomDateFilter";
import CustomCourseStudyFilter from "./CustomCourseStudyFilter";
import BlankSessionRelatedHtml from '../BlankSessionRelatedHtml';
import BlankSpeakerRelatedHtml from '../BlankSpeakerRelatedHtml';
import SessionEvaluation from './SessionEvaluation'

class SessionListGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rowHeight: 45,
    };
  }

  
  getAlreadyAddedSessionsInSchedule = () => {
    Axios.get(
      `${
        global.config.requestUrl
      }user/getSessionSchedules?eventId=${localStorage.getItem(
        "eventId"
      )}&memberId=${localStorage.getItem("userId")}`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {
        var scheduleInList = [];
        var schduleInListWithSchId = {}; // {} will create an object
        
        res.data.data.forEach((element) => {
          scheduleInList.push(element.session_id);
          var name = element.session_id;
          var val = element.schdeule_id;
          schduleInListWithSchId[name] = val;
        });
        localStorage.setItem('addedSession',JSON.stringify(schduleInListWithSchId));
      })
      .catch((err) => {
        console.log(err);
      });
  };
  currencyFormatter = (params) => {
    return (
      '<a href="https://www.google.com" target="_blank" rel="noopener">' +
      params.value +
      "</a>"
    );
  };

 

  render() {
    const rowHeight = 50;
    const SessionGrid = () => {
      const [gridApi, setGridApi] = useState(null);
      const [gridColumnApi, setGridColumnApi] = useState(null);
      const [rowData, setRowData] = useState(null);

      const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        const updateData = (data) => {
          setRowData(data);
        };

        const postdata = {
          eventId: localStorage.getItem("eventId"),
          searchByTrack: this.state.trackName,
          searchByName: "",
          sortByDate: "",
        };

        Axios.post(
          `${global.config.requestUrl}getTechnicalSessions`,
          postdata
        )
          .then((res) => {
            if (!res.data.errorCode) {
              updateData(res.data.data);
            }else{
              updateData([]);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };

      const onPaginationChanged = () => {
        if (gridApi) {
          if(localStorage.getItem('exhibitorToken')){
            const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            if (Date.now() < decoded.exp * 1000) {
              this.getAlreadyAddedSessionsInSchedule();
            }else{
              localStorage.setItem('addedSession','{}');
            }
          }else{
            localStorage.setItem('addedSession','{}');
          }
         
        }
      };

      return (
        <div style={{ width: "100%", height: "100%", align: "center" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              frameworkComponents={{
                LinkToSessionRenderer: LinkToSessionRenderer,
                SpeakerDataRenderer: SpeakerDataRenderer,
                // AddToScheduleRenderer: AddToScheduleRenderer,
                StartTimeRenderer:StartTimeRenderer,
                CustomDateFilter: CustomDateFilter,
                CustomCourseStudyFilter:CustomCourseStudyFilter,
                SessionEvaluation: SessionEvaluation
              }}
              defaultColDef={{
                editable: false,
                enableRowGroup: true,
                enablePivot: true,
                enableValue: true,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 0,
                minWidth: 30,
                floatingFilter: true,
                wrapText: true,     // <-- HERE
              }}
              rowClassRules={{
                'cancelled-session': function (params) {
                  var status = params.data.sStatus;
                  return status === 2 ;
                },
                'deleted-session': function (params) {
                  var status = params.data.sStatus;
                  return status === 1 ;
                },
                'active-session': 'data.sStatus === 0',
              }}
              suppressRowClickSelection={true}
              groupSelectsChildren={true}
              debug={true}
              rowSelection={"multiple"}
              rowGroupPanelShow={"always"}
              pivotPanelShow={"always"}
              enableRangeSelection={true}
              pagination={false}
              // paginationPageSize={10}
              onGridReady={onGridReady}
              rowData={rowData}
              onPaginationChanged={onPaginationChanged}
              rowHeight={this.state.rowHeight}
            >
              <AgGridColumn
                field="session_name"
                headerName="Session Name"
                cellRenderer="LinkToSessionRenderer"
                width="300"
              />
              <AgGridColumn
                field="speaker_name"
                headerName="Speaker Name"
                cellRenderer="SpeakerDataRenderer"
                width="350"
              />
              <AgGridColumn
                headerName="Date"
                field="start_date"
                filter="CustomDateFilter"
                // filterParams={filterParams}
                width="150"
              />
              <AgGridColumn
                field="start_time"
                headerName="StartTime"
                width="150"
                cellRenderer="StartTimeRenderer"
              />
              <AgGridColumn
                field="end_time"
                headerName="EndTime"
                width="150"
                cellRenderer="EndTimeRenderer"
              />
              <AgGridColumn
                field="track"
                headerName="Evaluation"
                filter="CustomCourseStudyFilter"
                width="120"
                cellRenderer="SessionEvaluation"
              />
              {/* <AgGridColumn
                field="room"
                headerName="Room No."
                width="100"
              /> */}
              {/* <AgGridColumn
                field="session_id"
                headerName="Schedule"
                filter={false}
                sortable={false}
                cellRenderer="AddToScheduleRenderer"
                width="75"
              /> */}
              {/* <AgGridColumn field="session_id" filter={false} valueFormatter={this.currencyFormatter} /> */}
            </AgGridReact>
          </div>
        </div>
      );
    };

    var filterParams = {
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[0]) - 1,
          Number(dateParts[1])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    };
    return (
      <React.Fragment>
        <SessionGrid />
        <BlankSessionRelatedHtml/>
        <BlankSpeakerRelatedHtml/>
      </React.Fragment>
    );
  }
}
export default SessionListGrid;
