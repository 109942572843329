import React, { Component } from 'react';
import BottomNav from './BottomNav'

import Axios from 'axios';
import {Link} from 'react-router-dom';

import Slider from "react-slick";


class ConferenceCenter extends Component {
    constructor(){
        super();
        this.state={
            sponsersImgList:[],
            mediaPartnerList:[],
            producedByList:[],
            sectionLoader:true,
            liveVideoUrl:[],
            eventListData:[],
            eventListDataNotFound:false,
            liveVideoUrlNotFound:"",
            sessionId:"",
            pageLoader:true,
            infoBoxModal:true,
        }
    }
    infoBoxModalHandlar = (e) => {
        e.preventDefault();
        this.setState({infoBoxModal:!this.state.infoBoxModal})
    }
    componentDidMount(){
          
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?eventId=${localStorage.getItem('eventId')}&type=3`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, mediaPartnerList:res.data.mediaPartner, producedByList:res.data.producedBy,sectionLoader:false});
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({sectionLoader:false});
            }

            
           
        })
        .catch(err => {
            console.log(err);
        });
            
        Axios.get(`${global.config.requestUrl}user/getSessionLiveVideo?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({liveVideoUrl:res.data.data, sessionId:res.data.data[0].session_id, liveVideoUrlNotFound:res.data.errorCode})
            if(res.data.errorCode === 0){
                if(res.data.data[0].live_url.includes('https://success.zoom.us/wc/join/')){
                    document.getElementById('liveconf').innerHTML = '<div class="confWrap">'+res.data.data[0].video_text+' <p><a target="_blank" href ="'+res.data.data[0].live_url+'" > Click here </a></p></div>';
                }
                if( res.data.data[0].live_url.includes('https://teams.microsoft.com/') ){
                    document.getElementById('liveconf').innerHTML = '<div class="confWrap"><p><a class="btn btn-warning" target="_blank" href ="'+res.data.data[0].live_url+'" > Click here to launch Teams meeting </a></p></div>';
                }
            }
            this.setState({pageLoader:false});
        })
        .catch(err => {
            console.log(err);
        });

        Axios.get(`${global.config.requestUrl}user/getLiveEventsList?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {

            if(res.data.errorCode === 0){
                this.setState({eventListData:res.data.data})
            }else{
                this.setState({eventListDataNotFound:false})
            }
            this.setState({pageLoader:false});
        })
        .catch(err => {
            console.log(err);
        });

        

    }

    render() {
        //const sessionId = this.state.sessionId;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
          };
            return (
                <React.Fragment>
               
                    <section className="bg11 signleScreen paddingTop height1100 conference">
                        {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                        <div className="container">
                            <div className="conferenceContainer">
                                <div className="conferenceSchedule">
                                    <h3>Schedule of Live Events</h3>
                                    <div className="scheduleTable">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <td>Session Name</td>
                                                    <td>Date/Time</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                
                                                { 
                                                    this.state.eventListData.map(eventList => (
                                                        <tr>
                                                            <td>{eventList.session_name}</td>
                                                            <td>{eventList.start_date}, <br/>{eventList.start_time}</td>
                                                        </tr>
                                                    ))
                                                }
                                                {
                                                    this.state.eventListDataNotFound
                                                    ?
                                                    <h5>No Data Found</h5>
                                                    :
                                                    null
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="conferenceVideoWrp">
                                    {
                                        this.state.liveVideoUrlNotFound !== 0
                                        ?
                                        <div className="conferenceVideo videoNotFound">
                                            {/* <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" /> */}
                                        </div>
                                        :
                                        <React.Fragment>
                                            
                                            { 
                                                this.state.liveVideoUrl.map(videoLink => (
                                                    <React.Fragment>
                                                        <h3>{videoLink.session_name}</h3>
                                                        {
                                                            this.state.liveUrlNull === null || this.state.liveUrlNull === ""
                                                            ?
                                                            <div className="conferenceVideo videoNotFound">
                                                                <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" />
                                                            </div>
                                                            :
                                                            
                                                            <div className="conferenceVideo" id="liveconf">
                                                                {
                                                                videoLink.live_url.includes('https://success.zoom.us/wc/join/') || videoLink.live_url.includes('https://teams.microsoft.com/')
                                                                ?
                                                                null
                                                                :
                                                                <iframe width="100%" height="315" title="conference video" src={`${videoLink.live_url}?rel=0`} key={videoLink.live_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                }
                                                        </div>
                                                        }
                                                        
                                                    </React.Fragment>
                                                ))
                                            }
                                        
                                        </React.Fragment>

                                    }
                                </div>
                            </div>
                        </div>
                        {
                            /* this.state.infoBoxModal
                            ?
                            <div className="midModal">
                                <div className="midModalArea width800">
                                    <div className="modalPopUpClose" onClick={this.infoBoxModalHandlar}><i className="fa fa-times"></i></div>
                                    <h3 className="modalHeading">Viewing Live Sessions with Microsoft Teams </h3>
                                    <div className="modalContent p-4">
                                        <p>The first time you enter click on the link in the session screen and a new window will open. If you already have the Microsoft Teams app installed on your computer and are logged in choose “Launch it Now” and go right to the session.</p>
                                        <p>If you would like to view without installing the app choose watch on web, and join the meeting anonymously.</p>
                                        <p>If you would like to install the Teams app, you will need to log out of the virtual conference and install the app using the link on the log in page.</p>
                                    </div>
                                </div>
                            </div>
                            :
                            null */
                        }
                        {/* <div className="stickyBtn">
                            <button  onClick={this.infoBoxModalHandlar}>
                                <i className="fa fa-user mr-2"></i>
                                <span>Live Session Help</span>
                            </button>
                            <a href="http://minnesotasafetycouncil.org/conf/2020%20Conference%20CEU%20Form.docx" target="_blank">
                                <i className="fa fa-edit mr-2"></i>
                                <span>CEU Request Form</span>
                            </a>
                        </div> */}
                        <div className="bottomScrollerSponsers">
                            <div className="bottomScrollerSponsersBx">
                            {
                                this.state.sectionLoader
                                ?
                                (
                                    <div className="sectionLoader">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                )
                                :
                                null
                            }

                            <Slider {...settings}>
                            { 
                                this.state.sponsersImgList.map(sponsers => (
                                    <div className="" key={sponsers.exhibitor_id}>
                                        <Link to={`/ihca-als2021/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                            <img src={sponsers.logo} alt=""/>
                                        </Link>
                                    </div>
                                    
                                ))
                                
                            }            
                            </Slider>

                             
                            </div>
                        </div>
                        <div /* className="bottomCenter" */>
                            <div className="rightBarButton">
                                <Link to="/ihca-als2021/attendee/technicalSessions">
                                    <span>View All Sessions</span>
                                    <i className="fa fa-eye"></i>
                                </Link>
                                {
                                    this.state.sessionId !=='' 
                                    ?
                                    <Link to={`/ihca-als2021/attendee/conferenceCenterDetails/${this.state.sessionId}`}>
                                    <span>Enter Live Session</span>
                                    <i className="fa fa-long-arrow-right"></i>
                                    </Link>
                                    :
                                    null

                                } 
                            </div>
                        </div>
                        <BottomNav />
                        
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default ConferenceCenter;
