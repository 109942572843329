import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from './PrivateRoute'

import Homepage from './Homepage'
import Header from './Components/csda/Header';
import Login from './Components/csda/Login';
import SingleSignOn from './Components/csda/SingleSignOn';
import LoginSplash from './Components/csda/LoginSplash';
import ForgotPassword from './Components/csda/ForgotPassword';
import ResetPassword from './Components/csda/ResetPassword';
import ExhibitorDetails from './Components/csda/Exhibitor/ExhibitorDetails';
import ExhibitorBooth from './Components/csda/Exhibitor/ExhibitorBooth';

import Dashboard from './Components/csda/Exhibitor/Dashboard';
import Lobby from './Components/csda/Attendee/Lobby';
import ExhibitHall from './Components/csda/Attendee/ExhibitHall';
import NetworkingLounge from './Components/csda/Attendee/NetworkingLounge';
import ConferenceCenter from './Components/csda/Attendee/ConferenceCenter';
import ConferenceCenterPage from './Components/csda/Attendee/ConferenceCenterPage';
import TechnicalSessions from './Components/csda/Attendee/TechnicalSessions';
import ExhibitList from './Components/csda/Attendee/ExhibitList';

import HelpDesk from './Components/csda/Attendee/HelpDesk';
import GoldenTicket from './Components/csda/Attendee/GoldenTicket';
import AttendeeExhibitorBooth from './Components/csda/Attendee/AttendeeExhibitorBooth';
import NotFound from './Components/csda/NotFound';

import DashboardSpeaker from './Components/csda/Speaker/Dashboard';
import ProfileDetails from './Components/csda/Speaker/ProfileDetails';
import SpeakerSession from './Components/csda/Speaker/SpeakerSession';
import SessionAttendees from './Components/csda/Speaker/SessionAtendees';

import ChatPage from "./TmChat/csda/chat/ChatPage";
import VideoCall from "./TmChat/csda/video/VideoCall";
import ConferenceMeeting from "./TmChat/csda/videoConference/conferenceMeeting";
import Meetings from "./TmChat/csda/videoConference/meetings";
import UserProfile from './Components/csda/UserProfile';

import WorkProgress from './Components/msc/WorkProgress';
import speakerWebinar from "./TmChat/csda/speakerWebinar/speakerWebinar";

import Disabled from './Components/Disabled';
import PosterMenu from './Components/csda/Attendee/PosterMenu';
import PosterAttendeeView from './Components/csda/Attendee/PosterAttendeeView';
import LogoutSuccess from './Components/csda/LogoutSuccess';


/* import test from './Components/csda/Exhibitor/test'; */

class Csda extends Component {
constructor(){
  super();

  this.state={
    homePage:""
  }
}
componentDidMount(){
  //console.log(window.location.href)
  
}

  render() {


      return (
      <Router>
          <div className="App">
            <Header/>
            <Switch>
                <Route path="/csda/login/:loginId" exact component={Login} />
                <Route path="/csda/logoutSuccess/:eventId/:emailId/:tokenpass" exact component={LogoutSuccess} />
                <Route path="/csda/single-signon/:eventId/:emailId/:tokenpass" exact component={SingleSignOn} />
                <PrivateRoute path="/csda/loginSplash" exact component={LoginSplash} />
                <Route path="/csda/forgotPassword" component={ForgotPassword} />
                <Route path="/csda/resetPassword" component={ResetPassword} />
                <Route path="/csda/disabled" component={Disabled} />
                <PrivateRoute path="/csda/chat" exact component={ChatPage} />
                <PrivateRoute path="/csda/video-call" exact component={VideoCall} />
                <PrivateRoute path="/csda/video-conference" exact component={Meetings} />
                <PrivateRoute path="/csda/conference-meeting/:id" exact component={ConferenceMeeting} />
                <PrivateRoute path="/csda/exhibitor/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/csda/exhibitor/profile" exact component={ExhibitorDetails} />
                <PrivateRoute path="/csda/exhibitor/booth" component={ExhibitorBooth} />
                <PrivateRoute path="/csda/attendee/lobby" component={Lobby} />
                <PrivateRoute path="/csda/attendee/exhibitHall" component={ExhibitHall} />
                <PrivateRoute path="/csda/attendee/networkingLounge" component={NetworkingLounge} />
                <PrivateRoute path="/csda/attendee/conferenceCenter" component={ConferenceCenter} />
                <PrivateRoute path="/csda/attendee/conferenceCenterDetails/:sessionId" component={ConferenceCenterPage} />
                <PrivateRoute path="/csda/attendee/technicalSessions" component={TechnicalSessions} />
                <PrivateRoute path="/csda/attendee/exhibitList" component={ExhibitList} />
                <PrivateRoute path="/csda/attendee/helpDesk" component={HelpDesk} />
                <PrivateRoute path="/csda/attendee/goldenTicket" component={GoldenTicket} />
                <PrivateRoute path="/csda/attendee/exhibitorBooth/:exhibitorId" component={AttendeeExhibitorBooth} />
                <PrivateRoute path="/csda/attendee/allPosters" exact component={PosterMenu} />
                <PrivateRoute path="/csda/attendee/poster/:sessionId" exact component={PosterAttendeeView} />
                <PrivateRoute path="/csda/speaker/dashboard" exact component={DashboardSpeaker} />
                <PrivateRoute path="/csda/speaker/profile" exact component={ProfileDetails} />
                <PrivateRoute path="/csda/speaker/attendees" exact component={SessionAttendees} />
                <PrivateRoute path="/csda/user/profile" exact component={UserProfile} />
                <Route path="/csda/workProgress" exact component={WorkProgress} />
                <PrivateRoute path="/csda/speaker/session/:sessionId" exact component={speakerWebinar} />
                <Route path="*" component={NotFound} />
              </Switch>
         
          </div>
      </Router>
    );
  }  
}

export default Csda;
