import React, { Component } from 'react';

import jwt_decode from 'jwt-decode'
import LeftNavBar from './LeftNavBar'
import Axios from 'axios';
import {Link} from "react-router-dom";
//import ChatIcon from '../../../TmChat/fna/chat/ChatIcon'

class DashboardSpeaker extends Component {
    constructor(){
        super();
        this.state={
            visitorsTable:true,
            leadsTable:false,
            totalTable:false,
            tableListDetailsModal:false,
            email : "",
            pageLoader:true,
            sectionLoader:true,
            showChatIcon:false,
            sessionLists:[],

            resetSuccess:"",
            resetMessage:"",
            watchDemoModal:false,
        }
    }
    watchDemoModalHandeler = () => {
        this.setState({watchDemoModal:!this.state.watchDemoModal})
    }
    componentDidMount(){
        if(global.config.speakerDashboard === 0 ){
            this.props.history.push(`/fna/disabled/`)
        }
        this.setState({sectionLoader:true});
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = '';
            
            if(rolesArr.indexOf('-6') !== -1){
                currentRole = -6
                localStorage.setItem('currentRole',currentRole);
            }else{
                this.props.history.push('/notfound');
            }
             
        }

        const token = localStorage.exhibitorToken;
        const decoded = jwt_decode(token);
        const details = {
            username: decoded.email,
            eventId:localStorage.getItem('eventId'),
            role:currentRole.toString()
        }

        Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            .then(res => {
               // console.log(res.data.data[0].profilePic);
                 if(res.data.errorCode === 0){
                    localStorage.setItem('speakerProfilePic',res.data.data[0].profilePic)
                    localStorage.setItem('useraccname',res.data.data[0].name)
                    localStorage.setItem('userId',res.data.data[0].speakerId)
                    localStorage.setItem('company',res.data.data[0].company)
                    if(res.data.data[0].profilePic !==''){
                        document.getElementById("profile_img").src = res.data.data[0].profilePic;
                        document.getElementById("left_bar_profile_img").src = res.data.data[0].profilePic;
                    }else{
                        document.getElementById("profile_img").src = '/images/placeholder-profile.jpg';
                        document.getElementById("left_bar_profile_img").src = '/images/placeholder-profile.jpg';  
                    }
                    document.getElementById("useraccname").innerHTML = res.data.data[0].name;
                    this.getSessionList();

                    
                }else{
                    
                }
                this.setState({pageLoader:false,showChatIcon:true});
                
            })
            .catch(err => {
                console.log(err);
            });

            
        
    }

    getSessionList = () => {
        Axios.get(`${global.config.requestUrl}speaker/getSpeakerSessions?eventId=${localStorage.getItem('eventId')}&speakerId=${localStorage.getItem('userId')}`,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            
            .then(res => {
                console.log(res.data.data, "sessionID")
                if(res.data.errorCode === 0){
                    this.setState({sessionLists:res.data.data})  
                }
                this.setState({sectionLoader:false})
            })
            .catch(err => {
                console.log(err);
            });
    }
    render() {
        return (
            <React.Fragment>
                <section className="bg7 signleScreen">
                    <div className="dashboardWrapper">
                        <LeftNavBar />
                        <div className="rightContentAera  d-flex">
                        <div className="dashboardDataTable">
                            <div className="mainDashboardHeading">
                                <h3>Speaker Main Dashboard</h3>
                            </div>
                            
                            <div className="mainDashboardLeadsData flexBxDashabord">
                                {/* <ul className="speakerDashboardCard">
                                    <li>
                                        <div className="dashboardCard visitorCard" onClick={this.visitorsTableHandler}>
                                            <div className="dashboardCardIco">
                                                <img src={process.env.PUBLIC_URL + '/images/dashboard-visitor.png'} alt="" />
                                            </div>
                                            <div className="dashboardCardContent">
                                                <h2>20</h2>
                                                <h5>Used Space</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dashboardCard leadsCard"  onClick={this.leadsTableHandler}>
                                            <div className="dashboardCardIco">
                                                <img src={process.env.PUBLIC_URL + '/images/dashboard-leads.png'} alt="" />
                                            </div>
                                            <div className="dashboardCardContent">
                                                <h2>09</h2>
                                                <h5>Used Space</h5>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dashboardCard allCard" onClick={this.totalTableHandler}>
                                            <div className="dashboardCardIco">
                                                <img src={process.env.PUBLIC_URL + '/images/dashboard-all.png'} alt="" />
                                            </div>
                                            <div className="dashboardCardContent">
                                                <h2>29</h2>
                                                <h5>Used Space</h5>
                                            </div>
                                        </div>
                                    </li>
                                </ul> */}
                                {
                                    this.state.resetSuccess === true
                                    ?
                                    <div className="alert alert-success">{this.state.resetMessage}</div>
                                    :
                                    null
                                }
                                {
                                    this.state.resetSuccess === false
                                    ?
                                    <div className="alert alert-danger">{this.state.resetMessage}</div>
                                    :
                                    null
                                }
                                <div className="conferenceSchedule">
                                    <h3>Session List</h3>
                                    
                                    <div className="scheduleTable">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <td colSpan="2">Session Name</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                     this.state.sectionLoader
                                                     ?
                                                          <tr>
                                                            <td colSpan="2">
                                                                <div className="sectionLoader text-center mt-3 mb-3">
                                                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                                </div>
                                                            </td>
                                                          </tr>
                                                          :
                                                          
                                                            this.state.sessionLists.length === 0
                                                            ?
                                                            <tr>
                                                                <td colSpan="2"><h4>No data found</h4></td>
                                                            </tr>
                                                            :
                                                            <React.Fragment>
                                                                { 
                                                                    this.state.sessionLists.map(sessionList => (
                                                                        <tr key={sessionList.session_id}>
                                                                            <td>{sessionList.session_name}</td>
                                                                            <td className="text-right">
                                                                                <Link to= {`/fna/speaker/session/${sessionList.session_id}`} target="_blank">
                                                                                 <button className="btn btn-primary">Starts <i className="fa fa-caret-right"></i></button>
                                                                                </Link>
                                                                                <button className="btn btn-warning ml-3" onClick={ () => {
                                                                                    this.setState({pageLoader:true})
                                                                                    Axios.get(`${global.config.requestUrl}troop-messenger/reset-session?sessionId=${sessionList.session_id}&eventId=${localStorage.getItem('eventId')}&speakerId=${localStorage.getItem('userId')}`,
                                                                                        {headers: { "auth-token": localStorage.exhibitorToken }}
                                                                                    )
                                                                                    .then(res => {            
                                                                                        this.setState({resetSuccess:res.data.success, resetMessage:res.data.message, pageLoader:false});
                                                                                        
                                                                                    })
                                                                                    .catch(err => {
                                                                                        console.log(err);
                                                                                    });
                                                                                }}>Reset <i class="fa fa-refresh"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                    
                                                                }
                                                            </React.Fragment>
                                                        
                                                        
                                            }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                            <div className="warchDemo mt-4" onClick={this.watchDemoModalHandeler}><i class="fa fa-youtube mr-1"></i> Watch Demo</div>
                        </div>
                            {
                                 /* this.state.showChatIcon
                                 ?
                                 <div className="chatIcon">
                                    <Link to="/chat" target='_blank'>
                                        <ChatIcon/>
                                         <img src={process.env.PUBLIC_URL + '/images/chat-ico.png'} alt="" />
                                    </Link>
                                 </div>
                                 :
                                 null */
                             }
                            
                        </div>
                    </div>
                </section>
                {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                {/*=============watch-demo-modal==============*/}
                {
                    this.state.watchDemoModal 
                    ? 
                    <div className="modalPopUp">
                        <div className="modalPopUpInr">
                            <div className="modalPopUpClose" onClick={this.watchDemoModalHandeler}>
                                <i className="fa fa-times"></i>
                            </div>
                            <div className="modalVideoWrapper">
                                <iframe width="560" height="315" title="Watch Demo" src="https://www.youtube.com/embed/sBETWoZagXA?rel=0&autoplay=1" frameborder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                            </div>
                            
                        </div>
                    </div> 
                    : null
                }
            </React.Fragment>
        )
    }
}
export default DashboardSpeaker;