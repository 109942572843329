function addUser(user,connection){
    return new Promise((resolve => {
            console.log(user,"user");
        connection.addUser(user).then(response=>{
            console.log(response,"cattleCallResponse");
            return resolve(response);
        }).catch(err=>{
               console.log(err,"errr");
            console.log(err);
        });
    }))

}
 const cattleCallChat={addUser:addUser};
export default  cattleCallChat;