import React, { Component } from 'react';

import {storage} from '../../../Firebase';
import Axios from 'axios';

class AddSessionOrPosterVideo extends Component {
    constructor(props){
        super(props);

        this.state = {
            pageLoader : true,
            sessionId : this.props.sessionId,
            eventId : localStorage.getItem('eventId'),
            videolink:'',
            videoSelectionSuccess:'',
            videoSelectionError:'',
            videoFileError:'',
            videoFile:null,
            videokey:'',
            alreadyHaveVideo:1, // 1 means insert as no video and 2 means yes video already there.
            shareVideoLink:'',
            shareVideoLinkError:''
        }
    }
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
      componentDidMount (){
          this.getVideoData();
      }

      getVideoData = () => {
        Axios.get(`${global.config.requestUrl}speaker/getPosterOrSessionVideo?sessionId=${this.props.sessionId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {

     if(!res.data.errorCode){
        this.setState({
            videolink:res.data.data[0].video_link,
            alreadyHaveVideo:2,// video already there.
            pageLoader: false
        });
      }else{
        this.setState({pageLoader: false});
      }
       
        
    })
    .catch(err => {
        console.log(err);
    });
      }

    getFileExtFromUrl =  (linkurl,medialinkname) => {
        var url = linkurl;
        var mainfile = url.split('/').pop().split('?')[0];
        var filetype = mainfile.split('.');
        if(filetype[1]){
            return filetype[1];
        }else if(url.includes('iframe')){
            var src = this.getSrcFromEmbedLink(url);
            if(src.includes('https://www.youtube.com/embed') || src.includes('https://player.vimeo.com/video/')){
                this.setState({[medialinkname] : src})
                return 'validevideolink';
            }else{
                return 'invalidembed';
            }
        }else if(url.includes('https://www.youtube.com/embed') || url.includes('youtu.be') || url.includes('https://www.youtube.com/watch') || url.includes('https://player.vimeo.com/video/') || url.includes('https://vimeo.com/') || url.includes("https://kaltura.uconn.edu/") || url.includes("https://uconn-cmr.webex.com/")){
            return 'validevideolink';
        }else{
            return '';
        }
        
    }

    getSrcFromEmbedLink = (url) => {
        var  re = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/i, // match src='a' OR src="a" OR src=a
            res = url.match(re),
            src = res[1]||res[2]||res[3];
            return src;
    }


    mediaValidateForm = (file,linkurl,fileerr,medialinkname) => {
       
       
        if(file === null && linkurl !== "" ){
            var mediatype =  this.getFileExtFromUrl(linkurl,medialinkname);  // Here getting and setting type of the media
            
            if(mediatype !==''){
                if(mediatype === 'png' || mediatype === 'jpg'){
                    return false;
                }else if(mediatype === 'mp4' || mediatype === 'validevideolink'){
                    return true;
                }else if(mediatype === 'invalidembed'){
                    this.setState({[fileerr] : "Invalid embed link!!!"})
                    return false;
                }else{
                    this.setState({[fileerr] : "Invalid media URL!!!"})
                    return false;
                }
            }else{
                this.setState({[fileerr] : "Invalid media URL!!!"})
                return false;
            }
           
        }
        if(file === null && linkurl === "" ){
            this.setState({[fileerr] : "Please Choose File"})
            return false
        }
        if(file !== null && linkurl !== "" ){
            this.setState({[fileerr] : "You only allowed to add a file or url at a time. "})
            return false
        }
        if(file !== null && linkurl === "" ){
            
            return true;
        }
      }

    /* media validator code ends */


    videoFirebaseUploader = (e) => { 
        
        e.preventDefault();
        if(this.mediaValidateForm(this.state.videoFile,this.state.shareVideoLink,'videoSelectionError','shareVideoLink')){
            
            this.setState({pageLoader:true})

            if(this.state.videoFile !== null){
                const sessionId = this.props.sessionId;
                const {videoFile, eventId} = this.state;
                // Create a storage reference from our storage service
                var storageRef = storage.ref();
                const uploadTask = storageRef.child(`videos/${eventId}/${sessionId}/${videoFile.name}`).put(videoFile);
                uploadTask.on('state_changed', 
                (snapshot) => {
                    //progress func
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    this.setState({progress})
                    // console.log(progress)
                    if(progress !== 100){
                    this.setState({pageLoader:true})
                    }
                }, 
                (error) => {
                    //error func
                    console.log(error)
                }, 
                () => {
                    //complete func  
                    storage.ref(`videos/${eventId}/${sessionId}`).child(videoFile.name).getDownloadURL().then(url=>{
                        if(url){

                            this.setState({
                                videolink : url,
                            }, () => {
                                this.videoSubmit();
                            });
                            
                        }
                    })
                });
            } else{
                this.videoSubmit();
            }

        }
    }  

    videoSubmit = () => {
        
           var url = '';
           if(this.state.shareVideoLink !== ''){
            if(this.state.shareVideoLink.includes('<iframe')) 
               url = this.getSrcFromEmbedLink(this.state.shareVideoLink).toString();
            else
               url = this.state.shareVideoLink.toString();
           }else{
            url = this.state.videolink.toString();
           }
      
            const videoData = {
                accountId:localStorage.getItem("accountId"),
                eventId: localStorage.getItem("eventId"),
                sessionId: this.props.sessionId,
                url: url,
                isInsert:this.state.alreadyHaveVideo
              };
       

        

        Axios.post(
            `${global.config.requestUrl}speaker/updateSessionOrPosterVideo`,
            videoData,
            {
              headers: {
                "auth-token": localStorage.getItem("exhibitorToken"),
              },
            }
          )
            .then((res) => {
              if (res.data.errorCode) {
                //it means error from server
                this.setState({
                    videoSelectionError: res.data.errorMessage,
                    videoSelectionSuccess: "",
                    pageLoader: false,
                });
              } else {
                this.setState({
                    videoSelectionError: "",
                    videoSelectionSuccess: res.data.errorMessage,
                    shareVideoLink: "",
                    pageLoader: false,
                });
                this.getVideoData();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        
    }

      videoChange = (e) => {
        if(e.target.files[0]){
            const videoFile = e.target.files[0];
            var size = videoFile.size/1024/1024;
            if(size > 50){
                let randomString = Math.random().toString(36);
                this.setState({
                    videokey :randomString,
                    videoFile : null,
                    videoFileError : "Max upload  size is 50MB"
                })
            }else{
                this.setState({
                    videoFile : videoFile,
                    videoFileError : ""
                })
               
            }
        }
    }

    render() {
     
        return (
            <React.Fragment>
        <div className="modalPopUpFormBx">
                                <div className="infoMsg">
                                    <p>Add the video of your presentation.   Click “Browse” to select a file, or paste embed video code from Youtube or Vimeo. Then click “submit” to save. </p>
                                    <p>Formats include:</p>
                                    <p>Youtube, Vimeo Link (or link to MP4 file), .mp4.  Maximum size file for mp4 upload is 50MB</p>
                                </div>
                                {
                                  this.state.videolink !== '' 
                                  ?
                                  <div className="uploadLink">
                                    <a href={this.state.videolink} target="_blank" without rel="noopener noreferrer"><i className="fa fa-picture-o"></i> See uploaded media</a>
                                  </div>
                                  :
                                  null
                                }
                                <h4 className="mb-3">Add/Edit Session/Poster Video</h4>
                                    <div className="error">{this.state.videoSelectionError}</div>
                                    <div className="success">{this.state.videoSelectionSuccess}</div>
                                    <form onSubmit={this.videoFirebaseUploader}>
                                        <div className="form-group">
                                            <label>Media</label>
                                            <input type="file" name="videoFile" className="form-control" accept="video/mp4,video/x-m4v,video/*" onChange={this.videoChange} key={this.state.videokey || '' }/>
                                            <div className="errorMessage">{this.state.videoFileError}</div>
                                            <span className="d-block mt-2 mb-2">or</span>
                                            <input type="text" name="shareVideoLink" value={this.state.shareVideoLink} className="form-control" placeholder="Share Video Link..." onChange={this.onChange}/>
                                            <div className="errorMessage">{this.state.shareVideoLinkError}</div>
                                        </div>
                                        <div className="mt-3">
                                            <button type="submit">Submit</button>
                                        </div>
                                    </form>

                                    {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                                
                            </div>
                            </React.Fragment>
        )

    }


    
}
export default AddSessionOrPosterVideo;