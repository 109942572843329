import React,{useState, useEffect} from 'react';
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import jwt_decode from 'jwt-decode';
import SessionAllDetails from './SessionDetails';



const SessionsList = () => {
    const history = useHistory();
    const [SessionsList, setSessionsList] = useState([]);
    const [DatesList, setDatesList] = useState([]);
    const [DropdownSelected, setDropdownSelected] = useState(['ALL']);
    const [SessionDetailsModal, setSessionDetailsModal] = useState(false);
    const [SessionDetailsId, setSessionDetailsId] = useState([]);
    const [SessionUniqueStartTime, setSessionUniqueStartTime] = useState([]);
    const [alreadyAddedSession, setAlreadyAddedSession] = useState([]);
    const [PageLoader, setPageLoader] = useState(false);


    

    useEffect(() => {
        getDateList();
        //getAllSessionList();
        getAlreadyAddedSessionsInSchedule();
    }, []);

    // useEffect(() => {
    //     if (helpDeskModal) getHelpDeskData();
    //   }, [helpDeskModal]);
    //setAlreadyAddedSession(JSON.parse(localStorage.getItem('addedSession')));
    const getDateList = () => {
        
        /* date list */
        Axios.get(`${global.config.requestUrl}user/getSessionDates?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )        
        .then(res => {
            if(res.data.errorCode === 0){
                setDatesList(res.data.data)
                getAllSessionList(res.data.data[0]['dates'])
                setDropdownSelected(res.data.data[0]['dates'])
            }
            
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getAllSessionList = (filterDate) => {
        //console.log(DatesList);
        setPageLoader(true)
        const postdata = { eventId: localStorage.getItem("eventId"),searchByDate: filterDate };
        Axios.post( `${global.config.requestUrl}getTechnicalSessions`, postdata)
        .then((res) => {
            if (!res.data.errorCode) {
            const arrayUniqueByStartTime = [...new Map(res.data.data.map(item =>
                [item['start_time'], item])).values()];
            setSessionUniqueStartTime(arrayUniqueByStartTime)
            console.log(res.data.data)
            setSessionsList(res.data.data)
            }else{
            setSessionsList([]);
            }
            setPageLoader(false)
        })
        .catch((err) => {
            console.log(err);
        });
    }
    const updateScheduleData = (e) => {
        getAllSessionList(e.target.value)
        setDropdownSelected(e.target.value)
    };

    //Handle Session Detail modal
    const sessionDetailModalHandler = (sessid) => {
        if(sessid){
            localStorage.setItem('modalSessionId',sessid);
        }        
        setSessionDetailsModal(!SessionDetailsModal);
    };

    const getAlreadyAddedSessionsInSchedule = () => {
        Axios.get(
          `${
            global.config.requestUrl
          }user/getSessionSchedules?eventId=${localStorage.getItem(
            "eventId"
          )}&memberId=${localStorage.getItem("userId")}`,
          { headers: { "auth-token": localStorage.exhibitorToken } }
        )
    
          .then((res) => {
            var scheduleInList = [];
            var schduleInListWithSchId = {}; // {} will create an object
            if(!res.data.errorCode){
                res.data.data.forEach((element) => {
                  scheduleInList.push(element.session_id);
                  var name = element.session_id;
                  var val = element.schdeule_id;
                  schduleInListWithSchId[name] = val;
                });
            }            
            localStorage.setItem('addedSession',JSON.stringify(schduleInListWithSchId));
            setAlreadyAddedSession(JSON.parse(localStorage.getItem('addedSession')));
          })
          .catch((err) => {
            console.log(err);
          });
      };

    const addToSessionSchedule = (session) => {
        setPageLoader(true)
        var sess_id = session.session_id
        var sess_name = session.session_name
        var sess_startdate = session.start_date
        var sess_starttime = session.start_time
        
        if(localStorage.getItem('exhibitorToken')){
            const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                alert('Your sesssion expired, Login again to add session in schedule!!!');return;
            }
        }
        if(sess_startdate === null){
            sess_startdate = '0000-00-00';
        }
        const newBoothContact = {
            sessionId:sess_id,
            eventId:localStorage.getItem('eventId'),
            memberId:localStorage.getItem('userId'),
            role:localStorage.getItem('currentRole'),
            sName: sess_name,
            startDate: sess_startdate,
            startTime:sess_starttime,
            isSession:1
            
        }
        Axios.post(`${global.config.requestUrl}user/addEventToSchedule`, newBoothContact,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode === 0){
                var ButtonIdAdd = 'add_'+sess_id;
                var ButtonIdDel = 'del_'+sess_id;
                document.getElementById(ButtonIdAdd).style.display = "none";
                document.getElementById(ButtonIdDel).style.display = "block";
                const delbtn = document.getElementById(ButtonIdDel)
                //delbtn.dataset.schid = res.data.schedule_id;
                var alreadyAddedSession = JSON.parse(localStorage.getItem('addedSession'));
                alreadyAddedSession[sess_id] = res.data.schedule_id;
                localStorage.setItem('addedSession',JSON.stringify(alreadyAddedSession));
                setAlreadyAddedSession(JSON.parse(localStorage.getItem('addedSession')));
                }
                setPageLoader(false)
            })
            .catch(err => {
                console.log(err);
            }); 
    }

    const deleteSessionSchedule = (sess_id,schedule_id) => {
        setPageLoader(true)
        if(localStorage.getItem('exhibitorToken')){
            const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                alert('Your sesssion expired, Login again to delete session from schedule!!!');return;
            }
        }
        var ButtonIdAdd = 'add_'+sess_id;
        var ButtonIdDel = 'del_'+sess_id;

        if(schedule_id === undefined){
            schedule_id = document.getElementById(ButtonIdDel).dataset.schid;
        }


        //document.getElementById('fordatatable').style.display = "block";
        const postdata = {
            eventId: localStorage.getItem('eventId'),
            scheduleId:schedule_id,
            memberId:localStorage.getItem('userId'),
           }

        Axios.post(`${global.config.requestUrl}user/deleteSchedule`, postdata,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
            .then(res => {
                if(res.data.errorCode === 0){
                    document.getElementById(ButtonIdAdd).style.display = "block";
                    document.getElementById(ButtonIdDel).style.display = "none";
                    var alreadyAddedSession = JSON.parse(localStorage.getItem('addedSession'));
                    delete alreadyAddedSession[sess_id];
                    localStorage.setItem('addedSession',JSON.stringify(alreadyAddedSession));
                    setAlreadyAddedSession(JSON.parse(localStorage.getItem('addedSession')));
                }
                //document.getElementById('fordatatable').style.display = "none";
                setPageLoader(false)
            })
            .catch(err => {
                console.log(err);
            }); 
    }

    
    return (
        
        <div className="p-3 padding-bottom-25">
            <div className="card p-3 border-none box-shadow">
                <div className="pageHeading mb-4">
                    <i className="fa fa-arrow-left backBtn" onClick={() => history.goBack()}></i>
                    <h4 className="text-center">Schedule</h4>
                    {/* {console.log(alreadyAddedSession['25922'])} */}
                </div>
                {
                    PageLoader
                    ?
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                    :
                    null
                }
                <table className="table">                
                    <tbody>
                        <tr>
                            <td colSpan="3">
                                <select value={DropdownSelected} style={{width:'100%'}} onChange={updateScheduleData}>
                                    {/* <option value="">All</option> */}
                                    {
                                        DatesList.map(date => (
                                            <option value={Moment(date.dates).format('YYYY-MM-DD')} key={Math.random()}>{Moment(date.dates).format('dddd, MMMM D yyyy')}</option>
                                        ))
                                    }                        
                                </select>
                            </td>
                        </tr>
                        
                        {SessionUniqueStartTime.length > 0
                        ?
                        SessionUniqueStartTime.map((sessionTime,index) => (
                            <React.Fragment key={index}>
                            <tr>
                                <td colSpan="3" className="bg-info text-white">{Moment(DropdownSelected).format('DD-MM-YYYY')} {sessionTime.start_time}</td>
                            </tr>

                            {SessionsList.map((session, index) => (
                                session.start_time == sessionTime.start_time
                                ?
                                <tr key={session.session_id}>
                                    <td style={{width:'90px'}}>{session.start_time} <br></br>{session.end_time}</td>
                                    <td onClick={() => sessionDetailModalHandler(session.session_id)}>
                                       {session.session_name} {session.track ? (<><span>- </span><b>{session.track}</b></>): ''}
                                        <br></br>
                                        {session.speaker_name}
                                        <br></br>
                                        {session.room}
                                    </td>
                                    <td>
                                    {
                                        alreadyAddedSession[session.session_id] !== undefined
                                        ?
                                        <>
                                        <button onClick={()=>addToSessionSchedule(session)} id={`add_${session.session_id}`} style={{border:'none',display: "none"}} >
                                            <em className="fa fa-plus btn btn-success"></em>
                                        </button>

                                        <button onClick={()=>deleteSessionSchedule(session.session_id,alreadyAddedSession[session.session_id])} style={{border:'none'}} id={`del_${session.session_id}`} className="">
                                            <em className="maroonBtn fa fa-minus btn btn-secondary"></em>
                                        </button>
                                        </>
                                        :
                                        <>
                                        <button style={{ border:'none' }} id={`add_${session.session_id}`} onClick={()=>addToSessionSchedule(session)} className="">
                                            <em className="fa fa-plus btn btn-success"></em>
                                        </button>
                                        <button onClick={()=>deleteSessionSchedule(session.session_id,alreadyAddedSession[session.session_id])} id={`del_${session.session_id}`} style={{ display: "none",border:'none' }}  className="">
                                            <em className="maroonBtn fa fa-minus btn btn-secondary"></em>
                                        </button>
                                        </>
                                    }
                                    </td>
                                </tr>
                                :
                                null
                            ))}                            
                            </React.Fragment>  
                        )

                        )
                        :
                        null
                        }
                        



                 
                    </tbody>                
                </table>
                {
                    SessionDetailsModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                        <div className="modalPopUpClose" onClick={sessionDetailModalHandler}>
                            <i className="fa fa-times"></i>
                        </div>
                        <h3>Session Details</h3>
                        <div className="midContentBx">
                        <SessionAllDetails />
                        </div>
                        </div>
                    </div>
                    :
                    null
                }
            </div>
        </div>        
    )
}
export default SessionsList;