
function sendTextMessage(connection,message){

    let sendMessage={
        message:message.message,
        sender_uid:message.sender_uid,
        conversation_reference_id:message.event_id
    };
       console.log(message,"messageeeee");
    if(!message.group_id){
        Object.assign(sendMessage,{receiver_id:message.receiver_id,
            receiver_uid:message.receiver_uid});
    }else{
        Object.assign(sendMessage,{'is_group':1,receiver_id:message.group_id})
    }
    if(message.is_room)
    {
        sendMessage['is_room']=1;
    }
    if(sendMessage.is_group)
    {
        sendMessage['is_group']=1;
    }else{
        sendMessage['is_group']=0;
    }
      console.log("send MEssage",sendMessage)
    connection.sendTextMessage(sendMessage)
                    .on("tm_error", function (errorMessage){
                        console.log(errorMessage,"sent message");
                    });
}
function chatList(connection,conversationReferenceId) {
    return  new Promise(async function(resolve) {
        await connection.getChatList({'conversation_reference_id':conversationReferenceId})
        .then(function (response){
               console.log(response,conversationReferenceId,"chat Conver");
            return resolve(response);
        })
        .catch(function (e){
              console.log(e,"chat Error");
            return resolve({});
        });
    });
}
function sendAttachment(connection,message) {
    let sendMessage={
        receiver_id:message.receiver_id,
        sender_uid:message.sender_uid,
        receiver_uid:message.receiver_uid,
        attachment:message.attachment,
        conversation_reference_id:message.event_id
    };
    if(message.is_group)
    {
        sendMessage['is_group']=1;
    }else{
        sendMessage['is_group']=0;
    }
    if(message.is_room)
    {
        sendMessage['is_room']=1;
    }
     if(message.group_id) {
         sendMessage['is_group']=1;
         sendMessage['receiver_id']=message.group_id;
        /// Object.assign(sendMessage, {'is_group': 1, receiver_id: message.group_id})
     }
     console.log(sendMessage,"sendMessage");
    connection.sendAttachment(sendMessage)
                    .on("tm_error", function (errorMessage) {
                        console.log(errorMessage);
                    });
}
function getConversation(connection,user){
    return  new Promise(async function(resolve) {
        let parameters={
            is_group: (user.is_group?user.is_group:0),
            is_room: (user.is_room?user.is_room:0),
            entity_id: user.tm_user_id,
            access_token:user.authorization_token,
            conversation_reference_id:user.conversation_reference_id
        };
          if(user.is_room){
              parameters['ignore_message_type']=[25,30]
          }
        if(user.is_group){
            parameters['entity_id']=user.group_id;
        }
            if(user.last_message_id){
                parameters['last_message_id']=user.last_message_id;
            }
        await connection.getConversation(parameters)
            .then(function (response) {
                  if(!user.is_room) {
                      updateMessageStatus(connection, response.messages, function () {
                      });
                  }
                return resolve(response.messages);
            })
            .catch(function (e) {
                     console.log(e,"getConsdsdsdsd");
                return resolve([]);
            });
    });

}
function removeGroupUser(connection,user){
    return  new Promise(async function(resolve) {

              await connection.group().removeUser({
                  group_id: user.group_id,
                  member_id: user.user_id
              })
                  .then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (e) {
                      return resolve('')
                  });

    });

}
function updateMessageStatus(troopConnection,messages,callback) {
    if(messages && messages.length) {
       for (const conversion of messages){
        if (!conversion.is_delivered) {
            troopConnection.messageDelivered({
                message_id: conversion.message_id,
                is_group: conversion.is_group,
            });
        }

        if (!conversion.is_read) {
            troopConnection.messageRead({
                message_id: conversion.message_id,
                is_group: conversion.is_group,
            });
        }
        }
    }
}
function updateGroupUser(connection,user){
    return  new Promise(async function(resolve) {
             let remove=user.remove;
                console.log(remove,user);
             if(remove){
                  if (user.preve_role === connection.groupMemberRole().ADMIN) {
                     await connection.group().removeAdmin({
                         group_id: user.group_id,
                         member_id: user.user_id
                     })
                         .then(function (response) {
                             return resolve(response);
                         })
                         .catch(function (e) {
                                console.log(e,"error");
                             return resolve('')
                         });
                 }
                 else if (user.preve_role === connection.groupMemberRole().MODERATOR) {
                     await connection.group().removeModerator({
                         group_id: user.group_id,
                         member_id: user.user_id
                     })
                         .then(function (response) {
                             return resolve(response);
                         })
                         .catch(function (e) {
                             console.log(e,"error");
                             return resolve('')
                         });
                 }
             }else {
                 if (user.role === connection.groupMemberRole().NORMAL) {
                     await connection.group().makeAdmin({
                         group_id: user.group_id,
                         member_id: user.user_id
                     })
                         .then(function (response) {
                             return resolve(response);
                         })
                         .catch(function (e) {
                             return resolve('')
                         });
                 }
                 else if (user.role === connection.groupMemberRole().ADMIN) {
                     await connection.group().makeAdmin({
                         group_id: user.group_id,
                         member_id: user.user_id
                     })
                         .then(function (response) {
                             return resolve(response);
                         })
                         .catch(function (e) {
                             return resolve('')
                         });
                 }
                 else if (user.role === connection.groupMemberRole().MODERATOR) {
                     await connection.group().makeModerator({
                         group_id: user.group_id,
                         member_id: user.user_id
                     })
                         .then(function (response) {
                             return resolve(response);
                         })
                         .catch(function (e) {
                             return resolve('')
                         });
                 }
             }
    });

}
async function updateGroup(connection,groupInfo){
    await connection.group().updateName({
        group_id: groupInfo.group_id ,
            group_name:groupInfo.title
                })
                .then(function (response) {
                })
                .catch(function (e) {
                });

}
async function updateGroupAvatar(connection,data){
      console.log(data,"dtassasas");
    await connection.group().updateAvatar({
        group_id: data.group_id ,
        group_avatar:data.image
    })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (e) {
            console.log(e);
        });
}
async function sendForkOutMessage(connection,data){
    console.log(data,"messageeeeee");
    connection.sendTextMessage(data,{is_forkout: true}).on("tm_error", function (errorMessage) {
        console.log(errorMessage,"forkoutMessage");
    });
}
const tmChat={
    sendTextMessage:sendTextMessage,
    getConversation:getConversation,
    chatList:chatList,
    sendAttachment:sendAttachment,
    removeGroupUser:removeGroupUser,
    updateGroupUser:updateGroupUser,
    updateGroup:updateGroup,
    updateGroupAvatar:updateGroupAvatar,
    sendForkOutMessage:sendForkOutMessage
}
export default  tmChat