import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import Axios from 'axios';

class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            dropMenu:false,
            quickDropMenu:false,
            allowAsExhibitor:false,
            allowAsSpeaker : false,
            allowAsLobbier : true,
            surveyModal:false,
            iframeLoader:true,
            askToFillSurveyModal:false,
            clickedOnLogout:0,
            timeSpendInSeconds:0,
            isSurveyDone:0,
        }
    }
    logOut = (e) => {
        this.setState({clickedOnLogout:1});
        e.preventDefault();
        if(this.state.isSurveyDone === 0){
            this.setState({askToFillSurveyModal:!this.state.askToFillSurveyModal})
        }else{
            this.proceedLogout();
        }
        
    }

    proceedLogout = () =>{
        this.setState({timeSpendInSeconds: 0});
        var eventId = localStorage.getItem('eventId');
            var eventName = localStorage.getItem('eventName');
            localStorage.clear();
            localStorage.setItem('eventName',eventName);
            this.props.history.push(`/fna/login/${eventId}`)
            this.setState({clickedOnLogout:0});
    }
    dropMenuToggle = e => {
        this.setState({dropMenu:!this.state.dropMenu, quickDropMenu:false})
    }
    quickDropMenuToggle = e => {
        /* e.preventDefault(); */
        this.setState({quickDropMenu:!this.state.quickDropMenu, dropMenu:false})
    }
    surveyModalHandler = () =>{
        this.setState({surveyModal:!this.state.surveyModal, dropMenu:false,askToFillSurveyModal:false,iframeLoader:true})
        this.checkIfSiteSurveyDone();
    }
    askToFillSurveyModalHandler = () =>{
        if(this.state.clickedOnLogout === 1){
            this.proceedLogout();
        }
        this.setState({askToFillSurveyModal:!this.state.askToFillSurveyModal})
        
        
    }
    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }
    componentDidMount(){
            
          
    }

    componentDidUpdate(prevProps, prevState) {
        if (localStorage.getItem('companyContactUrl')) {
            localStorage.getItem('companyContactUrl')
        }
    }

    checkIfSiteSurveyDone = () =>{
        Axios.get(`${global.config.requestUrl}user/checkEventSurveyExist?eventId=${localStorage.getItem('eventId')}&regId=${localStorage.getItem('userId')}&userId=${localStorage.getItem('accountId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode){
                this.setState({isSurveyDone:0});
            }else{
                this.setState({isSurveyDone:1});
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
    // componentWillReceiveProps(props) {
      
    //     if(localStorage.getItem('roles')){
    //         var rolesArr = localStorage.getItem('roles').split(",");
    //         if(rolesArr.indexOf('-1') !== -1){
    //           this.setState({allowAsExhibitor:true});
    //         }
    //         if(rolesArr.indexOf('-6') !== -1){
    //           this.setState({allowAsSpeaker:true});
    //         } 
    //         // if(rolesArr.indexOf('-7') !== -1){
    //         //   this.setState({allowAsExhibitor:true});
    //         // } 
    //     }

    //   }
    onMouseOut = (event) =>{
        // If the mouse is near the top of the window, show the popup
        // Also, do NOT trigger when hovering or clicking on selects
        if (
          event.clientY < 50 &&
          event.relatedTarget === null &&
          event.target.nodeName.toLowerCase() !== "select" &&
          localStorage.getItem("exhibitorToken") !== null
          
        ) {
          // Remove this event listener
          document.removeEventListener("mouseout", this.onMouseOut);
      
          // Show the popup
          //document.getElementById("popup").style.display = "block";
          this.setState({askToFillSurveyModal : true})
        }
      }
    render() {
        document.addEventListener("mouseout", this.onMouseOut);
        var rolesArr = [];
        if(localStorage.getItem('roles')){
             rolesArr = localStorage.getItem('roles').split(",");
             var pImg = process.env.PUBLIC_URL + '/images/placeholder-profile.jpg';
             var editProfileUrl = window.location.pathname;

            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null ))
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-10' && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null)
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-6' && localStorage.getItem('speakerProfilePic') !== '' && localStorage.getItem('speakerProfilePic') !== null)
                pImg = localStorage.getItem('speakerProfilePic');
            else if(localStorage.getItem('currentRole') === '-1' && localStorage.getItem('companyContactUrl') !== '' && localStorage.getItem('companyContactUrl') !== null)
                pImg = localStorage.getItem('companyContactUrl');

            
            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 ) || localStorage.getItem('currentRole') === '-10')
                editProfileUrl = '/fna/user/profile';
            else if(localStorage.getItem('currentRole') === '-6')
                editProfileUrl = '/fna/speaker/profile';
            else if(localStorage.getItem('currentRole') === '-1')
                editProfileUrl = '/fna/exhibitor/profile';

            
        }

        




        const userNotLoggedIn = (
            <li className="nav-item ">
                <button onClick={this.dropMenuToggle}>
                    <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                </button>
            </li> 
        );
        const userLoggedIn = (
            <React.Fragment>
                {/* <li className="nav-item quickLinkBtn">
                    <button onClick={this.quickDropMenuToggle}><i className="fa fa-link"></i> Quick Links <span className="fa fa-angle-down ml-2"></span></button>
                    {
                        this.state.quickDropMenu
                        ?
                        <ul className="dropMenu">
                            <li><Link onClick={this.quickDropMenuToggle} to="/fna/attendee/technicalSessions"><i className="fa fa-archive"></i>Search Technical Sessions & Speakers</Link></li>
                            <li><Link onClick={this.quickDropMenuToggle} to="/fna/attendee/exhibitHall?popup=1"><i className="fa fa-desktop"></i> Search Exhibit Hall</Link></li>
                        </ul>
                        :
                        null
                    }
                </li> */}
                {/* <li className="nav-item totalOnline">
                    <span>Total Online: 09</span>
                </li> */}
                <li className="nav-item userProfilePic">
                    {/* <img src={ localStorage.getItem('companyContactUrl') || process.env.PUBLIC_URL + '/images/placeholder-profile.jpg'} alt="" id="profile_img" /> */}
                    <img src={pImg} alt="" id="profile_img" />
                </li> 
                <li className="nav-item">
                    <button onClick={this.dropMenuToggle}>
                        <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                    </button>
                    {
                        this.state.dropMenu 
                        ?
                        <ul className="dropMenu">

                            { 
                                    rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1
                                    ?
                                    (<li onClick={this.dropMenuToggle}><Link to="/fna/exhibitor/dashboard"><i className="fa fa-user"></i> Marketplace Dashboard</Link></li>
                                    ):null
                            }

                            {/* 
                            { 
                                    rolesArr.indexOf('-6') !== -1
                                    ?
                                    (<li onClick={this.dropMenuToggle}><Link to="/fna/speaker/dashboard"><i className="fa fa-user"></i> Speaker Dashboard</Link></li>
                                    ):null
                            }
                            
                            <li onClick={this.dropMenuToggle}><Link to="/fna/attendee/lobby"><i className="fa fa-user"></i> Attendee Lobby</Link></li>
                            {
                                editProfileUrl !== '/fna/loginSplash'
                                ?
                                <li><Link onClick={this.dropMenuToggle} to={editProfileUrl} ><i className="fa fa-user" aria-hidden="true"></i> Edit Profile</Link></li>
                                :
                                null
                            } */}

                            
                            
                            <li><Link onClick={this.dropMenuToggle} to="/fna/attendee/exhibitHall"><i className="fa fa-edit" aria-hidden="true"></i> Enter Marketplace</Link></li>
                            <li><Link onClick={this.surveyModalHandler} ><i className="fa fa-edit" aria-hidden="true"></i> Site Survey</Link></li>
                            <li onClick={this.dropMenuToggle}><Link to="/" onClick={this.logOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link></li>
                        </ul>
                        :
                        null
                    }
                </li>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <header className="pageMainHeader">
                    <nav className="navbar navbar-expand-sm" id="navMenu">
                        <div className="container">
                            <Link className="navbar-brand" to="/fna/login/556" target="_blank">
                                <img src={process.env.PUBLIC_URL + '/images/Heat_Treat_market_place_white.png'} style={{maxWidth:"220px"}} alt="" />
                            </Link>
                            <div id="collapsibleNavbar">
                            <ul className="navbar-nav ml-auto">
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="">Link</a>
                                </li> */}
                                
                                {localStorage.getItem("exhibitorToken") ? userLoggedIn : userNotLoggedIn}
                            </ul>
                            </div>
                        </div>
                    </nav>
                </header>
                {/*=====Session Survey Modal=====*/}
                {
                    this.state.surveyModal 
                    ?
                    <div className="modalPopUp">
                        <div className="modalPopUpInr p-0">
                            <div className="modalPopUpClose" onClick={this.surveyModalHandler}><i className="fa fa-times"></i></div>
                            <h3 className="modalHeading">Survey</h3>
                            <div className="modalContent p-4">
                            {
                            this.state.iframeLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                            }
                               <iframe width="100%" height="500" title="survey" src={`https://www.tslwebreg.com/siteSurvey.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&email=${localStorage.getItem('userName')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe>                              
                            </div>
                        </div>
                    </div> 
                    :
                    null
                }

                {/*=====Ask to fill site survey Modal=====*/}
                {
                    this.state.askToFillSurveyModal && this.state.isSurveyDone === 0 && localStorage.exhibitorToken
                    ?
                    <div className="modalPopUp">
                        <div className="modalPopUpInr p-0">
                            {/* <div className="modalPopUpClose" onClick={this.askToFillSurveyModalHandler}><i className="fa fa-times"></i></div> */}
                            <h3 className="modalHeading">Would You like to Fill Site Experience Survey?</h3>
                            <div className="modalContent p-4">
                            <div className="form-group surveyBtns mb-0">
                              <button className="btn btn-primary" onClick={this.surveyModalHandler} >Sure</button>
                              <button className="btn btn-warning" onClick={this.askToFillSurveyModalHandler}>Remind me Later</button>
                            </div>
                                                            
                            </div>
                        </div>
                    </div> 
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
export default withRouter(Header);