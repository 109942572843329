import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";

import AboutSession from "./AboutSession";
import AboutSpeaker from "./AboutSpeaker";
import SessionSurveyEasy from "./SessionSurveyEasy";

import SessionDocuments from "./SessionDocuments";
import jwt_decode from "jwt-decode";

import MainSessionAllDetails from "./MainSessionAllDetails";

class ConferenceCenterPage extends Component {
  constructor(props) {
    super(props);
    var dateNow = new Date();
    this.state = {
      sponsersImgList: [],
      sessionBannerList: [],
      mediaPartnerList: [],
      producedByList: [],
      sectionLoader: true,
      aboutSessionModal: false,
      aboutSpeakerModal: false,
      sessionDocumentsModal: false,
      liveVideoUrl: [],
      liveVideoUrlNotFound: "",

      liveUrlNull: "",
      ip: "127.0.0.1",
      pageLoader: true,
      upgradeModal: false,
      paidUser: false,
      iframeLoader: true,
      sessionSurveyModal: false,
      timeSpendInSeconds: 0,
      minAttendance: 0, // in seconds

      sessionIdUrl: "",
      mainConferenceClass: "conferenceLinks",

      timeWhenConfPageLoadedInSec: dateNow.getTime() / 1000,
      sessionAllDetailsModal: false,
      alreadySavedOnAutoLogout: 0,
      videoBtnText: "Click To Enter",
    };

    if (localStorage.getItem("roles")) {
      var rolesArr = localStorage.getItem("roles").split(",");
      var currentRole = -10;

      if (rolesArr.length > 2) {
        currentRole = -10;
      }
      if (
        (rolesArr.indexOf("-1") !== -1 || rolesArr.indexOf("-7") !== -1) &&
        rolesArr.indexOf("-6") !== -1 &&
        rolesArr.length < 3
      ) {
        currentRole = -1;
      }
      if (
        rolesArr.indexOf("-1") == -1 &&
        rolesArr.indexOf("-7") == -1 &&
        rolesArr.indexOf("-6") !== -1 &&
        rolesArr.length == 1
      ) {
        currentRole = -6;
      }
      if (
        (rolesArr.indexOf("-1") !== -1 || rolesArr.indexOf("-7") !== -1) &&
        rolesArr.indexOf("-6") == -1 &&
        rolesArr.length == 1
      ) {
        currentRole = -1;
      }
      if (
        rolesArr.indexOf("-1") !== -1 &&
        rolesArr.indexOf("-7") !== -1 &&
        rolesArr.length === 2
      ) {
        currentRole = -1;
      }
      localStorage.setItem("currentRole", currentRole);
    }

    const {
      match: { params },
    } = props;
    this.scannedData = {
      regId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      sessionId: 0,
      accountId: localStorage.getItem("accountId"),
      serialNumber: this.state.ip,
      duration: this.state.timeSpendInSeconds.toString(),
      role: localStorage.getItem("currentRole"),
    };
    this.token = localStorage.getItem("exhibitorToken");
  }

  componentWillUnmount = () => {
    this.insertScannedDataHere();
    console.log(this.state.timeSpendInSeconds);
    // if(this.state.timeSpendInSeconds >= this.state.minAttendance){
    //   this.updateScannedData();
    // }
  };
  sessionLinksMobileToggle = () => {
    this.setState({ mainConferenceClass: "conferenceLinks activeToggle" });
    if (this.state.mainConferenceClass === "conferenceLinks activeToggle") {
      this.setState({ mainConferenceClass: "conferenceLinks" });
    }
  };
  sessionAllDetailsModalHandler = (e) => {
    e.preventDefault();
    this.setState({
      sessionAllDetailsModal: !this.state.sessionAllDetailsModal,
    });
  };

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    window.sessionStorage.removeItem("mat");
    window.sessionStorage.removeItem("tsp");
    // Remove myschedulepopup forcefully
    if (document.getElementById("myschedulepopup"))
      document.getElementById("myschedulepopup").click();
    this.getSessionData(this.props);
    this.saveAuthForSurvey(this.props);
    this.updateGoldenTicketStatus(this.props);
    this.getGeoIp();
    // fetch('https://geoip-db.com/json/')
    //    .then(res => res.json())
    //    .then(json => this.setState({ip: json.IPv4}));
  }

  getGeoIp = async () => {
    Axios.get("https://geolocation-db.com/json/")
      .then((res) => {
        if(res.status == 200){
          this.setState({ ip: res.data.IPv4 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  componentWillReceiveProps(nextProps) {
    localStorage.setItem("conferenceClicked", 0);
    document.getElementById("myschedulepopup").click();
    this.onLoadingFn(nextProps);
  }

  onLoadingFn = (nextProps) => {
    this.setState({ pageLoader: true });
    const details = {
      username: localStorage.getItem("userName"),
      eventId: localStorage.getItem("eventId"),
      role: localStorage.getItem("currentRole"),
    };

    Axios.post(`${global.config.requestUrl}user/getUserDetails`, details, {
      headers: { "auth-token": localStorage.exhibitorToken },
    })
      .then((res) => {
        if (res.data.errorCode === 0) {
          if (
            localStorage.getItem("currentRole") !== "-10" ||
            res.data.data[0].reg_type !== 1442
          ) {
            this.setState({ paidUser: true });
            this.getSessionData(nextProps);
            this.updateGoldenTicketStatus(nextProps);
            this.saveAuthForSurvey(nextProps);
          } else {
            this.setState({ upgradeModal: true });
          }
        }
        this.setState({ pageLoader: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSponsersLogos = () => {
    Axios.get(
      `${
        global.config.requestUrl
      }user/getSponsorsLogos?eventId=${localStorage.getItem("eventId")}&type=3`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {
        if (res.data.errorCode === 0) {
          this.setState({
            sponsersImgList: res.data.data,
            mediaPartnerList: res.data.mediaPartner,
            producedByList: res.data.producedBy,
            sectionLoader: false,
          });
        } else {
          this.setState({
            sponsersImgList: [
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
            ],
          });
          this.setState({ sectionLoader: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  sessionSurveyModalHandler = (e) => {
    e.preventDefault();
    this.setState({
      sessionSurveyModal: !this.state.sessionSurveyModal,
      iframeLoader: true,
    });
    localStorage.setItem("conferenceClicked", 1);
  };
  aboutSessionModalHandler = (e) => {
    e.preventDefault();
    this.setState({ aboutSessionModal: !this.state.aboutSessionModal });
    localStorage.setItem("conferenceClicked", 1);
  };
  aboutSpeakerModalHandler = (e) => {
    e.preventDefault();
    this.setState({ aboutSpeakerModal: !this.state.aboutSpeakerModal });
    localStorage.setItem("conferenceClicked", 1);
  };
  sessionDocumentsModalHandler = (e) => {
    e.preventDefault();
    this.setState({ sessionDocumentsModal: !this.state.sessionDocumentsModal });
    localStorage.setItem("conferenceClicked", 1);
  };
  hideIframeLoader = () => {
    this.setState({ iframeLoader: false });
  };
  upgradePaymentIframeModelHandeler = (e) => {
    e.preventDefault();
    this.setState({
      upgradePaymentIframeModel: !this.state.upgradePaymentIframeModel,
      iframeLoader: true,
    });
    if (this.state.upgradePaymentIframeModel) {
      this.onLoadingFn();
    }
  };
  updateScannedData = () => {
    const {
      match: { params },
    } = this.props;
    const scannedData = {
      regId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      sessionId: params.sessionId,
      accountId: localStorage.getItem("accountId"),
      serialNumber: this.state.ip,
      duration: this.state.timeSpendInSeconds.toString(),
      role: localStorage.getItem("currentRole"),
    };

    //console.log(scannedData);return false;

    Axios.post(`${global.config.requestUrl}user/insertScanData`, scannedData, {
      headers: { "auth-token": localStorage.getItem("exhibitorToken") },
    })
      .then((res) => {
        if (res.data.errorCode) {
          // Error case
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  insertScannedDataWhenAutoLogOut = () => {
    var eventId = localStorage.getItem("eventId");
    var eventName = localStorage.getItem("eventName");
    const {
      match: { params },
    } = this.props;
    this.scannedData.duration = this.state.timeSpendInSeconds.toString();
    this.scannedData.sessionId = params.sessionId;
    Axios.post(
      `${global.config.requestUrl}user/insertScanData`,
      this.scannedData,
      { headers: { "auth-token": this.token } }
    )
      .then((res) => {
        if (res.data.errorCode) {
          // Error case
        } else {
          localStorage.clear();
          window.location.href =
            window.location.protocol +
            "//" +
            window.location.host +
            "/" +
            eventName +
            "/login/" +
            eventId;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  insertScannedDataHere = () => {
    const {
      match: { params },
    } = this.props;
    this.scannedData.duration = this.state.timeSpendInSeconds.toString();
    this.scannedData.sessionId = params.sessionId;
    Axios.post(
      `${global.config.requestUrl}user/insertScanData`,
      this.scannedData,
      { headers: { "auth-token": this.token } }
    )
      .then((res) => {
        if (res.data.errorCode) {
          // Error case
        } else {
          this.setState({ timeSpendInSeconds: 0 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  updateGoldenTicketStatus = (myprop) => {
    this.setState({ pageLoader: true });
    const {
      match: { params },
    } = myprop;
    const ticketStatusData = {
      visitorId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      ticketValue: 0,
      linkPage: "sessionRoom",
      sessionId: params.sessionId,
      exhibitorId: 0,
    };
    Axios.post(
      `${global.config.requestUrl}user/checkGoldenTicketUsers`,
      ticketStatusData,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        if (res.data.errorCode) {
          document.getElementById("goldenTkt").classList.add("greyScale");
          this.setState({ pageLoader: false });
        } else {
          this.setState({ pageLoader: false });
          document.getElementById("goldenTkt").classList.remove("greyScale");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  countDown = () => {
    /** This code will redirect the user to login splash page before token expires */
    const token = localStorage.exhibitorToken;
    const decoded = jwt_decode(token, { complete: true });
    var clkb = this.state.timeWhenConfPageLoadedInSec;
    this.setState({ timeWhenConfPageLoadedInSec: clkb + 1 });
    if (
      decoded.exp - 10 <= this.state.timeWhenConfPageLoadedInSec &&
      this.state.alreadySavedOnAutoLogout === 0
    ) {
      //this.props.history.push(`/vcd/login/590`)
      this.setState(
        {
          alreadySavedOnAutoLogout: 1,
        },
        () => {
          this.insertScannedDataWhenAutoLogOut();
        }
      );
    }
    /** This code will save the scanned data if timespend is equal to the minattendance */
    if (
      this.state.timeSpendInSeconds == this.state.minAttendance &&
      this.state.minAttendance !== 0
    ) {
      //this.updateScannedData();
      this.insertScannedDataHere();
    }
    var clk = this.state.timeSpendInSeconds;
    this.setState({ timeSpendInSeconds: clk + 1 });
  };

  getSessionData = (myprop) => {
    this.setState({ pageLoader: true });
    const {
      match: { params },
    } = myprop;
    Axios.get(
      `${
        global.config.requestUrl
      }user/getSessionVideo?eventId=${localStorage.getItem(
        "eventId"
      )}&sessionId=${params.sessionId}`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {
        this.setState({
          liveVideoUrl: res.data.data,
          liveVideoUrlNotFound: res.data.errorCode,
          liveUrlNull: res.data.data[0].video_url,
          minAttendance: res.data.data[0].min_attendence * 60,
          pageLoader: false,
          sessionIdUrl: res.data.data[0].session_id,
        });
        if (res.data.errorCode === 0) {
          this.setState({});
          setInterval(this.countDown, 1000);
          if (res.data.data[0].sVideoButtonText !== "") {
            this.setState({ videoBtnText: res.data.data[0].sVideoButtonText });
          }

          if (res.data.data[0].video_url !== null) {
            if (
              res.data.data[0].video_url.includes(
                "https://success.zoom.us/wc/join/"
              )
            ) {
              document.getElementById("videoconf").innerHTML =
                res.data.data[0].video_text +
                '<div class="hoverText"><p>Click below to view live webinar.  Password is common2020</p><a target="_blank" href ="' +
                res.data.data[0].video_url +
                '" > ' +
                this.state.videoBtnText +
                " </a></div>";
            }
            if (
              res.data.data[0].video_url.includes(
                "https://event.webinarjam.com/"
              ) ||
              res.data.data[0].video_url.includes(
                "https://global.gotomeeting.com/join/"
              )
            ) {
              document.getElementById("videoconf").innerHTML =
                res.data.data[0].video_text +
                '<div class="hoverText"><a target="_blank" href ="' +
                res.data.data[0].video_url +
                '" > ' +
                this.state.videoBtnText +
                " </a></div>";
            }
          }

          if (res.data.data[0].banner !== "") {
            this.setState({
              sessionBannerList: [
                {
                  logo: res.data.data[0].banner,
                  banner_link: res.data.data[0].banner_link,
                },
              ],
            });
          } else {
            this.setState({
              sessionBannerList: [
                {
                  logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                  banner_link: null,
                },
              ],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ pageLoader: false });
      });
  };

  saveAuthForSurvey = (myprop) => {
    const {
      match: { params },
    } = myprop;
    const authSurveyData = {
      regId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      sessionId: params.sessionId,
      role: localStorage.getItem("currentRole"),
    };

    Axios.post(
      `${global.config.requestUrl}user/insertSessionForSurvey`,
      authSurveyData,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        if (res.data.errorCode) {
          // Error case
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    document.addEventListener("mouseout", this.onMouseOut);
    document
      .getElementById("root")
      .addEventListener("mouseenter", this.onMouseEnter);

    const {
      match: { params },
    } = this.props;
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <React.Fragment>
        <section className="bg11 signleScreen sessionScreen">
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : null}
          <div className="sessions-security-logo">
            <img width={120} src="/images/security22/Security22_Reg_Logo.png" />
          </div>
          <div
            style={{
              margin: "0 auto 10px",
              width: "auto",
              color: "#fff",
              textDecoration: "underline",
              textAlign: 'center'
            }}
          >
            <Link to="/security22/attendee/technicalSessions">
              Back to Sessions
            </Link>
          </div>
          <div className="container wideContainer">
            <div className="conferenceContainer verticleAlignCenter">
              <div className="sessionInforLink">
                <Link
                  className="newbtn2"
                  onClick={this.sessionAllDetailsModalHandler}
                  to="/"
                >
                  Session Details and Handouts
                </Link>
              </div>

              <div className="conferenceVideoWrp">
                {this.state.liveVideoUrlNotFound !== 0 ? (
                  <div className="conferenceVideo videoNotFound">
                    {/* <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" /> */}
                  </div>
                ) : (
                  <React.Fragment>
                    {this.state.liveVideoUrl.map((videoLink) => (
                      <React.Fragment>
                        {this.state.liveVideoUrlNotFound !== 0 ? (
                          <div className="conferenceVideo videoNotFound">
                            {/* <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" /> */}
                          </div>
                        ) : (
                          <React.Fragment>
                            {this.state.liveVideoUrl.map((videoLink) => (
                              <React.Fragment>
                                <h3>
                                  <p
                                    style={{
                                      fontSize: "13px",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    All times are Central Daylight Time
                                  </p>
                                  {videoLink.session_name}
                                </h3>
                                {this.state.liveUrlNull === null ||
                                this.state.liveUrlNull === "" ? (
                                  <div className="conferenceVideo videoNotFound">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/images/page-not-found.png"
                                      }
                                      alt=""
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="conferenceVideo zoomBanner"
                                    id="videoconf"
                                  >
                                    <iframe width="100%" height="100%" src={`${videoLink.video_url}`} key={videoLink.video_url} style={{overflow: "hidden"}} scrolling="no" frameborder="0" allow="autoplay" allowFullScreen></iframe>
                                    {/* <iframe width="100%" height="315" src={`${videoLink.video_url}?rel=0`} key={videoLink.video_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                                    {/* 
                                                                            videoLink.video_url.includes('https://success.zoom.us/wc/join/') ||
                                                                            videoLink.video_url.includes('https://event.webinarjam.com') ||
                                                                            videoLink.video_url.includes('https://global.gotomeeting.com')
                                                                            ?
                                                                            null
                                                                            :
                                                                            <iframe width="100%" height="315" src={`${videoLink.video_url}?rel=0`} key={videoLink.video_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                            */}
                                    {/* <a
                                      className="btn btn-warning"
                                      href={videoLink.video_url}
                                      target="_blank"
                                    > */}
                                      {/* 
                                                                                    videoLink.video_url.includes('https://zoom.us/')
                                                                                    ?
                                                                                    "Click to view replay"
                                                                                    :
                                                                                    "Click to join chat room"
                                                                                */}
                                      {/* {this.state.videoBtnText}
                                    </a> */}
                                  </div>
                                )}
                              </React.Fragment>
                            ))}
                          </React.Fragment>
                        )}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                )}
              </div>
              <div className="handOuts">
                {/* <Link to="/" onClick={this.sessionDocumentsModalHandler} className="newbtn2">Handouts</Link> */}
                <li>
                  <Link
                    onClick={this.sessionSurveyModalHandler}
                    className="newbtn2"
                    to="/"
                  >
                    {" "}
                    Session Evaluations
                  </Link>
                </li>
              </div>
            </div>
          </div>

          {/* <BottomNav /> */}
        </section>
        {/*=====About Session Modal=====*/}
        {this.state.aboutSessionModal ? (
          <div className="midModal">
            <div className="midModalArea">
              <div
                className="modalPopUpClose"
                id="exhibitpopclose"
                onClick={this.aboutSessionModalHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading pt-3 pb-3">About Session</h3>
              <div className="midModalAreaContent">
                <AboutSession passprops={this.props} />
              </div>
            </div>
          </div>
        ) : null}
        {/*=====About Speaker Modal=====*/}
        {this.state.aboutSpeakerModal ? (
          <div className="midModal">
            <div className="midModalArea">
              <div
                className="modalPopUpClose"
                id="exhibitpopclose"
                onClick={this.aboutSpeakerModalHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading pt-3 pb-3">About Speaker</h3>
              <div className="midModalAreaContent">
                <AboutSpeaker passprops={this.props} />
              </div>
            </div>
          </div>
        ) : null}

        {this.state.sessionAllDetailsModal ? (
          <div className="midModal bigDataModal">
            <div
              className="modalOverlay"
              onClick={this.sessionAllDetailsModalHandler}
            ></div>
            <div className="midModalArea">
              <div
                className="modalPopUpClose"
                id="exhibitpopclose"
                onClick={this.sessionAllDetailsModalHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading pt-3 pb-3">Session Details</h3>
              <div className="midModalAreaContent">
                <MainSessionAllDetails sessionId={params.sessionId} />
              </div>
            </div>
          </div>
        ) : null}
        {/*=====Session Documents Modal=====*/}
        {this.state.sessionDocumentsModal ? (
          <div className="midModal">
            <div
              className="modalOverlay"
              onClick={this.sessionDocumentsModalHandler}
            ></div>
            <div className="midModalArea">
              <div
                className="modalPopUpClose"
                id="exhibitpopclose"
                onClick={this.sessionDocumentsModalHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading pt-3 pb-3">Session Documents</h3>
              <div className="midModalAreaContent">
                <SessionDocuments passprops={this.props} />
              </div>
            </div>
          </div>
        ) : null}
        {/*=====Session Survey Modal=====*/}
        {this.state.sessionSurveyModal ? (
          <div className="midModal verticleCenterModal">
            <div
              className="modalOverlay"
              onClick={this.sessionSurveyModalHandler}
            ></div>
            <div className="midContentWrp">
              <div
                className="modalPopUpClose"
                onClick={this.sessionSurveyModalHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3>Session Evaluation</h3>
              <div className="midContentBx">
                <div className="surveyForm">
                  <form>
                    <SessionSurveyEasy passprops={this.props} />
                  </form>
                </div>

                {/* {
                            this.state.iframeLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                                    <iframe width="100%" height="450" src={`https://www.tslwebreg.com/sessionSurvey.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&role=${localStorage.getItem('currentRole')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe>  */}
              </div>
            </div>
          </div>
        ) : null}
        {/*=====Clain CreditModal=====*/}
        {this.state.claimCreditInfoPopup ? (
          <div className="modalPopUp">
            <div className="modalPopUpInr p-0">
              <div
                className="modalPopUpClose"
                onClick={this.closeClaimCreditInfoPopupHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading">CE Credit</h3>
              <div className="modalContent p-4">
                <div className="claimBx">
                  {this.state.creditAlreadyClaimed === true ? (
                    <p>
                      <i class="fa fa-check"></i> {this.state.earnedCredit} CE’s
                      Earned
                    </p>
                  ) : (
                    <p>
                      <i class="fa fa-times"></i> Your time has been logged but
                      you have not met the CE requirement
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}

        {/*=====popup before leave*/}
        {this.state.leaveBeforeClaimPopup ? (
          <div className="modalPopUp topAligned">
            <div className="modalPopUpInr p-0">
              <div
                className="modalPopUpClose"
                onClick={this.leaveBeforeClaimPopupHandler}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3 className="modalHeading">CE Credit</h3>
              <div className="modalContent p-4">
                <div className="claimBx">
                  <p>
                    <i class="fa fa-times"></i>Please click to log time and
                    claim CE credit before closing web browser.
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
export default ConferenceCenterPage;
