import React, { Component } from 'react'
import Axios from 'axios';
import {Link} from "react-router-dom";
import AddEventToSchedule from './AddEventToSchedule'

export default class MySchedule extends Component {
    constructor(){
        super();

        this.state={
            myScheduleData:[],
            filterDates:[],
            isSession:"",
            searchByDate:'',
            pageLoader:true,
            notFound:"",
            addEventToScheduleModal:false,
        }
    }
    addEventToScheduleModalHandlar = (e) => {
        e.preventDefault();
        this.setState({addEventToScheduleModal:!this.state.addEventToScheduleModal})
        if(this.state.addEventToScheduleModal){
            this.renderSchedules();
        }
    }
    componentDidMount(){
        this.renderSchedules();
        this.renderFilterDates();
    }

    renderFilterDates = () =>{
        Axios.get(`${global.config.requestUrl}user/getScheduleDates?eventId=${localStorage.getItem('eventId')}&memberId=${localStorage.getItem('userId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({filterDates:res.data.data, pageLoader:false})
        })
        .catch(err => {
            console.log(err);
        });
    }
 
    renderSchedules = () => {
        this.setState({pageLoader : true});
        Axios.get(`${global.config.requestUrl}user/getSessionSchedules?eventId=${localStorage.getItem('eventId')}&memberId=${localStorage.getItem('userId')}&searchByDate=${this.state.searchByDate}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({myScheduleData:res.data.data, pageLoader:false, notFound:res.data.errorCode})               
            console.log(res.data.data)
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
    }

    deleteSchedule = (schedule_id) => {
        this.setState({pageLoader : true});
        const postdata = {
            eventId: localStorage.getItem('eventId'),
            scheduleId:schedule_id,
            memberId:localStorage.getItem('userId'),
           }

        Axios.post(`${global.config.requestUrl}user/deleteSchedule`, postdata,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            if(!res.data.errorCode){
              this.setState({pageLoader : false});
              this.renderSchedules();
            }else{
                this.setState({pageLoader : false});
            }
           console.log(res.data.data) 
            
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader : false})
        });
    }

    onChangeDateHandler = (e) => {
        this.setState({
          searchByDate:e.target.value,
        }, () => {
          this.renderSchedules();
        });
        
    }
    render() {
        return (
            <React.Fragment>
            {
                this.state.pageLoader
                ?
                (
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                )
                :
                null
            }
                <div className="midTable">
                    <h3>My Schedule</h3>
                    <div className="sortBy">
                        <div className="sortBx">
                            <label>Date Filter :</label>
                            <select name="trackfilter" onChange={this.onChangeDateHandler}  >
                                <option value="">All</option>
                                {
                                    this.state.notFound === 0 
                                    ?
                                    <React.Fragment>
                                    { 
                                   
                                        this.state.filterDates.map(dates => (
                                            <option value={dates.session_date} >{dates.session_date}</option>
                                        ))
                                } 
                                    </React.Fragment>
                                    :
                                    null
                                }
                             
                            </select>
                        </div>
                        <div className="sortBx">
                            <div className="addBtn">
                                <Link onClick={this.addEventToScheduleModalHandlar}>
                                    <span>Add Event to Schedule</span>
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="midTableWrp">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="width100">Date</th>
                                    <th className="width100">Time</th>
                                    <th>Details</th>
                                    <th className="text-center width100">Action</th>
                                </tr>
                            </thead>
                            <tbody>  
                            {
                                this.state.notFound === 0 
                                ?
                                <React.Fragment>
                                { 
                                    this.state.myScheduleData.map(scheduleData => (
                                        <tr key={scheduleData.schdeule_id}>
                                            <td>{scheduleData.start_date}</td>
                                            <td>{scheduleData.start_time}</td>
                                            <td>
                                                <p><b>{scheduleData.session_name}</b></p>
                                                {
                                                    scheduleData.is_session === 0 
                                                    ?
                                                    <p>{scheduleData.notes}</p> 
                                                    :
                                                    null
                                                 }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    scheduleData.is_session === 1 
                                                    ?
                                                    <React.Fragment>
                                                        <Link to={`/ihca-als2021/attendee/conferenceCenterDetails/${scheduleData.session_id}`} className="btn btn-warning"><i className="fa fa-eye"></i></Link>
                                                        <button onClick={() => this.deleteSchedule(scheduleData.schdeule_id)} className="btn btn-danger ml-2 blk"><i className="fa fa-trash"></i></button>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <button onClick={() => this.deleteSchedule(scheduleData.schdeule_id)} className="btn btn-danger blk"><i className="fa fa-trash"></i></button>
                                                    </React.Fragment>
                                                }
                                                
                                            </td>
                                        </tr>
                                    ))
                                    
                                }
                                </React.Fragment>
                                :
                                <tr>
                                    <td colSpan="4"><h5 className="text-center">No Data Found</h5></td>
                                </tr>
                            }
                                
                            </tbody>
                        </table>
                    </div>
                </div> 
                {
                    this.state.addEventToScheduleModal
                    ?
                    <div className="midModal verticleCenterModal">
                        <div className="midContentWrp">
                            <div class="modalPopUpClose" id="addingSchedulePop" onClick={this.addEventToScheduleModalHandlar}><i className="fa fa-times"></i></div>
                            <h3>Add Event to Schedule</h3>
                            <div className="midContentBx">
                                <AddEventToSchedule />
                            </div>                                
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
