import React, { Component } from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';
import BoothContactsTable from './BoothContactsTable';
import FollowUpsTable from './ContactAndFollowUpsTable';
import BannerEditor from './BannerEditor';
import AddDocumentsVideos from './AddDocumentsVideos';
import {storage} from '../../../Firebase'
import Axios from 'axios';
import LeftNavBar from './LeftNavBar';
//import { stat } from 'react-native-fs';


class ExhibitorBoothLimitedAccess extends Component {
    constructor(props){
        super(props);
        var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
        this.state = {
            signDisplayColorPicker: false,
            signBgcolor: '#073382',
            wallDisplayColorPicker: false,
            wallBgcolor: '#073382',
            logoImageFiles : false,
            boothContactModal: false,
            followUpsModal: false,
            companyVideoModal: false,
            productDemoVideoModal: false,
            companyBioModal:false,
            technicalMarketingModal : false,
            media3modal:false,
            bannerTextModel:false,

            companyBioText : "",
            companyBioTextErr : "",
            companyBioTextSuccess : "",

            companyVideoFile : null,
            shareCompanyVideoLink :"",
            companyVideoSelectionError:"",
            companyVideoSelectionSuccess:"",
            companyVideoFileUrl:"",

            productDemoVideoFile : null,
            shareproductDemoVideoLink :"",
            productDemoVideoSelectionError:"",
            productDemoVideoSelectionSuccess:"",
            productDemoVideoFileUrl:"",

            media3File : null,
            sharemedia3Link :"",
            media3SelectionError:"",
            media3SelectionSuccess:"",
            media3FileUrl:"",

            exhibitCompanyLogo: null,
            exhibitCompanyLogoUrl:'',
            progress:0,

            // media1Type:'',
            // media2Type:'',
            // media3Type:'',

            pageLoader:true,

            boothId :null,
            exhibitorId : userId,
            eventId : localStorage.getItem('eventId'),
        }

    }

    componentDidMount (){
        console.log('limited access');

        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1){
                this.props.history.push('/focus2022/notfound');
                return false;
            }
        }
        var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }

        Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorBoothInfo?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
        if(!res.data.errorCode){

        let randomString = Math.random().toString(36);
        this.setState({
            boothId:res.data.data[0].boothId.toString(),
            signBgcolor: res.data.data[0].bgColor,
            wallBgcolor: res.data.data[0].wallColor,
            companyBioText :res.data.data[0].bio,
            companyVideoFileUrl :res.data.data[0].companyVideoLink,
            productDemoVideoFileUrl :res.data.data[0].productDemoLink,
            media3FileUrl :res.data.data[0].media3,
            exhibitCompanyLogo: res.data.data[0].banner,
            bannerText: res.data.data[0].bannerText,
            // media1Type: res.data.data[0].media1Type,
            // media2Type: res.data.data[0].media2Type,
            // media3Type: res.data.data[0].media3Type,
            companyvideokey :randomString,
            proddemovideokey :randomString,
            test: 5,
            pageLoader:false,
        });
       
        localStorage.setItem('media1Type', res.data.data[0].media1Type);
        localStorage.setItem('media2Type', res.data.data[0].media2Type);
        localStorage.setItem('media3Type', res.data.data[0].media3Type);
        if(res.data.data[0].bannerText == 0 ){
            document.getElementById("bannerhtml").innerHTML = '<img src="'+res.data.data[0].banner+'"/>';
        }else{
            var tt = res.data.data[0].banner;
            document.getElementById('bannerhtml').innerHTML = tt.replace(/\\"/g, '"').replace(/\\n/g, '');
        }
        this.forceUpdate();
      
     }else{
        this.setState({
            pageLoader:false,
        });
     }
    })
    .catch(err => {
        console.log(err);
    });

    
   
    }


    /*signBoard Background Change*/
    
    signHandleClick = () => {
        this.setState({ 
            signDisplayColorPicker: !this.state.signDisplayColorPicker,
            wallDisplayColorPicker : false 
        })
    };

    signHandleClose = () => {
        this.setState({ signDisplayColorPicker: false })
        let _stateerr = '';
        let _stateModel = '';
        this.updateBoothData(_stateerr, _stateModel);
    };

    signHandleChange = (color) => {
        this.setState({ signBgcolor: color.hex })
    };

    /*Wall Background Change*/
    wallHandleClick = () => {
        this.setState({ 
            wallDisplayColorPicker: !this.state.wallDisplayColorPicker,
            signDisplayColorPicker : false 
        })
    };

    wallHandleClose = () => {
        this.setState({ wallDisplayColorPicker: false })
        let _stateerr = '';
        let _stateModel = '';
        this.updateBoothData(_stateerr, _stateModel);
    };

    wallHandleChange = (color) => {
        this.setState({ wallBgcolor: color.hex })
    };

    getSrcFromEmbedLink = (url) => {
        var  re = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/i, // match src='a' OR src="a" OR src=a
            res = url.match(re),
            src = res[1]||res[2]||res[3];
            return src;
    }

    getFileExtFromUrl =  (linkurl,medialinkname) => {
        var url = linkurl;
        var mainfile = url.split('/').pop().split('?')[0];
        var filetype = mainfile.split('.');
        if(filetype[1]){
            return filetype[1];
        }else if(url.includes('iframe')){
            var src = this.getSrcFromEmbedLink(url);
            if(src.includes('https://www.youtube.com/embed') || src.includes('https://player.vimeo.com/video/')){
                this.setState({[medialinkname] : src})
                return 'validevideolink';
            }else{
                return 'invalidembed';
            }
        }else if(url.includes('https://www.youtube.com/embed') || url.includes('youtu.be') || url.includes('https://www.youtube.com/watch') || url.includes('https://player.vimeo.com/video/')){
            return 'validevideolink';
        }else{
            return '';
        }
        
    }

    /* media validator code starts */
    

    mediaValidateForm = (file,linkurl,mediatypestate,fileerr,medialinkname) => {
       
        if(file !== null && linkurl === "" ){
            if(file.type.includes('image')){
                localStorage.setItem(mediatypestate, 2);
            }else{
                localStorage.setItem(mediatypestate, 1);
            }
            return true;
        }
        if(file === null && linkurl !== "" ){
            var mediatype =  this.getFileExtFromUrl(linkurl,medialinkname);  // Here getting and setting type of the media
            
            if(mediatype !==''){
                if(mediatype === 'png' || mediatype === 'jpg'){
                    localStorage.setItem(mediatypestate, 2);
                    return true;
                }else if(mediatype === 'mp4' || mediatype === 'validevideolink'){
                    localStorage.setItem(mediatypestate, 1);
                    return true;
                }else if(mediatype === 'invalidembed'){
                    this.setState({[fileerr] : "Invalid embed link!!!"})
                    return false;
                }else{
                    this.setState({[fileerr] : "Invalid media URL!!!"})
                    return false;
                }
            }else{
                this.setState({[fileerr] : "Invalid media URL!!!"})
                return false;
            }
           
        }
        if(file === null && linkurl === "" ){
            this.setState({[fileerr] : "Please Choose File"})
            return false
        }
        if(file !== null && linkurl !== "" ){
            this.setState({[fileerr] : "You only allowed to add a file or url at a time. "})
            return false
        } 
      }

    /* media validator code ends */

    /*===Upload Company Video Handle function===*/
    companyVideoChange = (e) => {
        
        if(e.target.files[0]){
            const companyVideoFile = e.target.files[0];
            var size = companyVideoFile.size/1024/1024;
            if(size > 10){
                let randomString = Math.random().toString(36);
                this.setState({
                    companyvideokey :randomString,
                    companyVideoFile : null,
                    companyVideoFileError : "Max upload  size is 10MB"
                })
            }else{
                this.setState({
                    companyVideoFile : companyVideoFile,
                    companyVideoFileError : ""
                })
               
            }
        }
    }
   
    companyVideoFirebaseUploader = (e) => { 
        
        e.preventDefault();
        if(this.mediaValidateForm(this.state.companyVideoFile,this.state.shareCompanyVideoLink,'media1Type','companyVideoSelectionError','shareCompanyVideoLink')){
            
            this.setState({pageLoader:true})

            if(this.state.companyVideoFile !== null){
                console.log("Validate companyVideoFile", this.state.companyVideoFile)
    
                const {companyVideoFile, exhibitorId, eventId} = this.state;
                // Create a storage reference from our storage service
                var storageRef = storage.ref();
                const uploadTask = storageRef.child(`videos/${eventId}/${exhibitorId}/${companyVideoFile.name}`).put(companyVideoFile);
                uploadTask.on('state_changed', 
                (snapshot) => {
                    //progress func
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    this.setState({progress})
                    // console.log(progress)
                    if(progress !== 100){
                    this.setState({pageLoader:true})
                    }
                }, 
                (error) => {
                    //error func
                    console.log(error)
                }, 
                () => {
                    //complete func  
                    storage.ref(`videos/${eventId}/${exhibitorId}`).child(companyVideoFile.name).getDownloadURL().then(url=>{
                        if(url){
                            this.setState(
                                {
                                    companyVideoLink : url,
                                }
                            );
                            this.companyVideoSubmit();
                        }
                    })
                });
            } else{
                this.companyVideoSubmit();
            }

        }
    }  

    companyVideoSubmit = () => {
        let _stateerr = 'companyVideoSelectionError';
        let _stateModel = 'companyVideoModal';
        let _stateSuccess = 'companyVideoSelectionSuccess';
        this.updateBoothData(_stateerr, _stateModel, _stateSuccess);
      }
    
    /*===Upload Product Demo Video Handle function===*/
    productDemoVideoChange = (e) => {
        if(e.target.files[0]){
            const productDemoVideoFile = e.target.files[0];
            var size = productDemoVideoFile.size/1024/1024;
            if(size > 10){
                let randomString = Math.random().toString(36);
                this.setState({
                    proddemovideokey :randomString,
                    productDemoVideoFile : null,
                    productDemoVideoFileError : "Max upload  size is 10MB"
                })
            }else{
                this.setState({
                    productDemoVideoFile : productDemoVideoFile,
                    productDemoVideoFileError : ""
                })
               
            }
        }
        
    }
    
    productDemoVideoSubmit = (e) => {
        e.preventDefault();
        let _stateerr = 'productDemoVideoSelectionError';
        let _stateModel = 'productDemoVideoModal';
        let _stateSuccess = 'productDemoVideoSelectionSuccess';
        const validForm = this.mediaValidateForm(this.state.productDemoVideoFile,this.state.shareproductDemoVideoLink ,'media2Type','productDemoVideoSelectionError','shareproductDemoVideoLink');
        if(validForm){
            this.setState({pageLoader:true})
            if(this.state.productDemoVideoFile !== null){
                console.log("Validate companyVideoFile", this.state.companyVideoFile)
    
                const {productDemoVideoFile, exhibitorId, eventId} = this.state;
                // Create a storage reference from our storage service
                var storageRef = storage.ref();
                const uploadTask = storageRef.child(`videos/${eventId}/${exhibitorId}/${productDemoVideoFile.name}`).put(productDemoVideoFile);
                uploadTask.on('state_changed', 
                (snapshot) => {
                    //progress func
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    this.setState({progress})
                    // console.log(progress)
                    if(progress !== 100){
                    this.setState({pageLoader:true})
                    }
                }, 
                (error) => {
                    //error func
                    console.log(error)
                }, 
                () => {
                    //complete func  
                    storage.ref(`videos/${eventId}/${exhibitorId}`).child(productDemoVideoFile.name).getDownloadURL().then(url=>{
                        if(url){
                            this.setState(
                                {
                                    productDemoVideoLink : url,
                                }
                            );
                            this.updateBoothData(_stateerr, _stateModel, _stateSuccess);
                        }
                    })
                });
            }else{
                this.updateBoothData(_stateerr, _stateModel, _stateSuccess);
            }
            
        
        
        }
        
    }

    /*===Upload media3 Handle function===*/
    media3Change = (e) => {

        if(e.target.files[0]){
            const media3File = e.target.files[0];
            var size = media3File.size/1024/1024;
            if(size > 10){
                let randomString = Math.random().toString(36);
                this.setState({
                    media3key :randomString,
                    media3File : null,
                    media3SelectionError : "Max upload  size is 10MB"
                })
            }else{
                this.setState({
                    media3File : media3File,
                    media3SelectionError : ""
                })
               
            }
        }

       
    }
   
    media3FirebaseUploader = (e) => {
        e.preventDefault();
        if(this.mediaValidateForm(this.state.media3File,this.state.sharemedia3Link ,'media3Type','media3SelectionError','sharemedia3Link')){
            this.setState({pageLoader:true})

            if(this.state.media3File !== null){
                console.log("Validate media3File", this.state.media3File)
    
                const {media3File, exhibitorId, eventId} = this.state;
                // Create a storage reference from our storage service
                var storageRef = storage.ref();
                const uploadTask = storageRef.child(`videos/${eventId}/${exhibitorId}/${media3File.name}`).put(media3File);
                uploadTask.on('state_changed', 
                (snapshot) => {
                    //progress func
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    this.setState({progress})
                    // console.log(progress)
                    if(progress !== 100){
                    this.setState({pageLoader:true})
                    }
                }, 
                (error) => {
                    //error func
                    console.log(error)
                }, 
                () => {
                    //complete func  
                    storage.ref(`videos/${eventId}/${exhibitorId}`).child(media3File.name).getDownloadURL().then(url=>{
                        if(url){
                            this.setState(
                                {
                                    media3Link : url,
                                }
                            );
                            this.media3Submit();
                        }
                    })
                });
            } else{
                this.media3Submit();
            }

        }
    }  

    media3Submit = () => {
        let _stateerr = 'media3SelectionError';
        let _stateSuccess = 'media3SelectionSuccess';
        let _stateModel = 'media3Modal';
        this.updateBoothData(_stateerr, _stateModel, _stateSuccess);
      }




    /* Upadte company Bio */
     
    submitCompanyBio = (e) =>{
        e.preventDefault();
        let _stateerr = 'companyBioTextErr';
        let _stateModel = 'companyBioModal';
        let _stateSuccess = 'companyBioTextSuccess';

        
        if(this.state.companyBioText !== "" ){
          this.updateBoothData(_stateerr, _stateModel, _stateSuccess);
          this.setState({companyBioTextErr: ""})
        }else{
            this.setState({companyBioTextErr:"Please add some text here", companyBioTextSuccess:""})
        }
    }


    /*Booth Contact Modal Handeler*/
    boothContactModalHandeler = (e) => {
        this.setState({boothContactModal: !this.state.boothContactModal})
    }
    /*folow up Modal Handeler*/
    followUpsModalHandeler = (e) => {
        this.setState({followUpsModal: !this.state.followUpsModal})
    }
    /*Company Bio Modal Handeler*/
    companyBioModalHandeler = (e) => {
        this.setState({companyBioModal: !this.state.companyBioModal})
    }
    bannerTextModelHandeler = (e) => {
        this.setState({bannerTextModel: !this.state.bannerTextModel})
    }
    /*Technical and Marketing Modal Handeler*/
    technicalMarketingModalHandeler = (e) => {
        this.setState({technicalMarketingModal: !this.state.technicalMarketingModal})
    }
    /*Media 3 Modal Modal Handeler*/
    media3ModalHandeler = (e) => {
        let randomString = Math.random().toString(36);
        this.setState({media3modal:!this.state.media3modal,media3key :randomString,media3File : null,sharemedia3Link:'',media3SelectionError:''})
    }
    /*Company Video Modal Handeler*/
    companyVideoModalHandeler = (e) => {
        let randomString = Math.random().toString(36);
        this.setState({companyVideoModal: !this.state.companyVideoModal,companyvideokey :randomString,companyVideoFile : null,shareCompanyVideoLink:'',companyVideoSelectionError:''});
                           
    }
    /*Product Demo Video Modal Handeler*/
    productDemoVideoModalHandeler = (e) => {
        let randomString = Math.random().toString(36);
        this.setState({productDemoVideoModal: !this.state.productDemoVideoModal,proddemovideokey :randomString,productDemoVideoFile : null,shareproductDemoVideoLink:'',productDemoVideoSelectionError:''});
    }
    /*input box value change*/
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    handleBannerEditorCallback = (boothIdFromBannerEditor) =>{
        this.setState({boothId: boothIdFromBannerEditor.toString()})
    }

    updateBoothData(errfield,modelname, successfield = ""){
        
        this.setState({pageLoader:true});
        let compVideoLink ='';
        if(this.state.shareCompanyVideoLink !==''){
            compVideoLink= this.state.shareCompanyVideoLink;
        }else if(this.state.companyVideoLink){ 
            compVideoLink= this.state.companyVideoLink;
        }else{
            compVideoLink= this.state.companyVideoFileUrl;
        }

        if(compVideoLink.includes('iframe') && (compVideoLink.includes('www.youtube.com') || compVideoLink.includes('https://player.vimeo.com/video/') )  ){
            compVideoLink = this.getSrcFromEmbedLink(compVideoLink);
        }
        
        let prodDemoVideoLink ='';
        if(this.state.shareproductDemoVideoLink !==''){
            prodDemoVideoLink = this.state.shareproductDemoVideoLink;
        }else if(this.state.productDemoVideoLink){
            prodDemoVideoLink= this.state.productDemoVideoLink;
        }else{
            prodDemoVideoLink= this.state.productDemoVideoFileUrl;
        }
        if(prodDemoVideoLink.includes('iframe') && (prodDemoVideoLink.includes('www.youtube.com') || prodDemoVideoLink.includes('https://player.vimeo.com/video/') )  ){
            prodDemoVideoLink = this.getSrcFromEmbedLink(prodDemoVideoLink);
        }

        let med3Link ='';
        if(this.state.sharemedia3Link !==''){
            med3Link = this.state.sharemedia3Link;
        }else if(this.state.media3Link){
            med3Link= this.state.media3Link;
        }else{
            med3Link= this.state.media3FileUrl;
        }
        if(med3Link.includes('iframe') && (med3Link.includes('www.youtube.com') || med3Link.includes('https://player.vimeo.com/video/') )  ){
            med3Link = this.getSrcFromEmbedLink(med3Link);
        }

        var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
        
       
        const boothData = {
            memberId: userId,
            eventId: localStorage.getItem('eventId'),
            boothId : this.state.boothId,
            bgColor: this.state.signBgcolor,
            wallColor:this.state.wallBgcolor,
            companyVideoLink : compVideoLink,
            productDemoLink: prodDemoVideoLink,
            bio: this.state.companyBioText,
            media3: med3Link,
            // banner:this.state.exhibitCompanyLogo,
            media1Type: localStorage.getItem('media1Type'),
            media2Type: localStorage.getItem('media2Type'),
            media3Type: localStorage.getItem('media3Type'),
           }


          // console.log(boothData);return false;

         
        Axios.post(`${global.config.requestUrl}exhibitor/setUpExhibitorBooth`, boothData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                this.setState(
                    {
                        pageLoader:false
                    }
                );
                console.log(res.data);
                //if(errfield !== ''){
                    if(res.data.errorCode){  // Error case
                        this.setState({
                            [errfield] : res.data.errorMessage, [successfield]:""
                        })
                      }else{ 
                        this.setState({
                            [successfield] : res.data.errorMessage, [errfield] : ""
                        });
                        let randomString = Math.random().toString(36);
                        
                        this.setState({
                            boothId:res.data.result.boothId.toString(),
                            companyvideokey :randomString,
                            companyVideoFile : null,
                            shareCompanyVideoLink : '',
                            companyVideoLink:'',
                            companyVideoFileUrl : compVideoLink,
                            proddemovideokey :randomString,
                            productDemoVideoFile : null,
                            shareproductDemoVideoLink : '',
                            productDemoVideoFileUrl :prodDemoVideoLink,
                            media3key :randomString,
                            media3File : null,
                            sharemedia3Link : '',
                            media3FileUrl : med3Link,
    
                        })
                        setTimeout(() => {this.setState({[modelname]: false, [errfield] : "", [successfield]:""})}, 800);
                        setTimeout(() => {this.setState({media3modal: false})}, 800);

                      }
                      
                      
               // }
                
            })
            .catch(err => {
                console.log(err);
            });
    }



    render() {
        const styles = reactCSS({
            'default': {
              signColor: {
                background: this.state.signBgcolor,
              },
              wallColor: {
                background: this.state.wallBgcolor,
              }
            },
          });
          console.log("wall and sign bg" + styles)
         
        return (
            <React.Fragment>

                <section className="bg8 signleScreen exhibitorBoothSec boothLimitedAccess">
                    <LeftNavBar />
                    {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                    <div className="responsiveBoothWrapper">
                        <div className="exhibitorBoothWrapper">
                            <div className="exhibitorBoothFrame">
                                <img src={process.env.PUBLIC_URL + '/images/exhibitor-booth-frame-limited-access.png'} alt="" />
                            </div>
                            <div className="exhibitWallBackground" style={ styles.wallColor }></div>
                            <div className="exhibitCompanyLogo">
                                <React.Fragment>
                                    <div className="uploadPlaceholder" id="logobanner">
                                        <span className="bannerhtml" id="bannerhtml"><i className="fa fa-upload"></i>Upload Company Logo Banner</span>
                                    </div>
                                </React.Fragment> 
                                <div className="bannerInfoHover">
                                    <p> Upload an image OR type in text to display your company name.</p>
                                </div>
                                <div className="hover-overlay">
                                    <div className="btnList">
                                        <button onClick={this.bannerTextModelHandeler}><i className="fa fa-edit"></i></button>
                                    </div>
                                </div>
                            </div>  
                            <div className="exhibitMarketingDoc topInputHolder" style={ styles.signColor }
                                onClick={this.boothContactModalHandeler}>
                                <div className="uploadPlaceholder">
                                    <i className="fa fa-edit d-block"></i>
                                    <span>Edit <br/>Booth Staff</span>
                                </div>
                            </div>
                            
                            <div className="exhibitCompanyBio topInputHolder" style={ styles.signColor } onClick={this.companyBioModalHandeler}>
                                <div className="uploadPlaceholder">
                                    <i className="fa fa-edit d-block"></i>
                                    <span>Edit Company Bio</span>
                                </div>
                            </div>
                            
                        

                            
                        <div className="exhibitVideo companyVideo" >
                            {
                            this.state.companyVideoFileUrl ==''
                            ? 
                            
                                <div className="uploadPlaceholder" onClick={this.companyVideoModalHandeler}>
                                    <i className="fa fa-upload d-block"></i>
                                    <span>Add<br /> Video/Images 1</span>
                                </div>
                            
                                : 
                                <div className="uploadPlaceholder">
                                {
                                    localStorage.getItem('media1Type') == 1
                                    ?
                                    <React.Fragment>
                                    {
                                        this.state.companyVideoFileUrl.startsWith("https://www.youtube") || this.state.companyVideoFileUrl.startsWith("https://player.vimeo.com/video/")
                                        ?
                                        <iframe width="100%" height="100%" src={`${this.state.companyVideoFileUrl}?autoplay=false&rel=0`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay={false}></iframe>
                                        :
                                        <video controls muted>
                                        <source src={this.state.companyVideoFileUrl} type="video/mp4"/>
                                        </video>
                                    }
                                    </React.Fragment>
                                    :
                                    <img src={this.state.companyVideoFileUrl} alt="" />
                                }
                                    <div className="hover-overlay">
                                        {/* <i className="fa fa-upload d-block"></i>
                                        <span>Edit<br /> Video/Images 1</span> */}
                                        <div className="btnList">
                                            <button><a href={`${this.state.companyVideoFileUrl}?rel=0`} target="_blank"><i className="fa fa-eye"></i></a></button>
                                            <button onClick={this.companyVideoModalHandeler}><i className="fa fa-edit"></i></button>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div> 

                            <div className="boothColorPicker">
                                <div className="colorLabel">
                                    <label>Choose Sign and <br /> Background Color</label>
                                    <div className="colorCircle" style={ styles.signColor } onClick={this.signHandleClick}></div>
                                    { this.state.signDisplayColorPicker 
                                        ? 
                                        <div className="colorChart">
                                            <i className="fa fa-times-circle" onClick={ this.signHandleClose }></i>
                                            <div onClick={ this.signHandleClose }/>
                                            <SketchPicker color={ this.state.signBgcolor } onChange={ this.signHandleChange } />
                                        </div> 
                                        : 
                                    null }
                                </div>
                                <div className="colorLabel">
                                    <label>Choose Wall Color</label>
                                    <div className="colorCircle" style={ styles.wallColor } onClick={this.wallHandleClick}></div>
                                    { this.state.wallDisplayColorPicker 
                                        ? 
                                        <div className="colorChart" >
                                            <div onClick={ this.wallHandleClose }/>
                                            <SketchPicker color={ this.state.wallBgcolor } onChange={ this.wallHandleChange } />
                                            <i className="fa fa-times-circle" onClick={ this.wallHandleClose }></i>
                                        </div> 
                                        : 
                                        null 
                                    }
                                </div>
                            </div>

                            <div className="exhibitFormSubmit">
                                <button>
                                { localStorage.getItem('exhibitorId')
                                        ?
                                    <a href={`/focus2022/attendee/exhibitorBooth/${localStorage.getItem('exhibitorId')}`} target="_blank">Preview Exhibitor Booth <i class="fa fa-long-arrow-right ml-2"></i></a>
                                    :
                                    <a href={`/focus2022/attendee/exhibitorBooth/${localStorage.getItem('userId')}`} target="_blank">Preview Exhibitor Booth <i class="fa fa-long-arrow-right ml-2"></i></a>
                                }
                                    </button>
                            </div>

                        </div>
                    </div>
                    {/*=========banner-text-modal==========*/}
                    {
                        this.state.bannerTextModel 
                        ? 
                        <div className="modalPopUp">
                            <div className="modalPopUpInr">
                            <div className="modalPopUpClose" id="bannermodel" onClick={this.bannerTextModelHandeler}>
                                <i className="fa fa-times"></i>
                            </div>
                                <div className="infoMsg">
                                    <p>Exhibit Booth Header- Upload an image OR type in text to display your company name.
                                    To upload a Banner graphic, click “browse” Then select a file from your computer. File formats include jpg and png. Best size for image is 882x82 pixels.</p>
                                    <p>
                                    To add text to your header, select “Edit Text”. Then use the text tool to type your full company name. You may edit the font size, style, and color. To edit existing text, first highlight the selection.</p>

                                    <p>Click “submit” to save changes.</p>
                                </div>
                                <BannerEditor parentCallback = {this.handleBannerEditorCallback} />
                            </div>
                        </div> 
                        : null
                    }

                    {/*=========Edit Company Bio Modal=========*/}
                    {
                        this.state.companyBioModal 
                        ?
                        <div className="modalPopUp">
                            
                            <div className="modalPopUpInr">
                                <div className="modalPopUpClose" onClick={this.companyBioModalHandeler}><i className="fa fa-times"></i></div>
                                <div className="modalPopUpFormBx">
                                <div className="infoMsg">
                                    <p>Add a brief description of your company and/or a link to your website.  </p>
                                </div>
                                    <h4 className="mb-3">Company Bio</h4>
                                    <div className="success">{this.state.companyBioTextSuccess}</div>
                                    <div className="error">{this.state.companyBioTextErr}</div>
                                    <form onSubmit={this.submitCompanyBio}>
                                        <div className="form-group">
                                            <textarea name="" 
                                                placeholder="Add Company Bio here" 
                                                id="companyBioText" 
                                                value={this.state.companyBioText} 
                                                className="form-control" 
                                                rows="5" 
                                                onChange={this.onChange} 
                                                name="companyBioText" >
                                            </textarea>
                                        </div>
                                        <div className="mt-3">
                                            <button type="submit">Submit</button>
                                        </div>
                                    </form>
                                    
                                </div>
                            </div>
                        </div> 
                        :
                        null
                    }
                    {/*=========companyVideo-modal==========*/}
                    {
                        this.state.companyVideoModal
                        ?
                        <div className="modalPopUp">
                            
                            <div className="modalPopUpInr">
                                <div className="modalPopUpClose" onClick={this.companyVideoModalHandeler}>
                                    <i className="fa fa-times"></i>
                                </div>
                                <div className="modalPopUpFormBx">
                                    <div className="infoMsg">
                                        <p>Include videos or images for your company.  Click “Browse” to select a file or for You Tube video, open the video, click share, embed, then copy and paste the link in share video link box.  Click “Submit” to save. </p>
                                        <p>Formats include:</p>
                                        <p>Youtube Link (or link to MP4 file),  .mp4, .jpg, .png. Best size for image is 310X150 pixels.</p>
                                    </div>
                                    {
                                      this.state.companyVideoFileUrl !== '' 
                                      ?
                                      <div className="uploadLink">
                                        <a href={this.state.companyVideoFileUrl} target="_blank"><i className="fa fa-picture-o"></i> See uploaded media</a>
                                      </div>
                                      :
                                      null
                                    }
                                    <h4 className="mb-3">Edit Video/Images 1</h4>
                                        <div className="error">{this.state.companyVideoSelectionError}</div>
                                        <div className="success">{this.state.companyVideoSelectionSuccess}</div>
                                        <form onSubmit={this.companyVideoFirebaseUploader}>
                                            <div className="form-group">
                                                <label>Media 1</label>
                                                <input type="file" name="companyVideoFile" className="form-control" accept="video/mp4,video/x-m4v,video/*, image/x-png,image/gif,image/jpeg" onChange={this.companyVideoChange} key={this.state.companyvideokey || '' }/>
                                                <div className="errorMessage">{this.state.companyVideoFileError}</div>
                                                <span className="d-block mt-2 mb-2">or</span>
                                                <input type="text" name="shareCompanyVideoLink" value={this.state.shareCompanyVideoLink} className="form-control" placeholder="Share Video Link..." onChange={this.onChange}/>
                                                <div className="errorMessage">{this.state.shareCompanyVideoLinkError}</div>
                                            </div>
                                            <div className="mt-3">
                                                <button type="submit">Submit</button>
                                            </div>
                                        </form>
                                    
                                </div>
                            </div>
                        </div> 
                        :
                        null
                    }
                    
                    {/*=========Booth Contacts Modal and Show Booth Contact List=========*/}
                    {
                        this.state.boothContactModal 
                        ? 
                        <div className="modalPopUp widePopUp">
                            <div className="modalPopUpInr">
                            <div className="modalPopUpClose" onClick={this.boothContactModalHandeler}>
                                <i className="fa fa-times"></i>
                            </div>
                            <div className="infoMsg">
                                <p>Register your colleagues and/or edit their contact info here. Please include all members who will be attending the event.</p>
                                <p>Click “+Add” to register a new person. To make changes to an existing registrant, click on the name and then click “Edit”. </p>
                            </div>
                            <div className="checkBox">
                                <input type="checkbox" name="" value="" id="incValu"/>
                                <label htmlFor="incValu">Include main contact as booth member</label>
                            </div>
                                <BoothContactsTable />
                            </div>
                        </div> 
                        : null
                    }

                </section>
            </React.Fragment>
        )
    }
}
export default ExhibitorBoothLimitedAccess;
