import React, { Component } from 'react';
import BottomNav from './BottomNav'
import Axios from 'axios';
import {Link} from 'react-router-dom';

import Slider from "react-slick";

import AboutSession from './AboutSession';
import AboutSpeaker from './AboutSpeaker';
import SessionDocuments from './SessionDocuments';
import MainSessionAllDetails from './MainSessionAllDetails';
import SessionChatRoom from "../../../TmChat/goma/sessionChatRoom/sessionChatRoom";
import jwt_decode from 'jwt-decode';


class ConferenceCenterPage extends Component {
    constructor(props){
        super(props);
        var dateNow = new Date();
        this.state={
            sessionBannerList:[],
            mediaPartnerList:[],
            producedByList:[],
            currentSessionUsers:[],
            sectionLoader:true,
            aboutSessionModal:false,
            aboutSpeakerModal:false,
            sessionDocumentsModal:false,
            liveVideoUrl:[],
            liveVideoUrlNotFound:"",
            
            liveUrlNull:"",
            ip:'127.0.0.1',
            pageLoader:true,
            iframeLoader:true,
            sessionSurveyModal:false,
            timeSpendInSeconds:0,
            minAttendance:0, // in seconds
            infoBoxModal:false,
            mainConferenceClass:"conferenceLinks",
            sessionAllDetailsModal:false,
            alreadySavedOnAutoLogout:0,
            claimCreditInfoPopup:false,
            showOverlayOnVideo: true,
            intervalId:null,
            creditAlreadyClaimed:false,
            inTimeForSession:'',
            outTimeForSession:'',
            earnedCredit:0,
            leaveBeforeClaimPopup:false,
        }

        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = -10;

            if(rolesArr.length > 2 ){
                currentRole = -10;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                currentRole = -1;
            }
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                currentRole = -6;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                currentRole = -1;
            }
            if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                currentRole = -1;
            }
            localStorage.setItem('currentRole',currentRole);
            
        }

        const {match : {params}} = props;
        this.scannedData = {
            regId: localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            sessionId: 0,
            accountId:localStorage.getItem('accountId'),
            serialNumber:this.state.ip,
            duration: this.state.timeSpendInSeconds.toString(),
            role:localStorage.getItem('currentRole'),
        }
        this.token = localStorage.getItem('exhibitorToken');
        
    }
    sessionLinksMobileToggle = () =>{
        this.setState({mainConferenceClass:"conferenceLinks activeToggle"})
        if(this.state.mainConferenceClass === "conferenceLinks activeToggle" ){
            this.setState({mainConferenceClass:"conferenceLinks"})
        }
    }
    infoBoxModalHandlar = (e) => {
        e.preventDefault();
        this.setState({infoBoxModal:!this.state.infoBoxModal})
    }
    claimCreditInfoPopupHandler = (e) => {

        if(this.state.showOverlayOnVideo === false){
            Axios.get(`${global.config.requestUrl}getCurrentDateTime`).then((response) => {
                this.setState({
                    outTimeForSession:response.data,
                    claimCreditInfoPopup:true,
                }, () => {
                    this.insertScannedDataHere();
                });
            }
            );
        }else{
            alert('Please view entire session before claiming credit')
        }
        
    }

    leaveBeforeClaimPopupHandler =() =>{
        this.setState({leaveBeforeClaimPopup:!this.state.leaveBeforeClaimPopup})
    }

    closeClaimCreditInfoPopupHandler =() =>{
        this.setState({claimCreditInfoPopup:!this.state.claimCreditInfoPopup})
    }

    sessionAllDetailsModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionAllDetailsModal:!this.state.sessionAllDetailsModal})
    }
    componentWillUnmount = () => {
        const {match : {params}} = this.props;
        this.deleteCurrentSessionUser();
        //enable below if condition to show alert if the session is on and you trying to leave.
        // if(this.state.timeSpendInSeconds < this.state.minAttendance && this.state.showOverlayOnVideo === false ){
        //     clearInterval(this.state.intervalId);
        //     alert('If you leave this page before claiming the credit, you will not get them.')
        //  }
         window.sessionStorage.removeItem("tsp");
         window.sessionStorage.removeItem("mat");


    }
    componentWillReceiveProps(nextProps){
        var element =  document.getElementById('myschedulepopup');
        if (typeof(element) != 'undefined' && element != null){
            document.getElementById('myschedulepopup').click();
        }
      
        this.getSessionData(nextProps);
        this.updateGoldenTicketStatus(nextProps);
        this.saveAuthForSurvey(nextProps);
   }
   sessionSurveyModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionSurveyModal:!this.state.sessionSurveyModal,iframeLoader:true})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSessionModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSessionModal:!this.state.aboutSessionModal})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSpeakerModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSpeakerModal:!this.state.aboutSpeakerModal})
        localStorage.setItem('conferenceClicked',1)
    }
    sessionDocumentsModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionDocumentsModal:!this.state.sessionDocumentsModal})
        localStorage.setItem('conferenceClicked',1)
    }
    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }

    removeLayerFromVideo = () =>{
        var intervalId = setInterval(this.countDown, 1000);
        Axios.get(`${global.config.requestUrl}getCurrentDateTime`).then((response) => {
            this.setState({showOverlayOnVideo:false,intervalId:intervalId,inTimeForSession:response.data});
        }
        );
        
        
    }


    removeLayerFromVideoFirstTime = () =>{
        
        var intervalId = setInterval(this.countDown, 1000);
        Axios.get(`${global.config.requestUrl}getCurrentDateTime`).then((response) => {
            this.setState({
                showOverlayOnVideo:false,intervalId:intervalId,inTimeForSession:response.data,outTimeForSession:response.data
            }, () => {
                this.insertScannedDataHere();
            });
            
        }
        );
        
        
    }

     roomListToggle = () => {
        let element = document.getElementById("roomListBx");
        element.classList.toggle("hide");
        let heading = document.getElementById("roomListHeading");
        heading.classList.toggle("active");
      }
    
    insertCurrentSessionUser = () =>{
        const {match : {params}} = this.props;
            const currentUsersData = {
                userId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                userName:localStorage.getItem('useraccname'),
                company:localStorage.getItem('company'),
            }
        Axios.post(`${global.config.requestUrl}user/insertSessionUser`, currentUsersData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            console.log(res.data);
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }


    deleteCurrentSessionUser = () =>{
        const {match : {params}} = this.props;
            const currentUsersData = {
                userId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
            }
        Axios.post(`${global.config.requestUrl}user/deleteSessionUser`, currentUsersData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    getSessionCurrentUsers = () => {
        this.setState({sectionLoader:true,currentSessionUsers:[]});
        const {match : {params}} = this.props;
        Axios.get(`${global.config.requestUrl}user/getSessionRoomUsers?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            
            if(res.data.errorCode === 0){
                this.setState({currentSessionUsers:res.data.data})
            }
            this.setState({sectionLoader:false});
        })
        .catch(err => {
            console.log(err);
        });
    }

    insertScannedDataHere = () => {

        if(localStorage.getItem('userId') === null){
            return false;
        }
        const {match : {params}} = this.props;
        this.setState({pageLoader:true});

        /*this condition will make out time to blank so that the database will get current time for out */
        var newInTime = this.state.outTimeForSession.toString();

        if(this.state.outTimeForSession.toString() === this.state.inTimeForSession.toString()){
            this.setState({outTimeForSession:''});
        }

        const scannedDataAll = [
            { 
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
                duration:'0',
                accountId:localStorage.getItem('accountId'),
                serialNumber:this.state.ip,
                addTime:this.state.inTimeForSession.toString(),

            },
            { 
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
                duration:this.state.timeSpendInSeconds.toString(),
                accountId:localStorage.getItem('accountId'),
                serialNumber:this.state.ip,
                addTime:this.state.outTimeForSession.toString(),

            }
          ];


        Axios.post(`${global.config.requestUrl}user/insertInOutScannedData`, scannedDataAll,
                    {headers: { "auth-token": this.token }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }else{
                          this.setState({inTimeForSession:newInTime})
                          if(this.state.minAttendance <= this.state.timeSpendInSeconds){
                            clearInterval(this.state.intervalId);
                            window.sessionStorage.removeItem('tsp');
                            this.setState({creditAlreadyClaimed:true})
                          }
                        }
                        this.setState({pageLoader:false});
                })
                .catch(err => {
                    console.log(err);
                });
    }

    updateGoldenTicketStatus = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        const ticketStatusData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:0,
            linkPage:'sessionRoom',
            sessionId:params.sessionId,
            exhibitorId:0
            
        }
        Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(res.data.errorCode){
            document.getElementById('goldenTkt').classList.add("greyScale");
            this.setState({pageLoader:false});
        }else{
            this.setState({pageLoader:false});
            document.getElementById('goldenTkt').classList.remove("greyScale");
        }
    })
    .catch(err => {
        console.log(err);
    });
}

getUserScannedData =(myprop) => {
    const {match : {params}} = myprop;
            const scannedData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
            }

            Axios.post(`${global.config.requestUrl}user/checkScanDataForUser`, scannedData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                            if(res.data.duration < this.state.minAttendance){
                                this.setState({
                                    timeSpendInSeconds:res.data.duration
                                }, () => {
                                    this.removeLayerFromVideo();
                                });
                            }else{
                                this.setState({showOverlayOnVideo:false,creditAlreadyClaimed:true,timeSpendInSeconds:res.data.duration});
                            }
                        }
                })
                .catch(err => {
                    console.log(err);
                });
}
    componentDidMount(){
        const {match : {params}} = this.props;
        window.sessionStorage.removeItem("mat");
        window.sessionStorage.removeItem("tsp");
        this.insertCurrentSessionUser();
        this.getSessionData(this.props);
        this.saveAuthForSurvey(this.props);
        this.updateGoldenTicketStatus(this.props);
        setInterval(this.getSessionCurrentUsers, 600000);
        this.getSessionCurrentUsers();
        fetch('https://geoip-db.com/json/')
           .then(res => res.json())
           .then(json => this.setState({ip: json.IPv4}));
        
    }

    onMouseEnter = (event) =>{
        this.setState({leaveBeforeClaimPopup : false});
      }

    onMouseOut = (event) =>{
        // If the mouse is near the top of the window, show the popup
        // Also, do NOT trigger when hovering or clicking on selects
        if (
          event.clientY < 50 &&
          event.relatedTarget == null &&
          event.target.nodeName.toLowerCase() !== "select" &&
          localStorage.getItem("exhibitorToken") !== null
          
        ) {
          // Remove this event listener
          document.removeEventListener("mouseout", this.onMouseOut);
      
          // Show the popup
          //document.getElementById("popup").style.display = "block";
          if(this.state.creditAlreadyClaimed !== true && this.state.showOverlayOnVideo !== true){
            this.setState({leaveBeforeClaimPopup : true});
          }
            
        }
      }

    countDown = () => {
      var clk = parseInt(this.state.timeSpendInSeconds,10);
      this.setState({timeSpendInSeconds: clk + 1});
      console.log('timespend = ',this.state.timeSpendInSeconds,'min time = ',this.state.minAttendance);
      //localStorage.setItem("tsp",this.state.timeSpendInSeconds);
      window.sessionStorage.setItem("tsp",this.state.timeSpendInSeconds);

       
    }

    getSessionData = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        Axios.get(`${global.config.requestUrl}user/getSessionVideo?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            

            if(res.data.errorCode === 0){
                
                this.setState({liveVideoUrl:res.data.data, liveVideoUrlNotFound:res.data.errorCode, liveUrlNull:res.data.data[0].video_url, minAttendance:res.data.data[0].min_attendence*60,earnedCredit:res.data.data[0].session_credit,pageLoader:false});
                //localStorage.setItem("mat",res.data.data[0].min_attendence*60);
                window.sessionStorage.setItem("mat",res.data.data[0].min_attendence*60);
                this.getUserScannedData(myprop);
                if(res.data.data[0].video_url !== null){
                    if(res.data.data[0].video_url.includes('https://success.zoom.us/wc/join/') ){
                        document.getElementById('videoconf').innerHTML = '<div class="confWrap">'+res.data.data[0].video_text+' <p><a class="btn btn-warning" target="_blank" href ="'+res.data.data[0].video_url+'" > Click here </a></p></div>';
                    }
                    if( res.data.data[0].video_url.includes('https://teams.microsoft.com/') ){
                        document.getElementById('videoconf').innerHTML = '<div class="confWrap"><p><a class="btn btn-warning" target="_blank" href ="'+res.data.data[0].video_url+'" > Click here to launch Teams meeting </a></p></div>';
                    }
                }
                if(res.data.data[0].banner !== ''){
                    this.setState({sessionBannerList:[
                        {'logo':res.data.data[0].banner,'banner_link':res.data.data[0].banner_link},
                    ]});
                }else{
                    this.setState({sessionBannerList:[
                        {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','banner_link':null},
                    ]});
                }
                this.setState({sectionLoader:false});
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader:false,sectionLoader:false});
        });
    }


        saveAuthForSurvey = (myprop) => {
            const {match : {params}} = myprop;
            const authSurveyData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
            }

            Axios.post(`${global.config.requestUrl}user/insertSessionForSurvey`, authSurveyData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    render() {
        //enable these 2 lines if you want popup on mouseout if  
        //document.addEventListener("mouseout", this.onMouseOut);
        //document.getElementById('root').addEventListener('mouseenter', this.onMouseEnter); 

        const {match : {params}} = this.props;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
          };
            return (

                <React.Fragment>  
                    <section className="bg11 signleScreen paddingTop height1100 conference">
                        {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                        <div className="container wideContainer">
                            <div className="conferenceContainer">
                            <div className="conferenceVideoWrp">
                            {
                                this.state.liveVideoUrlNotFound !== 0
                                ?
                                <div className="conferenceVideo videoNotFound">
                                    {/* <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" /> */}
                                </div>
                                :
                                <React.Fragment>
                                    
                                    { 
                                        this.state.liveVideoUrl.map(videoLink => (
                                            <React.Fragment>
                                                <h3>{videoLink.session_name}</h3>
                                                {
                                                    this.state.liveUrlNull === null || this.state.liveUrlNull === ""
                                                    ?
                                                    <div className="conferenceVideo videoNotFound">
                                                        <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" />
                                                    </div>
                                                    :
                                                    <div className="conferenceVideo" id="videoconf">
                                                        {
                                                        videoLink.video_url.includes('https://success.zoom.us/wc/join/') || videoLink.video_url.includes('https://teams.microsoft.com/')
                                                        ?
                                                        null
                                                        :
                                                        <iframe width="100%" height="315" src={`${videoLink.video_url}?rel=0`} key={videoLink.video_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        }
                                                        
                                                        {
                                                        this.state.showOverlayOnVideo === true
                                                        ?
                                                        <div className="videoOverlayWid">
                                                            <div className="videoOverlayWidInr">
                                                                <button className="btn btn-danger" onClick={this.removeLayerFromVideoFirstTime}><i class="fa fa-play"></i> Play Video</button>
                                                                <p>After complete viewing, please click below to claim CME credit.<br/>After claiming credit for this presentation, please return to the lobby and click on Meeting Evaluations to complete an evaluation for each session for which you wish to claim AOA Category 1-A CME credit.</p>
                                                                
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                        }
                                                        
                                                </div>
                                                }
                                                
                                            </React.Fragment>
                                        ))
                                    }
                                
                                </React.Fragment>

                            }
                            <div className="videoTerm">
                                {
                                  this.state.creditAlreadyClaimed !== true
                                  ?
                                  <button className="btn" onClick={this.claimCreditInfoPopupHandler}> Click here to claim CME credit </button>
                                  :
                                  null
                                }


                                {
                                this.state.creditAlreadyClaimed === true
                                ?
                                <div className="claimBx text-center">
                                   <p><i class="fa fa-check"></i> {this.state.earnedCredit} CME Credit Earned</p>
                                </div> 
                                :
                                null
                                }
                            </div>   
                            <div className={this.state.mainConferenceClass} style={{width:"100%"}}>
                                    <div class="usefulLinks">
                                    {
                                        window.innerWidth <= 991
                                        ?
                                        <h3 onClick={this.sessionLinksMobileToggle}>Session Descriptions and Disclosures</h3>
                                        :
                                        <h3>Session Descriptions and Disclosures</h3>
                                    }
                                    <ul>
                                        <li><Link onClick={this.sessionAllDetailsModalHandler} to="/"><i class="fa fa-info"></i> About this session</Link></li>
                                        {/* <li><Link onClick={this.aboutSpeakerModalHandler} to="/"><i class="fa fa-volume-up"></i> About the speaker</Link></li>
                                        <li><Link onClick={this.sessionDocumentsModalHandler} to="/"><i class="fa fa-file"></i> Session documents</Link></li> */}
                                        {/* <li><Link onClick={this.sessionSurveyModalHandler} to="/"><i class="fa fa-copy"></i> Session survey</Link></li> */}
                                    </ul>
                                    </div>
                                    
                                                { 
                                                    this.state.sessionBannerList.map(banner => (
                                                        <React.Fragment>
                                                        
                                                            
                                                            {
                                                              banner.banner_link !== null
                                                              ?
                                                              <div className="conferenceBx">
                                                              <div className="sponsersCat">
                                                                  <div className="sponsersCatInr">
                                                                  <div className="sponsersCatBx" key={banner.logo}>
                                                                <a href={banner.banner_link} target="_blank">
                                                                    <img src={banner.logo} alt="" className="" />
                                                                </a>
                                                                </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                              
                                                              :
                                                              null
                                                              }
                                                            
                                                            
                                                        </React.Fragment>
                                                    ))
                                                    
                                                }
                                                
                                            
                                </div>
                        </div>
                         <div className="conferenceLinks">
                            <SessionChatRoom sessionId={params.sessionId} />
                            <div className="roomList">
                                <h3 id="roomListHeading"><span onClick={this.roomListToggle}>People in the Room</span> <i className="fa fa-refresh ml-3" aria-hidden="true" onClick={this.getSessionCurrentUsers}></i></h3>
                                <ul id="roomListBx">

                                {
                                this.state.sectionLoader
                                ?
                                (
                                    <div className="sectionLoader">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                )
                                :
                                null
                            }

                                { 
                                this.state.currentSessionUsers.map(liveusers => (
                                    <React.Fragment> 
                                    <li>
                                        <h5>{liveusers.name}</h5>
                                        <h6>{liveusers.company}</h6>
                                    </li>              
                                    </React.Fragment>
                                ))                
                                }

                                </ul>
                            </div> 
                         </div>       
                            </div>
                        </div>  
                        {
                            this.state.infoBoxModal
                            ?
                            <div className="midModal">
                                <div className="midModalArea width800">
                                    <div className="modalPopUpClose" onClick={this.infoBoxModalHandlar}><i className="fa fa-times"></i></div>
                                    <h3 className="modalHeading">Viewing Live Sessions with Microsoft Teams </h3>
                                    <div className="modalContent p-4">
                                        <p>The first time you enter click on the link in the session screen and a new window will open. If you already have the Microsoft Teams app installed on your computer and are logged in choose “Launch it Now” and go right to the session.</p>
                                        <p>If you would like to view without installing the app choose watch on web, and join the meeting anonymously.</p>
                                        <p>If you would like to install the Teams app, you will need to log out of the virtual conference and install the app using the link on the log in page.</p>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        
                                <div>
                                    <div className="rightBarButton">
                                        <Link to="/goma/attendee/technicalSessions">
                                            <span>View All Sessions</span>
                                            <i className="fa fa-eye"></i>
                                        </Link>
                                    </div>
                                </div>
                        
                        <BottomNav />

                    </section>
                    {/*=====About Session Modal=====*/}
                    {
                        this.state.aboutSessionModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSessionModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Session</h3>
                                <div className="midModalAreaContent">
                                    <AboutSession passprops= {this.props}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====About Speaker Modal=====*/}
                    {
                        this.state.aboutSpeakerModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSpeakerModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Speaker</h3>
                                <div className="midModalAreaContent">
                                    <AboutSpeaker passprops= {this.props}/>
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    {
                        this.state.sessionAllDetailsModal
                        ?
                        <div className="midModal bigDataModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.sessionAllDetailsModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">Session Details</h3>
                                <div className="midModalAreaContent">
                                    <MainSessionAllDetails sessionId= {params.sessionId}/>
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Documents Modal=====*/}
                    {
                        this.state.sessionDocumentsModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.sessionDocumentsModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">Session Documents</h3>
                                <div className="midModalAreaContent">
                                    <SessionDocuments passprops= {this.props} />
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Survey Modal=====*/}
                    {
                        this.state.sessionSurveyModal
                        ?
                        <div className="midModal verticleCenterModal">
                            <div className="midContentWrp">
                                <div className="modalPopUpClose" onClick={this.sessionSurveyModalHandler}><i className="fa fa-times"></i></div>
                                <h3>Session Survey</h3>
                                <div className="midContentBx">
                                {
                            this.state.iframeLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                                    <iframe width="100%" height="450" src={`https://www.tslwebreg.com/sessionSurvey.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&role=${localStorage.getItem('currentRole')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                                </div>                               
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Clain CreditModal=====*/}
                    {
                        this.state.claimCreditInfoPopup 
                        ?
                        <div className="modalPopUp">
                            <div className="modalPopUpInr p-0">
                                <div className="modalPopUpClose" onClick={this.closeClaimCreditInfoPopupHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading">CE Credit</h3>
                                <div className="modalContent p-4">
                                    <div className="claimBx">
                                        {
                                        this.state.creditAlreadyClaimed === true
                                        ?
                                        <p><i class="fa fa-check"></i> {this.state.earnedCredit} CE’s Earned</p>
                                        :
                                        <p><i class="fa fa-times"></i>Please view entire session before claiming CME credit.</p>
                                    }
                                    </div>                                
                                </div>
                            </div>
                        </div> 
                        :
                        null
                    }

                    {/*=====popup before leave*/}
                    {
                        this.state.leaveBeforeClaimPopup 
                        ?
                        <div className="modalPopUp topAligned">
                            <div className="modalPopUpInr p-0">
                                <div className="modalPopUpClose" onClick={this.leaveBeforeClaimPopupHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading">CE Credit</h3>
                                <div className="modalContent p-4">
                                    <div className="claimBx">
                                        <p><i class="fa fa-times"></i>Please click to log time and claim CE credit before closing web browser.</p>
                                    </div>                                
                                </div>
                            </div>
                        </div> 
                        :
                        null
                    }
                    
                </React.Fragment>
            )
        
        
         
    }
}
export default ConferenceCenterPage;
