import React, { Component } from 'react';


import Axios from 'axios';

class FollowupList extends Component {
    constructor(props){
        super(props);

        this.state = {
            followupData: [],
            followUpSuccess:'',
            followUpErr:'',
            pageLoader : true,
        }
    }
    

    componentDidMount (){
            const {match : {params}}  = this.props.passprops;
            Axios.get(`${global.config.requestUrl}exhibitor/getFollowUps?exhibitorId=${params.exhibitorId}&eventId=${localStorage.getItem('eventId')}`, 
            {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
                if(!res.data.errorCode){
                this.setState({
                    followupData:res.data.data,
                    pageLoader : false
                })
            }else{
                this.setState({
                    pageLoader: false
                });
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    saveFollowUps = (title) => {
        const {match : {params}}  = this.props.passprops;
        if(params.exhibitorId === localStorage.getItem('userId')) return false;
        this.setState({pageLoader : true});
        const leadFollowUpAnalyticData = {
            exhibitorId: params.exhibitorId,
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            role:localStorage.getItem('currentRole'),
            followUp:title
        }
        Axios.post(`${global.config.requestUrl}user/insertFollowUpsVisitor`, leadFollowUpAnalyticData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        ).then(res => {
           if(res.data.errorCode){
               this.setState({followUpErr:'FollowUp already Added!!!',followUpSuccess:''})
           }else{
                this.setState({followUpErr:'',followUpSuccess:'FollowUp Added!!!'})
           }
           this.setState({pageLoader : false});
           setTimeout(() => {this.setState({followUpErr:'',followUpSuccess:''})}, 3000);
            
        })
        .catch(err => {
            console.log(err);
        });
        
    }

    render() {
     
    const followUpHtml = this.state.followupData.map((anObjectMapped, index) => {
            return (
                
                <tr key={index} onClick={() => this.saveFollowUps(anObjectMapped.title)}>
                <td>{`${anObjectMapped.title}`}</td>
               </tr>
            );
        })
        return (
            <React.Fragment>
                <div className="modalTableWrp">
                <div className="success">{this.state.followUpSuccess}</div>
                <div className="error">{this.state.followUpErr}</div>
                    <table className="table modalTable text-center mt-3">
                        <thead>
                            <tr>
                                <th>Follow Ups</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {followUpHtml} 
                        </tbody>
                    </table>
                </div>
                {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
            </React.Fragment>
        )
    }
}
export default FollowupList;