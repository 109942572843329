import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Homepage from "./Homepage";
import Header from "./Components/security22/Header";
import TechnicalSessions from './Components/security22/Attendee/TechnicalSessions';
import NotFound from "./Components/security22/NotFound";
import ConferenceCenterPage from './Components/security22/Attendee/ConferenceCenterPage';
import SingleSignOn from "./Components/security22/SingleSignOn";
import LoginSingleSignOn from './Components/security22/LoginSingleSignOn';


class Security22 extends Component {
  constructor() {
    super();

    this.state = {
      homePage: "",
    };
  }

  render() {
    const domain = window.location.href;
    if(domain === ("http://localhost:3000/" || "https://affectionate-bohr-4d306b.netlify.app/")){
      return(
        <div className="App">
            <Router>
              <Route path="/" exact component={Homepage} />
            </Router>
        </div>
      )
    }
    else{
        return (
            <Router>
                <div className="App">
                    <Header/>
                    <Switch>
                        <Route path="/security22/single-signon/:eventId/:emailId/:tokenpass" exact component={SingleSignOn} />
                        <Route path="/security22/loginSingleSignOn/:loginId" component={LoginSingleSignOn} />
                        <PrivateRoute path="/security22/attendee/technicalSessions" component={TechnicalSessions} />
                        <PrivateRoute path="/security22/attendee/conferenceCenterDetails/:sessionId" component={ConferenceCenterPage} />
                        <Route path="/security22/login/826" component={LoginSingleSignOn} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </div>
            </Router>
        )
    }
  }
}

export default Security22;
