import React, { Component } from 'react';

import {Link} from "react-router-dom";

class SpeakerSession extends Component {
    constructor(){
        super();
    }
    componentDidMount(){
       
    }
    render(){
        return(
        <div>Speaker Sessions</div>
        )
    }
}

export default SpeakerSession;