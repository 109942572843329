import React, { Component } from 'react';
import {Link, Redirect } from 'react-router-dom';
import Axios from 'axios';
import jwt_decode from 'jwt-decode';

class AddToScheduleRenderer extends Component {

    constructor(props){
        super(props);

        this.state={
            pageLoader : true,
        }
        
    }

    addToSessionSchedule = (sess_id,sess_name,sess_startdate,sess_starttime) => {

        if(localStorage.getItem('exhibitorToken')){
            const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                alert('Your sesssion expired, Login again to add session in schedule!!!');return;
            }
        }
        if(sess_startdate === null){
            sess_startdate = '0000-00-00';
        }
        document.getElementById('fordatatable').style.display = "block";
        const newBoothContact = {
            sessionId:sess_id,
            eventId:localStorage.getItem('eventId'),
            memberId:localStorage.getItem('userId'),
            role:localStorage.getItem('currentRole'),
            sName: sess_name,
            startDate: sess_startdate,
            startTime:sess_starttime,
            isSession:1
            
        }
        Axios.post(`${global.config.requestUrl}user/addEventToSchedule`, newBoothContact,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode === 0){
                var ButtonIdAdd = 'add_'+sess_id;
                var ButtonIdDel = 'del_'+sess_id;
                document.getElementById(ButtonIdAdd).style.display = "none";
                document.getElementById(ButtonIdDel).style.display = "block";
                const delbtn = document.getElementById(ButtonIdDel)
                delbtn.dataset.schid = res.data.schedule_id;
                var alreadyAddedSession = JSON.parse(localStorage.getItem('addedSession'));
                alreadyAddedSession[sess_id] = res.data.schedule_id;
                localStorage.setItem('addedSession',JSON.stringify(alreadyAddedSession));
                }
                document.getElementById('fordatatable').style.display = "none";
            })
            .catch(err => {
                console.log(err);
            }); 
    }

    deleteSessionSchedule = (sess_id,schedule_id) => {
        if(localStorage.getItem('exhibitorToken')){
            const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                alert('Your sesssion expired, Login again to delete session from schedule!!!');return;
            }
        }
        var ButtonIdAdd = 'add_'+sess_id;
        var ButtonIdDel = 'del_'+sess_id;

        if(schedule_id === undefined){
            schedule_id = document.getElementById(ButtonIdDel).dataset.schid;
        }


        document.getElementById('fordatatable').style.display = "block";
        const postdata = {
            eventId: localStorage.getItem('eventId'),
            scheduleId:schedule_id,
            memberId:localStorage.getItem('userId'),
           }

        Axios.post(`${global.config.requestUrl}user/deleteSchedule`, postdata,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
            .then(res => {
                if(res.data.errorCode === 0){
                    document.getElementById(ButtonIdAdd).style.display = "block";
                    document.getElementById(ButtonIdDel).style.display = "none";
                    var alreadyAddedSession = JSON.parse(localStorage.getItem('addedSession'));
                    delete alreadyAddedSession[sess_id];
                    localStorage.setItem('addedSession',JSON.stringify(alreadyAddedSession));
                    
                }
                document.getElementById('fordatatable').style.display = "none";
            })
            .catch(err => {
                console.log(err);
            }); 
    }

    render() {
        const sessId = this.props.valueFormatted ? this.props.valueFormatted : this.props.value;
        const sessName = this.props.data.session_name;
        const sessStartDate = this.props.data.start_date;
        const sessStartTime = this.props.data.start_time;
        var alreadyAddedSession = JSON.parse(localStorage.getItem('addedSession'));
        // console.log(this.props.addedSessionWithSch);
        // console.log(this.props.addedSessionWithSch[15651]);
            return (
                <React.Fragment>
               
                    <section className="text-center schButton">
                   

                        {

                            localStorage.getItem("exhibitorToken")!== null
                            ?


                            alreadyAddedSession[sessId] !== undefined
                            ?
                            (
                                
                                <React.Fragment><button id={`add_${sessId}`} style={{ display: "none" }} onClick={() => this.addToSessionSchedule(sessId,sessName,sessStartDate,sessStartTime)} className=""><em className="fa fa-plus btn btn-success"></em></button><button id={`del_${sessId}`} onClick={() => this.deleteSessionSchedule(sessId,alreadyAddedSession[sessId])} className=""><em className="maroonBtn fa fa-minus btn btn-secondary"></em></button>
                                </React.Fragment>
                            )
                            :
                            <React.Fragment><button id={`add_${sessId}`} onClick={() => this.addToSessionSchedule(sessId,sessName,sessStartDate,sessStartTime)} className=""><em className="fa fa-plus btn btn-success"></em></button><button id={`del_${sessId}`} style={{ display: "none" }} onClick={() => this.deleteSessionSchedule(sessId,alreadyAddedSession[sessId])} className=""><em className="maroonBtn fa fa-minus btn btn-secondary"></em></button>
                            </React.Fragment>


                            :
                            <React.Fragment>
                                <Link to="/focus2022/login/674" ><em className="fa fa-plus btn btn-success"></em></Link>
                            </React.Fragment>
                        }







                                                    
                                
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default AddToScheduleRenderer;
