import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';

class SpeakerDataRenderer extends Component {

    constructor(props){
        super(props);

        this.state={
            //pageLoader : true,
        }
        
    }

    getSpeakerRelatedData = (speakerId) =>{
        document.getElementById("speakerSessionData").innerHTML = '';
        document.getElementById("speakerBioData").innerHTML = '';
          this.getSpeakerBio(speakerId);
          this.getSpeakerSessions(speakerId);
          document.getElementById("speakerDetailsModal").style.display = "block";
    }
    getSpeakerBio = (speakerId) => {
        Axios.get(`${global.config.requestUrl}speaker/getSpeakerInfo?eventId=${localStorage.getItem('eventId')}&speakerId=${speakerId}`, 
            {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        
        .then(res => {
            var data = '';
            if(!res.data.errorCode){
              if(res.data.data.length === 1){
                  //console.log(res.data.data);
                res.data.data.map(content => (
                    data+='<div class="singlespeaker"><div class="speakercard" onclick="toggleSpeakerContent('+speakerId+')">'+content.firstName+' '+ content.lastName +'</div><div id="speakerdetail_'+speakerId+'"  style="display:block;"  > <img src="'+content.image+'" alt="" class="speakerAbtPic"/>'+content.bio+'</div></div>'
                 ))
              }
            }
            document.getElementById("speakerBioData").innerHTML = data;
        })
        .catch(err => {
            console.log(err);
        });
    }
    getSpeakerSessions = (speakerId) => {
        Axios.get(
          `${
            global.config.requestUrl
          }speaker/getSpeakerSessions?eventId=${localStorage.getItem(
            "eventId"
          )}&speakerId=${speakerId}`,
          { headers: { "auth-token": localStorage.exhibitorToken } }
        )
    
          .then((res) => {
            if (res.data.errorCode === 0) {
                //console.log(res.data);
                var data = '<h5>Speaker Sessions</h5><table>';
                res.data.data.map((session) => {
                     data+='<tr><td>'+session.session_name+'</td></tr>' ; 
                  })
                data+='</table>';
                document.getElementById("speakerSessionData").innerHTML = data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    
        
      };
    
    
    
    

    render() {
        var speakerIds = 0;
        speakerIds = this.props.data.speaker_ids;
        const sessId = this.props.data.session_id;
            return (
                <React.Fragment>
{

speakerIds.toString().split(',').map((speakerId, index) => {
    return <span className="singlespeakerspan" key = {index} onClick={() => this.getSpeakerRelatedData(speakerId)}>{ (index ? ', ' : '') + this.props.data.speaker_name.split(',')[index] }</span>; 
  })
}
                    

                    
                </React.Fragment>
            )
        
        
         
    }
}
export default SpeakerDataRenderer;
