import React, { Component } from 'react';
import BottomNav from './BottomNav'
import {Link} from "react-router-dom";
import Slider from "react-slick";
import Axios from 'axios';
import jwt_decode from 'jwt-decode'

class Lobby extends Component {
    constructor(){
        super();
        this.state={
            messageModal:false,
            sponsersImgList:[],
            mediaPartnerList:[],
            producedByList:[],
            sectionLoader:true,
            userMessages:[],
            leaderBoardParagraph:[],
            goldenTicketPointEarners:[],
            certificateModal:false,
            iframeLoader:true
        }
    }
    messageModalHandler = (e) =>{
        e.preventDefault();
        this.setState({messageModal:!this.state.messageModal})
    }
    certificateModalHandler = (e) => {
        e.preventDefault();
        this.setState({certificateModal:!this.state.certificateModal,iframeLoader:true})
    }
    goldenTicketModalHandler = (e) => {
        e.preventDefault();
        this.setState({pageLoader:true});
        Axios.get(`${global.config.requestUrl}user/getGoldenTicketUsers?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(!res.data.errorCode){
                this.setState({goldenTicketPointEarners:res.data.data});
            }
            this.setState({pageLoader:false});
        })
        .catch(err => {
            console.log(err);
        });
        this.setState({goldenTicketModal:!this.state.goldenTicketModal})
    }
    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }
    componentDidMount(){

// -1,-6,1441  => -10
// -1          => -1
// -6          => -6
// 1441        => -10
// -1,1441     => -10
// -6,1441     => -10
// -1,-6     => -1
//-1,-7 => -1 
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = -10;

            if(rolesArr.length > 2 ){
                currentRole = -10;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                currentRole = -1;
            }
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                currentRole = -6;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                currentRole = -1;
            }
            if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                currentRole = -1;
            }
            localStorage.setItem('currentRole',currentRole);
            
            
        }

        const token = localStorage.exhibitorToken;
        const decoded = jwt_decode(token);
        const details = {
            username: decoded.email,
            eventId:localStorage.getItem('eventId'),
            role:currentRole.toString()
        }

        Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
                        {headers: { "auth-token": localStorage.exhibitorToken }}
                    )
                    .then(res => {
                         if(res.data.errorCode === 0){
                            localStorage.setItem('companyContactUrl',res.data.data[0].profilePic)
                            localStorage.setItem('userProfilePic','')
                            localStorage.setItem('useraccname',res.data.data[0].name)
                            if(res.data.data[0].exhibitorId){
                                localStorage.setItem('userId',res.data.data[0].exhibitorId)
                            }
                            if(res.data.data[0].speakerId){
                                localStorage.setItem('userId',res.data.data[0].speakerId)
                            }
                            if(res.data.data[0].userId){
                                localStorage.setItem('userId',res.data.data[0].userId)
                                localStorage.setItem('userProfilePic',res.data.data[0].profilePic)
                            }
                            localStorage.setItem('company',res.data.data[0].company)
                            if(res.data.data[0].profilePic !==""){
                                document.getElementById("profile_img").src = res.data.data[0].profilePic;
                            }else{
                                document.getElementById("profile_img").src = '/images/placeholder-profile.jpg';;  
                            }
                        }
                        this.setState({pageLoader:false});
                        
                    })
                    .catch(err => {
                        console.log(err);
                    });
          
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?eventId=${localStorage.getItem('eventId')}&type=3`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            

            if(res.data.errorCode === 0){
                var leftSponsers = res.data.data.length / 2;
                this.setState({sponsersImgList:res.data.data, mediaPartnerList:res.data.mediaPartner, producedByList:res.data.producedBy,sectionLoader:false,leftSponsers:leftSponsers}) 
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    
                ]});
                this.setState({sectionLoader:false,leftSponsers:3})
            }

           
           
        })
        .catch(err => {
            console.log(err);
        });
            
        Axios.get(`${global.config.requestUrl}user/getEventMessages?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({userMessages:res.data.data}) 
            }
             
        })
        .catch(err => {
            console.log(err);
        });

        Axios.get(`${global.config.requestUrl}user/getLeaderboardContent?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {

            if(res.data.errorCode === 0){
                this.setState({leaderBoardParagraph:res.data.data})
            }
                       
            
        })
        .catch(err => {
            console.log(err);
        });

        }
    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            autoplay: true,
            arrows:true,
            vertical:true,
          };
          const msgSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            fade: true,
            arrows:false,
          };
        return (
            <React.Fragment>
                <section className="bg9 signleScreen attendeeLobbyPage">
                    <div className="topCenterSponsor"> 
                        <h2 className="levelHeading">GOLD<span>LEVEL</span></h2>
                        <ul>
                            {
                                this.state.sectionLoader
                                ?
                                (
                                    <div className="sectionLoader">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                )
                                :
                                null
                            }
                            { 
                                this.state.sponsersImgList.map(sponsers => (

                                    <div key={sponsers.exhibitor_id}>
                                    {sponsers.logo_level === 1
                                        ?
                                        
                                            sponsers.exhibitor_id === 0
                                            ?
                                            (
                                            <a href={sponsers.sponsor_link} target="_blank">
                                                <img src={sponsers.logo} alt=""/>
                                            </a>
                                            )
                                            :
                                            <Link to={`/paos/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                <img src={sponsers.logo} alt=""/>
                                            </Link>
                                        
                                        :
                                        null 
                                    }
                                    </div>
                                ))
                            }                            
                        </ul>
                        
                    </div>
                    <div className="centerSponsers">
                        <ul>
                            <li><img src={process.env.PUBLIC_URL + '/images/paos/paos_banner.jpg'} alt="" /></li>
                        </ul>
                    </div>
                    <div className="twoSideSponsers">
                        <div className="topLeftSponsers">
                            <h2 className="levelHeading">SILVER<span>LEVEL</span></h2>
                            <ul>
                                {
                                    this.state.sectionLoader
                                    ?
                                    (
                                        <div className="sectionLoader">
                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                        </div>
                                    )
                                    :
                                    null
                                }
                                { 
                                    this.state.sponsersImgList.map(sponsers => (

                                        <div key={sponsers.exhibitor_id}>
                                        {sponsers.logo_level === 3
                                            ?
                                            
                                                sponsers.exhibitor_id === 0
                                                ?
                                                (
                                                <a href={sponsers.sponsor_link} target="_blank">
                                                    <img src={sponsers.logo} alt=""/>
                                                </a>
                                                )
                                                :
                                                <Link to={`/paos/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                    <img src={sponsers.logo} alt=""/>
                                                </Link>
                                            
                                            :
                                            null 
                                        }
                                        </div>
                                    ))
                                }                          
                            </ul>
                        </div>
                        <div className="topRightSponsers">
                            <h2 className="levelHeading">BRONZE<span>LEVEL</span></h2>
                            <ul>
                                {
                                    this.state.sectionLoader
                                    ?
                                    (
                                        <div className="sectionLoader">
                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                        </div>
                                    )
                                    :
                                    null
                                }
                                { 
                                    this.state.sponsersImgList.map(sponsers => (

                                        <div key={sponsers.exhibitor_id}>
                                        {sponsers.logo_level === 4
                                            ?
                                            
                                                sponsers.exhibitor_id === 0
                                                ?
                                                (
                                                <a href={sponsers.sponsor_link} target="_blank">
                                                    <img src={sponsers.logo} alt=""/>
                                                </a>
                                                )
                                                :
                                                <Link to={`/paos/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                    <img src={sponsers.logo} alt=""/>
                                                </Link>
                                            
                                            :
                                            null 
                                        }
                                        </div>
                                    ))
                                }                            
                            </ul>
                            
                        </div>
                    </div>
                    {
                        window.innerWidth < 767
                        ?
                        <div className="lobbyNav">
                            <ul>
                                <li>
                                    <Link to="/paos/attendee/lobby">
                                        <img src={process.env.PUBLIC_URL + '/images/paos/bottom-nav-lobby.png'} alt="" />
                                        <span>Lobby</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/paos/attendee/exhibitHall">
                                        <img src={process.env.PUBLIC_URL + '/images/paos/bottom-nav-exhibit-hall.png'} alt="" />
                                        <span>Exhibit Hall</span>
                                    </Link>
                                </li>
                                <li>
                                    {<Link to="/paos/attendee/technicalSessions">
                                        <img src={process.env.PUBLIC_URL + '/images/paos/bottom-nav-conference-hall.png'} alt="" />
                                        <span>Conference Center</span>
                                    </Link>}
                                    {/* <a href="https://www.minnesotasafetycouncil.org/conference.conference.html" target="_blank" >
                                        <img src={process.env.PUBLIC_URL + '/images/paos/bottom-nav-conference-hall.png'} alt="" />
                                        <span>Conference Center</span>
                                    </a> */}
                                </li>
                                {/* <li>
                                    <Link to="/paos/attendee/networkingLounge">
                                        <img src={process.env.PUBLIC_URL + '/images/paos/bottom-nav-networking-lounge.png'} alt="" />
                                        <span>Networking lounge</span>
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/" onClick={this.myScheduleModalHandler}>
                                        <img src={process.env.PUBLIC_URL + '/images/paos/schedule_button.png'} alt="" />
                                        <span>My Schedule</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        :
                        null
                    }
                    {/* <div className="resPonsiveBar">
                        <div className="lobbyLinks" onClick={this.goldenTicketModalHandler}>
                        {
                            window.innerWidth <= 767
                            ?
                            <h3>Golden Ticket Leaders</h3>
                            :
                            null
                        }
                            <img src={process.env.PUBLIC_URL + '/images/paos/win_proze_with_ticket_bg.png'} alt="" />
                        </div>
                        <div className="lobbyMsg"  onClick={this.messageModalHandler}>
                            {
                                window.innerWidth <= 767
                                ?
                                <h3>Message Board Announcements</h3>
                                :
                                null
                            }
                            <img src={process.env.PUBLIC_URL + '/images/paos/lobby-msg-stand.png'} alt="" />
                            <div className="msgScroller">
                                
                                <Slider {...msgSettings}>
                                    { 
                                        this.state.userMessages.map(message => (
                                            <div className="msg" key={message.msg_id}>
                                                <h4>{message.title}</h4>
                                                <p>{message.description}</p>
                                            </div>
                                        ))
                                        
                                    } 
                                </Slider>
                                <div className="text-left">
                                    <button>View More <i className="fa fa-long-arrow-right"></i></button>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="stickyBtn">
                        <button onClick={this.certificateModalHandler}>
                            <i className="fa fa-address-card"></i> 
                            <span>Conference Survey</span>
                        </button>
                        <a href="https://firebasestorage.googleapis.com/v0/b/tslvirtual.appspot.com/o/pdf_doc%2FPAOS%2FNCCPA%20Logging.pdf?alt=media&token=b6c3273b-47af-44f6-83fb-ef9e55d49a1d" target="_blank">
                            <i className="fa fa-address-card"></i> 
                            <span>NCCPA</span>
                        </a>
                        <a href="https://firebasestorage.googleapis.com/v0/b/tslvirtual.appspot.com/o/pdf_doc%2FPAOS%2FSelf%20Assessment%20and%20CME%20Certificate.pdf?alt=media&token=e40ef925-33e2-4250-be58-c7716c50331a" target="_blank">
                            <i className="fa fa-address-card"></i> 
                            <span>Self Assessment</span>
                        </a>
                    </div>
                    <BottomNav />
                </section>
                {/*=====Message Board Modal=====*/}
                {
                    this.state.messageModal 
                    ?
                    <div className="modalPopUp">
                        <div className="modalPopUpInr p-0">
                            <div className="modalPopUpClose" onClick={this.messageModalHandler}><i className="fa fa-times"></i></div>
                            <h3 className="modalHeading">Messages</h3>
                            <div className="modalContent p-4">
                                <div className="msgListWrp">
                                    <ul>
                                    { 
                                        this.state.userMessages.map(message => (
                                            <li className="msg" key={message.msg_id}>
                                                <h4>{message.title}</h4>
                                                <p>
                                                {
                                          message.description.split('\n').map(function(item, key) 
                                          {
                                            return (
                                                    <span key={key}>
                                                    {item}
                                                    <br/>
                                                    </span>
                                                    )
                                          }
                                         )
                                         }
                                                </p>
                                            </li>
                                        ))
                                        
                                    } 
                                    </ul>
                                </div>                                
                            </div>
                        </div>
                    </div> 
                    :
                    null
                }
                {/*=====Certificate Modal=====*/}
                {
                    this.state.certificateModal
                    ?
                    <div className="midModal verticleCenterModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" onClick={this.certificateModalHandler}><i className="fa fa-times"></i></div>
                            <h3>Conference Survey</h3>
                            <div className="midContentBx">
                              {
                               this.state.iframeLoader
                               ?
                               (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                               )
                               :
                               null
                              }
                              
                                <iframe width="100%" height="450" src={`https://www.tslwebreg.com/eventSurvey.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&role=${localStorage.getItem('currentRole')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                            </div>                               
                        </div>
                    </div>
                    :
                    null
                }
                {/*=====Golden Ticket Modal=====*/}
                {
                    this.state.goldenTicketModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" onClick={this.goldenTicketModalHandler}><i className="fa fa-times"></i></div>
                            <h3>Leaderboard</h3>
                                <div className="midContentBx">
                                { 
                                    this.state.leaderBoardParagraph.map(paragraph => (
                                        <div className="mb-2" key={paragraph.golden_ticket_id}>

                                         {
                                          paragraph.header.replace(/(<([^>]+)>)/gi, "").split('\n').map(function(item, key) 
                                          {
                                            return (
                                                    <span key={key}>
                                                    {item}
                                                    <br/>
                                                    </span>
                                                    )
                                          }
                                         )
                                         }
                                        </div>
                                    ))    
                                }
                                    <div className="leaderTable">
                                    {
                                        this.state.pageLoader
                                        ?
                                        (
                                            <div className="loaderWrp">
                                                <div className="loaderInr">
                                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                </div>
                                            </div>
                                        )
                                        :
                                        null
                                        }
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Points</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            { 
                                              this.state.goldenTicketPointEarners.map(earner => (
                                                <tr key={earner.name}>
                                                  <td>{earner.name}</td>
                                                  <td>{earner.total_points}</td>
                                                </tr>
                                              ))    
                                            }
                                            { 
                                              this.state.goldenTicketPointEarners.length == 0
                                              ?
                                              <tr><td colSpan="2">No Data Found</td></tr>
                                              :
                                              null 
                                            }
                                          </tbody>
                                        </table>
                                    </div>
                                </div>                               
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
export default Lobby; 
