import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';

const initialState = {
    password:"",
    passwordError : "",
    confirmPassword:"",
    confirmpasswordError:"",
    isValidParams: false,
    paramInvalidError : "",
    resetLink :"",
    submitSuccess : "",
    submitError : "",
    
  }
class ResetPassword extends Component {
    constructor(props){
        super(props);

        this.state = initialState;
        
    }
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
      }

    componentWillMount(){

      
      let eventId =  this.getQueryVariable('eventId');
      this.setState({pageLoader:true,resetLink : 'login/'+eventId})

      Axios.get(`${global.config.requestUrl}user/verifyForgotPassLink${this.props.location.search}`)
        .then(res => {
           
            if(res.data.errorCode > 0 ){
                this.setState({paramInvalidError:res.data.errorMessage})
            }else{
                this.setState({isValidParams:true})
            }
            this.setState({
               
                pageLoader:false,
            })

          
        })
        .catch(err => {
            console.log(err);
        });
        

      
     
        
    }

    getQueryVariable(variable) {
        var query = this.props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (decodeURIComponent(pair[0]) === variable) {
                return decodeURIComponent(pair[1]);
            }
        }
        console.log('Query variable %s not found', variable);
    }

    // componentDidMount() {
    //     console.log(43434343434)
    // }

    validateForm(){
       let flag = 0;
       if(this.state.password === ''){
        flag =1;
        this.setState({passwordError:'cannot be blank'})
       }else if(this.state.password.length < 8){
        this.setState({ passwordError:'Password lenght atleast 8 characters'})
       }else{
         this.setState({ passwordError:''})
       }
       if(this.state.confirmPassword === ''){
        flag =1;
        this.setState({confirmpasswordError:' cannot be blank'})
       }else{
        this.setState({confirmpasswordError:''})
       }
       if(this.state.confirmPassword !== this.state.password  && this.state.confirmPassword !=="" ){
        flag =1;
         this.setState({
             confirmpasswordError:' confirm password should match with password '
         })
       }

    
       if(flag){
           return false;
       }else{
           return true;
       }

    }

    resetPasswordSubmit = (e) => {
        e.preventDefault();
        if(this.validateForm()){
            this.setState({pageLoader:true})
            Axios.post(`${global.config.requestUrl}user/resetPassword`, {
                email: this.getQueryVariable('email'),
                eventId:this.getQueryVariable('eventId'),
                newPass: this.state.password,
                confirmPass: this.state.confirmPassword
              })
            .then(res => {
                this.setState({pageLoader:false}); 
                if(res.data.errorCode !== 0){
                    this.setState({submitError:res.data.errorMessage, submitSuccess:"" })
                }
               
                if(res.data.errorCode === 0){
                    this.setState({submitSuccess:res.data.errorMessage, submitError:"",email :"" })
                    setTimeout(() => {
                        this.props.history.push('/fna/login/'+this.getQueryVariable('eventId'))
                    }, 800);
                    
                }
                this.setState({pageLoader:false})
                
              })
              .catch(err => {
                console.log(err);
              });
        }
    }
    render() {
        return (
            <React.Fragment>
                <section className="bg1 signleScreen">
                    <div className="midFormBx">
                        <img src={process.env.PUBLIC_URL + '/images/logo-black.png'} alt="" />
                        <h3 className="mt-3 mb-3">Reset Password</h3>
                        <div className="error">{this.state.submitError}</div>
                        <div className="success">{this.state.submitSuccess}</div>
                        {
                          this.state.pageLoader
                          ?
                          (
                           <div className="loaderWrp">
                              <div className="loaderInr">
                                  <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                              </div>
                           </div>
                          )
                          :
                          null
                        }
                        {
                          this.state.isValidParams
                          ?
                          (
                        <form onSubmit={this.resetPasswordSubmit} noValidate>
                            <div className="form-group">
                                <input type="password" name="password"  placeholder="Password" onChange={this.onChange} value={this.state.password} className="form-control"/>
                                <div className="errorMessage">{this.state.passwordError}</div>
                            </div>
                            <div className="form-group">
                                <input type="password" name="confirmPassword"  placeholder="Confirm Password" onChange={this.onChange} value={this.state.confirmPassword} className="form-control"/>
                                <div className="errorMessage">{this.state.confirmpasswordError}</div>
                            </div>
                            <div className="form-group text-center mt-3">
                                <button className="btn" type="submit">
                                    <i className="fa fa-paper-plane"></i>
                                    <span>Reset</span>
                                </button>
                            </div>
                            
                        </form>
                          )
                          :
                          <div>
                          <div className="invalidparams error">{this.state.paramInvalidError}</div>
                          <p><Link to = {this.state.resetLink} >Reset again</Link></p>
                          </div>
                        }
                        
                    </div>
                </section>
            </React.Fragment>
        )
    }
}
export default ResetPassword;
