import React, { Component } from 'react';
import BottomNav from './BottomNav';
import FilterableExhibitorList from './FilterableExhibitorList';

import {Link} from 'react-router-dom';

import Axios from 'axios';




class ExhibitHall extends Component {
    constructor(props){
        super(props);

        this.state = {
            sponsersImgList : [],
            exhibitorList : [],
            pageLoader:true,
            exhibitListModal:false,
        }
        
    }
    exhibitListModalHandler = () => {
        this.setState({exhibitListModal:!this.state.exhibitListModal})
    }





    componentDidMount(){
      
        /* getting sponsers logos */
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?type=4&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
        
       
         .then(res => {
            
             if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, sponsersUrl:res.data.logo, apierrorCode:res.data.errorCode})
                 this.setState({ pageLoader:false})
                 if(window.location.href.includes('popup=1')){
                    this.setState({exhibitListModal:true})
                 }
             } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                 this.setState({pageLoader:false})
                 
             }
             
         })
         .catch(err => {
             console.log(err);
         });
         
        
    }
   


    render() {

            return (
                <React.Fragment>
               
                    <section className="bg1 signleScreen overflow-hidden">
                            <div className="exhibitHallBx">
                                <h2>2021 Exhibitors</h2>
                                <div className="exhibitHallBxInr">
                                    <div className="sponSec">
                                        <div class="levelHeading goldHeading">Gold Sponsors</div>
                                        <div className="sponsersList">
                                            { 
                                                this.state.sponsersImgList.map(sponsers => (
                                                    sponsers.logo_level === 1
                                                    ?
                                                        sponsers.exhibitor_id === 0
                                                        ?
                                                        (
                                                        <a href={sponsers.sponsor_link} target="_blank">
                                                          <img src={sponsers.logo} alt=""/>
                                                        </a>
                                                        )
                                                        :
                                                        <Link to={`/paos/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                           <img src={sponsers.logo} alt=""/>
                                                        </Link>
                                                    :
                                                    null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="sponSec">
                                        <div class="levelHeading platinumHeading">Silver Sponsors</div>
                                        <div className="sponsersList">
                                            { 
                                                this.state.sponsersImgList.map(sponsers => (
                                                    sponsers.logo_level === 3
                                                    ?
                                                       sponsers.exhibitor_id === 0
                                                       ?
                                                       (
                                                       <a href={sponsers.sponsor_link} target="_blank">
                                                          <img src={sponsers.logo} alt=""/>
                                                       </a>
                                                       )
                                                       :
                                                       <Link to={`/paos/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                           <img src={sponsers.logo} alt=""/>
                                                       </Link>
                                                    :
                                                    null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="sponSec">
                                        <div class="levelHeading diamondHeading">Bronze Sponsors</div>
                                        <div className="sponsersList">
                                            { 
                                                this.state.sponsersImgList.map(sponsers => (
                                                    sponsers.logo_level === 4
                                                    ?
                                                       sponsers.exhibitor_id === 0
                                                       ?
                                                       (
                                                       <a href={sponsers.sponsor_link} target="_blank">
                                                          <img src={sponsers.logo} alt=""/>
                                                       </a>
                                                       )
                                                       :
                                                       <Link to={`/paos/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                           <img src={sponsers.logo} alt=""/>
                                                       </Link>
                                                    :
                                                    null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    <div className="sponSec">
                                        <div class="levelHeading">Exhibitors</div>
                                        <div className="sponsersList">
                                            { 
                                                this.state.sponsersImgList.map(sponsers => (
                                                    sponsers.logo_level === 0
                                                    ?
                                                       sponsers.exhibitor_id === 0
                                                       ?
                                                       (
                                                       <a href={sponsers.sponsor_link} target="_blank">
                                                          <img src={sponsers.logo} alt=""/>
                                                       </a>
                                                       )
                                                       :
                                                       <Link to={`/paos/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                           <img src={sponsers.logo} alt=""/>
                                                       </Link>
                                                    :
                                                    null
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BottomNav />
                            {
                             this.state.pageLoader
                             ?
                             (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                            )
                            :
                            null
                           }
                           <div className="bootomButton">
                                <button className="btn btn-success" onClick={this.exhibitListModalHandler}><i className="fa fa-eye"></i> View All Exhibitors</button>
                           </div>
                    
                    {
                        this.state.exhibitListModal
                        ?
                        <div className="midModal">
                            <div className="midTable exhibitorListTable">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.exhibitListModalHandler}><i class="fa fa-times"></i></div>
                                <FilterableExhibitorList/>
                                
                            </div>
                        </div>
                        :
                        null
                    }
                </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default ExhibitHall;
