import React, { Component } from 'react'
import Axios from 'axios';
import { Link, withRouter } from "react-router-dom";


export default class AboutSession extends Component {
    constructor(props){
        super(props);

        this.state={
            aboutSessionContent:[],
            aboutSessionError:"",
            pageLoader:true
        }
    }
    componentDidMount(){
        const {match : {params}}  = this.props;
        Axios.get(`${global.config.requestUrl}getSessionBio?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({aboutSessionContent:res.data.data, aboutSessionError:res.data.errorCode});
                console.log(res.data.data)
            }
            this.setState({pageLoader:false})
        })
        .catch(err => {
            console.log(err);
        });
    }
    
    render() {
        
        return (
          <React.Fragment>
            <section className="sessionScreen signleScreen paddingTop">
              
              
            {
                this.state.pageLoader
                ?
                (
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                )
                :
                null
            }
            {
                <React.Fragment>
                    <div className="midModal">
                            <div className="midModalArea">
                                <h3 className="modalHeading pt-3 pb-3" style={{'position':'relative'}}>
                                    <Link to="/focus2022/sessionList" style={{'position':'absolute','left':'10px'}}>
                                    <i class="fa fa-arrow-left"></i>
                                    </Link>About Session</h3>
                                <div className="midModalAreaContent">
                                {
                        this.state.aboutSessionContent.map(content => (
                            <p key={content.session_id}>{content.description}</p>
                        ))
                    }
                                </div>
                    
                     </div>
                            </div>
                </React.Fragment>
            }
              
            </section>
          </React.Fragment>
        );
      }
}
