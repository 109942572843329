import React, { Component } from 'react';
import LeftNavBar from './LeftNavBar';
//import { CSVLink } from "react-csv";
import Axios from 'axios';

class SessionAttendees extends Component {
    constructor(props){
        super(props);

        this.state = {
            csvData:[],
            sessionLists:[],
            pageLoader:true,
            csvfilename:'speakersessionUsers.csv',
        }

    }

    componentDidMount(){
        if(global.config.speakerAttendees === 0 ){
            this.props.history.push(`/fna/disabled/`)
        }
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            if(rolesArr.indexOf('-6') === -1){
                this.props.history.push('/fna/notfound');
            }
        }
        this.getSpeakerSessions();
    }

    getSpeakerSessions = () => {
        Axios.get(`${global.config.requestUrl}speaker/getSpeakerSessions?eventId=${localStorage.getItem('eventId')}&speakerId=${localStorage.getItem('userId')}`,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            
            .then(res => {
                console.log(res.data.data, "sessionID")
                if(res.data.errorCode === 0){
                    this.setState({sessionLists:res.data.data})  
                }
                this.setState({pageLoader:false})
            })
            .catch(err => {
                console.log(err);
            });
    }

    

    downloadSessionUsers = (sessionId) => {
        this.setState({pageLoader:true})
        let btn = this.refs.csv;
        Axios.get(`${global.config.requestUrl}speaker/getUserListBySession?eventId=${localStorage.getItem('eventId')}&sessionId=${sessionId}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            if(res.data.errorCode === 0 ){
                this.setState({
                    csvData:res.data.data,
                    csvfilename: sessionId+'_userData.csv'
                }, () => {
                    this.setState({pageLoader:false})
                    setTimeout(() => {btn.link.click()}, 200);
                    
                });
                
            }
         
        })
        .catch(err => {
            console.log(err);
        });
    }

    render() {

        return (
            <React.Fragment>
                <section className="bg1 signleScreen">
                    <LeftNavBar />
                    {/* <div className="container pt-120">
                        <div className="row">
                            <div className="col-12">
                                <div className="sessionTableList">
                                    <h3>List of Sessions</h3>
                                    <table>
                                        <tr>
                                            <td>
                                               <CSVLink data={this.state.csvData} ref="csv" filename={this.state.csvfilename} className="btn btn-primary" style={{display:'none'}}>
                                                   Download
                                               </CSVLink>
                                            </td>
                                        </tr>

                                        

                                            {
                                                this.state.sessionLists.length === 0
                                                ?
                                                <tr>
                                                    <td colSpan="2"><h4>No data found</h4></td>
                                                </tr>
                                                :
                                                <React.Fragment>
                                                                {
                                                this.state.sessionLists.map(sessionList => (
                                                    <tr key={sessionList.session_id}>
                                                      <td>
                                                <label htmlFor="id1">{sessionList.session_name}</label>
                                                      </td>
                                                       <td className="text-right"  onClick={() => this.downloadSessionUsers(sessionList.session_id)}>
                                                           <img src={process.env.PUBLIC_URL + '/images/excel.png'} alt="" />
                                                       </td>
                                                    </tr>
                                                ))
                                            }
                                            </React.Fragment>
                                            }
                                         
                                        
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                    
                </section>
            </React.Fragment>
        )
    }
}
export default SessionAttendees;