import React, { Component } from 'react';
import BottomNav from './BottomNav';
import FilterableExhibitorList from './FilterableExhibitorList';

import {Link} from 'react-router-dom';

import Axios from 'axios';




class ExhibitHall extends Component {
    constructor(props){
        super(props);

        this.state = {
            sponsersImgList : [],
            exhibitorList : [],
            pageLoader:true,
            exhibitListModal:false,
        }
        
    }
    exhibitListModalHandler = () => {
        this.setState({exhibitListModal:!this.state.exhibitListModal})
    }

    componentWillReceiveProps(){
        if(window.location.href.includes('popup=1')){
            this.setState({exhibitListModal:true})
         }
    }



    componentDidMount(){
      
        /* getting sponsers logos */
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?type=4&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
        
       
         .then(res => {
            
             if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, sponsersUrl:res.data.logo, apierrorCode:res.data.errorCode})
                 this.setState({ pageLoader:false})
                 if(window.location.href.includes('popup=1')){
                    this.setState({exhibitListModal:true})
                 }
             } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},{'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                 this.setState({pageLoader:false})
                 
             }
             
         })
         .catch(err => {
             console.log(err);
         });
         
        
    }
   


    render() {

            return (
                <React.Fragment>
               
                    <section className="bg1 signleScreen">
                            <div className="topHangerBrands">
                                <div className="staticLogo">
                                    <img src={process.env.PUBLIC_URL + '/images/csda/sponsor.jpg'} alt="" />
                                </div>

                                <ul className="exhibitHallList">
                                    { 
                                        this.state.sponsersImgList.map((sponsers,index) => (
                                            <li key={index}>
                                            <img src={process.env.PUBLIC_URL + '/images/top-brand-hanger-stand.png'} alt="" />
                                                <div className="brandLogoBx">
                                                    <img src={process.env.PUBLIC_URL + '/images/top-brand-hanger-bx.png'} alt="" />
                                                {
                                                    sponsers.exhibitor_id === 0
                                                    ?
                                                    (
                                                    <a href={sponsers.sponsor_link} target="_blank">
                                                       <img src={sponsers.logo} alt="" className="topBrandLogo"/>
                                                    </a>
                                                    )
                                                    :
                                                    <Link to = {`/csda/attendee/exhibitorBooth/${sponsers.exhibitor_id}`} >
                                                       <img src={sponsers.logo} alt="" className="topBrandLogo" />
                                                    </Link>
                                                }
                                                </div>
                                            </li>
                                        ))
                                        
                                    }                            
                                </ul>
                                
                            </div>

                            {/* <div className="bottomHangerBrands">

                            <ul>
                                    { 
                                        this.state.sponsersImgList.slice(9, 14).map((sponsers,index) => (
                                            <li key={index}>
                                            <img src={process.env.PUBLIC_URL + '/images/bottom-sponsers-stand.png'} alt="" />
                                            <div className="brandLogoBx">
                                                <img src={process.env.PUBLIC_URL + '/images/top-brand-hanger-bx.png'} alt="" />
                                                {
                                                    sponsers.exhibitor_id === 0
                                                    ?
                                                    (
                                                    <a href={sponsers.sponsor_link} target="_blank">
                                                       <img src={sponsers.logo} alt="" className="topBrandLogo"/>
                                                    </a>
                                                    )
                                                    :
                                                    <Link to = {`/csda/attendee/exhibitorBooth/${sponsers.exhibitor_id}`} >
                                                       <img src={sponsers.logo} alt="" className="topBrandLogo" />
                                                    </Link>
                                                }
                                                
                                            </div>
                                        </li>
                                        ))
                                        
                                    }                            
                                </ul>
                                
                            </div> */}
                            <BottomNav />
                            {
                             this.state.pageLoader
                             ?
                             (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                            )
                            :
                            null
                           }
                           <div className="bootomButton">
                                <button className="btn btn-success" onClick={this.exhibitListModalHandler}><i className="fa fa-eye"></i> View All Exhibitors</button>
                           </div>
                    
                    {
                        this.state.exhibitListModal
                        ?
                        <div className="midModal">
                            <div className="midTable exhibitorListTable">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.exhibitListModalHandler}><i class="fa fa-times"></i></div>
                                <FilterableExhibitorList/>
                                
                            </div>
                        </div>
                        :
                        null
                    }
                </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default ExhibitHall;
