import React, { Component } from "react";

import jwt_decode from "jwt-decode";
import LeftNavBar from "./LeftNavBar";
import SessionsAndPostersEdit from "./SessionsAndPostersEdit";
import Axios from "axios";
import { Link } from "react-router-dom";
import ChatIcon from "../../../TmChat/csda/chat/ChatIcon";

class DashboardSpeaker extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      pageLoader: true,
      sectionLoader: true,
      showChatIcon: false,
      sessionLists: [],

      resetSuccess: "",
      resetMessage: "",
      watchDemoModal: false,
    };
  }
  
  componentDidMount() {
    
    if (localStorage.getItem("roles")) {
      var rolesArr = localStorage.getItem("roles").split(",");
      var currentRole = "";

      if (rolesArr.indexOf("-6") !== -1) {
        currentRole = -6;
        localStorage.setItem("currentRole", currentRole);
      } else {
        this.props.history.push("/notfound");
      }
    }
  }

  
  render() {
    return (
      <React.Fragment>
        <section className="bg7 signleScreen">
          <div className="dashboardWrapper">
            <LeftNavBar />
            <div className="rightContentAera">
              
                <div className="mainDashboardHeading">
                  <h3>Speaker Main Dashboard</h3>
                </div>
                <SessionsAndPostersEdit />
              
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}
export default DashboardSpeaker;
