import React, { Component } from 'react';
import BottomNav from './BottomNav'

import {Link} from 'react-router-dom';




class ExhibitList extends Component {
    constructor(props){
        super(props);

        this.state = {
            
        }
        
    }

    render() {

            return (
                <React.Fragment>
               
                    <section className="bg1 signleScreen paddingTop">
                            <div className="midTable exhibitorListTable">
                                <h3>Exhibitor List</h3>
                                <div className="sortBy">
                                    <div className="sortBx">
                                        <label>Filter By:</label>
                                        <select>
                                            <option>All</option>
                                            <option>All</option>
                                        </select>
                                    </div>
                                    <div className="sortBx">
                                        <label>Sort By:</label>
                                        <select>
                                            <option>All</option>
                                            <option>All</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="midTableWrp">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Exhibitor Logo</th>
                                                <th>Company Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-1.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-2.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-3.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-4.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-5.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-1.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-3.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-4.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-3.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><p><img src={process.env.PUBLIC_URL + '/images/top-brand-4.png'} alt="" /></p></td>
                                                <td>
                                                    <h4>Toyo Tanso </h4>
                                                </td>
                                                <td>
                                                    <button><i className="fa fa-eye"></i><span>View</span></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                                <BottomNav />
                            
                            
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default ExhibitList;
