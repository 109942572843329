import React, { Component } from 'react';


import Axios from 'axios';

class TechnicalMarketingDocs extends Component {
    constructor(props){
        super(props);

        this.state = {
            documentsFile:null,
            documentsVideoFile:null,
            documentsVideoLinkShare:"",
            docDataServer : [],
            docs : [],
            pageLoader : true,
        }
    }
    

    
    

      componentDidMount (){
        const {match : {params}}  = this.props.passprops;
        Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorBoothDocs?exhibitorId=${params.exhibitorId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
console.log(res.data.data);
     if(!res.data.errorCode){
        this.setState({
            docDataServer:res.data.data,
            pageLoader: false
        });
      }else{
        if(res.data.errorCode === 1){
            this.setState({
                docDataServer:[],
                pageLoader: false
            });
        }else{
            this.setState({
                pageLoader: false
            }); 
        }
         
      }
       
        
    })
    .catch(err => {
        console.log(err);
    });
   
    }

     

    render() {

    const dochtml = this.state.docDataServer.map((anObjectMapped, index) => {
        var type = '';
           if(anObjectMapped.fileType === 1){
               type = 'video';
           }else{
            type = 'document';
           }
           var fileName = anObjectMapped.fileName;
           if(fileName === ""){
            fileName = 'No Name';
        }
            return (
                
                <tr key={index}>
                <td>{fileName}</td>
                <td>{type}</td>
                <td><a href={`${anObjectMapped.file}`} target="_blank" rel="noopener noreferrer" className="btn btn-success">View</a></td>
               </tr>
            );
        })
        return (
            <React.Fragment>
                <div className="modalTableWrp">
                    <table className="table modalTable text-center mt-3">
                        <thead>
                            <tr>
                                <th>Documents/Video</th>
                                <th>Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dochtml} 
                        </tbody>
                    </table>
                </div>
                {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
            </React.Fragment>
        )
    }
}
export default TechnicalMarketingDocs;