import React, { Component } from 'react';
import BottomNav from './BottomNav'
import Axios from 'axios';
import {Link} from 'react-router-dom';



import AboutSession from './AboutSession';
import AboutSpeaker from './AboutSpeaker';
import SessionDocuments from './SessionDocuments';



class ConferenceCenterPage extends Component {
    constructor(props){
        super(props);

        this.state={
            sponsersImgList:[],
            mediaPartnerList:[],
            producedByList:[],
            sectionLoader:true,
            aboutSessionModal:false,
            aboutSpeakerModal:false,
            sessionDocumentsModal:false,
            liveVideoUrl:[],
            liveVideoUrlNotFound:"",
            
            liveUrlNull:"",
            ip:'127.0.0.1',
            pageLoader:true,
            upgradeModal:false,
            paidUser:false,
            iframeLoader:true,
            sessionSurveyModal:false,
            timeSpendInSeconds:0,
            minAttendance:0, // in seconds
            
            sessionIdUrl:"",
        }
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = -10;

            if(rolesArr.length > 2 ){
                currentRole = -10;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                currentRole = -1;
            }
            if(rolesArr.indexOf('-1') === -1 && rolesArr.indexOf('-7') === -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length === 1 ){
                currentRole = -6;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') === -1 && rolesArr.length === 1 ){
                currentRole = -1;
            }
            if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                currentRole = -1;
            }
            localStorage.setItem('currentRole',currentRole);
            
        }
        
    }

    componentWillUnmount = () => {
        if(this.state.timeSpendInSeconds >= this.state.minAttendance){
          this.updateScannedData();
        }
    }
    componentDidMount(){
        if(global.config.attendeeConferenceCenterDetails === 0 ){
            this.props.history.push(`/fna/disabled/`)
        }
        localStorage.setItem('conferenceClicked',0);
        //const {match : {params}} = this.props;
        
        this.getSponsersLogos();
        this.onLoadingFn(this.props);
        fetch('https://geoip-db.com/json/')
           .then(res => res.json())
           .then(json => this.setState({ip: json.IPv4}));
    }


    componentWillReceiveProps(nextProps){
        localStorage.setItem('conferenceClicked',0);
        document.getElementById('myschedulepopup').click();
        this.onLoadingFn(nextProps);
        
   }
   
   onLoadingFn = (nextProps) =>{
    this.setState({pageLoader:true});
    const details = {
        username: localStorage.getItem('userName'),
        eventId:localStorage.getItem('eventId'),
        role:localStorage.getItem('currentRole'),
    }

    Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
            {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        .then(res => {
             if(res.data.errorCode === 0){
                 if(localStorage.getItem('currentRole') !== '-10' || res.data.data[0].reg_type !== 1442 ){
                    this.setState({paidUser:true})
                    this.getSessionData(nextProps);
                    this.updateGoldenTicketStatus(nextProps);
                    this.saveAuthForSurvey(nextProps);
                 }else{
                     this.setState({upgradeModal:true})
                 }
             }
             this.setState({pageLoader:false});
            
        })
        .catch(err => {
            console.log(err);
        });
    
}

   getSponsersLogos = () => {
    Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?eventId=${localStorage.getItem('eventId')}&type=3`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, mediaPartnerList:res.data.mediaPartner, producedByList:res.data.producedBy,sectionLoader:false});
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({sectionLoader:false});
            }
        })
        .catch(err => {
            console.log(err);
        });
}
   sessionSurveyModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionSurveyModal:!this.state.sessionSurveyModal,iframeLoader:true})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSessionModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSessionModal:!this.state.aboutSessionModal})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSpeakerModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSpeakerModal:!this.state.aboutSpeakerModal})
        localStorage.setItem('conferenceClicked',1)
    }
    sessionDocumentsModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionDocumentsModal:!this.state.sessionDocumentsModal})
        localStorage.setItem('conferenceClicked',1)
    }
    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }
    upgradePaymentIframeModelHandeler = (e) => {
        e.preventDefault();
        this.setState({upgradePaymentIframeModel:!this.state.upgradePaymentIframeModel,iframeLoader:true})
        if(this.state.upgradePaymentIframeModel){
            this.onLoadingFn();
        }
    }
    updateScannedData = () => {

        
        const {match : {params}} = this.props;
            const scannedData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                accountId:localStorage.getItem('accountId'),
                serialNumber:this.state.ip,
                role:localStorage.getItem('currentRole'),
            }

            //console.log(scannedData);return false;

            Axios.post(`${global.config.requestUrl}user/insertScanData`, scannedData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    updateGoldenTicketStatus = (myprop) => {
            this.setState({pageLoader:true});
            const {match : {params}} = myprop;
            const ticketStatusData = {
                visitorId:localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                ticketValue:0,
                linkPage:'sessionRoom',
                sessionId:params.sessionId,
                exhibitorId:0
                
            }
            Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
            .then(res => {
            if(res.data.errorCode){
                document.getElementById('goldenTkt').classList.add("greyScale");
                this.setState({pageLoader:false});
            }else{
                this.setState({pageLoader:false});
                document.getElementById('goldenTkt').classList.remove("greyScale");
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
    

    countDown = () => {
      if(this.state.timeSpendInSeconds === this.state.minAttendance){
        this.updateScannedData();
      }
      var clk = this.state.timeSpendInSeconds;
      this.setState({timeSpendInSeconds: clk + 1});
       
    }

    getSessionData = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        Axios.get(`${global.config.requestUrl}user/getSessionVideo?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({liveVideoUrl:res.data.data, liveVideoUrlNotFound:res.data.errorCode, liveUrlNull:res.data.data[0].video_url, minAttendance:res.data.data[0].min_attendence*60, pageLoader:false, sessionIdUrl:res.data.data[0].session_id})
            if(res.data.errorCode === 0){
                this.setState({})
                setInterval(this.countDown, 1000);
                if(res.data.data[0].video_url.includes('https://success.zoom.us/wc/join/')){
                    document.getElementById('videoconf').innerHTML = res.data.data[0].video_text+'<div class="hoverText"><p>Click below to view live webinar.  Password is FNA2020</p><a target="_blank" href ="'+res.data.data[0].video_url+'" > Click here </a></div>';
                }
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader:false});
        });
    }


        saveAuthForSurvey = (myprop) => {
            const {match : {params}} = myprop;
            const authSurveyData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
            }

            Axios.post(`${global.config.requestUrl}user/insertSessionForSurvey`, authSurveyData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    render() {

            return (
                <React.Fragment>  
                    <section className="bg6 signleScreen paddingTop">
                        {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                        <div className="conferenceLinks">
                            <ul>
                                <li><Link onClick={this.aboutSessionModalHandler} to="/">About this session</Link></li>
                                <li><Link onClick={this.aboutSpeakerModalHandler} to="/">About the speaker</Link></li>
                                <li><Link onClick={this.sessionDocumentsModalHandler} to="/">Session documents</Link></li>
                                <li><Link onClick={this.sessionSurveyModalHandler} to="/">Session survey</Link></li>
                            </ul>
                        </div>
                        <div className="conferenceVideoWrp">
                            {
                                this.state.liveVideoUrlNotFound !== 0
                                ?
                                <div className="conferenceVideo videoNotFound">
                                    {/* <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" /> */}
                                </div>
                                :
                                <React.Fragment>
                                    
                                    { 
                                        this.state.liveVideoUrl.map(videoLink => (
                                            <React.Fragment>
                                                <h3>{videoLink.session_name}</h3>
                                                {
                                                    this.state.liveUrlNull === null || this.state.liveUrlNull === ""
                                                    ?
                                                    <div className="conferenceVideo videoNotFound">
                                                        <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" />
                                                    </div>
                                                    :
                                                    <div className="conferenceVideo" id="videoconf">
                                                        {
                                                        videoLink.video_url.includes('https://success.zoom.us/wc/join/')
                                                        ?
                                                        null
                                                        :
                                                        <iframe width="100%" height="315" title="session video" src={`${videoLink.video_url}?rel=0`} key={videoLink.video_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        }
                                                   </div>
                                                }
                                                
                                            </React.Fragment>
                                        ))
                                    }
                                
                                </React.Fragment>

                            }
                                
                      
                        </div>
                        <div className="conferenceSponserLogo">
                            <ul>
                            

                                { 
                                    this.state.producedByList.map(produceby => (
                                        <li key={produceby.exhibitor_id}>
                                            <Link to={`/fna/attendee/exhibitorBooth/${produceby.exhibitor_id}`}>
                                                <img src={produceby.logo} alt=""/>
                                            </Link>
                                        </li>
                                    ))
                                    
                                }


                                <li><img src={process.env.PUBLIC_URL + '/images/lobby/cs-2.jpg'} alt="" /></li>
                                

                                { 
                                    this.state.mediaPartnerList.map(mediapartner => (
                                        <li key={mediapartner.exhibitor_id}>
                                            <Link to={`/fna/attendee/exhibitorBooth/${mediapartner.exhibitor_id}`}>
                                                <img src={mediapartner.logo} alt=""/>
                                            </Link>
                                        </li>
                                    ))
                                    
                                }
                            </ul>
                        </div>
                        <div className="bottomStripSponsers">
                            <ul>
                                {
                                    this.state.sectionLoader
                                    ?
                                    (
                                        <div className="sectionLoader">
                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                        </div>
                                    )
                                    :
                                    null
                                }
                                { 
                                    this.state.sponsersImgList.map(sponsers => (
                                        <li key={sponsers.exhibitor_id}>
                                            <Link to={`/fna/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                <img src={sponsers.logo} alt=""/>
                                            </Link>
                                        </li>
                                    ))
                                    
                                }                            
                            </ul>
                        </div>
                        <div className="rightBarButton">
                            <Link to="/fna/attendee/technicalSessions">
                                <span>View All Technical Sessions</span>
                                <i className="fa fa-eye"></i>
                            </Link>
                            <Link to= {`/fna/speaker/session/${this.state.sessionIdUrl}`} target="_blank">
                                <span>Start Session Chat</span>
                                <i class="fa fa-comments"></i>
                            </Link>
                        </div>
                        <BottomNav />

                    </section>
                    {/*=====About Session Modal=====*/}
                    {
                        this.state.aboutSessionModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSessionModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Session</h3>
                                <div className="midModalAreaContent">
                                    <AboutSession passprops= {this.props}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====About Speaker Modal=====*/}
                    {
                        this.state.aboutSpeakerModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSpeakerModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Speaker</h3>
                                <div className="midModalAreaContent">
                                    <AboutSpeaker passprops= {this.props}/>
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Documents Modal=====*/}
                    {
                        this.state.sessionDocumentsModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.sessionDocumentsModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">Session Documents</h3>
                                <div className="midModalAreaContent">
                                    <SessionDocuments passprops= {this.props} />
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Survey Modal=====*/}
                    {
                        this.state.sessionSurveyModal
                        ?
                        <div className="midModal verticleCenterModal">
                            <div className="midContentWrp">
                                <div className="modalPopUpClose" onClick={this.sessionSurveyModalHandler}><i className="fa fa-times"></i></div>
                                <h3>Session Survey</h3>
                                <div className="midContentBx">
                                {
                            this.state.iframeLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                                    <iframe width="100%" height="450" title="survey popup" src={`https://www.tslwebreg.com/sessionSurvey.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&role=${localStorage.getItem('currentRole')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                                </div>                               
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                this.state.upgradeModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                            <h3>Upgrade</h3>
                            <div className="midContentBx pt-5 pb-5 midForm">
                                <div className="row">
                                        <div className="offset-md-1 col-md-10">
                                            <h5 className="text-center mb-4">Conference Center access only with Full Conference Registration, Click below to upgrade</h5>
                                            <div className="text-center">
                                                <button className="btn btn-warning" onClick={this.upgradePaymentIframeModelHandeler}> <span>Upgrade</span></button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {/*=====Certificate Modal=====*/}
                {
                    this.state.upgradePaymentIframeModel
                    ?
                    <div className="midModal verticleCenterModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" onClick={this.upgradePaymentIframeModelHandeler}><i className="fa fa-times"></i></div>
                            <h3>Ugrade Here</h3>
                            <div className="midContentBx">
                              {
                               this.state.iframeLoader
                               ?
                               (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                               )
                               :
                               null
                              }
                              
                                <iframe width="100%" height="450" title="vc payments" src={`https://www.tslwebreg.com/VCPayments.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&pagetype=ATT`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                            </div>                               
                        </div>
                    </div>
                    :
                    null
                }
                    
                </React.Fragment>
            )
        
        
         
    }
}
export default ConferenceCenterPage;
