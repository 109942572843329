import React,{useState,useEffect} from 'react'
import './fna2022.css'
import { Link } from "react-router-dom";

export default function Header({exhibitorToken}) {

    const [unreadCount,setUnreadCount] = useState(false);

    window.addEventListener("all_unread_count_set",function(){
        setUnreadCount(true);
    });

    window.addEventListener("all_unread_count_clear",function(){
        setUnreadCount(false);
    });

    useEffect(()=>{
       setTimeout(() => {
            window.dispatchEvent( new Event('get_all_unread_count') );
       },500)
    },[0]);

    return (
        <header className="mainHeader" >
            <link rel="shortcut icon" href={process.env.PUBLIC_URL + '/images/fna2022/fna2022.png'} />
            <link rel="apple-touch-icon" href={process.env.PUBLIC_URL + '/images/fna2022/fna2022.png'} />
            <meta name="apple-mobile-web-app-capable" content="no" />
            <div className="logo"><img src={process.env.PUBLIC_URL + '/images/fna2022/logo.png'} alt="" /></div>
            {exhibitorToken ? <span className={`chat-btn ${unreadCount ? 'unread-count' : ''}`}>
                <Link to="/fna2022/chat"><img alt='' src={process.env.PUBLIC_URL + "/images/chat-ico.png"} className='img-fluid w-100 h-100'/></Link>
            </span> : ''}
        </header>
    )
}