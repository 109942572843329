import React, { Component } from 'react'
import MySchedule from './MySchedule'
import {Link} from "react-router-dom";
import { Accordion, Card, Button  } from 'react-bootstrap';
import Axios from 'axios';


export default class MainNav extends Component {
    constructor(){
        super();
        this.state={
            myScheduleModal:false,
            helpDeskModal:false,
            helpDeskContents:[],
            mainNavToggleClass:"centerNav"
        }
    }
    
    myScheduleModalHandler = (e) => {
        e.preventDefault();
        this.setState({myScheduleModal:!this.state.myScheduleModal})
    }
    mobileMenuHandler = (e) => {
        this.setState({mainNavToggleClass: "centerNav showMenu"})
    }
    closeMobileMenu = (e) => {
        this.setState({mainNavToggleClass: "centerNav"})
    }
    helpDeskModalHandler = (e) =>{
        e.preventDefault();
        this.setState({helpDeskModal:!this.state.helpDeskModal})
    }
    getHelpDeskData = () => {
        Axios.get(`${global.config.requestUrl}user/getHelpDeskContent?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(!res.data.errorCode)
               this.setState({helpDeskContents:res.data.data})
        })
        .catch(err => {
            console.log(err);
        });
    }
    componentDidMount(){
        this.getHelpDeskData();

        let pathArray = window.location.pathname.split('/');
        if(pathArray[3] === "exhibitorBooth"){
            this.setState({chatHide:false})
        }
        console.log(pathArray)
    }
    render() {
        return (
            <React.Fragment>
                <div className="mobileMenu" onClick={this.mobileMenuHandler}>
                    <img src={process.env.PUBLIC_URL + '/images/right-arrow-angle.png'} alt="" />
                </div>
                <div className={this.state.mainNavToggleClass}>
                    <div className="modalPopUpClose mobile" onClick={this.closeMobileMenu}>
                        <i className="fa fa-times"></i>
                    </div>
                    <ul>
                        <li>
                            <Link to="/navigate2022/attendee/lobby">
                                <span onClick={this.closeMobileMenu}>Lobby</span>
                            </Link>
                        </li>
                        <li>
                            {<Link to="/navigate2022/attendee/technicalSessions">
                                <span onClick={this.closeMobileMenu}>Sessions</span>
                            </Link>}
                        </li>
                        <li>
                            <Link to="/navigate2022/attendee/exhibitHall">
                                <span onClick={this.closeMobileMenu}>Expo</span>
                            </Link>
                        </li>
                        
                        <li>
                            <Link to="/navigate2022/attendee/networkingLounge">
                                <span onClick={this.closeMobileMenu}>Chat lounge</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={this.myScheduleModalHandler}>
                                <span onClick={this.closeMobileMenu}>My Schedule</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={this.helpDeskModalHandler}>
                                <span onClick={this.closeMobileMenu}>Information</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                {
                    this.state.myScheduleModal
                    ?
                    <div className="midModal">
                        <div className="modalOverlay" onClick={this.myScheduleModalHandler}></div>
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" id="myschedulepopup" onClick={this.myScheduleModalHandler}><i className="fa fa-times"></i></div>
                            <MySchedule props={this.props} />                             
                        </div>
                    </div>
                    :
                    null
                }
                {
                    this.state.helpDeskModal
                    ?
                    <div className="midModal">
                        <div className="modalOverlay" onClick={this.helpDeskModalHandler}></div>
                        <div className="midContentWrp exhibitHallBx">
                            <div class="modalPopUpClose" onClick={this.helpDeskModalHandler}><i class="fa fa-times"></i></div>
                            <h2>Info Desk</h2>
                            <div className="midContentBx">
                                <Accordion defaultActiveKey="0">
                                    { 
                                        this.state.helpDeskContents.map(content => (
                                            <Card key={content.help_desk_id}>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={content.help_desk_id}>
                                                        {content.title}
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={content.help_desk_id}>
                                                    <Card.Body>

                                                    {content.description.split('\n').map(function(item, key) {
                                          return (
                                                   <span key={key}>
                                                   {item}
                                                   <br/>
                                                   </span>
                                                 )
                                           })}
                                                        
                                                       
                                                        
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))    
                                    }
                                    
                                </Accordion>
                            </div>                                
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
