import React,{useState, useEffect} from 'react';
import Axios from 'axios';
import { Accordion, Card, Button  } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const SessionDetailsModal = (props) => {

    const [speakerBioContent, setSpeakerBioContent] = useState([]);
    const [aboutSpeakerError, setAboutSpeakerError] = useState([]);
    const [sessionDocuments, setSessionDocuments] = useState([]);
    const [speakerDocError, setSpeakerDocError] = useState([]);
    const [sessionBio, setSessionBio] = useState([]);
    const [PageLoader, setPageLoader] = useState(true);
    

    useEffect(() => {
        getSpeakerBio();
        getSessionDocuments();
        getSessionBio();
    }, []);

    const getSpeakerBio = () => {
        Axios.get(`${global.config.requestUrl}user/getSpeakerBio?eventId=${localStorage.getItem('eventId')}&sessionId=${localStorage.getItem('modalSessionId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            //this.setState({speakerBioContent:res.data.data, aboutSpeakerError:res.data.errorCode, pageLoader:false})
            setSpeakerBioContent(res.data.data)
            setAboutSpeakerError(res.data.errorCode)
            setPageLoader(false)
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getSessionDocuments = () => {
        Axios.get(`${global.config.requestUrl}user/getSessionDocuments?eventId=${localStorage.getItem('eventId')}&sessionId=${localStorage.getItem('modalSessionId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            //this.setState({sessionDocuments:res.data.data, speakerDocError:res.data.errorCode, pageLoader:false})
            setSessionDocuments(res.data.data)
            setSpeakerDocError(res.data.errorCode)
            setPageLoader(false)
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getSessionBio =()=>{
        Axios.get(`${global.config.requestUrl}user/getSessionBio?eventId=${localStorage.getItem('eventId')}&sessionId=${localStorage.getItem('modalSessionId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                setSessionBio(res.data.data[0].description)
            }
            setPageLoader(false)
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            {
                PageLoader
                ?
                <div className="loaderWrp">
                    <div className="loaderInr">
                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                    </div>
                </div>
                :
                null
            }
            <p>{ReactHtmlParser(sessionBio)}</p>
            <Accordion defaultActiveKey="0">
                {
                    aboutSpeakerError !== 0
                    ?
                    null
                    :                    
                    speakerBioContent.length === 1
                    ?
                    speakerBioContent.map(content => (
                        <Card key={Math.random()}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    {content.name}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>
                                    <img src={content.profile_pic} alt="" className="speakerAbtPic"/>
                                    <h5 className="mb-3 mt-3">{content.title}</h5>
                                    {ReactHtmlParser(content.about)}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))
                    :
                    speakerBioContent.map(content => (
                        <Card key={content.speaker_id}>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey={content.speaker_id}>
                                    {content.name}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={content.speaker_id}>
                                <Card.Body>
                                    <img src={content.profile_pic} alt="" className="speakerAbtPic"/>
                                    <h5 className="mb-3 mt-3">{content.title}</h5>
                                    {ReactHtmlParser(content.about)}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))
                    
                }
                
            </Accordion>

            {
                    speakerDocError !== 0
                    ?
                    null
                    :
                    <div className="modalTableWrp">
                        <table className="table modalTable text-center mt-3">
                            <tbody>
                                    <React.Fragment>
                                        {
                                            sessionDocuments.map(content => (
                                                <tr key={content.doc_id}>
                                                    <td className="text-left">
                                                        <a href={content.file} target="_blank" rel="noopener noreferrer">{content.name}</a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </React.Fragment>
                            </tbody>
                        </table>
                    </div>
                }
        </>
    )
}
export default SessionDetailsModal;