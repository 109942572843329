import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";

class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            dropMenu:false,
            allowAsExhibitor:false,
            allowAsSpeaker : false,
            allowAsLobbier : true,
        }
    }
    logOut = (e) => {
        e.preventDefault();
        var eventId = localStorage.getItem('eventId');
        localStorage.clear();
        this.props.history.push(`/fna/login/${eventId}`)
    }
    dropMenuToggle = e => {
        this.setState({dropMenu:!this.state.dropMenu})
    }
    componentDidMount(){

        
    }
    componentDidUpdate(prevProps, prevState) {
        if (localStorage.getItem('companyContactUrl')) {
            localStorage.getItem('companyContactUrl')
        }
    }
    // componentWillReceiveProps(props) {
      
    //     if(localStorage.getItem('roles')){
    //         var rolesArr = localStorage.getItem('roles').split(",");
    //         if(rolesArr.indexOf('-1') !== -1){
    //           this.setState({allowAsExhibitor:true});
    //         }
    //         if(rolesArr.indexOf('-6') !== -1){
    //           this.setState({allowAsSpeaker:true});
    //         } 
    //         // if(rolesArr.indexOf('-7') !== -1){
    //         //   this.setState({allowAsExhibitor:true});
    //         // } 
    //     }

    //   }
    render() {
        var rolesArr = [];
        if(localStorage.getItem('roles')){
             rolesArr = localStorage.getItem('roles').split(",");
             var pImg = process.env.PUBLIC_URL + '/images/placeholder-profile.jpg';

            if(rolesArr.length > 2 ){
                pImg = process.env.PUBLIC_URL + '/images/placeholder-profile.jpg';
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 && localStorage.getItem('companyContactUrl') !== "" ){
                pImg = localStorage.getItem('companyContactUrl');
            }
            if(rolesArr.indexOf('-1') === -1 && rolesArr.indexOf('-7') === -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length === 1  && localStorage.getItem('speakerProfilePic') !== ""){
                pImg = localStorage.getItem('speakerProfilePic');
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') === -1 && rolesArr.length === 1 && localStorage.getItem('companyContactUrl') !== "" ){
                pImg = localStorage.getItem('companyContactUrl');
            }
            //console.log(pImg);
        }




        const userNotLoggedIn = (
            <li className="nav-item ">
                <button onClick={this.dropMenuToggle}>
                    <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                </button>
            </li> 
        );
        const userLoggedIn = (
            <React.Fragment>
                <li className="nav-item userProfilePic">
                    {/* <img src={ localStorage.getItem('companyContactUrl') || process.env.PUBLIC_URL + '/images/placeholder-profile.jpg'} alt="" id="profile_img" /> */}
                    <img src={pImg} 
                        alt="" id="profile_img" />
                </li> 
                <li className="nav-item">
                    <button onClick={this.dropMenuToggle}>
                        <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                    </button>
                    {
                        this.state.dropMenu 
                        ?
                        <ul className="dropMenu">

                            { 
                                    rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1
                                    ?
                                    (<li onClick={this.dropMenuToggle}><Link to="/fna/exhibitor/dashboard"><i className="fa fa-user"></i> Exhibitor Dashboard</Link></li>
                                    ):null
                            }
                            { 
                                    rolesArr.indexOf('-6') !== -1
                                    ?
                                    (<li onClick={this.dropMenuToggle}><Link to="/fna/speaker/dashboard"><i className="fa fa-user"></i> Speaker Dashboard</Link></li>
                                    ):null
                            }
                            
                            <li onClick={this.dropMenuToggle}><Link to="/fna/attendee/lobby"><i className="fa fa-user"></i> Attendee Lobby</Link></li>
                            <li onClick={this.dropMenuToggle}><Link to="/" onClick={this.logOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link></li>
                        </ul>
                        :
                        null
                    }
                </li>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <header className="pageMainHeader">
                    <nav className="navbar navbar-expand-sm" id="navMenu">
                        <div className="container">
                            <Link className="navbar-brand" to="/" target="_blank">
                                <img src={process.env.PUBLIC_URL + '/images/logo-white.png'} alt="" />
                            </Link>
                            <div id="collapsibleNavbar">
                            <ul className="navbar-nav ml-auto">
                                {/* <li className="nav-item">
                                    <a className="nav-link" href="">Link</a>
                                </li> */}
                                
                                {localStorage.getItem("exhibitorToken") ? userLoggedIn : userNotLoggedIn}
                            </ul>
                            </div>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        )
    }
}
export default withRouter(Header);