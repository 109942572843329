import React, {Component} from "react";
import ChatHeader from "../chat/ChatHeader";
import troopServer from "../utility/troopServer";
import utility from "../utility/utility";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import tmChatServer from "../utility/server";
import {Link} from "react-router-dom";
import tmChat from "../utility/tmChat";
import TroopMessenger from "troop-messenger-chat-sdk";
import CattleCall from "cattlecall-conference-sdk";
let initalState;
let cattlecall;
let troopMessenger;
let tmLoginUserDetails;
class Meetings extends Component {
    constructor() {
        super();
        initalState = {
            showError: false,
            loadingMeetings:true,
            errorMessage: '',
            meetingDuration: '',
            meetingTitle: '',
            addedUsers: [],
            meetingUsers: [],
            meetingDate: '',
            meetingTime: '',
            addMeetingModal: false,
            submitAddMeeting: false,
            addUserListActiveRole: -10,
            usersList: {},
            addUsersModal: false,
            meetingDetails: {},
            checkedUsers:[],
            addMeetingProgress: false,
            eventId:localStorage.getItem('eventId')

        };
        this.state = initalState;
        this.searchUsers=this.searchUsers.bind(this);
    }
    connectCattleCall=()=>{
            console.log("call init");
        cattlecall=new CattleCall("L0gJS27u4pHxwbh5L0gJS27u4pHxwbh5","HGupYTsKbiJWZSDOqw3O01ajKAgNUTnf");
        cattlecall.connect(this.state.userDetails.cattle_call_id);
        cattlecall.on("ready",(data)=>{
               console.log(data);
        }).on("error",(data)=>{
            console.log(data)
        });
    }

    async componentDidMount() {
        if(document.querySelector('header')){
            document.querySelector('header').style.display='none';
        }
        const userDetailsdata = await troopServer.getLoginUserDetails()
        if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            const loginUserDetails = userDetailsdata.data[0];

            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
            loginUserDetails.uid = uid;

            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });


            loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
            loginUserDetails.cattle_call_id = getUserDetails.cattle_call_id;
            this.setState({
                userDetails: loginUserDetails,
                isLoading: false
            });
             this.connectTMChat();
             this.connectCattleCall();
        }
        this.loadMeetingsList(20, 0);
    }
    componentWillUnmount(){
        if(document.querySelector('header')){
            document.querySelector('header').style.display='block';
        }
    }
    copyLink =(roomId,password)=>{
      let text=  window.location.origin+process.env.PUBLIC_URL +"/conference-meeting/"+roomId+"?pwd="+password
        utility.copyToClipboard(text)
    }
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                console.log(message, "mmessage");
            })
            .on("connected", async () => {
                console.log("sdsdsdsdsd");
                this.setState({troopConnection: troopMessenger});

            })
    };
    openAddMeetingModal = () => {
        this.setState({
            'addMeetingProgress': !this.state.addMeetingProgress,
            "addMeetingModal": !this.state.addMeetingModal
        })
        this.setState({
            showError: false,
            errorMessage: '',
            meetingDuration: '',
            meetingTitle: '',
            addedUsers: [],
            meetingDate: '',
            meetingTime: '',
            submitAddMeeting: false,
            addUserListActiveRole: -10,
            usersList: {},
            addUsersModal: false,
            checkedUsers:[]});
    }
    openAddUsersModal = () => {
        if (this.state.addMeetingModal) {
            this.setState({"addMeetingModal": !this.state.addMeetingModal})
        }
        if (this.state.addUsersModal && this.state.addMeetingProgress) {
            this.setState({'addMeetingModal': !this.state.addMeetingModal})
        }
        if (!Object.keys(this.state.usersList).length) {
            this.setState({'loadingUsers': true});
            this.loadUsersList(-10, 10, 0);
        }
        this.setState({"addUsersModal": !this.state.addUsersModal})
    }
    loadUsersList(role, limit = 10, offset = 0) {
        let parameters = {"role": role, "offset": 0, "limit": 20};
        troopServer.getUsersList(parameters).then((result) => {
            let users=[];
              if(result.data && result.data.length) {
                   users = result.data.map((user) => {
                      if (this.state.checkedUsers.indexOf(user.userId + user.role)!==-1) {
                          user.userSelected = true;
                          return user;
                      } else {
                          return user;
                      }
                  })
              }
            this.state.usersList[role] = users;
            this.setState({
                'loadingUsers': false,
                'usersListWithRole': this.state.usersList[this.state.addUserListActiveRole]
            })
        });
    }
    activeUsers(role) {
        this.state.addUserListActiveRole = role;
        if (this.state.usersList[role] === undefined) {
            this.setState({'loadingUsers': true});
            this.loadUsersList(role, 10, 0);
        } else {
            this.setState({'loadingUsers': false, 'usersListWithRole': this.state.usersList[role]})

        }
    }

    loadMeetingsList = async (limit, offset) => {
        let meetings = await tmChatServer.getMeetings(limit, offset);
        this.setState({'meetingsList': meetings.meetings,'loadingMeetings':false});
    }
    dateChange = date => {
        this.setState({
            meetingDate: date
        });
    };
    timeChange = time => {
        this.setState({meetingTime: time});
    };
    durationChange = e => {
        const re = /^[0-9\b]+$/;

        // if value is not blank, then test the regex

        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({meetingDuration: e.target.value})
        }
    };
    titleChange = (e) => {
        this.setState({meetingTitle: e.target.value});
    }

    formatTheUsers=(user,meetingDetails)=>{
        return new Promise(async (resolve) => {
            let message=`Title: ${meetingDetails.title}
                         Date:${meetingDetails.date_time}
                         Meeting Link:   ${utility.generateMeetingLink(meetingDetails)}
                         Meeting ID : ${meetingDetails.room_id}
                         password : ${meetingDetails.password}`;
            let uid=utility.generateUserUid({'userId':user.userId,role:user.role});
            let tmDetails = await this.state.troopConnection.register(uid, user.name);
            let  tm_user_id=tmDetails.tm_user_id;
    let details={
        receiver_id:tm_user_id,
        sender_uid:this.state.userDetails.uid,
        receiver_uid:uid,
        message:message,
        conversation_reference_id:this.state.eventId,
        user_id:this.state.userDetails.tm_user_id };
            return resolve(details);

        });
    }

    addMeeting = async () => {
        if (!this.state.meetingTitle || !this.state.meetingTitle.trim()) {
            this.setState({showError: true, errorMessage: "Please Enter Meeting Title"});
            return false;
        }
        if (!this.state.meetingDate) {
            this.setState({showError: true, errorMessage: "Please Select Meeting Date"});
            return false;
        }
        if (!this.state.meetingTime) {
            this.setState({showError: true, errorMessage: "Please Select Meeting Time"});
            return false;
        }
        if (!this.state.meetingDuration || !this.state.meetingDuration.trim()) {
            this.setState({showError: true, errorMessage: "Please Enter Meeting Duration"});
            return false;
        }
        if(parseInt(this.state.meetingDuration)>120){
            this.setState({showError: true, errorMessage: "Meeting Duration Should be less than 120"});
            return false;
        }
        this.setState({showError: false, errorMessage: "", 'submitAddMeeting': true});
        let dateTime = moment(this.state.meetingDate).format('DD-MM-YYYY') + ' ' + moment(this.state.meetingTime).format('HH:mm');

        let submitData = {
            'title': this.state.meetingTitle, 'date': this.state.meetingDate,
            'time': this.state.meetingTime,
            'duration': this.state.meetingDuration,
            'date_time': moment(dateTime, "DD-MM-YYYY HH:mm").utc().format('YYYY-MM-DD HH:mm')
        }
        submitData['usersList'] = this.state.addedUsers.map((user) => {
            return {userId: user.userId, 'role': user.role};
        });
        let response = await tmChatServer.addMeeting(submitData);
        let promises=[];
        const meetingCattleCallId=response.meeting_id;
            console.log(cattlecall,"cattleccall");
        cattlecall.scheduleMeeting(response.room_id,response.password,this.state.userDetails.cattle_call_id).then((meetingData)=>{
              console.log(meetingData.cc_meeting_id,"After Creation");
            tmChatServer.updateMeeting({'cattle_call_meeting_id':meetingData.cc_meeting_id,'meeting_id':meetingCattleCallId});
        })
          this.state.addedUsers.forEach((user)=>{
              promises.push(this.formatTheUsers(user,{'title':submitData['title'],
                  'room_id':response.room_id,
                  'password':response.password,
                  'date_time':moment(dateTime, "DD-MM-YYYY HH:mm").utc().format('YYYY-MM-DD HH:mm')}));
          });
        Promise.all(promises).then((result)=>{
            tmChat.sendForkOutMessage(this.state.troopConnection,result)
        });
        this.loadMeetingsList();
        this.setState({
            showError: false,
            errorMessage: '',
            meetingDuration: '',
            meetingTitle: '',
            addedUsers: [],
            meetingDate: '',
            meetingTime: '',
            addMeetingModal: false,
            submitAddMeeting: false,
            addUserListActiveRole: -10,
            usersList: {},
            addUsersModal: false,
            checkedUsers:[],
            loadingMeetings:true,
            addMeetingProgress: false
        });
    }

    addUserToMeeting = (e, index, userId, role) => {
        this.state.usersList[this.state.addUserListActiveRole][index]['userSelected'] = !this.state.usersList[this.state.addUserListActiveRole][index]['userSelected']
        if (this.state.usersList[this.state.addUserListActiveRole][index]['userSelected']) {
            this.state.addedUsers.push(this.state.usersList[this.state.addUserListActiveRole][index]);
             this.state.checkedUsers.push(userId+role);
        } else {
            let userIndex = this.state.addedUsers.findIndex((user) => {
                if (user.userId === userId && user.role === role) {
                    return user;
                }
            });
            if (userIndex !== -1) {
                let users=this.state.addedUsers;
                users.splice(userIndex, 1);
                this.setState({addedUsers:users});
            }
            let userSelectedIndex = this.state.checkedUsers.findIndex((user) => {
                if (user === userId+role) {
                    return user;
                }
            });
            if (userSelectedIndex !== -1) {
                this.state.checkedUsers.slice(userSelectedIndex, 1);
            }
        }
        const myNewArray = Object.assign([...this.state.usersListWithRole], {
            [index]: this.state.usersListWithRole[index]
        });
        this.setState({'usersListWithRole': myNewArray,usersList: this.state.usersList});
    }
    showMeetingDetails = async (index) => {

        this.setState({'meetingDetails': this.state.meetingsList[index], 'meetingUsers': []});
        let users = await tmChatServer.getMeetingUsers(this.state.meetingsList[index].id);
        if (users && users.meetingUsers) {
            this.getMeetingUsers(users.meetingUsers);
        }
    }
    getMeetingUsers = async (users) => {
        for (let user of users) {
           let userDetails=await troopServer.getUsersListBasedOnRole({'ids':''+user['user_id'],'role':user['role']});
           console.log(this.state.meetingUsers,"meetingUsersss",userDetails.data[0]);
           let users=this.state.meetingUsers.concat(userDetails.data[0])
           this.setState({'meetingUsers':users});
        }

    }
    searchUsers(e){
        this.setState({'searchValue':e.target.value});
        if(e.target.value){
            this.searchUsersList(this.state.addUserListActiveRole,e.target.value);
        }else{
            this.activeUsers(this.state.addUserListActiveRole);
        }
    }
    searchUsersList = async (role, keyword) => {
        let parameters = {"eventId": this.state.eventId, "role": role, "offset": 0, "limit": 20, 'keyword': keyword};
        let userListdata = await troopServer.getSearchUsersList(parameters);
        if (userListdata.errorCode === 4) {
            this.setState({"redirect": true});
            return;
        }
        if (userListdata.data) {
            let users=[];
            if(userListdata.data && userListdata.data.length) {
                users = userListdata.data.map((user) => {
                    if (this.state.checkedUsers.indexOf(user.userId + user.role)!==-1) {
                        user.userSelected = true;
                        return user;
                    } else {
                        return user;
                    }
                })
            }
            this.state.usersList[role] =users;
            this.setState({
                'loadingUsers': false,
                'usersListWithRole': this.state.usersList[this.state.addUserListActiveRole]
            })
        } else {
            return [];
        }
    };
    deleteConformation=(e,meetingId)=>{
        e.stopPropagation();
        this.setState({'deleteMeetingId':meetingId,'openDeleteConformation':true});
    }
    closeDeleteConformation=()=>{
        this.setState({'deleteMeetingId':'','openDeleteConformation':false});

    }
    deleteMetting=()=>{

    }
    render() {
        return (
            <React.Fragment>
                <div className='tmChat'>
                    <ChatHeader  activeUrl={2}/>
                    <div className="fullscreen-height p-4 p-lg-5">
                        <div className="color-000 d-md-flex align-items-center mb-3">
                <span className="schedule-meetingheading d-inline-block mr-2">
                    Scheduled Meeting List
                </span>
                            <button className="btn text-white bg-blue py-1" data-toggle="modal"
                                    data-target="#addmeetingModal" onClick={this.openAddMeetingModal}>
                                Add New Meeting
                            </button>
                           {/* <span className="d-inline-block mx-2">
                    or
                </span>
                            <a href="start-meeting.html" className="btn bg-blue text-white py-1">
                                Start New Meeting
                            </a>*/}
                        </div>
                        <div className="row custom-height">
                            <div className="col-12 col-sm-12 col-lg-6 mb-4 mb-xl-0 h-100">
                                <div className="mCustomScrollbar meetings-leftbox" data-mcs-theme="minimal-dark">
                                    {this.state.meetingsList  && this.state.meetingsList.length ? this.state.meetingsList.map((meeting, index) => {

                                        let meetingLocalTime = moment.utc(meeting.date_time).local();
                                              console.log(meetingLocalTime.format(),meeting.date_time,"convert the time to local");
                                        let meetingEndTime = moment.utc(meeting.date_time).local().add(parseInt(meeting.duration), 'minutes');
                                        console.log(meetingLocalTime.format(),"convert the time to local");

                                        return (<div className="meetings-list pointer mb-3" key={index} data-id={meeting.date_time}>
                                            <div className="d-flex align-items-center" data-id={meetingLocalTime.format()}>
                                                <div className="mr-3 meeting-day d-inline-block text-center">
                                                    {meetingLocalTime.format("MMM")}
                                                    <div className="meeting-date bg-blue text-white">
                                                        {meetingLocalTime.format("DD")}
                                                    </div>
                                                    {meetingLocalTime.format("YYYY")}
                                                </div>
                                                <div className="meeting-details"
                                                     onClick={e => this.showMeetingDetails(index)}>
                                                    <div className="meeting-name">
                                                        <div className="text-truncate">
                                                            {meeting.title},
                                                        </div>
                                                        <div className="roboto-mediumitalic meeting-time">
                                                            {meetingLocalTime.format("hh:mm a")} -
                                                            {meetingEndTime.format("hh:mm a")}
                                                        </div>
                                                    </div>
                                                    { !moment().isAfter(meetingEndTime)?<div className='d-flex'>
                                                        <button className="btn mr-3 text-white py-1 bg-danger" onClick={e=>this.deleteConformation(e,meeting.meeting_id)}>
                                                            Delete Meeting
                                                        </button>
                                                        <button className="btn bg-blue text-white py-1" onClick={e=>this.copyLink(meeting.room_id,meeting.password)}>
                                                            Copy Link
                                                        </button>

                                                    </div>:''}
                                                </div>
                                            </div>
                                        </div>);
                                    }) : (this.state.loadingMeetings?'Loading...':'No Meetings Found')}
                                </div>
                            </div>
                            {this.state.meetingDetails && Object.keys(this.state.meetingDetails).length ?
                                <div className="col-12 col-sm-12 col-lg-6 mb-4 mb-xl-0 h-100">
                                    <div className="h-100 meetings-rightbox">
                                        <div className="d-flex align-items-center mb-3">
                            <span className="schedule-meetingheading d-inline-block mr-2">
                                Meeting Details
                            </span>
                                            <button className="btn text-white bg-blue py-1 d-none">
                                                Edit
                                            </button>
                                        </div>
                                        <div className="row mx-n2 mb-4">
                                            <div className="col-12 px-2 mb-3">
                                                <label>
                                                    Title
                                                </label>
                                                <input type="text" className="form-control" placeholder="Meeting title"
                                                       value={this.state.meetingDetails.title} />
                                            </div>
                                            <div className="col-12 col-lg-6 px-2 mb-3">
                                                <label>
                                                    Date
                                                </label>
                                                <input type="text" className="form-control" placeholder="Meeting date"
                                                       value={moment.utc(this.state.meetingDetails.date_time).local().format("DD MMMM Y")}/>
                                            </div>
                                            <div className="col-12 col-lg-3 px-2 mb-3">
                                                <label>
                                                    Start Time
                                                </label>
                                                <input type="text" className="form-control"
                                                       placeholder="Meeting start time"
                                                       value={moment.utc(this.state.meetingDetails.date_time).local().format("hh:mm a")}/>
                                            </div>
                                            <div className="col-12 col-lg-3 px-2 mb-3">
                                                <label>
                                                    Duration
                                                </label>
                                                <input type="text" className="form-control"
                                                       placeholder="Meeting duration"
                                                       value={this.state.meetingDetails.duration}/>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                            <span className="d-inline-block mr-2">
                                User List
                            </span>
                                            <button className="btn text-white bg-blue py-1 d-none" data-toggle="modal"
                                                    data-target="#addusersModal" onClick={this.openAddUsersModal}>
                                                Add User
                                            </button>
                                        </div>
                                        <div className="row mx-n2 mb-3">
                                            {this.state.meetingUsers && this.state.meetingUsers.length?
                                                this.state.meetingUsers.map((user,index)=>{
                                                    return (<div className="col-12 col-md-6 px-2 mb-2" key={index}>
                                                        <div className="meetings-userslist">
                                                            <div className="position-relative d-flex align-items-center">
                                                                <div className="meetings-userpic mr-3"
                                                                     style={{background: 'url(' + user.profilePic + ') no-repeat center / cover'}}>
                                                                    {user.profilePic ? '' : utility.acronym(user.name)
                                                                        .toUpperCase()
                                                                        .slice(0, 2)}
                                                                </div>

                                                                <div className="roboto-bold color-000">
                                                                    {user.name}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }):''}

                                        </div>
                                        <div className="mb-2">
                                            <div className="">
                                                Meeting Link
                                            </div>
                                            <div className="color-blue text-break">
                                             <a href={window.location.origin+process.env.PUBLIC_URL +"/conference-meeting/"+this.state.meetingDetails.room_id+"?pwd="+this.state.meetingDetails.password} target='_blank'>   {utility.generateMeetingLink(this.state.meetingDetails)}</a>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <div className="mb-2">
                                                Meeting ID : {this.state.meetingDetails.room_id}
                                            </div>
                                            <div className="mb-2">
                                                Code : {this.state.meetingDetails.password}
                                            </div>
                                        </div>
                                        {!moment().isAfter(moment.utc(this.state.meetingDetails.date_time).local().add(parseInt(this.state.meetingDetails.duration), 'minutes'))?   <div className="text-right">
                                            <button className="btn bg-blue text-white rounded-pill roboto-bold custom-btn" onClick={e=>this.copyLink(this.state.meetingDetails.room_id,this.state.meetingDetails.password)}>
                                                Copy Link
                                            </button>
                                           <Link target='_blank' to={process.env.PUBLIC_URL +'/conference-meeting/'+this.state.meetingDetails.room_id+'?pwd='+this.state.meetingDetails.password}> <button
                                                className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2">
                                                Start Now
                                            </button></Link>
                                        </div>:''}
                                    </div>
                                </div> : ''}
                        </div>
                    </div>
                    {this.state.addMeetingModal ?
                        <React.Fragment>
                            <div className="modal-backdrop show"></div>
                            <div className="modal d-block" id="addmeetingModal">
                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content">
                                        <div className="modal-header pl-lg-5">
                                            <h5 className="modal-title">Add Meeting</h5>
                                            {this.state.submitAddMeeting ? '' :
                                                <button type="button" className="close" data-dismiss="modal"
                                                        aria-label="Close" onClick={this.openAddMeetingModal}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>}
                                        </div>
                                        <div className="modal-body px-lg-5">
                                            <div className="row mx-n2 mb-4">
                                                {this.state.showError ? <div className="col-12 alert alert-danger">
                                                    <strong>Error!</strong> {this.state.errorMessage}.
                                                </div> : ''}

                                                <div className="col-12 px-2 mb-3">
                                                    <label>
                                                        Title
                                                    </label>
                                                    <input type="text" className="form-control"
                                                           placeholder="Meeting title" value={this.state.meetingTitle}
                                                           onChange={this.titleChange}/>
                                                </div>
                                                <div className="col-12 col-lg-6 px-2 mb-3">
                                                    <label>
                                                        Date
                                                    </label>

                                                    <div>
                                                        <DatePicker
                                                            selected={this.state.meetingDate}
                                                            onChange={this.dateChange}
                                                            dateFormat="dd/MM/yyyy"
                                                            className="form-control"
                                                            minDate={new Date()}
                                                            placeHolder='Select Date'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-3 px-2 mb-3">
                                                    <label>
                                                        Start Time
                                                    </label>
                                                    <div className=''>
                                                        <DatePicker
                                                            selected={this.state.meetingTime}
                                                            onChange={this.timeChange}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={15}
                                                            className="col-12 form-control"
                                                            timeCaption="Time"
                                                            dateFormat="HH:mm"
                                                            placeHolder='Select Time'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-lg-3 px-2 mb-3">
                                                    <label>
                                                        Duration ( in minutes)
                                                    </label>
                                                    <input type="text" className="form-control"
                                                           placeholder="Meeting duration"
                                                           value={this.state.meetingDuration}
                                                           onChange={this.durationChange}/>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center mb-3">
                            <span className="d-inline-block mr-2">
                                User List
                            </span>
                                                <button className="btn text-white bg-blue py-1"
                                                        onClick={this.openAddUsersModal}>
                                                    Add User
                                                </button>
                                            </div>
                                            <div className="addmeetingModal-userslist mCustomScrollbar"
                                                 data-mcs-theme="minimal-dark">
                                                <div className="row mb-3 mx-0">
                                                    {this.state.addedUsers ? this.state.addedUsers.map((user, index) => {
                                                        return (
                                                            <div className="col-12 col-lg-6 px-2 my-0 mb-2" key={index}>
                                                                <div className="meetings-userslist">
                                                                    <div
                                                                        className="position-relative d-flex align-items-center">
                                                                        <div className="meetings-userpic mr-3"
                                                                             style={{background: 'url(' + user.profilePic + ') no-repeat center / cover'}}>
                                                                            {user.profilePic ? '' : utility.acronym(user.name)
                                                                                .toUpperCase()
                                                                                .slice(0, 2)}
                                                                        </div>
                                                                        <div className="roboto-bold color-000">
                                                                            {user.name}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>)
                                                    }) : 'No Users Selected'}


                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer px-lg-5">
                                            <div className="text-right">
                                                {this.state.submitAddMeeting ? '' : <button
                                                    className="btn cancel-btn text-white rounded-pill roboto-bold custom-btn py-2"
                                                    data-dismiss="modal" onClick={this.openAddMeetingModal}>
                                                    Cancel
                                                </button>}
                                                <button
                                                    className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2"
                                                    onClick={this.addMeeting} disabled={this.state.submitAddMeeting}>
                                                    {this.state.submitAddMeeting ? 'Please Wait..' : 'Save'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment> : ''}
                    {this.state.addUsersModal ? <React.Fragment>
                            <div className="modal-backdrop show"></div>
                            <div className="modal d-block" id="addusersModal">
                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content rounded-0">
                                        <div className="modal-header pl-lg-5">
                                            <h5 className="modal-title">Add Users</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                    onClick={this.openAddUsersModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body px-lg-5">
                                            {this.state.loadingUsers ? 'Loading...' :
                                                <React.Fragment>
                                                    <ul className="nav nav-tabs flex-nowrap w-100 overflow-auto mb-4">
                                                        <li className="nav-item" role="presentation">
                                                            <a className={(this.state.addUserListActiveRole === -10 ? 'active' : '') + " nav-link"}
                                                               data-toggle="tab" href="#"
                                                               onClick={e => this.activeUsers(-10)}>
                                                                <div className="addusersModal-tabs">
                                                                    <i className="fab fa-slideshare"></i>
                                                                </div>
                                                                Attendees
                                                            </a>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <a className={(this.state.addUserListActiveRole === -1 ? 'active' : '') + " nav-link"}
                                                               data-toggle="tab" href="#"
                                                               onClick={e => this.activeUsers(-1)}>
                                                                <div className="addusersModal-tabs">
                                                                    <i className="fas fa-university"></i>
                                                                </div>
                                                                Exhibit Staff
                                                            </a>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <a className={(this.state.addUserListActiveRole === -6 ? 'active' : '') + " nav-link"}
                                                               data-toggle="tab" href="#"
                                                               onClick={e => this.activeUsers(-6)}>
                                                                <div className="addusersModal-tabs">
                                                                    <i className="fas fa-microphone"></i>
                                                                </div>
                                                                Speakers
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div className="tab-pane fade show active" id="add-usersAttendees">
                                                            <div className="position-relative add-users--searchbox mb-3">
                                                                <input type="text" className="form-control"
                                                                       placeholder="Search People" onChange={this.searchUsers} />
                                                            </div>
                                                            <div className="addusers-list mCustomScrollbar"
                                                                 data-mcs-theme="minimal-dark">
                                                                <div className="row mx-0 mb-3">
                                                                    {this.state.usersListWithRole ? this.state.usersListWithRole.map((user, index) => {
                                                                        return (<div className="col-12 col-md-6 px-2 mb-2"
                                                                                     key={index}>
                                                                            <div className="meetings-userslist">
                                                                                <div
                                                                                    className="position-relative d-flex align-items-center">
                                                                                    {/* <span className="d-inline-block status-btn"></span>*/}
                                                                                    <div className="meetings-userpic mr-3"
                                                                                         style={{background: 'url(' + user.profilePic + ') no-repeat center / cover'}}>
                                                                                        {user.profilePic ? '' : utility.acronym(user.name)
                                                                                            .toUpperCase()
                                                                                            .slice(0, 2)}
                                                                                    </div>
                                                                                    <div
                                                                                        className="d-flex justify-content-between align-items-center addusers-userbox">
                                                                                        <div
                                                                                            className="roboto-bold color-000">
                                                                                            {user.name}
                                                                                        </div>
                                                                                        <label
                                                                                            className="options-checkbox position-relative pointer">
                                                                                            <div className="d-inline-block">
                                                                                                <input type="checkbox"
                                                                                                       checked={user.userSelected ? 'checked' : ''}
                                                                                                       onChange={(e) => this.addUserToMeeting(e, index, user.userId, user.role)}/>
                                                                                                <span
                                                                                                    className="custom-checkbox"></span>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>)

                                                                    }) : 'No Users Found'}


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade" id="add-usersExhibitstaff">
                                                            Exhibit Staff
                                                        </div>
                                                        <div className="tab-pane fade" id="add-usersSpeakers">
                                                            Speakers
                                                        </div>
                                                        <div className="tab-pane fade" id="add-usersGroups">
                                                            Groups
                                                        </div>
                                                    </div>
                                                </React.Fragment>}
                                        </div>
                                        <div className="modal-footer px-lg-5 pb-lg-5">
                                            <div className="text-right">
                                                <button
                                                    className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2"
                                                    onClick={this.openAddUsersModal}>
                                                    Done
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        : ''}
                    {this.state.openDeleteConformation ? <React.Fragment>
                            <div className="modal-backdrop show"/>
                            <div className="modal d-block" id="deleteMeetingConformation">
                                <div className="modal-dialog modal-dialog-centered">
                                    <div className="modal-content rounded-0">
                                        <div className="modal-header pl-lg-5">
                                            <h5 className="modal-title">Delete Conformation</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                    onClick={this.closeDeleteConformation}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body px-lg-5">
                                           Are You Sure u Want to Delete?
                                        </div>
                                        <div className="modal-footer px-lg-5 pb-lg-5">
                                            <div className="text-right">
                                                <button
                                                    className="btn bg-danger text-white rounded-pill roboto-bold custom-btn ml-2 py-2"
                                                    onClick={this.deleteMetting}>
                                                    Yes
                                                </button>
                                                <button
                                                    className="btn bg-default text-white rounded-pill roboto-bold custom-btn ml-2 py-2"
                                                    onClick={this.closeDeleteConformation}>
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        : ''}
                </div>
            </React.Fragment>
        );
    }
}

export default Meetings