import React, { Component } from 'react';

import {storage} from '../../../Firebase';
import Axios from 'axios';

class AddPosterBanner extends Component {
    constructor(props){
        super(props);

        this.state = {
            pageLoader : true,
            sessionId : this.props.sessionId,
            eventId : localStorage.getItem('eventId'),
            bannerSelectionSuccess:'',
            bannerSelectionError:'',
            bannerFile:null,
            bannerkey:'',
            banner:'',
            bio:'',
        }
    }
    
      componentDidMount (){
          this.getBannerData();
      }

      getBannerData = () => {
        Axios.get(`${global.config.requestUrl}speaker/getPosterOrSessionData?sessionId=${this.props.sessionId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {

     if(!res.data.errorCode){
        this.setState({
            banner:res.data.data[0].banner,
            bio:res.data.data[0].bio,
            pageLoader: false
        });
      }else{
        this.setState({pageLoader: false});
      }
       
        
    })
    .catch(err => {
        console.log(err);
    });
      }

    


    mediaValidateForm = (file) => {
       
       
        if(file === null ){
            this.setState({bannerSelectionError : "Please Choose File"})
            return false
        }else{
            return true;
        }
        
      }

    /* media validator code ends */


    bannerFirebaseUploader = (e) => { 
        e.preventDefault();
        if(this.mediaValidateForm(this.state.bannerFile)){
            
            this.setState({pageLoader:true})

            if(this.state.bannerFile !== null){
                const sessionId = this.props.sessionId;
                const {bannerFile, eventId} = this.state;
                // Create a storage reference from our storage service
                var storageRef = storage.ref();
                const uploadTask = storageRef.child(`banners/${eventId}/${sessionId}/${bannerFile.name}`).put(bannerFile);
                uploadTask.on('state_changed', 
                (snapshot) => {
                    //progress func
                    const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                    this.setState({progress})
                    // console.log(progress)
                    if(progress !== 100){
                    this.setState({pageLoader:true})
                    }
                }, 
                (error) => {
                    //error func
                    console.log(error)
                }, 
                () => {
                    //complete func  
                    storage.ref(`banners/${eventId}/${sessionId}`).child(bannerFile.name).getDownloadURL().then(url=>{
                        if(url){

                            this.setState({
                                banner : url,
                            }, () => {
                                this.bannerSubmit();
                            });
                        }
                    })
                });
            } 

        }
    }  

    bannerSubmit = () => {
           var url = this.state.banner.toString();
            const bannerData = {
                eventId: localStorage.getItem("eventId"),
                sessionId: this.props.sessionId,
                banner: url,
                bio: this.state.bio
              };
        Axios.post(
            `${global.config.requestUrl}speaker/updateSessionOrPosterInfo`,
            bannerData,
            {
              headers: {
                "auth-token": localStorage.getItem("exhibitorToken"),
              },
            }
          )
            .then((res) => {
              if (res.data.errorCode) {
                //it means error from server
                this.setState({
                    bannerSelectionError: res.data.errorMessage,
                    bannerSelectionSuccess: "",
                    pageLoader: false,
                });
              } else {
                this.setState({
                    bannerSelectionError: "",
                    bannerSelectionSuccess: res.data.errorMessage,
                    pageLoader: false,
                });
                //this.getBannerData();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        
    }

      bannerChange = (e) => {
        if(e.target.files[0]){
            const bannerFile = e.target.files[0];
            var size = bannerFile.size/1024/1024;
            if(size > 10){
                let randomString = Math.random().toString(36);
                this.setState({
                    bannerkey :randomString,
                    bannerFile : null,
                    bannerFileError : "Max upload  size is 10MB"
                })
            }else{
                this.setState({
                    bannerFile : bannerFile,
                    bannerFileError : ""
                })
               
            }
        }
    }

    render() {
     
        return (
            <React.Fragment>
        <div className="modalPopUpFormBx">
                                <div className="infoMsg">
                                    <p>Upload Poster-You can create your poster using PowerPoint, Adobe Illustrator, Canvas, or any other appropriate program. Poster dimensions are as follows: The maximum width is 48 inches (130 cm); the maximum height is 36 inches (100 cm). The file size may not exceed 25MB, and you must save your file as a PDF. The virtual poster software does not recognize any other file formats. </p>
                                </div>
                                {
                                  this.state.banner !== '' 
                                  ?
                                  <div className="uploadLink">
                                    <a href={this.state.banner} target="_blank"><i className="fa fa-picture-o"></i> See uploaded Banner</a>
                                  </div>
                                  :
                                  null
                                }
                                <h4 className="mb-3">Add/Edit Session/Poster Banner</h4>
                                    <div className="error">{this.state.bannerSelectionError}</div>
                                    <div className="success">{this.state.bannerSelectionSuccess}</div>
                                    <form onSubmit={this.bannerFirebaseUploader}>
                                        <div className="form-group">
                                            <label>Banner</label>
                                            <input type="file" name="bannerFile" className="form-control" accept="application/pdf" onChange={this.bannerChange} key={this.state.bannerkey || '' }/>
                                            <div className="errorMessage">{this.state.bannerFileError}</div>
                                        </div>
                                        <div className="mt-3">
                                            <button type="submit">Submit</button>
                                        </div>
                                    </form>

                                    {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                                
                            </div>
                            </React.Fragment>
        )

    }


    
}
export default AddPosterBanner;