import React, { Component } from 'react';

import jwt_decode from 'jwt-decode'
import LeftNavBar from './LeftNavBar'
import Axios from 'axios';
import {Link} from "react-router-dom";
import ChatIcon from '../../../TmChat/vcd/chat/ChatIcon'

class DashboardSpeaker extends Component {
    constructor(){
        super();
        this.state={
            visitorsTable:true,
            leadsTable:false,
            totalTable:false,
            tableListDetailsModal:false,
            email : "",
            pageLoader:true,
            sectionLoader:true,
            showChatIcon:false,
            sessionLists:[],
        }
    }
    componentDidMount(){
        this.setState({sectionLoader:true});
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = '';
            
            if(rolesArr.indexOf('-6') !== -1){
                currentRole = -6
                localStorage.setItem('currentRole',currentRole);
            }else{
                this.props.history.push('/notfound');
            }
             
        }

        const token = localStorage.exhibitorToken;
        const decoded = jwt_decode(token);
        const details = {
            username: decoded.email,
            eventId:localStorage.getItem('eventId'),
            role:currentRole.toString()
        }

        Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            .then(res => {
               // console.log(res.data.data[0].profilePic);
                 if(res.data.errorCode === 0){
                    localStorage.setItem('speakerProfilePic',res.data.data[0].profilePic)
                    localStorage.setItem('useraccname',res.data.data[0].name)
                    localStorage.setItem('userId',res.data.data[0].speakerId)
                    localStorage.setItem('company',res.data.data[0].company)
                    if(res.data.data[0].profilePic !==''){
                        document.getElementById("profile_img").src = res.data.data[0].profilePic;
                        document.getElementById("left_bar_profile_img").src = res.data.data[0].profilePic;
                    }else{
                        document.getElementById("profile_img").src = '/images/placeholder-profile.jpg';
                        document.getElementById("left_bar_profile_img").src = '/images/placeholder-profile.jpg';  
                    }
                    document.getElementById("useraccname").innerHTML = res.data.data[0].name;
                    this.getSessionList();

                    
                }else{
                    
                }
                this.setState({pageLoader:false,showChatIcon:true});
                
            })
            .catch(err => {
                console.log(err);
            });

            
        
    }

    getSessionList = () => {
        Axios.get(`${global.config.requestUrl}speaker/getSpeakerSessions?eventId=${localStorage.getItem('eventId')}&speakerId=${localStorage.getItem('userId')}`,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            
            .then(res => {
               if(res.data.errorCode === 0){
                    this.setState({sessionLists:res.data.data})  
                }
                this.setState({sectionLoader:false})
            })
            .catch(err => {
                console.log(err);
            });
    }
    render() {
        return (
            <React.Fragment>
                <section className="bg7 signleScreen">
                    <div className="dashboardWrapper">
                        <LeftNavBar />
                        <div className="rightContentAera">
                            
                            <div className="mainDashboardHeading">
                                <h3>Speaker Main Dashboard</h3>
                            </div>
                            {/* <div className="mainDashboardLeadsData flexBxDashabord">
                                
                                <div className="conferenceSchedule">
                                    <h3>Session List</h3>
                                    <div className="scheduleTable">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <td colSpan="2">Session Name</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                     this.state.sectionLoader
                                                     ?
                                                          <tr>
                                                            <td colSpan="2">
                                                                <div className="sectionLoader text-center mt-3 mb-3">
                                                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                                </div>
                                                            </td>
                                                          </tr>
                                                          :
                                                          
                                                            this.state.sessionLists.length == 0
                                                            ?
                                                            <tr>
                                                                <td colSpan="2"><h4>No data found</h4></td>
                                                            </tr>
                                                            :
                                                            <React.Fragment>
                                                                { 
                                                                    this.state.sessionLists.map(sessionList => (
                                                                        <tr key={sessionList.session_id}>
                                                                            <td>{sessionList.session_name}</td>
                                                                            <td className="text-right">
                                                                                <button className="btn btn-primary">Start <i class="fa fa-caret-right"></i></button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                    
                                                                }
                                                            </React.Fragment>
                                                        
                                                        
                                            }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div> */}
                            {
                                 this.state.showChatIcon
                                 ?
                                 <div className="chatIcon">
                                    <Link to="/chat" target='_blank'>
                                        <ChatIcon/>
                                         <img src={process.env.PUBLIC_URL + '/images/chat-ico.png'} alt="" />
                                    </Link>
                                 </div>
                                 :
                                 null
                             }
                            
                        </div>
                    </div>
                </section>
                {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
export default DashboardSpeaker;