import React, {Component} from 'react'
import ChatMessages from "../chat/ChatMessages";
import utility from "../utility/utility";
import fileUpload from "../utility/firebase";

class VideoChat extends Component {
    state = {'files': [], message: ''}

    componentDidMount() {
        this.scrollToBottom();
        window.addEventListener('dragenter', this.handleDragIn);
        window.addEventListener('dragleave', this.handleDragOut);
        window.addEventListener('dragover', this.handleDrag);
        window.addEventListener('drop', this.handleDrop);
    }

    componentDidUpdate() {
        this.scrollToBottom();
    }

    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({block: "end", inline: "nearest"});
        }
    }
    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log(e.dataTransfer.files, "files");
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.saveFiles(e.dataTransfer.files);
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    };
    uploadFile = (event) => {
        this.saveFiles(event.target.files);
        event.target.value = null;
    }
    removeFile = (e) => {
        let files = this.state.files;
        files.splice(e, 1);

        this.setState({'files': files})
    };
    saveFiles = (files) => {
        let stateFiles = this.state.files;
        stateFiles.push(files[0]);
        this.setState({'files': stateFiles})
    };

    uploadFileDiv(files) {
        if (files.length === 0) {
            return ('');
        }
        return (<div className="attachments">
            {files.map((file, index) => {
                return (<div className="uploaded-files" key={index}>
                    <div className="bg-white p-3 border-radius12">
                        <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/document.svg"} className="img-fluid"/>
                        <span className="d-block text-black mt-2 upload-file-name">{file.name}</span>
                        <div className="file_size mt-3">
                            {utility.formatBytes(file.size, 1)}
                        </div>
                        <button className="cursor-pointer attachments-close"
                                onClick={this.removeFile.bind(this, index)}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/close.svg"} className="img-fluid"/>
                        </button>
                    </div>
                </div>);
            })}

            <div className="add-more">
                <button className="w-100 h-100 m-0">
                    <input type='file' className='file-upload' onChange={this.uploadFile}/>
                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/add.svg"}/>
                </button>
            </div>
        </div>)
    }


    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };

    updateInput = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }

        this.setState({message: e.target.value})
    }
    fileuploadHandler = async () => {
        const files = this.state.files;
        return await fileUpload(files);
    };

     handleSubmit =async ()=>{
        let filesPaths = [];

        this.setState({'submited': true});

        if (this.state.recording) {
            //  this.setState({recording: false,recordTime:'',showRecording:false});
            this.stopRecording()
        }
        if (this.state.files.length) {
            filesPaths = await this.fileuploadHandler();
        }
        let meesage={
            'message': this.state.message,
            filesPaths: filesPaths,
        };
          if(this.props.userInfo){
              Object.assign(meesage,{ 'receiver_id': this.props.userInfo.tm_user_id,
                  'receiver_uid': this.props.userInfo.uid})
          }
        this.props.onSendMessage(meesage, this.props.userInfo);
        this.setState({'message': ''});
        this.setState({
            message: '',
            files: [],
            audio: '',
            recording: false,
            recordTime: '',
            showRecording: false
        });
        this.setState({'submited': false});
//Send state to the server code
    }

    render() {
        return (
            <React.Fragment>
                <div className="startmeeting-chat bg-white">
                    <div className="roboto-bold heading text-center">
                        <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat-icon.png"} className="img-fluid mr-2"/> Chat
                        <button className="btn startmeeting-chat--close"  onClick={this.props.closeChat}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/close.png"} className="img-fluid"/>
                        </button>
                    </div>
                    <div className="startmeeting-chatbox p-3">
                        <ChatMessages userInfo={this.props.userInfo} loginUserDetails={this.props.loginUserDetails}
                                      chatConversations={this.props.chatConversations}
                                      troopConnection={this.props.troopConnection}/>
                        <div className='mb-2'></div>

                        <div style={{float: "left", clear: "both"}}
                             ref={(el) => {
                                 this.messagesEnd = el;
                             }}>
                        </div>


                    </div>
                    <div className="startmeeting-controlsbox">
                        {this.uploadFileDiv(this.state.files)}
                        <div className="position-relative startmeeting-typemessage">
                            <input type="text" placeholder="Type message" className="form-control"
                                   value={this.state.message} onChange={this.updateInput}
                                   onKeyDown={this.handleKeyDown}/>
                            <button className="btn startmeeting-sendmessage" onClick={this.handleSubmit}>
                                <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/send.png"} className="img-fluid"/>
                            </button>
                        </div>
                        <div className="upload-attachmentsbox position-relative">
                            <input type="file" onChange={this.uploadFile}/>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/add.png"} className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default VideoChat