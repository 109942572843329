import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from './PrivateRoute'

import Homepage from './Homepage'
import Header from './Components/ihca-als2021/Header';
import Login from './Components/ihca-als2021/Login';
import LoginSplash from './Components/ihca-als2021/LoginSplash';
import ForgotPassword from './Components/ihca-als2021/ForgotPassword';
import ResetPassword from './Components/ihca-als2021/ResetPassword';
import ExhibitorDetails from './Components/ihca-als2021/Exhibitor/ExhibitorDetails';
import ExhibitorBooth from './Components/ihca-als2021/Exhibitor/ExhibitorBooth';

import Dashboard from './Components/ihca-als2021/Exhibitor/Dashboard';
import Lobby from './Components/ihca-als2021/Attendee/Lobby';
import ExhibitHall from './Components/ihca-als2021/Attendee/ExhibitHall';
import NetworkingLounge from './Components/ihca-als2021/Attendee/NetworkingLounge';
import ConferenceCenter from './Components/ihca-als2021/Attendee/ConferenceCenter';
import ConferenceCenterPage from './Components/ihca-als2021/Attendee/ConferenceCenterPage';
import TechnicalSessions from './Components/ihca-als2021/Attendee/TechnicalSessions';


import HelpDesk from './Components/ihca-als2021/Attendee/HelpDesk';
import AttendeeExhibitorBooth from './Components/ihca-als2021/Attendee/AttendeeExhibitorBooth';
import NotFound from './Components/ihca-als2021/NotFound';

import DashboardSpeaker from './Components/ihca-als2021/Speaker/Dashboard';
import ProfileDetails from './Components/ihca-als2021/Speaker/ProfileDetails';
import SessionAttendees from './Components/ihca-als2021/Speaker/SessionAtendees';
import UserProfile from './Components/ihca-als2021/UserProfile';

import ChatPage from "./TmChat/ihca-als2021/chat/ChatPage";
import VideoCall from "./TmChat/ihca-als2021/video/VideoCall";
import ConferenceMeeting from "./TmChat/ihca-als2021/videoConference/conferenceMeeting";
import Meetings from "./TmChat/ihca-als2021/videoConference/meetings";
import WorkProgress from './Components/ihca-als2021/WorkProgress';

/* import test from './Components/ihca-als2021/Exhibitor/test'; */

class Ihcaals2021 extends Component {
constructor(){
  super();

  this.state={
    homePage:""
  }
}


  render() {

    const domain = window.location.href
    if(domain === ("http://localhost:3000/" || "https://affectionate-bohr-4d306b.netlify.app/")){
      return(
        <div className="App">
            <Router>
              <Route path="/" exact component={Homepage} />
              {/* <Route path="/home" exact component={Homepage} /> */}
            </Router>
        </div>
      )
    }
    else {
      return (
      <Router>
          <div className="App">
            
            <Header/>
            <Switch>
                
                <Route path="/ihca-als2021/login/:loginId" exact component={Login} />
                <PrivateRoute path="/ihca-als2021/loginSplash" exact component={LoginSplash} />
                <Route path="/ihca-als2021/forgotPassword" component={ForgotPassword} />
                <Route path="/ihca-als2021/resetPassword" component={ResetPassword} />
                <PrivateRoute path="/ihca-als2021/chat" exact component={ChatPage} />
                <PrivateRoute path="/ihca-als2021/video-call" exact component={VideoCall} />
                <PrivateRoute path="/ihca-als2021/video-conference" exact component={Meetings} />
                <PrivateRoute path="/ihca-als2021/conference-meeting/:id" exact component={ConferenceMeeting} />
                <PrivateRoute path="/ihca-als2021/exhibitor/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/ihca-als2021/exhibitor/profile" exact component={ExhibitorDetails} />
                <PrivateRoute path="/ihca-als2021/exhibitor/booth" component={ExhibitorBooth} />
                <PrivateRoute path="/ihca-als2021/attendee/lobby" component={Lobby} />
                <PrivateRoute path="/ihca-als2021/attendee/exhibitHall" component={ExhibitHall} />
                <PrivateRoute path="/ihca-als2021/attendee/networkingLounge" component={NetworkingLounge} />
                <PrivateRoute path="/ihca-als2021/attendee/conferenceCenter" component={ConferenceCenter} />
                <PrivateRoute path="/ihca-als2021/attendee/conferenceCenterDetails/:sessionId" component={ConferenceCenterPage} />
                <PrivateRoute path="/ihca-als2021/attendee/technicalSessions" component={TechnicalSessions} />
                
                <PrivateRoute path="/ihca-als2021/attendee/helpDesk" component={HelpDesk} />
                
                <PrivateRoute path="/ihca-als2021/attendee/exhibitorBooth/:exhibitorId" component={AttendeeExhibitorBooth} />
                <PrivateRoute path="/ihca-als2021/speaker/dashboard" exact component={DashboardSpeaker} />
                <PrivateRoute path="/ihca-als2021/speaker/profile" exact component={ProfileDetails} />
                <PrivateRoute path="/ihca-als2021/speaker/attendees" exact component={SessionAttendees} />
                <PrivateRoute path="/ihca-als2021/user/profile" exact component={UserProfile} />
                <Route path="/ihca-als2021/workProgress" exact component={WorkProgress} />
                <Route path="*" component={NotFound} />
              </Switch>
         
          </div>
      </Router>
    );}
  }  
}

export default Ihcaals2021;
