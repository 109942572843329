import React, { Component } from 'react';

import jwt_decode from 'jwt-decode'
import Axios from 'axios';
import SessionChatRoom from "../../../TmChat/csda/sessionChatRoom/sessionChatRoom";
import AddSessionOrPosterDoc from './AddSessionOrPosterDoc';
import AddSessionOrPosterVideo from './AddSessionOrPosterVideo';
import AddPosterBanner from './AddPosterBanner';
import AddSessionOrPosterAbout from './AddSessionOrPosterAbout';

class SessionsAndPostersEdit extends Component {
    constructor(){
        super();
        this.state={
            pageLoader:false,
            sectionLoader:true,
            showChatIcon:false,
            videoUrlUpload:false,
            sessionDocumentsModal:false,
            aboutSessionModal:false,
            posterUpload:false,
            sessionsList:[],
            postersList:[],
            selectedSessionOrPoster:'',
            isPoster:0,
        }
    }

    /*Session Dpcuments Modal Handeler*/
    sessionDocumentsModalHandeler = (e) => {
        if(this.state.selectedSessionOrPoster === ''){
            alert('Please select poster or session first to see Documents!!!!'); 
        }else{
            this.setState({sessionDocumentsModal: !this.state.sessionDocumentsModal})
        }
    }
    aboutSessionModalHandeler = (e) => {
        if(this.state.selectedSessionOrPoster === ''){
              alert('Please select poster or session first to see Bio!!!!'); 
        }else{
            this.setState({aboutSessionModal: !this.state.aboutSessionModal})
        }
       
    }
    /*Video Url Modal Handeler*/
    videoUrlUploadHandeler = (e) => {
        if(this.state.selectedSessionOrPoster === ''){
            alert('Please select poster or session first to see video!!!!'); 
        }else{
            this.setState({videoUrlUpload: !this.state.videoUrlUpload})
        }
        
    }
    /*Poster Upload Modal Handeler*/
    posterUploadHandeler = (e) => {
        this.setState({posterUpload: !this.state.posterUpload})
    }

    componentDidMount() {
        this.getSessionsAndPosters();
    }

    getSessionsAndPosters = () =>{
        Axios.get(`${global.config.requestUrl}speaker/getSpeakerSessions?eventId=${localStorage.getItem('eventId')}&speakerId=${localStorage.getItem('userId')}`,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            
            .then(res => {
                if(res.data.errorCode === 0){
                    this.setState({sessionsList:res.data.data})  
                }
                this.setState({sectionLoader:false})
            })
            .catch(err => {
                console.log(err);
            });
        
        Axios.get(`${global.config.requestUrl}speaker/getPresenterPosters?eventId=${localStorage.getItem('eventId')}&speakerId=${localStorage.getItem('userId')}`,
            {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({postersList:res.data.data})  
            }
            this.setState({sectionLoader:false})
        })
        .catch(err => {
            console.log(err);
        });

    }

    

    onChangeForSessPosterHandler = (e) => {
        this.setState({
            selectedSessionOrPoster:e.target.value,
            isPoster: e.target[e.target.selectedIndex].getAttribute('data-isposter'),
        })
        
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    render() {
        return (
            <React.Fragment>
                <div className="boxWrpPresnt">
                                <div className="selectionBx">
                                    <h2>Session/Poster Information</h2>
                                    <div className="form-group">
                                        <select onChange={this.onChangeForSessPosterHandler} >
                                            <option value="">--- Select Session/Poster ---</option>
                                           <optgroup label="Sessions">
                                           { 
                                             this.state.sessionsList.map(session => (
                                               <option key={session.session_id} value= {session.session_id} data-isposter="0">
                                                  {session.session_name}
                                               </option>
                                             ))
                                           } 
                                             
                                           </optgroup>
                                           <optgroup label="Posters">
                                           { 
                                             this.state.postersList.map(poster => (
                                               <option key={poster.poster_id} value= {poster.poster_id} data-isposter="1">
                                                  {poster.session_name}
                                               </option>
                                             ))
                                           }
                                           </optgroup>
                                        </select>
                                    </div>
                                    {/* <div className="form-group">
                                        <button onClick={this.aboutSessionModalHandeler}>About</button>
                                    </div> */}
                                    <div className="form-group">
                                        <button onClick={this.sessionDocumentsModalHandeler}>Documents</button>
                                    </div>
                                    {/* <div className="form-group">
                                        <button onClick={this.videoUrlUploadHandeler}>Video url or Upload</button>
                                    </div> */}
                                    {
                                  this.state.isPoster === '1'
                                  ?
                                  <div className="form-group">
                                        <button onClick={this.posterUploadHandeler}>Poster upload</button>
                                  </div>
                                  :
                                  null
                                }
                                    
                                </div>
                               
                                {/* {
                    this.state.selectedSessionOrPoster
                    ?
                    (
                                <div className="chatBxPresnt">
                                    <SessionChatRoom sessionId={this.state.selectedSessionOrPoster} />
                                </div>

                                
                                )
                                :
                                null
                            } */}
                    </div>
                    
                {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                {/*=========About Session Modal=========*/}
                {
                    this.state.aboutSessionModal 
                    ?
                    <div className="modalPopUp">
                        
                        <div className="modalPopUpInr">
                            <div className="modalPopUpClose" onClick={this.aboutSessionModalHandeler}><i className="fa fa-times"></i></div>
                            <AddSessionOrPosterAbout sessionId ={this.state.selectedSessionOrPoster} isPoster = {this.state.isPoster}/>
                        </div>
                    </div> 
                    :
                    null
                }
                {/*=========sessionDocuments-modal==========*/}
                {
                    this.state.sessionDocumentsModal
                    ?
                    <div className="modalPopUp">
                        <div className="modalPopUpInr">
                            <div className="modalPopUpClose" onClick={this.sessionDocumentsModalHandeler}>
                                <i className="fa fa-times"></i>
                            </div>
                            <AddSessionOrPosterDoc sessionId ={this.state.selectedSessionOrPoster} isPoster = {this.state.isPoster}/>
                        </div>
                    </div> 
                    :
                    null
                }
                {/*=========Video-url-upload-modal==========*/}
                {
                    this.state.videoUrlUpload
                    ?
                    <div className="modalPopUp">
                        
                        <div className="modalPopUpInr">
                            <div className="modalPopUpClose" onClick={this.videoUrlUploadHandeler}>
                                <i className="fa fa-times"></i>
                            </div>
                            <AddSessionOrPosterVideo sessionId ={this.state.selectedSessionOrPoster} isPoster = {this.state.isPoster}/>
                        </div>
                    </div> 
                    :
                    null
                }
                {/*=========Poster-upload-modal==========*/}
                {
                    this.state.posterUpload
                    ?
                    <div className="modalPopUp">
                        
                        <div className="modalPopUpInr">
                            <div className="modalPopUpClose" onClick={this.posterUploadHandeler}>
                                <i className="fa fa-times"></i>
                            </div>
                            <AddPosterBanner sessionId ={this.state.selectedSessionOrPoster} isPoster = {this.state.isPoster}/>
                        </div>
                    </div> 
                    :
                    null
                }
                
            </React.Fragment>
        )
    }

}

export default SessionsAndPostersEdit;