import React, { Component } from 'react';
import BottomNav from './BottomNav'
/* import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; */

import Slider from "react-slick";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import LoungeChat from "../../../TmChat/goma/LoungeChat/loungeChat";



class NetworkingLounge extends Component {
    constructor(props){
        super(props);

        this.state = {
            sponsersImgList : [],
            pageLoader:true, 
        }
        
    }

    componentDidMount(){

        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?type=5&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
        
       
         .then(res => {
            
             if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, sponsersUrl:res.data.logo, apierrorCode:res.data.errorCode})
                 this.setState({ pageLoader:false})
             } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},{'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                 this.setState({pageLoader:false})
                 
             }
             
             
             return res.data;
         })
         .catch(err => {
             console.log(err);
         });
         this.updateGoldenTicketStatus();
        
    }

    updateGoldenTicketStatus = () => {
        this.setState({pageLoader:true});
       
        const ticketStatusData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:0,
            linkPage:'networking',
            sessionId:0,
            exhibitorId:0
            
        }
        Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(res.data.errorCode){
            document.getElementById('goldenTkt').classList.add("greyScale");
            this.setState({pageLoader:false});
        }else{
            this.setState({pageLoader:false});
            document.getElementById('goldenTkt').classList.remove("greyScale");
        }
    })
    .catch(err => {
        console.log(err);
    });
}

    render() {
        var slideToShow = 5
        if(this.state.sponsersImgList.length < 5){
            slideToShow = this.state.sponsersImgList.length
        }
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: slideToShow,
            slidesToScroll: 1,
            autoplay: true,
          };
          const mobileSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
        };
            return (
                <React.Fragment>
               
                    <section className="bg5 signleScreen paddingTop">
                        <div className="mid-container">
                            <div className="row justify-content-center">
                                <div className="col-lg-7 col-12">
                                    <div className="midTab">
                                    <div className="chatLoungeBx">
                                    <LoungeChat/>
                                    </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-5 col-12 loungeScroller">
                                    <div className="loungeBx mt-0">
                                        <h3>GOMA Social Media</h3>
                                        <div className="socialBx">
                                            <div className="centerContent">
                                                <div className="selfCenter standardWidth p-2">
                                                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fgoma.org&tabs=timeline&width=500&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="500" height="550" style={{"width":"100%"}} scrolling="no" frameBorder="0" allowFullScreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>    
                        {/* <div className="sociaMediaList">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/goma.org/" target="_blank">
                                        <i className="fa fa-facebook-f"></i>
                                        <span>Facebook</span>
                                    </a>
                                </li>
                            </ul>
                        </div> */}
                        <BottomNav />
                        {
                             this.state.pageLoader
                             ?
                             (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                            )
                            :
                            null
                           }
                        
                            
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default NetworkingLounge;
