import React, { Component } from "react";
import Axios from "axios";

import { Link } from "react-router-dom";
import Slider from "react-slick";
import firebase from "../../Firebase/index";

class LoginSplash extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sponsersImgList: [],
      sponsersUrl: "",
      pageUrlVarify: false,
      pageLoader: true,
      allowAsExhibitor: false,
      allowAsSpeaker: false,
      allowAsLobbier: true,
    };
  }

  componentDidMount() {
    // document.getElementById("profile_img").src = "/images/placeholder-profile.jpg";

    if (localStorage.getItem("roles")) {
      var rolesArr = localStorage.getItem("roles").split(",");
      if (rolesArr.indexOf("-1") !== -1) {
        this.setState({ allowAsExhibitor: true });
      }
      if (rolesArr.indexOf("-6") !== -1) {
        this.setState({ allowAsSpeaker: true });
      }
      if (rolesArr.indexOf("-7") !== -1) {
        this.setState({ allowAsExhibitor: true });
      }
    }

    if (/iPhone|iPad|Mac|Macintosh|iPod/i.test(navigator.userAgent)) {
      console.log("yes apple");
    } else {
      if (!("Notification" in window)) {
        console.log("This browser does not support desktop notification");
      }
      else if (Notification.permission === "granted") {
        const messaging = firebase.messaging();
            messaging
            .getToken()
              .then((token) => {
                console.log(token)
                this.saveFirebasePushNotificationToken(token);
              })
              .catch((err) => {
                console.log(err);
              });
      }
      else if (Notification.permission !== "denied") {
        Notification.requestPermission().then(function (permission) {
          if (permission === "granted") {
            const messaging = firebase.messaging();
            messaging
            .getToken()
              .then((token) => {
                console.log(token)
                
                const tokData = {
                  token: token,
                  email: localStorage.getItem("userName"),
                };
            
                Axios.post(`${global.config.requestUrl}saveFirebaseToken`, tokData, {
                  headers: { "auth-token": localStorage.getItem("exhibitorToken") },
                })
                  .then((res) => {})
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          }
        })
      } 
    }

    Axios.get(
      `${global.config.requestUrl}exhibitor?eventId=${localStorage.getItem(
        "eventId"
      )}&type=2`
    )
      .then((res) => {
        if (res.data.errorCode === 0) {
          this.setState({
            pageUrlVarify: true,
            pageLoader: false,
            sponsersImgList: res.data.data,
            sponsersUrl: res.data.logo,
          });
        } else {
          this.setState({
            sponsersImgList: [
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
              {
                logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
                exhibitor_id: null,
              },
            ],
          });
          this.setState({ pageLoader: false });
        }

        /* console.log(res.data.data) */
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  saveFirebasePushNotificationToken = (token) => {
    const tokData = {
      token: token,
      email: localStorage.getItem("userName"),
    };

    Axios.post(`${global.config.requestUrl}saveFirebaseToken`, tokData, {
      headers: { "auth-token": localStorage.getItem("exhibitorToken") },
    })
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
    };
    const mobileSettings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 2,
      slidesToScroll: 1,
      autoplay: true,
    };
    return (
      <React.Fragment>
        <section className="bg2 signleScreen overflow-hidden">
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="splashWrp">
              <div className="splashListWrp">
                <div className="tp_logo">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/images/navigate2022/logo-title.png"
                    }
                    alt=""
                  />
                  <div style={{fontSize:'16px'}}><strong>October 3-5, 2022</strong></div>
                </div>
                {this.state.allowAsSpeaker ? (
                  <div className="splashList">
                    <div className="splashBx">
                      <Link to="/navigate2022/speaker/dashboard">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/splashSpeaker.png"
                          }
                          alt=""
                        />
                        <h3>Speaker Dashboard</h3>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {this.state.allowAsExhibitor ? (
                  <div className="splashList">
                    <div className="splashBx">
                      <Link to="/navigate2022/exhibitor/dashboard">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/splashExhibit.png"
                          }
                          alt=""
                        />
                        <h3>Exhibitor Dashboard</h3>
                      </Link>
                    </div>
                  </div>
                ) : null}
                {this.state.allowAsLobbier ? (
                  <div className="splashList">
                    <div className="splashBx">
                      <Link to="/navigate2022/attendee/lobby">
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/splashAtandee.png"
                          }
                          alt=""
                        />
                        <h3>Attendee Lobby</h3>
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
              {/* {window.innerWidth > 767 ? (
                <div className="sponsersSlideBx">
                  <Slider {...settings}>
                    {this.state.sponsersImgList.map((sponsers) => (
                      <div className="sponserBx" key={sponsers.logo}>
                        <img src={sponsers.logo} alt="" className="" />
                          <p>
                              {sponsers.logo_level === 1?"Diamond ": ""}
                              {sponsers.logo_level === 2?"Platinum ": ""}
                              {sponsers.logo_level === 3?"Gold ": ""}
                              {sponsers.logo_level === 4?"Bronze ": ""}
                              {sponsers.logo_level === 5?"Nickel ": ""}
                              Sponsor
                          </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              ) : (
                <div className="sponsersSlideBx">
                  <Slider {...mobileSettings}>
                    {this.state.sponsersImgList.map((sponsers) => (
                      <div className="sponserBx" key={sponsers.logo}>
                        <img src={sponsers.logo} alt="" className="" />
                        <p>
                            {sponsers.logo_level === 1?"Diamond ": ""}
                            {sponsers.logo_level === 2?"Platinum ": ""}
                            {sponsers.logo_level === 3?"Gold ": ""}
                            {sponsers.logo_level === 4?"Bronze ": ""}
                            {sponsers.logo_level === 5?"Nickel ": ""}
                            Sponsor
                        </p>
                      </div>
                    ))}
                  </Slider>
                </div>
              )} */}   { /* comment By gaurav dixit at 23/09/2022*/}
            
            <div className="lobbySponsorsBx">
                            <ul>
                                {
                                    this.state.sectionLoader
                                    ?
                                    (
                                        <div className="sectionLoader">
                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                        </div>
                                    )
                                    :
                                    null
                                }
                                { 
                                    this.state.sponsersImgList.map(sponsers => (
                                        <li key={sponsers.exhibitor_id}>
                                    {
                                        sponsers.exhibitor_id === 0
                                        ?
                                        (
                                        <span style={{ margin:"0 10px",display:"block" }}>
                                            <img src={sponsers.logo} alt=""/>
                                            {/* <p>Sponsor</p> */}
                                        </span>
                                        )
                                        :
                                        <span style={{ margin:"0 10px",display:"block" }}>
                                            <img src={sponsers.logo} alt="" width={"100%"}/>
                                            <p>
                                                {sponsers.logo_level === 1?"Diamond ": ""}
                                                {sponsers.logo_level === 2?"Platinum ": ""}
                                                {sponsers.logo_level === 3?"Gold ": ""}
                                                {sponsers.logo_level === 4?"Bronze ": ""}
                                                {sponsers.logo_level === 5?"Nickel ": ""}
                                                Sponsor
                                            </p>
                                            </span>
                                       
                                    } 
                                        </li>
                                    ))
                                }                           
                            </ul>
                        </div>
            
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}
export default LoginSplash;
