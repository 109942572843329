import React, { Component } from 'react'
import Axios from 'axios';
import { Link, withRouter } from "react-router-dom";


export default class BlankSessionRelatedHtml extends Component {
    constructor(props){
        super(props);
        
    }

    closeSessionDesc = () => {
       document.getElementById("sessionDetailsModal").style.display = "none";
    }
    
    render() {
        
        return (
          <React.Fragment>
            <div className="midModal" id="sessionDetailsModal" style={{'display':'none'}}>
        <div className="outerModal"  onClick={this.closeSessionDesc}></div>
                            <div className="midModalArea">
                            <div className="modalPopUpClose" onClick={this.closeSessionDesc}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3" style={{'position':'relative'}}>
                                    About Session</h3>
                                  <div className="sessionListDataWrp p-4">
                                    <div className="midModalAreaContent" id="sessionTitleData"></div>
                                    <div className="midModalAreaContent" id="sessionDetailsData"></div>
                                    <div className="midModalAreaContent" id="sessionSpeakerData"></div>
                                    <div className="midModalAreaContent mt-3" id="sessionDocumentData"></div>
                                  </div>
                               
                    
                     </div>
                            </div>
          </React.Fragment>
        );
      }
}
