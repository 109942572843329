import React, { Component } from 'react'
import Axios from 'axios';
import { Accordion, Card, Button  } from 'react-bootstrap';


export default class AboutPresenter extends Component {
    constructor(props){
        super(props);

        this.state={
            presenterBioContent:[],
            aboutPresenterError:"",
            pageLoader:true,
        }
    }
    componentDidMount(){
        const {match : {params}}  = this.props.passprops;
        Axios.get(`${global.config.requestUrl}user/getPresenterDetails?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({presenterBioContent:res.data.data, aboutPresenterError:res.data.errorCode, pageLoader:false})
        })
        .catch(err => {
            console.log(err);
        });
    }
    render() {
        return (
            <React.Fragment>
            {
                this.state.pageLoader
                ?
                (
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                )
                :
                null
            }
            {
                <React.Fragment>
                    <Accordion defaultActiveKey="0">
                    {
                        this.state.aboutPresenterError !== 0
                        ?
                        <h5>No Content Found</h5>
                        :
                        <React.Fragment>
                            {
                                this.state.presenterBioContent.map(content => (
                                    <Card key={content.presenter_id}>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey={content.presenter_id}>
                                                {content.name}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={content.presenter_id}>
                                            <Card.Body>
                                                <img src={content.profile_pic} alt="" className="speakerAbtPic"/>
                                                <h5 className="mb-3 mt-3">{content.title}</h5>
                                                {content.about}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))
                            }
                        </React.Fragment>
                    }
                    
                </Accordion>
                </React.Fragment>
            }
            </React.Fragment>
        )
    }
}
