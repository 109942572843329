import React,{useState, useEffect} from 'react';
import Axios from 'axios';
import { Accordion, Card, Button  } from 'react-bootstrap';
import ReactHtmlParser from 'react-html-parser';

const SessionDetailsModal = (props) => {

    const [PageLoader, setPageLoader] = useState(false);
    const [SurveyQuestions, setSurveyQuestions] = useState([]);
    const [SurveyAnswersConfig, setSurveyAnswersConfig] = useState([]);
    const [AnswerData, setAnswerData] = useState([]);
    const [totalQuestionsCount, setTotalQuestionCount] = useState(0);
    const [totalAnswersCount, setTotalAnswersCount] = useState(0);
    

    useEffect(() => {
        getSurveyData();
        getSubmittedAnswers();
        
    }, []);

    const getSurveyData =()=>{
        setPageLoader(true)
        let totalQuestions = 0;
        let totalAnswers = 0;
        Axios.get(`${global.config.requestUrl}user/getPollingSessionQuestions?eventId=${localStorage.getItem('eventId')}&sessionId=${localStorage.getItem('modalSessionId')}&accountId=${localStorage.getItem('accountId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        .then(res => {
            if(res.data.data){
                setSurveyQuestions(res.data.data)
                var questionArr = [];
                for(var i = 0;i < res.data.data.length;i++){
                    if(!res.data.data[i].sName.includes('If no')){
                        totalQuestions++;
                    }
                    questionArr.push(res.data.data[i].lQuestionID)
                }
                setTotalQuestionCount(totalQuestions)

                //Get Answers for all questions
                const postData = {
                    eventId: localStorage.getItem('eventId'),
                    accountId:localStorage.getItem('accountId'),
                    questionArray:questionArr.toString()
                }
                console.log('data lenght is - ',res.data.data.length)
            
                Axios.post(`${global.config.requestUrl}user/getPollingSessionAnsConfig`, postData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {   
                    setSurveyAnswersConfig(res.data.data)
                })
                .catch(err => {
                    console.log(err);
                });
            }

            //console.log(questionArr)
            //setPageLoader(false)
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getSubmittedAnswers = ()=>{
        Axios.get(`${global.config.requestUrl}user/getSurveySubmittedAnswers?eventId=${localStorage.getItem('eventId')}&sessionId=${localStorage.getItem('modalSessionId')}&accountId=${localStorage.getItem('accountId')}&userId=${localStorage.getItem('userId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        .then(res => {
            if(res.data.data){
                let ansFields=AnswerData;
                for(var i = 0;i < res.data.data.length;i++){
                    if(res.data.data[i].lAnswerID == 0){
                        ansFields[localStorage.getItem('modalSessionId') +'-'+ res.data.data[i].lQuestionID+'-t'] = res.data.data[i].sOther;
                    } else {
                        ansFields[localStorage.getItem('modalSessionId') +'-'+ res.data.data[i].lQuestionID] = localStorage.getItem('modalSessionId') +'-'+res.data.data[i].lQuestionID +'-'+res.data.data[i].lAnswerID +'-'+res.data.data[i].sOther;
                    }
                }
                setAnswerData(ansFields);
            }
            setPageLoader(false)
            
        })
        .catch(err => {
            console.log(err);
        });
    }

    const getData = (questionid) => {
        if(SurveyAnswersConfig.length > 0){
            let items = [];         
            for (let i = 0; i < SurveyAnswersConfig.length; i++) {      
                if(questionid == SurveyAnswersConfig[i].lQuestionID){
                    items.push(<option key={localStorage.getItem('modalSessionId') +'-'+SurveyAnswersConfig[i].lQuestionID +'-'+SurveyAnswersConfig[i].lAnswerID +'-'+SurveyAnswersConfig[i].sAnswer} value={localStorage.getItem('modalSessionId') +'-'+SurveyAnswersConfig[i].lQuestionID +'-'+SurveyAnswersConfig[i].lAnswerID +'-'+SurveyAnswersConfig[i].sAnswer}>{SurveyAnswersConfig[i].sAnswer}</option>); 
                }
                
            }
            return items;
        }         
    }

    const captureAnswers = (type,qid,ans) => {

        switch (type) {
            case 0:
                //console.log(ans)
                setAnswerData({ ...AnswerData, [localStorage.getItem('modalSessionId') +'-'+ qid + '-t']: ans });
                //console.log(AnswerData)
            break;

            case 1://Radiobutton
                setAnswerData({ ...AnswerData, [localStorage.getItem('modalSessionId') +'-'+ qid]: ans });
                //console.log(AnswerData)
                
            break;

            case 2:
                var kky = localStorage.getItem('modalSessionId') +'-'+qid;

                if(kky in AnswerData){
                    //console.log('yes')
                } else {
                    //console.log('no')
                    setAnswerData({ ...AnswerData, [localStorage.getItem('modalSessionId') +'-'+qid]: ans });
                }
                //console.log(AnswerData)
            break;

            case 3://for dropdown
                setAnswerData({ ...AnswerData, [localStorage.getItem('modalSessionId') +'-'+qid]: ans });
                //console.log(AnswerData)
            break;

            case 4:
                setAnswerData({ ...AnswerData, [localStorage.getItem('modalSessionId') +'-'+qid + '-t']: ans });
                //console.log(AnswerData)
            break;
        }
    }

    const validateInput = () =>{
        setPageLoader(true)
        var counter = 0;
        var valueString = '';
        
        for (var key in AnswerData) {         

            var txtData = key.split('-')
            //console.log('textbox - ',txtData)
            if(txtData.length > 2){
                if(txtData[1] > 0){
                    var separator = (valueString == '') ? '':','
                    valueString = valueString + separator +'('+localStorage.getItem('accountId')+','+localStorage.getItem('eventId')+','+localStorage.getItem('modalSessionId')+','+txtData[1]+','+localStorage.getItem('userId')+','+0+',"'+AnswerData[key]+'")'
                }
                
            } else {
                var dd = AnswerData[key].split('-')
                if(dd[1] > 0){
                    var separator = (valueString == '') ? '':','
                    valueString = valueString + separator + '('+localStorage.getItem('accountId')+','+localStorage.getItem('eventId')+','+localStorage.getItem('modalSessionId')+','+dd[1]+','+localStorage.getItem('userId')+','+dd[2]+',"'+dd[3]+'")'
                }
            }                       
            counter++;
        }
        //console.log(valueString)
        if(valueString == ''){
            var valueStringCombine =  '('+localStorage.getItem('accountId')+','+localStorage.getItem('eventId')+','+localStorage.getItem('modalSessionId')+','+0+','+localStorage.getItem('userId')+','+0+',"'+0+'")';
        } else {
            var valueStringCombine = valueString + ',' +  '('+localStorage.getItem('accountId')+','+localStorage.getItem('eventId')+','+localStorage.getItem('modalSessionId')+','+0+','+localStorage.getItem('userId')+','+0+',"'+0+'")';
        }

        var postData = {
            lAccountID:localStorage.getItem('accountId'),
            lEventID:localStorage.getItem('eventId'),
            lSessionID:localStorage.getItem('modalSessionId'),
            userId:localStorage.getItem('userId'),
            data:valueStringCombine,
            totalSurveyQuestion:totalQuestionsCount,
            totalSurveyAnswers:counter

        } 
        //console.log(valueStringCombine)        
        Axios.post(`${global.config.requestUrl}user/savePollingAnswers`, postData,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {   
            //setSurveyAnswersConfig(res.data.data)
            window.location.reload();
        })
        .catch(err => {
            console.log(err);
        });
        //console.log(counter)
        //setPageLoader(false)
        //window.location.reload(false);
    }

    return (
        <>
            {
                PageLoader
                ?
                <div className="loaderWrp">
                    <div className="loaderInr">
                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                    </div>
                </div>
                :
                null
            }
            {
                SurveyQuestions?.length === 0 
                //SurveyQuestions && SurveyQuestions.length
                
                ?
                <div className="col-12 col-md-6">
                    <div className="form-group">
                        <h2>Survey not available</h2>
                    </div>
                </div>
                :
                <Accordion defaultActiveKey="0">
                {
                    SurveyQuestions.map(content => (
                    <Card key={content.lQuestionID}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                {content.sName}
                            </Accordion.Toggle>
                        </Card.Header>
                        
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            {
                                content.nType == 0 ?
                                <>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" className="form-control" value={AnswerData[localStorage.getItem('modalSessionId') +'-'+content.lQuestionID+'-t']} onChange={(e) => captureAnswers(0,content.lQuestionID,e.target.value)} name={localStorage.getItem('modalSessionId') +'-'+content.lQuestionID}/>
                                        </div>
                                    </div>
                                </>                                        
                                : null                                    
                            }   
                            {
                                content.nType == 1 ?
                                    SurveyAnswersConfig.map((ans) => { 
                                        if(content.lQuestionID == ans.lQuestionID){
                                        return(
                                            <>
                                            <div className="col-12 col-md-6">
                                            <div className="form-check">
                                            <input className="form-check-input"
                                            value={AnswerData[localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID]} 
                                            onChange={()=> captureAnswers(1,ans.lQuestionID,localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID+'-'+ans.lAnswerID+'-'+ans.sAnswer)} 
                                            type="radio" 
                                            name={localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID}
                                            checked = {(AnswerData[localStorage.getItem('modalSessionId') +'-'+content.lQuestionID] == localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID+'-'+ans.lAnswerID+'-'+ans.sAnswer)? true:false}  
                                            /> {ans.sAnswer}
                                            <br />
                                            </div>
                                            </div>
                                            </>
                                            
                                        )
                                        }
                                    
                                }) : null                                    
                            }   
                            {
                                content.nType == 2 ?
                                    SurveyAnswersConfig.map((ans) => { 
                                    if(content.lQuestionID == ans.lQuestionID){
                                    return(
                                        <>
                                        <div className="col-12 col-md-6">
                                        <div className="form-check">
                                        <input className="form-check-input"
                                        type="checkbox" 
                                        onChange={()=> captureAnswers(2,ans.lQuestionID+'-'+ans.lAnswerID,localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID+'-'+ans.lAnswerID+'-'+ans.sAnswer)} 
                                        defaultChecked = {(AnswerData[localStorage.getItem('modalSessionId') +'-'+content.lQuestionID +'-'+ans.lAnswerID] == localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID+'-'+ans.lAnswerID+'-'+ans.sAnswer)? true:false}
                                        //value={localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID +'-'+ans.lAnswerID +'-'+ans.sAnswer} 
                                        name={localStorage.getItem('modalSessionId') +'-'+ans.lQuestionID}/> {ans.sAnswer}
                                        <br />
                                        </div>
                                        </div>
                                        </>                                            
                                    )
                                    }
                                    
                                }) : null                                    
                            }  
                                
                            {
                                content.nType == 3 ?
                                <>
                                    <div className="col-12 col-md-2">
                                        <div className="form-group">
                                        <select className="form-control" value={AnswerData[localStorage.getItem('modalSessionId') +'-'+content.lQuestionID]} onChange={(e)=> captureAnswers(3,content.lQuestionID,e.target.value)} name={localStorage.getItem('modalSessionId') +'-'+content.lQuestionID}>
                                        <option>Select</option>
                                            {getData(content.lQuestionID)}
                                        </select>
                                        </div>
                                    </div>
                                </>
                                :                                     
                                null                                    
                            } 

                            {
                                content.nType == 4 ?
                                <>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                    <textarea rows="4" value={AnswerData[localStorage.getItem('modalSessionId') +'-'+content.lQuestionID+'-t']} onChange={(e) => captureAnswers(4,content.lQuestionID,e.target.value)} name={localStorage.getItem('modalSessionId') +'-'+content.lQuestionID} />
                                    </div>
                                </div>
                                </>
                                    
                                : null                                    
                            }  
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    ))
                }
                <>
                <div className="col-12 col-md-6">
                    <div className="form-group">
                    <button type="button" onClick={validateInput} className="btn btn-primary btn-lg">Save</button>
                    </div>
                </div>
                </>
            </Accordion>
            }
            
        </>
    )
}
export default SessionDetailsModal;