import React, { Component } from 'react'
import Axios from 'axios';
import { Link, withRouter } from "react-router-dom";


export default class BlankSpeakerRelatedHtml extends Component {
    constructor(props){
        super(props);
        
    }

    closeSpeakerDesc = () => {
       document.getElementById("speakerDetailsModal").style.display = "none";
    }
    
    render() {
        
        return (
          <React.Fragment>
            <div className="midModal" id="speakerDetailsModal" style={{'display':'none'}}>
        <div className="outerModal"  onClick={this.closeSpeakerDesc}></div>
                            <div className="midModalArea">
                            <div className="modalPopUpClose" onClick={this.closeSpeakerDesc}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3" style={{'position':'relative'}}>
                                    About Speaker</h3>
                                  <div className="sessionListDataWrp p-4">
                                  
                                    <div className="midModalAreaContent" id="speakerBioData"></div>
                                    <div className="midModalAreaContent mt-3" id="speakerSessionData"></div>
                                    
                                  </div>
                               
                    
                     </div>
                            </div>
          </React.Fragment>
        );
      }
}
