import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';

class LinkToSessionRenderer extends Component {

    constructor(props){
        super(props);

        this.state={
            //pageLoader : true,
        }
        
    }

    getAllSessionRelatedData = (sessId) =>{
        document.getElementById("sessionSpeakerData").innerHTML = '';
        document.getElementById("sessionDocumentData").innerHTML = '';
        document.getElementById("sessionDetailsData").innerHTML = '';
        document.getElementById("sessionTitleData").innerHTML = '';
          this.getSessionBio(sessId);
          this.getSessionDocuments(sessId);
          this.getSpeakerBio(sessId);
          document.getElementById("sessionDetailsModal").style.display = "block";
    }
    getSpeakerBio = (sessId) => {
        Axios.get(`${global.config.requestUrl}getSpeakerBio?eventId=${localStorage.getItem('eventId')}&sessionId=${sessId}`
        )
        
        .then(res => {
            var data = '';
            if(!res.data.errorCode){
              if(res.data.data.length === 1){
                res.data.data.map(content => (
                    //console.log(content)
                    data+='<div class="singlespeaker"><div class="speakercard" onclick="toggleSpeakerContent('+content.speaker_id+')">'+content.name+'</div><div id="speakerdetail_'+content.speaker_id+'"  style="display:block;"  > <img src="'+content.profile_pic+'" alt="" class="speakerAbtPic"/>'+content.about+'</div></div>'
                 ))
              }else{
                res.data.data.map(content => (
                    //console.log(content)
                    data+='<div class="singlespeaker"><div class="speakercard" onclick="toggleSpeakerContent('+content.speaker_id+')">'+content.name+'</div><div id="speakerdetail_'+content.speaker_id+'"  style="display:none;"  > <img src="'+content.profile_pic+'" alt="" class="speakerAbtPic"/>'+content.about+'</div></div>'
                 ))
              }
               
            }
            document.getElementById("sessionSpeakerData").innerHTML = data;
        })
        .catch(err => {
            console.log(err);
        });
    }
    getSessionDocuments = (sessId) => {
        Axios.get(`${global.config.requestUrl}getSessionDocuments?eventId=${localStorage.getItem('eventId')}&sessionId=${sessId}`
        )
        
        .then(res => {
            tbl = '';
            if(!res.data.errorCode){
                var tbl = '<div className="modalTableWrp"><table className="table modalTable text-center mt-3"><tbody>';
                res.data.data.map(content => (
                    tbl+= '<tr><td className="text-left"><a href="'+content.file+'" target="_blank" rel="noopener noreferrer">'+content.name+'</a></td></tr>'
                ))
                tbl+= '</tbody></table></div>';
            }
            document.getElementById("sessionDocumentData").innerHTML = tbl;
            
        })
        .catch(err => {
            console.log(err);
        });
    }
    getSessionBio = (sessId) =>{
        Axios.get(`${global.config.requestUrl}getSessionBio?eventId=${localStorage.getItem('eventId')}&sessionId=${sessId}`
        )
        .then(res => {
             //console.log(res.data.data[0].description)
             var desc = '';
             var sessTitle = '';
             if(!res.data.errorCode){
                var desc = '<p>'+res.data.data[0].description+'</p>';
                var sessTitle = '<h5>'+res.data.data[0].title+'</h5>';
             }
             document.getElementById("sessionDetailsData").innerHTML = desc;
             document.getElementById("sessionTitleData").innerHTML = sessTitle;
             

        })
        .catch(err => {
            console.log(err);
        });
    }

    

    render() {
        const sessName = this.props.valueFormatted ? this.props.valueFormatted : this.props.value;
        const sessId = this.props.data.session_id;
            return (
                <React.Fragment>
                    {
                        localStorage.getItem('singleSignOn') === "0"
                        ?
                        <div  onClick={() => this.getAllSessionRelatedData(sessId)}>{sessName}</div>
                        :
                        <Link to={`/common-powerup2021/attendee/conferenceCenterDetails/${sessId}`}>{sessName}</Link>
                    }
                    
                </React.Fragment>
            )
        
        
         
    }
}
export default LinkToSessionRenderer;
