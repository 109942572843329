import React, { Component } from 'react';

import {storage} from '../../../Firebase';
import Axios from 'axios';

class AddPosterBanner extends Component {
    constructor(props){
        super(props);

        this.state = {
            pageLoader : true,
            sessionId : this.props.sessionId,
            eventId : localStorage.getItem('eventId'),
            sessionOrPosterBio:'',
            sessionOrPosterBanner:'',
            sessionOrPosterBannerLink:''
        }
    }
    
      componentDidMount (){
          this.getSessionOrPosterDetails();
      }

      getSessionOrPosterDetails = () =>{
        this.setState({pageLoader:true,sessionOrPosterBio:'',sessionOrPosterBanner:'',sessionOrPosterBannerLink:''})
        Axios.get(`${global.config.requestUrl}speaker/getPosterOrSessionData?eventId=${localStorage.getItem('eventId')}&sessionId=${this.state.sessionId}`,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            
            .then(res => {
                if(res.data.errorCode === 0){
                    this.setState({sessionOrPosterBio:res.data.data[0].bio,sessionOrPosterBanner:res.data.data[0].banner,sessionOrPosterBannerLink:res.data.data[0].banner_link})  
                }
                this.setState({pageLoader:false})
            })
            .catch(err => {
                console.log(err);
            });
    }

    updateSessionOrPosterDetails = (e) => {
        e.preventDefault();
        this.setState({pageLoader:true}); 
        const sessionOrPosterData = {
            eventId: localStorage.getItem('eventId'),
            sessionId : this.state.sessionId,
            bio: this.state.sessionOrPosterBio,
            banner: this.state.sessionOrPosterBanner
           }

           Axios.post(`${global.config.requestUrl}speaker/updateSessionOrPosterInfo`, sessionOrPosterData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode === 0){
                    this.setState({sessionOrPosterSuccess:'Details updated!!!'});
                }else
                  this.setState({sessionOrPosterErr:'Some Error!!!'});

                this.setState({pageLoader:false});  
            })
            .catch(err => {
                console.log(err);
            });
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }


    

    
    render() {
     
        return (
            <React.Fragment>
        <div className="modalPopUpFormBx">
                            <div className="infoMsg">
                                <p>Add a brief description about the session/presentation.  <br/>PLEASE BE SURE TO INCLUDE THE TITLE OF THE POSTER.</p>
                            </div>
                                <h4 className="mb-3">About Session</h4>
                                <div className="success">{this.state.sessionOrPosterSuccess}</div>
                                <div className="error">{this.state.sessionOrPosterErr}</div>
                                <form onSubmit={this.updateSessionOrPosterDetails}>
                                    <div className="form-group">
                                        <textarea name="" 
                                            placeholder="Add Session/Poster Bio here" 
                                            id="sessionOrPosterBio" 
                                            value={this.state.sessionOrPosterBio} 
                                            className="form-control" 
                                            rows="5" 
                                            onChange={this.onChange} 
                                            name="sessionOrPosterBio" >
                                        </textarea>
                                    </div>
                                    <div className="mt-3">
                                        <button type="submit">Submit</button>
                                    </div>
                                </form>

                                {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                                
                            </div>
                            </React.Fragment>
        )

    }


    
}
export default AddPosterBanner;