import React,{useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import Axios from 'axios';
import { useHistory } from 'react-router-dom';

const Login = ({setExhibitorToken}) => {
    const [sponserImg, setSponserImg] = useState([]);
    const history = useHistory();
    const [state , setState] = useState({
        email : "",
        emailError:""
    })
    const { id } = useParams();
    localStorage.setItem('eventId',id);
    useEffect(()=>{
        
        Axios.get(`${global.config.requestUrl}exhibitor?eventId=${id}&type=1`).then(
            res=>{
                if(res.data.errorCode === 0){
                   setSponserImg(res.data.data)
                }else{
                    setSponserImg([
                        {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                        {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null}
                    ])
                }
            }
        )
    },[id]);

    const logInSubmit = (e,props)=>{
        e.preventDefault();
        //alert(state.email);return false;
        
        let flag = 0
        if(state.email === ''){
            flag = 1;
            setState({emailError:"Please insert some user email"})
        }
        if(!flag){
            const loginData = {
                username: state.email,
                eventId: localStorage.getItem('eventId'),
            }
            Axios.post(`${global.config.requestUrl}user/mdapa_login`, loginData
            ).then(res => {
            
               if(res.data.errorCode === 0){
                   setExhibitorToken(res.data.token);
                   localStorage.setItem('exhibitorToken',res.data.token);
                   localStorage.setItem('accountId',res.data.accountId);
                   localStorage.setItem('username',state.email);
                   localStorage.setItem('userId',res.data.id);
                   localStorage.setItem('currentRole',res.data.roles);
                   setState({emailError:""})
                   loginAnalytics(state.email,localStorage.getItem('eventId'))
                   
                   var rolesArr = localStorage.getItem("currentRole").split(",");
                    if (rolesArr.indexOf("-1") !== -1) {
                        //this.setState({ allowAsExhibitor: true });
                        history.push("/mdapa/loginSplash");
                    }
                    else if (rolesArr.indexOf("-6") !== -1) {
                        //this.setState({ allowAsSpeaker: true });
                         history.push("/mdapa/lobby");
                        //history.push("/tobi2022/loginSplash");
                    }
                    else if (rolesArr.indexOf("-7") !== -1) {
                        //this.setState({ allowAsExhibitor: true });
                        history.push("/mdapa/loginSplash");
                    }
                    else{
                        history.push("/mdapa/lobby");
                        //history.push("/tobi2022/loginSplash");
                    }
                   
               }else{
                setState({emailError:"User does not EXIST"})
               }
            })
            .catch(err => {
               console.log(err);
            });
        }
        
    }
    const handleChange = (e) => {
        const {id , value} = e.target
        setState(prevState => ({
            ...prevState,
            [id] : value
        }))
    }
    const loginAnalytics = (emailId,eventId) =>{
        const loginData = {
            email: emailId,
            eventId: eventId,
          }
          Axios.post(`${global.config.requestUrl}user/saveLoginInfo`, loginData,
              {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
          ).then(res => {
                
          }).catch(err => {
              console.log(err);
          });
      }
    return (
        <React.Fragment>
            <div className="p-3">
                <div className="card p-3 border-none box-shadow">
                    <div className='grey-box'>
                        <div className='card p-3'>
                        <h4 className="text-center mb-4">SIGN IN</h4>
                        <form onSubmit={logInSubmit}>
                            <div className="customInput mb-3">
                                <div className="inputIco">
                                    <img src={process.env.PUBLIC_URL + '/images/mdapa/user-line.png'} alt="" />
                                </div>
                                <input type="email" id="email" name="email" value={state.email || ""} onChange={handleChange} placeholder="Email address" />
                                <div className="errorMessage">{state.emailError}</div>
                            </div>
                            <button className="customBtn mt-1" type="submit">SUBMIT</button>
                        </form>
                        </div>
                    </div>
                </div>
                <div className="border-none mt-3">
                    <div className="twoSideList">
                        {sponserImg.map((sponsor,index)=>(
                            <div className="card" key={index}>{sponsor.link ? <a href={sponsor.link} target="_blank"><img src={sponsor.logo} alt="" /></a> : <img src={sponsor.logo} alt="" />}</div>
                        ))}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Login;