import React,{useState} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './Components/MdAPA2023/Header';
import Login from './Components/MdAPA2023/Login';
import Lobby from './Components/MdAPA2023/Lobby';
import SpeakerList from './Components/MdAPA2023/speakers/SpeakerList';
import SpeakerDetails from './Components/MdAPA2023/speakers/SpeakerDetails';
import SponsorList from './Components/MdAPA2023/sponsors/Sponsors';
import SponsorDetails from './Components/MdAPA2023/sponsors/SponsorDetails';
import AttendeesList from './Components/MdAPA2023/attendee/Attendees';
import AttendeeDetails from './Components/MdAPA2023/attendee/AttendeeDetails';
import ExhibitHall from './Components/MdAPA2023/attendee/ExhibitHall';
import ExhibitorDetails from './Components/MdAPA2023/attendee/ExhibitorDetails';
import ConferenceSchedule from './Components/MdAPA2023/conference/getSession';
import Maps from './Components/MdAPA2023/Maps/Maps';
import MapDetail from './Components/MdAPA2023/Maps/MapDetail';
import UserProfile from './Components/MdAPA2023/UserProfile';
import LoginSplash from './Components/MdAPA2023/LoginSplash';
import ExhibitorProfile from './Components/MdAPA2023/Exhibitor/profile';
import SpeakerProfile from './Components/MdAPA2023/speakers/SpeakerProfile';
import ChatPage from "./TmChat/MdAPA2023/chat/ChatPage";
import ChatNotification from "./TmChat/MdAPA2023/chat/ChatNotification";

import PrivateRoute from './PrivateRoute';


const MdAPA2023 = () => {
    const [exhibitorToken,setExhibitorToken] = useState(localStorage.getItem('exhibitorToken') || null);

    return (
        <Router>
            <Header exhibitorToken={exhibitorToken} />
            {exhibitorToken?<ChatNotification />:''}
            <Switch>
                {/* <Route path="/" exact component={Login} /> */}
                <Route path="/mdapa2023/login/:id" exact render={(props) => <Login setExhibitorToken={setExhibitorToken} {...props}/>} />
                <PrivateRoute path="/mdapa2023/loginSplash" exact component={LoginSplash} />
                <PrivateRoute path="/mdapa2023/conference" exact component={ConferenceSchedule} />
                <PrivateRoute path="/mdapa2023/lobby" exact component={Lobby} />
                <PrivateRoute path="/mdapa2023/speakers" exact component={SpeakerList} />
                <PrivateRoute path="/mdapa2023/speakers/:id" exact component={SpeakerDetails} />
                <PrivateRoute path="/mdapa2023/sponsors" exact component={SponsorList} />
                <PrivateRoute path="/mdapa2023/sponsor/:id" exact component={SponsorDetails} />
                <PrivateRoute path="/mdapa2023/attendees" exact component={AttendeesList} />
                <PrivateRoute path="/mdapa2023/attendee/exhibitHall" exact component={ExhibitHall} />
                <PrivateRoute path="/mdapa2023/attendee/exhibitor/:exhibitorId/:title" exact component={ExhibitorDetails} />
                <PrivateRoute path="/mdapa2023/attendee/:emailId" exact component={AttendeeDetails} />
                <PrivateRoute path="/mdapa2023/maps" exact component={Maps} />
                <PrivateRoute path="/mdapa2023/maps/details" exact component={MapDetail} />
                <PrivateRoute path="/mdapa2023/user/profile" exact component={UserProfile} />
                <PrivateRoute path="/mdapa2023/exhibitor/profile" exact component={ExhibitorProfile} />
                <PrivateRoute path="/mdapa2023/speaker/profile" exact component={SpeakerProfile} />
                <PrivateRoute path="/mdapa2023/chat" exact component={ChatPage} />
                

            </Switch>
        </Router>
    )
}
export default MdAPA2023;