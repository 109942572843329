module.exports = global.config = {
    requestUrl: 'https://tslvirtual.herokuapp.com/',
    //requestUrl: 'https://api.tslvirtualconference.com:3000/',

    // Decide which page you want to enable/disable here (currently work for FNA only)
    login:1,
    loginSplash:1,
    exhibitHall:1,
    speakerDashboard:0,
    speakerProfile:0,
    exhibitorDashBoard:1,
    exhibitorProfile:1,
    exhibitorBooth:1,
    attendeeLobby:0,
    attendeeConferenceCenter:0,
    attendeeTechnicalSessions:0,
    attendeeNetworkingLounge:0,
    attendeeConferenceCenterDetails:0,
    attendeeGoldenTicket:0,
    attendeeHelpDesk:0,
    speakerAttendees:0,
    userProfile:0,
    speakerSession:0,
    chat:0,
    video_call:0,
    video_conference:0,
    conference_meeting:0,
    //Set if bottom nav show/hide
    showBottomNav:0,



    // other global config variables you wish
};