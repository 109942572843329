import React, { Component } from 'react';
import BottomNav from './BottomNav'
import Axios from 'axios';
import {Link} from 'react-router-dom';

import Slider from "react-slick";

import AboutSession from './AboutSession';
import AboutSpeaker from './AboutSpeaker';
import SessionDocuments from './SessionDocuments';
import SessionChatRoom from "../../../TmChat/paos/sessionChatRoom/sessionChatRoom";
import MainSessionAllDetails from './MainSessionAllDetails';

class ConferenceCenterPage extends Component {
    constructor(props){
        super(props);

        this.state={
            sessionBannerList:[],
            mediaPartnerList:[],
            producedByList:[],
            sectionLoader:true,
            aboutSessionModal:false,
            aboutSpeakerModal:false,
            sessionDocumentsModal:false,
            liveVideoUrl:[],
            liveVideoUrlNotFound:"",
            
            liveUrlNull:"",
            ip:'127.0.0.1',
            pageLoader:true,
            iframeLoader:true,
            sessionSurveyModal:false,
            timeSpendInSeconds:0,
            minAttendance:0, // in seconds
            infoBoxModal:false,
            mainConferenceClass:"conferenceLinks"
        }
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = -10;

            if(rolesArr.length > 2 ){
                currentRole = -10;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                currentRole = -1;
            }
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                currentRole = -6;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                currentRole = -1;
            }
            if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                currentRole = -1;
            }
            localStorage.setItem('currentRole',currentRole);
            
        }
        
    }
    sessionLinksMobileToggle = () =>{
        this.setState({mainConferenceClass:"conferenceLinks activeToggle"})
        if(this.state.mainConferenceClass === "conferenceLinks activeToggle" ){
            this.setState({mainConferenceClass:"conferenceLinks"})
        }
    }
    infoBoxModalHandlar = (e) => {
        e.preventDefault();
        this.setState({infoBoxModal:!this.state.infoBoxModal})
    }
    componentWillUnmount = () => {

        //console.log(this.state.timeSpendInSeconds,this.state.minAttendance);
        if(this.state.timeSpendInSeconds >= this.state.minAttendance){
          this.updateScannedData();
        }
    }


    componentWillReceiveProps(nextProps){
        document.getElementById('myschedulepopup').click();
        this.getSessionData(nextProps);
        this.updateGoldenTicketStatus(nextProps);
        this.saveAuthForSurvey(nextProps);
   }
   sessionSurveyModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionSurveyModal:!this.state.sessionSurveyModal,iframeLoader:true})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSessionModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSessionModal:!this.state.aboutSessionModal})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSpeakerModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSpeakerModal:!this.state.aboutSpeakerModal})
        localStorage.setItem('conferenceClicked',1)
    }
    sessionDocumentsModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionDocumentsModal:!this.state.sessionDocumentsModal})
        localStorage.setItem('conferenceClicked',1)
    }
    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }
    updateScannedData = () => {

        
        const {match : {params}} = this.props;
        const scannedData = {
            regId: localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            sessionId: params.sessionId,
            // accountId:localStorage.getItem('accountId'),
            // serialNumber:this.state.ip,
            // duration: this.state.timeSpendInSeconds.toString(),
            role:localStorage.getItem('currentRole'),
        }

            //console.log(scannedData);return false;
            //Check before insert scanned data

            Axios.post(`${global.config.requestUrl}user/checkScanDataForUser`, scannedData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode === 0){  // Error case
                            this.insertScannedDataHere();
                        }
                })
                .catch(err => {
                    console.log(err);
                });


            
    }

    insertScannedDataHere = () => {
        const {match : {params}} = this.props;
            const scannedData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                accountId:localStorage.getItem('accountId'),
                serialNumber:this.state.ip,
                duration: this.state.timeSpendInSeconds.toString(),
                role:localStorage.getItem('currentRole'),
            }
        Axios.post(`${global.config.requestUrl}user/insertScanData`, scannedData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    updateGoldenTicketStatus = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        const ticketStatusData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:0,
            linkPage:'sessionRoom',
            sessionId:params.sessionId,
            exhibitorId:0
            
        }
        Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(res.data.errorCode){
            document.getElementById('goldenTkt').classList.add("greyScale");
            this.setState({pageLoader:false});
        }else{
            this.setState({pageLoader:false});
            document.getElementById('goldenTkt').classList.remove("greyScale");
        }
    })
    .catch(err => {
        console.log(err);
    });
}
    componentDidMount(){
        const {match : {params}} = this.props;
        this.getSessionData(this.props);
        this.saveAuthForSurvey(this.props);
        this.updateGoldenTicketStatus(this.props);
        fetch('https://geoip-db.com/json/')
           .then(res => res.json())
           .then(json => this.setState({ip: json.IPv4}));
        
        
    }

    countDown = () => {
      if(this.state.timeSpendInSeconds == this.state.minAttendance){
        this.updateScannedData();
      }
      var clk = this.state.timeSpendInSeconds;
      this.setState({timeSpendInSeconds: clk + 1});
       
    }

    getSessionData = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        Axios.get(`${global.config.requestUrl}user/getSessionVideo?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({liveVideoUrl:res.data.data, liveVideoUrlNotFound:res.data.errorCode, liveUrlNull:res.data.data[0].video_url, minAttendance:res.data.data[0].min_attendence*60, pageLoader:false})
            if(res.data.errorCode === 0){
                this.setState({})
                setInterval(this.countDown, 1000);
                if(res.data.data[0].video_url !== null){
                    if(res.data.data[0].video_url.includes('https://success.zoom.us/wc/join/') ){
                        document.getElementById('videoconf').innerHTML = '<div class="confWrap">'+res.data.data[0].video_text+' <p><a class="btn btn-warning" target="_blank" href ="'+res.data.data[0].video_url+'" > Click here </a></p></div>';
                    }
                    if( res.data.data[0].video_url.includes('https://teams.microsoft.com/') ){
                        document.getElementById('videoconf').innerHTML = '<div class="confWrap"><p><a class="btn btn-warning" target="_blank" href ="'+res.data.data[0].video_url+'" > Click here to launch Teams meeting </a></p></div>';
                    }
                }
                if(res.data.data[0].banner !== ''){
                    this.setState({sessionBannerList:[
                        {'logo':res.data.data[0].banner,'banner_link':res.data.data[0].banner_link},
                    ]});
                }else{
                    this.setState({sessionBannerList:[
                        {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','banner_link':null},
                    ]});
                }
                this.setState({sectionLoader:false});
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader:false,sectionLoader:false});
        });
    }


        saveAuthForSurvey = (myprop) => {
            const {match : {params}} = myprop;
            const authSurveyData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
            }

            Axios.post(`${global.config.requestUrl}user/insertSessionForSurvey`, authSurveyData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    render() {
        const {match : {params}} = this.props;
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
          };
            return (

                <React.Fragment>  
                    <section className="bg11 signleScreen paddingTop height1100 conference">
                        {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                        <div className="container wideContainer">
                            <div className="conferenceContainer" style={{'justifyContent':'center'}}>
                                {/* <div className="sessionChatBxx">
                                <SessionChatRoom sessionId={params.sessionId} />
                                </div> */}
                                {/* <div className="conferenceVideoWrp">
                                    {
                                        this.state.liveVideoUrlNotFound !== 0
                                        ?
                                        <div className="conferenceVideo videoNotFound">
                                           
                                        </div>
                                        :
                                        <React.Fragment>
                                            
                                            { 
                                                this.state.liveVideoUrl.map(videoLink => (
                                                    <React.Fragment>
                                                        <h3>{videoLink.session_name}</h3>
                                                        {
                                                            this.state.liveUrlNull === null || this.state.liveUrlNull === ""
                                                            ?
                                                            <div className="conferenceVideo videoNotFound">
                                                                <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" />
                                                            </div>
                                                            :
                                                            <div className="conferenceVideo" id="videoconf">
                                                                {
                                                                videoLink.video_url.includes('https://success.zoom.us/wc/join/') || videoLink.video_url.includes('https://teams.microsoft.com/')
                                                                ?
                                                                null
                                                                :
                                                                <iframe width="100%" height="315" src={`${videoLink.video_url}?rel=0`} key={videoLink.video_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                                }
                                                        </div>
                                                        }
                                                        
                                                    </React.Fragment>
                                                ))
                                            }
                                        
                                        </React.Fragment>

                                    }
                                        
                            
                                </div> */}
                                <div className={this.state.mainConferenceClass} style={{'minWidth':'350px'}}>
                                    {
                                        window.innerWidth <= 767
                                        ?
                                        <h3 onClick={this.sessionLinksMobileToggle}>Session Links</h3>
                                        :
                                        <h3>Session Links</h3>
                                    }
                                    <ul>
                                        {/* <li><Link onClick={this.aboutSessionModalHandler} to="/"><i class="fa fa-info"></i> About this session</Link></li> */}
                                        <li><Link onClick={this.aboutSpeakerModalHandler} to="/"><i class="fa fa-volume-up"></i> Speaker and Documents</Link></li>
                                        {/* <li><Link onClick={this.sessionDocumentsModalHandler} to="/"><i class="fa fa-file"></i> Session documents</Link></li> */}
                                        <li><Link onClick={this.sessionSurveyModalHandler} to="/"><i class="fa fa-copy"></i> Session evaluations</Link></li>
                                    </ul>


                                    

                                        { 
                                            this.state.sessionBannerList.map(banner => (
                                                <React.Fragment>
                                                
                                                    <div key={banner.logo}>
                                                    {
                                                        banner.banner_link !== null
                                                        ?
                                                        (
                                                        <div className="sponsersCatBxX">
                                                            <a href={banner.banner_link} target="_blank">
                                                                <img src={banner.logo} alt="" className="" />
                                                            </a>
                                                        </div>
                                                        )
                                                        :
                                                        null
                                                        }
                                                    </div>
                                                    
                                                </React.Fragment>
                                            ))
                                            
                                        }
                                        <div className="buttonStick mt-3">
                                            <Link to="/paos/attendee/technicalSessions">
                                                <span>View All Sessions</span>
                                                <i className="fa fa-eye"></i>
                                            </Link>
                                        </div>
                                                
                 
                                </div>
                            </div>
                        </div>  
                        {
                            this.state.infoBoxModal
                            ?
                            <div className="midModal">
                                <div className="midModalArea width800">
                                    <div className="modalPopUpClose" onClick={this.infoBoxModalHandlar}><i className="fa fa-times"></i></div>
                                    <h3 className="modalHeading">Viewing Live Sessions with Microsoft Teams </h3>
                                    <div className="modalContent p-4">
                                        <p>The first time you enter click on the link in the session screen and a new window will open. If you already have the Microsoft Teams app installed on your computer and are logged in choose “Launch it Now” and go right to the session.</p>
                                        <p>If you would like to view without installing the app choose watch on web, and join the meeting anonymously.</p>
                                        <p>If you would like to install the Teams app, you will need to log out of the virtual conference and install the app using the link on the log in page.</p>
                                    </div>
                                </div>
                            </div>
                            :
                            null
                        }
                        

                        
                        <BottomNav />

                    </section>
                    {/*=====About Session Modal=====*/}
                    {
                        this.state.aboutSessionModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSessionModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Session</h3>
                                <div className="midModalAreaContent">
                                    <AboutSession passprops= {this.props}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====About Speaker Modal=====*/}
                    {
                        this.state.aboutSpeakerModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSpeakerModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Speaker</h3>
                                <div className="midModalAreaContent">
                                    <MainSessionAllDetails sessionId={params.sessionId}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Documents Modal=====*/}
                    {
                        this.state.sessionDocumentsModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.sessionDocumentsModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">Session Documents</h3>
                                <div className="midModalAreaContent">
                                    <SessionDocuments passprops= {this.props} />
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Survey Modal=====*/}
                    {
                        this.state.sessionSurveyModal
                        ?
                        <div className="midModal verticleCenterModal">
                            <div className="midContentWrp">
                                <div className="modalPopUpClose" onClick={this.sessionSurveyModalHandler}><i className="fa fa-times"></i></div>
                                <h3>Session Survey</h3>
                                <div className="midContentBx">
                                {
                            this.state.iframeLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                                    <iframe width="100%" height="450" src={`https://www.tslwebreg.com/sessionSurvey.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&role=${localStorage.getItem('currentRole')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                                </div>                               
                            </div>
                        </div>
                        :
                        null
                    }
                    
                </React.Fragment>
            )
        
        
         
    }
}
export default ConferenceCenterPage;
