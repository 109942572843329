import React, { Component } from 'react';
import BottomNav from './BottomNav';
import SessionChatRoom from "../../../TmChat/csda/sessionChatRoom/sessionChatRoom";
import Axios from 'axios';
import AboutPresenter from './AboutPresenter';
import PosterDocuments from './PosterDocuments'


export default class PosterAttendeeView extends Component {
    constructor(props){
        super(props);
        this.state = {
            pageLoader:true,
            posterTitle:'',
            videoUrl:'',
            bio:'',
            bannerImg:'',
            bannerLink:'',
            bioText:'',
            aboutPosterModal:false,
            aboutAuthorModal:false,
            posterDocumentsModal:false,
            somethingWrong:false,
            zoomPosterModal:false,
        }
    }

    componentDidMount(){
     localStorage.setItem('conferenceClicked',0);
     const {match : {params}} = this.props;
     this.getPosterDetails(params.sessionId);
     this.updateGoldenTicketStatus(this.props);
    }
    zoomPosterModalHandler = (e) => {
        this.setState({zoomPosterModal:!this.state.zoomPosterModal})
    }
    aboutPosterModalHandler = (e) => {
        localStorage.setItem('conferenceClicked',1)
        this.setState({aboutPosterModal:!this.state.aboutPosterModal})
    }
    aboutAuthorModalHandler = (e) => {
        localStorage.setItem('conferenceClicked',1)
        this.setState({aboutAuthorModal:!this.state.aboutAuthorModal})
    }
    posterDocumentsModalHandler = (e) => {
        localStorage.setItem('conferenceClicked',1)
        this.setState({posterDocumentsModal:!this.state.posterDocumentsModal})
    }
    getPosterDetails = (sessionId) =>{
        Axios.get(`${global.config.requestUrl}user/getPosterDetails?eventId=${localStorage.getItem('eventId')}&sessionId=${sessionId}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
        
       
         .then(res => {
            
             if(res.data.errorCode === 0){
                this.setState({posterTitle:res.data.data[0].session_name,bannerImg:res.data.data[0].banner,bannerLink:res.data.data[0].banner_link,bioText:res.data.data[0].bio,videoUrl:res.data.data[0].video_url})
                this.setState({ pageLoader:false})
             } else{
                 this.setState({pageLoader:false,somethingWrong:true})
             }
             
         })
         .catch(err => {
             console.log(err);
         });
    }

    updateGoldenTicketStatus = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        const ticketStatusData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:0,
            linkPage:'sessionRoom',
            sessionId:params.sessionId,
            exhibitorId:0
            
        }
        Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(res.data.errorCode){
            document.getElementById('goldenTkt').classList.add("greyScale");
            this.setState({pageLoader:false});
        }else{
            this.setState({pageLoader:false});
            document.getElementById('goldenTkt').classList.remove("greyScale");
        }
    })
    .catch(err => {
        console.log(err);
    });
}

    render() {
        const {match : {params}} = this.props;
        return (
            <React.Fragment>
                <section className="bg1 signleScreen paddingTop height1200">
                    {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                    <div className="centerLogo">
                        <span>{this.state.posterTitle}</span>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-7 col-12">
                                <div className="firstColBx">
                                    
                                    <SessionChatRoom sessionId={params.sessionId} />
                                    <div className="usefulLinks">
                                        <ul>
                                            <li onClick={this.aboutPosterModalHandler}>
                                                <i className="fa fa-info"></i>
                                                <span>About the Poster</span>
                                            </li>
                                            <li onClick={this.aboutAuthorModalHandler}>
                                                <i className="fa fa-edit"></i>
                                                <span>About the Author</span>
                                            </li>
                                            <li onClick={this.posterDocumentsModalHandler}>
                                                <i className="fa fa-copy"></i>
                                                <span>Other Documents</span>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="col-md-5 col-12">
                                <div className="headingWithMedia">
                                    <h3>Poster</h3>
                                    {
                                         this.state.bannerLink === ''
                                        ?
                                        (
                                            <iframe src={this.state.bannerImg} title="description"></iframe>
                                           
                                        )
                                        :
                                        <iframe src={this.state.bannerImg} height="200" width="300" title="description"></iframe>
                                        // <a href={this.state.bannerLink} target="_blank"><img src={this.state.bannerImg} alt="" /></a>
                                    }
                                    {<a href={this.state.bannerImg} className="zoomBtn" target="_blank">
                                        View full <i className="fa fa-search-plus"></i>
                                    </a>}
                                </div>
                                <div className="video">

                                    {
                                                    this.state.videoUrl === null || this.state.videoUrl === ""
                                                    ?
                                                    <div className="conferenceVideo videoNotFound">
                                                        <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" />
                                                    </div>
                                                    :
                                                    <div className="conferenceVideo" id="videoconf">
                                                        <iframe width="100%" height="250" src={this.state.videoUrl} frameborder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                   </div>
                                                }
                                        
                                    </div>
                            </div>
                        </div>
                    </div>
                    <BottomNav />
                    {/*=====Zoom Poster Modal=====*/}
                    {
                        this.state.zoomPosterModal
                        ?
                        <div className="midModal fullWidth">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" onClick={this.zoomPosterModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">{this.state.posterTitle}</h3>
                                <div className="midModalAreaContent">
                                <img src={this.state.bannerImg} alt={this.state.bannerImg} />
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====About Poster Modal=====*/}
                    {
                        this.state.aboutPosterModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" onClick={this.aboutPosterModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About the Poster</h3>
                                <div className="midModalAreaContent">
                                <p>{this.state.bioText}</p>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    
                    {/*=====About Author Modal=====*/}
                    {
                        this.state.aboutAuthorModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" onClick={this.aboutAuthorModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About the Author</h3>
                                <div className="midModalAreaContent">
                                    <AboutPresenter passprops= {this.props}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Poster Documents Modal=====*/}
                    {
                        this.state.posterDocumentsModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" onClick={this.posterDocumentsModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">Poster Documents</h3>
                                <div className="midModalAreaContent">
                                    <PosterDocuments passprops= {this.props}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                        this.state.somethingWrong
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="midModalAreaContent">
                                    Something Wrong!!!!
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }     
                </section>
            </React.Fragment>
        )
    }
}
