import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import Axios from 'axios';
import jwt_decode from 'jwt-decode';

/* import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import introJs from 'intro.js'; */
// import 'intro.js/introjs.css';


class LeftNavBar extends Component {
    constructor(props){
        super(props);
        //console.log(this.state.stepsEnabled);
        this.state={
            changePasswordModal: false,
            userEmail:"",
            currentPassword:"",
            currentPasswordError:"",
            newPassword:"",
            newPasswordError:"",
            confirmPassword:"",
            confirmPasswordError:"",
            passwordSuccessChanged:"",
            passwordMismatch:"",
            /* stepsEnabled: true,
            initialStep: 0,
            steps: [
                {
                    element: ".dashboard",
                    intro: "Access the full suite of features from the Exhibitor Dashboard. This is the central location to view your visitor traffic and leads reports. View who is visiting your booth in real- time, and interact with them in your dedicated live chat window."
                },
                {
                    element: ".companyInfo",
                    intro: "Confirm and/or edit the primary contact fields for your company. Assign specific categories related to your business that make it easier for attendees to find you."
                },
                {
                    element: ".boothSetup",
                    intro: "Establish your company’s exhibition presence in Booth Setup. Add documents, videos, and a description of your company. This is also where you will register your booth staff, and setup the lead generation tool."
                },
                {
                    element: ".chngePass",
                    intro: "Reset your current individual password here."
                }
            ] */
        }
    }
    changePasswordModalHandler = (e) => {
        e.preventDefault();
        this.setState({changePasswordModal:!this.state.changePasswordModal})
    }
    slideBarToggle= () =>{
        const element = document.querySelector(".leftNavBar");
        element.classList.toggle("navBarShrink");
    }
    /* onchange function */
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }
    /* form validation function */
    validateForm = () => {
        let currentPasswordError = "";
        let newPasswordError = "";
        let confirmPasswordError = "";

        if(!this.state.currentPassword){
            currentPasswordError = "Invalid currentPassword"
        }
        if(!this.state.newPassword){
            newPasswordError = "Invalid newPasswordError"
        }
        if(!this.state.confirmPassword){
            confirmPasswordError = "Invalid confirmPasswordError"
        }
        if((this.state.confirmPassword) !== (this.state.newPassword)){
            confirmPasswordError = "New and Confirm Password should be same"
        }
        if(currentPasswordError || newPasswordError || confirmPasswordError){
            this.setState({currentPasswordError : currentPasswordError, newPasswordError : newPasswordError, confirmPasswordError : confirmPasswordError });
            return false;
        }
        
        return true;
    }

    
    
    
    /* form submit function */
    changeSubmit = (e) => {
        e.preventDefault();
        
        const validForm = this.validateForm();
        if(validForm){
        console.log(this.state);
        this.setState(
                {
                    currentPassword:"",
                    currentPasswordError:"",
                    newPassword:"",
                    newPasswordError:"",
                    confirmPassword:"",
                    confirmPasswordError:"",
                }
            );
        }
        
        const formDetails = {
            eventId:localStorage.getItem('eventId'),
            email : this.state.userEmail,
            oldPass : this.state.currentPassword,
            newPass : this.state.newPassword,
            confirmPass : this.state.confirmPassword,
        }
    
        if(validForm){
            Axios.post(`${global.config.requestUrl}exhibitor/changePassword`, formDetails,
                { headers: { "auth-token": localStorage.getItem('exhibitorToken') } }
            )
            .then(res => {
              
                if(res.data.errorCode !== 0){
                    this.setState({passwordMismatch : res.data.errorMessage, passwordSuccessChanged : ""})
                 }
                if(res.data.errorCode === 0){
                   this.setState({passwordSuccessChanged : "Password Updated Successfully", passwordMismatch: "", changePasswordModal: false })
                }
                console.log(res.data);
                /* return loginRes.data; */
            })
            .catch(err => {
                console.log(err);
            });
            console.log(formDetails)
        }
    }
    componentDidMount(){
        //introJs().start();
        const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            console.log(decoded);
            this.setState({
                userEmail : decoded.email,
            })

      }

    render() {
        // const {
        //     stepsEnabled,
        //     steps,
        //     initialStep
        //   } = this.state;
        return (
            <React.Fragment>
                
                {/* <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={initialStep}
                    onExit={this.onExit}
                /> */}
                <div className="leftNavBar">
                    <div className="leftNavToggleBtn">
                        <i className="fa fa-long-arrow-left" onClick={this.slideBarToggle}></i>
                        <i className="fa fa-long-arrow-right" onClick={this.slideBarToggle}></i>
                    </div>
                    <div className="profileInfo">
                        <img src={ localStorage.getItem('companyContactUrl') || process.env.PUBLIC_URL + '/images/placeholder-profile.jpg'} alt="" id="left_bar_profile_img" />
                        <p id="useraccname">{ localStorage.getItem('useraccname')}</p>
                        <p className="profileEmail" title={this.state.userEmail}>{this.state.userEmail}</p>
                    </div>
                    <div className="leftNavList">
                        <ul>
                            <li>
                                <Link to="/hfam/exhibitor/dashboard" className="dashboard">
                                    <span className="navImg">
                                        <img src={process.env.PUBLIC_URL + '/images/dashboard-home.png'} alt="" />
                                    </span>
                                    <span className="navLinkCnt">Main Dashboard</span>
                                </Link>
                                <div className="hoverTooltip">
                                    <p>Access the full suite of features from the Exhibitor Dashboard. This is the central location to view your visitor traffic and leads reports. View who is visiting your booth in real- time, and interact with them in your dedicated live chat window.</p>
                                </div>
                            </li>
                            <li>
                                <Link to="/hfam/exhibitor/profile" className = "companyInfo">
                                    <span className="navImg">
                                        <img src={process.env.PUBLIC_URL + '/images/company-info.png'} alt="" />
                                    </span>
                                    <span className="navLinkCnt">Company Information</span>
                                </Link>
                                <div className="hoverTooltip">
                                    <p>Confirm and/or edit the primary contact fields for your company. Assign specific categories related to your business that make it easier for attendees to find you.</p>
                                </div>
                            </li>
                            <li>
                                <Link to="/hfam/exhibitor/booth" className="boothSetup">
                                    <span className="navImg">
                                        <img src={process.env.PUBLIC_URL + '/images/boot-setup.png'} alt="" />
                                    </span>
                                    <span className="navLinkCnt">Booth Setup</span>
                                </Link>
                                <div className="hoverTooltip">
                                    <p>Establish your company’s exhibition presence in Booth Setup. Add documents, videos, and a description of your company. This is also where you will register your booth staff, and setup the lead generation tool.</p>
                                </div>
                            </li>
                            {
                            localStorage.getItem('exhibitorId') 
                            ?
                            null
                            :
                            <li>
                                <Link to="/" onClick={this.changePasswordModalHandler} className="chngePass">
                                    <span className="navImg">
                                        <img src={process.env.PUBLIC_URL + '/images/change-pass.png'} alt="" />
                                    </span>
                                    <span className="navLinkCnt">Change Password</span>
                                </Link>
                                <div className="hoverTooltip">
                                    <p>Reset your current individual password here.</p>
                                </div>
                            </li>
                            }
                            
                        </ul>
                    </div>
                </div>
                {
                    this.state.changePasswordModal 
                    ?
                    <div className="modalPopUp">
                        <div className="modalPopUpInr">
                            <div className="modalPopUpClose" onClick={this.changePasswordModalHandler}><i className="fa fa-times"></i></div>
                            <div className="modalPopUpFormBx">
                            <h3 className="mt-3 mb-3">Change Password</h3>
                            <div className="success">{this.state.passwordSuccessChanged}</div>
                            <div className="error">{this.state.passwordMismatch}</div>
                            <form onSubmit={this.changeSubmit} noValidate>
                                <div className="form-group mb-3">
                                    <input 
                                        type="password" 
                                        name="currentPassword" 
                                        id="currentPasswordid" 
                                        placeholder="Current Password" 
                                        onChange={this.onChange} 
                                        value={this.state.currentPassword} 
                                        className="form-control"
                                    />
                                    <div className="errorMessage">{this.state.currentPasswordError}</div>
                                </div>
                                <div className="form-group mb-3">
                                    <input 
                                        type="password" 
                                        name="newPassword" 
                                        id="newPasswordid" 
                                        placeholder="New Password" 
                                        onChange={this.onChange} 
                                        value={this.state.newPassword} 
                                        className="form-control"
                                    />
                                    <div className="errorMessage">{this.state.newPasswordError}</div>
                                </div>
                                <div className="form-group mb-3">
                                    <input 
                                        type="password" 
                                        name="confirmPassword" 
                                        id="confirmPasswordid" 
                                        placeholder="Confirm Password" 
                                        onChange={this.onChange} 
                                        value={this.state.confirmPassword} 
                                        className="form-control"
                                    />
                                    <div className="errorMessage">{this.state.confirmPasswordError}</div>
                                </div>
                                <div className="form-group text-center mt-3">
                                    <button className="btn" type="submit">
                                        <span>Submit</span>
                                    </button>
                                </div>
                                
                            </form>
                                
                            </div>
                        </div>
                    </div> 
                    :
                    null
                }
            </React.Fragment>
        )
        
    }
    onExit = () => {
        this.setState(() => ({ stepsEnabled: false }));
      };
}
export default withRouter(LeftNavBar);