import React, { Component } from 'react'
import DatePicker from "react-datepicker";
import Axios from 'axios';
 
import "react-datepicker/dist/react-datepicker.css";

export default class AddEventToSchedule extends Component {
    constructor(){
        super();

        this.state={
            startDate:new Date(),
            starthours:'01',
            startmins:'00',
            endhours:'01',
            endmins:'00',
            meetingTitle:'',
            timeError:'',
            mettingTitleError:'',
            successMsg:'',
            pageLoader : false,
            startTimeShift:'am',
            endTimeShift:'am',
        }
    }
    dateChange = date => {
        this.setState({
          startDate: date, endDate:date
        });
      };

    onChangeSelection = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    validateForm(){
      var startHours = this.state.starthours;
      var endHours = this.state.endhours;
      var startMins = this.state.startmins;
      var endMins = this.state.endmins;
      var startTimeShift = this.state.startTimeShift;
      var endTimeShift = this.state.endTimeShift;
      if(startTimeShift === 'pm'){
        startHours = startHours + 12
        this.setState(prevState => {
            return {startHours: prevState.startHours + 12}
         })
      }
      if(endTimeShift === 'pm'){
        endHours = endHours + 12
        this.setState({endHours:endHours})
      }
      /* if(startTimeShift === 'pm' && endTimeShift === 'am'){
        this.setState({timeError:'Wrong Time selected'});
      }else{
        this.setState({timeError:''})
     } */
      var startsInMinuts = startHours*60 + parseInt(startMins);
      var endInMinuts = endHours*60 + parseInt(endMins);
      var flag = 0;
      
      console.log(startsInMinuts, endInMinuts)
      
      if(startsInMinuts > endInMinuts || startsInMinuts === endInMinuts  ){
         this.setState({timeError:'End Time should be greater then start time!!'});
         flag = 1;
      }else{
         this.setState({timeError:''})
      }
      if(this.state.meetingTitle === ''){
        this.setState({mettingTitleError:'Title Needed!!'});
        flag = 1;
      }else{
        this.setState({mettingTitleError:''});
      }

      if(flag){
         return false;
      }else{
         return true;
      }

      
    }
    
    submitSchedule = () => {
        if(this.validateForm()){
            this.setState({pageLoader : true})
            const newSchedule = {
                sessionId:0,
                eventId:localStorage.getItem('eventId'),
                memberId:localStorage.getItem('userId'),
                role:localStorage.getItem('currentRole'),
                sName: this.state.meetingTitle,
                startDate: this.state.startDate,
                startTime:this.state.starthours+':'+this.state.startmins,
                endTime:this.state.endhours+':'+this.state.endmins,
                isSession:0,
                startFormat:this.state.startTimeShift,
                endFormat:this.state.endTimeShift
            }
            /* console.log(newSchedule);
            return false */
            Axios.post(`${global.config.requestUrl}user/addEventToSchedule`, newSchedule,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                    this.setState({meetingTitle:'',pageLoader : false,successMsg:'Schedule Added!!!'})
                    setTimeout(() => {document.getElementById('addingSchedulePop').click()}, 800);
                })
                .catch(err => {
                    console.log(err);
                    this.setState({pageLoader : false})
                });
        }
    }

    render() {

    var hourOptions = [];
    for (var i=1; i<12; i++) {
        if(i<10){
            hourOptions.push(<option value={`0${i}`}>{i}:00</option>);
        } else{
            hourOptions.push(<option value={i}>{i}:00</option>);
        }
        
    }
    hourOptions.push(<option value="00">00:00</option>);
    var minOptions = [];
    minOptions.push(<option value="00">00:00</option>);
    minOptions.push(<option value="05">00:05</option>);
    for (i=10; i<60; i++) {
        minOptions.push(<option value={i}>00:{i}</option>);
        i = i+4;
    }
    
        return (
            <React.Fragment>
                {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                <div className="midPopupForm col-md-10 offset-md-1 pt-3 pb-5 min-height-450">
                    <div className="error">{this.state.timeError}</div>
                    <div className="success">{this.state.successMsg}</div>
                    <div className="form-group">
                        <label>Meeting Title</label>
                        <input type="text" name="meetingTitle" onChange={this.onChange} value={this.state.meetingTitle}   className="form-control" />
                        <div className="error">{this.state.mettingTitleError}</div>
                    </div>
                    <div className="dateTimePair">
                        <div className="form-group">
                            <div className="dateSch">
                                <label>Start Date</label>
                                <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.dateChange}
                                    dateFormat="MM/dd/yyyy"
                                    className="form-control"
                                    minDate={new Date()}
                                    showDisabledMonthNavigation
                                />
                            </div>
                            <div className="d-flex">
                                <div className="timeSch">
                                    <label>Start Time</label>
                                    <select onChange={this.onChangeSelection} name="starthours" className="form-control">
                                        {hourOptions}
                                    </select>
                                    <select onChange={this.onChangeSelection} name ="startmins" className="form-control ml-3 mr-3">
                                        {minOptions}
                                    </select>
                                    <select onChange={this.onChangeSelection} name ="startTimeShift" className="form-control">
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="timeSch">
                                    <label>End Time</label>
                                    <select onChange={this.onChangeSelection} name="endhours" className="form-control">
                                    {hourOptions}
                                    </select>
                                    <select onChange={this.onChangeSelection} name="endmins" className="form-control ml-3 mr-3">
                                    {minOptions}
                                    </select>
                                    <select onChange={this.onChangeSelection} name ="endTimeShift" className="form-control">
                                        <option value="am">AM</option>
                                        <option value="pm">PM</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-4">
                        <button onClick = {this.submitSchedule} className="btn btn-warning">Submit</button>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
