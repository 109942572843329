import React, { Component } from 'react'
import {Link} from "react-router-dom";
import { Accordion, Card, Button  } from 'react-bootstrap';
import Axios from 'axios';
import MySchedule from './MySchedule'
//import ChatIcon from '../../../TmChat/fna/chat/ChatIcon'

class BottomNav extends Component {
    constructor(){
        super();
        this.state={
            goldenTicketSuccessModal:false,
            goldenTicketErrorModal:false,
            helpDeskModal:false,
            helpDeskContents:[],
            ticketOnExhibit:0,
            ticketValueExhibit:0,
            ticketOnConference:0,
            ticketValueConference:0,
            ticketOnNetworking:0,
            ticketValueNetworking:0,
            showGoldenTicket:false,
            myScheduleModal:false,
            ticketError:'',
            pageLoader:false,
            chatHide:true
        }
    }
    myScheduleModalHandler = (e) => {
        e.preventDefault();
        this.setState({myScheduleModal:!this.state.myScheduleModal})
    }
    goldenTicketModalClose = (e) =>{
        e.preventDefault();  
        this.setState({goldenTicketErrorModal:false,goldenTicketSuccessModal:false})
    }
    goldenTicketModalHandler = (e) =>{
        e.preventDefault();

        var CurrLocation = window.location.href;
        var linkpage = '';
        var ticketValue = 0;
        var sessionId = 0;
        var exhibitorId = 0;
        
        if (document.getElementById('goldenTkt').classList.contains('greyScale')) {
            this.setState({goldenTicketErrorModal:true,ticketError:'Your have already claimed the ticket points!!!!'})
                 return false;
        }
        var pathArray = window.location.pathname.split('/');
        if(CurrLocation.includes('/attendee/networkingLounge')){
            linkpage = 'networking';
            ticketValue = this.state.ticketValueNetworking;
        }else if(CurrLocation.includes('/attendee/conferenceCenterDetails')){
            sessionId = pathArray[4];
            linkpage = 'sessionRoom';
            ticketValue = this.state.ticketValueConference;
            if(localStorage.getItem('conferenceClicked') === 0){
                this.setState({goldenTicketErrorModal:true,ticketError:'You must engage in the technical session before claiming the Golden Ticket.'})
                 return false;
            }
        }else{
            
            exhibitorId = pathArray[4];
            linkpage = 'exhibitBooth';
            ticketValue = this.state.ticketValueExhibit;
            if(localStorage.getItem('exhibitBoothClicked') === 0){
                this.setState({goldenTicketErrorModal:true,ticketError:'You must engage in the booth before claiming the Golden Ticket.'})
                 return false;
            }
            
        }
        this.setState({pageLoader:true});

        const ticketInsertData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:ticketValue,
            linkPage:linkpage,
            sessionId:sessionId,
            exhibitorId:exhibitorId
        }
        Axios.post(`${global.config.requestUrl}user/insertGoldenTicketUsers`, ticketInsertData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        ).then(res => {
            //console.log(res.data.errorCode);
            if(res.data.errorCode){
                this.setState({goldenTicketErrorModal:true,ticketError:'Your have already claimed the ticket points!!!!'})
            }else{
                document.getElementById('goldenTkt').classList.add("greyScale");
                this.setState({goldenTicketSuccessModal:true})
            }
            this.setState({pageLoader:false});
            
        })
        .catch(err => {
            console.log(err);
        });


        
        
    }
    helpDeskModalHandler = (e) =>{
        e.preventDefault();
        this.setState({helpDeskModal:!this.state.helpDeskModal})
    }
    componentDidMount(){

        if(global.config.showBottomNav === 1 ){
            this.getHelpDeskData();
            this.getGoldenTicketData();
        }
        let pathArray = window.location.pathname.split('/');
        if(pathArray[3] === 'exhibitorBooth'){
            this.setState({chatHide:false})
        }
    }

    CheckGoldenTicketPageValidity= () => {
        var CurrLocation = window.location.href;
        if((CurrLocation.includes('/attendee/networkingLounge') || CurrLocation.includes('/attendee/conferenceCenterDetails') || CurrLocation.includes('/attendee/exhibitorBooth')) && localStorage.getItem('currentRole') === '-10' ){
            return true;
        }else{
            return false;
        }
    }
    
    getGoldenTicketData = () => {
     
        if(this.CheckGoldenTicketPageValidity()){
                Axios.get(`${global.config.requestUrl}user/getGoldenTicketData?eventId=${localStorage.getItem('eventId')}`,
            {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            .then(res => {
                this.setState({
                    ticketOnExhibit:res.data.data[0].ticketOnExhibit,
                    ticketOnConference:res.data.data[0].ticketOnConference,
                    ticketOnNetworking:res.data.data[0].ticketOnNetworking,
                    ticketValueConference:res.data.data[0].ticketValueConference,
                    ticketValueExhibit:res.data.data[0].ticketValueExhibit,
                    ticketValueNetworking:res.data.data[0].ticketValueNetworking,
                })

                /** Here we check to show golden ticket or not */
                var CurrLocation = window.location.href;
                if((CurrLocation.includes('/attendee/networkingLounge') && res.data.data[0].ticketOnNetworking === 1) || (CurrLocation.includes('/attendee/conferenceCenterDetails') && res.data.data[0].ticketOnConference === 1) || (CurrLocation.includes('/attendee/exhibitorBooth') && res.data.data[0].ticketOnExhibit === 1)){
                  this.setState({showGoldenTicket:true})
                }

               

            })
            .catch(err => {
                console.log(err);
            });
        }
         
    }

    getHelpDeskData = () => {
        Axios.get(`${global.config.requestUrl}user/getHelpDeskContent?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(!res.data.errorCode)
               this.setState({helpDeskContents:res.data.data})
        })
        .catch(err => {
            console.log(err);
        });
    }
    render() {

        if(global.config.showBottomNav === 0 ){
            return (
                <div></div>
            )
        }
       

        return (
            <React.Fragment>
                <div className="stickyBottomMenu">
                    <div className="responsiveBtn">
                    <div className="goldenTkt" id="goldenTkt">
                        {
                            this.state.showGoldenTicket
                            ?
                            
                            <a href="#" onClick={this.goldenTicketModalHandler}>
                                <img src={process.env.PUBLIC_URL + '/images/golden-ticket.png'} alt="" />
                            </a>
                           
                           :
                           null

                        }
                    </div>
                        
                        {
                            /* this.state.chatHide
                            ?
                            <div className="chatIco">
                                <ChatIcon chatIconImage={process.env.PUBLIC_URL + '/images/chat-ico2.png'} />
                            </div>
                            :
                            null */

                        }
                        
                        <div className="helpIco" onClick={this.helpDeskModalHandler}>
                            <img src={process.env.PUBLIC_URL + '/images/help-desk.png'} alt="" />
                        </div>
                    </div>
                    <div className="bottomNav">
                        <ul>
                            <li>
                                <Link to="/fna/attendee/lobby">
                                    <img src={process.env.PUBLIC_URL + '/images/bottom-nav-lobby.png'} alt="" />
                                    <span>Lobby</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/fna/attendee/exhibitHall">
                                    <img src={process.env.PUBLIC_URL + '/images/bottom-nav-exhibit-hall.png'} alt="" />
                                    <span>Exhibit Hall</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/fna/attendee/conferenceCenter">
                                    <img src={process.env.PUBLIC_URL + '/images/bottom-nav-conference-hall.png'} alt="" />
                                    <span>Conference Center</span>
                                </Link>
                            </li>
                            <li>
                                <Link to="/fna/attendee/networkingLounge">
                                    <img src={process.env.PUBLIC_URL + '/images/bottom-nav-networking-lounge.png'} alt="" />
                                    <span>Networking lounge</span>
                                </Link>
                            </li>
                            <li>
                                <Link onClick={this.myScheduleModalHandler}>
                                    <img src={process.env.PUBLIC_URL + '/images/schedule_button.png'} alt="" />
                                    <span>My Schedule</span>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {
                    this.state.helpDeskModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                            <div class="modalPopUpClose" onClick={this.helpDeskModalHandler}><i class="fa fa-times"></i></div>
                            <h3>Help Desk</h3>
                            <div className="midContentBx">
                                <Accordion defaultActiveKey="0">
                                    { 
                                        this.state.helpDeskContents.map(content => (
                                            <Card key={content.help_desk_id}>
                                                <Card.Header>
                                                    <Accordion.Toggle as={Button} variant="link" eventKey={content.help_desk_id}>
                                                        {content.title}
                                                    </Accordion.Toggle>
                                                </Card.Header>
                                                <Accordion.Collapse eventKey={content.help_desk_id}>
                                                    <Card.Body>

                                                    {content.description.split('\n').map(function(item, key) {
                                          return (
                                                   <span key={key}>
                                                   {item}
                                                   <br/>
                                                   </span>
                                                 )
                                           })}
                                                        
                                                       
                                                        
                                                    </Card.Body>
                                                </Accordion.Collapse>
                                            </Card>
                                        ))    
                                    }
                                    
                                </Accordion>
                            </div>                                
                        </div>
                    </div>
                    :
                    null
                }

                {
                    this.state.myScheduleModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" id="myschedulepopup" onClick={this.myScheduleModalHandler}><i className="fa fa-times"></i></div>
                            <MySchedule props={this.props} />                             
                        </div>
                    </div>
                    :
                    null
                }
                {/*========warning Modal=========*/}
                {
                    this.state.goldenTicketErrorModal
                    ?
                    <div className="midModal warningModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" onClick={this.goldenTicketModalClose}>
                                <i className="fa fa-times"></i>
                            </div>
                            <h3>Golden Ticket</h3>
                            <div className="midContentBx">
                                <p className="text-center"><i class="fa fa-exclamation-triangle mr-2"></i>{this.state.ticketError} </p>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {/*========success Modal=========*/}
                {
                     this.state.goldenTicketSuccessModal
                    ?
                    <div className="midModal successModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" onClick={this.goldenTicketModalClose}>
                                <i className="fa fa-times"></i>
                            </div>
                            <h3>Golden Ticket</h3>
                            <div className="midContentBx">
                                <p className="text-center"><i class="fa fa-check-circle mr-2"></i>You have successfully claimed the golden ticket points!!!</p>
                            </div>
                        </div>
                    </div>
                    :
                    null 
                }

                {
                             this.state.pageLoader
                             ?
                             (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                            )
                            :
                            null
                           }
               
            </React.Fragment>
        )
    }
}
export default BottomNav;