import React, {Component} from 'react'
import '../TmStyle.css'
import TroopMessenger from 'troop-messenger-chat-sdk';
import tmChatServer from '../utility/server';
import troopServer from '../utility/troopServer';
import utility from "../utility/utility";

let troopMessenger;
let tmLoginUserDetails;
let loginUserDetails;
let chatConversations=[];
let recentUsersWithRoles = {};
let activeUserId = '';

function checkNotificationPermission() {
    if (!("Notification" in window)) {
        /*alert("This browser does not support desktop notification");*/
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
        });
    }
}

class ChatPage extends Component {

    state = {
        usersList: [],
        userDetails: {},
        chatUser: {},
        isUsersLoading:true,
        chatConversationsLoading:false,
        chatConversations: [],
        files: [],
        eventId:'',
        groupList:[],
        userInfo:{},
        activeGroup:false,
        activeRole: '111',
        activeUserSearch:false,
        onlineUsers:[],
        groupInfo:{},
        notifications : []
    };
    onUnload = e => { 

    }
    constructor() {
        super();
        this.state.eventId=localStorage.getItem('eventId')
    }
    componentWillUnmount(){

    }

    displayNotification(message) {
        if(Notification.permission != "granted") return;
        let notification = new Notification(message.title, {
            body: message.message,
            icon: process.env.PUBLIC_URL + '/images/tmChat/logo.png',
            vibrate: [100],
            timeout: 300,
            data: {entity_id: message.sender_id},
            silent: false,
            sound: true
        });
        notification.onclick = function (event) {
            event.preventDefault();
            window.focus();
            notification.close();
            if(window.location.href.search("/mdapa2024/chat") < 0){
                window.location.href = "/mdapa2024/chat";
            }
        };
        this.state.notifications.push(notification);
    }

    clearNotifications = () => {
        this.state.notifications.map(_d => {
            try{
                _d.close();
            }catch(e){

            }
        });
    }

    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                
            })
            .on("connected", async () => {
                troopMessenger.getAllUnreadMessagesCount({
                    conversation_reference_id: this.state.eventId
                });
            })
            .on("all_unread_messages_count",function(data){
                localStorage.setItem("unread_messages_count",data.unread_messages_count);
                if(data.unread_messages_count > 0){
                    window.dispatchEvent( new Event('all_unread_count_set') );
                }else{
                    window.dispatchEvent( new Event('all_unread_count_clear') );
                }
            })
            .on("disconnected", function () {
            })
            .on("receive_message", async (data) => {
                if ('' + this.state.eventId === '' + data.conversation_reference_id) {
                    if (data.is_group === 0) {
                        let userId = utility.getUserId(data.sender_uid);
                        if(data.sender_id == localStorage.getItem("_a_uid")) return;

                        let count = parseInt(localStorage.getItem("unread_messages_count")) || 0;
                        count++;
                        localStorage.setItem("unread_messages_count",count);
                        window.dispatchEvent( new Event('all_unread_count_set') );

                        let userDetails;
                             let users = utility.getUserRole(data.sender_uid);
                                let userIndex = this.state.usersList.findIndex((user) => {
                                    data.userId = utility.getUserId(data.sender_uid);
                                    if (parseInt(data.userId) === parseInt(user.userId)) {
                                        return user;
                                    }
                                });
                                if (userIndex === -1) {
                                    let uid = utility.getUserId(data.sender_uid);
                                    const parameters = {
                                        'ids': uid,
                                        'role': users,
                                    };

                                    let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                                    usersList = usersList.data;
                                    if (usersList && usersList.length) {
                                        userDetails = usersList[0];
                                        usersList = Object.assign(usersList[0], data);
                                        this.state.usersList = [usersList].concat(this.state.usersList);
                                    }
                                } else {
                                    userDetails = this.state.usersList[userIndex];
                                    userDetails = Object.assign(userDetails, data);
                                    this.state.usersList.splice(userIndex, 1);
                                    this.state.usersList = [userDetails].concat(this.state.usersList);
                                }
                                
                            if (recentUsersWithRoles[this.state.activeRole] !== undefined) {
                                this.setState({
                                    usersList: this.state.usersList
                                });
                            }
                            data.userId = parseInt(userId);
                            activeUserId = parseInt(activeUserId);
                            if (data.userId === activeUserId) {
                                chatConversations.push(data);
                                this.setState({'chatConversations': chatConversations})
                            }
                            if (userDetails) {
                                let notificationData = {
                                    'title': 'New Message From ' + userDetails.name,
                                    'message': data.message,
                                    'sender_id': data.sender_id
                                };
                                if (data.message_type === 1) {
                                    notificationData['title'] = userDetails.name + ' Sent Attachment';
                                    notificationData['message'] = 'Attachment';
                                }
                                this.displayNotification(notificationData);
                            }

                    } 
                }
            })
    };

    async getUserDetails(uid) {
        let userIdRole = utility.getUserIdAndRole(uid);
        const parameters = {
            'ids': userIdRole['userId'],
            'role': userIdRole['role'],
        };
        let usersList = await troopServer.getUsersListBasedOnRole(parameters);
        usersList = usersList.data;
        if (usersList && usersList.length) {
            return usersList[0];
        }
    }

    async componentDidMount() {


        window.addEventListener("get_all_unread_count",function(){
            try{
                if(troopMessenger){
                    troopMessenger.getAllUnreadMessagesCount({
                        conversation_reference_id: this.state.eventId
                    });
                }
            }catch(e){

            }
        });

        checkNotificationPermission();
        const userDetailsdata = await troopServer.getLoginUserDetails();
        if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            loginUserDetails = userDetailsdata.data[0];
            this.setState({
                userDetails: loginUserDetails,
                isLoading: false
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
            loginUserDetails.uid = uid;
            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });

            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                this.connectTMChat();

            } catch (e) {
                
            }
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }

        window.addEventListener('clear_notifications', () => { 
            this.clearNotifications();
         });

    }

    render() {
        return "";
    }
}

export default ChatPage;
