import React, {Component} from "react";
import './sessionChatRoom.css'
import troopServer from "../utility/troopServer";
import utility from "../utility/utility";
import TroopMessenger from "troop-messenger-chat-sdk";
import tmChatServer from "../utility/server";
import tmChat from "../utility/tmChat";
import LounageChatMessages from "./loungeChatMessages";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import ReactDOM from "react-dom";
import fileUpload from "../utility/firebase";
let tmLoginUserDetails;
let troopMessenger;
let isScroll=false;
class SessionChatRoom  extends Component {
    constructor(props){
        super(props);
           ///live room
        this.state={chatConversationReferenceId:'session_chat_'+props.sessionId,chatConversations:[],'eventId':localStorage.getItem('eventId'),loadingChat:true,userInfo:{},message:'','files':[]}
           // testing room
      // this.state={chatConversations:[],chatConversationReferenceId:'lounge_chat_test_'+localStorage.getItem('eventId'),'eventId':localStorage.getItem('eventId'),loadingChat:true,'roomId':205,userInfo:{},message:'','files':[]}
    }
    async  componentDidMount() {
        if(!localStorage.getItem("currentRole")){
            this.props.history.push(`/ihca-als2021/loginSplash/`);
            return false;
        }

        let loginUserId=localStorage.getItem('userId');
        let currentRole=localStorage.getItem('currentRole');


        /*window.onbeforeunload =  (e) =>{
            window.onunload =  () =>{
                  this.exitRoom();
            };
            return 'Do you want to leave the network lounge?';
        };*/
      /*  window.addEventListener("onunload", () => {
            this.exitRoom();
            return 'Do you want to leave the network lounge?';

        });
        window.addEventListener('beforeunload',  (e)=> {
            this.exitRoom();
            e.preventDefault();
            e.returnValue = '';
        });*/
        const userDetailsdata = await troopServer.getLoginUserDetails();

            if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
               if(!userDetailsdata.data[0].role){
                   userDetailsdata.data[0].role='-10';
               }
            let loginUserDetails = userDetailsdata.data[0];
            this.setState({
                loginUserDetails: loginUserDetails
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
                console.log(uid,"uid++++");
            loginUserDetails.uid = uid;
            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });

            //cattleCallLoginUserDetails.user_id = getUserDetails.cattle_call_id;
            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                loginUserDetails.cattle_call_id = getUserDetails.cattle_call_id;
                this.setState({'loginUserDetails':loginUserDetails},()=>{
                    this.connectTMChat();
                });
            } catch (e) {
                console.log(e);
            }
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }

    }
    async  componentWillReceiveProps(next,prev){
        //console.log(next,prev,"check next")
    // this.setState({'chatConversationReferenceId':'session_chat_'+this.props.sessionId,chatConversations:[],'loadingChat':true},()=>{
         //this.createRoom(this.state.chatConversationReferenceId)
 
 //    })
     }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };
    updateInput = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }

        this.setState({message: e.target.value})
    }
    fileuploadHandler = async () => {
        const files = this.state.files;
        return await fileUpload(files);
    };
    handleSubmit =async ()=>{
        let filesPaths = [];
        this.setState({'submited': true});
        let message={};
        if (this.state.files.length) {
            filesPaths = await this.fileuploadHandler();
        }
        message.sender_uid = this.state.loginUserDetails.uid;
        message.event_id =this.state.chatConversationReferenceId;
        message.group_id = this.state.roomId;
        message.filesPaths = filesPaths;
        message.is_room = 1;
        message.message=this.state.message;
        console.log(message['receiver_uid'],message);
        if (message.filesPaths && message.filesPaths.length) {
            for (const filepath of message.filesPaths) {
                message.attachment = filepath;
                tmChat.sendAttachment(troopMessenger, message);
            }
        } else {
            tmChat.sendTextMessage(troopMessenger, message);
        }

        this.setState({'submited': false,'message':'',files:[]});
//Send state to the server code
    };
    isFeedAtTop=(e)=>{
        return 0 === this.messageFeed.scrollTop;
    }
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                console.log("message",message)
            })
            .on("connected", async () => {
                this.setState({troopMessengerConnection: troopMessenger,'troopConnected':true});
                
                this.createRoom(this.state.chatConversationReferenceId)
            }).on('message_sent',(data)=>{
                console.log(data,"dataa sent");
                  if(data.conversation_reference_id===this.state.chatConversationReferenceId) {
                      this.setState({'noMessages':false})

                      let userData = Object.assign(data, this.state.loginUserDetails);
                      let conversation = this.state.chatConversations.concat(userData);
                      this.setState({'chatConversations': conversation});
                      this.scrollToBottom();
                  }
        }).on('receive_message',async (data)=>{
            if(data.conversation_reference_id===this.state.chatConversationReferenceId) {
                this.setState({'noMessages':false});
                let userData = {};
                 if(data.sender_uid!=="") {
                     if (this.state.userInfo[data.sender_uid] !== undefined) {
                         let uid = utility.getUserId(data.sender_uid);
                         let role = utility.getUserRole(data.sender_uid);
                         const parameters = {
                             'ids': uid,
                             'role': role,
                         };
                         let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                         usersList = usersList.data;
                         if (usersList) {
                             this.state.userInfo[data.sender_uid] = usersList[0];
                             userData = Object.assign(usersList[0], data)

                         } else {
                             userData = Object.assign(data, {'name': 'anonymous user', 'profilePic': ''});
                         }
                         this.setState({'userInfo': this.state.userInfo});

                     } else {
                         userData = Object.assign(data, this.state.userInfo[data.sender_uid])
                     }
                     let conversation = this.state.chatConversations.concat(userData);
                     if (this.messageFeed) {
                         this.messageFeed.addEventListener("scroll", () => {
                             if (this.isFeedAtTop() && this.state.chatConversations && this.state.chatConversations.length) {
                                 if (!isScroll) {
                                     this.loadchatConversations();
                                     isScroll = true;
                                 }
                             }
                         });
                     }
                     this.setState({'chatConversations': conversation});

                 }else{
                     userData = Object.assign(data,{'name':data.member_name,'profilePic':""});
                     let conversation = this.state.chatConversations.concat(userData);
                     this.setState({'chatConversations': conversation});
                 }
                this.scrollToBottom();
            }
        }).on("group_created",  (data) =>{

        }).on("group_updated",  (data) =>{
        })
    };

    joinRoom=async(roomId)=>{
         await troopMessenger.room().join({
             room_id: roomId,
             conversation_reference_id: this.state.chatConversationReferenceId
         })
                 .then( (response)=> {
                     this.chatConversations();
                 console.log(response);

             })
                 .catch(function (e) {
                 console.log("e = ", e);
             });
    }
    exitRoom=()=>{
        troopMessenger.room().exit({
            room_id: this.state.roomId
        }).then(function(data){
            console.log("data:",data);
            return undefined
        }).catch(function(err){
            return undefined
        });
        return undefined
    }
    loadchatConversations=async ()=>{

        let parameters={
            'group_id': this.state.roomId,
            'is_room':1,
            'is_group':1,
            authorization_token: tmLoginUserDetails.authorization_token,
            'conversation_reference_id':  this.state.chatConversationReferenceId
        };
        parameters['last_message_id'] = this.state.chatConversations[0].message_id;

        let  chatConversations = await tmChat.getConversation(troopMessenger,parameters);
        if(chatConversations && chatConversations.length){
            let promises=[];
            console.log(chatConversations[0],"chatConversations");
            for(const chat of chatConversations){
                promises.push(this.assignUserInfo(chat));
            }
            Promise.all(promises).then((response)=>{

              let chat=  response.concat(this.state.chatConversations);
                console.log(chat,"chat  Count++++");
                this.setState({"chatConversations":chat});
                isScroll=false;
            });
        }
    };
    chatConversations=async ()=>{
        console.log('conversion');
       let  chatConversations = await tmChat.getConversation(troopMessenger, {
            'group_id': this.state.roomId,
           'is_room':1,
           'is_group':1,
            authorization_token: tmLoginUserDetails.authorization_token,
            'conversation_reference_id':  this.state.chatConversationReferenceId
        });
        if(chatConversations && chatConversations.length){
            let promises=[];
            for(const chat of chatConversations){
                promises.push(this.assignUserInfo(chat));
            };
            Promise.all(promises).then((response)=>{
                     console.log(response.length,"response OnlineUsersss");
                this.setState({"chatConversations":response,'loadingChat':false});
                if(this.messageFeed) {
                    this.messageFeed.addEventListener("scroll", () => {
                        if (this.isFeedAtTop() && this.state.chatConversations && this.state.chatConversations.length) {
                            if(!isScroll) {
                                this.loadchatConversations();
                                isScroll = true;
                            }
                        }
                    });
                }
                this.scrollToBottom();
            });
        }else{
            this.setState({'loadingChat':false,'noMessages':true})
        }
    };
    assignUserInfo=async (chat)=>{
        return new Promise(async (resolve)=>{
            if(chat.sender_uid && chat.sender_uid!==this.state.loginUserDetails.uid) {
                if (!this.state.userInfo[chat.sender_uid]) {
                    let uid = utility.getUserIdAndRole(chat.sender_uid);

                    const parameters = {
                        'ids': uid.userId,
                        'role': uid.role,
                    };
                    let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                      if(usersList.data) {
                          this.state.userInfo[chat.sender_uid] = usersList.data[0];
                          chat = Object.assign(chat, usersList.data[0]);
                      }else{
                          chat = Object.assign(chat, {'name':'anonymous user','profilePic':''});
                      }
                } else {
                    chat = Object.assign(chat, this.state.userInfo[chat.sender_uid]);
                }
            }else{
                chat = Object.assign(chat, this.state.loginUserDetails);
            }

            return resolve(chat);
        });
    }
     createRoom=async(eventId)=>{
         await troopMessenger.room().create({
             room_name: eventId,
                 room_description: '',
                     room_avatar:'',
                         conversation_reference_id: this.state.chatConversationReferenceId
                             })
                             .then( (response) =>{
                                 if(response.success && response.room_data){
                                     this.setState({'roomId':response.room_data.room_id},()=>{
                                        this.joinRoom(this.state.roomId);
                                     })
                                 }else if(response.success && response.code==1){
                                    this.setState({'roomId':response.room_id},()=>{
                                        this.joinRoom(this.state.roomId);
                                    })
 
                                 }
                                 console.log(response,"roomResponse");

                             })
                             .catch(function (e) {
                                 console.log("createRoom e = ", e);
                             });
    }
    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({block: "end", inline: "nearest"});
        }
    }
    onEmojiClick = (emojiObject) => {
        this.setState({'message':this.state.message+emojiObject.native})
    };
    openEmoji=()=>{
        this.setState({'emojiOpen':!this.state.emojiOpen});
        this.emojiPicker = React.createRef();

    }
    handleClick=(event) =>{
        try {
            let node = ReactDOM.findDOMNode(this.emojiPicker.current);
            console.log("comes hererr",node,node.contains(event.target));
            if (!node.contains(event.target) && !this.emjoiIcon.current.contains(event.target)) {
                // Handle outside click here
                this.openEmoji();
            }
        } catch(error) {
            return null
        }
    }
    uploadFile = (event) => {
        this.saveFiles(event.target.files);
        event.target.value = null;
    }
    saveFiles = (files) => {
        let stateFiles = this.state.files;
        stateFiles.push(files[0]);
        this.setState({'files': stateFiles})
    };
    removeFile = (e) => {
        let files = this.state.files;
        files.splice(e, 1);

        this.setState({'files': files})
    };
    uploadFileDiv(files) {
        if (files.length === 0) {
            return ('');
        }
        return (<div className="attachments">
            {files.map((file, index) => {
                return (<div className="uploaded-files" key={index}>
                    <div className="bg-white p-3 border-radius12">
                        <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/document.svg"} className="img-fluid"/>
                        <span className="d-block text-black mt-2 upload-file-name">{file.name}</span>
                        <div className="file_size mt-3">
                            {utility.formatBytes(file.size, 1)}
                        </div>
                        <button className="cursor-pointer w-auto h-auto" onClick={this.removeFile.bind(this, index)}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/close.svg"} className="img-fluid"/>
                        </button>
                    </div>
                </div>);
            })}

            <div className="add-more">
                <button className="w-100 h-100 m-0">
                    <input type='file' className='file-upload' onChange={this.uploadFile}/>
                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/add.svg"}/>
                </button>
            </div>
        </div>)
    }

    render() {
        return (<div className="peer-widget">
            <div className="widget-heading px-3 py-2">
                Session Chat
            </div>
            <div className="lounge-chat-widget p-3">
                <div className="widget-chatheading">
                    Chat with your peers here!
                </div>
                <div className="peer-detailsbox" ref={(el) => {
                    this.messageFeed = el;
                }}>
                    {this.state.loadingChat?'Loading..':<LounageChatMessages chatConversations={this.state.chatConversations} troopConnection={this.state.troopMessengerConnection} />}

                    <div className='mb-2'/>

                    <div style={{float: "left", clear: "both"}}
                         ref={(el) => {
                             this.messagesEnd = el;
                         }}>
                    </div>
                </div>
                {this.uploadFileDiv(this.state.files)}
                {this.state.emojiOpen?<Picker ref={this.emojiPicker} onSelect={this.onEmojiClick} style={{ position: 'absolute', bottom: '35px', left: '25px' }} />:''}

                <div className="d-flex mt-3 peer-chatbox">
                    <div className="peer-inputbox position-relative">
                        <button className="btn p-1 emoji-box" onClick={this.openEmoji}>
                            <img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_emoji.png"} className="img-fluid"/>
                        </button>
                        <input type="text" placeholder="Type your message here" className="send-message-input form-control" value={this.state.message}  onChange={this.updateInput}  onKeyDown={this.handleKeyDown}/>
                        <button className="btn p-1 file-box">
                            <input type="file" name='file' onChange={this.uploadFile}/>
                            <img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/attachment.svg"}
                                 className="img-fluid"/>
                        </button>
                    </div>
                    <button className="btn peersend-btn">
                        {this.state.submited ? <span className='loader-spinner'><i
                            className='fa fa-spinner  fa-spin'></i></span> :    <img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/send.png"} className="img-fluid" onClick={this.handleSubmit}/>}
                    </button>
                </div>
            </div>
        </div>)

    }
}
export  default SessionChatRoom