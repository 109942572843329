import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";

const MapDetail = (props) => {
  const history = useHistory();
  const [pageLoader, setPageLoader] = useState(true);
  const { filePath } = props.location;

  const notFound = (data) => {
    if (!data) history.push("/fna2022/maps");
  };

  return (
    <div className="p-3">
      <div className="card p-3 border-none box-shadow">
        <div className="pageHeading">
          <i
            className="fa fa-arrow-left backBtn"
            onClick={() => history.goBack()}
          ></i>
          <h4 className="text-center">Map</h4>
        </div>
      </div>
      <div className="card p-3 border-none box-shadow">
        {filePath ? (
          <embed
            height="600px"
            src={filePath + "#toolbar=0&navpanes=0&scrollbar=0&zoom=80"}
            type="application/pdf"
          />
        ) : (
          notFound(null)
        )}
      </div>
    </div>
  );
};

export default MapDetail;
