import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from './PrivateRoute'

import Homepage from './Homepage'
import Header from './Components/hfam/Header';
import Login from './Components/hfam/Login';
import SingleSignOn from './Components/hfam/SingleSignOn';
import LoginSplash from './Components/hfam/LoginSplash';
import ForgotPassword from './Components/hfam/ForgotPassword';
import ResetPassword from './Components/hfam/ResetPassword';
import ExhibitorDetails from './Components/hfam/Exhibitor/ExhibitorDetails';
import ExhibitorBooth from './Components/hfam/Exhibitor/ExhibitorBooth';

import Dashboard from './Components/hfam/Exhibitor/Dashboard';
import Lobby from './Components/hfam/Attendee/Lobby';
import ExhibitHall from './Components/hfam/Attendee/ExhibitHall';
import NetworkingLounge from './Components/hfam/Attendee/NetworkingLounge';
import ConferenceCenter from './Components/hfam/Attendee/ConferenceCenter';
import ConferenceCenterPage from './Components/hfam/Attendee/ConferenceCenterPage';
import TechnicalSessions from './Components/hfam/Attendee/TechnicalSessions';
import ExhibitList from './Components/hfam/Attendee/ExhibitList';

import HelpDesk from './Components/hfam/Attendee/HelpDesk';
import GoldenTicket from './Components/hfam/Attendee/GoldenTicket';
import AttendeeExhibitorBooth from './Components/hfam/Attendee/AttendeeExhibitorBooth';
import NotFound from './Components/hfam/NotFound';

import DashboardSpeaker from './Components/hfam/Speaker/Dashboard';
import ProfileDetails from './Components/hfam/Speaker/ProfileDetails';
import SessionAttendees from './Components/hfam/Speaker/SessionAtendees';
import UserProfile from './Components/hfam/UserProfile';

import ChatPage from "./TmChat/hfam/chat/ChatPage";
import VideoCall from "./TmChat/hfam/video/VideoCall";
import ConferenceMeeting from "./TmChat/hfam/videoConference/conferenceMeeting";
import Meetings from "./TmChat/hfam/videoConference/meetings";
import WorkProgress from './Components/hfam/WorkProgress';

/* import test from './Components/hfam/Exhibitor/test'; */

class Hfam extends Component {
constructor(){
  super();

  this.state={
    homePage:""
  }
}


  render() {

    const domain = window.location.href
    if(domain === ("http://localhost:3000/" || "https://affectionate-bohr-4d306b.netlify.app/")){
      return(
        <div className="App">
            <Router>
              <Route path="/" exact component={Homepage} />
              {/* <Route path="/home" exact component={Homepage} /> */}
            </Router>
        </div>
      )
    }
    else {
      return (
      <Router>
          <div className="App">
            
            <Header/>
            <Switch>
                
                <Route path="/hfam/login/:loginId" exact component={Login} />
                <PrivateRoute path="/hfam/loginSplash" exact component={LoginSplash} />
                <Route path="/hfam/single-signon/:eventId/:emailId/:tokenpass" exact component={SingleSignOn} />
                <Route path="/hfam/forgotPassword" component={ForgotPassword} />
                <Route path="/hfam/resetPassword" component={ResetPassword} />
                <PrivateRoute path="/hfam/chat" exact component={ChatPage} />
                <PrivateRoute path="/hfam/video-call" exact component={VideoCall} />
                <PrivateRoute path="/hfam/video-conference" exact component={Meetings} />
                <PrivateRoute path="/hfam/conference-meeting/:id" exact component={ConferenceMeeting} />
                <PrivateRoute path="/hfam/exhibitor/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/hfam/exhibitor/profile" exact component={ExhibitorDetails} />
                <PrivateRoute path="/hfam/exhibitor/booth" component={ExhibitorBooth} />
                <PrivateRoute path="/hfam/attendee/lobby" component={Lobby} />
                <PrivateRoute path="/hfam/attendee/exhibitHall" component={ExhibitHall} />
                <PrivateRoute path="/hfam/attendee/networkingLounge" component={NetworkingLounge} />
                <PrivateRoute path="/hfam/attendee/conferenceCenter" component={ConferenceCenter} />
                <PrivateRoute path="/hfam/attendee/conferenceCenterDetails/:sessionId" component={ConferenceCenterPage} />
                <PrivateRoute path="/hfam/attendee/technicalSessions" component={TechnicalSessions} />
                <PrivateRoute path="/hfam/attendee/exhibitList" component={ExhibitList} />
                <PrivateRoute path="/hfam/attendee/helpDesk" component={HelpDesk} />
                <PrivateRoute path="/hfam/attendee/goldenTicket" component={GoldenTicket} />
                <PrivateRoute path="/hfam/attendee/exhibitorBooth/:exhibitorId" component={AttendeeExhibitorBooth} />
                <PrivateRoute path="/hfam/speaker/dashboard" exact component={DashboardSpeaker} />
                <PrivateRoute path="/hfam/speaker/profile" exact component={ProfileDetails} />
                <PrivateRoute path="/hfam/speaker/attendees" exact component={SessionAttendees} />
                <PrivateRoute path="/hfam/user/profile" exact component={UserProfile} />
                <Route path="/hfam/workProgress" exact component={WorkProgress} />
                <Route path="*" component={NotFound} />
              </Switch>
         
          </div>
      </Router>
    );}
  }  
}

export default Hfam;
