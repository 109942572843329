import React, { Component } from "react";
import Axios from "axios";
//import { Link } from "react-router-dom";
//import Slider from "react-slick";
import SessionListGrid from "./TechnicalSessions/SessionListGrid";

class TechnicalSessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: true,
      sponsersImgList: [],
      mediaPartnerList: [],
      producedByList: [],
      technicalSessionList: [],
      trackList: [],
      addedSessions: [],
      searchByTrack: "",
      sectionLoader: true,
    };
  }
  componentDidMount() {
    this.getSponsorsLogo();
    this.getTrackList();
  }

  getSponsorsLogo = () => {
    /* sponser logos */

    Axios.get(
      `${
        global.config.requestUrl
      }user/getSponsorsLogos?eventId=${localStorage.getItem("eventId")}&type=3`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {
        if (res.data.errorCode === 0) {
          this.setState({
            sponsersImgList: res.data.data,
            mediaPartnerList: res.data.mediaPartner,
            producedByList: res.data.producedBy,
            sectionLoader: false,
          });
        } else {
          this.setState({ sectionLoader: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTrackList = () => {
    /* track list */

    Axios.get(
      `${
        global.config.requestUrl
      }user/getSessionTrackList?eventId=${localStorage.getItem("eventId")}`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {
        if (res.data.errorCode === 0) {
          this.setState({ trackList: res.data.data });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeTrackHandler = (e) => {
    this.setState({ searchByTrack: e.target.value });
  };
  render() {
    // const settings = {
    //   dots: false,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 6,
    //   slidesToScroll: 1,
    //   autoplay: true,
    // };

    return (
      <React.Fragment>
        <section className="bg11 signleScreen paddingTop">
          <div
            className="loaderWrp"
            id="fordatatable"
            style={{ display: "none" }}
          >
            <div className="loaderInr">
              <img src={process.env.PUBLIC_URL + "/images/loader.gif"} alt="" />
            </div>
          </div>
          <div className="midTable">
            
            <div className="sortBy">
            <div className="sortBx"><h3 className="m-0 p-0">Educational Sessions</h3></div>
              <div className="sortBx">
                <label>Filter By Track:</label>
                <select name="trackfilter" onChange={this.onChangeTrackHandler}>
                  <option value="">All</option>
                  {this.state.trackList.map((trackdata) => (
                    <option value={trackdata.track} key={trackdata.track}>
                      {trackdata.track}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="midTableWrp">
              <SessionListGrid passprops={this.state.searchByTrack} />
            </div>
          </div>
          {/* <div className="bottomScrollerSponsers">
            <div className="bottomScrollerSponsersBx">
              {this.state.sectionLoader ? (
                <div className="sectionLoader">
                  <img
                    src={process.env.PUBLIC_URL + "/images/loader.gif"}
                    alt=""
                  />
                </div>
              ) : null}

              <Slider {...settings}>
                {this.state.sponsersImgList.map((sponsers) => (
                  <div className="" key={sponsers.exhibitor_id}>
                    <Link
                      to={`/ihca-als2021/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}
                    >
                      <img src={sponsers.logo} alt="" />
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div> */}
        </section>
      </React.Fragment>
    );
  }
}
export default TechnicalSessions;
