import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import io  from 'socket.io-client';
import Axios from 'axios';
import MainNav from './Attendee/MainNav';
const socket = io.connect('https://tslvirtual-chat.herokuapp.com');


class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            dropMenu:false,
            quickDropMenu:false,
            allowAsExhibitor:false,
            allowAsSpeaker : false,
            allowAsLobbier : true,
            logOutBeforeClaimPopup:false,
        }
    }
    logOut = (e) => {
       
       e.preventDefault();
       if(window.location.pathname.split('/')[3] ==='conferenceCenterDetails' && window.sessionStorage.getItem("tsp") !== null){
            this.setState({logOutBeforeClaimPopup:true});
       }else{
           this.yesLogOut();
       }
    }

    yesLogOut = () => {
        this.setState({logOutBeforeClaimPopup:false});
        this.deleteCurrentUserFromSession(localStorage.getItem('eventId'),localStorage.getItem('userId'));
        const logoutData = {
            email: localStorage.getItem('userName'),
            eventId:localStorage.getItem('eventId'),
        }
        Axios.post(`${global.config.requestUrl}user/logoutUser`, logoutData,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        ).then(res => {
        var eventId = localStorage.getItem('eventId');
        var eventName = localStorage.getItem('eventName');
         /** */
        // socket.emit('usersCount', eventId);
        // socket.on('users', function(message) {
        //  console.log(message);
        //  if(document.getElementById("total_online"))
        //    document.getElementById('total_online').innerHTML = 'Total Online: '+message[0].total_count;
        // });
        /** */
        localStorage.clear();
        localStorage.setItem('eventName',eventName);
        this.props.history.push(`/goma/login/${eventId}`);
        })
        .catch(err => {
        console.log(err);
        });
    }
   
    dropMenuToggle = e => {
        this.setState({dropMenu:!this.state.dropMenu, quickDropMenu:false})
    }
    quickDropMenuToggle = e => {
        /* e.preventDefault(); */
        this.setState({quickDropMenu:!this.state.quickDropMenu, dropMenu:false})
    }
    componentDidMount(){
        // this.callSocketForOnlineUsers();
        // this.callSocketForLogoutCurrentUser(this.props);
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (localStorage.getItem('companyContactUrl')) {
            localStorage.getItem('companyContactUrl')
        }
    }
    // componentWillReceiveProps(props) {
    //     if(localStorage.getItem('exhibitorToken')){
    //         this.callSocketForOnlineUsers();
    //         this.callSocketForLogoutCurrentUser(props);
    //     }
           
    // }
    /* callSocketForLogoutCurrentUser function is useful when developer want to force logout all the users from frontend,
    *  this can be done if we set all the users to set 0 in field islogin in regaccesscode table.
    */
    // callSocketForLogoutCurrentUser = (currentProp) => {
        
    //     socket.on('logoutUsers', function(message) {
    //         var eventId = localStorage.getItem('eventId');
    //         var eventName = localStorage.getItem('eventName');
    //         localStorage.clear();
    //         localStorage.setItem('eventName',eventName);
    //         localStorage.setItem('eventId',eventId);
    //         currentProp.history.push(`/goma/login/${eventId}`)
    //     });
    // }

    // callSocketForOnlineUsers = () => {
    //     socket.emit('usersCount', localStorage.getItem('eventId'));
    //     socket.on('users', function(message) {
    //      if(document.getElementById("total_online"))
    //        document.getElementById('total_online').innerHTML = 'Total Online: '+message[0].total_count;
    //     });
    // }
    logOutBeforeClaimPopupHandler =() =>{
        this.setState({logOutBeforeClaimPopup:!this.state.logOutBeforeClaimPopup})
    }
    deleteCurrentUserFromSession = (eventId, userId) =>{
        const {match : {params}} = this.props;
            const currentUsersData = {
                userId: userId,
                eventId: eventId,
            }
        Axios.post(`${global.config.requestUrl}user/deleteSessionUser`, currentUsersData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }
    render() {
        var rolesArr = [];
        if(localStorage.getItem('roles')){
             rolesArr = localStorage.getItem('roles').split(",");
             var pImg = process.env.PUBLIC_URL + '/images/placeholder-profile.jpg';
             var editProfileUrl = window.location.pathname;

            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null ))
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-10' && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null)
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-6' && localStorage.getItem('speakerProfilePic') !== '' && localStorage.getItem('speakerProfilePic') !== null)
                pImg = localStorage.getItem('speakerProfilePic');
            else if(localStorage.getItem('currentRole') === '-1' && localStorage.getItem('companyContactUrl') !== '' && localStorage.getItem('companyContactUrl') !== null)
                pImg = localStorage.getItem('companyContactUrl');

            
            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 ) || localStorage.getItem('currentRole') === '-10')
                editProfileUrl = '/goma/user/profile';
            else if(localStorage.getItem('currentRole') === '-6')
                editProfileUrl = '/goma/speaker/profile';
            else if(localStorage.getItem('currentRole') === '-1')
                editProfileUrl = '/goma/exhibitor/profile';

            
        }

        




        const userNotLoggedIn = (
            <div id="collapsibleNavbar">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item ">
                        <button onClick={this.dropMenuToggle}>
                            <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                        </button>
                    </li> 
                </ul>
            </div>
        );
        const userLoggedIn = (
            <React.Fragment>
                {
                    window.location.pathname.split('/')[2] !=='loginSplash'  ? <MainNav/> : ''
                }
                
                <div id="collapsibleNavbar">
                    <ul className="navbar-nav ml-auto">
                    {/* <li className="nav-item quickLinkBtn">
                        <button onClick={this.quickDropMenuToggle}><i className="fa fa-link"></i> <em>Quick Links</em> <span className="fa fa-angle-down ml-2"></span></button>
                        {
                            this.state.quickDropMenu
                            ?
                            <ul className="dropMenu">
                                <li><Link onClick={this.quickDropMenuToggle} to="/goma/attendee/technicalSessions"><i className="fa fa-archive"></i>Search Technical Sessions & Speakers</Link></li>
                                <li><Link onClick={this.quickDropMenuToggle} to="/goma/attendee/exhibitHall?popup=1"><i className="fa fa-desktop"></i> Search Exhibit Hall</Link></li>
                            </ul>
                            :
                            null
                        }
                    </li> */}
                    {/* <li className="nav-item totalOnline">
                        <span id="total_online">Total Online: 0</span>
                    </li> */}
                    <li className="nav-item userProfilePic">
                        <img src={pImg} alt="" id="profile_img" />
                    </li> 
                    <li className="nav-item">
                        <button onClick={this.dropMenuToggle}>
                            <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                        </button>
                        {
                            this.state.dropMenu 
                            ?
                            <ul className="dropMenu">

                                { 
                                        rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1
                                        ?
                                        (<li onClick={this.dropMenuToggle}><Link to="/goma/exhibitor/dashboard"><i className="fa fa-user"></i> Exhibitor Dashboard</Link></li>
                                        ):null
                                }
                                { 
                                        rolesArr.indexOf('-6') !== -1
                                        ?
                                        (<li onClick={this.dropMenuToggle}><Link to="/goma/speaker/dashboard"><i className="fa fa-user"></i> Speaker Dashboard</Link></li>
                                        ):null
                                }
                                
                                <li onClick={this.dropMenuToggle}><Link to="/goma/attendee/lobby"><i className="fa fa-user"></i> Attendee Lobby</Link></li>
                                {
                                    editProfileUrl !== '/goma/loginSplash'
                                    ?
                                    <li><Link onClick={this.dropMenuToggle} to={editProfileUrl} ><i className="fa fa-user" aria-hidden="true"></i> Edit Profile</Link></li>
                                    :
                                    null
                                }

                                

                                <li onClick={this.dropMenuToggle}>
                                    <Link to="/" onClick={this.logOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>

                                   
                                    
                                    </li>
                            </ul>
                            :
                            null
                        }
                    </li>
                    </ul>
                </div>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <header className="pageMainHeader">
                    <nav className="navbar navbar-expand-sm" id="navMenu">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to="/goma/loginSplash">
                                <img src={process.env.PUBLIC_URL + '/images/goma/goma.jpg'} alt="" />
                            </Link>
                            
                            {localStorage.getItem("exhibitorToken") ? userLoggedIn : userNotLoggedIn}
                        </div>
                    </nav>
                </header>
                {/*=====popup before leave*/}
                {
                    this.state.logOutBeforeClaimPopup 
                    ?       
                        <div className="modalPopUp">
                            <div className="modalPopUpInr p-0">
                                <h3 className="modalHeading">CE Credit</h3>
                                <div className="modalContent p-4">
                                    <div className="claimBx">
                                        <p>You will not receive credit if logout before claiming credit.</p>
                                        <div className="form-group surveyBtns mb-0">
                                            <button className="btn btn-primary" onClick={this.yesLogOut} >Yes, Logout</button>
                                            <button className="btn btn-warning" onClick={this.logOutBeforeClaimPopupHandler}>No, Stay Here</button>
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
            </React.Fragment>
        )
    }
}
export default withRouter(Header);