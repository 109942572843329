import React, {Component} from 'react'
import '../TmStyle.css'
import fileUpload from '../utility/firebase';
import TroopMessenger from 'troop-messenger-chat-sdk';
import tmChat from '../utility/tmChat';
import tmChatServer from '../utility/server';
import troopServer from '../utility/troopServer';
import {Redirect} from 'react-router-dom'
import utility from "../utility/utility";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import ReactDOM from 'react-dom';

let stream;
const audioType = 'audio/wav';
let troopMessenger;
let tmLoginUserDetails;
let loginUserDetails;
let receiverUid = 0;

class ChatInput extends Component {

    state = {
        usersList: [],
        isLoading: true,
        videoCall: false,
        incomingCallAction: false,
        onAnswered: false,
        userDetails: {},
        chatUser: {},
        isUsersLoading:true,
        initialLoading: true,

        chatConversationsLoading:false,
        chatConversations: [],
        files: [],
        eventId:'',
        groupList:[],
        userInfo:{},
        activeGroup:false,
        activeRole: '-10',
        activeUserSearch:false,
        onlineUsers:[],
        groupInfo:{},


        message: '',
        files: [],
        recording: false,
        visible: true,
        submited: false,
        seconds: 0,
        hours: 0,
        minutes: 0,
        recordTime: '00:00:00',
        showRecording:false,
        userDetails:{},
        groupUsers:[],
        emojiOpen:false,

        receiverTMUserId: 0
    };

    constructor(prop) {
        super(prop);
        this.getUserDetails = this.getUserDetails.bind(this);
        this.state.eventId=localStorage.getItem('eventId')
        this.emjoiIcon=React.createRef();
        this.updateInput = this.updateInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.saveFiles(e.dataTransfer.files);
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    };
    uploadFile = (event) => {
        this.saveFiles(event.target.files);
        event.target.value = null;
    }
    saveFiles = (files) => {
        let stateFiles = this.state.files;
        stateFiles.push(files[0]);
        this.setState({'files': stateFiles})
    };

    componentWillUnmount(){
        window.removeEventListener('dragenter', this.handleDragIn);
        window.removeEventListener('dragleave', this.handleDragOut);
        window.removeEventListener('dragover', this.handleDrag);
        window.removeEventListener('drop', this.handleDrop);
        window.removeEventListener('mousedown', this.handleClick, false);
    }

    onEmojiClick = (emojiObject) => {
        this.setState({'message':this.state.message+emojiObject.native})
    };
    openEmoji=()=>{
        this.setState({'emojiOpen':!this.state.emojiOpen});
        this.emojiPicker = React.createRef();

    }
    handleClick(event) {
        try {
            let node = ReactDOM.findDOMNode(this.emojiPicker.current);
            if (!node.contains(event.target) && !this.emjoiIcon.current.contains(event.target)) {
                // Handle outside click here
                this.openEmoji();
            }
        } catch(error) {
            return null
        }
    }

    fileuploadHandler = async () => {
        const files = this.state.files;
        return await fileUpload(files);
    };
    saveAudioFile = async () => {
        const files ={'fileType':'blod',type:audioType,"blod":this.state.audio,name:this.makefileName(10)+".wav"};
        return await fileUpload([files]);
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };

    updateInput(e) {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }

        this.setState({message: e.target.value})
    }

    removeFile = (e) => {
        let files = this.state.files;
        files.splice(e, 1);

        this.setState({'files': files})
    };

    async startRecording(e) {


        stream = await navigator.mediaDevices.getUserMedia({audio: true});
        // show it to user
        // init recording
        this.mediaRecorder = new MediaRecorder(stream);
        // init data storage for video chunks
        this.chunks = [];
        // listen for data from media recorder
        this.mediaRecorder.ondataavailable = e => {
            if (e.data && e.data.size > 0) {
                this.chunks.push(e.data);
            }
        };
        // e.preventDefault();
        // wipe old data chunks
        this.chunks = [];
        // start recorder with 10ms buffer
        this.mediaRecorder.start(10);
        // say that we're recording
        let intervalId = setInterval(this.timer, 1000);

        this.setState({
            recording: true, seconds: 0,
            hours: 0,
            minutes: 0, intervalId: intervalId
        });
    }

    timer() {
        // setState method is used to update the state
        let seconds=this.state.seconds
        seconds++;
        this.setState({seconds:seconds});
        if (this.state.seconds >= 60) {
            this.setState({seconds :0});
            let minutes=this.state.minutes;
            minutes++;
            this.setState({'minutes':minutes});
            if (this.state.minutes >= 60) {
                this.setState({minutes : 0});
                let hours=this.state.hours;
                hours++;
                this.setState({hours : hours});
            }
        }

        let time = (this.state.hours ? (this.state.hours > 9 ? this.state.hours : "0" + this.state.hours) : "00") + ":" + (this.state.minutes ? (this.state.minutes > 9 ? this.state.minutes : "0" + this.state.minutes) : "00") + ":" + (this.state.seconds > 9 ? this.state.seconds : "0" + this.state.seconds);
        this.setState({recordTime: time});
    }

    stopRecording(e) {
        this.mediaRecorder.stop();
        this.mediaRecorder=null;
        this.stopMediaStream();
        clearInterval(this.state.intervalId);
        this.saveAudio();
        this.setState({recording: false,'showRecording':true,recordTime:''});

    }
    stopMediaStream(){
        stream.getTracks().forEach(function(track) {
            if (track.readyState === 'live' && track.kind === 'audio') {
                track.stop();
            }
        });
    }
    cancelRecording(e) {
        if(this.mediaRecorder){
            this.mediaRecorder.stop();
            this.mediaRecorder=null;
        }
        if(stream) {
            this.stopMediaStream();
        }
        this.setState({recording: false,recordTime:'',showRecording:false,audio:''});
        clearInterval(this.state.intervalId);
    }

    saveAudio() {
        // convert saved chunks to blob
        const blob = new Blob(this.chunks, {type: audioType});
        // generate video url from blob
        const audioURL = window.URL.createObjectURL(blob);
        // append videoURL to list of saved videos for rendering
        this.setState({audio: audioURL});

    }
    async handleSubmit() {
        let filesPaths = [];

        this.setState({'submited': true});

        if(this.state.recording){
            //  this.setState({recording: false,recordTime:'',showRecording:false});
            this.stopRecording()
        }
        if(this.state.audio){

            filesPaths = await this.saveAudioFile();
        }
        if (this.state.files.length) {
            filesPaths = await this.fileuploadHandler();
        }
        let data={
            'message': this.state.message,
            filesPaths: filesPaths,

        };
        data['receiver_id']= this.state.receiverTMUserId;
        data['receiver_uid']= receiverUid;
        this.sendMessage(data, this.props.userInfo);
        this.setState({'message':''});
        this.setState({
            message: '',
            files: [],
            audio:'',
            recording: false,
            recordTime:'',
            showRecording:false
        });
        this.setState({'submited': false});
//Send state to the server code
    }

    uploadFileDiv(files) {
        if (files.length === 0) {
            return ('');
        }
        return (<div className="attachments">
            {files.map((file, index) => {
                return (<div className="uploaded-files" key={index}>
                    <div className="bg-white p-3 border-radius12">
                        <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/document.svg"} className="img-fluid"/>
                        <span className="d-block text-black mt-2 upload-file-name">{file.name}</span>
                        <div className="file_size mt-3">
                            {utility.formatBytes(file.size, 1)}
                        </div>
                        <button className="cursor-pointer w-auto h-auto" onClick={this.removeFile.bind(this, index)}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/close.svg"} className="img-fluid"/>
                        </button>
                    </div>
                </div>);
            })}

            <div className="add-more">
                <button className="w-100 h-100 m-0">
                    <input type='file' className='file-upload' onChange={this.uploadFile}/>
                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/add.svg"}/>
                </button>
            </div>
        </div>)
    }

    getUserDetails=async ()=>{
        let userDetails=await troopServer.getUserDetails(this.props.userInfo.email,this.props.userInfo.role);
         this.setState({"userDetails":userDetails.data[0]});
}
   
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                
            })
            .on("connected", async () => {
                this.setState({troopMessengerConnection: troopMessenger});
            })
            .on("disconnected", function () {
            });
    };
    sendMessage = (message, userDetails) => {
        message.event_id =this.state.eventId;
        message.sender_uid = loginUserDetails.uid;
        if (message.filesPaths && message.filesPaths.length) {
            for (const filepath of message.filesPaths) {
                message.attachment = filepath;
                tmChat.sendAttachment(troopMessenger, message);
            }
        } else {
            tmChat.sendTextMessage(troopMessenger, message);
        }
    };

    async getUserDetails(uid) {
        let userIdRole = utility.getUserIdAndRole(uid);
        const parameters = {
            'ids': userIdRole['userId'],
            'role': userIdRole['role'],
        };
        let usersList = await troopServer.getUsersListBasedOnRole(parameters);
        usersList = usersList.data;
        if (usersList && usersList.length) {
            return usersList[0];
        }
    }
    makeAudioDevice = (e) => {
        this.setState({'selectedAudio': e.target.value})
    }
    closeAudioModal = () => {
        this.setState({"openAudioDeviceModal": false});
    }
    selectAudioModal = () => {
        if (!this.state.selectedAudio) {
            alert("Please Select Audio Device");
            return false;
        }
        this.setState({"openAudioDeviceModal": false});
        localStorage.setItem('c2VsZWN0ZWRBdWRvRGV2aWNlSWQ', this.state.selectedAudio)
        localStorage.setItem('c2VsZWN0ZWRWaWRlb0RldmljZUlk', this.state.videoDevices[0].deviceId)
        this.makeCall();
    }

    async componentDidMount() {
        window.addEventListener('storage', function(e) {
            if(e.key==='currentRole' && e.oldValue!==e.newValue){
                window.location.reload();
            }
        });
        window.onbeforeunload = function (e) {
            window.onunload = function () {
                window.localStorage.isChatWindowActive = "false";
            }
            return undefined;
        };

        window.onload = function () {
            window.localStorage.isChatWindowActive = "true";
        };

        document.addEventListener('mousedown', this.handleClick, false);
        window.addEventListener('dragenter', this.handleDragIn);
        window.addEventListener('dragleave', this.handleDragOut);
        window.addEventListener('dragover', this.handleDrag);
        window.addEventListener('drop', this.handleDrop);
        this.timer = this.timer.bind(this);

        const userDetailsdata = await troopServer.getLoginUserDetails();
        if (userDetailsdata.errorCode === 0) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            loginUserDetails = userDetailsdata.data[0];
            this.setState({
                userDetails: loginUserDetails,
                isLoading: false
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
            loginUserDetails.uid = uid;
            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });

            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                this.connectTMChat();
                let userDetails=await troopServer.getUserDetails(this.props.userInfo.email,this.props.userInfo.role);
                if(userDetails.errorCode === 0 && Object.keys(userDetails.data).length){
                    let userId = userDetails.data[0].userId;
                    if(this.props.userInfo.role == "-6"){
                        userId = userDetails.data[0].speakerId;
                    }else if(this.props.userInfo.role == "-1"){
                        userId = userDetails.data[0].exhibitorId;
                    }
                    let tmDetails = await troopMessenger.register(utility.generateUserUid({userId,role:this.props.userInfo.role}), userDetails.data[0].name);
                    if(tmDetails.success){
                        this.setState({receiverTMUserId : tmDetails.tm_user_id});
                        receiverUid = tmDetails.uid;    
                    }
                }
            } catch (e) {
                console.log("Error");
                console.log(e);
            }
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }
    }

    makefileName(length) {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    render() {
        if (this.state.redirect) {
            return (
                <Redirect to={`${process.env.PUBLIC_URL}/login`}/>
            )
        }
        /* if (this.state.isLoading || !this.state.receiverTMUserId) return ""; */
        //if(this.state.videoCall) return  <VideoPage incomingCall={this.state.incommingCall} toggleAudio={this.toggleAudio} toggleAudioValue={this.state.toggleAudioValue} endCall={this.endCall} onAnswered={this.state.onAnswered} index={0}/>;
        return (
            <React.Fragment>
                {this.state.emojiOpen?<Picker ref={this.emojiPicker} onSelect={this.onEmojiClick} style={{ position: 'absolute', bottom: '20px', right: '20px' }} />:''}

                <div className="message-area py-3 px-4 d-flex justify-content-between" style={{zIndex:9}}>
                    <div className="input-area d-flex">
                        {this.uploadFileDiv(this.state.files)}
                        <div className={(this.state.files.length?'input-text-area--file-upload ':'')+ ' d-flex w-100 input-text-area'} data-recording={this.state.recording}>
                            {this.state.recording ? <div className="microphone-record-container">
                                <div className="microphone-stop-btn" onClick={e => this.stopRecording(e)}><img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/stop-button.svg"} className='img-fluid w-100 h-100'/></div>
                                <div className="microphone-timer-text">{this.state.recordTime}</div>
                                <div className="microphone-record-close"
                                    onClick={e => this.cancelRecording(e)}><img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/cancel.svg"} className='img-fluid w-100 h-100'/></div>
                            </div> : (this.state.showRecording ?<div className="microphone-record-container">
                                    <audio controlsList="nodownload" className='w-80' controls><source src={this.state.audio}/>
                                    </audio>
                                    <div className="microphone-record-close"
                                        onClick={e => this.cancelRecording(e)}><img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/cancel.svg"} className='img-fluid w-100 h-100'/></div>
                                </div>:
                                <input type="text" placeholder={(this.state.isLoading || !this.state.receiverTMUserId) ? "Loading..." : "Type your message here..."}  value={this.state.message} onChange={this.updateInput} onKeyDown={this.handleKeyDown}/>)}
                            {this.state.submited || (this.state.isLoading || !this.state.receiverTMUserId) ? <span className='loader-spinner'><i
                                    className='fa fa-spinner  fa-spin'></i></span> :
                                <button type='button' className='btn p-0 m-0 message-sendbtn' onClick={this.handleSubmit}>
                                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_send.png"}
                                        className="img-fluid"/>
                                </button>
                            }
                        </div>
                    </div>
                    <div className="d-flex attachment_area">
                        <button className="position-relative cursor-pointer">
                            <input type='file' className='file-upload' onChange={this.uploadFile}/>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_attachemnts.png"}
                                className="img-fluid"/>
                        </button>
                        <button onClick={this.openEmoji}>


                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_emoji.png"} ref={this.emjoiIcon} className="img-fluid"/>
                        </button>
                        <button style={{display:'none'}}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_share.png"} className="img-fluid"/>
                        </button>
                        {!this.state.recording? <button onClick={e => this.startRecording(e)}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_mic.png"} className="img-fluid"/>
                        </button>:''}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ChatInput;
