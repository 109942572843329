import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { withRouter} from 'react-router-dom';

class LogoutSuccess extends Component {

    constructor(props){
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <div className="centerBx">
                    <i className="fa fa-check-circle"></i>
                    <h1>You have been successfully logged out!</h1>
                    <Link to={`/csda/single-signon/${this.props.match.params.eventId}/${this.props.match.params.emailId}/${this.props.match.params.tokenpass}`}>Login again</Link>
                </div>
            </React.Fragment>
        )
    }
}

export default withRouter(LogoutSuccess);
