import { Link, withRouter } from "react-router-dom";
import  moment from 'moment';
import React, {Component} from "react";
import tmChatServer from "../utility/server";
import CattleCall from "cattlecall-conference-sdk";
import troopServer from "../utility/troopServer";
import TroopMessenger from "troop-messenger-chat-sdk";
import utility from "../utility/utility";
import VideoChat from "../video/VideoChat";
import tmChat from "../utility/tmChat";
import DatePicker from "react-datepicker";
import UrlParams from "../utility/UrlParams";
let cattlecall;
let troopMessenger;
let tmLoginUserDetails;
class ConferenceMeeting extends Component{
    constructor(props) {
        super(props);
        this.state={devices:{},selectedAudioDevice:'default',speakerOff:false,groupMembers:[],selectedVideoDevice:'default',userVideoStreams:{},openAudioDevice:false,openVideoDevice:false,loadingMeeting:false,isMeetingStarted:true,troopConnected:false,chatClose:false,userListClose:false,'chatUsers':{},chatConversation:[],eventId:localStorage.getItem('eventId'),meetingUsers:[],activeUser:false,'meetingDetails':{},videoState:true,'audioState':true,videoCallUsers:{}}
    }
    getGroupDetails=async ()=>{
        if(troopMessenger){
            let details= await troopMessenger.group().details({
                group_id: this.state.meetingDetails.tm_group_id
            }).then((response)=>{
                if(response.success){
                    let members=response.data.members;
                    this.setState({'groupMembers':this.state.members});
                }
            });

        }
    }
    getAfterGroupCreate=async ()=>{
        if(troopMessenger){
            let details= await troopMessenger.group().details({
                group_id: this.state.meetingDetails.tm_group_id
            }).then((response)=>{
                if(response.success){
                    let members=response.data.members;
                    this.setState({'groupMembers':this.state.members});
                    this.checkUserExists();
                }
            });

        }
    }
    checkUserExists=()=>{
        let users=Object.values(this.state.videoCallUsers);
        for(let user of users){
            let groupIndex=this.state.groupMembers.findIndex((member)=>{
                if(member.member_uid===user.uid){
                    return member;
                }
            })
            if(groupIndex===-1){
                this.addGroupMember(user);
            }
        }

    }
    endCall = () => {
        this.setState({videoCall: false});
        console.log((this.state.meetingDetails.created_by===this.state.loginUserDetails.cattle_call_id),"sdsdssdsd");
        if(this.state.meetingDetails.created_by===this.state.loginUserDetails.cattle_call_id){
            cattlecall.endMeeting();
        }
        console.log("leaveMeetinggg");
        cattlecall.leaveMeeting();
        var win = window.open("about:blank", "_self");
        win.close()

    }
    createGroup=async (groupUsers)=>{
        console.log("callinf Groupp",tmLoginUserDetails,troopMessenger);
        await troopMessenger.group().create({
            group_name: this.state.meetingDetails.title+'_'+this.state.meetingDetails.room_id,
            group_description: "Conference Meeting",
            members: groupUsers
        })
            .then((response)=>{
               // console.log(response,"after group Create");
                tmChatServer.updateMeeting({'tm_group_id':response.group_data.group_id,'meeting_id':this.state.meetingDetails.id});
                this.state.meetingDetails.tm_group_id=response.group_data.group_id;
                this.setState({'meetingDetails':this.state.meetingDetails});
                this.getGroupDetails();
            })
            .catch(function (e) {
                console.log(e,"error At Join Meett6ing");
            });
    }
    createMeetingRoom=(roomId,password,meetingId)=>{
        cattlecall.createMeating(roomId,password,meetingId).then(response=>{
            if(response.success){
                this.state.meetingDetails.cattle_call_room_id=response.cc_meeting_id;
                this.setState({'meetingDetails':this.state.meetingDetails});
                this.joinMeeting();
                tmChatServer.updateMeeting({'cattle_call_meeting_id':response.cc_meeting_id,'meeting_id':meetingId});
            }
        }).catch(error=>{
            alert("No Room Found");
            window.location.href=window.location.origin+process.env.PUBLIC_URL+'/video-conference';
        })
    }
    joinMeeting=()=>{
        cattlecall.joinMeeting(''+this.state.meetingDetails.cattle_call_room_id,this.state.roomPassword,"#localVideo","#remotevideo").then(response=>{
            let userList=response.data;
            console.log(userList,"userListtt");
            for(let user of userList) {

                if(user.user_id) {
                    if ('' + user.user_id !== '' + this.state.loginUserDetails.cattle_call_id) {
                        this.getJoinedUserDetails(user.user_id)
                    }
                }
            }
            this.setState({'videoCallUsers':this.state.videoCallUsers});

        }).catch(error=>{
            console.log(error);
        })
    }
    getJoinedUserDetails=async (cattleUserId)=>{
        console.log(this.state.videoCallUsers[cattleUserId],cattleUserId,"confffgfgfg");
        if(!this.state.videoCallUsers[cattleUserId] || this.state.videoCallUsers[cattleUserId].name==="") {
            const getUserDetailsResponse = await tmChatServer.getUserDetail({'cattle_call_id': cattleUserId});
            console.log(getUserDetailsResponse,"sdsdsdsdsdsdsdsd",(getUserDetailsResponse && Object.keys(getUserDetailsResponse).length));
            if (getUserDetailsResponse && Object.keys(getUserDetailsResponse).length) {
                console.log("comes Insde",getUserDetailsResponse);
                let videoCallUser = await this.getUserDetails(utility.generateUserUid({
                    'userId': getUserDetailsResponse.user_id,
                    'role': getUserDetailsResponse.roles
                }));
                videoCallUser.cattle_call_id = cattleUserId;

                    console.log("comes here",(!this.state.videoCallUsers[cattleUserId] || this.state.videoCallUsers[cattleUserId].name!==""))
                 if (!this.state.videoCallUsers[cattleUserId] || this.state.videoCallUsers[cattleUserId].name===""){
                    this.state.chatUsers[videoCallUser.uid]=videoCallUser;
                    if(this.state.userVideoStreams[cattleUserId]){
                        videoCallUser.stream=this.state.userVideoStreams[cattleUserId].stream
                        videoCallUser.videoOn=true;
                        videoCallUser.activeUser=false;
                        videoCallUser.streamAdded=true;
                    }
                    this.state.videoCallUsers[cattleUserId] = videoCallUser;
                }
                 if(!this.state.chatUsers[videoCallUser.uid]){
                     this.state.chatUsers[videoCallUser.uid]=videoCallUser;
                     this.setState({'chatUsers':videoCallUser});
                 }
                setTimeout(()=>{
                    console.log("iside teh eee remote Cideo at user Detailsss",cattleUserId,this.state.videoCallUsers[cattleUserId],"caaaa");
                    let remoteVideoSelector=document.querySelector('#remoteVideoElement_'+cattleUserId+'');
                    if(this.state.userVideoStreams[cattleUserId]){
                        remoteVideoSelector.srcObject=null;
                        remoteVideoSelector.srcObject = this.state.userVideoStreams[cattleUserId].stream;

                    }

                },1000);
                this.setState({'videoCallUsers': this.state.videoCallUsers,chatUsers:this.state.chatUsers});
                if(this.state.groupMembers && this.state.groupMembers.length) {
                    let userIndex = this.state.groupMembers.findIndex((member) => {
                        if (this.state.videoCallUsers[cattleUserId].uid === member.member_uid) {
                            return member;
                        }
                    });
                    if (userIndex === -1) {

                              this.addGroupMember(this.state.videoCallUsers[cattleUserId])

                    }
                }
            }
        }
    }
    addGroupMember=async (member)=>{
        let tmDetails = await this.props.troopMessengerConnection.register(utility.generateUserUid({'userId':member.userId,role:member.role}), member.name);
        let  tm_user_id=tmDetails.tm_user_id;

        await troopMessenger.group().addMembers({
            group_id: this.state.meetingDetails.tm_group_id ,
            members: [{user_id: tm_user_id, role: this.state.troopMessengerConnection.groupMemberRole().NORMAL}]
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (e) {
                console.log(e);
            });
    }
    getUserDetails= (uid)=> {
        return  new Promise(async (resolve) => {
            let userIdRole = utility.getUserIdAndRole(uid);
            const parameters = {
                'ids': userIdRole['userId'],
                'role': userIdRole['role'],
                'eventId': this.state.eventId
            };
            let usersList = await troopServer.getUsersListBasedOnRole(parameters);
            console.log(usersList,"user list dataa sdsdsdsd",parameters);
            usersList = usersList.data;
            if (usersList && usersList.length) {
                usersList[0].uid = uid;
                return resolve(usersList[0]);
            }
        });
    }
    startScreenShareing=()=>{
        cattlecall.shareScreen();
    }
    connectCattleCall=()=>{
        cattlecall=new CattleCall("L0gJS27u4pHxwbh5L0gJS27u4pHxwbh5","HGupYTsKbiJWZSDOqw3O01ajKAgNUTnf");
        cattlecall.connect(this.state.loginUserDetails.cattle_call_id);
        cattlecall.on("ready",(data)=>{
            this.getMeetingDetails(this.state.roomId);
        }).on("user_left",(data)=>{

            if(data.participant_id){
                  if(this.state.videoCallUsers[data.participant_id]) {
                      this.state.videoCallUsers[data.participant_id]['streamAdded'] = false;
                      this.setState({'videoCallUsers': this.state.videoCallUsers});
                  }
            }
        }).on("disconnected",(id)=>{
            console.log(id,"disconneted");
             if(this.state.videoCallUsers[id]) {
                 this.state.videoCallUsers[id]['streamAdded'] = false;
                 this.setState({'videoCallUsers':this.state.videoCallUsers});
             }
        }).on('user_stream_added',(streem,id)=>{
            console.log(streem,id,"user Stream Addedded");
            setTimeout(()=>{
                let remoteVideoSelector=document.querySelector('#remoteVideoElement_'+id+'');
                console.log(remoteVideoSelector,"remoteVideoSelector");
                if(!this.state.userVideoStreams[id]){
                    this.state.userVideoStreams[id]={};
                }
                this.state.userVideoStreams[id].stream=streem;
                if(remoteVideoSelector) {
                       if(this.state.videoCallUsers[id]) {
                           this.state.videoCallUsers[id].stream = streem;
                           this.state.videoCallUsers[id].videoOn = true;
                           this.state.videoCallUsers[id].activeUser = false;
                           this.state.videoCallUsers[id].streamAdded = true;
                       }
                    this.setState({'videoCallUsers':this.state.videoCallUsers})
                    if(remoteVideoSelector.srcObject) return ;
                    remoteVideoSelector.srcObject=null;
                    remoteVideoSelector.srcObject = streem;
                }
            },1000)
        }).on("user_joined",(user)=>{
            console.log(user,"user Joined Stream Not added");
            this.state.videoCallUsers[user.user_id]={'name':'','cattle_call_id':user.user_id};
            this.setState({'videoCallUsers':this.state.videoCallUsers});
            this.getJoinedUserDetails(user.user_id)
        }).on("meeting_end",(data)=>{
            window.close();
        }).on("video_change",(data)=>{
            if(''+data.participant_id!==''+this.state.loginUserDetails.cattle_call_id)
            {
                this.state.videoCallUsers[data.participant_id]['muteVideo']=!this.state.videoCallUsers[data.participant_id]['muteVideo'];
                this.setState({'videoCallUsers':this.state.videoCallUsers});
            }else{
                this.setState({'videoState':!this.state.videoState});
            }
        }).on("audio_change",(data)=>{
            console.log(data,"audioChasasassas");
            if(''+data.participant_id!==''+this.state.loginUserDetails.cattle_call_id)
            {
                this.state.videoCallUsers[data.participant_id]['muteAudio']=!this.state.videoCallUsers[data.participant_id]['muteAudio'];
                this.setState({'videoCallUsers':this.state.videoCallUsers});
            }else{
                this.setState({'audioState':!this.state.audioState});
            }
        }).on('spotlight_user',(participant)=>{
            let user=participant.participant_id;
            if(this.state.loginUserDetails.cattle_call_id!==user) {
                this.setState({'activeUser': true, 'activeUserId': user});
                let remoteVideoSelector = document.querySelector('#remoteVideoElement');
                if (remoteVideoSelector) {

                    remoteVideoSelector.srcObject=null;
                    remoteVideoSelector.srcObject = this.state.videoCallUsers[user].stream;
                    remoteVideoSelector.muted =  false;
                    this.setState({'videoCallUsers': this.state.videoCallUsers})
                }
            }else{
                this.setState({'activeUser': true, 'activeUserId': user});
                let remoteVideoSelector = document.querySelector('#remoteVideoElement');
                if (remoteVideoSelector) {
                    remoteVideoSelector.srcObject=null;
                    let localVideoSelector=document.querySelector('#localVideo');
                    if(localVideoSelector){
                        remoteVideoSelector.srcObject =  localVideoSelector.srcObject;
                        remoteVideoSelector.muted =  true;
                       // this.setState({'videoCallUsers': this.state.videoCallUsers})
                    }
                }
            }

        }).on("meeting_started",(data)=>{
            this.setState({'isMeetingStarted':true});
            this.joinMeeting();
        }).on('screen_stream_added', (stream,id)=>{
               console.log(stream,"stream screen stream addede");
            let remoteVideoSelector = document.querySelector('#remoteVideoElement');
            this.setState({'activeUser': true});
            if (remoteVideoSelector) {
                remoteVideoSelector.srcObject=null;
                remoteVideoSelector.srcObject =stream;
                remoteVideoSelector.muted =  false;
            }
        }).on("error",(data)=>{
            console.log(data)
        });
    }
    componentWillUnmount(){
        if(document.querySelector('header')){
            document.querySelector('header').style.display='block';
        }
    }
    setSrcObject=async (video,stream) =>{
        if(video&& stream) {
            video.srcObject = stream;
        }
    }
    async componentDidMount() {
        const {match : {params}} = this.props;
        let roomPassword = new UrlParams(this.props.location.search).get("pwd");
        if(document.querySelector('header')){
            document.querySelector('header').style.display='none';
        }
        this.setState({'loadingMeeting':true});
        //checkNotificationPermission();
        const userDetailsdata = await troopServer.getLoginUserDetails()

        if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            let loginUserDetails = userDetailsdata.data[0];
            this.setState({
                userDetails: loginUserDetails,
                isLoading: false
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
            loginUserDetails.uid = uid;
            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });

            //cattleCallLoginUserDetails.user_id = getUserDetails.cattle_call_id;
            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                loginUserDetails.cattle_call_id = getUserDetails.cattle_call_id;
                this.setState({'loginUserDetails':loginUserDetails},()=>{
                    this.connectCattleCall();
                    this.connectTMChat();
                });

            } catch (e) {
                console.log(e);
            }
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }

        this.setState({'roomId':params.id,'roomPassword':roomPassword});


    }
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
            })
            .on("connected", async () => {

                this.setState({troopMessengerConnection: troopMessenger,'troopConnected':true});
                if(!this.state.conversationLoading && Object.keys(this.state.meetingDetails).length) {
                    this.getChatConversation();
                    this.setState({'conversationLoading':true});

                }
            })
            .on("disconnected", function () {
            })
            .on("message_sent", async (data) => {
                  if(''+this.state.eventId===''+data.conversation_reference_id) {
                      this.setState({'chatConversation': this.state.chatConversation.concat(data)});
                  }
            })
            .on("receive_message", async (data) => {
                if(''+this.state.eventId===''+data.conversation_reference_id) {
                    let conversation = Object.assign(data, this.state.chatUsers[data.sender_uid])
                    this.setState({'chatConversation': this.state.chatConversation.concat(conversation)});
                }
            })
            .on("message_delivered", function (data) {
            })
            .on("message_read", function (data) {
            })
            .on("user_online", function (data) {
            })
            .on("user_dnd", function (data) {
            })
            .on("user_offline", function (data) {
            })
            .on("group_created", function (data) {
            })
            .on("group_updated", function (data) {
            })
            .on("typing", function (data) {
            })
    };
    muteParticipants=(e,userId)=>{
        e.stopPropagation();
        if(this.state.meetingDetails.created_by===this.state.loginUserDetails.cattle_call_id) {
            if (this.state.videoCallUsers[userId]['muteAudio']) {
                cattlecall.unmuteParticipant(userId);
            } else {
                cattlecall.muteParticipant(userId);
            }
        }
    }
    videoffParticipants=(e,userId)=>{
        e.stopPropagation();
        if(this.state.meetingDetails.created_by===this.state.loginUserDetails.cattle_call_id) {
            if (this.state.videoCallUsers[userId]['muteVideo']) {
                cattlecall.turnOnVideo(userId);
            } else {
                cattlecall.turnOffVideo(userId);
            }
        }
    }
    getMeetingDetails=async (roomId)=>{
        let details=await tmChatServer.getMeetingDetails({'room_id':roomId});
        if(!details.meetingDetails){
            alert("Invalid Meeting");
            window.close();
        }
        this.setState({'meetingDetails':details.meetingDetails});
        this.getMeetingUsers();
        cattlecall.isMeetingStarted(details.meetingDetails.cattle_call_room_id,details.meetingDetails.password).then(response=>{
                console.log(response,"isMeetingStarted");
            this.setState({'loadingMeeting':false,isMeetingStarted:true});
            this.joinMeeting();
        }).catch(error=>{
            this.setState({'loadingMeeting':false});
            if(error.error_code===1 && ''+this.state.meetingDetails.created_by===''+this.state.loginUserDetails.userId){
                   console.log("comes Heres");
                this.setState({'loadingMeeting':false},()=>{
                       console.log("comes Insde");
                     this.startMeeting();
                 });

            }else{
                this.setState({'isMeetingStarted':false});   // todo change it false
                this.joinMeeting();
            }
            console.log(error);
        })
    }
    startMeeting=async()=>{

        cattlecall.startMeeting(parseInt(this.state.meetingDetails.cattle_call_room_id),this.state.meetingDetails.password).then(response=>{
                  this.joinMeeting();
        }).catch(error=>{
            console.log(error);
        })
    }
    getChatConversation=async()=>{
        let conversation=await tmChat.getConversation(troopMessenger, {
            is_group: 1,
            group_id:this.state.meetingDetails.tm_group_id,
            tm_user_id: tmLoginUserDetails.tm_user_id,
            'authorization_token': tmLoginUserDetails.authorization_token,
            'conversation_reference_id': this.state.eventId
        });
        conversation=conversation?conversation.map((chat)=>{
            if(chat.sender_uid!==this.state.loginUserDetails.uid) {
                console.log(this.state.chatUsers[chat.sender_uid],"chataaaaaaa");
                chat=  Object.assign(chat,this.state.chatUsers[chat.sender_uid]);
            }
            return chat;
        }):[];
        this.setState({'chatConversation':conversation})
    }
    getMeetingUsers = async () => {
        let users = await tmChatServer.getMeetingUsers(this.state.meetingDetails.id);
        if(users.success && users.meetingUsers){
            users=users.meetingUsers;
            let groupUsers=[];
            for (let user of users) {
                if ('' + user.cattle_call_id !== '' + this.state.loginUserDetails.cattle_call_id) {
                    let userDetails = await troopServer.getUsersListBasedOnRole({
                        'ids': '' + user['user_id'],
                        'role': user['role']
                    });
                    userDetails = userDetails.data[0];
                    userDetails.activeUser=false;
                    if (!user['cattle_call_id']) {
                        let registerUser = await tmChatServer.updateUser(userDetails, troopMessenger);
                        userDetails.tm_user_id = registerUser.tm_user_id;
                        userDetails.cattle_call_id = registerUser.cattle_call_id;
                        userDetails.uid = registerUser.uid;

                    }else{
                        userDetails.tm_user_id = user.tm_user_id;
                        userDetails.cattle_call_id = user.cattle_call_id;
                        userDetails.uid = utility.generateUserUid({'userId':user.user_id,'role':user.role});
                    }

                    userDetails.videoOn=false;
                    if ('' + userDetails.cattle_call_id !== '' + this.state.loginUserDetails.cattle_call_id) {
                        this.state.chatUsers[userDetails.uid]=userDetails;
                        groupUsers.push({'user_id':userDetails.tm_user_id,'role': troopMessenger.groupMemberRole().ADMIN})
                        if(this.state.userVideoStreams[userDetails.cattle_call_id]){
                            userDetails.stream=this.state.userVideoStreams[userDetails.cattle_call_id].stream
                            userDetails.videoOn=true;
                            userDetails.activeUser=false;
                            userDetails.streamAdded=true;
                        }
                        if (this.state.videoCallUsers[userDetails.cattle_call_id]) {
                            this.state.videoCallUsers[userDetails.cattle_call_id] = Object.assign(this.state.videoCallUsers[userDetails.cattle_call_id], userDetails);
                        } else {
                            this.state.videoCallUsers[userDetails.cattle_call_id] = userDetails;
                        }
                        this.setState({'videoCallUsers': this.state.videoCallUsers,chatUsers:this.state.chatUsers});
                        setTimeout(()=>{
                            if(this.state.userVideoStreams[userDetails.cattle_call_id]) {
                                console.log("iside teh eee remote Cideo", userDetails.cattle_call_id, this.state.userVideoStreams[userDetails.cattle_call_id].stream, this.state.userVideoStreams)
                                let remoteVideoSelector = document.querySelector('#remoteVideoElement_' + userDetails.cattle_call_id + '');
                                remoteVideoSelector.srcObject=null;
                                remoteVideoSelector.srcObject = this.state.userVideoStreams[userDetails.cattle_call_id].stream;

                            }
                        },1000);
                    }
                }
            }
           // this.setState({'groupUsers':groupUsers});
            if(!this.state.meetingDetails.tm_group_id || this.state.meetingDetails.tm_group_id=== ""){
                  if(this.state.videoCallUsers && Object.keys(this.state.videoCallUsers)){
                      this.createGroup(groupUsers);
                  }
            }else{
                if( (!this.state.conversationLoading) && this.state.troopConnected && Object.keys(this.state.meetingDetails).length) {
                    this.getGroupDetails();
                    this.getChatConversation();
                    this.setState({'conversationLoading':true});
                }
            }
        }
    }

    toggleAudio=()=>{
        // this.props.toggleAudio();
        cattlecall.toggleAudio();
       // this.setState({'audioState':!this.state.audioState});
    }
    toggleVideo=()=>{
      //  const activeClass=this.state.videoState;
       // this.setState({'videoState':!activeClass});
        cattlecall.toggleVideo();
        //  this.setState({'videoState':activeClass });

    }

    showAudioControl=()=>{
        cattlecall.toggleAudio();
        this.setState({'audioControl':!this.state.audioControl});
    }
    hideAudioControl=()=>{
        this.setState({'audioControl':false});
    }
    activeUserToVideo=(user)=>{
       // if(''+this.state.meetingDetails.created_by === ''+this.state.loginUserDetails.cattle_call_id) {
            cattlecall.spotlightUser(user);
      //  }

    }
    handleOnChange = (value) => {
        this.setState({
            volume: value
        })
    }
    sendMessage = (message, userDetails) => {
        message.event_id = this.state.eventId;
        message.sender_uid = this.state.loginUserDetails.uid;
        message.group_id = this.state.meetingDetails.tm_group_id;
        console.log(message['receiver_uid'],message);
        if (message.filesPaths && message.filesPaths.length) {
            for (const filepath of message.filesPaths) {
                message.attachment = filepath;
                tmChat.sendAttachment(troopMessenger, message);
            }
        } else {
            tmChat.sendTextMessage(troopMessenger, message);
        }

    };
    closeChat=()=>{
        this.setState({'chatClose':!this.state.chatClose})
    }
    closeUserList=()=>{
        this.setState({'userListClose':!this.state.userListClose})
    }
    openAudioSelection=()=>{
        this.setState({'devicesLoading':true,openVideoDevice:false});
          if(!this.state.openAudioDevice) {
              cattlecall.getDevices((err, response) => {
                        console.log(response);
                  this.setState({"devices": response, devicesLoading: false});

              });
          }
        this.setState({'openAudioDevice': !this.state.openAudioDevice});

    }
    onchangeAudioSelection=(deviceId)=>{
        if(this.state.selectedAudioDevice!==deviceId){
            cattlecall.changeAudioSource(deviceId);
            this.setState({'selectedAudioDevice':deviceId});
        }
        this.setState({'openAudioDevice': !this.state.openAudioDevice});
    }
    openVideoSelection=()=>{
        this.setState({'devicesLoading':true,openAudioDevice:false});
        if(!this.state.openVideoDevice) {
            cattlecall.getDevices((err, response) => {
                console.log(response);
                this.setState({"devices": response, devicesLoading: false});

            });
        }
        this.setState({'openVideoDevice': !this.state.openVideoDevice});

    }
    onchangeVideoSelection=(deviceId)=>{
        if(this.state.selectedVideoDevice!==deviceId){
            cattlecall.changeVideoSource(deviceId);
            this.setState({'selectedVideoDevice':deviceId});
        }
        this.setState({'openVideoDevice': !this.state.openVideoDevice});

    }
    speakerControl=()=>{
              this.setState({"speakerOff":!this.state.speakerOff});
    }
    openMeetingInfo=()=>{
        this.setState({'openMeetingModal':!this.state.openMeetingModal});
    }
    render() {
         if(this.state.loadingMeeting===true){
             return  (<div>Loading....</div>);
         }else if(!this.state.isMeetingStarted){
             return  (<div >Meeting Not Started Please Wait ....</div>);

         }else {
             return (
                 <div className='tmChat conferenceMeeting'>
                 <div
                     className={(this.state.userListClose ? ' userslist-active' : '') + " startmeeting-screen d-lg-flex"}>
                     <div className={(this.state.chatClose ? 'close-chat ' : '') + " startmeeting-leftsection"}>
                         <div className="startmeeting-beforescreen position-relative">
                             <div className="startmeeting-videooff h-100 d-none">
                                 <div className="startmeeting-hostdetails text-center">
                                     <div className="startmeeting-hostpic mb-3">
                                         BN
                                     </div>
                                     <div className="text-white roboto-bold">

                                     </div>
                                 </div>
                             </div>
                             <div className="startmeeting-videoon position-relative">
                                 <div className="startmeeting-hostdetails text-center">
                                     <div className="startmeeting-hostpic mb-3">
                                         <video autoPlay id='localVideo' muted onClick={e=>this.activeUserToVideo((this.state.loginUserDetails?this.state.loginUserDetails.cattle_call_id:0))}/>

                                     </div>
                                     <div className="color-000 roboto-bold">
                                         Me
                                     </div>
                                 </div>
                                 <div
                                     className={(this.state.activeUser ? '' : 'd-none') + " text-center position-relative w-100 conference-video"}>
                                     <video autoPlay id='remoteVideoElement' src='' />
                                     <div
                                         className='startmeeting-talking text-white'>{"Talking " + (this.state.videoCallUsers[this.state.activeUserId] && Object.keys(this.state.videoCallUsers[this.state.activeUserId]).length ? this.state.videoCallUsers[this.state.activeUserId].name : '')}</div>
                                 </div>
                                 {(this.state.activeUser) ?
                                     '' :
                                     <div className="startmeeting-details col-sm-9 col-md-7 col-lg-4 col-xl-4 mx-auto">
                                         <div className="mb-4">
                                             <div className="heading mb-3">
                                                 Meeting Details
                                             </div>
                                             <div className="mb-3">
                                                 Title - {this.state.meetingDetails.title}
                                             </div>
                                             <div className="mb-3">
                                                 Date
                                                 - {moment.utc(this.state.meetingDetails.date_time).local().format("DD MMMM YYYY")}
                                             </div>
                                             <div className="mb-3">
                                                 From Time
                                                 - {moment.utc(this.state.meetingDetails.date_time).local().format("hh:mm a")}
                                             </div>
                                             <div className="mb-3">
                                                 Max Duration - {this.state.meetingDetails.duration}
                                             </div>
                                             <div className="mb-3">
                                                 Meeting Link
                                                 <button className="btn text-white bg-blue py-1 ml-2">
                                                     Copy Link
                                                 </button>
                                             </div>
                                             <div className="mb-3 text-wrap">
                                                 {window.location.origin + process.env.PUBLIC_URL + "/conference-meeting/" + this.state.meetingDetails.room_id + "?pwd=" + this.state.meetingDetails.password}
                                             </div>
                                             <div className="mb-3">
                                                 Meeting ID : {this.state.meetingDetails.room_id}
                                             </div>
                                             <div className="mb-3">
                                                 Code : {this.state.meetingDetails.password}
                                             </div>
                                         </div>

                                     </div>}
                                 <div
                                     className="startmeeting-actionbtns startmeeting-videocall col-sm-9 col-md-7 col-lg-4 col-xl-4 mx-auto">
                                     <div className="meetings-control--dropdown mr-3">
                                         <button className="btn startmeeting-btn startmeeting-btn--record mr-0"
                                                 onClick={this.toggleAudio}>
                                             <img className={this.state.audioState ? '' : 'd-none'}
                                                  src={process.env.PUBLIC_URL + "/images/tmChat/microphone.png"}/>
                                             <img src={process.env.PUBLIC_URL + "/images/tmChat/microphone-off.png"}
                                                  className={this.state.audioState ? 'd-none' : ''}/>
                                         </button>
                                       <div className="dropup d-none">
                                             <button className="btn p-0 meetings-cam--arrowbtn dropdown-toggle" type="button"
                                                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.openAudioSelection}>
                                                 <i className="fa fa-chevron-up color-888"/>
                                             </button>
                                           {this.state.openAudioDevice?
                                             <div className="meetings-cam--dropdown dropdown-menu-right"
                                                  aria-labelledby="dropdownMenuButton">
                                                 <div
                                                     className="d-flex align-items-center justify-content-between px-3 py-2">
                                                     <div className="settings-subsection color-lightgreen mb-0">
                                                         Select Audio Device
                                                     </div>
                                                     <span className="d-inline-block"/>
                                                 </div>
                                                 {!this.state.devicesLoading && this.state.devices.audio?this.state.devices.audio.map((audio,index)=>{
                                                    return( <div className="dropdown-item px-3 py-2 pointer" key={index} onClick={e=>this.onchangeAudioSelection(audio.deviceId)}>
                                                         <div className="d-flex justify-content-between align-items-center">
                                                             <div className="device-name">
                                                                 {audio.label}
                                                             </div>
                                                             {audio.deviceId===this.state.selectedAudioDevice || (this.state.devices.audio.length===1)?<i className="fa fa-check-circle"/>:''}
                                                         </div>
                                                     </div>);
                                                 }):(this.state.devicesLoading?'Loading':'')}

                                             </div>:''}
                                         </div>
                                     </div>
                                     <div className="meetings-control--dropdown mr-3">
                                     <button className="btn startmeeting-btn mr-0" onClick={this.toggleVideo}>
                                         <img className={this.state.videoState ? '' : 'd-none'}
                                              src={process.env.PUBLIC_URL + "/images/tmChat/video.png"}/>
                                         <img className={this.state.videoState ? 'd-none' : ''}
                                              src={process.env.PUBLIC_URL + "/images/tmChat/video_off.png"}/>
                                     </button>
                                        <div className="dropup d-none">
                                             <button className="btn p-0 meetings-cam--arrowbtn dropdown-toggle" type="button"
                                                     data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.openVideoSelection}>
                                                 <i className="fa fa-chevron-up color-888"/>
                                             </button>
                                            {this.state.openVideoDevice?   <div className="meetings-cam--dropdown dropdown-menu-right"
                                                  aria-labelledby="dropdownMenuButton">
                                                 <div
                                                     className="d-flex align-items-center justify-content-between px-3 py-2">
                                                     <div className="settings-subsection color-lightgreen mb-0">
                                                         Select Camera Device
                                                     </div>
                                                     <span className="d-inline-block">
                                        </span>
                                                 </div>
                                                {!this.state.devicesLoading && this.state.devices.video?this.state.devices.video.map((video,index)=>{
                                                    return( <div className="dropdown-item px-3 py-2 pointer" key={index} onClick={e=>this.onchangeVideoSelection(video.deviceId)}>
                                                        <div className="d-flex justify-content-between  align-items-center">
                                                            <div className="device-name">    {video.label}</div>
                                                            {video.deviceId===this.state.selectedVideoDevice || (this.state.devices.video.length===1)?<i className="fa fa-check-circle"/>:''}
                                                        </div>
                                                    </div>);
                                                }):(this.state.devicesLoading?'Loading':'')}
                                             </div>:''}
                                         </div>
                                     </div>
                                     <button className="btn startmeeting-btn" onClick={this.startScreenShareing}>
                                         <img src={process.env.PUBLIC_URL + "/images/tmChat/screenshare.png"}/>
                                     </button>
                                     <button className=" btn startmeeting-btn startmeeting-volumebtn" onClick={this.speakerControl}>
                                         {this.state.speakerOff?<img src={process.env.PUBLIC_URL + "/images/tmChat/speaker_off.png"} alt=''/>:<img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/speaker.png"} />}
                                         <span className="position-absolute speaker-range"/>
                                     </button>
                                     <button className="btn startmeeting-btn d-none" data-toggle="modal"
                                             data-target="#addusersModal">
                                         <img src={process.env.PUBLIC_URL + "/images/tmChat/add-user.png"}/>
                                     </button>
                                     <button className="btn startmeeting-btn d-none">
                                         <img src={process.env.PUBLIC_URL + "/images/tmChat/recording.png"}/>
                                     </button>
                                     <button onClick={this.endCall}
                                             className="btn startmeeting-btn startmeeting-btn--end">
                                         <img src={process.env.PUBLIC_URL + "/images/tmChat/endcall.png"}/>
                                     </button>
                                     {this.state.activeUser?<button className='btn startmeeting-btn' onClick={this.openMeetingInfo}><i className='fa fa-info'/></button> :''}

                                     {this.state.chatClose ?
                                         <button onClick={this.closeChat} className="btn startmeeting-btn bg-white">
                                             <img src={process.env.PUBLIC_URL + "/images/tmChat/chat_floatbtn.png"}/>
                                         </button> : ''}
                                     {this.state.userListClose ? <button onClick={this.closeUserList}
                                                                         className="btn startmeeting-btn bg-white">
                                         <img src={process.env.PUBLIC_URL + "/images/tmChat/users_floatbtn.png"}/>
                                     </button> : ''
                                     }
                                 </div>
                             </div>
                             <div className="tsl-logo">
                                 <img src={process.env.PUBLIC_URL + "/images/tsl.png"} className="img-fluid"/>
                             </div>
                         </div>
                     </div>
                     {this.state.chatClose ? '' : <VideoChat
                         userInfo={this.state.callUserInfo}
                         loginUserDetails={this.state.userDetails}
                         onSendMessage={this.sendMessage}
                         chatConversations={this.state.chatConversation}
                         troopConnection={(this.state.troopMessengerConnection ? this.state.troopMessengerConnection : {})}
                         closeChat={this.closeChat}

                     />}
                 </div>
                 <div
                     className={(this.state.userListClose ? 'd-none' : '') + " startmeeting-userslist mCustomScrollbar"}
                     data-mcs-theme="minimal-dark">
                     <button className="btn startmeeting-chat--close" onClick={this.closeUserList}><img
                         src={process.env.PUBLIC_URL + "/images/tmChat/close.png"}
                         className="img-fluid"/></button>
                     {this.state.videoCallUsers && Object.keys(this.state.videoCallUsers) ?
                         <div className="p-2 startmeeting-users-found">
                             {Object.values(this.state.videoCallUsers).map((user, index) => {

                                 return (<div className="text-center startmeeting-usersbox" key={index}
                                              onClick={e => this.activeUserToVideo(user.cattle_call_id)}>
                                     <div className="startmeeting-users">

                                         <div className="roboto-bold startmeeting-username"
                                              data-id={user.cattle_call_id}>
                                             {(this.state.activeUserId === user.cattle_call_id)?  '':<video autoPlay id={'remoteVideoElement_' + user.cattle_call_id}
                                                                                                            className={user.videoOn ? ((this.state.activeUserId === user.cattle_call_id) ? 'd-none' : '') : 'd-none'}
                                                                                                            ref={video => this.setSrcObject(video, user.stream)} muted={this.state.speakerOff}/>}
                                             {!user.videoOn || (this.state.activeUserId === user.cattle_call_id) ? (user.profilePic ?
                                                 <img src={user.profilePic} className='user-profile-pic'
                                                      alt='image'/> : utility.acronym(user.name)
                                                     .toUpperCase()
                                                     .slice(0, 2)) : ''
                                             }

                                         </div>

                                         <div className="startmeeting-usersactions">
                                             <button className="btn p-1 startmeeting-users--actionbtn"
                                                     onClick={e => this.muteParticipants(e, user.cattle_call_id)}>
                                                 {user.muteAudio ?
                                                     <img src={process.env.PUBLIC_URL + "/images/tmChat/mute.svg"}
                                                          className="img-fluid"/> : <img
                                                         src={process.env.PUBLIC_URL + "/images/tmChat/microphone-red.png"}
                                                         className="img-fluid"/>}
                                             </button>
                                             <button className="btn p-1 startmeeting-users--actionbtn"
                                                     onClick={e => this.videoffParticipants(e, user.cattle_call_id)}>
                                                 {user.muteVideo ? <img
                                                         src={process.env.PUBLIC_URL + "/images/tmChat/video_slash.svg"}
                                                         className="img-fluid"/> :
                                                     <img src={process.env.PUBLIC_URL + "/images/tmChat/video-red.png"}
                                                          className="img-fluid"/>}
                                             </button>
                                         </div>
                                     </div>
                                     <div className="color-000 roboto-bold">
                                         {user.name} ({(user.streamAdded ? 'Connected' : 'Not Joined')})
                                     </div>
                                 </div>);

                             })}
                         </div> : <div className="startmeeting-nouser-found">
                             <div className="heading">
                                 No User Found
                             </div>
                             <div className="mb-2">
                                 Send Invitaion by
                             </div>
                             <div className="d-flex align-items-center">
                                 <button className="btn text-white bg-blue py-1">
                                     Copy Link
                                 </button>
                                 <div className="d-inline-block mx-2">
                                     or
                                 </div>
                                 <button className="btn text-white bg-blue py-1" data-toggle="modal"
                                         data-target="#addusersModal">
                                     Add User
                                 </button>
                             </div>
                         </div>}

                 </div>
                 {this.state.openMeetingModal ?
                     <React.Fragment>
                         <div className="modal-backdrop show"></div>
                         <div className="modal d-block" id="addmeetingModal">
                             <div className="modal-dialog  modal-dialog-centered">
                                 <div className="modal-content">
                                     <div className="modal-header pl-lg-5">
                                         <h5 className="modal-title">Meeting Info</h5>

                                             <button type="button" className="close" data-dismiss="modal"
                                                     aria-label="Close" onClick={this.openMeetingInfo}>
                                                 <span aria-hidden="true">&times;</span>
                                             </button>
                                     </div>
                                     <div className="modal-body px-lg-5">
                                         <div className="meeting-details-modal col-sm-9 col-md-7 col-lg-4 col-xl-4 mx-auto">
                                             <div className="mb-4">
                                                 <div className="heading mb-3">
                                                     Meeting Details
                                                 </div>
                                                 <div className="mb-3">
                                                     Title - {this.state.meetingDetails.title}
                                                 </div>
                                                 <div className="mb-3">
                                                     Date
                                                     - {moment.utc(this.state.meetingDetails.date_time).local().format("DD MMMM YYYY")}
                                                 </div>
                                                 <div className="mb-3">
                                                     From Time
                                                     - {moment.utc(this.state.meetingDetails.date_time).local().format("hh:mm a")}
                                                 </div>
                                                 <div className="mb-3">
                                                     Max Duration - {this.state.meetingDetails.duration}
                                                 </div>
                                                 <div className="mb-3">
                                                     Meeting Link
                                                     <button className="btn text-white bg-blue py-1 ml-2">
                                                         Copy Link
                                                     </button>
                                                 </div>
                                                 <div className="mb-3 text-wrap">
                                                     {window.location.origin + process.env.PUBLIC_URL + "/conference-meeting/" + this.state.meetingDetails.room_id + "?pwd=" + this.state.meetingDetails.password}
                                                 </div>
                                                 <div className="mb-3">
                                                     Meeting ID : {this.state.meetingDetails.room_id}
                                                 </div>
                                                 <div className="mb-3">
                                                     Code : {this.state.meetingDetails.password}
                                                 </div>
                                             </div>

                                         </div>
                                     </div>
                                     <div className="modal-footer px-lg-5">

                                     </div>
                                 </div>
                             </div>
                         </div>
                     </React.Fragment> : ''}
             </div>)
         }
    };
}
export  default withRouter(ConferenceMeeting)