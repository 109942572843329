import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import io  from 'socket.io-client';
import Axios from 'axios';
//import MySchedule from './Attendee/MySchedule';
import jwt_decode from 'jwt-decode';
import MainNav from './Attendee/MainNav';
import Clock from 'react-live-clock';
const socket = io.connect('https://tslvirtual-chat.herokuapp.com');



class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            dropMenu:false,
            quickDropMenu:false,
            allowAsExhibitor:false,
            allowAsSpeaker : false,
            allowAsLobbier : true,
            myScheduleModal : false,
            mobiletoggleClass:''
        }
    }
    logOutSingleSignOn = (e) => {
       
        e.preventDefault();
        const decoded = jwt_decode(localStorage.getItem('exhibitorToken'));
         var eventId = localStorage.getItem('eventId');
         var eventName = localStorage.getItem('eventName');
         localStorage.clear();
         localStorage.setItem('eventName',eventName);
         this.props.history.push(`/security22/loginSingleSignOn/`+eventId);
        
     }
    logOut = (e) => {
       
       e.preventDefault();
       const decoded = jwt_decode(localStorage.getItem('exhibitorToken'));
       if (Date.now() >= decoded.exp * 1000) {
        var eventId = localStorage.getItem('eventId');
        var eventName = localStorage.getItem('eventName');
        localStorage.clear();
        localStorage.setItem('eventName',eventName);
        this.props.history.push(`/focus2022/login/`+eventId);
       }

       this.yesLogOut();
    }
    mobiletoggleClassHandler = () => {
        this.setState({mobiletoggleClass:'active'})
        if(this.state.mobiletoggleClass === 'active'){
            this.setState({mobiletoggleClass:''})
        }
      }
    // myScheduleModalHandler = (e) => {
    //     e.preventDefault();
    //     this.setState({myScheduleModal:!this.state.myScheduleModal})
    //   }
        
    yesLogOut = () => {
        this.setState({logOutBeforeClaimPopup:false});
        const logoutData = {
            email: localStorage.getItem('userName'),
            eventId:localStorage.getItem('eventId'),
        }
        Axios.post(`${global.config.requestUrl}user/logoutUser`, logoutData,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        ).then(res => {
        var eventId = localStorage.getItem('eventId');
        var eventName = localStorage.getItem('eventName');
         /** */
        // socket.emit('usersCount', eventId);
        // socket.on('users', function(message) {
        //  console.log(message);
        //  if(document.getElementById("total_online"))
        //    document.getElementById('total_online').innerHTML = 'Total Online: '+message[0].total_count;
        // });
        /** */
        localStorage.clear();
        localStorage.setItem('eventName',eventName);
        this.props.history.push(`/focus2022/login/`+eventId);
        })
        .catch(err => {
        console.log(err);
        });
    }
   
    dropMenuToggle = e => {
        this.setState({dropMenu:!this.state.dropMenu, quickDropMenu:false})
    }
    quickDropMenuToggle = e => {
        /* e.preventDefault(); */
        this.setState({quickDropMenu:!this.state.quickDropMenu, dropMenu:false})
    }
    componentDidMount(){
        // this.callSocketForOnlineUsers();
        // this.callSocketForLogoutCurrentUser(this.props)
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (localStorage.getItem('companyContactUrl')) {
            localStorage.getItem('companyContactUrl')
        }
    }
    // componentWillReceiveProps(props) {
    //     if(localStorage.getItem('exhibitorToken')){
    //         this.callSocketForOnlineUsers();
    //         this.callSocketForLogoutCurrentUser(props);
    //     }
           
    // }
    /* callSocketForLogoutCurrentUser function is useful when developer want to force logout all the users from frontend,
    *  this can be done if we set all the users to set 0 in field islogin in regaccesscode table.
    */
    // callSocketForLogoutCurrentUser = (currentProp) => {
        
    //     socket.on('logoutUsers', function(message) {
    //         var eventId = localStorage.getItem('eventId');
    //         var eventName = localStorage.getItem('eventName');
    //         localStorage.clear();
    //         localStorage.setItem('eventName',eventName);
    //         localStorage.setItem('eventId',eventId);
    //         currentProp.history.push(`/focus2022/login/${eventId}`)
    //     });
    // }

    // callSocketForOnlineUsers = () => {
    //     socket.emit('usersCount', localStorage.getItem('eventId'));
    //     socket.on('users', function(message) {
    //      if(document.getElementById("total_online"))
    //        document.getElementById('total_online').innerHTML = 'Total Online: '+message[0].total_count;
    //     });
    // }
    logOutBeforeClaimPopupHandler =() =>{
        this.setState({logOutBeforeClaimPopup:!this.state.logOutBeforeClaimPopup})
    }
    deleteCurrentUserFromSession = (eventId, userId) =>{
        const {match : {params}} = this.props;
            const currentUsersData = {
                userId: userId,
                eventId: eventId,
            }
        Axios.post(`${global.config.requestUrl}user/deleteSessionUser`, currentUsersData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }
    render() {
        var rolesArr = [];
        var pathArray = window.location.pathname.split('/');
        if(localStorage.getItem('roles')){
             rolesArr = localStorage.getItem('roles').split(",");
             var pImg = process.env.PUBLIC_URL + '/images/placeholder-profile.jpg';
             var editProfileUrl = window.location.pathname;

            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null ))
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-10' && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null)
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-6' && localStorage.getItem('speakerProfilePic') !== '' && localStorage.getItem('speakerProfilePic') !== null)
                pImg = localStorage.getItem('speakerProfilePic');
            else if(localStorage.getItem('currentRole') === '-1' && localStorage.getItem('companyContactUrl') !== '' && localStorage.getItem('companyContactUrl') !== null)
                pImg = localStorage.getItem('companyContactUrl');

            
            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 ) || localStorage.getItem('currentRole') === '-10')
                editProfileUrl = '/focus2022/user/profile';
            else if(localStorage.getItem('currentRole') === '-6')
                editProfileUrl = '/focus2022/speaker/profile';
            else if(localStorage.getItem('currentRole') === '-1')
                editProfileUrl = '/focus2022/exhibitor/profile';

            
        }
        const userNotLoggedIn = (
            <div id="collapsibleNavbar" className={this.state.mobiletoggleClass}>
                <ul className="navbar-nav ml-auto">
                    {/* {
                        localStorage.getItem("exhibitorToken")
                        ?
                        <li className="nav-item"><a href="#" onClick={this.myScheduleModalHandler}><i class="fa fa-eye"></i> View my Schedule</a></li>
                        :
                           window.location.pathname.split('/')[2] !== 'loginSingleSignOn'
                           ?
                           <li className="nav-item"><Link to={`/focus2022/login/${window.location.pathname.split('/')[3]}`}><i class="fa fa-user"></i> Login </Link></li>
                           :
                           null
                    } */}
                    {/* <li className="nav-item"><a href="https://www.common.org/powerup21/registration"  target="_blank"><i class="fa fa-user-plus"></i> Register for the Conference</a></li> */}
                    {/* <li className="nav-item"><a href="http://www.powerup21.com" target="_blank"><i class="fa fa-info-circle"></i> Learn More </a></li> */}
                </ul>
            </div>
        );
        const userLoggedIn = (
            <React.Fragment>
                <div id="collapsibleNavbar" className={this.state.mobiletoggleClass}>
                    <ul className="navbar-nav ml-auto">
                    {/* {
                        localStorage.getItem("exhibitorToken")
                        ?
                        <li className="nav-item"><a href="#" onClick={this.myScheduleModalHandler}><i class="fa fa-eye"></i> View my Schedule</a></li>
                        :
                        <li className="nav-item"><Link to={`/focus2022/login/${window.location.pathname.split('/')[3]}`}><i class="fa fa-user"></i> Login</Link></li>
                    } */}
                    <li className="nav-item"><a href="https://www.common.org/powerup21/registration"  target="_blank"><i class="fa fa-user-plus"></i> Register for the Conference</a></li>
                    <li className="nav-item"><a href="http://www.powerup21.com" target="_blank"><i class="fa fa-info-circle"></i> Learn More </a></li>
                    <li className="nav-item">
                        <Link to="/" onClick={this.logOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>
                       
                    </li>
                    </ul>
                </div>
            </React.Fragment>
        );
        
        
        const userLoggedInSingleSignOn = (
            <React.Fragment>
                {
                    window.location.pathname.split('/')[2] !=='loginSplash'  ? <MainNav/> : ''
                }
                
                <div id="collapsibleNavbar">
                    <div className="help-system-logo">
                        <img src={process.env.PUBLIC_URL + '/images/security22/HelpSystems.png'} alt="" />
                    </div>
                    <ul className="navbar-nav ml-auto">
                    <li className="nav-item clockTicking"><p>Conference Time</p>
                        <Clock format={'HH:mm:ss'} ticking={true} timezone={'America/Matamoros'} />
                    </li>
                    
                    {/* <li className="nav-item userProfilePic">
                        <img src={pImg} alt="" id="profile_img" />
                    </li>  */}
                    <li className="nav-item">
                        <button onClick={this.dropMenuToggle}>
                            <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                        </button>
                        {
                            this.state.dropMenu 
                            ?
                            <ul className="dropMenu">

                                { 
                                        rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1
                                        ?
                                        (<li onClick={this.dropMenuToggle}><Link to="/focus2022/exhibitor/dashboard"><i className="fa fa-user"></i> Exhibitor Dashboard</Link></li>
                                        ):null
                                }
                                { 
                                        rolesArr.indexOf('-6') !== -1
                                        ?
                                        (<li onClick={this.dropMenuToggle}><Link to="/focus2022/speaker/dashboard"><i className="fa fa-user"></i> Speaker Dashboard</Link></li>
                                        ):null
                                }
                                
                                {/* <li onClick={this.dropMenuToggle}><Link to="/focus2022/attendee/lobby"><i className="fa fa-user"></i> Attendee Lobby</Link></li>
                                {
                                    editProfileUrl !== '/focus2022/loginSplash'
                                    ?
                                    <li><Link onClick={this.dropMenuToggle} to={editProfileUrl} ><i className="fa fa-user" aria-hidden="true"></i> Edit Profile</Link></li>
                                    :
                                    null
                                } */}

                                

                                <li onClick={this.dropMenuToggle}>
                                    <Link to="/" onClick={this.logOutSingleSignOn}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link>

                                   
                                    
                                    </li>
                            </ul>
                            :
                            null
                        }
                    </li>
                    </ul>
                </div>
            </React.Fragment>
        );

        if(localStorage.getItem('singleSignOn') === "1"){
            
            return (
                <React.Fragment>
                    <header className="pageMainHeader">
                    <nav className="navbar navbar-expand-sm" id="navMenu">
                        <div className="container-fluid header-logo-1">
                            <Link className="navbar-brand" to="/security22/attendee/technicalSessions">
                                <img src={process.env.PUBLIC_URL + '/images/security22/common.png'} alt="" />
                            </Link>
                            <div className="header-security-logo">
                                <img src={process.env.PUBLIC_URL + "/images/security22/Logo.jpg"} />
                                <div><h2>Security Conference</h2></div>
                            </div>
                            {localStorage.getItem("exhibitorToken") ? userLoggedInSingleSignOn : ''}
                        </div>
                    </nav>
                    </header>
                    {/* {
                        this.state.myScheduleModal
                        ?
                        <div className="midModal">
                            <div className="outerModal"  onClick={this.myScheduleModalHandler}></div>
                            <div className="midContentWrp">
                                <div className="modalPopUpClose" id="myschedulepopup" onClick={this.myScheduleModalHandler}><i className="fa fa-times"></i></div>
                                <MySchedule props={this.props} />                             
                            </div>
                        </div>
                        :
                        null
                    } */}
                </React.Fragment>
            )

        }else{

            return (
                <React.Fragment>
                    <header className="pageMainHeader">
                        <nav className="navbar navbar-expand-sm" id="navMenu">
                            <div className="container-fluid responsive-header">
                                <Link className="navbar-brand" to="/security22/attendee/technicalSessions">
                                    <img src={process.env.PUBLIC_URL + '/images/security22/common.png'} alt="" />
                                </Link>
                                <div className="header-security-logo" style={{paddingLeft: '0px', justifyContent: 'center'}}>
                                    <img src={process.env.PUBLIC_URL + "/images/security22/Logo.jpg"} />
                                    <div><h2>Security Conference</h2></div>
                                </div>
                                <div className="help-system-logo">
                                    <img src={process.env.PUBLIC_URL + '/images/security22/HelpSystems.png'} alt="" />
                                </div>
                                {/* <div className="mobileMenuToggle"  onClick={this.mobiletoggleClassHandler}>
                                    <div className="mobile">
                                        <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                                    </div>
                                    {localStorage.getItem("exhibitorToken") ? userLoggedIn : userNotLoggedIn} 
                                </div> */}
                                
                            </div>
                        </nav>
                    </header>
                    {/* {
                        this.state.myScheduleModal
                        ?
                        <div className="midModal">
                            <div className="outerModal"  onClick={this.myScheduleModalHandler}></div>
                            <div className="midContentWrp">
                                <div className="modalPopUpClose" id="myschedulepopup" onClick={this.myScheduleModalHandler}><i className="fa fa-times"></i></div>
                                <MySchedule props={this.props} />                             
                            </div>
                        </div>
                        :
                        null
                    } */}
                </React.Fragment>
            )
            
        }

        
    }
}
export default withRouter(Header);