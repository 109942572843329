import React, { Component } from 'react';
import Axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import {storage} from '../../../Firebase';
import LeftNavBar from './LeftNavBar';



// TODO: Switch to https://github.com/palmerhq/the-platform#stylesheet when it will be stable
/* const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink); */

const initialState = {  
    company : "",
    companyError : "",
    firstName: "",
    firstNameError : "",
    lastName:"",
    lastNameError : "",
    address1: "",
    address1Error : "",
    address2:"",
    address2Error : "",
    city: "",
    cityError : "",
    state:"",
    stateError : "",
    zip: "",
    zipError : "",
    country:"",
    countryError : "",
    phone: "",
    phoneError : "",
    email:"",
    emailError : "",
    website: "",
    websiteError : "",
    /* categories:"",
    categoriesError : "", */
    title:"",

    category:"",
    
    categoriesOptions: [],
    /* currentValues:[], */
    
    image: null,
    imageUrl:'',
    progress:0,

    companyLogo: null,
    companyLogoUrl:'',

    imageError : "",
    companyData: [],
    exhibitorId : localStorage.getItem('userId'),
    eventId : localStorage.getItem('eventId'),
    pageLoader:true,
    authToken : localStorage.getItem('exhibitorToken'),
    submitError : '',
    submitSuccess : '',
    somethingWrongData: false,
  }
class ExhibitorDetails extends Component {
    constructor(props){
        super(props);

        this.state = initialState;

    }
   
    /*===Company Form Validations function===*/
    validateForm = () => {
        let companyError = "";
        let firstNameError = "";
        let lastNameError = "";
        let address1Error = "";
        let address2Error = "";
        let cityError = "";
        let stateError = "";
        let zipError = "";
        /* let countryError = ""; */
        let phoneError = "";
        let emailError = "";
        let websiteError = "";
        let categoriesError = "";
        /* let imageError = ""; */

        /* if(!this.state.email ){
        emailError = "Email Can't be blank" 
        } */
        

        if(!this.state.company){
            companyError = "Invalid Company Name"
        }
        if(!this.state.firstName){
            firstNameError = "Invalid Contact First Name"
        }
        if(!this.state.lastName){
            lastNameError = "Invalid  Contact Last Name"
        }
        if(!this.state.address1){
            address1Error = "Invalid Address 1"
        }
        
        if(!this.state.city){
            cityError = "Invalid City Name"
        }
        if(!this.state.zip){
            zipError = "Invalid ZIP Code"
        }
        if(!this.state.state){
            stateError = "Invalid ST Name"
        }
      
        if(this.state.phone === ''){
            phoneError = "Invalid Phone Number"
        }

        console.log(this.state.phone);
        if(this.state.email !== "undefined"){
            let lastAtPos = this.state.email.lastIndexOf('@');
            let lastDotPos = this.state.email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
                emailError= "Invalid Email"
            }
        } 
        if(!this.state.website){
            websiteError = "Invalid Website Name"
        }
         if(this.state.currentValues.length < 2){
            categoriesError = "Please select atleast one category"
         }
        /* if(!this.state.image){
            imageError = "Choose Contact Pic"
        } */
        if(companyError || firstNameError || lastNameError || address1Error || cityError || stateError || zipError || phoneError || emailError || websiteError || categoriesError){
        this.setState({companyError, firstNameError, lastNameError, address1Error, cityError, stateError, zipError, phoneError, emailError, websiteError, categoriesError});
        return false;
        }
        
        return true;

    }


    /*===Add Default category function===*/
    handleAddition = (e, { value }) => {
        this.setState((prevState) => ({
        options: [{ text: value, value }, ...prevState.options],
        }))
    }
    /*===Handle Category value function===*/
     categoryHandleChange = (e, { value }) => this.setState({ currentValues: value })

    /*===Form input field Handle function===*/
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log(777);
    }
    /*===Upload contact pic Handle function===*/
    contactPicImageChange = (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            this.setState(() => ({image}))
        }
    }
    /*===company logo Handle function===*/
    companyLogoChange = (e) => {
        if(e.target.files[0]){
            const companyLogo = e.target.files[0];
            this.setState(() => ({companyLogo}))
        }
    }


    /*===Company Form Submit function===*/
    companyInfoSubmit = (e) => {
        e.preventDefault();
        
 
        const formValidate = this.validateForm();
        if(formValidate){
            this.setState({pageLoader:true});
        }
        
        /*===image upload function===*/
        if(this.state.image !== null){
            const {image, exhibitorId, eventId} = this.state;
            // Create a storage reference from our storage service
            var storageRef = storage.ref();
            const uploadTask = storageRef.child(`images/${eventId}/${exhibitorId}/${image.name}`).put(image);
            uploadTask.on('state_changed', 
            (snapshot) => {
                //progress func
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                this.setState({progress})
                // console.log(progress)
                if(progress !== 100){
                   /* this.setState({pageLoader:true}) */
                }
            }, 
            (error) => {
                //error func
                console.log(error)
            }, 
            () => {
                //complete func  
                storage.ref(`images/${eventId}/${exhibitorId}`).child(image.name).getDownloadURL().then(url=>{
                    //console.log(url)
                    this.setState({imageUrl:url})
                    localStorage.setItem("companyContactUrl", url);
                    if(url){
                        this.submitForm();
                    }
                })
            });
        }else{
            this.submitForm();
        }

        /*===Company Logo upload function===*/
        if(this.state.companyLogo !== null){
            const {companyLogo, exhibitorId, eventId} = this.state;
            // Create a storage reference from our storage service
            var storageRef = storage.ref();
            const uploadTask = storageRef.child(`images/${eventId}/${exhibitorId}/${companyLogo.name}`).put(companyLogo);
            uploadTask.on('state_changed', 
            (snapshot) => {
                //progress func
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                this.setState({progress})
                // console.log(progress)
                if(progress !== 100){
                   /* this.setState({pageLoader:true}) */
                }
            }, 
            (error) => {
                //error func
                console.log(error)
            }, 
            () => {
                //complete func  
                storage.ref(`images/${eventId}/${exhibitorId}`).child(companyLogo.name).getDownloadURL().then(url=>{
                    //console.log(url)
                    this.setState({companyLogoUrl:url})
                    localStorage.setItem("companyLogoUrl", url);
                    if(url){
                        this.submitForm();
                    }
                })
            });
        }else{
            this.submitForm();
        }
        
        /* console.log(this.state) */
   
    }
    

    submitForm = () => {
        const formValidate = this.validateForm();
        /*===update exhibitor API function===*/
        //console.log(typeof this.state.value)
        let cateVal = this.state.currentValues;
        //console.log(this.state.imageUrl, "imageUrl")

        if(localStorage.getItem('exhibitorId')){
            var userId =  localStorage.getItem('exhibitorId');
        }else{
            var userId =  localStorage.getItem('userId');
        }

        const companyDetails = {
            company:this.state.company,
            firstName: this.state.firstName.trim(),
            lastName:this.state.lastName.trim(),
            address1: this.state.address1,
            address2:this.state.address2,
            city: this.state.city,
            state:this.state.state,
            zip: this.state.zip,
            country:this.state.country,
            phone: this.state.phone,
            email:this.state.email,
            website: this.state.website,
            category: JSON.stringify(cateVal) ,
            exhibitorId: userId,
            eventId: localStorage.getItem('eventId'),
            image:this.state.imageUrl,
            logo:this.state.companyLogoUrl,
            title:this.state.title
        }
        if(formValidate){

            Axios.post(`${global.config.requestUrl}exhibitor/updateExhibitorInfo`, companyDetails,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                 if(res.data.errorCode === 0){
                    this.setState({submitError:'' ,submitSuccess:res.data.errorMessage, pageLoader:false})
                    document.getElementById("profile_img").src = localStorage.getItem('companyContactUrl');
                    
                }else{
                    this.setState({submitError:res.data.errorMessage, submitSuccess:'', pageLoader:false})
                }
                /* removing errors */
                this.setState({companyError:'', firstNameError:'', lastNameError:'', address1Error:'', cityError:'', stateError:'', zipError:'', phoneError:'', emailError:'', websiteError:'', categoriesError:''});
                
            })
            .catch(err => {
                console.log(err);
            });  
            
            
        }
    }
    
    componentDidMount(){
       
        /*===Get ExhibitorInfoAPI function===*/

        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1){
                this.props.history.push('/vcd/notfound');
                return false;
            } 
        }

        
        if(localStorage.getItem('exhibitorId')){
            var userId =  localStorage.getItem('exhibitorId');
        }else{
            var userId =  localStorage.getItem('userId');
        }

        Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorInfo?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
            {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            /* console.log(res.data.data) */
            if(res.data.errorCode > 0 ){
                this.setState({somethingWrongData:true})
                
            }
            this.setState({
                companyData:res.data.data[0],
                company:res.data.data[0].company,
                firstName: res.data.data[0].firstName,
                lastName:res.data.data[0].lastName,
                address1: res.data.data[0].address1,
                address2:res.data.data[0].address2,
                city: res.data.data[0].city,
                state:res.data.data[0].state,
                zip: res.data.data[0].zip,
                country:res.data.data[0].country,
                phone: res.data.data[0].phone,
                email:res.data.data[0].email,
                website: res.data.data[0].website,
                category:res.data.data[0].category ,
                imageUrl:res.data.data[0].image,
                companyLogoUrl:res.data.data[0].logo,
                title: res.data.data[0].title,
                pageLoader:false,
            })

            let stringData = this.state.category;
            let KeyVal = stringData.split(",");
            let objVal = [];
            for (var i = 0; i < KeyVal.length; i++) {
                objVal.push(parseInt(KeyVal[i]));
            }
            this.setState({currentValues:objVal})


            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
        
        
        /*===Get Category List API function===*/
        const eventId = this.state.eventId;
        Axios.get(`${global.config.requestUrl}exhibitor/getEventCategoryList?eventId=${localStorage.getItem('eventId')}` ,
            {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            
        )
        .then(res => {
            //console.log(res.data.data.map(catVal => ( catVal.value )), 'console value')
            this.setState({categoriesOptions:res.data.data, value:res.data.data.value,})
            //console.log(this.state.categoriesOptions)
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });

       
    }
    componentWillUpdate(){
        
    }

    render() {
        const { currentValues } = this.state

        return (
            <React.Fragment>
                <section className="bg8 signleScreen compnayInfo">
                <LeftNavBar />
                {
                    this.state.somethingWrongData 
                    ?
                    <div className="somethingWrong">
                        <h1>Something Went Wrong</h1>
                    </div>
                    :
                    null
                }
                
                    <div className="container pt-120">
                    <form onSubmit={this.companyInfoSubmit} noValidate autoComplete="off">
                        <div className="companyInfoForm">
                        <div className="row">
                            <div className="col-12">
                                <div className="formHeader">
                                    Company Information
                                </div>
                                <div className="error text-center">{this.state.submitError}</div>
                                <div className="success text-center">{this.state.submitSuccess}</div>
                            </div>
                            <div className="col-lg-3 col-md-12 pr-0">
                                <div className="formProfile">
                                    <div className="formProfilePic">
                                        {
                                            this.state.imageUrl !==''
                                            ?
                                            <div className="profile"> <img src={this.state.imageUrl} height="80" alt="" /></div>
                                            :
                                            null
                                        }
                                    </div>
                                    <div className="formProfileBtn">
                                        <div className="form-group">
                                            <label>Main Contact Pic</label>
                                            <input type="file" className="form-control" name="image" onChange={this.contactPicImageChange} accept="image/x-png,image/gif,image/jpeg"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="formProfile">
                                    <div className="formProfilePic">
                                    {
                                        this.state.companyLogoUrl !==''
                                        ?
                                        <div className="profile"> <img src={this.state.companyLogoUrl} height="80" alt="" /></div>
                                        :
                                        null
                                      }
                                    </div>
                                    <div className="formProfileBtn">
                                        <div className="form-group">
                                            <label>Company Logo</label>
                                            <input type="file" className="form-control" name="companyLogo"  accept="image/x-png,image/gif,image/jpeg" onChange={this.companyLogoChange}/>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-9 pr-5">
                                    
                                        <div className="row">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Company Name <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="company" id="companyid"  onChange={this.onChange}  value={this.state.company} autoComplete="off" disabled={true}/>
                                                    <div className="errorMessage">{this.state.companyError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Main Contact First <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="firstName" id="firstNameid"  onChange={this.onChange} value={this.state.firstName} autoComplete="off"/>
                                                    <div className="errorMessage">{this.state.firstNameError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Main Contact Last <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="lastName" id="lastNameid"  onChange={this.onChange} value={this.state.lastName}/>
                                                    <div className="errorMessage">{this.state.lastNameError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Address 1 <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="address1" id="address1id"  onChange={this.onChange} value={this.state.address1}/>
                                                    <div className="errorMessage">{this.state.address1Error}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Address 2 </label>
                                                    <input type="text" className="form-control" name="address2" id="address2id"  onChange={this.onChange} value={this.state.address2}/>
                                                    
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>City <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="city" id="cityid"  onChange={this.onChange} value={this.state.city}/>
                                                    <div className="errorMessage">{this.state.cityError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>ST <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="state" id="stateid"  onChange={this.onChange} value={this.state.state}/>
                                                    <div className="errorMessage">{this.state.stateError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>ZIP <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="zip" id="zipid"  onChange={this.onChange} value={this.state.zip}/>
                                                    <div className="errorMessage">{this.state.zipError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Country </label>
                                                    <input type="text" className="form-control" name="country" id="countryid"  onChange={this.onChange} value={this.state.country}/>
                                                    {/* <div className="errorMessage">{this.state.countryError}</div> */}
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Phone <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="phone" id="phoneid"  onChange={this.onChange} value={this.state.phone}/>
                                                    <div className="errorMessage">{this.state.phoneError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Email <sup>*</sup></label>
                                                    <input type="email" className="form-control" name="email" id="emailid"  onChange={this.onChange} value={this.state.email} autoComplete="off" disabled={true}/>
                                                    <div className="errorMessage">{this.state.emailError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Website <sup>*</sup></label>
                                                    <input type="text" className="form-control" name="website" id="websiteid"  onChange={this.onChange} value={this.state.website}/>
                                                    <p className="inputMsg">"https://" is required for website URL</p>
                                                    <div className="errorMessage">{this.state.websiteError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-12 col-lg-6">
                                                <div className="form-group">
                                                    <label>Categories <sup>*</sup></label>
                                                    
                                                    {<Dropdown
                                                        options={this.state.categoriesOptions}
                                                        placeholder='Select Categories'
                                                        search
                                                        selection
                                                        fluid
                                                        multiple
                                                        allowAdditions
                                                        value={currentValues}
                                                        onAddItem={this.handleAddition}
                                                        onChange={this.categoryHandleChange}
                                                    />}
                                                </div>
                                                <div className="errorMessage">{this.state.categoriesError}</div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Title </label>
                                                    <input type="text" className="form-control" name="title" id="titleid"  onChange={this.onChange} value={this.state.title}/>
                                                    {/* <div className="errorMessage">{this.state.countryError}</div> */}
                                                </div>
                                            </div>
                                            {/* <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Main Contact Pic</label>
                                                    <input type="file" className="form-control" name="image" onChange={this.contactPicImageChange} accept="image/x-png,image/gif,image/jpeg"/>
                                                    {
                                                      this.state.imageUrl !==''
                                                      ?
                                                      <div className="profile"> <img src={this.state.imageUrl} height="80" alt="" /></div>
                                                      :
                                                      null
                                                    }
                                                </div>
                                            </div> */}
                                            
                                        </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-group text-center mb-0 mt-3 mb-4">
                                        <button type="submit">
                                            <span>Submit <i class="fa fa-long-arrow-right pl-2"></i></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                    
                </section>
                {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
export default ExhibitorDetails;