import React, { Component } from 'react';
import BottomNav from './BottomNav'

import Axios from 'axios';
import {Link} from 'react-router-dom';




class ConferenceCenter extends Component {
    constructor(){
        super();
        this.state={
            sponsersImgList:[],
            mediaPartnerList:[],
            producedByList:[],
            sectionLoader:true,
            liveVideoUrl:[],
            eventListData:[],
            eventListDataNotFound:false,
            liveVideoUrlNotFound:"",
            upgradeModal:false,
            paidUser:false,
            sessionId:"",
            iframeLoader:false,
            pageLoader:true,
        }
    }

    upgradePaymentIframeModelHandeler = (e) => {
        e.preventDefault();
        this.setState({upgradePaymentIframeModel:!this.state.upgradePaymentIframeModel,iframeLoader:true})
        if(this.state.upgradePaymentIframeModel){
            this.onLoadingFn();
        }
    }

    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }
    
    
    componentDidMount(){
        if(global.config.attendeeConferenceCenter === 0 ){
            this.props.history.push(`/fna/disabled/`)
        }
       this.onLoadingFn();
    }

    onLoadingFn = () =>{
        this.setState({pageLoader:true});
        const details = {
            username: localStorage.getItem('userName'),
            eventId:localStorage.getItem('eventId'),
            role:localStorage.getItem('currentRole'),
        }

        Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            .then(res => {
                 if(res.data.errorCode === 0){
                     if(localStorage.getItem('currentRole') !== '-10' || res.data.data[0].reg_type !== 1442 ){
                        this.setState({paidUser:true})
                        this.getSponsersLogos();
                        this.getSessionLiveVideo();
                        this.getLiveEventList();
                     }else{
                         this.setState({upgradeModal:true})
                     }
                 }
                 this.setState({pageLoader:false});
                
            })
            .catch(err => {
                console.log(err);
            });
        
    }

    getSponsersLogos = () => {
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?eventId=${localStorage.getItem('eventId')}&type=3`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, mediaPartnerList:res.data.mediaPartner, producedByList:res.data.producedBy,sectionLoader:false});
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({sectionLoader:false});
            }

            
           
        })
        .catch(err => {
            console.log(err);
        });
    }

    getSessionLiveVideo = () => {
        this.setState({pageLoader:true});
        Axios.get(`${global.config.requestUrl}user/getSessionLiveVideo?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({liveVideoUrl:res.data.data, sessionId:res.data.data[0].session_id, liveVideoUrlNotFound:res.data.errorCode})
            if(res.data.errorCode === 0){
                if(res.data.data[0].live_url.includes('https://success.zoom.us/wc/join/')){
                    document.getElementById('liveconf').innerHTML = res.data.data[0].video_text+' <div class="hoverText"><p>Click below to view live webinar.  Password is FNA2020</p><a target="_blank" href ="'+res.data.data[0].live_url+'" > Click here </a></div>';
                }
            }
            this.setState({pageLoader:false});
        })
        .catch(err => {
            console.log(err);
        });
    }

    getLiveEventList = () => {
        Axios.get(`${global.config.requestUrl}user/getLiveEventsList?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {

            if(res.data.errorCode === 0){
                this.setState({eventListData:res.data.data})
            }else{
                this.setState({eventListDataNotFound:false})
            }
            this.setState({pageLoader:false});
        })
        .catch(err => {
            console.log(err);
        });
    }
    render() {
        //const sessionId = this.state.sessionId;
        if(this.state.paidUser){
            return (
                <React.Fragment>
               
                    <section className="bg6 signleScreen paddingTop">
                        {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                        <div className="conferenceSchedule">
                            <h3>Schedule of Live Events</h3>
                            <div className="scheduleTable">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <td>Session Name</td>
                                            <td>Date/Time</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        { 
                                            this.state.eventListData.map(eventList => (
                                                <tr>
                                                    <td>{eventList.session_name}</td>
                                                    <td>{eventList.start_date}, <br/>{eventList.start_time}</td>
                                                </tr>
                                            ))
                                        }
                                        {
                                            this.state.eventListDataNotFound
                                            ?
                                            <h5>No Data Found</h5>
                                            :
                                            null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="conferenceVideoWrp">
                            {
                                this.state.liveVideoUrlNotFound !== 0
                                ?
                                <div className="conferenceVideo videoNotFound">
                                    {/* <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" /> */}
                                </div>
                                :
                                <React.Fragment>
                                    
                                    { 
                                        this.state.liveVideoUrl.map(videoLink => (
                                            <React.Fragment>
                                                <h3>{videoLink.session_name}</h3>
                                                {
                                                    this.state.liveUrlNull === null || this.state.liveUrlNull === ""
                                                    ?
                                                    <div className="conferenceVideo videoNotFound">
                                                        <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" />
                                                    </div>
                                                    :
                                                    
                                                    <div className="conferenceVideo" id="liveconf">
                                                        {
                                                        videoLink.live_url.includes('https://success.zoom.us/wc/join/')
                                                        ?
                                                        null
                                                        :
                                                        <iframe width="100%" height="315" title="conference video" src={`${videoLink.live_url}?rel=0`} key={videoLink.live_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        }
                                                   </div>
                                                }
                                                
                                            </React.Fragment>
                                        ))
                                    }
                                
                                </React.Fragment>

                            }
                                
                      
                        </div>
                        <div className="conferenceSponserLogo">
                            <ul>
                            

                                { 
                                    this.state.producedByList.map(produceby => (
                                        <li key={produceby.exhibitor_id}>
                                            <Link to={`/fna/attendee/exhibitorBooth/${produceby.exhibitor_id}`}>
                                                <img src={produceby.logo} alt=""/>
                                            </Link>
                                        </li>
                                    ))
                                    
                                }


                                <li><img src={process.env.PUBLIC_URL + '/images/lobby/cs-2.jpg'} alt="" /></li>
                                

                                { 
                                    this.state.mediaPartnerList.map(mediapartner => (
                                        <li key={mediapartner.exhibitor_id}>
                                            <Link to={`/fna/attendee/exhibitorBooth/${mediapartner.exhibitor_id}`}>
                                                <img src={mediapartner.logo} alt=""/>
                                            </Link>
                                        </li>
                                    ))
                                    
                                }
                            </ul>
                        </div>
                        <div className="bottomStripSponsers">
                            <ul>
                                {
                                    this.state.sectionLoader
                                    ?
                                    (
                                        <div className="sectionLoader">
                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                        </div>
                                    )
                                    :
                                    null
                                }
                                { 
                                    this.state.sponsersImgList.map(sponsers => (
                                        <li key={sponsers.exhibitor_id}>
                                            <Link to={`/fna/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                <img src={sponsers.logo} alt=""/>
                                            </Link>
                                        </li>
                                    ))
                                    
                                }                            
                            </ul>
                        </div>
                        <div className="rightBarButton">
                            <Link to="/fna/attendee/technicalSessions">
                                <span>View All Technical Sessions</span>
                                <i className="fa fa-eye"></i>
                            </Link>
                            {
                                this.state.sessionId !=='' 
                                ?
                                <Link to={`/fna/attendee/conferenceCenterDetails/${this.state.sessionId}`}>
                                <span>Enter Live Session</span>
                                <i className="fa fa-long-arrow-right"></i>
                                </Link>
                                :
                                null

                            }
                            
                        </div>
                
                        <BottomNav />
                        
                    </section>
                </React.Fragment>
            )

        }else{

            return (
                <React.Fragment>
               
                <section className="bg6 signleScreen paddingTop">
                {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                }
                {
                this.state.upgradeModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                            <h3>Upgrade</h3>
                            <div className="midContentBx pt-5 pb-5 midForm">
                                <div className="row">
                                        <div className="offset-md-1 col-md-10">
                                            <h5 className="text-center mb-4">Conference Center access only with Full Conference Registration, Click below to upgrade</h5>
                                            <div className="text-center">
                                                <button className="btn btn-warning" onClick={this.upgradePaymentIframeModelHandeler}> <span>Upgrade</span></button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {/*=====Certificate Modal=====*/}
                {
                    this.state.upgradePaymentIframeModel
                    ?
                    <div className="midModal verticleCenterModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" onClick={this.upgradePaymentIframeModelHandeler}><i className="fa fa-times"></i></div>
                            <h3>Ugrade Here</h3>
                            <div className="midContentBx">
                              {
                               this.state.iframeLoader
                               ?
                               (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                               )
                               :
                               null
                              }
                              
                                <iframe width="100%" height="450" title="upgrade modal" src={`https://www.tslwebreg.com/VCPayments.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&pagetype=ATT`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                            </div>                               
                        </div>
                    </div>
                    :
                    null
                }
                </section>
                    </React.Fragment>
            )

        }
    }
}
export default ConferenceCenter;
