import React, {Component} from "react";
import fileUpload from '../utility/firebase';
import ChatMessages from "./ChatMessages";
import utility from "../utility/utility";
import troopServer from "../utility/troopServer";
import tmChat from "../utility/tmChat";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import ReactDOM from 'react-dom';


let stream;
const audioType = 'audio/wav';
let isScroll=false;
class ChatSection extends Component {

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClick, false);


        window.addEventListener('dragenter', this.handleDragIn);
        window.addEventListener('dragleave', this.handleDragOut);
        window.addEventListener('dragover', this.handleDrag);
        window.addEventListener('drop', this.handleDrop);
        this.scrollToBottom();
        this.setVisible = this.setVisible.bind(this);
        this.timer = this.timer.bind(this);

    }

    componentDidUpdate(prevProps) {

        if(!isScroll){
            this.scrollToBottom();
            isScroll=false
        }
        if(this.props.userInfo!==prevProps.userInfo){
            isScroll=false;
        }
        if(this.messageFeed) {
            this.messageFeed.addEventListener("scroll", () => {
                if (this.isFeedAtTop() && this.props.chatConversations && this.props.chatConversations.length) {
                    let entityId='';
                    let isGroup=false;
                    if(this.props.activeGroup){
                        entityId=this.props.userInfo.receiver_id;
                        isGroup=true;
                    }else{
                        entityId=this.props.userInfo.tm_user_id;
                    }
                    this.props.loadChatConversations(entityId,isGroup);
                    isScroll=true;
                }
            });
        }
    }
    groupuploadHandler = async (files) => {
        return await fileUpload([files]);
    };

    makefileName(length) {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    setVisible = (value) => {
        this.setState({'visible': value});
    }
    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({block: "end", inline: "nearest"});
        }
    }

    constructor(prop) {
        super(prop);
        this.state = {
            message: '',
            files: [],
            recording: false,
            'visible': true, 'submited': false,
            seconds: 0,
            hours: 0,
            minutes: 0,
            recordTime: '00:00:00',
            showRecording:false,
            'groupModal':false,
            userInfoModal:false,
            userDetails:{},
            groupUsers:[],
            usersList:{},
            checkedUsers:[],
            emojiOpen:false
        };
        this.emjoiIcon=React.createRef();
        this.updateInput = this.updateInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    isFeedAtTop=(e)=>{
        return 0 === this.messageFeed.scrollTop;
    }
    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        console.log(e.dataTransfer.files, "files");
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.saveFiles(e.dataTransfer.files);
            e.dataTransfer.clearData()
            this.dragCounter = 0
        }
    };
    uploadFile = (event) => {
        this.saveFiles(event.target.files);
        event.target.value = null;
    }
    saveFiles = (files) => {
        let stateFiles = this.state.files;
        stateFiles.push(files[0]);
        this.setState({'files': stateFiles})
    };

    componentWillUnmount() {
        window.removeEventListener('dragenter', this.handleDragIn);
        window.removeEventListener('dragleave', this.handleDragOut);
        window.removeEventListener('dragover', this.handleDrag);
        window.removeEventListener('drop', this.handleDrop);

            document.removeEventListener('mousedown', this.handleClick, false);

    }

//upload multi
    fileuploadHandler = async () => {
        const files = this.state.files;
        return await fileUpload(files);
    };
    saveAudioFile = async () => {
        const files ={'fileType':'blod',type:audioType,"blod":this.state.audio,name:this.makefileName(10)+".wav"};
        return await fileUpload([files]);
    };
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };

    updateInput(e) {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }

        this.setState({message: e.target.value})
    }

    removeFile = (e) => {
        let files = this.state.files;
        files.splice(e, 1);

        this.setState({'files': files})
    };

    async startRecording(e) {


        stream = await navigator.mediaDevices.getUserMedia({audio: true});
        // show it to user
        // init recording
        this.mediaRecorder = new MediaRecorder(stream);
        // init data storage for video chunks
        this.chunks = [];
        // listen for data from media recorder
        this.mediaRecorder.ondataavailable = e => {
            if (e.data && e.data.size > 0) {
                this.chunks.push(e.data);
            }
        };
        // e.preventDefault();
        // wipe old data chunks
        this.chunks = [];
        // start recorder with 10ms buffer
        this.mediaRecorder.start(10);
        // say that we're recording
        let intervalId = setInterval(this.timer, 1000);

        this.setState({
            recording: true, seconds: 0,
            hours: 0,
            minutes: 0, intervalId: intervalId
        });
    }

    timer() {
        // setState method is used to update the state
        let seconds=this.state.seconds
            console.log(seconds);
        seconds++;
        this.setState({seconds:seconds});
        if (this.state.seconds >= 60) {
            this.setState({seconds :0});
            let minutes=this.state.minutes;
            minutes++;
            this.setState({'minutes':minutes});
            if (this.state.minutes >= 60) {
                this.setState({minutes : 0});
                let hours=this.state.hours;
                hours++;
                this.setState({hours : hours});
            }
        }

        let time = (this.state.hours ? (this.state.hours > 9 ? this.state.hours : "0" + this.state.hours) : "00") + ":" + (this.state.minutes ? (this.state.minutes > 9 ? this.state.minutes : "0" + this.state.minutes) : "00") + ":" + (this.state.seconds > 9 ? this.state.seconds : "0" + this.state.seconds);
        this.setState({recordTime: time});
    }

    stopRecording(e) {
        this.mediaRecorder.stop();
        this.mediaRecorder=null;
        this.stopMediaStream();
        clearInterval(this.state.intervalId);
        this.saveAudio();
        this.setState({recording: false,'showRecording':true,recordTime:''});

    }
    stopMediaStream(){
        stream.getTracks().forEach(function(track) {
            if (track.readyState === 'live' && track.kind === 'audio') {
                track.stop();
            }
        });
    }
    cancelRecording(e) {
        if(this.mediaRecorder){
            this.mediaRecorder.stop();
            this.mediaRecorder=null;
        }
        if(stream) {
            this.stopMediaStream();
        }
        this.setState({recording: false,recordTime:'',showRecording:false,audio:''});
        clearInterval(this.state.intervalId);
    }

    saveAudio() {
        // convert saved chunks to blob
        const blob = new Blob(this.chunks, {type: audioType});
        // generate video url from blob
        const audioURL = window.URL.createObjectURL(blob);
        // append videoURL to list of saved videos for rendering
        this.setState({audio: audioURL});

    }
    async handleSubmit() {
        let filesPaths = [];

        this.setState({'submited': true});

        if(this.state.recording){
            //  this.setState({recording: false,recordTime:'',showRecording:false});
            this.stopRecording()
        }
        console.log(this.state.audio);
        if(this.state.audio){

            filesPaths = await this.saveAudioFile();
        }
        if (this.state.files.length) {
            filesPaths = await this.fileuploadHandler();
        }
        let sendMessage={
            'message': this.state.message,
            filesPaths: filesPaths,

        };
        if(this.props.activeGroup){
            console.log(this.props.userInfo,"userInfoo");
            sendMessage['group_id']=this.props.userInfo.receiver_id;
            sendMessage['is_group']=1;
        }else{
            sendMessage['receiver_id']= this.props.userInfo.tm_user_id;
            sendMessage['receiver_uid']= this.props.userInfo.uid
        }
        this.props.onSendMessage(sendMessage, this.props.userInfo);
        this.setState({'message':''});
        this.setState({
            message: '',
            files: [],
            audio:'',
            recording: false,
            recordTime:'',
            showRecording:false
        });
        this.setState({'submited': false});
//Send state to the server code
    }

    uploadFileDiv(files) {
        if (files.length === 0) {
            return ('');
        }
        return (<div className="attachments">
            {files.map((file, index) => {
                return (<div className="uploaded-files" key={index}>
                    <div className="bg-white p-3 border-radius12">
                        <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/document.svg"} className="img-fluid"/>
                        <span className="d-block text-black mt-2 upload-file-name">{file.name}</span>
                        <div className="file_size mt-3">
                            {utility.formatBytes(file.size, 1)}
                        </div>
                        <button className="cursor-pointer w-auto h-auto" onClick={this.removeFile.bind(this, index)}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/close.svg"} className="img-fluid"/>
                        </button>
                    </div>
                </div>);
            })}

            <div className="add-more">
                <button className="w-100 h-100 m-0">
                    <input type='file' className='file-upload' onChange={this.uploadFile}/>
                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/add.svg"}/>
                </button>
            </div>
        </div>)
    }

    openInfoModal=()=>{
        if(this.props.activeGroup){
            this.openAddGroupModal();
        }else{
            this.openUserInfoModal();
        }
    }
    getUserDetails=async ()=>{
               let userDetails=await troopServer.getUserDetails(this.props.userInfo.email,this.props.userInfo.role);
                  console.log(userDetails,"userDetails");
                this.setState({"userDetails":userDetails.data[0]});
    }
    openUserInfoModal=()=>{
           if(!this.state.userInfoModal){
               if(this.state.userDetails['email']!==this.props.userInfo.email){
                   this.setState({'userDetails':{}})
               }
               this.getUserDetails();
           }
           this.setState({"userInfoModal":!this.state.userInfoModal})
    }
    getGroupMemebersDetails=(user)=>{
        return new Promise(async (resolve) => {
            let uid = utility.getUserIdAndRole(user.member_uid);
            const parameters = {
                'ids': uid.userId,
                'role': uid.role,
            };
            let usersList = await troopServer.getUsersListBasedOnRole(parameters);
            user.is_old_user=true;
            user.user_role=user.member_role;
            console.log(user,usersList.data[0],"userDataaaa");
            user = Object.assign(user, usersList.data[0]);

            return resolve(user);

        });
    }
    openAddGroupModal=()=>{
        let usersPromises=[];
        this.props.groupInfo.members.forEach((user)=>{
            if(''+user.member_id!==''+this.props.loginUserDetails.tm_user_id) {
                usersPromises.push(this.getGroupMemebersDetails(user));
            }
        });
        Promise.all(usersPromises).then((response)=>{
            this.setState({'addedUsers':response,'groupMemebersLoading':false});
        })
        this.setState({groupMemebersLoading:true,'groupModal':!this.state.groupModal,'groupImage':'','groupTitle':this.props.groupInfo.group_name,"groupDescription":this.props.groupInfo.group_description,'groupImageSrc':this.props.groupInfo.group_avatar});
    }
    isGroupUsersFeedAtBottom=(e)=>{
        return  ((this.groupUsersFeed.scrollHeight) <= (this.groupUsersFeed.scrollTop+this.groupUsersFeed.offsetHeight+5));
    }

    activeUsers=(role)=> {
        this.setState({addUserListActiveRole : role});
        if (this.state.usersList[role] === undefined) {
            this.setState({'loadingUsers': true});
            this.loadUsersList(role, 10, 0);
        } else {
            this.setState({'loadingUsers': false, 'usersListWithRole': this.state.usersList[role]})

        }
    }
    openAddUsersModal = () => {


        if (!Object.keys(this.state.usersList).length) {
            this.setState({'loadingUsers': true,'addUserListActiveRole':-10});
            this.loadUsersList(-10, 10, 0);
        }
        this.setState({"addUsersModal": !this.state.addUsersModal,"groupModal": !this.state.groupModal})
    }
    addedRoleToUser=(index,role)=>{

        console.log(index,role);
        let remove=false;
        let updatedRole;
        let preveRole=this.state.addedUsers[index].user_role;
        console.log(preveRole);
        if(role===this.state.addedUsers[index].user_role){
            remove=true;
            updatedRole=this.props.troopConnection.groupMemberRole().NORMAL;
            this.state.addedUsers[index].user_role=this.props.troopConnection.groupMemberRole().NORMAL;
        }else{
            updatedRole=role;
            this.state.addedUsers[index].user_role=role;
        }
        let updateUser =this.state.addedUsers[index].is_old_user;
        console.log(updateUser,"removeUserFromMeeting");
        if(updateUser){
            tmChat.updateGroupUser(this.props.troopConnection,{'group_id':this.props.groupInfo.group_id,'preve_role':preveRole,'role':updatedRole,'user_id':this.state.addedUsers[index].member_id,'remove':remove});
        }
        this.setState({'addedUsers':this.state.addedUsers});
    }
    loadUsersList(role, limit = 20, offset = 0) {
        let parameters = {"role": role, "offset": offset, "limit":limit};
        troopServer.getUsersList(parameters).then((result) => {
            let users=[];
            if(result.data && result.data.length) {
                users = result.data.map((user) => {
                    if (this.state.checkedUsers.indexOf(user.userId + user.role)!==-1) {
                        user.userSelected = true;
                        return user;
                    } else {
                        return user;
                    }
                })
            }
            if(this.state.usersList[role] && this.state.usersList[role].length){
                this.state.usersList[role]=this.state.usersList[role].concat(users);
            }else{
                this.state.usersList[role]=users;
            }
            this.setState({
                'loadingUsers': false,
                isGroupUserLoading:false,
                usersList:this.state.usersList,
                'usersListWithRole': this.state.usersList[this.state.addUserListActiveRole]
            })
        });
    }
    updateGroup=async (e)=>{
        if(!this.state.groupTitle && this.state.groupTitle===''){
            this.setState({'showError':true,'errorMessage':"Please Enter Group Name"});
            return  false;
        }

        if(!this.state.addedUsers && this.state.addedUsers.length===0){
            this.setState({'showError':true,'errorMessage':"Please Select Users to add Group"});
            return  false;
        }

        tmChat.updateGroup({'group_id':this.props.groupInfo.group_id,'title':this.state.groupTitle,'description':this.state.groupDescription});


        //

    }

    formatTheUsers=(user)=>{
        return new Promise(async (resolve) => {
            let tmDetails = await this.props.troopConnection.register(utility.generateUserUid({'userId':user.userId,role:user.role}), user.name);
            let  tm_user_id=tmDetails.tm_user_id;
            let groupRole=this.props.troopConnection.groupMemberRole().NORMAL;
            if(user.user_role){
                groupRole=user.user_role;
            }
            return resolve({'user_id':tm_user_id,'role': groupRole })

        });
    }
    groupTitleChange = (e) => {
        this.setState({groupTitle: e.target.value});
    }
    groupDescriptionChange = (e) => {
        this.setState({groupDescription: e.target.value});
    }
    addUserToMeeting = (e, index, userId, role) => {
        this.state.usersList[role][index]['userSelected'] = !this.state.usersList[role][index]['userSelected']
        if (this.state.usersList[role][index]['userSelected']) {
            this.state.addedUsers.push(this.state.usersList[role][index]);
            this.state.checkedUsers.push(userId+role);
        } else {
            console.log(this.state.addedUsers,"addedddedUserModel");
            let userIndex = this.state.addedUsers.findIndex((user) => {
                if (user.userId === userId && user.role === role) {
                    return user;
                }
            });
            if (userIndex !== -1) {
                let users=this.state.addedUsers;
                users.splice(userIndex, 1);
                this.setState({addedUsers:users});
            }
            let userSelectedIndex = this.state.checkedUsers.findIndex((user) => {
                if (user === userId+role) {
                    return user;
                }
            });
            if (userSelectedIndex !== -1) {
                this.state.checkedUsers.splice(userSelectedIndex, 1);
            }
        }
        const myNewArray = Object.assign([...this.state.usersListWithRole], {
            [index]: this.state.usersListWithRole[index]
        });
        this.setState({'usersListWithRole': myNewArray,usersList: this.state.usersList});
    }
    removeUserFromMeeting = (e, index, userId, role) => {
        let userIndex = this.state.addedUsers.findIndex((user) => {
            if (user.userId === userId && user.role === role) {
                return user;
            }
        });
        let deleteUser =this.state.addedUsers[index].is_old_user;
        console.log(deleteUser,"removeUserFromMeeting");
        if(deleteUser){
            let user=this.state.addedUsers[index];
            tmChat.removeGroupUser(this.props.troopConnection,{'group_id':this.state.userInfo.receiver_id,'role':user.role,'user_id':user.memeber_id});
        }
        if (userIndex !== -1) {
            let users=this.state.addedUsers;
            users.splice(userIndex, 1);
            this.setState({addedUsers:users});
        }
        let userSelectedIndex = this.state.checkedUsers.findIndex((user) => {
            if (user === userId+role) {
                return user;
            }
        });
        if (userSelectedIndex !== -1) {
            this.state.checkedUsers.splice(userSelectedIndex, 1);
        }
        this.setState({usersList: this.state.usersList,checkedUsers:this.state.checkedUsers});
    }
    saveFilesToGroup = async (e) => {
        let files=e.target.files;
        var pattern = /image-*/;
        if (!files[0].type.match(pattern)) {
            alert('Invalid format');
            return;
        }
        var reader = new FileReader();
        var url = reader.readAsDataURL(files[0]);

        reader.onloadend = function (e) {
            this.setState({
                groupImageSrc: [reader.result]
            })
        }.bind(this);
        let image= await this.groupuploadHandler(files[0]);
           console.log(image,"imageee");
        tmChat.updateGroupAvatar(this.props.troopConnection,{'group_id':this.props.groupInfo.group_id,'image':image})
        // this.setState({'groupImage': files[0]})
    };
    removeGroupImage=()=>{
        console.log(this.props.groupInfo);
        this.setState({'groupImage':'',groupImageSrc:''});
        tmChat.updateGroupAvatar(this.props.troopConnection,{'group_id':this.props.groupInfo.group_id,'image':''})

    }
    loadMore(){
        this.setState({'isLoading': true});
        if (this.state.isLoading) {
            this.props.loadMoreUsers()
        }
    }
    loadMoreGroupUsers=()=>{
        this.setState({'isGroupUserLoading':true});
        this.loadUsersList(this.state.addUserListActiveRole,20,this.state.usersList[this.state.addUserListActiveRole].length)
    }
    onEmojiClick = (emojiObject) => {
        this.setState({'message':this.state.message+emojiObject.native})
    };
    openEmoji=()=>{
        this.setState({'emojiOpen':!this.state.emojiOpen});
        this.emojiPicker = React.createRef();

    }
    handleClick(event) {
        try {
            let node = ReactDOM.findDOMNode(this.emojiPicker.current);
                 console.log("comes hererr",node,node.contains(event.target));
            if (!node.contains(event.target) && !this.emjoiIcon.current.contains(event.target)) {
                // Handle outside click here
                this.openEmoji();
            }
        } catch(error) {
            return null
        }
    }
    render() {
        return (
            <React.Fragment>
                {this.props.userInfo && Object.keys(this.props.userInfo).length ?
                    <div className="col-md-9 px-0 chat_window">
                        <div
                            className="d-flex justify-content-between align-items-center online-user py-3 px-4 chat-widget_updated bg-white">
                            <div className="d-flex" onClick={this.openInfoModal}>
                                <div className="user_img d-flex justify-content-center">
                                    <img  alt='' src={this.props.activeGroup?this.props.userInfo.receiver_profile_pic:this.props.userInfo.profilePic} className="img-fluid rounded-pill"/>
                                    {/* <div className="position-absolute user_status_online">

                                    </div>*/}
                                </div>
                                <div className="pl-3 message_div">
                                    <div className="d-flex justify-content-between">
                                        <div className="user_name name">{this.props.activeGroup?this.props.userInfo.receiver_name:this.props.userInfo.name}</div>
                                        <div className="time"></div>
                                    </div>

                                    {this.props.activeGroup?'': (this.props.userInfo.is_online?<div className="user_message mt-n1">online</div>:'')}
                                </div>
                            </div>

                            {  this.props.activeGroup?'': <div className="video_call">
                                <button onClick={this.props.makeVideoCall}>
                                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_group_call.png"}
                                         className="img-fluid"/> Video Call
                                </button>

                            </div>}
                        </div>

                        <div className="widget_chat p-4 mCustomScrollbar" data-mcs-theme="minimal-dark"   ref={(el) => {
                            this.messageFeed = el;
                        }}>
                            <ChatMessages userInfo={this.props.userInfo} loginUserDetails={this.props.loginUserDetails}
                                          chatConversations={this.props.chatConversations}
                                          troopConnection={this.props.troopConnection}/>
                            <div className='mb-2'/>

                            <div style={{float: "left", clear: "both"}}
                                 ref={(el) => {
                                     this.messagesEnd = el;
                                 }}>
                            </div>

                        </div>
                        {this.state.emojiOpen?<Picker ref={this.emojiPicker} onSelect={this.onEmojiClick} style={{ position: 'absolute', bottom: '20px', right: '20px' }} />:''}

                        <div className="message-area py-3 px-4 d-flex justify-content-between">
                            <div className="input-area d-flex">
                                {this.uploadFileDiv(this.state.files)}
                                <div className={(this.state.files.length?'input-text-area--file-upload ':'')+ ' d-flex w-100 input-text-area'} data-recording={this.state.recording}>
                                    {this.state.recording ? <div className="microphone-record-container">
                                        <div className="microphone-stop-btn" onClick={e => this.stopRecording(e)}><img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/stop-button.svg"} className='img-fluid w-100 h-100'/></div>
                                        <div className="microphone-timer-text">{this.state.recordTime}</div>
                                        <div className="microphone-record-close"
                                             onClick={e => this.cancelRecording(e)}><img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/cancel.svg"} className='img-fluid w-100 h-100'/></div>
                                    </div> : (this.state.showRecording ?<div className="microphone-record-container">
                                            <audio controlsList="nodownload" className='w-80' controls><source src={this.state.audio}/>
                                            </audio>
                                            <div className="microphone-record-close"
                                                 onClick={e => this.cancelRecording(e)}><img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/cancel.svg"} className='img-fluid w-100 h-100'/></div>
                                        </div>:
                                        <input type="text" placeholder="Type your message here..."  value={this.state.message} onChange={this.updateInput} onKeyDown={this.handleKeyDown}/>)}
                                    {this.state.submited ? <span className='loader-spinner'><i
                                            className='fa fa-spinner  fa-spin'></i></span> :
                                        <button type='button' className='btn p-0 m-0 message-sendbtn' onClick={this.handleSubmit}>
                                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_send.png"}
                                                 className="img-fluid"/>
                                        </button>
                                    }
                                </div>
                            </div>
                            <div className="d-flex attachment_area">
                                <button className="position-relative cursor-pointer">
                                    <input type='file' className='file-upload' onChange={this.uploadFile}/>
                                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_attachemnts.png"}
                                         className="img-fluid"/>
                                </button>
                                <button onClick={this.openEmoji}>


                                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_emoji.png"} ref={this.emjoiIcon} className="img-fluid"/>
                                </button>
                                <button>
                                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_share.png"} className="img-fluid"/>
                                </button>
                                {!this.state.recording? <button onClick={e => this.startRecording(e)}>
                                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_mic.png"} className="img-fluid"/>
                                </button>:''}
                            </div>
                        </div>


                    </div> : ''}
                {  this.state.groupModal?
                    <React.Fragment>
                        <div className="modal-backdrop show"></div>
                        <div className="modal d-block" id="groupinfoModal">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header pl-lg-5">
                                        <h5 className="modal-title">Group Info</h5>
                                        <button type="button" className="close roboto-light" data-dismiss="modal"
                                                aria-label="Close" onClick={this.openAddGroupModal}>
                                            <span aria-hidden="true">[ X ] Close</span>
                                        </button>
                                        {this.props.groupInfo.member_role!==this.props.troopConnection.groupMemberRole().NORMAL ?<button
                                            className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2" onClick={this.openAddUsersModal}>
                                            Add Member
                                        </button>:''}
                                    </div>
                                    {this.state.showError ? <div className="col-12 alert alert-danger">
                                        <strong>Error!</strong> {this.state.errorMessage}.
                                    </div> : ''}
                                    <div className="modal-body px-lg-5">
                                        <div className="row mx-n2 mb-3">
                                            <div className="col-12 col-lg-8 px-2 mb-4 my-lg-0">
                                                <div className="mb-4">
                                                    <label className="color-333 mb-1">
                                                        <i>Group Title</i>
                                                    </label>
                                                    <div>
                                                        <input type="text" className="form-control"
                                                               placeholder="Enter group title" value={this.state.groupTitle} onChange={this.groupTitleChange} disabled={!(this.props.groupInfo.member_role !== this.props.troopConnection.groupMemberRole().NORMAL)}/>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <label className="color-333 mb-1">
                                                        <i>Group Description</i>
                                                    </label>
                                                    <div>
                                                    <textarea className="form-control" rows="4"
                                                              placeholder="Enter group description" value={this.state.groupDescription} onChange={this.groupDescriptionChange} disabled={!(this.props.groupInfo.member_role !== this.props.troopConnection.groupMemberRole().NORMAL)}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 px-2 my-0">
                                                <div>
                                                    <div className="text-right">
                                                        {this.state.groupImageSrc && !(this.props.groupInfo.member_role === this.props.troopConnection.groupMemberRole().NORMAL)? <span className="btn d-inline-block color-333 py-1" onClick={this.removeGroupImage}>
                                            [ X ] Remove
                                        </span>:<div className='mt-4'/>}
                                                    </div>
                                                    <div className="position-relative upload-imgbox" style={{background: 'url(' + this.state.groupImageSrc + ') no-repeat center / cover'}}>
                                                        {!(this.props.groupInfo.member_role === this.props.troopConnection.groupMemberRole().NORMAL)?  <input type="file" onChange={this.saveFilesToGroup} accept="image/x-png,image/jpeg" />:''}
                                                        {this.state.groupImageSrc?'':<div
                                                            className="d-flex align-items-center justify-content-center roboto-light">
                                                            Click Here
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group-usersdetails mCustomScrollbar" data-mcs-theme="minimal-dark">
                                            <div className="row mx-n2 mb-3">

                                                {this.state.addedUsers  ?this.state.addedUsers.map((user,index)=>{
                                                    return ( <div className="col-12 col-md-6 col-lg-4 px-2 mb-2 mt-0" key={index}>
                                                        <div>

                                                     {(this.props.groupInfo.member_role !== this.props.troopConnection.groupMemberRole().NORMAL)?  <span className="btn d-inline-block color-333 p-1" onClick={e=>this.removeUserFromMeeting(e,index, user.userId, user.role)}>
                                            [ X ] Remove
                                        </span>:''}
                                                        </div>
                                                        <div className="meetings-userslist">
                                                            <div className="position-relative d-flex align-items-center">
                                                                <div className="meetings-userpic mr-3" date-id={user.profilePic }
                                                                     style={{background: 'url(' + user.profilePic + ') no-repeat center / cover'}}>
                                                                    {user.profilePic!=='' ? '' : utility.acronym(user.name)
                                                                        .toUpperCase()
                                                                        .slice(0, 2)}
                                                                </div>
                                                                <div
                                                                    className="d-flex justify-content-between align-items-center user-detailsbox">
                                                                    <div className="roboto-bold color-000 text-truncate">
                                                                        {user.name}
                                                                    </div>
                                                                    <div className="user-rolebox">
                                                    <span className={((this.props.troopConnection.groupMemberRole().ADMIN===user.user_role)?'user-role--active':'')+" user-role mb-1 "} onClick={e=>this.addedRoleToUser(index,this.props.troopConnection.groupMemberRole().ADMIN)}>
                                                        A
                                                    </span>
                                                                        <span className={((this.props.troopConnection.groupMemberRole().MODERATOR===user.user_role)?'user-role--active':'')+" user-role "} onClick={e=>this.addedRoleToUser(index,this.props.troopConnection.groupMemberRole().MODERATOR)}>
                                                        M
                                                    </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>)
                                                }):(this.state.groupMemebersLoading?'Loading Users...':'')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer px-lg-5 pb-lg-4">
                                        <div className="text-right">
                                            <button
                                                className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2" onClick={this.addGroup}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>:''}
                {this.state.addUsersModal ? <React.Fragment>
                        <div className="modal-backdrop show"></div>
                        <div className="modal d-block" id="addusersModal">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header pl-lg-5">
                                        <h5 className="modal-title">Add Users</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                onClick={this.openAddUsersModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body px-lg-5">
                                        {this.state.loadingUsers ? 'Loading...' :
                                            <React.Fragment>
                                                <ul className="nav nav-tabs flex-nowrap w-100 overflow-auto mb-4">
                                                    <li className="nav-item" role="presentation">
                                                        <a className={(this.state.addUserListActiveRole === -10 ? 'active' : '') + " nav-link"}
                                                           data-toggle="tab" href="#"
                                                           onClick={e => this.activeUsers(-10)}>
                                                            <div className="addusersModal-tabs">
                                                                <i className="fab fa-slideshare"></i>
                                                            </div>
                                                            Attendees
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className={(this.state.addUserListActiveRole === -1 ? 'active' : '') + " nav-link"}
                                                           data-toggle="tab" href="#"
                                                           onClick={e => this.activeUsers(-1)}>
                                                            <div className="addusersModal-tabs">
                                                                <i className="fas fa-university"></i>
                                                            </div>
                                                            Exhibit Staff
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className={(this.state.addUserListActiveRole === -6 ? 'active' : '') + " nav-link"}
                                                           data-toggle="tab" href="#"
                                                           onClick={e => this.activeUsers(-6)}>
                                                            <div className="addusersModal-tabs">
                                                                <i className="fas fa-microphone"></i>
                                                            </div>
                                                            Speakers
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div className="tab-pane fade show active" id="add-usersAttendees">
                                                        <div className="position-relative add-users--searchbox mb-3">
                                                            <input type="text" className="form-control" value={this.state.searchGroupUsersValue}
                                                                   placeholder="Search People" onChange={e=>this.searchGroupMeetingUsers(e)} />
                                                        </div>
                                                        <div className="addusers-list row mx-n2 mb-3"
                                                             data-mcs-theme="minimal-dark" ref={(el) => {
                                                            this.groupUsersFeed = el;
                                                        }}>

                                                            {this.state.usersListWithRole ? this.state.usersListWithRole.map((user, index) => {
                                                                return (<div className="col-12 col-md-6 px-2 mb-2"
                                                                             key={index}>
                                                                    <div className="meetings-userslist">
                                                                        <div
                                                                            className="position-relative d-flex align-items-center">
                                                                            {/* <span className="d-inline-block status-btn"></span>*/}
                                                                            <div className="meetings-userpic mr-3"
                                                                                 style={{background: 'url(' + user.profilePic + ') no-repeat center / cover'}}>
                                                                                {user.profilePic ? '' : utility.acronym(user.name)
                                                                                    .toUpperCase()
                                                                                    .slice(0, 2)}
                                                                            </div>
                                                                            <div
                                                                                className="d-flex justify-content-between align-items-center addusers-userbox">
                                                                                <div
                                                                                    className="roboto-bold color-000">
                                                                                    {user.name}
                                                                                </div>
                                                                                <label
                                                                                    className="options-checkbox position-relative pointer">
                                                                                    <div className="d-inline-block">
                                                                                        <input type="checkbox"
                                                                                               checked={user.userSelected ? 'checked' : ''}
                                                                                               onChange={(e) => this.addUserToMeeting(e, index, user.userId, user.role)}/>
                                                                                        <span
                                                                                            className="custom-checkbox"></span>
                                                                                    </div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>)

                                                            }) : 'No Users Found'}



                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="add-usersExhibitstaff">
                                                        Exhibit Staff
                                                    </div>
                                                    <div className="tab-pane fade" id="add-usersSpeakers">
                                                        Speakers
                                                    </div>
                                                    <div className="tab-pane fade" id="add-usersGroups">
                                                        Groups
                                                    </div>
                                                </div>
                                            </React.Fragment>}
                                    </div>
                                    <div className="modal-footer px-lg-5 pb-lg-5">
                                        <div className="text-right">
                                            <button
                                                className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2"
                                                onClick={this.openAddUsersModal}>
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : ''}

                {this.state.userInfoModal ? <React.Fragment>
                        <div className="modal-backdrop show"></div>
                        <div className="modal d-block" id="userInfoModal">
                            <div className="modal-dialog modal-dialog-centered">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header p-0">
                                        <button type="button" className="close roboto-light" data-dismiss="modal"
                                                aria-label="Close" onClick={this.openUserInfoModal}>
                                            <span aria-hidden="true">[ X ] Close</span>
                                        </button>
                                    </div>
                                    <div className="modal-body p-3">
                                        {this.state.userDetails && Object.keys(this.state.userDetails).length?<div className="row">
                                            <div className="col-12 col-md-6">
                                                {this.state.userDetails.profilePic?<div>
                                                    <img alt='' src={this.state.userDetails.profilePic} className="img-fluid rounded overflow-hidden"/>
                                                </div>: <div className="info-noimage">
                                                    {utility.acronym(this.state.userDetails.name)}
                                                </div>}

                                                <div className="text-center mt-2">
                                                    <div className="text-uppercase color-blue font-weight-bold">
                                                        { this.state.userDetails.name}
                                                    </div>
                                                    <div className="font-italic d-none">
                                                        CEO
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="mb-3">
                                                    Designation
                                                    <div className="font-weight-bold infodetail">
                                                        { this.state.userDetails.designation}
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    Company
                                                    <div className="font-weight-bold infodetail">
                                                        { this.state.userDetails.company}
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    City
                                                    <div className="font-weight-bold infodetail">
                                                        { this.state.userDetails.city}
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    State
                                                    <div className="font-weight-bold infodetail">
                                                        { this.state.userDetails.state}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>:'Loading...'}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : ''}

            </React.Fragment>
        )
    }

}

export default ChatSection