import React, { Component } from 'react'
import Axios from 'axios';

const initialState = {
    email:"",
    emailError: "",
    submitSuccess : "",
    submitError : "",
    pageLoader:false,
  }
class ForgotPassword extends Component {
    constructor(props){
        super(props);
        this.state = initialState;
        
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
      }

    forgetPassSubmit = (e) =>{
        
           e.preventDefault();
           
           if(this.state.email !== "undefined"){
            let lastAtPos = this.state.email.lastIndexOf('@');
            let lastDotPos = this.state.email.lastIndexOf('.');
            if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
              this.setState({emailError:"Invalid Email"});
              return false;
             }else{
                this.setState({emailError:"", pageLoader:true}); 
             }
          } 
           
            Axios.post(`${global.config.requestUrl}user/forgetPassword`, {
                email: this.state.email,
                eventId:localStorage.getItem('eventId')
              })
            .then(res => {
                this.setState({pageLoader:false}); 
                if(res.data.errorCode !== 0){
                    this.setState({submitError:res.data.errorMessage, submitSuccess:"" })
                }
               
                if(res.data.errorCode === 0){
                    this.setState({submitSuccess:res.data.errorMessage, submitError:"",email :"", forgotPasswordModal:false, })
                   
                    
                }
                
              })
              .catch(err => {
                console.log(err);
              });
         
    }
    render() {
        return (
            <React.Fragment>
                  <div  className =" forgetform">
                  {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                  }
                        <h3 className="mt-3 mb-3">Forgot Password</h3>
                        <div className="error">{this.state.submitError}</div>
                        <div className="success">{this.state.submitSuccess}</div>
                        <form onSubmit={this.forgetPassSubmit} noValidate>
                            <div className="form-group">
                                <input type="email" name="email" id="emailid" placeholder="Registerd Email id" onChange={this.onChange} value={this.state.email} className="form-control"/>
                                <div className="errorMessage">{this.state.emailError}</div>
                            </div>
                            <div className="form-group text-center mt-3">
                                <button className="btn" type="submit">
                                    <i className="fa fa-paper-plane"></i>
                                    <span>Submit</span>
                                </button>
                            </div>
                            
                        </form>
                    </div>
            </React.Fragment>
        )
    }
}
export default ForgotPassword;
