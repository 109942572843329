import React, { Component } from 'react';


import Axios from 'axios';

class BoothContactList extends Component {
    constructor(props){
        super(props);

        this.state = {
            boothcontactData: [],
            pageLoader : true,
        }
    }
    

    componentDidMount (){
        const {match : {params}}  = this.props.passprops;
        Axios.get(`${global.config.requestUrl}exhibitor/getBoothContacts?exhibitorId=${params.exhibitorId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
console.log(res.data.data)
            if(!res.data.errorCode){
            this.setState({
                boothcontactData:res.data.data,
                pageLoader : false
            })
        }else{
            this.setState({
                pageLoader: false
            });
        }
    })
    .catch(err => {
        console.log(err);
    });
   
    }

    

      
     

    render() {
     
    const boothcontactHtml = this.state.boothcontactData.map((anObjectMapped, index) => {
            return (
                
                <tr key={index}>
                <td>{`${anObjectMapped.firstName}`}</td>
                <td>{`${anObjectMapped.lastName}`}</td>
                <td>{`${anObjectMapped.title}`}</td>
                <td><a href={`mailto:${anObjectMapped.email}`}>{`${anObjectMapped.email}`}</a></td>
                <td>{`${anObjectMapped.phone}`}</td>
                
               </tr>
            );
        })
        return (
            <React.Fragment>
                <div className="modalTableWrp">
                    <table className="table modalTable text-center mt-3">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Title</th>
                                <th>Email</th>
                                <th>Phone</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            {boothcontactHtml} 
                        </tbody>
                    </table>
                </div>
                {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
            </React.Fragment>
        )
    }
}
export default BoothContactList;