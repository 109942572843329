import React, { Component } from 'react';
import BottomNav from './BottomNav';
import {Link} from "react-router-dom";
import Axios from 'axios';

export default class PosterMenu extends Component {

    constructor(props){
        super(props);

        this.state={
           postersList:[],
            CategoryList:[],
            searchByCategory:'',
            searchByName:'',
            pageLoader : true,
            offset:0,
            limit:1000,
            showloadmore:false,
            norecord:false,
            textToSearch:'',
        }
        
    }

    componentDidMount(){
        this.renderPosterList();
        this.renderCategoryList();
    }
    renderCategoryList = () => {
        /* getting exhibitor list with logos */
    
        Axios.get(`${global.config.requestUrl}user/getPosterCategoryList?eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
        
       
         .then(res => {
            
             if(res.data.errorCode === 0){
                this.setState({CategoryList:res.data.data})
                this.setState({ pageLoader:false})
             } else{
                 this.setState({pageLoader:false})
                 
             }
             
         })
         .catch(err => {
             console.log(err);
         });
     }

    renderPosterList = () => {
        this.setState({pageLoader:true})
        const postdata = {
            eventId: localStorage.getItem('eventId'),
            offset: this.state.offset,
            limit:this.state.limit,
            searchByCategory:this.state.searchByCategory,
            searchByName:this.state.searchByName,
           }
           Axios.post(`${global.config.requestUrl}user/getPosters`, postdata,
           {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
           )
           .then(res => {
             
              if(res.data.errorCode === 0){
                var datacount = Object.keys(res.data.data).length;
                var newoffset = this.state.offset
                 this.setState({ postersList: [...this.state.postersList, ...res.data.data ] ,pageLoader:false,norecord:false})
                 this.setState({offset: newoffset + 1000});
                 if(datacount < 1000){
                    this.setState({ showloadmore:false})
                 }else{
                    this.setState({ showloadmore:true})
                 }
                 
              }else if(res.data.errorCode === 1){
                this.setState({ pageLoader:false,showloadmore:false,norecord:true})
              
              } else{
                this.setState({ pageLoader:false})
                  
              }
              
              
              
          })
          .catch(err => {
              console.log(err);
          });
      }

       /*input box value change*/
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
}
    searchText = (e) => {
        e.preventDefault();
        this.setState({
            searchByName:this.state.textToSearch,
            offset:0,
            limit:1000,
            postersList: [],
          }, () => {
            this.renderPosterList();
          });
     }

     onChangeCategoryHandler = (e) => {
        console.log(e.target.value);
        this.setState({
          searchByCategory:e.target.value,
          offset:0,
          limit:1000,
          postersList: [],
        }, () => {
          this.renderPosterList();
        });
        
    }

    render() {
        return (
            <React.Fragment>
                <section className="bg1 signleScreen paddingTop height1200">
                {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                    <div className="centerLogo">
                        <img src={process.env.PUBLIC_URL + '/images/csda/sponsor.jpg'} alt="" />
                    </div>
                    <div className="midTable conferenceTable">
                        <h3>Conference Poster</h3>
                        {/*<div className="sortBy">
                             <div className="sortBx">
                                <label>Filter:</label>
                                <select name="trackfilter" onChange={this.onChangeCategoryHandler}>
                                    <option value="">All</option>
                                    { 
                                      this.state.CategoryList.map(category => (
                                         <option value={category.trackId} key={category.trackId}>{category.track}</option>
                                       ))
                                    }
                                </select>
                            </div> 
                            
                                <div className="searchBx">
                                    <form onSubmit={this.searchText}>
                                      <input type="text" name="textToSearch" value={this.state.textToSearch}  placeholder="Search Here..." onChange={this.onChange}/>
                                      <button><i className="fa fa-search"></i></button>
                                    </form>
                                </div>
                        </div>*/}
                        <div className="midTableWrp pt-3">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th style={{'minWidth':'250px'}}>Author</th>
                                        <th>Poster Title</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                { 
                                this.state.postersList.map(poster => (
                                    <tr key={poster.session_id}>
                                        <td>{poster.speaker}</td>
                                        <td>{poster.session_name}</td>
                                        <td><Link to={`/csda/attendee/poster/${poster.session_id}`} className="fullBtn">Click to view</Link></td>

                                    </tr>
                                    ))
                                }
                                </tbody>
                            </table>

                               


                             

{
                                      this.state.norecord
                                      ?
                                      (
                                        <div className="noRecord">
                                                No record Found
                                        </div>
                                      )
                                      :
                                      null
                                    }
                                
                            
                        </div>
                        
                        {
                                      this.state.showloadmore
                                      ?
                                      (
                                        <div className="loadMore mt-3" onClick={this.renderPosterList}>
                                            <button>Load More</button>
                                        </div>
                                      )
                                      :
                                      null
                                    }
                                    {/* {
                                      !this.state.showloadmore && !this.state.norecord
                                      ?
                                      (
                                        <div>All result are here now.</div>
                                      )
                                      :
                                      null
                                    } */}
                        
                    </div>
                    <BottomNav />     
                </section>
            </React.Fragment>
        )
    }
}
