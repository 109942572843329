import React, { Component } from 'react';
import BottomNav from './BottomNav'
import Axios from 'axios';
import {Link} from 'react-router-dom';
import ReactStars from "react-rating-stars-component";

class SessionSurvayEasy extends Component {
    constructor(props){
        super(props);
        this.state={
            pageLoader:true,
            vote:null,
            comment:'',
            listId:'',
            msg:'',
        }
        
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    
    componentDidMount(){
        this.getFilledSessionSurvey();

    }

    submitSurvey = (e) => {
        e.preventDefault();
        this.setState({pageLoader:true});

        const {match : {params}}  = this.props.passprops;
            const surveyData = {
                visitorId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role:localStorage.getItem('currentRole'),
                vote:this.state.vote,
                comment:this.state.comment,
                listId:this.state.listId,
            }

            Axios.post(`${global.config.requestUrl}user/insertUserSessionSurvey`, surveyData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        
                        this.setState({pageLoader:false,msg:res.data.errorMessage});
                })
                .catch(err => {
                    console.log(err);
                });


    }

    ratingChanged = (newRating) => {
        this.setState({
            vote:newRating
        })
    }


    getFilledSessionSurvey = () => {
        const {match : {params}}  = this.props.passprops;
            const surveyData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role:localStorage.getItem('currentRole'),
            }

            Axios.post(`${global.config.requestUrl}user/getUserSessionSurveyData`, surveyData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode === 0){ 
                            //console.log(res.data.data);
                    this.setState({vote:res.data.data[0].vote,comment:res.data.data[0].comment,listId:res.data.data[0].list_id});
                        }

                        this.setState({pageLoader:false});
                })
                .catch(err => {
                    console.log(err);
                });




    }


    
    render() {
        const { vote } = this.state;
        
            return (
                <React.Fragment>

                    {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
  
                   
                       <div className="midContentBx">
                                    <div className="surveyForm">
                                        <form onSubmit={this.submitSurvey}>
                                        {this.state.msg !== ''?<h3>{this.state.msg}</h3>:null}
                                            <label className="mb-0">1. Please rate this session.</label>
                                            <ReactStars
                                                count={5}
                                                onChange={this.ratingChanged}
                                                size={40}
                                                isHalf={false}
                                                key={this.state.vote}
                                                value = {vote}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                activeColor="#ffd700"
                                            />
                                            <div className="form-group mt-2">
                                                <label>2. Your thoughts about the session.</label>
                                                <textarea className="form-control" rows="5" onChange={this.onChange} value={this.state.comment} name="comment"></textarea>
                                            </div>
                                            <div className="form-group text-center">
                                                <button className="btn btn-warning" type="submit">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                
                    
                    
                </React.Fragment>
            )
        
        
         
    }
}
export default SessionSurvayEasy;
