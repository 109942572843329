import React, { Component } from 'react'
import Axios from 'axios';


export default class SessionDocuments extends Component {
    constructor(props){
        super(props);

        this.state={
            sessionDocuments:[],
            sessionDocumentsType1:"Document",
            speakerDocError:"",
            pageLoader:true,
        }
    }
    componentDidMount(){
        const {match : {params}}  = this.props.passprops;
        Axios.get(`${global.config.requestUrl}user/getSessionDocuments?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({sessionDocuments:res.data.data, speakerDocError:res.data.errorCode, pageLoader:false})
        })
        .catch(err => {
            console.log(err);
        });
    }
    render() {
        return (
            <React.Fragment>
            {
                this.state.pageLoader
                ?
                (
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                )
                :
                null
            }
            {
                <React.Fragment>
                    <div className="modalTableWrp">
                        <table className="table modalTable text-center mt-3">
                            <thead>
                                <tr>
                                    <th>Documents/Video</th>
                                    <th>Type</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.speakerDocError !== 0
                                    ?
                                    <tr>
                                        <td colSpan="3"><h5>No Documents Available</h5></td>
                                    </tr>
                                    :
                                    <React.Fragment>
                                        {
                                            this.state.sessionDocuments.map(content => (
                                                <tr key={content.doc_id}>
                                                    <td>{content.name}</td>
                                                    <td>{this.state.sessionDocumentsType1}</td>
                                                    <td>
                                                        <a href={content.file} target="_blank" rel="noopener noreferrer" className="btn btn-success">View</a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </React.Fragment>
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
            }
            </React.Fragment>
        )
    }
}
