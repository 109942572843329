import React, { Component } from 'react';
import AttendeeExhibitorBoothTemp1 from './AttendeeExhibitorBoothTemp1';
import AttendeeExhibitorBoothTemp2 from './AttendeeExhibitorBoothTemp2';
import Axios from 'axios';
import jwt_decode from 'jwt-decode'


class AttendeeExhibitorBooth extends Component {
    constructor(props){
        super(props);
        this.state = {
            showTemplate:false,
            pageLoader:true,
            templateId:0,
        }
    }
    componentWillReceiveProps(nextProps){

        this.setState({
            showTemplate:false,templateId:0
        }, () => {
            this.setUserData();
        });
    }
    componentDidMount(){
        this.setUserData();
    }

    setUserData = () =>{
        const {match : {params}} = this.props;
        if(params.exhibitorId == localStorage.getItem('userId')){
            this.gettingTempId();
            return false;
        } 
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = -10;

            if(rolesArr.length > 2 ){
                currentRole = -10;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                currentRole = -1;
            }
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                currentRole = -6;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                currentRole = -1;
            }
            if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                currentRole = -1;
            }
            localStorage.setItem('currentRole',currentRole);
        }
        const token = localStorage.exhibitorToken;
        const decoded = jwt_decode(token);
        const details = {
            username: decoded.email,
            eventId:localStorage.getItem('eventId'),
            role:currentRole.toString()
        }

        Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            .then(res => {
                this.setState({pageLoader:false});
                localStorage.setItem('useraccname',res.data.data[0].name);
                if(res.data.data[0].userId)localStorage.setItem('userId',res.data.data[0].userId);
                if(res.data.data[0].exhibitorId)localStorage.setItem('userId',res.data.data[0].exhibitorId);
                if(res.data.data[0].speakerId)localStorage.setItem('userId',res.data.data[0].speakerId);
                localStorage.setItem('userName',res.data.data[0].email);
                this.gettingTempId();
                
            })
            .catch(err => {
                console.log(err);
            });
    
    }

    gettingTempId = () =>{
        const {match : {params}} = this.props;
            Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorInfo?exhibitorId=${params.exhibitorId}&eventId=${localStorage.getItem('eventId')}`, 
            {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            if(!res.data.errorCode){

                this.setState({ showTemplate:true,templateId:res.data.data[0].template})
            }else{
    
            }
            this.setState({ pageLoader:false})   

        })
        .catch(err => {
            console.log(err);
        });
    }
    

    render() {
        const passprops = this.props;
        
        if(this.state.showTemplate){

        if(this.state.templateId === 1){
            return (
                <AttendeeExhibitorBoothTemp1 passprops= {passprops}/>
                )
        }else{
            return (
                <AttendeeExhibitorBoothTemp2 passprops= {passprops}/>
                )
        }
        }else{
        return (
                    <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>

            )
        } 



    }
}
export default AttendeeExhibitorBooth;
