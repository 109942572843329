import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import io  from 'socket.io-client';
import Axios from 'axios';
const socket = io.connect('https://tslvirtual-chat.herokuapp.com');


class Header extends Component {
    constructor(props){
        super(props);
        this.state={
            dropMenu:false,
            quickDropMenu:false,
            allowAsExhibitor:false,
            allowAsSpeaker : false,
            allowAsLobbier : true,
        }
    }
    logOut = (e) => {
        e.preventDefault();

        const logoutData = {
            email: localStorage.getItem('userName'),
            eventId:localStorage.getItem('eventId'),
           }
       
          Axios.post(`${global.config.requestUrl}user/logoutUser`, logoutData,
          {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
      )
        .then(res => {

        var eventId = localStorage.getItem('eventId');
        var eventName = localStorage.getItem('eventName');
        var userName = localStorage.getItem('userName');
        var tokenpass = localStorage.getItem('tokenpass');
        /** */
        socket.emit('usersCount', eventId);
        socket.on('users', function(message) {
         console.log(message);
         if(document.getElementById("total_online"))
           document.getElementById('total_online').innerHTML = 'Total Online: '+message[0].total_count;
        });


        /** */

        localStorage.clear();
        localStorage.setItem('eventName',eventName);
        this.props.history.push(`/paos/logoutSuccess/${eventId}/${userName}/${tokenpass}`)
        
          })
          .catch(err => {
            console.log(err);
          });  
        
    }
   
    dropMenuToggle = e => {
        this.setState({dropMenu:!this.state.dropMenu, quickDropMenu:false})
    }
    quickDropMenuToggle = e => {
        /* e.preventDefault(); */
        this.setState({quickDropMenu:!this.state.quickDropMenu, dropMenu:false})
    }
    componentDidMount(){
        this.callSocketForOnlineUsers();
        this.callSocketForLogoutCurrentUser(this.props);
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (localStorage.getItem('companyContactUrl')) {
            localStorage.getItem('companyContactUrl')
        }
    }
    componentWillReceiveProps(props) {
        if(localStorage.getItem('exhibitorToken')){
            this.callSocketForOnlineUsers();
            this.callSocketForLogoutCurrentUser(props);
        }
           
    }
    /* callSocketForLogoutCurrentUser function is useful when developer want to force logout all the users from frontend,
    *  this can be done if we set all the users to set 0 in field islogin in regaccesscode table.
    */
    callSocketForLogoutCurrentUser = (currentProp) => {
        
        socket.on('logoutUsers', function(message) {
            var eventId = localStorage.getItem('eventId');
            var eventName = localStorage.getItem('eventName');
            var userName = localStorage.getItem('userName');
            var tokenpass = localStorage.getItem('tokenpass');
            localStorage.clear();
            localStorage.setItem('eventName',eventName);
            localStorage.setItem('eventId',eventId);
            currentProp.history.push(`/paos/logoutSuccess/${eventId}/${userName}/${tokenpass}`)
        });
    }

    callSocketForOnlineUsers = () => {
        socket.emit('usersCount', localStorage.getItem('eventId'));
        socket.on('users', function(message) {
         if(document.getElementById("total_online"))
           document.getElementById('total_online').innerHTML = 'Total Online: '+message[0].total_count;
        });
    }
    render() {
        var rolesArr = [];
        if(localStorage.getItem('roles')){
             rolesArr = localStorage.getItem('roles').split(",");
             var pImg = process.env.PUBLIC_URL + '/images/placeholder-profile.jpg';
             var editProfileUrl = window.location.pathname;

            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null ))
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-10' && localStorage.getItem('userProfilePic') !== "" && localStorage.getItem('userProfilePic') !== null)
                pImg = localStorage.getItem('userProfilePic');
            else if(localStorage.getItem('currentRole') === '-6' && localStorage.getItem('speakerProfilePic') !== '' && localStorage.getItem('speakerProfilePic') !== null)
                pImg = localStorage.getItem('speakerProfilePic');
            else if(localStorage.getItem('currentRole') === '-1' && localStorage.getItem('companyContactUrl') !== '' && localStorage.getItem('companyContactUrl') !== null)
                pImg = localStorage.getItem('companyContactUrl');

            
            if((localStorage.getItem('currentRole') === '-1' && rolesArr.indexOf('-7') !== -1 ) || localStorage.getItem('currentRole') === '-10')
                editProfileUrl = '/paos/user/profile';
            else if(localStorage.getItem('currentRole') === '-6')
                editProfileUrl = '/paos/speaker/profile';
            else if(localStorage.getItem('currentRole') === '-1')
                editProfileUrl = '/paos/exhibitor/profile';

            
        }

        




        const userNotLoggedIn = (
            <li className="nav-item ">
                <button onClick={this.dropMenuToggle}>
                    <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                </button>
            </li> 
        );
        const userLoggedIn = (
            <React.Fragment>
                <li className="nav-item quickLinkBtn">
                    <button onClick={this.quickDropMenuToggle}><i className="fa fa-link"></i> <em>Quick Links</em> <span className="fa fa-angle-down ml-2"></span></button>
                    {
                        this.state.quickDropMenu
                        ?
                        <ul className="dropMenu">
                            <li><Link onClick={this.quickDropMenuToggle} to="/paos/attendee/technicalSessions"><i className="fa fa-archive"></i>Search Technical Sessions & Speakers</Link></li>
                            <li><Link onClick={this.quickDropMenuToggle} to="/paos/attendee/exhibitHall?popup=1"><i className="fa fa-desktop"></i> Search Exhibit Hall</Link></li>
                        </ul>
                        :
                        null
                    }
                </li>
                {/* <li className="nav-item totalOnline">
                    <span id="total_online">Total Online: 0</span>
                </li> */}
                <li className="nav-item userProfilePic">
                    <img src={pImg} alt="" id="profile_img" />
                </li> 
                <li className="nav-item">
                    <button onClick={this.dropMenuToggle}>
                        <img src={process.env.PUBLIC_URL + '/images/toggle-icon.png'} alt="" />
                    </button>
                    {
                        this.state.dropMenu 
                        ?
                        <ul className="dropMenu">

                            { 
                                    rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1
                                    ?
                                    (<li onClick={this.dropMenuToggle}><Link to="/paos/exhibitor/dashboard"><i className="fa fa-user"></i> Exhibitor Dashboard</Link></li>
                                    ):null
                            }
                            {/* uncomment this if you want to show link for speaker dashboard */}
                            {/* { 
                                    rolesArr.indexOf('-6') !== -1
                                    ?
                                    (<li onClick={this.dropMenuToggle}><Link to="/paos/speaker/dashboard"><i className="fa fa-user"></i> Speaker Dashboard</Link></li>
                                    ):null
                            } */}
                            
                            <li onClick={this.dropMenuToggle}><Link to="/paos/attendee/lobby"><i className="fa fa-user"></i> Attendee Lobby</Link></li>
                            {
                                editProfileUrl !== '/paos/loginSplash'
                                ?
                                <li><Link onClick={this.dropMenuToggle} to={editProfileUrl} ><i className="fa fa-user" aria-hidden="true"></i> Edit Profile</Link></li>
                                :
                                null
                            }

                            

                            <li onClick={this.dropMenuToggle}><Link to="/" onClick={this.logOut}><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</Link></li>
                        </ul>
                        :
                        null
                    }
                </li>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <header className="pageMainHeader">
                    <nav className="navbar navbar-expand-sm" id="navMenu">
                        <div className="container">
                            <Link className="navbar-brand" to="/paos/loginSplash">
                                <img src={process.env.PUBLIC_URL + '/images/paos/logo.png'} alt="" />
                            </Link>
                            <div id="collapsibleNavbar">
                            <ul className="navbar-nav ml-auto">
                                {localStorage.getItem("exhibitorToken") ? userLoggedIn : userNotLoggedIn}
                            </ul>
                            </div>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        )
    }
}
export default withRouter(Header);