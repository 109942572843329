import React, { Component } from 'react';
import { Link} from 'react-router-dom';
import Axios from 'axios';
import reactCSS from 'reactcss';
import TechnicalMarketingDocs from './TechnicalMarketingDocs';
import FollowupList from './FollowupList';
import BoothContactList from './BoothContactList';
// import BottomNav from './BottomNav';
import FilterableExhibitorList from './FilterableExhibitorList';
import BoothChat from "../../../TmChat/navigate2022/boothChat/BoothChat";
import '../ExhibitorBoothTemp2.css'
import BottomNav from './BottomNav';


class AttendeeExhibitorBoothTemp2 extends Component {
    constructor(props){
        super(props);
        this.state = {
            companyBioModal:false,
            technicalMarketingModal:false,
            boothId:null,
            signBgcolor: '#073382',
            wallBgcolor: '#073382',
            companyBioText :'',
            companyVideoFileUrl :'',
            productDemoVideoFileUrl :'',
            bannerText: '',
            media3FileUrl :'',
            exhibitCompanyLogo: '',
            pageLoader:false,
            media1Type:1,
            media2Type:1,
            media3Type:1,
            pageLoader:true,
            prevExhibitorId:null,
            currentExhibitorId:null,
            nextExhibitorId:null,
            exhibitListModal:false,
            
            companyData: [],
            company : "",
            address1: "",
            city: "",
            state:"",
            zip: "",
            country:"",
            website: "",
            exhibitorValueType:"",
        }

    }
    componentWillReceiveProps(nextProps){
        this.updateDom(nextProps.passprops);
     this.visitorsAnalytics(nextProps.passprops);
   }
    // componentDidMount(){
    //  this.updateDom(this.props.passprops);
    //  this.visitorsAnalytics(this.props.passprops);
         
    // }

    visitorsAnalytics = (myprop) => {
            const {match : {params}} = myprop;

            if(params.exhibitorId == localStorage.getItem('userId')) return false;
            // -1,-6,1441  => -10
            // -1          => -1
            // -6          => -6
            // 1441        => -10
            // -1,1441     => -10
            // -6,1441     => -10
            // -1,-6     => -1 
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = -10;

            if(rolesArr.length > 2 ){
                currentRole = -10;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                currentRole = -1;
            }
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                currentRole = -6;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                currentRole = -1;
            }
            localStorage.setItem('currentRole',currentRole);
        }
            const visitorAnalyticData = {
                exhibitorId: params.exhibitorId,
                visitorId:localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                role:currentRole,
                isLead:0
            }
            Axios.post(`${global.config.requestUrl}user/insertExhibitorBoothVisitor`, visitorAnalyticData,
                        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            ).then(res => {
                
                
            })
            .catch(err => {
                console.log(err);
            });
    }

    leadsAnalytics = () => {
        const {match : {params}} = this.props.passprops;
        if(params.exhibitorId == localStorage.getItem('userId')) return false;
           // -1,-6,1441  => -10
            // -1          => -1
            // -6          => -6
            // 1441        => -10
            // -1,1441     => -10
            // -6,1441     => -10
            // -1,-6     => -1 
            if(localStorage.getItem('roles')){
                var rolesArr = localStorage.getItem('roles').split(",");
                var currentRole = -10;
    
                if(rolesArr.length > 2 ){
                    currentRole = -10;
                }
                if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                    currentRole = -1;
                }
                if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                    currentRole = -6;
                }
                if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                    currentRole = -1;
                }
                localStorage.setItem('currentRole',currentRole);
            }
        const leadAnalyticData = {
            exhibitorId: params.exhibitorId,
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            role:currentRole,
            isLead:1
        }
        Axios.post(`${global.config.requestUrl}user/insertExhibitorBoothVisitor`, leadAnalyticData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        ).then(res => {
            
            
        })
        .catch(err => {
            console.log(err);
        });
    }
     updateNexPrev(exhibitorId){
        this.setState({pageLoader:true});
        const exhibdata = {
            exhibitorId: exhibitorId,
            eventId: localStorage.getItem('eventId'),
            
           }
        Axios.post(`${global.config.requestUrl}user/getNextPrevExhibitor`, exhibdata,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(!res.data.errorCode){
            if(res.data.data.length < 2){
                if(res.data.data[0].exhibitorId < exhibitorId){
                    this.setState({prevExhibitorId:res.data.data[0].exhibitorId,nextExhibitorId:null}); 
                }else{
                    this.setState({prevExhibitorId:null,nextExhibitorId:res.data.data[0].exhibitorId}); 
                }

            }else{
                var prev = res.data.data[0].exhibitorId;
                var next = res.data.data[1].exhibitorId;
                this.setState({prevExhibitorId:prev,nextExhibitorId:next});
            }
            this.setState({pageLoader:false});
        }else{
        this.setState({
            pageLoader:false,
        });
     }
    })
    .catch(err => {
        console.log(err);
    });
     }

     updateGoldenTicketStatus = (exhibitorId) => {
        this.setState({pageLoader:true});
        const ticketStatusData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:0,
            linkPage:'exhibitBooth',
            sessionId:0,
            exhibitorId:exhibitorId
            
           }
        Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(res.data.errorCode){
            document.getElementById('goldenTkt').classList.add("greyScale");
            this.setState({pageLoader:false});
        }else{
            this.setState({pageLoader:false});
            document.getElementById('goldenTkt').classList.remove("greyScale");
        }
    })
    .catch(err => {
        console.log(err);
    });
     }



    updateDom(myprop){
        localStorage.setItem('exhibitBoothClicked',0);
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        this.setState({currentExhibitorId:params.exhibitorId});
         
        Axios.get(`${global.config.requestUrl}user/getAttendeeBoothData?exhibitorId=${params.exhibitorId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
        if(!res.data.errorCode){
            if(res.data.data[0].bannerText ==  '0'){
                document.getElementById("bannerhtml").innerHTML = '<img src="'+res.data.data[0].banner+'"/>';
            }
            if(res.data.data[0].bannerText ==  '1'){
                var banner_text = res.data.data[0].banner;
                document.getElementById('bannerhtml').innerHTML = banner_text.replace(/\\"/g, '"').replace(/\\n/g, '');
            }

            var valueTypeImg = '';
            if(res.data.data[0].exhibitor_type == 1) valueTypeImg = 'diamond.png';
            if(res.data.data[0].exhibitor_type == 2) valueTypeImg = 'gold.png';
            if(res.data.data[0].exhibitor_type == 3) valueTypeImg = 'silver.png';

            if(res.data.data[0].boothId !== null){
                this.setState({
                    boothId:res.data.data[0].boothId.toString(),
                    signBgcolor: res.data.data[0].bgColor,
                    wallBgcolor: res.data.data[0].wallColor,
                    companyBioText :res.data.data[0].bio,
                    companyVideoFileUrl :res.data.data[0].companyVideoLink,
                    productDemoVideoFileUrl :res.data.data[0].productDemoLink,
                    media3FileUrl :res.data.data[0].media3,
                    exhibitCompanyLogo: res.data.data[0].banner,
                    media1Type:res.data.data[0].media1Type,
                    media2Type:res.data.data[0].media2Type,
                    media3Type:res.data.data[0].media3Type,
                    bannerText: res.data.data[0].bannerText,
                    company:res.data.data[0].company,
                    address1: res.data.data[0].address1,
                    address2:res.data.data[0].address2,
                    city: res.data.data[0].city,
                    state:res.data.data[0].state,
                    zip: res.data.data[0].zip,
                    website:res.data.data[0].website,
                    exhibitorValueType:valueTypeImg,
                    pageLoader:false,
                });
            }else{

                document.getElementById("bannerhtml").innerHTML = '';

                this.setState({
                    boothId:null,
                    signBgcolor: '#073382',
                    wallBgcolor: '#073382',
                    companyBioText :'',
                    companyVideoFileUrl :'',
                    productDemoVideoFileUrl :'',
                    bannerText: '',
                    media3FileUrl :'',
                    exhibitCompanyLogo: '',
                    pageLoader:false,
                    media1Type:1,
                    media2Type:1,
                    media3Type:1,
                    company:res.data.data[0].company,
                    address1: res.data.data[0].address1,
                    address2:res.data.data[0].address2,
                    city: res.data.data[0].city,
                    state:res.data.data[0].state,
                    zip: res.data.data[0].zip,
                    website:res.data.data[0].website,
                    pageLoader:false,
                });

            }

        
        }else if(res.data.errorCode === 1){
            document.getElementById('bannerhtml').innerHTML = '';
            this.setState({
            boothId:null,
            signBgcolor: '#073382',
            wallBgcolor: '#073382',
            companyBioText :'',
            companyVideoFileUrl :'',
            productDemoVideoFileUrl :'',
            bannerText: '',
            media3FileUrl :'',
            exhibitCompanyLogo: '',
            media1Type:1,
            media2Type:1,
            media3Type:1,
            company:'',
            address1: '',
            address2:'',
            city: '',
            state:'',
            zip: '',
            pageLoader:false,

            });
      
     }else{
        this.setState({
            pageLoader:false,
        });
     }
     this.updateNexPrev(params.exhibitorId);
     this.updateGoldenTicketStatus(params.exhibitorId);
     /* To reloade video file */
     var element =  document.getElementsByClassName('otherVideo');
     for(var i = 0; i < element.length; i++){
            element[i].load()
     }
    })
    .catch(err => {
        console.log(err);
    });
    }

    exhibitListModalHandler = () => {
        this.setState({exhibitListModal:!this.state.exhibitListModal})
        this.leadsAnalytics();
        localStorage.setItem('exhibitBoothClicked',1)
    }
    companyBioModalHandeler = (e) => {
        this.setState({companyBioModal:!this.state.companyBioModal})
        this.leadsAnalytics();
        localStorage.setItem('exhibitBoothClicked',1)
    }
    technicalMarketingModalHandeler = (e) => {
        this.setState({technicalMarketingModal:!this.state.technicalMarketingModal})
        this.leadsAnalytics();
        localStorage.setItem('exhibitBoothClicked',1)
    }
    followUpsModalHandeler = (e) => {
        this.setState({followUpsModalHandeler:!this.state.followUpsModalHandeler})
        this.leadsAnalytics();
        localStorage.setItem('exhibitBoothClicked',1)
    }

    boothContactModalHandeler = (e) => {
        this.setState({boothContactModalHandeler:!this.state.boothContactModalHandeler})
        this.leadsAnalytics();
        localStorage.setItem('exhibitBoothClicked',1)
    }

    render() {
        const passprops = this.props.passprops;
        const styles = reactCSS({
            'default': {
              signColor: {
                background: this.state.signBgcolor,
              },
              wallColor: {
                background: this.state.wallBgcolor,
              }
            },
          });
        return (
            <React.Fragment>

                <section className="bg13 signleScreen exhibitorBoothSec attendeeExhibitor exhibitorBoothSec2">
                    <div className="companyDetailsBanner">
                        <div className="cmpnyDetailsWrp">
                            <div className="cmpnyDetailsnir">
                                <img src={process.env.PUBLIC_URL + '/images/'+this.state.exhibitorValueType} alt=""/>
                                <p>{this.state.company}</p>
                                <p>{this.state.address1}</p>
                                <p>
                                    {this.state.city !== ''?(this.state.city):null}
                                    {this.state.state !== ''?(', '+this.state.state):null}
                                    {/* {this.state.zip !== ''?(', '+this.state.zip):null} */}
                                </p>
                                <p>{this.state.country}</p>
                                <p className="wordBreak"><a href={this.state.website} target="_blank">Website</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="responsiveBoothWrapper">
                        <div className="exhibitorBoothWrapper">
                            <div className="exhibitorBoothFrame">
                                <img src={process.env.PUBLIC_URL + '/images/exhibitor-booth-frame-2.png'} alt="" />
                            </div>
                            <div className="exhibitWallBackground" style={ styles.wallColor } ></div>
                            <div className="exhibitCompanyLogo">
                                <div className="uploadPlaceholder">
                                
                                <div id="bannerhtml"></div>
                                {
                                this.state.exhibitCompanyLogo == ''
                                ?
                                (
                                    <span>Company Logo Banner</span>
                                )
                                :
                                null
                                }

                            
                                    
                                </div> 
                            </div>
                            
                            <div className="exhibitMarketingDoc topInputHolder" style={ styles.signColor } onClick={this.technicalMarketingModalHandeler}>
                                <div className="uploadPlaceholder">
                                    <span>Technical and Marketing Documents</span>
                                </div>
                            </div>
                            
                            
                            <div className="exhibitCompanyBio topInputHolder" style={ styles.signColor } onClick={this.companyBioModalHandeler}>
                                <div className="uploadPlaceholder">
                                    <span>Company Bio</span>
                                </div>
                            </div>
                            
                        

                            
                        <div className="exhibitVideo companyVideo" >
                        {
                            this.state.companyVideoFileUrl ===''
                            ? 
                            
                                <div className="uploadPlaceholder">
                                    <span>Video/Images 1</span>
                                </div>
                            
                                : 
                                <div className="uploadPlaceholder">
                                {
                                    this.state.media1Type === 1
                                    ?
                                    <React.Fragment>
                                    {
                                        this.state.companyVideoFileUrl.startsWith("https://www.youtube") || this.state.companyVideoFileUrl.startsWith("https://player.vimeo.com/video/")
                                        ?
                                        <iframe width="100%" height="100%" src={`${this.state.companyVideoFileUrl}?autoplay=false`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false"></iframe>
                                        :
                                        this.state.companyVideoFileUrl.includes(".pdf")
                                        ?
                                        <img src={process.env.PUBLIC_URL + '/images/pdf_icon.png'} alt="" />
                                        :
                                        <video className="otherVideo" controls muted>
                                        <source src={this.state.companyVideoFileUrl} type="video/mp4"/>
                                        </video>
                                    }
                                    </React.Fragment>
                                    :
                                    <img src={this.state.companyVideoFileUrl} alt="" />
                                }
                                    <div className="hover-overlay">
                                        <div className="btnList">
                                            <button onClick = {this.leadsAnalytics}><a href={this.state.companyVideoFileUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-eye"></i></a></button>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div> 
                            
                            
                            <div className="exhibitVideo prodDemoVideo" >
                                <div className="uploadPlaceholder">
                                {
                            this.state.productDemoVideoFileUrl === ''
                            ? 
                            
                                <div className="uploadPlaceholder">
                                    <span>Video/Images 2</span>
                                </div>
                            
                                : 
                                <div className="uploadPlaceholder">
                                {
                                    this.state.media2Type === 1
                                    ?
                                    <React.Fragment>
                                    {
                                        this.state.productDemoVideoFileUrl.startsWith("https://www.youtube") || this.state.productDemoVideoFileUrl.startsWith("https://player.vimeo.com/video/")
                                        ?
                                        <iframe width="100%" height="100%" src={`${this.state.productDemoVideoFileUrl}?autoplay=false`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false"></iframe>
                                        :
                                        this.state.productDemoVideoFileUrl.includes(".pdf")
                                        ?
                                        <img src={process.env.PUBLIC_URL + '/images/pdf_icon.png'} alt="" />
                                        :
                                        <video className="otherVideo" controls muted>
                                        <source src={this.state.productDemoVideoFileUrl} type="video/mp4"/>
                                        </video>
                                    }
                                    </React.Fragment>
                                    :
                                    <img src={this.state.productDemoVideoFileUrl} alt="" />
                                }
                                    <div className="hover-overlay">
                                        <div className="btnList">
                                            <button onClick = {this.leadsAnalytics}><a href={this.state.productDemoVideoFileUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-eye"></i></a></button>
                                        </div>
                                    </div>
                                </div>
                            }
                                </div>
                            </div>
                            
                            <div className="exhibitVideoWideBx" >
                                <div className="uploadPlaceholder">
                                {
                            this.state.media3FileUrl === ''
                            ? 
                            
                                <div className="uploadPlaceholder">
                                    <span>Video/Images 3</span>
                                </div>
                            
                                : 
                                <div className="uploadPlaceholder">
                                {
                                    this.state.media3Type === 1
                                    ?
                                    <React.Fragment>
                                    {
                                        this.state.media3FileUrl.startsWith("https://www.youtube") || this.state.media3FileUrl.startsWith("https://player.vimeo.com/video/")
                                        ?
                                        <iframe width="100%" height="100%" src={`${this.state.media3FileUrl}?autoplay=false`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false"></iframe>
                                        :
                                        this.state.media3FileUrl.includes(".pdf")
                                        ?
                                        <img src={process.env.PUBLIC_URL + '/images/pdf_icon.png'} alt="" />
                                        :
                                        <video className="otherVideo" controls muted>
                                        <source src={this.state.media3FileUrl} type="video/mp4"/>
                                        </video>
                                    }
                                    </React.Fragment>
                                    :
                                    <img src={this.state.media3FileUrl} alt="" />
                                }
                                    <div className="hover-overlay">
                                        <div className="btnList">
                                            <button onClick = {this.leadsAnalytics}><a href={this.state.media3FileUrl} target="_blank" rel="noopener noreferrer"><i className="fa fa-eye"></i></a></button>
                                        </div>
                                    </div>
                                </div>
                            }
                                </div>
                            </div> 
                            

                            <div className="contactFollowups" style={ styles.signColor } onClick={this.followUpsModalHandeler}>
                                <div className="uploadPlaceholder">
                                    <span>Contact Me About</span>
                                </div>
                            </div>
                            
                            
                            <div className="boothContacts" style={ styles.signColor } onClick={this.boothContactModalHandeler}>
                                <div className="uploadPlaceholder">
                                    <span> Booth Contacts</span>
                                </div>
                            </div>
                            
                            

                        </div>
                    </div>
                    {/* <BottomNav /> */}
                    <div className="exhibitorNavigationBtn">

                    {
                        this.state.prevExhibitorId
                        ?
                        (
                            <Link to={`/navigate2022/attendee/exhibitorBooth/${this.state.prevExhibitorId}`} className="btn btn-primary prevBtn"><i className="fa fa-long-arrow-left"></i>&nbsp; <span>Previous Booth</span></Link>
                        )
                        :
                        null
                    }

                    {
                        this.state.nextExhibitorId
                        ?
                        (
                            <Link to={`/navigate2022/attendee/exhibitorBooth/${this.state.nextExhibitorId}`} className="btn btn-primary nxtBtn"> <span>Next Booth</span> &nbsp;<i className="fa fa-long-arrow-right"></i></Link>
                        )
                        :
                        null
                    }

                    </div>
                    {/* <div className="bootomButton"><button className="btn btn-success" onClick={this.exhibitListModalHandler}><i className="fa fa-eye"></i> View All Exhibitors</button></div> */}

                    {
                        this.state.exhibitListModal
                        ?
                        <div className="midModal">
                            <div className="midTable exhibitorListTable">
                                <div className="modalPopUpClose" id="exhibitpopclose"  onClick={this.exhibitListModalHandler}><i className="fa fa-times"></i></div>
                                <FilterableExhibitorList/>
                                
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                    {/*===========technicalMarketingModal============*/}
                    {
                        this.state.technicalMarketingModal 
                        ?
                        (
                            <div className="modalPopUp">
                                <div className="modalPopUpInr width800">
                                    <div className="modalPopUpClose" onClick={this.technicalMarketingModalHandeler}><i className="fa fa-times"></i></div>
                                    <div className="modalPopUpFormBx">
                                        <h3 className="mt-3 mb-3">Technical and Marketing Documents</h3>
                                         <TechnicalMarketingDocs passprops= {passprops}/>  
                                    </div>
                                </div>
                            </div> 
                        )
                        :
                        null
                    }
                    {/*===========companyBioModal============*/}
                    {
                        this.state.companyBioModal 
                        ?
                        (
                            <div className="modalPopUp">
                                <div className="modalPopUpInr width800">
                                    <div className="modalPopUpClose" onClick={this.companyBioModalHandeler}><i className="fa fa-times"></i></div>
                                    <div className="modalPopUpFormBx">
                                        <h3 className="mt-3 mb-3">Company Bio</h3>
                                        <div className="textOverflow">
                                            {this.state.companyBioText.split('\n').map(function(item, key) {
                                            return (
                                                    <span key={key}>
                                                    {item}
                                                    <br/>
                                                    </span>
                                                    )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        )
                        :
                        null
                    }
                    {/*===========boothContactModalHandeler============*/}
                    {
                        this.state.boothContactModalHandeler 
                        ?
                        (
                            <div className="modalPopUp">
                                <div className="modalPopUpInr width1100">
                                    <div className="modalPopUpClose" onClick={this.boothContactModalHandeler}><i className="fa fa-times"></i></div>
                                    <div className="modalPopUpFormBx">
                                        <h3 className="mt-3 mb-3">Booth Contacts</h3>
                                         <BoothContactList passprops= {passprops}/>  
                                    </div>
                                </div>
                            </div> 
                        )
                        :
                        null
                    }
                    {/*===========boothContactModalHandeler============*/}
                    {
                        this.state.followUpsModalHandeler 
                        ?
                        (
                            <div className="modalPopUp">
                                <div className="modalPopUpInr width800">
                                    <div className="modalPopUpClose" onClick={this.followUpsModalHandeler}><i className="fa fa-times"></i></div>
                                    <div className="modalPopUpFormBx">
                                        <h3 className="mt-3 mb-3">Followups</h3>
                                         <FollowupList passprops= {passprops}/>  
                                    </div>
                                </div>
                            </div> 
                        )
                        :
                        null
                    }
                    <BottomNav enblGoldTktforThisExhib ={this.props.enblGoldTktforThisExhib}/>
                    <div className="boothChatIco">
                        <BoothChat exhibitorId= {passprops.match.params.exhibitorId}/>
                    </div>

                </section>
            </React.Fragment>
        )
    }
}
export default AttendeeExhibitorBoothTemp2;
