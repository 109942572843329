import React, { Component, useState } from "react";
import Axios from 'axios';

export default class CustomCourseStudyFilter extends Component {
    constructor(props) {
        super(props);
 
        this.state = {
            filterTrackId: 0,
            trackList:[],
        }
    }

    componentDidMount(){
        this.getTrackList();
    }


 
    doesFilterPass(params) {
        //console.log(params.data,params.data.track_id,this.state.filterTrackId);
        return params.data.track_id === parseInt(this.state.filterTrackId);
    }

    getTrackList = () => {
        /* track list */
    
        Axios.get(
          `${
            global.config.requestUrl
          }getSessionTrackList?eventId=${localStorage.getItem("eventId")}`,
          { headers: { "auth-token": localStorage.exhibitorToken } }
        )
    
          .then((res) => {
            if (res.data.errorCode === 0) {
              this.setState({ trackList: res.data.data });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };
 
    isFilterActive() {

        if(this.state.filterTrackId === '0'){
            return 0;
        }else{
            return 1;
        }
        
        
    }
 
    // this example isn't using getModel() and setModel(),
    // so safe to just leave these empty. don't do this in your code!!!
    getModel() {
    }
 
    setModel() {
    }
 
    onFilterTrackIdChange(event) {
        this.setState({filterTrackId: event.target.value},
            () => this.props.filterChangedCallback()
        );
    }
 
    render() {
        return (
            <select name="filterTrackId" onChange={this.onFilterTrackIdChange.bind(this)}>
                <option  value="0" selected={this.state.filterTrackId === 0}>All</option>
                { 
                                                  this.state.trackList.map(trackdata => (
                                                    <option value={trackdata.trackId} key={trackdata.track}>{trackdata.track}
                                                    </option>
                                                   ))
                                                }
            </select>
        );
    }
 }