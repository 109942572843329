import React, { Component } from 'react';
import Axios from 'axios';
/* import { login } from '../../userFunctions'; */
/* import UserContext from '../Context/UserContext'; */
import ForgotPassword from './ForgotPassword';

import { withRouter} from 'react-router-dom';

var md5 = require('md5');

const initialState = {
    sponsersImgList : [],
    pageLoader:true,
}



class Login extends Component {
    constructor(props){
        super(props);

        this.state = initialState
        // if(localStorage.getItem('exhibitorToken')){
        //     this.props.history.push(`/csda/loginSplash/`)
        // }
        
    }



  componentDidMount(){
       
    const {match : {params}} = this.props;
     
    Axios.get(`${global.config.requestUrl}exhibitor?eventId=${params.loginId}&type=1`)
     .then(res => {
         localStorage.setItem('eventId', params.loginId)
         if(res.data.errorCode === 0){
             this.setState({sponsersImgList:res.data.data, sponsersUrl:res.data.logo, pageUrlVarify : true, pageLoader:false})
             
             
         } else{
             this.setState({sponsersImgList:[
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                 {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
             ]});
             this.setState({pageLoader:false})
         }
         
         /* console.log(res.data.data) */
         return res.data;
     })
     .catch(err => {
         console.log(err);
     });
     
 }
   


    render() {
            return (
                <React.Fragment>
               
                    <section className="bg2 signleScreen">
                            <div className="topHangerBrands">
                            
                                <ul>
                                    { 
                                        this.state.sponsersImgList.map(sponsers => (
                                            <li key={sponsers.logo}>
                                            <img src={process.env.PUBLIC_URL + '/images/top-brand-hanger-stand.png'} alt="" />
                                                <div className="brandLogoBx">
                                                    <img src={process.env.PUBLIC_URL + '/images/top-brand-hanger-bx.png'} alt="" />
                                                    <img src={sponsers.logo} alt="" className="topBrandLogo" />
                                                </div>
                                            </li>
                                        ))
                                        
                                    }                            
                                </ul>
                            </div>
                            <div className="logInForm">
                                <div className="loginImg">
                                    <img src={process.env.PUBLIC_URL + '/images/login-man.png'} alt="" />
                                </div>
                                <div className="loginFormBx">
                                    <img src={process.env.PUBLIC_URL + '/images/csda/sponsor.jpg'} alt="" />
                                    <h3>Conference Log In </h3>
                                    <p>This event is no longer available.  <br/>Thank you for attending</p>
                                </div>
                            </div>
                            
                            {/* <div className="regBx">
                                <p>Please use your unique link to sign back in to the event.</p>
                            </div> */}
                            
                            
                            
                            
                    </section>

                </React.Fragment>
            )
        
        
         
    }
}
export default withRouter(Login);
