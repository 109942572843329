import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from './PrivateRoute'

import Homepage from './Homepage'
import Header from './Components/vcd/Header';
import Login from './Components/vcd/Login';
import LoginSplash from './Components/vcd/LoginSplash';
import ForgotPassword from './Components/vcd/ForgotPassword';
import ResetPassword from './Components/vcd/ResetPassword';
import ExhibitorDetails from './Components/vcd/Exhibitor/ExhibitorDetails';
import ExhibitorBooth from './Components/vcd/Exhibitor/ExhibitorBooth';

import Dashboard from './Components/vcd/Exhibitor/Dashboard';
import Lobby from './Components/vcd/Attendee/Lobby';
import ExhibitHall from './Components/vcd/Attendee/ExhibitHall';
import NetworkingLounge from './Components/vcd/Attendee/NetworkingLounge';
import ConferenceCenter from './Components/vcd/Attendee/ConferenceCenter';
import ConferenceCenterPage from './Components/vcd/Attendee/ConferenceCenterPage';
import TechnicalSessions from './Components/vcd/Attendee/TechnicalSessions';
import ExhibitList from './Components/vcd/Attendee/ExhibitList';

import HelpDesk from './Components/vcd/Attendee/HelpDesk';
import GoldenTicket from './Components/vcd/Attendee/GoldenTicket';
import AttendeeExhibitorBooth from './Components/vcd/Attendee/AttendeeExhibitorBooth';
import NotFound from './Components/vcd/NotFound';

import DashboardSpeaker from './Components/vcd/Speaker/Dashboard';
import ProfileDetails from './Components/vcd/Speaker/ProfileDetails';
import SessionAttendees from './Components/vcd/Speaker/SessionAtendees';
import UserProfile from './Components/fna/UserProfile';

// import ChatPage from "./TmChat/vcd/chat/ChatPage";
// import VideoCall from "./TmChat/vcd/video/VideoCall";
// import ConferenceMeeting from "./TmChat/vcd/videoConference/conferenceMeeting";
// import Meetings from "./TmChat/vcd/videoConference/meetings";
import WorkProgress from './Components/vcd/WorkProgress';
import TrackList from './Components/vcd/Attendee/TrackList';
import Session from './Components/vcd/Attendee/Session';


/* import test from './Components/vcd/Exhibitor/test'; */

class Vcd extends Component {
constructor(){
  super();

  this.state={
    homePage:""
  }
}
componentDidMount(){
  //console.log(window.location.href)
  
}

  render() {

    const domain = window.location.href
    if(domain === ("http://localhost:3000/" || "https://affectionate-bohr-4d306b.netlify.app/")){
      return(
        <div className="App">
            <Router>
              <Route path="/" exact component={Homepage} />
              {/* <Route path="/home" exact component={Homepage} /> */}
            </Router>
        </div>
      )
    }
    else {
      return (
      <Router>
          <div className="App">
            
            <Header/>
            <Switch>
                
                <Route path="/vcd/login/:loginId" exact component={Login} />
                <PrivateRoute path="/vcd/loginSplash" exact component={LoginSplash} />
                <Route path="/vcd/forgotPassword" component={ForgotPassword} />
                <Route path="/vcd/resetPassword" component={ResetPassword} />
                {/* <PrivateRoute path="/vcd/chat" exact component={ChatPage} />
                <PrivateRoute path="/vcd/video-call" exact component={VideoCall} />
                <PrivateRoute path="/vcd/video-conference" exact component={Meetings} />
                <PrivateRoute path="/vcd/conference-meeting/:id" exact component={ConferenceMeeting} /> */}
                <PrivateRoute path="/vcd/exhibitor/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/vcd/exhibitor/profile" exact component={ExhibitorDetails} />
                <PrivateRoute path="/vcd/exhibitor/booth" component={ExhibitorBooth} />
                <PrivateRoute path="/vcd/attendee/lobby" component={Lobby} />
                <PrivateRoute path="/vcd/attendee/exhibitHall" component={ExhibitHall} />
                <PrivateRoute path="/vcd/attendee/networkingLounge" component={NetworkingLounge} />
                <PrivateRoute path="/vcd/attendee/conferenceCenter" component={ConferenceCenter} />
                <PrivateRoute path="/vcd/attendee/session" component={Session} />
                <PrivateRoute path="/vcd/attendee/conferenceCenterDetails/:sessionId" component={ConferenceCenterPage} />
                <PrivateRoute path="/vcd/attendee/technicalSessions" component={TechnicalSessions} />
                <PrivateRoute path="/vcd/attendee/exhibitList" component={ExhibitList} />
                <PrivateRoute path="/vcd/attendee/helpDesk" component={HelpDesk} />
                <PrivateRoute path="/vcd/attendee/goldenTicket" component={GoldenTicket} />
                <PrivateRoute path="/vcd/attendee/exhibitorBooth/:exhibitorId" component={AttendeeExhibitorBooth} />
                <PrivateRoute path="/vcd/speaker/dashboard" exact component={DashboardSpeaker} />
                <PrivateRoute path="/vcd/speaker/profile" exact component={ProfileDetails} />
                <PrivateRoute path="/vcd/speaker/attendees" exact component={SessionAttendees} />
                <PrivateRoute path="/vcd/user/profile" exact component={UserProfile} />
                <PrivateRoute path="/vcd/attendee/track" exact component={TrackList} />
                <Route path="/vcd/workProgress" exact component={WorkProgress} />
                <Route path="*" component={NotFound} />
              </Switch>
         
          </div>
      </Router>
    );}
  }  
}

export default Vcd;
