import React, { Component } from 'react';
import Axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import '../Material.css';

class BoothContactTable extends Component {
    constructor() {
        super(...arguments);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top' };
        this.editparams = { params: { popupHeight: '300px' } };
        this.validationRule = { required: true };
        this.phoneRules = { required: true, number: true };
        this.emailRules = { required: true, email: true };
        this.pageSettings = { pageCount: 5 };
        this.format = { type: 'dateTime', format: 'M/d/y hh:mm a' };
        this.state = {
            contactData: null,
            pageLoader : true,
            errorMessage :null,
            successMessage:null,
            allowedBoothMembers:0,
            currentBoothMembers:0,
            includeMainContact:1,
            isChecked:'checked',
          };
    }

    componentDidMount() {
        var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }

        this.getAllowedBoothMembers(userId);
        this.getBoothContacts(userId);

      }

        getBoothContacts = (userId) => {
            Axios.get(`${global.config.requestUrl}exhibitor/getBoothContacts?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
            {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            var boothmemberscount = 0;
            if(res.data.data.length > 0){
                boothmemberscount = res.data.data.length-1;
            }
            this.setState({
                contactData:res.data.data,
                currentBoothMembers: boothmemberscount,
                pageLoader : false
            })
        })
        .catch(err => {
            console.log(err);
        }); 
        }

      getAllowedBoothMembers = (userId) =>{
        
            Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorInfo?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
            {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            if(!res.data.errorCode){

                this.setState({ allowedBoothMembers:res.data.data[0].max_booth_staff,includeMainContact:res.data.data[0].show_as_booth_staff})
                if(res.data.data[0].show_as_booth_staff === 1){
                    this.setState({isChecked:'checked'})
                }else{
                    this.setState({isChecked:''})
                }
            }
            this.setState({ pageLoader:false})   

        })
        .catch(err => {
            console.log(err);
        });
    }
    actionBegin(args) { 

        if(args.requestType === 'save' && (args.action === 'add' || args.action === 'edit')){
           /** Adding Max staff validation */

           if(this.state.allowedBoothMembers <= this.state.currentBoothMembers  && this.state.allowedBoothMembers > 0 && args.action === 'add'){
            this.setState({errorMessage : 'Limit reached for adding booth staff members',successMessage:''})
            this.componentDidMount();
            return false;
           }else{
            this.setState({errorMessage : ''}) 
           }

           /** */

            this.setState({
                pageLoader : true,
                errorMessage : null,
                successMessage : null
            })
            var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
            
            const newBoothContact = {
                contactId:args.data.contactId,
                eventId:localStorage.getItem('eventId'),
                memberId:userId,
                title: args.data.title,
                firstName: args.data.firstName.trim(),
                lastName:args.data.lastName.trim(),
                email:args.data.email,
                phone:args.data.phone,
                status:0
                
            }
            Axios.post(`${global.config.requestUrl}exhibitor/insertOrUpdateBoothContacts`, newBoothContact,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode){
                    this.setState({
                        errorMessage : res.data.errorMessage
                    })
                }else{
                    this.setState({
                        successMessage : res.data.errorMessage
                    })
                     
                }
                this.componentDidMount();
            })
            .catch(err => {
                console.log(err);
            }); 

        }

        if(args.requestType === 'delete'){
            this.setState({
                pageLoader : true,
                errorMessage : null,
                successMessage : null
            })
            var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
           
            const newBoothContact = {
                contactId:args.data[0].contactId,
                eventId:localStorage.getItem('eventId'),
                memberId:userId,
                title: args.data[0].title,
                firstName: args.data[0].firstName.trim(),
                lastName:args.data[0].lastName.trim(),
                email:args.data[0].email,
                phone:args.data[0].phone,
                status:1
            }
            Axios.post(`${global.config.requestUrl}exhibitor/insertOrUpdateBoothContacts`, newBoothContact,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode){
                    this.setState({
                        errorMessage : res.data.errorMessage
                    })
                }else{
                    this.setState({
                        successMessage : res.data.errorMessage
                    })
                     
                }
                this.componentDidMount();
            })
            .catch(err => {
                console.log(err);
            }); 

        }

        if (args.requestType === 'save') {
            if (this.gridInstance.pageSettings.currentPage !== 1 && this.gridInstance.editSettings.newRowPosition === 'Top') {
                args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - this.gridInstance.pageSettings.pageSize;
            }
            else if (this.gridInstance.editSettings.newRowPosition === 'Bottom') {
                args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - 1;
            }
        }
    }

    ddChange() {
        this.gridInstance.editSettings.newRowPosition = this.dropDownInstance.value;
    }
    handleIncludeMainContact = () =>{
        this.setState({pageLoader : true,contactData:null});
        if(this.state.includeMainContact === 2){
            this.setState({
                includeMainContact:1,
                isChecked:'checked'
            }, () => {
                this.refreshList();
            });
        }else{
            this.setState({
                includeMainContact:2,
                isChecked:''
            }, () => {
                this.refreshList();
            });
        }
    }

    refreshList = () => {
        var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
        const newShowData = {
            eventId:localStorage.getItem('eventId'),
            exhibitorId:userId,
            status:this.state.includeMainContact,
        }

        Axios.post(`${global.config.requestUrl}exhibitor/updateExhibitorBoothStatus`, newShowData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                this.componentDidMount();
            })
            .catch(err => {
                console.log(err);
            });
        
    }

    render() {
        return (
        <div className='control-pane'>
            <div className="checkBox">
                <input type="checkbox" name="includeMainContact" value={this.state.includeMainContact} id="includeMainContact" checked={this.state.isChecked} onChange={ this.handleIncludeMainContact }/>
                <label htmlFor="incValu">Include main contact as booth member</label>
            </div>
        <div className='control-section'>
          <div className='gridSecWrp'>
          <div className="success">
                  {this.state.successMessage}
              </div>
          <div className="error">
                  {this.state.errorMessage}
              </div>
          {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                        )
                        :
                        null
                    }
            <GridComponent dataSource={this.state.contactData} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowPaging={true} editSettings={this.editSettings} pageSettings={this.pageSettings} actionBegin={this.actionBegin.bind(this)}>
                <ColumnsDirective>
                    <ColumnDirective field='contactId' headerText='Contact ID' width='120' textAlign='Right' validationRules={this.phoneRules} isPrimaryKey={true} hideAtMedia></ColumnDirective>
                    <ColumnDirective field='firstName' headerText='First Name' validationRules={this.validationRule}></ColumnDirective>
                    <ColumnDirective field='lastName' headerText='Last Name' validationRules={this.validationRule}></ColumnDirective>
                    <ColumnDirective field='title' headerText='Title' validationRules={this.validationRule}></ColumnDirective>
                    <ColumnDirective field='email' headerText='Email' validationRules={this.emailRules}></ColumnDirective>
                    <ColumnDirective field='phone' headerText='Phone' validationRules={this.validationRule}></ColumnDirective>
                </ColumnsDirective>
              <Inject services={[Page, Toolbar, Edit]}/>
            </GridComponent>
          </div>
      </div>
      </div>);
    }
}
export default  BoothContactTable;