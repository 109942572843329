import axios from "axios";

//let BASEURL='https://api.tslvirtualconference.com:3000';
//let BASEURL='http://localhost:3000';
let BASEURL='https://tslvirtual-chat.herokuapp.com';

async function getSearchUsersList(parameters){
    return  new Promise(async (resolve)=>{
        parameters['eventId']=localStorage.getItem('eventId');
        parameters['loginId']=localStorage.getItem('userId');
        const usersListRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')},
            body: JSON.stringify(parameters)
        };
        const usersListResponse = await fetch(BASEURL+'/troop/searchUser', usersListRequestOptions);
        const userListdata = await usersListResponse.json();
        return  resolve(userListdata);
    });
}
async function getUsersList(parameters){
    return  new Promise(async (resolve)=>{
        parameters['eventId']=localStorage.getItem('eventId');
        parameters['loginId']=localStorage.getItem('userId');

        const usersListRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')},
            body: JSON.stringify(parameters)
        };
        const usersListResponse = await fetch(BASEURL+'/troop/getUserList', usersListRequestOptions);
        const userListdata = await usersListResponse.json();
        return  resolve(userListdata);
    });
}
async function getUsersListBasedOnRole(parameters){
    return  new Promise(async (resolve)=>{
        parameters['eventId']=localStorage.getItem('eventId');
        const usersListRequestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')},
            body: JSON.stringify(parameters)
        };
        const usersListResponse = await fetch(BASEURL+'/troop/getUserListByIds', usersListRequestOptions);
        let usersList = await usersListResponse.json();
        return  resolve(usersList);
    });
}

// to get the user details based on username and role
async function getLoginUserDetails(){
    return  new Promise(async (resolve)=>{
        try{
            const parameters= {'eventId':localStorage.getItem('eventId'),'username':localStorage.getItem('userName'),'role':localStorage.getItem('currentRole')}
            const  headers= {'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')};
            let userDetailsdata = await axios.post(BASEURL+'/user/getUserDetails',JSON.stringify(parameters),{
                headers: headers
            });
            userDetailsdata=userDetailsdata.data;
            return  resolve(userDetailsdata);
        }catch (e){
            return  resolve({'errorCode':e.status});
        }

    });
}
async function getUserDetails(userName,role){
    return  new Promise(async (resolve)=>{
        try{
            const parameters= {'eventId':localStorage.getItem('eventId'),'username':userName,'role':role}
            const  headers= {'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')};
            let userDetailsdata = await axios.post(BASEURL+'/user/getUserDetails',JSON.stringify(parameters),{
                headers: headers
            });
            userDetailsdata=userDetailsdata.data;
            return  resolve(userDetailsdata);
        }catch (e){
            return  resolve({'errorCode':e.status});
        }

    });
}
async function getBoothStaff(exhibitorId){
    return  new Promise(async (resolve)=>{
        try{
            const  headers= {'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')};
            let userDetailsdata = await axios.get(BASEURL+'/troop/getBoothContacts?eventId='+localStorage.getItem('eventId')+'&exhibitorId='+exhibitorId,{
                headers: headers
            });
            userDetailsdata=userDetailsdata.data;
            return  resolve(userDetailsdata);
        }catch (e){
            return  resolve({'errorCode':e.status});
        }

    });
}
async function getSessionDetails(sessionId){
    return  new Promise(async (resolve)=>{
        try{
            const  headers= {'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')};
            let userDetailsdata = await axios.get(BASEURL+'/troop/getSessionInfo?eventId='+localStorage.getItem('eventId')+'&sessionId='+sessionId,{
                headers: headers
            });
            userDetailsdata=userDetailsdata.data;
            return  resolve(userDetailsdata);
        }catch (e){
            return  resolve({'errorCode':e.status});
        }

    });
}
async function makeHost(details){
    return  new Promise(async (resolve)=>{
        try{
            const  headers= {  'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken')};
            let userDetailsdata = await axios.post(BASEURL+'/troop/updateSessionHost',JSON.stringify(details),{
                headers: headers
            });
            userDetailsdata=userDetailsdata.data;
            return  resolve(userDetailsdata);
        }catch (e){
            return  resolve({'errorCode':e.status});
        }

    });
}
const troopServer ={getLoginUserDetails,getSearchUsersList,getUsersList,getUserDetails,getUsersListBasedOnRole,getBoothStaff,getSessionDetails,makeHost};
export  default troopServer;