import React, { Component } from 'react'
import Axios from 'axios';
import { Accordion, Card, Button  } from 'react-bootstrap';


export default class MainSessionAllDetails extends Component {
    constructor(props){
        super(props);

        this.state={
            speakerBioContent:[],
            aboutSpeakerError:"",
            sessionDocuments:[],
            sessionDocumentsType1:"Document",
            speakerDocError:"",
            sessionBio:'',
            pageLoader:true,
        }
    }
    componentDidMount(){
        
        Axios.get(`${global.config.requestUrl}user/getSpeakerBio?eventId=${localStorage.getItem('eventId')}&sessionId=${this.props.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({speakerBioContent:res.data.data, aboutSpeakerError:res.data.errorCode, pageLoader:false})
        })
        .catch(err => {
            console.log(err);
        });
        Axios.get(`${global.config.requestUrl}user/getSessionDocuments?eventId=${localStorage.getItem('eventId')}&sessionId=${this.props.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({sessionDocuments:res.data.data, speakerDocError:res.data.errorCode, pageLoader:false})
        })
        .catch(err => {
            console.log(err);
        });

        Axios.get(`${global.config.requestUrl}user/getSessionBio?eventId=${localStorage.getItem('eventId')}&sessionId=${this.props.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({sessionBio:res.data.data[0].description})
            }
            this.setState({pageLoader:false})
        })
        .catch(err => {
            console.log(err);
        });
    }
    render() {
        return (
            <React.Fragment>
            {
                this.state.pageLoader
                ?
                (
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                )
                :
                null
            }
            {
                <React.Fragment>

                    {/* <h3>Description</h3> */}
                    <p>{this.state.sessionBio}</p>
                    <Accordion defaultActiveKey="0">
                    {
                        this.state.aboutSpeakerError !== 0
                        ?
                        null
                        :
                        <React.Fragment>
                            {
                                this.state.speakerBioContent.length === 1
                                ?
                                this.state.speakerBioContent.map(content => (
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                {content.name} - {content.credentials}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                <img src={content.profile_pic} alt="" className="speakerAbtPic"/>
                                                <h5 className="mb-3 mt-3">{content.title}</h5>
                                                {content.about}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))
                                :
                                this.state.speakerBioContent.map(content => (
                                    <Card key={content.speaker_id}>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey={content.speaker_id}>
                                               {content.name} - {content.credentials}
                                            </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={content.speaker_id}>
                                            <Card.Body>
                                                <img src={content.profile_pic} alt="" className="speakerAbtPic"/>
                                                <h5 className="mb-3 mt-3">{content.title}</h5>
                                                {content.about}
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                ))
                            }
                        </React.Fragment>
                    }
                    
                </Accordion>
                {
                    this.state.speakerDocError !== 0
                    ?
                    null
                    :
                    <div className="modalTableWrp">
                        <table className="table modalTable text-center mt-3">
                            <tbody>
                                    <React.Fragment>
                                        {
                                            this.state.sessionDocuments.map(content => (
                                                <tr key={content.doc_id}>
                                                    <td className="text-left">
                                                        <a href={content.file} target="_blank" rel="noopener noreferrer">{content.name}</a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </React.Fragment>
                            </tbody>
                        </table>
                    </div>
                }
                </React.Fragment>
            }
            </React.Fragment>
        )
    }
}
