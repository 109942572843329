import React,{useState} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from './Components/Tobi2022/Header';
import Login from './Components/Tobi2022/Login';
import Lobby from './Components/Tobi2022/Lobby';
import SpeakerList from './Components/Tobi2022/speakers/SpeakerList';
import SpeakerDetails from './Components/Tobi2022/speakers/SpeakerDetails';
import SponsorList from './Components/Tobi2022/sponsors/Sponsors';
import SponsorDetails from './Components/Tobi2022/sponsors/SponsorDetails';
import AttendeesList from './Components/Tobi2022/attendee/Attendees';
import AttendeeDetails from './Components/Tobi2022/attendee/AttendeeDetails';
import ExhibitHall from './Components/Tobi2022/attendee/ExhibitHall';
import ExhibitorDetails from './Components/Tobi2022/attendee/ExhibitorDetails';
import ConferenceSchedule from './Components/Tobi2022/conference/getSession';
import Maps from './Components/Tobi2022/Maps/Maps';
import MapDetail from './Components/Tobi2022/Maps/MapDetail';
import UserProfile from './Components/Tobi2022/UserProfile';
import LoginSplash from './Components/Tobi2022/LoginSplash';
import ExhibitorProfile from './Components/Tobi2022/Exhibitor/profile';
import SpeakerProfile from './Components/Tobi2022/speakers/SpeakerProfile';
import ChatPage from "./TmChat/tobi2022/chat/ChatPage";
import ChatNotification from "./TmChat/tobi2022/chat/ChatNotification";

import PrivateRoute from './PrivateRoute';


const Tobi2022 = () => {

    const [exhibitorToken,setExhibitorToken] = useState(localStorage.getItem('exhibitorToken') || null);

    return (
        <Router>
            <Header exhibitorToken={exhibitorToken} />
            {exhibitorToken?<ChatNotification />:''}
            <Switch>
                {/* <Route path="/" exact component={Login} /> */}
                <Route path="/tobi2022/login/:id" exact render={(props) => <Login setExhibitorToken={setExhibitorToken} {...props}/>} />
                <PrivateRoute path="/tobi2022/loginSplash" exact component={LoginSplash} />
                <PrivateRoute path="/tobi2022/conference" exact component={ConferenceSchedule} />
                <PrivateRoute path="/tobi2022/lobby" exact component={Lobby} />
                <PrivateRoute path="/tobi2022/speakers" exact component={SpeakerList} />
                <PrivateRoute path="/tobi2022/speakers/:id" exact component={SpeakerDetails} />
                <PrivateRoute path="/tobi2022/sponsors" exact component={SponsorList} />
                <PrivateRoute path="/tobi2022/sponsor/:id" exact component={SponsorDetails} />
                <PrivateRoute path="/tobi2022/attendees" exact component={AttendeesList} />
                <PrivateRoute path="/tobi2022/attendee/exhibitHall" exact component={ExhibitHall} />
                <PrivateRoute path="/tobi2022/attendee/exhibitor/:exhibitorId/:title" exact component={ExhibitorDetails} />
                <PrivateRoute path="/tobi2022/attendee/:emailId" exact component={AttendeeDetails} />
                <PrivateRoute path="/tobi2022/maps" exact component={Maps} />
                <PrivateRoute path="/tobi2022/maps/details" exact component={MapDetail} />
                <PrivateRoute path="/tobi2022/user/profile" exact component={UserProfile} />
                <PrivateRoute path="/tobi2022/exhibitor/profile" exact component={ExhibitorProfile} />
                <PrivateRoute path="/tobi2022/speaker/profile" exact component={SpeakerProfile} />

                /*
                <PrivateRoute path="/tobi2022/chat" exact component={ChatPage} />
                */

            </Switch>
        </Router>
    )
}
export default Tobi2022;