import React, {Component} from "react";


class ChatHeader extends Component {

    render() {
        return (
            <React.Fragment>
                <header className='chatMainHeader'>
                    <div className="px-3">
                        <div className="row">
                            <div className="col-md-3">
                                <img src={process.env.PUBLIC_URL + "/images/tmChat/tsl_logo.png"} className="img-fluid py-2"/>
                            </div>
                            <div className="col-md-9 pl-0">
                                <div className="d-flex justify-content-between h-100">
                                    <div className="d-flex">
                                        <div className={(this.props.activeUrl===1?'active':'')+" chat-icon h-100 d-flex align-items-center text-white tmchatHeader "}>
                                            <a href={process.env.PUBLIC_URL +'/navigate2022/chat'}>
                                                <button>
                                                    <img src={process.env.PUBLIC_URL + "/images/tmChat/chat_chatIco.png"} className="img-fluid mr-2"/> Chat
                                                </button>
                                            </a>
                                        </div>

                                        <div className={(this.props.activeUrl===2?'active':'')+ " video-icon h-100 align-items-center text-white tmchatHeader d-none"}>
                                            <a href={process.env.PUBLIC_URL +'/video-conference'}>

                                                <button >
                                                    <img src={process.env.PUBLIC_URL + "/images/tmChat/chat_video.png"} className="img-fluid mr-2"/> Video Conference
                                                </button>
                                            </a>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        )
    }
}

export default ChatHeader;


