import React, { useState, useEffect,useMemo } from "react";
//import { useParams } from "react-router-dom";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import profileImage from "../../../assets/images/no-image.png";

const AttendeesList = () => {
  const history = useHistory();
  const [attendeeList, setAttendeeList] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [loading,setLoading]=useState(false);
  useEffect(() => {
    setLoading(true);
    getList();
    return () =>{  setAttendeeList([]); } 
  }, [textSearch]);

  const getList = () => {
    const userListData = {
      offset: 0,
      limit: 10000,
      eventId: localStorage.getItem("eventId"),
      role: 111,
      loginId: localStorage.getItem("accountId"),
      searchByKeyword:textSearch,
    };
    Axios.post(`${global.config.requestUrl}user/getUserList`, userListData, {
      headers: { "auth-token": localStorage.getItem("exhibitorToken") },
    }).then((res) => {
        if (res.data.errorCode === 0) {
          setAttendeeList(res.data.data);
        }else{
          setAttendeeList([]);
        }
         setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }


const callMemoData=(attendeeList)=>{
  return attendeeList.length>0 ? attendeeList.map((attendee, index) => (
    <li key={index}>
      <Link to={`/fna2022/attendee/${attendee.email}`}>
        <div className="userPic ml-0">
          <img
            src={attendee.profilePic == '' || attendee.profilePic == "''" ? profileImage : attendee.profilePic}
            alt=""
          />
        </div>
        <div className="userName">
          <p className="mb-2">
            {attendee.name} ({attendee.company})
          </p>
        </div>
        <i className="fa fa-angle-right rightCorner"></i>
      </Link>
    </li>
  )):'No Data Found';
}

const showList = useMemo(() => callMemoData(attendeeList),[attendeeList])

const searchItems = (value) => {
  setLoading(true);
  setTextSearch(value)
}
  return (
    <React.Fragment>
      <div className="p-3">
        <div className="card p-3 border-none box-shadow">
          <div className="pageHeading mb-4">
            <i
              className="fa fa-arrow-left backBtn"
              onClick={() => history.goBack()}
            ></i>
            <h4 className="text-center">Attendees List</h4>
          </div>
          <div className="searchBx">
              <form>
                  <input type="text" name="textToSearch" value={textSearch}  placeholder="Search Here..." onChange={(e) => searchItems(e.target.value)}/>
                  
              </form>
            </div>
          <ul className="borderList speakers-list">
            {
             loading ? <div className="loaderWrp">
             <div className="loaderInr">
               <img
                 src={process.env.PUBLIC_URL + "/images/loader.gif"}
                 alt=""
               />
             </div>
           </div>
          :  showList
          }
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AttendeesList;
