import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const HelpDeskModal = React.memo(props => {
  return (
    <>
      <div className="midModal">
        {/* <div
              className="modalOverlay"
              onClick={this.helpDeskModalHandler}
            ></div> */}
        <div className="midContentWrp">
          <div className="modalPopUpClose" onClick={props.closeModal}>
            <i className="fa fa-times"></i>
          </div>
          <h3>Info Desk</h3>
          <div className="midContentBx">
            {props.helpDeskContents.length > 0 ? (
              <Accordion defaultActiveKey="0">
                {props.helpDeskContents.map((content) => (
                  <Card key={content.help_desk_id}>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={content.help_desk_id}
                      >
                        {content.title}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={content.help_desk_id}>
                      <Card.Body>
                        {content.description
                          .split("\n")
                          .map(function (item, key) {
                            return (
                              <span key={key}>
                                {item}
                                <br />
                              </span>
                            );
                          })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))}
              </Accordion>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  )
});

export default HelpDeskModal;
