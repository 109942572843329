import React, { Component } from 'react';

import {storage} from '../../../Firebase';
import Axios from 'axios';

class AddDocumentsVideos extends Component {
    constructor(props){
        super(props);
        var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }

        this.state = {
            documentsFile:null,
            documentsVideoFile:null,
            documentsVideoLinkShare:"",
            documentsVideoLinkTitle:'',
            documentVideoSelectionError :'',
            maxDocumentSelectionError :'',
            documentVideoSelectionSuccess :'',
            docDataServer : [],
            docs : [],
            pageLoader : true,

            exhibitorId : userId,
            eventId : localStorage.getItem('eventId'),
        }
    }
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    documentVideoChange = (e) => {
        if(e.target.files[0]){
            const documentsVideoFile = e.target.files[0];
            var size = documentsVideoFile.size/1024/1024;
            if(size > 10){
                let randomString = Math.random().toString(36);
                this.setState({
                    documentsVideoFilekey :randomString,
                    documentsVideoFile : null,
                    documentVideoSelectionError : "Max upload  size is 10MB"
                })
            }else{
                this.setState({
                    documentsVideoFile : documentsVideoFile,
                    documentVideoSelectionError : ""
                })
               
            }
           
            
        }
    }

    deleteDoc  = (e) => {
        //console.log(e.currentTarget.dataset.id );
        this.setState({pageLoader : true})
        var id = e.currentTarget.dataset.id;
        var url = e.currentTarget.dataset.url;
        var filetype = e.currentTarget.dataset.filetype;
        var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }

        const docData = {
            memberId: userId,
            eventId: localStorage.getItem('eventId'),
            file : url.toString(),
            fileType: filetype.toString(),
            status:0,
            fileName:url.toString(),
            docId:id
           }
    
           Axios.post(`${global.config.requestUrl}exhibitor/insertOrUpdateBoothDocs`, docData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                    this.componentDidMount();
                })
                .catch(err => {
                    console.log(err);
                });
    }

    onChangeDocuments = (event) =>{
        var files = event.target.files;
        this.setState({
            documentsFile: files
         });
         console.log(files);
        
        
      }

      componentDidMount (){
        var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
        Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorBoothDocs?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {

     if(!res.data.errorCode){
        this.setState({
            docDataServer:res.data.data,
            pageLoader: false
        });
      }else{
        if(res.data.errorCode === 1){
            this.setState({
                docDataServer:[],
                pageLoader: false
            });
        }else{
            this.setState({
                pageLoader: false
            }); 
        }
         
      }
       
        
    })
    .catch(err => {
        console.log(err);
    });
   
    }

      documentsVideoFileValidateForm = () => {
        this.setState({documentVideoSelectionError : ""})
       
        var countdoc = 0;
     if(this.state.documentsFile !== null){
        for (const key of Object.keys(this.state.documentsFile)) {
            countdoc = countdoc+1;
        }
        if(countdoc > 5){
            this.setState({maxDocumentSelectionError : "maximum 4 Doc allow at a time",documentVideoSelectionSuccess:""})
            return false;
        }else{
            this.setState({maxDocumentSelectionError : ""})
        } 
      }

        if(this.state.documentsVideoFile !== null && this.state.documentsVideoLinkShare !== "" ){
            this.setState({documentVideoSelectionError : "Please Select only one Input for video",documentVideoSelectionSuccess:""})
            return false;
        }else{
            this.setState({documentVideoSelectionError : ""})
        }

        if(this.state.documentsVideoLinkShare !== "" ){
            var url = this.state.documentsVideoLinkShare;
            var mediatype =  this.getFileExtFromUrl(url);
            if(this.state.documentsVideoLinkTitle == ''){
                this.setState({documentVideoSelectionError : "Need Video Title!!!",documentVideoSelectionSuccess:""})
                return false;
            }
            if((mediatype === 'mp4' && url.includes('http')) || mediatype === 'validevideolink'){
                this.setState({documentVideoSelectionError : ""})
                return true;
            }else{
                this.setState({documentVideoSelectionError : "Invalid URL!!",documentVideoSelectionSuccess:""})
                return false;
            }
            
        }else{
            this.setState({documentVideoSelectionError : ""})
        }
        
      

        if(this.state.documentsVideoFile === null && this.state.documentsVideoLinkShare === "" && this.state.documentsFile === null){
            this.setState({documentVideoSelectionError : "Nothing to update",documentVideoSelectionSuccess:""})
            return false;
        }else{
            this.setState({documentVideoSelectionError : ""})
        }

        return true;

           
     
      }

      getFileExtFromUrl =  (linkurl) => {
        var url = linkurl;
        var mainfile = url.split('/').pop().split('?')[0];
        var filetype = mainfile.split('.');
        if(filetype[1]){
            return filetype[1];
        }else if(url.includes('https://www.youtube.com/embed') || url.includes('youtu.be') || url.includes('https://www.youtube.com/watch') || url.includes('https://player.vimeo.com/video/') ){
            return 'validevideolink';
        }else{
            return '';
        }
        
    }
      
    addDocumentsVideosSubmit = (e) => {
        
        e.preventDefault();
        
        if(this.documentsVideoFileValidateForm()){
            this.setState({
                pageLoader: true
            });
            if(this.state.documentsVideoFile !== null){
                this.uploadFilesAsPromise(this.state.documentsVideoFile, 1,'videos');
            } 
            else if(this.state.documentsVideoLinkShare !==''){
                this.documentSubmitToServer(this.state.documentsVideoLinkShare,1);
            }

            if(this.state.documentsFile !== null){
           
                    var docs =[];
                    for (const key of Object.keys(this.state.documentsFile)) {
                        var docfiles = this.state.documentsFile[key];
                        docs[key] = docfiles;
                    }
                    let randomString = Math.random().toString(36);
                    this.setState({documentsFilekey :randomString,documentsFile : null});
                    for (const key of Object.keys(docs)) {
                        var docfiles = docs[key];
                        this.uploadFilesAsPromise(docfiles,2,'docs');
                    }
               
            }

        }
        
    }

    //Handle waiting to upload each file using promise
uploadFilesAsPromise = (imageFile, filetype,foldername) => {
    const {exhibitorId, eventId} = this.state;
    this.setState({
        pageLoader: true
    });
    var that = this;
    return new Promise(function (resolve, reject) {
        var storageRef = storage.ref().child(`${foldername}/${eventId}/${exhibitorId}/${imageFile.name}`);

        //Upload file
        var task = storageRef.put(imageFile);

        //Update progress bar
        task.on('state_changed',
            function progress(snapshot){
                var percentage = snapshot.bytesTransferred / snapshot.totalBytes * 100;
                //uploader.value = percentage;
            },
            function error(err){

            },
            function complete(){
            var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
                //var downloadURL = task.snapshot.downloadURL;
                storage.ref(`${foldername}/${eventId}/${exhibitorId}`).child(imageFile.name).getDownloadURL().then(url=>{
                    if(url){
                        const docData = {
                            memberId: userId,
                            eventId: localStorage.getItem('eventId'),
                            file : url.toString(),
                            fileType: filetype.toString(),
                            status:1,
                            fileName:imageFile.name
                           }
                    
                           Axios.post(`${global.config.requestUrl}exhibitor/insertOrUpdateBoothDocs`, docData,
                                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                                )
                                .then(res => {
                                    if(res.data.errorCode){ //it means error from server
                                        that.setState({
                                            documentVideoSelectionError : res.data.errorMessage, documentVideoSelectionSuccess:"",
                                            pageLoader:false,
                                        })
                                      }else{
                                        that.setState({
                                            documentVideoSelectionError : "", 
                                            documentVideoSelectionSuccess:res.data.errorMessage,
                                            documentsVideoLinkShare : ""
                                        })
                                      }
                                      that.componentDidMount();
                                })
                                .catch(err => {
                                    console.log(err);
                                });
                        
                    }
                })
            }
        );
    });
     
}

getSrcFromEmbedLink = (url) => {
    var  re = /\ssrc=(?:(?:'([^']*)')|(?:"([^"]*)")|([^\s]*))/i, // match src='a' OR src="a" OR src=a
        res = url.match(re),
        src = res[1]||res[2]||res[3];
        return src;
}

/* Only for the video link this function written */
documentSubmitToServer(url,filetype) {

    if(url.includes('iframe') && (url.includes('www.youtube.com') || url.includes('https://player.vimeo.com/video/') )  ){
        url = this.getSrcFromEmbedLink(url);
    }
    var userId =  localStorage.getItem('userId');
    if(localStorage.getItem('exhibitorId')){
         userId =  localStorage.getItem('exhibitorId');
    }

    
    const docData = {
        memberId: userId,
        eventId: localStorage.getItem('eventId'),
        file : url.toString(),
        fileType: filetype.toString(),
        status:1,
        fileName:this.state.documentsVideoLinkTitle,
       }

       Axios.post(`${global.config.requestUrl}exhibitor/insertOrUpdateBoothDocs`, docData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode){ //it means error from server
                        this.setState({
                            documentVideoSelectionError : res.data.errorMessage, documentVideoSelectionSuccess:""
                        })
                      }else{
                        this.setState({
                            documentVideoSelectionError : "", 
                            documentVideoSelectionSuccess:res.data.errorMessage,
                            documentsVideoLinkShare : '',
                            documentsVideoLinkTitle:'',
                        })
                       
                        document.getElementById('documentsVideoLinkShare').value = '';
                        this.componentDidMount();
                      }
            })
            .catch(err => {
                console.log(err);
            });
    
}
    render() {

    const dochtml = this.state.docDataServer.map((anObjectMapped, index) => {
           if(anObjectMapped.fileType === 1){
               var type = 'video';
           }else{
            var type = 'document';
           }
           fileName = anObjectMapped.fileName;
           if(fileName === ""){
            var fileName = 'No Name';
        }
            return (
                
                <tr key={index}>
                <td><a href={`${anObjectMapped.file}?rel=0`} target="_blank" rel="noopener noreferrer">{fileName}</a></td>
                 <td>{type}</td>
                <td><button className="btn-danger" data-id={`${anObjectMapped.docId}`} data-url={`${anObjectMapped.file}`} data-filetype={`${anObjectMapped.fileType}`} onClick={this.deleteDoc.bind(this)}><i className="fa fa-trash"></i></button></td>
               </tr>
            );
        })
        return (
            <React.Fragment>
                <div className="modalPopUpFormBx">
                {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                    <h4 className="mb-3">Add Documents/Video</h4>
                <div className="error">{this.state.documentVideoSelectionError} {this.state.maxDocumentSelectionError}</div>
                <div className="success">{this.state.documentVideoSelectionSuccess}</div>
                    <form onSubmit={this.addDocumentsVideosSubmit}>
                        
                        <div className="form-group">
                            <label>Documents</label>
                            <input type="file" name="documentsFile" multiple className="form-control" accept=".xlsx,.xls, .doc, .docx,.ppt, .pptx,.txt,.pdf" multiple onChange={this.onChangeDocuments} key={this.state.documentsFilekey || '' } />
                            <small>Multiple Files Can be Selected</small>
                        </div>
                        <div className="form-group">
                            <label>Video</label>
                            <input type="file" name="documentsVideoFile" className="form-control" accept="video/mp4,video/x-m4v,video/*" onChange={this.documentVideoChange} key={this.state.documentsVideoFilekey || '' }/>
                            <span className="d-block mt-2 mb-2">or</span>
                            <input type="text" name="documentsVideoLinkShare" id="documentsVideoLinkShare" className="form-control" placeholder="Share Video Link..." onChange={this.onChange} />
                           
                        </div>
                        <div className="form-group">
                            <label>Video Title (if video link added)</label>
                            <input type="text" name="documentsVideoLinkTitle" id="documentsVideoLinkTitle" className="form-control" value={this.state.documentsVideoLinkTitle} placeholder="Video title name.." onChange={this.onChange} />
                        </div>
                        <div className="mt-3">
                            <button type="submit">Add</button>
                        </div>
                    </form>
                </div>

        

                <div className="modalTableWrp">
                    <table className="table modalTable text-center mt-3">
                        <thead>
                            <tr>
                                <th>Documents/Video</th>
                                <th>Type</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                    
                            {dochtml} 
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        )
    }
}
export default AddDocumentsVideos;