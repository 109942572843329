import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { useHistory } from "react-router-dom";

const ExhibitHall = (props) => {
  const history = useHistory();
  const eventId = localStorage.getItem("eventId");
  const [exhibitorsList, setExhibitorsList] = useState([]);
  const [pageLoader, setPageLoader] = useState(true);
  const [limit, setLimit] = useState(200);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    getSponsorsLogos();
  }, []);

  const getSponsorsLogos = () => {
    /* getting sponsers logos */
    const exhibitorData = {
      eventId: eventId,
      offset: offset,
      limit: limit,
      searchByKeyword: "",
      searchByCategory: "",
    };
    Axios.post(
      `${global.config.requestUrl}user/getAllExhibitors`,
      exhibitorData,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        setPageLoader(false);
        if (res.data.errorCode === 0) {
          setExhibitorsList(res.data.data);
        } else {
          setExhibitorsList([
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
            {
              logo: process.env.PUBLIC_URL + "/images/sponser-dummy.png",
              exhibitor_id: null,
            },
          ]);
        }
      })
      .catch((err) => {
        setPageLoader(false);
        console.log(err);
      });
  };

  return (
    <Fragment>
      {pageLoader ? (
        <div className="loaderWrp">
          <div className="loaderInr">
            <img src={process.env.PUBLIC_URL + "/images/loader.gif"} alt="" />
          </div>
        </div>
      ) : null}
      <div className="p-3">
        <div className="card p-3 border-none box-shadow">
          <div className="pageHeading">
            <i
              className="fa fa-arrow-left backBtn"
              onClick={() => history.goBack()}
            ></i>
            <h4 className="text-center">
              {new Date().getFullYear()} EXHIBITORS
            </h4>
          </div>
        </div>
        <div className="card p-3 border-none box-shadow">
          <section className="signleScreen overflow-hidden exhibitHallSec">
            <div className="exhibitHallBx">
              <div className="exhibitHallBxInr">
                <div className="sponSec">
                  <div className="sponsersList">
                    {exhibitorsList
                      .sort((a, b) => (a.company > b.company ? 1 : -1))
                      .map((exhibitor) =>
                        exhibitor.logo ? (
                          <Link
                            to={`/fna2022/attendee/exhibitor/${exhibitor.exhibitor_id}/Exhibitor`}
                            key={exhibitor.logo}
                          >
                            <img src={exhibitor.logo} alt={exhibitor.company} title={exhibitor.company} />
                          </Link>
                        ) : null
                      )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Fragment>
  );
};

export default ExhibitHall;
