import React, {Component} from "react";
import utility from "../utility/utility";

class BoothStaff extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="widget-usersbox">
                    {this.props.staffList && this.props.staffList.length ?
                        this.props.staffList.map((staff,index)=>{
                            return (<div className=" widget-userslist" key={index} onClick={e=>this.props.activeUser(index,staff.contactId)}>
                                <div className={"widget-userimg "+(staff.profilePic?'':'widget-user--active')}
                                     style={{background: 'url(' + staff.profilePic + ') no-repeat center / cover'}}>
                                    {staff.profilePic?'':utility.acronym(staff.name)}
                                </div>
                                <div className="opensans-semibold username color-333">
                                    {staff.name}
                                </div>
                            </div>);
                        }):''}
                </div>
            </React.Fragment>)
    }
}
export  default  BoothStaff