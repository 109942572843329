import React, {Component} from "react";
import BoothStaff from './BoothStaff';
import BoothChatSection from './BoothChatSection';
import '../TmStyle.css'
import troopServer from "../utility/troopServer";
import TroopMessenger from "troop-messenger-chat-sdk";
import utility from "../utility/utility";
import tmChatServer from "../utility/server";
import tmChat from "../utility/tmChat";

let troopMessengerBoothChat;
let tmLoginUserDetails;
class BoothChat extends Component {
    constructor(props)
    {
        super(props);
        this.state={'staffList':[],chatConversations:[],onlineUsers:[],openWidget:false,eventId:localStorage.getItem("eventId"),recentChat:[],activeUserIndex:0}
    }
    getOnlineUsers=()=>{
        return new Promise((resolve) => {
            troopMessengerBoothChat.getOnlineUsers()
                .on("online_users",  (data) =>{
                    console.log(data.online,"online USer ");
                    this.setState({'onlineUsers':data.online});
                    return resolve('');
                })
                .on("tm_error", function (errorMessage) {
                    console.log(errorMessage);
                    return resolve('');
                });

        });
    }
    makeVideoCall = async () => {
        console.log(this.state.chatUser, "chatUser");
        let getRemoteUserDetails = await tmChatServer.updateUser({
            'userId': this.state.staffDetails.contactId,
            'name': this.state.staffDetails.name,
            'role': '-1',
            'uid': this.state.staffDetails.uid,
            tm_user_id: this.state.staffDetails.tm_user_id
        });
        //let
      //  let cattleCallRemoteUserDetails.user_id = getRemoteUserDetails.cattle_call_id;
        //this.setState();
        let data = JSON.stringify({
            caller_uid: utility.generateUserUid({
                'userId': this.state.staffDetails.contactId,
                'role': this.state.staffDetails.role
            }), incommingCall: false, 'call_user_id': getRemoteUserDetails.cattle_call_id
        });
        data = utility.encodeBase64(data);
        this.setState({'videoCallData': data});
        //cattlecall.call('' + cattleCallRemoteUserDetails.user_id, "#localVideo", "#remoteVideo");
        // this.takeAudioVideoDevices();
        this.makeCall();
    };
    makeCall() {
        let videoCallWindow = utility.popupWindow(process.env.PUBLIC_URL + "/video-call?data=" + this.state.videoCallData, 'Video Call', 900, 500)
    }
    syncRecentChat=async ()=>{
      let recentUsersList = await tmChat.chatList(troopMessengerBoothChat, this.state.eventId);
        recentUsersList = recentUsersList.recent_list;
        if(recentUsersList && recentUsersList.length) {
            recentUsersList.forEach((chat) => {
                let userIndex = this.state.staffList.findIndex((staff) => {
                    let uid = utility.generateUserUid({
                        'userId': staff.contactId,
                        'role': staff.role
                    });

                    if (uid === chat.uid) {
                        return staff;
                    }
                });
                if (userIndex !== -1) {
                    let staffList = this.state.staffList;
                    let staffDetails = this.state.staffList[userIndex];
                    Object.assign(staffDetails, chat);
                    staffList.splice(userIndex, 1);
                    staffList = [staffDetails].concat(staffList);
                   // this.activeUser(0, staffDetails.contactId);
                    this.setState({'staffList':staffList});
                }
            });

        }
             console.log(this.state.staffList,"staffList");
          if(this.state.staffList.length) {
              this.activeUser(0, this.state.staffList[0].contactId);
          }

    }
    async getUserDetails(uid) {
        let userIdRole = utility.getUserIdAndRole(uid);
        const parameters = {
            'ids': userIdRole['userId'],
            'role': userIdRole['role'],
        };
        let usersList = await troopServer.getUsersListBasedOnRole(parameters);
        usersList = usersList.data;
        if (usersList && usersList.length) {
            return usersList[0];
        }
    }
    displayNotification(message) {
       if(!this.state.openWidget) {
           let notification = new Notification(message.title, {
               body: message.message,
               icon: process.env.PUBLIC_URL + '/images/tmChat/logo.png',
               vibrate: [100],
               timeout: 300,
               data: {entity_id: message.sender_id}
           });
           notification.onclick = function (event) {
               event.preventDefault();
               window.focus();
               notification.close();
           };
       }
    }

    connectTMChat = () => {
            console.log("comes heresss",tmLoginUserDetails,this.state.staffList);
        troopMessengerBoothChat.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                console.log(message, "mmessage");
            })
            .on("connected", async () => {
                this.setState({troopMessengerConnection: troopMessengerBoothChat});
                this.getOnlineUsers();
                this.syncRecentChat();
            })
            .on("disconnected", function () {
            }).on("message_sent", async (data) => {
            if(''+this.state.eventId===''+data.conversation_reference_id) {
                if (this.state.staffDetails.uid === data.receiver_uid) {
                    if (this.state.chatConversations && this.state.chatConversations.length) {
                        let conversations = this.state.chatConversations.concat(data);
                        console.log(conversations, "conversionssss");

                        this.setState({'chatConversations': conversations});
                    } else {
                        this.state.chatConversations = [data];
                        this.setState({'chatConversations': this.state.chatConversations});
                    }

                }
            }
        })
            .on("receive_message", async (data) => {
                if(''+this.state.eventId===''+data.conversation_reference_id) {
                    if (data.is_group === 0) {
                        let userInfo = this.state.staffList.filter((user) => {
                            if (user.uid === data.sender_uid) {
                                return user;
                            }
                        });
                        if (userInfo.length) {
                            data.sender_name = userInfo[0]['name'];
                        } else {
                            let userDetails = await this.getUserDetails(data.sender_uid);
                            userDetails.sender_uid = data.sender_uid;
                            data.sender_name = userDetails.name;
                        }
                        let notificationData = {
                            'title': 'New Message From ' + data.sender_name,
                            'message': data.message,
                            'sender_id': data.sender_id
                        };
                        if (data.message_type === 1) {
                            notificationData['title'] = data.sender_name + ' Sent Attachment';
                            notificationData['message'] = 'Attachment';
                        }
                        this.displayNotification(notificationData);
                        if(this.state.staffDetails.uid===data.sender_uid)
                        {
                            let conversations=this.state.chatConversations.concat(data);
                            console.log(conversations,"conversionssss");
                            this.setState({'chatConversations':conversations});
                        }
                    } else {
                        let notificationData = {
                            'title': 'New Message From ' + data.sender_name,
                            'message': data.message,
                            'sender_id': data.sender_id
                        };
                        if (data.message_type === 1) {
                            notificationData['title'] = data.sender_name + ' Sent Attachment';
                            notificationData['message'] = 'Attachment';
                        }
                        this.displayNotification(notificationData);
                    }
                }
            })
            .on("message_delivered", function (data) {
            })
            .on("message_read", function (data) {
            })
            .on("user_online",  (data)=> {
                this.state.onlineUsers.push(data.user_id)
                this.setState({'onlineUsers':this.state.onlineUsers});
                  if(this.state.staffDetails) {
                      if ('' + this.state.staffDetails.tm_user_id === '' + data.user_id) {
                          this.state.staffDetails.is_online = true;
                          this.setState({'staffDetails': this.state.staffDetails});
                      }
                  }
            })
            .on("user_dnd", function (data) {
            })
            .on("user_offline",  (data) =>{
                console.log(data,"userOffline");
                let userIndex= this.state.onlineUsers.findIndex((user)=>{ return user===data.user_id});
                this.state.onlineUsers.splice(userIndex,1);
                this.setState({'onlineUsers':this.state.onlineUsers});
                  if(this.state.staffDetails) {
                      if ('' + this.state.staffDetails.tm_user_id === '' + data.user_id) {
                          this.state.staffDetails.is_online = false;
                          this.setState({'chatUser': this.state.staffDetails});
                      }
                  }
            })
            .on("group_created", (data) => {
            })
            .on("group_updated", function (data) {
            })
            .on("typing", function (data) {
            })
    };
    formateUsers=(staff)=>{
       return new Promise((resolve)=>{
           let staffList=[];
       for(const staffDetails of staff.data) {
           let uid=utility.generateUserUid({
               'userId': staffDetails.contactId,
               'role': staffDetails.role
           });
           staffList.push({
               'name': staffDetails.name,
               'profilePic': staffDetails.image,
               'contactId': staffDetails.contactId,
               uid:uid,
               'role': staffDetails.role
           });
       }
       console.log(staffList,"stafffListttttt");
       this.setState({'staffList':staffList});
       return resolve(true);
       })
   }
    async componentDidMount() {
   let staff=await troopServer.getBoothStaff(this.props.exhibitorId);
       console.log("stafff List",staff);
     if(staff.data){
         await this.formateUsers(staff);

     }
       const userDetailsdata = await troopServer.getLoginUserDetails();

       if ((userDetailsdata.errorCode === 0)) {
           userDetailsdata.data[0].userId = localStorage.getItem('userId');
           userDetailsdata.data[0].role = localStorage.getItem('currentRole');
          let loginUserDetails = userDetailsdata.data[0];
           this.setState({
               userDetails: loginUserDetails,
               isLoading: false
           });
           troopMessengerBoothChat = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
           let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
           loginUserDetails.uid = uid;
           tmLoginUserDetails = await troopMessengerBoothChat.login(uid, loginUserDetails.name);
           let getUserDetails = await tmChatServer.updateUser({
               'userId': loginUserDetails.userId,
               'name': loginUserDetails.name,
               'role': loginUserDetails.role,
               tm_user_id: tmLoginUserDetails.tm_user_id
           });
        //   cattleCallLoginUserDetails.user_id = getUserDetails.cattle_call_id;
           try {
               loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
               this.setState({'loginUserDetails':loginUserDetails});
               this.connectTMChat();
           } catch (e) {
               console.log(e);
           }
       } else if (userDetailsdata.errorCode === 4) {
           this.setState({"redirect": true});
       }
   }
  async  componentDidUpdate(prevProps, prevState, snapshot) {
          if(prevProps.exhibitorId !==this.props.exhibitorId) {
              this.setState({'staffList': [], 'staffDetails': null});
              let staff = await troopServer.getBoothStaff(this.props.exhibitorId);
              console.log("stafff List", staff);
              if (staff.data) {
                  await this.formateUsers(staff);
                  if(this.state.staffList.length){
                  this.activeUser(0,this.state.staffList[0].contactId);
                  }
              }
          }
   }

    getConversation=async (tmUserId)=>{
    let chatConversations = await tmChat.getConversation(troopMessengerBoothChat, {
        'tm_user_id':tmUserId,
        authorization_token: tmLoginUserDetails.authorization_token,
        'conversation_reference_id': this.state.eventId
    });
     console.log(chatConversations);
    this.setState({'chatConversations':chatConversations});
}

    manageWidget=()=>{
        this.setState({"openWidget":!this.state.openWidget})
    }
    activeUser=async (index,contactId)=>{
        let staffDetails=this.state.staffList[index];
             console.log(staffDetails,"staffDetails")
         if( staffDetails && !staffDetails.tm_user_id) {
             let tmDetails = await troopMessengerBoothChat.register(staffDetails.uid, staffDetails.name);
             staffDetails.tm_user_id = tmDetails.tm_user_id;
             this.state.staffList[index] = staffDetails;
         }
        this.setState({"staffList":this.state.staffList});
           console.log(staffDetails,'stafDetailsss');
        staffDetails.is_online=(this.state.onlineUsers.findIndex((user)=>{ return user===staffDetails.tm_user_id}) !== -1);
        this.getConversation(staffDetails.tm_user_id);
         this.setState({'activeUserIndex':index,staffDetails:staffDetails});
    };
    onSendMessage=(message,userInfo)=>{
        message.event_id =this.state.eventId;

        message.sender_uid = this.state.userDetails.uid;
        if(message.is_group!==1) {
           // recentUsersRoles[message['receiver_uid']] = userInfo.role;
          //  console.log(recentUsersRoles, message['receiver_uid']);

        }
        if (message.filesPaths && message.filesPaths.length) {
            for (const filepath of message.filesPaths) {
                message.attachment = filepath;
                tmChat.sendAttachment(troopMessengerBoothChat, message);
            }
        } else {
            tmChat.sendTextMessage(troopMessengerBoothChat, message);
        }
    }
    render() {
        return (
            <React.Fragment>

    <div className="widget-box" onClick={this.manageWidget}>
        <img src={process.env.PUBLIC_URL + "/images/booth-chat-ico.png"} alt=""/>
    </div>
                {this.state.openWidget?<div className="widget-detailsbox widget-detailsbox--open" id="widgetDetails">
                    <div className="widget-detailsbox--close pointer" onClick={this.manageWidget}>
                        <img src={process.env.PUBLIC_URL + "/images/tmChat/close.png"} alt='close'/>
                    </div>
                    <BoothStaff staffList={this.state.staffList} activeUser={this.activeUser} activeUserIndex={this.state.activeUserIndex}/>
                    {this.state.staffDetails?<BoothChatSection makeVideoCall={this.makeVideoCall} staffDetails={this.state.staffDetails}  onSendMessage ={this.onSendMessage} troopConnection={this.state.troopMessengerConnection} loginUserDetails={this.state.userDetails} chatConversations={this.state.chatConversations}/>:'Loading'}

                </div>:''}

            </React.Fragment>
        )
    }
}
export default BoothChat