import React, { Component } from 'react'

export default class Homepage extends Component {
    render() {
        const mystyle = {
            position: "absolute",
            width: "100%",
            left: 0,
            top: 0,
            padding:"10px 0"
          };
        return (
            <React.Fragment>
            <header id="home" className="hero-area">
                <div className="overlay">
                    <span></span>
                    <span></span>
                </div>
                <nav className="navbar navbar-expand-md bg-inverse scrolling-navbar" style={mystyle}>
                    <div className="container">
                        <a href="/" className="navbar-brand"><img src={process.env.PUBLIC_URL + "images/logo.png"} alt=""/></a>       
                    </div>

                </nav>
                <div className="container">
                    <div className="row space-100">
                        <div className="col-lg-6 col-md-12 col-xs-12">
                            <div className="contents">
                                <h2 className="head-title">A companion or a contingency for your next event.</h2>
                                <div className="header-button">
                                    <a href="http://tsleads.net/virtualconference" target="_blank" rel="noopener noreferrer" className="btn btn-border-filled">More Info</a>
                                    leftNavBar                    </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-xs-12 p-0">
                            <div className="intro-img">
                                <img src={process.env.PUBLIC_URL + "images/intro.png"} alt="" className="img-responsive"/>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            <section id="services" className="section">
                <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-sm-4 col-xs-12 special">
                                <div className="services-item text-center">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "images/convention_lobby_icon.png"} alt="" />
                                    </div>
                                    <h4>Convention Lobby</h4>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 special">
                                <div className="services-item text-center">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "images/educational_sessions_icon.png"} alt="" />
                                    
                                    </div>
                                    <h4>Educational Sessions</h4>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 special">
                                <div className="services-item text-center">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "images/trade_show_icon.png"} alt="" />
                                    
                                    </div>
                                    <h4>Trade Show</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-4 col-xs-12 special">
                                <div className="services-item text-center">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "images/exhibit_booth_icon.png"} alt="" />
                                    
                                    </div>
                                    <h4>Exhibit Booth</h4>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 special">
                                <div className="services-item text-center">
                                    <div className="icon">
                                        <img src={process.env.PUBLIC_URL + "images/networking_lounge_icon.png"} alt="" />
                                    
                                    </div>
                                    <h4>Networking Lounge</h4>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-4 col-xs-12 special">
                                <div className="services-item text-center">
                                    <div className="icon">
                                       <img src={process.env.PUBLIC_URL + "images/speaker_portal_icon.png"} alt="" />
                                    </div>
                                    <h4>Speaker Portal</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 text-center mt-5 mb-5">
                                <div className="request_demo_button">
                                    <a href="https://tsleads.net/contact" target="_blank" rel="noopener noreferrer" className="btn btn-warning">Contact TSL </a>
                                </div>
                            </div>
                        </div>
                  
                </div>
            </section>
            <footer>
                <section id="footer-Content">
                    <div className="copyright">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="site-info text-center">
                                        <p>Copyright © 2020 <a href="#/" rel="nofollow">TSL Virtual Conference.</a> All Rights Reserved.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
            </React.Fragment>
        )
    }
}
