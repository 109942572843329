import React, {Component} from "react";
import utility from "../utility/utility";
import {withRouter} from "react-router-dom";
import troopServer from "../utility/troopServer";
import tmChatServer from "../utility/server";
import CattleCall from "cattlecall-conference-sdk";
import TroopMessenger from "troop-messenger-chat-sdk";
import ChatMessages from "../chat/ChatMessages";
import tmChat from "../utility/tmChat";
import moment from "moment";

let cattlecall;
let tmLoginUserDetails;
let troopMessenger;
class SpeakerWebinar  extends Component {

    constructor(props){
        super(props);
        this.state={isAcceptDropDown:'',clickedExtendMeeting:false,resetMeetingModal:false,startTimoutMeeting:false,meetingClosed:false,isChatRequestAccepted:false,userStreams:{},isChatRequested:false,chatConversations:[],message:'',isLoading:true,'sessionId':'',videoState:true,audioState:true,isSpeaker:false,sessionDetails:{},'sessionRoomDetails':{},'participateUsers':[]};
    }
    connectCattleCall=()=>{
        cattlecall=new CattleCall("L0gJS27u4pHxwbh5L0gJS27u4pHxwbh5","HGupYTsKbiJWZSDOqw3O01ajKAgNUTnf");
        cattlecall.connect(this.state.loginUserDetails.cattle_call_id);
        cattlecall.on("ready",(data)=>{
            //  this.getMeetingDetails(this.state.roomId);
            this.speakerSessionRoomDetails(this.state.sessionDetails);

        }).on("user_left",(data)=>{
            let participateId=data.participant_id;
               if(''+this.state.speakerCattleCallId === ''+participateId){
                   let remoteVideoSelector = document.querySelector('#remoteVideoElement');
                   if(remoteVideoSelector.srcObject){
                       return false;
                   }
                   remoteVideoSelector.srcObject = null;
               }
            let participateUserIndex = this.state.participateUsers.findIndex((user)=>{
                if (''+user.cattle_call_id === ''+participateId){
                    return user;
                }
            });
            console.log(participateUserIndex,'participateUserIndex');
            if(participateUserIndex!==-1){
                let participateUser=this.state.participateUsers[participateUserIndex];
                let participateUsers=this.state.participateUsers;
                participateUser.is_request_chat=true;
                participateUsers.splice(participateUserIndex,1);
                console.log(participateUsers,"participateUsers participateUsers participateUsers");
                this.setState({'participateUsers':participateUsers},()=>{
                    console.log(this.state.participateUsers,"participateUsers participateUsers participateUsers");
                });
            }

        }).on("disconnected",(id)=>{

        }).on('user_stream_added',async (streem,id)=>{
            console.log(this.state.isSpeaker,"speaker If")
            if((!this.state.isSpeaker)){
                console.log(this.state.isSpeaker,"speaker If")
                if(!this.state.speakerCattleCallId) {
                    const getUserDetailsResponse = await tmChatServer.getUserDetail({'cattle_call_id': id});
                    if(getUserDetailsResponse) {
                        let uid = utility.generateUserUid({
                            'userId': getUserDetailsResponse.user_id,
                            'role': getUserDetailsResponse.roles
                        });
                        if (this.state.sessionDetails.uid === uid) {
                            this.setState({'isMeetingStarted':true});
                            this.setState({'speakerCattleCallId': id});
                        }
                    }
                }
                console.log(id,this.state.speakerCattleCallId,"speakerCattleCallIdspeakerCattleCallIdspeakerCattleCallId");
                if(this.state.speakerCattleCallId) {

                    if ('' + id === '' + this.state.speakerCattleCallId) {
                        this.setState({'isMeetingStarted':true},()=>{
                            console.log("comes nsideee");
                            let remoteVideoSelector = document.querySelector('#remoteVideoElement');
                              if(remoteVideoSelector.srcObject){
                                  return false;
                              }
                            remoteVideoSelector.srcObject = streem;
                        });

                    }
                }
            }

        }).on("user_joined",(user)=>{
            if(this.state.isSpeaker) {
                this.getJoinedUserDetails(user.user_id);
            }
        }).on("meeting_end",(data)=>{
            this.setState({'meetingClosed':true});
            cattlecall.leaveMeeting();
            setTimeout(()=>{
                this.props.history.push(`/csda/loginSplash/`);
            },3000);
        }).on("video_change",(data)=>{

        }).on("audio_change",(data)=>{

        }).on('spotlight_user',(participant)=>{


        }).on('screen_share_stop',(id)=>{
            console.log(id,"stream screen stream ended");
            if ('' + id === '' + this.state.speakerCattleCallId) {
                let remoteVideoSelector = document.querySelector('#remoteVideoElement');
                let remoteUserVideoSelector = document.querySelector('#remoteUserVideoElement');
                if (remoteVideoSelector) {
                    console.log(remoteVideoSelector.srcObject, "srcObject Addeded");
                    remoteVideoSelector.srcObject = remoteUserVideoSelector.srcObject;
                    remoteVideoSelector.muted =  false;
                    this.setState({'activeScreenStream': false});

                }
            }
        }).on('screen_stream_added', (stream,id)=>{
            console.log(stream,"stream screen stream addede");
              let remoteVideoSelector = document.querySelector('#remoteVideoElement');
              this.setState({'activeScreenStream': true});
              let remoteUserVideoSelector = document.querySelector('#remoteUserVideoElement');
              if (remoteVideoSelector) {
                       console.log(remoteVideoSelector.srcObject,"srcObject Addeded");
                  remoteUserVideoSelector.srcObject=remoteVideoSelector.srcObject;
                  remoteVideoSelector.srcObject=null;
                  remoteVideoSelector.srcObject =stream;
                  remoteVideoSelector.muted =  true;
              }
        }).on("meeting_started",(data)=>{
            this.setState({'isMeetingStarted':true});
            this.joinMeeting();
        }).on('hand_raise',(participateId)=>{
            console.log(participateId);
            let participateUserIndex = this.state.participateUsers.findIndex((user)=>{
                if (''+user.cattle_call_id === ''+participateId){
                    return user;
                }
            });
            console.log(participateUserIndex,'participateUserIndex');
            if(participateUserIndex!==-1){
                let participateUser=this.state.participateUsers[participateUserIndex];
                let participateUsers=this.state.participateUsers;
                participateUser.is_request_chat=true;
                participateUsers.splice(participateUserIndex,1);
                console.log(participateUsers,"participateUsers participateUsers participateUsers");
                participateUsers= [participateUser].concat(participateUsers);
                this.setState({'participateUsers':participateUsers},()=>{
                    console.log(this.state.participateUsers,"participateUsers participateUsers participateUsers");
                });
            }
        })
            .on('hand_status',(data)=>{
                if(''+this.state.loginUserDetails.cattle_call_id===''+data.participant_id){
                    if(data.request_status===1){
                        this.setState({'isChatRequestAccepted':true});
                    }
                    this.setState({'isChatRequested':false});
                }
            }).on('meeting_communication',(data)=>{
                     console.log(data,"dataaaa");
                    this.setState({'resetMeetingModal':true});
        })
            .on("error",(data)=>{
            console.log(data)
        });
    }
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
            })
            .on("connected", async () => {
                this.setState({troopMessengerConnection: troopMessenger,'troopConnected':true});
            }).on('message_sent',(data)=>{
            console.log(data,"dataaaaa",(''+data.conversation_reference_id===''+this.state.sessionDetails.session_id));
            if(''+data.conversation_reference_id===''+this.state.sessionDetails.session_id){
                let conversation=this.state.chatConversations.concat(data);
                this.setState({'chatConversations':conversation});
                this.scrollToBottom();

            }

        }).on('receive_message',async (data)=>{
            console.log(data,"dataaaaa",(''+data.conversation_reference_id===''+this.state.sessionDetails.session_id));
            if(''+data.conversation_reference_id===''+this.state.sessionDetails.session_id){
                let userIndex = this.state.participateUsers.findIndex((user) => {
                    data.userId = utility.getUserId(data.sender_uid);
                    if (parseInt(data.userId) === parseInt(user.userId)) {
                        return user;
                    }
                });
                let userData={};
                if (userIndex === -1) {
                    let uid = utility.getUserId(data.sender_uid);
                    let role = utility.getUserRole(data.sender_uid);

                    const parameters = {
                        'ids': uid,
                        'role': role,
                    };
                    let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                    usersList = usersList.data;
                    userData=Object.assign(usersList[0],data)
                }else{
                    userData=Object.assign(data,this.state.participateUsers[userIndex])
                }
                console.log(userData,"userDataaa");
                let conversation=this.state.chatConversations.concat(userData);
                this.setState({'chatConversations':conversation});
                this.scrollToBottom();
            }
        }).on("group_created",  (data) =>{
                 console.log(data,"group Created",this.state.sessionRoomDetails.tm_group_id,((!this.state.sessionRoomDetails.tm_group_id) || this.state.sessionRoomDetails.tm_group_id===''));
               if((!this.state.sessionRoomDetails.tm_group_id) || this.state.sessionRoomDetails.tm_group_id==="") {
                   this.state.sessionRoomDetails.tm_group_id = data.group_data.group_id;
                   this.setState({'sessionRoomDetails': this.state.sessionRoomDetails});
               }
        }).on("group_updated",  (data) =>{
        })
    };
    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({block: "end", inline: "nearest"});
        }
    }
    async  componentDidMount() {
        if(document.querySelector('header')){
            document.querySelector('header').style.display='none';
        }
        const {match : {params}} = this.props;
          if(!localStorage.getItem("currentRole")){
                  this.props.history.push(`/csda/loginSplash/`);
                  return false;
          }

          let loginUserId=localStorage.getItem('userId');
          let currentRole=localStorage.getItem('currentRole');
        this.setState({'sessionId':params.sessionId});

        let sessionDetails= await troopServer.getSessionDetails(params.sessionId);

         if(!sessionDetails || !sessionDetails.data){
             this.props.history.push(`/csda/loginSplash/`);
             return false;
         }

         let sessionInfo;
             for (const session of sessionDetails.data){
                 if(session.is_host){
                     sessionInfo=session;
                 }
             }
         if(!sessionInfo){
             for (const session of sessionDetails.data){
                 if(''+session.speaker_id===''+loginUserId && currentRole==='-6'){
                     sessionInfo=session;
                 }
             }
             if(sessionInfo){
                 let response =await troopServer.makeHost({'eventId':localStorage.getItem('eventId'),'speakerId':sessionInfo.speaker_id,'sessionId':parseInt(params.sessionId)})
                    if(response.errorCode===1){
                        window.location.reload();
                        return  false;
                    }
             }
         }
         if(!sessionInfo){
             this.setState({'isLoading':false,'isHostNotAvailable':true});
          return  false;
         }
        sessionDetails=sessionInfo;
        sessionDetails.session_id=params.sessionId;
        sessionDetails.uid=utility.generateUserUid({
            'userId': sessionDetails.speaker_id,
            'role': '-6'
        });
        this.setState({'sessionDetails':sessionDetails});
        window.onbeforeunload = function (e) {
            window.onunload = function () {
                if(cattlecall){
                    console.log(cattlecall,"comes heressss");
                    cattlecall.leaveMeeting();
                }
            }
            return undefined;
        };
        const userDetailsdata = await troopServer.getLoginUserDetails()

        if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            let loginUserDetails = userDetailsdata.data[0];
            this.setState({
                loginUserDetails: loginUserDetails
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
            loginUserDetails.uid = uid;
            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });

            //cattleCallLoginUserDetails.user_id = getUserDetails.cattle_call_id;
            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                loginUserDetails.cattle_call_id = getUserDetails.cattle_call_id;
                this.setState({'loginUserDetails':loginUserDetails},()=>{
                    this.connectCattleCall();
                    this.connectTMChat();
                });

            } catch (e) {
                console.log(e);
            }
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }

    }
    componentWillUnmount(){
        //   window.removeEventListener("beforeunload", this.onUnload);

        if(document.querySelector('header')){
            document.querySelector('header').style.display='block';
        }
    }

    speakerSessionRoomDetails=async (sessionDetails)=>{
        let sessionRoomDetails=  await  tmChatServer.getSessionRoomInfo(sessionDetails);
            console.log(sessionRoomDetails,"sessionRoomDetailss");
        sessionRoomDetails=sessionRoomDetails.speakerRoomDetails[0];
        let sepakerDetails=  await  troopServer.getUsersListBasedOnRole({'ids':''+sessionRoomDetails.created_by,'role':'-6'});
        sepakerDetails=sepakerDetails.data[0];
        this.setState({'sessionRoomDetails':sessionRoomDetails,'speakerUserDetails':sepakerDetails},()=>{
            if(this.state.sessionRoomDetails.tm_group_id){
                this.setState({'createdGroup':true});
            }
            if(!this.state.sessionRoomDetails.cattle_call_room_id || parseInt(this.state.sessionRoomDetails.cattle_call_room_id)===0) {
                        console.log(this.state.sessionDetails.speaker_id,"speaker id",this.state.loginUserDetails.userId,this.state.loginUserDetails.role);
                if(''+this.state.sessionDetails.uid!==''+this.state.loginUserDetails.uid){
                    this.getSpeakerDetails(this.state.sessionDetails.speaker_id);
                }else{
                    this.createMeeting(this.state.loginUserDetails.cattle_call_id);
                }
            }else{
                this.checkMeetingStarted()
            }
        });
    }

    getSpeakerDetails= async (speakerId)=>{
        console.log(speakerId);
        let speakerDetails=await tmChatServer.updateUser({'userId':speakerId,'role':'-6'});
        this.setState({'speakerDetails':speakerDetails});
            this.createMeeting(speakerDetails.cattle_call_id);

    }
    startMeeting=()=>{
        cattlecall.startMeeting(parseInt(this.state.sessionRoomDetails.cattle_call_room_id),this.state.sessionRoomDetails.password).then(response=>{
            this.joinMeeting();
        }).catch(error=>{
            console.log(error);
        });
        console.log(this.state.sessionRoomDetails,"start meeting",this.state.sessionRoomDetails.cattle_call_room_id);

    }
    startTimoutMeeting=()=>{
        this.setState({'isMeetingGetingEndeding':true});
    }
    resetMeeting=async ()=>{
        this.setState({'clickedExtendMeeting':true});
        await tmChatServer.resetMeeting({'sessionId':this.state.sessionId,'speakerId':this.state.speakerId});
        cattlecall.meetingCommunication({'is_going_to end':true});
        setTimeout(()=>{
            window.location.reload();
        },2000);
    };
    closeTimeOutModal=async ()=>{
        this.setState({'isMeetingGetingEndeding':false})
    };
    checkTime=()=>{

                console.log(this.state.cattle_call_meeting_info.start_date_time,"meeeting Start time");
            let meetingTime =  moment.utc(this.state.cattle_call_meeting_info.start_date_time).local();
               console.log(meetingTime.format("HH:mm:ss"),"local Start time");
            let meetingLocalTime = moment.utc(meetingTime).local();
            let meetingEndTime = meetingLocalTime.add(parseInt(115), 'minutes');
            // var a = meetingEndTime.split(':'); // split it at the colons
            let endTimeDiff = meetingEndTime.diff(new Date(), 'seconds');
            console.log(endTimeDiff, meetingEndTime.format('HH:mm:ss'), 'endTimeDiff endTimeDiff');
            // var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        let closeTime= meetingLocalTime.add(parseInt(120), 'minutes');
        if(this.state.isSpeaker) {
            let closedateTime=new Date(closeTime.format('YYYY-MM-DD HH:mm:ss')).getTime();
            setTimeout(() => {
              let x=  setInterval(() =>{

                    // Get today's date and time
                    var now = new Date().getTime();

                    // Find the distance between now and the count down date
                    var distance =  closedateTime- now;

                    // Time calculations for days, hours, minutes and seconds
                    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000);


                     let show=minutes + "m " + seconds + "s ";
                            this.setState({'showTime':show});
                    if (distance < 0) {
                        clearInterval(x);
                    }
                }, 1000);
                this.startTimoutMeeting();
            }, (endTimeDiff) * 1000);
            console.log(meetingEndTime, 'meetingEndTime');
        }

        let closeTimeDff = closeTime.diff(new Date(), 'seconds');
        setTimeout(() => {
               if(this.state.isSpeaker){
                   this.props.history.push('/csda/speaker/dashboard');
               }else{
                   this.props.history.push('/csda/loginSplash');

               }
        }, (closeTimeDff) * 1000)

    }
    checkMeetingStarted=()=>{
        console.log(this.state.sessionRoomDetails.cattle_call_room_id,'cattle_call_room_id');
        cattlecall.isMeetingStarted(this.state.sessionRoomDetails.cattle_call_room_id,this.state.sessionRoomDetails.password).then(response=>{
            console.log(response,"isMeetingStarted");
            this.setState({'isLoading':false,isMeetingStarted:true});
            this.joinMeeting();

        }).catch(error=>{
                 console.log(error,"errorerrorr at meeting");
            if(error.error_code===1 && ''+this.state.sessionRoomDetails.created_by===''+this.state.loginUserDetails.userId){
                this.startMeeting();
            }else{
                this.setState({'isMeetingStarted':false,'isLoading':false});
                //  this.joinMeeting();
            }
            if(error.error_code===0){
               // alert(error.message);
               // window.location.href=process.env.PUBLIC_URL;
            }
        })

    }

    joinMeeting=()=>{
        let speakerVideo=false;
        let localVideo=false;
        let audio=false;
        let video=false;
        this.setState({'isLoading':false,'isMeetingStarted':true});
        console.log(this.state.sessionRoomDetails.created_by,this.state.loginUserDetails,"meetingDetailsssssss");
        if(''+this.state.sessionRoomDetails.created_by===''+this.state.loginUserDetails.userId && this.state.loginUserDetails.role==='-6') {
            speakerVideo="#remoteVideoElement";
            localVideo='#localVideoElement';
            audio=true;
            video=true;
            this.setState({'isSpeaker':true});
            console.log(this.state.sessionRoomDetails.cattle_call_room_id,"cattle Calll",speakerVideo,localVideo);
            cattlecall.joinMeeting(''+this.state.sessionRoomDetails.cattle_call_room_id,this.state.sessionRoomDetails.password,speakerVideo,audio,video).then(response=>{
                  this.setState({'cattle_call_meeting_info':response.meeting_info},()=>{
                    this.checkTime();
                });
                let userList=response.data;
                console.log(userList,response,"userListtt");

                for(let user of userList) {

                    if(user.user_id) {
                        if ('' + user.user_id !== '' + this.state.loginUserDetails.cattle_call_id) {
                            this.getJoinedUserDetails(user.user_id)
                        }
                    }
                }
                this.setState({'videoCallUsers':this.state.videoCallUsers});

            }).catch(error=>{
                console.log(error);
            })
        }else{
            console.log(this.state.sessionRoomDetails.cattle_call_room_id,"cattle Calll",speakerVideo,localVideo);
            cattlecall.joinWebinar(''+this.state.sessionRoomDetails.cattle_call_room_id,this.state.sessionRoomDetails.password).then(response=>{
                let userList=response.data;
                this.checkTime();
                console.log(userList,"userListtt");
                for(let user of userList) {

                    if(user.user_id) {
                        if ('' + user.user_id !== '' + this.state.loginUserDetails.cattle_call_id) {
                            this.getJoinedUserDetails(user.user_id)
                        }
                    }
                }
                this.setState({'videoCallUsers':this.state.videoCallUsers});

            }).catch(error=>{
                console.log(error);
            })
        }


    }
    getJoinedUserDetails=async (cattleUserId)=>{
        let participateUserIndex = this.state.participateUsers.findIndex((user) => {
            if (user.cattle_call_id === cattleUserId) {
                return user;
            }
        });
        if (participateUserIndex === -1) {
            const getUserDetailsResponse = await tmChatServer.getUserDetail({'cattle_call_id': cattleUserId});
            let uid =utility.generateUserUid({
                'userId': getUserDetailsResponse.user_id,
                'role': getUserDetailsResponse.roles
            });
            let speakerUid =utility.generateUserUid({
                'userId': this.state.sessionDetails.speaker_id,
                'role':'-6'
            });
            let userIdRole = utility.getUserIdAndRole(uid);
            if(uid!==speakerUid){
                const parameters = {
                    'ids': '' + getUserDetailsResponse.user_id,
                    'role': getUserDetailsResponse.roles,
                    'eventId': this.state.eventId
                };
                let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                usersList = usersList.data;
                if (usersList && usersList.length) {
                    usersList[0].uid = utility.generateUserUid({
                        'userId': getUserDetailsResponse.user_id,
                        'role': getUserDetailsResponse.roles
                    });
                    console.log(this.state.participateUsers,"participateUsers");
                    usersList[0].cattle_call_id=cattleUserId;

                    let users= this.state.participateUsers.concat(usersList[0]);
                    this.setState({'participateUsers':users})
                    if(!this.state.createdGroup){
                        this.createGroup();
                        this.setState({'createdGroup':true});
                    }else{
                        this.addGroupMember(usersList[0])
                    }
                }
            }
        }
    }
    addGroupMember=async (member)=>{
        console.log(member,"memberererer");
        let tmDetails =await  troopMessenger.register(utility.generateUserUid({'userId':member.userId,role:member.role}), member.name);
        let  tm_user_id=tmDetails.tm_user_id;

        await troopMessenger.group().addMembers({
            group_id: this.state.sessionRoomDetails.tm_group_id ,
            members: [{user_id: tm_user_id, role: troopMessenger.groupMemberRole().NORMAL}]
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (e) {
                console.log(e);
            });
    }

    createGroup=async ()=>{
        return  new Promise(async (resolve)=>{
            let groupUsers=[];
                 console.log(this.state.participateUsers,"participateUsers");
            for(let user of this.state.participateUsers){
                let tmDetails =await  troopMessenger.register(utility.generateUserUid({'userId':user.userId,role:user.role}), user.name);
                let  tm_user_id=tmDetails.tm_user_id;
                groupUsers.push({'user_id':tm_user_id,'role': troopMessenger.groupMemberRole().ADMIN})
            }
            await troopMessenger.group().create({
                group_name: this.state.sessionDetails.session_id+'_'+this.state.sessionRoomDetails.room_id,
                group_description: "Speaker Webinar",
                members: groupUsers
            })
                .then((response)=>{
                     console.log(response,"after group Create");
                    tmChatServer.updateMeeting({'tm_group_id':response.group_data.group_id,'meeting_id':this.state.sessionRoomDetails.id});
                    this.state.sessionRoomDetails.tm_group_id=response.group_data.group_id;
                    this.setState({'sessionRoomDetails':this.state.sessionRoomDetails});
                    return resolve(true);
                })
                .catch(function (e) {
                    console.log(e,"error At Join Meett6ing");
                });
        })
    }
    createMeeting=async (cattleCallId)=>{
              console.log(cattleCallId,"cattleCallId")
        cattlecall.scheduleMeeting(this.state.sessionRoomDetails.room_id,this.state.sessionRoomDetails.password,cattleCallId).then((meetingData)=>{
            this.state.sessionRoomDetails.cattle_call_room_id=meetingData.cc_meeting_id;
            this.setState({"sessionRoomDetails":this.state.sessionRoomDetails});
            tmChatServer.updateMeeting({'cattle_call_meeting_id':meetingData.cc_meeting_id,'meeting_id':this.state.sessionRoomDetails.id});
            this.checkMeetingStarted()
        })
    }
    startScreenShareing=()=>{
        cattlecall.shareScreen();
    }
    acceptRequest=(participentId,index)=>{
        console.log(participentId,"accept R");
        cattlecall.acceptRequest(participentId);
        this.deactiveHandRaise(index);
    }
    rejectRequest=(participentId,index)=>{
        console.log(participentId,"reject R");
        cattlecall.rejectRequest(participentId)
        this.deactiveHandRaise(index);
    }
    deactiveHandRaise=(index)=>{
        let participateUser=this.state.participateUsers[index];
        let participateUsers=this.state.participateUsers;
        participateUser.is_request_chat=false;
        participateUsers.splice(index,1);
        participateUsers= [participateUser].concat(participateUsers);
        this.setState({'participateUsers':participateUsers,'isAcceptDropDown':''});
    }

    requestChat=async ()=>{
        this.setState({'isChatRequestAccepted':false,'isChatRequested':true});
           if(this.state.sessionRoomDetails.tm_group_id!==''){

           }
        cattlecall.raiseHand(this.state.loginUserDetails.cattle_call_id);
    }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };
    updateInput = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }

        this.setState({message: e.target.value})
    }
    handleSubmit =async ()=>{
        let filesPaths = [];
        this.setState({'submited': true});
        let message={};
        message.sender_uid = this.state.loginUserDetails.uid;
        message.event_id = this.state.sessionDetails.session_id;
        message.group_id = this.state.sessionRoomDetails.tm_group_id;
        message.message=this.state.message;
        console.log(message['receiver_uid'],message);
        if (message.filesPaths && message.filesPaths.length) {
            for (const filepath of message.filesPaths) {
                message.attachment = filepath;
                tmChat.sendAttachment(troopMessenger, message);
            }
        } else {
            tmChat.sendTextMessage(troopMessenger, message);
        }

        this.setState({'submited': false,'message':'','isChatRequestAccepted':false});
//Send state to the server code
    };
    openDropDown=async(cattlecallId)=>{
        this.setState({'isAcceptDropDown':cattlecallId});
    };
    toggleAudio=()=>{
        // this.props.toggleAudio();
        cattlecall.toggleAudio();
        this.setState({'audioState':!this.state.audioState});
    };
    toggleVideo=()=>{
        const activeClass=this.state.videoState;
        this.setState({'videoState':!activeClass});
        cattlecall.toggleVideo();
        //  this.setState({'videoState':activeClass });

    }
    endCall = () => {
        this.setState({videoCall: false});
        cattlecall.endMeeting();
        this.props.history.push(`/csda/loginSplash/`);
    }

    render() {
        if(this.state.isLoading){
            return ('Loading')
        }else if(this.state.isHostNotAvailable){
            return (<div>please wait meeting not yet started. speaker will start the meeting in short.

            </div>)
        }else if(!this.state.isMeetingStarted){
            return (<div>  please wait meeting not yet started. Our Speaker, Mr.{this.state.speakerUserDetails.name} will start the meeting in short.

            </div>)
        }else {
            return (
                <div className='tmChat conferenceMeeting'>
                    <div
                        className=" userslist-active startmeeting-screen d-lg-flex">
                        <div className="startmeeting-leftsection">
                            <div className="startmeeting-beforescreen position-relative">
                                <div className="startmeeting-videoon position-relative">
                                    <div className=" text-center position-relative w-100 h-100">
                                        <video autoPlay id='remoteVideoElement' src=''/>
                                    </div>
                                    {this.state.isSpeaker ? <div
                                        className="startmeeting-actionbtns startmeeting-videocall col-sm-9 col-md-7 col-lg-4 col-xl-4 mx-auto">
                                        <div className="meetings-control--dropdown mr-3">
                                            <button className="btn startmeeting-btn startmeeting-btn--record mr-0"
                                                    onClick={this.toggleAudio}>
                                                <img className={this.state.audioState ? '' : 'd-none'}
                                                     src={process.env.PUBLIC_URL + "/images/tmChat/microphone.png"}/>
                                                <img src={process.env.PUBLIC_URL + "/images/tmChat/microphone-off.png"}
                                                     className={this.state.audioState ? 'd-none' : ''}/>
                                            </button>
                                            <div className="dropup d-none">
                                                <button className="btn p-0 meetings-cam--arrowbtn dropdown-toggle"
                                                        type="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                        onClick={this.openAudioSelection}>
                                                    <i className="fa fa-chevron-up color-888"/>
                                                </button>
                                                {this.state.openAudioDevice ?
                                                    <div className="meetings-cam--dropdown dropdown-menu-right"
                                                         aria-labelledby="dropdownMenuButton">
                                                        <div
                                                            className="d-flex align-items-center justify-content-between px-3 py-2">
                                                            <div className="settings-subsection color-lightgreen mb-0">
                                                                Select Audio Device
                                                            </div>
                                                            <span className="d-inline-block"/>
                                                        </div>
                                                        {!this.state.devicesLoading && this.state.devices.audio ? this.state.devices.audio.map((audio, index) => {
                                                            return (<div className="dropdown-item px-3 py-2 pointer"
                                                                         key={index}
                                                                         onClick={e => this.onchangeAudioSelection(audio.deviceId)}>
                                                                <div
                                                                    className="d-flex justify-content-between align-items-center">
                                                                    <div className="device-name">
                                                                        {audio.label}
                                                                    </div>
                                                                    {audio.deviceId === this.state.selectedAudioDevice || (this.state.devices.audio.length === 1) ?
                                                                        <i className="fa fa-check-circle"/> : ''}
                                                                </div>
                                                            </div>);
                                                        }) : (this.state.devicesLoading ? 'Loading' : '')}

                                                    </div> : ''}
                                            </div>
                                        </div>
                                        <div className="meetings-control--dropdown mr-3">
                                            <button className="btn startmeeting-btn mr-0" onClick={this.toggleVideo}>
                                                <img className={this.state.videoState ? '' : 'd-none'}
                                                     src={process.env.PUBLIC_URL + "/images/tmChat/video.png"}/>
                                                <img className={this.state.videoState ? 'd-none' : ''}
                                                     src={process.env.PUBLIC_URL + "/images/tmChat/video_off.png"}/>
                                            </button>
                                            <div className="dropup d-none">
                                                <button className="btn p-0 meetings-cam--arrowbtn dropdown-toggle"
                                                        type="button"
                                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                                        onClick={this.openVideoSelection}>
                                                    <i className="fa fa-chevron-up color-888"/>
                                                </button>
                                                {this.state.openVideoDevice ?
                                                    <div className="meetings-cam--dropdown dropdown-menu-right"
                                                         aria-labelledby="dropdownMenuButton">
                                                        <div
                                                            className="d-flex align-items-center justify-content-between px-3 py-2">
                                                            <div className="settings-subsection color-lightgreen mb-0">
                                                                Select Camera Device
                                                            </div>
                                                            <span className="d-inline-block">
                                        </span>
                                                        </div>
                                                        {!this.state.devicesLoading && this.state.devices.video ? this.state.devices.video.map((video, index) => {
                                                            return (<div className="dropdown-item px-3 py-2 pointer"
                                                                         key={index}
                                                                         onClick={e => this.onchangeVideoSelection(video.deviceId)}>
                                                                <div
                                                                    className="d-flex justify-content-between  align-items-center">
                                                                    <div className="device-name">    {video.label}</div>
                                                                    {video.deviceId === this.state.selectedVideoDevice || (this.state.devices.video.length === 1) ?
                                                                        <i className="fa fa-check-circle"/> : ''}
                                                                </div>
                                                            </div>);
                                                        }) : (this.state.devicesLoading ? 'Loading' : '')}
                                                    </div> : ''}
                                            </div>
                                        </div>
                                        <button className="btn startmeeting-btn" onClick={this.startScreenShareing}>
                                            <img src={process.env.PUBLIC_URL + "/images/tmChat/screenshare.png"}/>
                                        </button>
                                        {/*   <button
                                        className=" btn startmeeting-btn startmeeting-volumebtn" onClick={this.speakerControl}>
                                        {this.state.speakerOff?<img src={process.env.PUBLIC_URL + "/images/tmChat/speaker_off.png"} alt=''/>:<img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/speaker.png"} />}
                                        <span className="position-absolute speaker-range"/>
                                    </button>*/}
                                        <button className="btn startmeeting-btn d-none" data-toggle="modal"
                                                data-target="#addusersModal">
                                            <img src={process.env.PUBLIC_URL + "/images/tmChat/add-user.png"}/>
                                        </button>
                                        <button className="btn startmeeting-btn d-none">
                                            <img src={process.env.PUBLIC_URL + "/images/tmChat/recording.png"}/>
                                        </button>
                                        <button onClick={this.endCall}
                                                className="btn startmeeting-btn startmeeting-btn--end">
                                            <img src={process.env.PUBLIC_URL + "/images/tmChat/endcall.png"}/>
                                        </button>
                                        {this.state.activeUser ?
                                            <button className='btn startmeeting-btn' onClick={this.openMeetingInfo}><i
                                                className='fa fa-info'/></button> : ''}

                                        {this.state.chatClose ?
                                            <button onClick={this.closeChat} className="btn startmeeting-btn bg-white">
                                                <img src={process.env.PUBLIC_URL + "/images/tmChat/chat_floatbtn.png"}/>
                                            </button> : ''}
                                        {this.state.userListClose ? <button onClick={this.closeUserList}
                                                                            className="btn startmeeting-btn bg-white">
                                            <img src={process.env.PUBLIC_URL + "/images/tmChat/users_floatbtn.png"}/>
                                        </button> : ''
                                        }
                                    </div> : ''}
                                </div>
                                <div className="tsl-logo">
                                    <img src={process.env.PUBLIC_URL + "/images/tsl.png"} className="img-fluid"/>
                                </div>
                            </div>
                        </div>
                        <div className='participants-list p-3'>
                            {this.state.activeScreenStream?<div className='w-100'><video className='w-100' autoPlay id='remoteUserVideoElement' /></div>:''}

                            {this.state.isSpeaker ?
                                <React.Fragment>
                                    <div className='participants-users'>
                                        <div className="participants-listheading">
                                            session participants ({this.state.participateUsers.length})
                                        </div>
                                        <ul className="list-unstyled mb-0 participants-listbox">
                                            {this.state.participateUsers.map((user, index) => {
                                                return (<li key={index}>
                                                    <div
                                                        className="d-flex align-items-center participant-box position-relative">
                                                        <div className="participant-img" style={{background:'url('+ user.profilePic +') no-repeat center / cover'}}>
                                                            {user.profilePic?'':utility.acronym(user.name)} {/*style={{'--img:url("")'}}*/}
                                                        </div>
                                                        <div className="participant-name" data-id={user.is_request_chat}>
                                                            {user.name}
                                                        </div>
                                                        {user.is_request_chat ?
                                                            <div className="handraise-buttons pointer">
                                                                <div className="btn-group">
                                                                    <button type="button"
                                                                            className="bg-transparent btn dropdown-toggle"
                                                                            data-toggle="dropdown"
                                                                            onClick={e => this.openDropDown(user.cattle_call_id)}>
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + '/images/tmChat/hand_raise.png'}
                                                                            alt=''/>
                                                                    </button>
                                                                    {this.state.isAcceptDropDown === user.cattle_call_id ?
                                                                        <div
                                                                            className="d-block dropdown-menu dropdown-menu-right ">
                                                                            <button className="dropdown-item"
                                                                                    type="button">
                                                                                <div
                                                                                    onClick={e => this.acceptRequest(user.cattle_call_id, index)}>
                                                                                    <span>Accept</span>
                                                                                </div>
                                                                            </button>
                                                                            <button className="dropdown-item"
                                                                                    type="button">
                                                                                <div
                                                                                    onClick={e => this.rejectRequest(user.cattle_call_id)}>
                                                                                    <span>Reject</span>
                                                                                </div>
                                                                            </button>
                                                                        </div> : ''}
                                                                </div>

                                                            </div> : ''}
                                                    </div>
                                                </li>);
                                            })}
                                        </ul>
                                    </div>
                                </React.Fragment> :(this.state.isChatRequested?<div className='text-center'>Please Wait To accept.. </div>: <div className='text-center'>
                                    <button type="button" className="p-3 border" onClick={this.requestChat}>Request Chat
                                    </button>
                                </div>)}
                            <React.Fragment>
                                <div className='handRaiseUsers position-relative'>
                                    <div className="participants-listheading">
                                        Chat
                                    </div>
                                    <div className='speakerChatMessage'>
                                        <ChatMessages userInfo={this.state.userInfo}
                                                      loginUserDetails={this.state.loginUserDetails}
                                                      chatConversations={this.state.chatConversations}
                                                      troopConnection={this.state.troopMessengerConnection}/>
                                        <div className='mb-2'/>

                                        <div style={{float: "left", clear: "both"}}
                                             ref={(el) => {
                                                 this.messagesEnd = el;
                                             }}>
                                        </div>
                                    </div>
                                    {this.state.isChatRequestAccepted || this.state.isSpeaker ? <div
                                        className="position-absolute startmeeting-typemessage speaker-chat-input w-100">
                                        <input type="text" placeholder="Type message" className="form-control"
                                               value={this.state.message} onChange={this.updateInput}
                                               onKeyDown={this.handleKeyDown}/>
                                        <button className="btn startmeeting-sendmessage" onClick={this.handleSubmit}>
                                            <img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/send.png"}
                                                 className="img-fluid"/>
                                        </button>
                                    </div> : ''}

                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                    {this.state.meetingClosed ? <React.Fragment>
                            <div className="modal-backdrop show"/>
                            <div className="modal d-block" id="">
                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content rounded-0">
                                        <div className="modal-header pl-lg-5">
                                            <h5 className="modal-title">Meeting Ended By Speaker</h5>
                                        </div>
                                        <div className="modal-body px-lg-5">
                                            Meeting Ended By Speaker
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        : ''}
                        {this.state.isMeetingGetingEndeding ? <React.Fragment>
                            <div className="modal-backdrop show"/>
                            <div className="modal d-block" id="">
                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content rounded-0">
                                        <div className="modal-header pl-lg-5">
                                            <h5 className="modal-title">Timeout Alert!!!</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                    onClick={this.closeTimeOutModal}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body px-lg-5">
                                            Session going to end in {this.state.showTime}
                                            Click Extend Button to continue
                                            <div><button type='button' className='btn' onClick={this.resetMeeting} disabled={this.state.clickedExtendMeeting}>{this.state.clickedExtendMeeting?'Please Wait..':'EXTEND BUTTON'}</button></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        : ''}
                    {this.state.resetMeetingModal ? <React.Fragment>
                            <div className="modal-backdrop show"/>
                            <div className="modal d-block" id="">
                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content rounded-0">
                                        <div className="modal-header pl-lg-5">
                                            <h5 className="modal-title">Timeout</h5>
                                        </div>
                                        <div className="modal-body px-lg-5">
                                            <a href={window.location.href}>  Session has reset please refresh the page by clicking here</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                        : ''}
                </div>)
        }
    }

}
export  default   withRouter(SpeakerWebinar)