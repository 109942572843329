import React, { Component } from 'react';
import BottomNav from './BottomNav'

import { Accordion, Card, Button  } from 'react-bootstrap';

class GoldenTicket extends Component {

    render() {

            return (
                <React.Fragment>
               
                    <section className="bg3 signleScreen paddingTop">
                            <div className="midContentWrp">
                                <h3>Leaderboard</h3>
                                <div className="midContentBx">
                                    <p><b>Info about their gamification program:</b> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since...</p>
                                    <div  className="leaderTable">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Points</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Jhon Doe</td>
                                                    <td>1,000</td>
                                                </tr>
                                                <tr>
                                                    <td>Bill Jones</td>
                                                    <td>1,000</td>
                                                </tr>
                                                <tr>
                                                    <td>Vicky</td>
                                                    <td>900</td>
                                                </tr>
                                                <tr>
                                                    <td>Ricky William</td>
                                                    <td>900</td>
                                                </tr>
                                                <tr>
                                                    <td>Jhon Doe</td>
                                                    <td>1,000</td>
                                                </tr>
                                                <tr>
                                                    <td>Bill Jones</td>
                                                    <td>1,000</td>
                                                </tr>
                                                <tr>
                                                    <td>Vicky</td>
                                                    <td>900</td>
                                                </tr>
                                                <tr>
                                                    <td>Ricky William</td>
                                                    <td>900</td>
                                                </tr>
                                                <tr>
                                                    <td>Jhon Doe</td>
                                                    <td>1,000</td>
                                                </tr>
                                                <tr>
                                                    <td>Bill Jones</td>
                                                    <td>1,000</td>
                                                </tr>
                                                <tr>
                                                    <td>Vicky</td>
                                                    <td>900</td>
                                                </tr>
                                                <tr>
                                                    <td>Ricky William</td>
                                                    <td>900</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>                                
                            </div>
                            <BottomNav />
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default GoldenTicket;
