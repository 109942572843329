import React, { Component } from 'react';
import Axios from 'axios';

import { withRouter} from 'react-router-dom';

var md5 = require('md5');

const initialState = {
    email:"",
    password : "",
    invalidDetails : false,
    token : "",
    pageLoader:true,
}



class SingleSignOn extends Component {
    constructor(props){
        super(props);
        this.state = initialState;
    }
 
    componentDidMount(){
        
        //localStorage.clear();
       const {match : {params}} = this.props;
       let emailError = "";

        if(params.emailId === ''){
            emailError= "No Email set";
        }else if(params.emailId !== ''){
          let lastAtPos = params.emailId.lastIndexOf('@');
          let lastDotPos = params.emailId.lastIndexOf('.');
          if (!(lastAtPos < lastDotPos && lastAtPos > 0 && params.emailId.indexOf('@@') === -1 && lastDotPos > 2 && (params.emailId.length - lastDotPos) > 2)) {
            emailError= "Invalid Email";
           }
        }

        if(emailError !== ''){
            this.setState({invalidDetails:true,pageLoader:false})
        }else{
            this.checkDetailsAndRedirect();
        }
        
        
    }

    checkDetailsAndRedirect = () => {
        const {match : {params}} = this.props;
        Axios.post(`${global.config.requestUrl}user/single-signon`, {
            username: params.emailId,
            pass: params.tokenpass,
            eventId:params.eventId
          })
        .then(loginRes => {
            if(loginRes.data.errorCode !== 0){
                if(loginRes.data.errorCode==2){
                    this.setState({eventExpire:true,pageLoader:false })
                }else{
                    this.setState({invalidDetails:true,pageLoader:false });
                }
            }
            
            if(loginRes.data.errorCode === 0){
                this.setState({invalidDetails:false});

                localStorage.setItem("exhibitorToken", loginRes.data.token);
                localStorage.setItem("userId", loginRes.data.id);
                localStorage.setItem("accountId", loginRes.data.accountId);
                localStorage.setItem("roles", loginRes.data.roles);
                localStorage.setItem('userName', params.emailId );
                localStorage.setItem('eventId', params.eventId );
                localStorage.setItem('tokenpass', params.tokenpass );
                localStorage.setItem('singleSignOn', "1" );
                
                
                if(localStorage.getItem('roles')){
                    var rolesArr = localStorage.getItem('roles').split(",");
                    var currentRole = -10;
                    if(rolesArr.length > 2 ){
                        currentRole = -10;
                    }
                    if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                        currentRole = -1;
                    }
                    if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                        currentRole = -6;
                    }
                    if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                        currentRole = -1;
                    }
                    if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                        currentRole = -1;
                    }
                    localStorage.setItem('currentRole',currentRole);
                    
                    
                }

                this.loginAnalytics(params.emailId,params.eventId);
                setTimeout(() => {
                    this.props.history.push('/navigate2022/loginSplash')
                }, 800);
            }
          })
          .catch(err => {
            console.log(err);
          }); 
    }

    loginAnalytics = (emailId,eventId) =>{
        const loginData = {
            email: emailId,
            eventId: eventId,
          }
          Axios.post(`${global.config.requestUrl}user/saveLoginInfo`, loginData,
              {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
          ).then(res => {
                
          }).catch(err => {
              console.log(err);
          });
      }

    

    render() {
            
            return (
                <React.Fragment>
               
                    <section className="bg2 signleScreen overflow-hidden">

                    {
                    this.state.pageLoader
                    ?
                    (
                        <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                        </div>
                    )
                    :
                    null
                }
                {
                        this.state.invalidDetails
                        ?
                        <div className="modalPopUp">
                            <div className="modalPopUpInr p-0">
                            
                                <h3 className="modalHeading">???</h3>
                                <div className="modalContent p-4">
                                <div className="form-group mb-0">
                                Unauthorized User!!!
                                </div>                       
                                </div>
                            </div>
                        </div> 
                        :
                        null
                }
                {
                            this.state.eventExpire
                            ?
                            <div className="modalPopUp">
                                <div className="modalPopUpInr p-0">
                                
                                    <h3 className="modalHeading">NOTE</h3>
                                    <div className="modalContent p-4">
                                    <div className="form-group mb-0">
                                    Event Expired !!
                                    </div>                       
                                    </div>
                                </div>
                            </div> 
                            :
                            null
                            }
                    </section>
                </React.Fragment>
            )
         
        
         
    }
}
export default withRouter(SingleSignOn);
