import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFound from './Components/NotFound';
import PrivateRoute from './PrivateRoute';

import Header from './Components/Header';

import ChatPage from "./TmChat/msc/chat/ChatPage";
import VideoCall from "./TmChat/msc/video/VideoCall";
import ConferenceMeeting from "./TmChat/msc/videoConference/conferenceMeeting";
import Meetings from "./TmChat/msc/videoConference/meetings";
import Homepage from './Homepage';


class App extends Component {

  render() {
    const domain = window.location.href
    if(domain === ("https://www.tslvirtualconference.com/")){
      return(
        <div className="App">
            <Router>
              <Route path="/" exact component={Homepage} />
              {/* <Route path="/home" exact component={Homepage} /> */}
            </Router>
        </div>
      )
    }
      else return (
      <Router>
          <div className="App">
          <Header/>
            
            <Switch>

                <PrivateRoute path="/chat" exact component={ChatPage} />
                <PrivateRoute path="/video-call" exact component={VideoCall} /> 
                <PrivateRoute path="/video-conference" exact component={Meetings} />
                <PrivateRoute path="/conference-meeting/:id" exact component={ConferenceMeeting} />
                <Route path="*" component={NotFound} />
              </Switch>
         
          </div>
      </Router>
    );
  }  
}

export default App;
