import React, { Component } from "react";
import Axios from "axios";

import { storage } from "../../../Firebase";
// import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import profileImage from "../../../assets/images/no-image.png";
import { Dropdown } from "semantic-ui-react";

const initialState = {
  company: "",
  firstName: "",
  firstNameError: "",
  lastName: "",
  lastNameError: "",
  address1: "",
  //address1Error : "",
  address2: "",
  //address2Error : "",
  city: "",
  //cityError : "",
  state: "",
  //stateError : "",
  zip: "",
  //zipError : "",
  country: "",
  //countryError : "",
  phone: "",
  phoneError: "",
  email: "",
  emailError: "",
  website: "",
  //websiteError : "",
  /* categories:"",
    categoriesError : "", */
  title: "",

  //category:"",

  categoriesOptions: [],
  currentValues: [],

  image: null,
  imageUrl: "",
  progress: 0,

  companyLogo: null,
  companyLogoUrl: "",

  imageError: "",
  companyData: [],
  exhibitorId: localStorage.getItem("userId"),
  eventId: localStorage.getItem("eventId"),
  pageLoader: true,
  authToken: localStorage.getItem("exhibitorToken"),
  submitError: "",
  submitSuccess: "",
  somethingWrongData: false,
  bio: ""
};

class ExhibitorProfile extends Component {
  constructor(props) {
    super(props);

    this.state = initialState;
  }
  /*===Company Form Validations function===*/
  validateForm = () => {
    let firstNameError = "";
    let lastNameError = "";
    //let address1Error = "";
    //let address2Error = "";
    //let cityError = "";
    // let stateError = "";
    //let zipError = "";
    /* let countryError = ""; */
    let phoneError = "";
    let emailError = "";
    /* let imageError = ""; */

    /* if(!this.state.email ){
        emailError = "Email Can't be blank" 
        } */

    if (!this.state.firstName) {
      firstNameError = "Invalid Contact First Name";
    }
    if (!this.state.lastName) {
      lastNameError = "Invalid  Contact Last Name";
    }
    // if(!this.state.address1){
    //     address1Error = "Invalid Address 1"
    // }

    // if(!this.state.city){
    //     cityError = "Invalid City Name"
    // }
    // if(!this.state.zip){
    //     zipError = "Invalid ZIP Code"
    // }
    // if(!this.state.state){
    //     stateError = "Invalid ST Name"
    // }

    if (this.state.phone === "") {
      phoneError = "Invalid Phone Number";
    }

    if (this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        emailError = "Invalid Email";
      }
    }
    if (firstNameError || lastNameError || phoneError || emailError) {
      this.setState({ firstNameError, lastNameError, phoneError, emailError });
      return false;
    }

    return true;
  };

  /*===Add Default category function===*/
  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      options: [{ text: value, value }, ...prevState.options],
    }));
  };
  /*===Handle Category value function===*/
  categoryHandleChange = (e, { value }) =>
    this.setState({ currentValues: value });

  /*===Form input field Handle function===*/
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  /*===Upload contact pic Handle function===*/
  contactPicImageChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };
  /*===company logo Handle function===*/
  companyLogoChange = (e) => {
    if (e.target.files[0]) {
      const companyLogo = e.target.files[0];
      this.setState(() => ({ companyLogo }));
    }
  };

  /*===Company Form Submit function===*/
  companyInfoSubmit = (e) => {
    e.preventDefault();

    const formValidate = this.validateForm();
    if (formValidate) {
      this.setState({ pageLoader: true });
    }

    /*===image upload function===*/
    if (this.state.image !== null) {
      const { image, exhibitorId, eventId } = this.state;
      // Create a storage reference from our storage service
      var storageRef = storage.ref();
      const uploadTask = storageRef
        .child(`images/${eventId}/${exhibitorId}/${image.name}`)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //progress func
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
          // console.log(progress)
          if (progress !== 100) {
            /* this.setState({pageLoader:true}) */
          }
        },
        (error) => {
          //error func
          console.log(error);
        },
        () => {
          //complete func
          storage
            .ref(`images/${eventId}/${exhibitorId}`)
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              //console.log(url)
              this.setState({ imageUrl: url });
              localStorage.setItem("companyContactUrl", url);
              if (url) {
                //console.log('1')
                this.submitForm();
              }
            });
        }
      );
    } else {
      //console.log('2')
      this.submitForm();
    }

    /*===Company Logo upload function===*/
    if (this.state.companyLogo !== null) {
      const { companyLogo, exhibitorId, eventId } = this.state;
      // Create a storage reference from our storage service
      var storageRef = storage.ref();
      const uploadTask = storageRef
        .child(`images/${eventId}/${exhibitorId}/${companyLogo.name}`)
        .put(companyLogo);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //progress func
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
          // console.log(progress)
          if (progress !== 100) {
            /* this.setState({pageLoader:true}) */
          }
        },
        (error) => {
          //error func
          console.log(error);
        },
        () => {
          //complete func
          storage
            .ref(`images/${eventId}/${exhibitorId}`)
            .child(companyLogo.name)
            .getDownloadURL()
            .then((url) => {
              //console.log(url)
              this.setState({ companyLogoUrl: url });
              localStorage.setItem("companyLogoUrl", url);
              if (url) {
                //console.log('3')
                this.submitForm();
              }
            });
        }
      );
    } else {
      //console.log('4')
      this.submitForm();
    }

    /* console.log(this.state) */
  };

  templateChanged = (e) => {
    this.setState({
      template: e.currentTarget.value,
    });
  };

  submitForm = () => {
    //console.log('yes')
    const formValidate = this.validateForm();
    /*===update exhibitor API function===*/
    //console.log(typeof this.state.value)
    let cateVal = this.state.currentValues;
    //console.log(this.state.imageUrl, "imageUrl")

    if (localStorage.getItem("exhibitorId")) {
      var userId = localStorage.getItem("exhibitorId");
    } else {
      var userId = localStorage.getItem("userId");
    }

    const companyDetails = {
      company: this.state.company,
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
      phone: this.state.phone,
      email: this.state.email,
      website: this.state.website,
      category: JSON.stringify(cateVal),
      exhibitorId: userId,
      eventId: localStorage.getItem("eventId"),
      image: this.state.imageUrl,
      logo: this.state.companyLogoUrl,
      title: this.state.title,
      bio: this.state.bio
      //template:this.state.template
    };
    if (formValidate) {
      Axios.post(
        `${global.config.requestUrl}exhibitor/updateExhibitorInfo`,
        companyDetails,
        { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
      )
        .then((res) => {
          if (res.data.errorCode === 0) {
            this.setState({
              submitError: "",
              submitSuccess: res.data.errorMessage,
              pageLoader: false,
            });
            document.getElementById("profile_img").src =
              localStorage.getItem("companyContactUrl");
          } else {
            this.setState({
              submitError: res.data.errorMessage,
              submitSuccess: "",
              pageLoader: false,
            });
          }
          /* removing errors */
          this.setState({
            firstNameError: "",
            lastNameError: "",
            phoneError: "",
            emailError: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    if (localStorage.getItem("exhibitorId")) {
      var userId = localStorage.getItem("exhibitorId");
    } else {
      var userId = localStorage.getItem("userId");
    }

    Axios.get(
      `${
        global.config.requestUrl
      }exhibitor/getExhibitorInfo?exhibitorId=${userId}&eventId=${localStorage.getItem(
        "eventId"
      )}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        /* console.log(res.data.data) */
        if (res.data.errorCode > 0) {
          this.setState({ somethingWrongData: true });
        }
        this.setState({
          companyData: res.data.data[0],
          company: res.data.data[0].company,
          firstName: res.data.data[0].firstName,
          lastName: res.data.data[0].lastName,
          address1: res.data.data[0].address1,
          address2: res.data.data[0].address2,
          city: res.data.data[0].city,
          state: res.data.data[0].state,
          zip: res.data.data[0].zip,
          country: res.data.data[0].country,
          phone: res.data.data[0].phone,
          email: res.data.data[0].email,
          imageUrl: res.data.data[0].image,
          companyLogoUrl: res.data.data[0].logo,
          title: res.data.data[0].title,
          pageLoader: false,
          website: res.data.data[0].website,
          bio: res.data.data[0].about
        });

        let stringData = this.state.category;
        let KeyVal = stringData.split(",");
        let objVal = [];
        for (var i = 0; i < KeyVal.length; i++) {
          objVal.push(parseInt(KeyVal[i]));
        }
        this.setState({ currentValues: objVal });

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });

    /*===Get Category List API function===*/
    const eventId = this.state.eventId;
    Axios.get(
      `${
        global.config.requestUrl
      }exhibitor/getEventCategoryList?eventId=${localStorage.getItem(
        "eventId"
      )}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        //console.log(res.data.data.map(catVal => ( catVal.value )), 'console value')
        this.setState({
          categoriesOptions: res.data.data,
          value: res.data.data.value,
        });
        //console.log(this.state.categoriesOptions)
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }
  componentWillUpdate() {}

  render() {
    const { currentValues } = this.state;

    return (
      <React.Fragment>
        <div className="p-3">
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : null}
          <div className="card p-3 border-none box-shadow">
            <div className="pageHeading">
              <Link to="/mdapa/lobby">
                <i className="fa fa-arrow-left backBtn"></i>
              </Link>
              <h4 className="text-center">Exhibitor Information</h4>
            </div>
          </div>
          <div className="card p-3 border-none box-shadow userProfile-container">
            <form
              onSubmit={this.companyInfoSubmit}
              noValidate
              autoComplete="off"
            >
              <div className="companyInfoForm">
                <div className="row">
                  <div className="col-lg-3 col-md-12 pr-0">
                    <div className="formProfile">
                      <div className="formProfilePic">
                        {this.state.imageUrl !== "" ? (
                          <div className="profile">
                            {" "}
                            <img src={this.state.imageUrl} height="80" alt="" />
                          </div>
                        ) : null}
                      </div>
                      <div className="formProfileBtn">
                        <div className="form-group">
                          <label>Main Contact Pic</label>
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            onChange={this.contactPicImageChange}
                            accept="image/x-png,image/gif,image/jpeg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="formProfile">
                      <div className="formProfilePic">
                        {this.state.companyLogoUrl !== "" ? (
                          <div className="profile">
                            {" "}
                            <img
                              src={this.state.companyLogoUrl}
                              height="80"
                              alt=""
                            />
                          </div>
                        ) : null}
                      </div>
                      <div className="formProfileBtn">
                        <div className="form-group">
                          <label>Company Logo</label>
                          <input
                            type="file"
                            className="form-control"
                            name="companyLogo"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={this.companyLogoChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-9 pr-5">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Company Name</label>
                          <input
                            type="text"
                            className="form-control"
                            name="company"
                            id="companyid"
                            value={this.state.company}
                            autoComplete="off"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Main Contact First <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            id="firstNameid"
                            onChange={this.onChange}
                            value={this.state.firstName}
                            autoComplete="off"
                          />
                          <div className="errorMessage">
                            {this.state.firstNameError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Main Contact Last <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            id="lastNameid"
                            onChange={this.onChange}
                            value={this.state.lastName}
                          />
                          <div className="errorMessage">
                            {this.state.lastNameError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Address 1</label>
                          <input
                            type="text"
                            className="form-control"
                            name="address1"
                            id="address1id"
                            onChange={this.onChange}
                            value={this.state.address1}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Address 2 </label>
                          <input
                            type="text"
                            className="form-control"
                            name="address2"
                            id="address2id"
                            onChange={this.onChange}
                            value={this.state.address2}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            name="city"
                            id="cityid"
                            onChange={this.onChange}
                            value={this.state.city}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>ST</label>
                          <input
                            type="text"
                            className="form-control"
                            name="state"
                            id="stateid"
                            onChange={this.onChange}
                            value={this.state.state}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>ZIP</label>
                          <input
                            type="text"
                            className="form-control"
                            name="zip"
                            id="zipid"
                            onChange={this.onChange}
                            value={this.state.zip}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Country </label>
                          <input
                            type="text"
                            className="form-control"
                            name="country"
                            id="countryid"
                            onChange={this.onChange}
                            value={this.state.country}
                          />
                          {/* <div className="errorMessage">{this.state.countryError}</div> */}
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Phone</label>
                          <input
                            type="text"
                            className="form-control"
                            name="phone"
                            id="phoneid"
                            onChange={this.onChange}
                            value={this.state.phone}
                          />
                          <div className="errorMessage">
                            {this.state.phoneError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            id="emailid"
                            onChange={this.onChange}
                            value={this.state.email}
                            autoComplete="off"
                            disabled={true}
                          />
                          <div className="errorMessage">
                            {this.state.emailError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Title </label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            id="titleid"
                            onChange={this.onChange}
                            value={this.state.title}
                          />
                          {/* <div className="errorMessage">{this.state.countryError}</div> */}
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label>COMPANY bio</label>
                          <textarea
                            rows="5"
                            name="bio"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.bio}
                          ></textarea>
                          <div className="errorMessage">
                            {this.state.bioError}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group text-center mb-0 mt-3 mb-4">
                      <button type="submit">
                        <span>
                          Submit <i class="fa fa-long-arrow-right pl-2"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ExhibitorProfile;
