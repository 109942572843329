import React, { Component } from 'react';
import Axios from 'axios';

import { withRouter, Link} from 'react-router-dom';
import { Base64 } from 'js-base64';

var md5 = require('md5');

const initialState = {
    firstName:"",
    lastName:"",
    email:"",
    password : "",
    repassword : "",
    firstNameError:"",
    lastNameError:"",
    emailError: "",
    passwordError : "",
    passworddoesnotmatchError : "",
    pageLoader:true,
    formSuccessAlert:false,
    formFailedError:'',
}



class Register extends Component {
    constructor(props){
        super(props);

        this.state = initialState
        if(localStorage.getItem('exhibitorToken')){
            this.props.history.push(`/common-powerup2021/loginSplash/`)
        }
        
    }



/* form validation function */
validateForm = () => {

    let emailError = "";
    let passwordError = "";
    let firstNameError = "";
    let lastNameError = "";
    let passworddoesnotmatchError = "";

    if(this.state.email !== "undefined"){
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
        emailError= "Invalid Email"
       }
    }
    if(this.state.firstName === ''){
        firstNameError = "Cannot be empty";
    }
    if(this.state.lastName === ''){
        lastNameError = "Cannot be empty";
    }
    if(this.state.password === ''){
        passwordError = "Cannot be empty";
    }
    if(this.state.password !== this.state.repassword){
        passworddoesnotmatchError = "confirm password does not match";
    }
    this.setState({
        emailError:emailError,
        firstNameError:firstNameError,
        lastNameError:lastNameError,
        passwordError:passwordError,
        passworddoesnotmatchError:passworddoesnotmatchError

    });
    if(emailError === '' && firstNameError === '' && lastNameError === '' && passwordError === '' && passworddoesnotmatchError === '' ){
        return true;
    }else{
        return false;
    }
    
  }

 /* onchange function */
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  
 /* form submit function */
 createAccount = (e) => {
    e.preventDefault();
    if(this.validateForm()){
        this.setState({pageLoader:true});
        const user = {
            firstName : this.state.firstName,
            lastName : this.state.lastName,
            email : this.state.email,
            newPass : md5(this.state.password),
            confirmPass : md5(this.state.password),
            eventId:localStorage.getItem('eventId'),
            sharableAccessCode:Base64.encode(this.state.password)
          }
          Axios.post(`${global.config.requestUrl}powerup-registration`, user).then(res => {
            if(res.data.errorCode === 0){
                this.setState({formSuccessAlert:true}); 
                setTimeout(() => {
                    this.props.history.push('/common-powerup2021/login/'+localStorage.getItem('eventId'))
                }, 800);    
            }else{
               this.setState({formFailedError:"There is already user with the same email!!!"})
            }
            this.setState({pageLoader:false});
            
          })
          .catch(err => {
            console.log(err);
          });    
    }
  }

 

    componentDidMount(){
    }



    render() {
            return (
                <React.Fragment>
               
                    <section className="bg1 signleScreen overflow-hidden">
                            <div className="loginWrp" style={{'padding':'0'}}>
                                <div className="logInForm">
                                    <div className="loginFormBx text-center">
                                    
                                        <h3>Create Account</h3>
                                        <div class="alert alert-info" role="alert">
                                            Please use the email address associated with your COMMON account
                                        </div>
                                        {
                                            this.state.formSuccessAlert
                                            ?
                                            (
                                                <div className="alert alert-success successBx text-center mb-3 mt-3" role="alert">
                                                    <b>Successfull</b>
                                                </div>
                                            )
                                            :
                                            null
                                        }
                                        {
                                            this.state.formFailedError !== ''
                                            ?
                                            (
                                                <div className="alert alert-danger text-center mb-3 mt-3" role="alert">
                                                    <b>{this.state.formFailedError}</b>
                                                </div>
                                            )
                                            :
                                            null
                                        }
                                        
                                        <form onSubmit={this.createAccount} noValidate autoComplete="off">
                                            <div className="form-group">
                                                <label className="text-left d-block">First Name <sup>*</sup></label>
                                                <input type="text" name="firstName" id="firstNameid" onChange={this.onChange} value={this.state.firstName} autoComplete="off"/>
                                                <div className="errorMessage">{this.state.firstNameError}</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-left d-block">Last Name <sup>*</sup></label>
                                                <input type="text" name="lastName" id="lastNameid" onChange={this.onChange} value={this.state.lastName} autoComplete="off"/>
                                                <div className="errorMessage">{this.state.lastNameError}</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-left d-block">Email <sup>*</sup></label>
                                                <input type="email" name="email" id="emailid" onChange={this.onChange} value={this.state.email} autoComplete="off"/>
                                                <div className="errorMessage">{this.state.emailError}</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-left d-block">Password <sup>*</sup></label>
                                                <input type="password" name="password" id="passwordid"  onChange={this.onChange} value={this.state.password} autoComplete="off"/>
                                                <div className="errorMessage">{this.state.passwordError}</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="text-left d-block">Confirm Password <sup>*</sup></label>
                                                <input type="password" name="repassword" id="passwordid" onChange={this.onChange} autoComplete="off" value={this.state.repassword} />
                                                <div className="errorMessage">{this.state.passworddoesnotmatchError}</div>
                                            </div>
                                            <div className="form-group text-center">
                                                <button type="submit">
                                                <span>Submit</span>
                                                {
                                                this.state.submitLoader
                                                ?
                                                (
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                )
                                                :
                                                <i className="fa fa-long-arrow-right"></i>
                                                }
                                                    
                                                    
                                                </button>
                                            </div>
                                        </form>
                                        <div className="form-group mb-0 text-center">
                                            <div className="formLink"  style={{'borderBottom':'none'}}>
                                                <Link to="/common-powerup2021/login/674">Already have an account Login now</Link>
                                            </div>
        
                                            
                                           
                                        </div> 
                                    </div>
                                </div>
                               
                           
                            </div>
                            
                    </section>

                </React.Fragment>
            )
         
        
         
    }
}
export default withRouter(Register);
