import React, { Component } from 'react';
import Axios from 'axios';

import {storage} from '../../Firebase';
/* import LeftNavBar from './LeftNavBar'; */


class UserProfile extends Component {
    constructor(props){
        super(props);

        this.state = {
            firstName:"",
            lastName:"",
            phone:"",
            title:"",
            image: null,
            imageUrl:'',

            firstNameError: "",
            lastNameError: "",
            phoneError: "",
            titleError:'',

            submitError : '',
            submitSuccess : '',

            exhibitorId : localStorage.getItem('userId'),
            eventId : localStorage.getItem('eventId'),
            pageLoader:true,
            authToken : localStorage.getItem('exhibitorToken'),
        }

    }

    /*===Form input field Handle function===*/
    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
        console.log(e.target.value);
    }
    /*===Upload contact pic Handle function===*/
    contactPicImageChange = (e) => {
        if(e.target.files[0]){
            const image = e.target.files[0];
            this.setState(() => ({image}))
        }
    }
    /*===Form Submit function===*/
    validateForm = (e) => {

        let firstNameError = "";
        let lastNameError = "";
        let phoneError = "";
        let titleError = "";


        if(!this.state.firstName){
            firstNameError = "Invalid First Name"
        }
        if(!this.state.lastName){
            lastNameError = "Invalid  Last Name"
        }

        if(this.state.phone === ''){
            phoneError = "Invalid Phone Number"
        }
        if(!this.state.title){
            titleError = "Invalid Title"
        }
        

        if(firstNameError || lastNameError || titleError || phoneError ){
        this.setState({firstNameError, lastNameError,  titleError, phoneError});
        return false;
        }
        
        return true;

    }

    /*===Company Form Submit function===*/
    profileInfoSubmit = (e) => {
        e.preventDefault();
        
 
        const formValidate = this.validateForm();
        if(formValidate){
            this.setState({pageLoader:true});
        }
        
        /*===image upload function===*/
        if(this.state.image !== null){
            const {image, exhibitorId, eventId} = this.state;
            // Create a storage reference from our storage service
            var storageRef = storage.ref();
            const uploadTask = storageRef.child(`images/${eventId}/${exhibitorId}/${image.name}`).put(image);
            uploadTask.on('state_changed', 
            (snapshot) => {
                //progress func
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                this.setState({progress})
                // console.log(progress)
                if(progress !== 100){
                   /* this.setState({pageLoader:true}) */
                }
            }, 
            (error) => {
                //error func
                console.log(error)
            }, 
            () => {
                //complete func  
                storage.ref(`images/${eventId}/${exhibitorId}`).child(image.name).getDownloadURL().then(url=>{
                    //console.log(url)
                    this.setState({imageUrl:url})
                    localStorage.setItem("userProfilePic", url);
                    if(url){
                        this.submitForm();
                    }
                })
            });
        }else{
            this.submitForm();
        }
    }

    submitForm = () => {
        const formValidate = this.validateForm();
        var rolesArr = localStorage.getItem('roles').split(",");
        var userType= '-10';
        if(rolesArr.indexOf('-7') !== -1){
            userType= '-7';
        }

        
        /*===update user profile API function===*/
        const userDetails = {
            firstName: this.state.firstName.trim(),
            lastName:this.state.lastName.trim(),
            phone: this.state.phone,
            title: this.state.title,
            userId: localStorage.getItem('userId'),
            type:userType,
            eventId: localStorage.getItem('eventId'),
            image:this.state.imageUrl,
        }
        if(formValidate){

            Axios.post(`${global.config.requestUrl}user/updateUserProfile`, userDetails,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                 if(res.data.errorCode === 0){
                    this.setState({submitError:'' ,submitSuccess:res.data.errorMessage, pageLoader:false})
                    if(localStorage.getItem('userProfilePic') !== '' && localStorage.getItem('userProfilePic') !== null){
                        document.getElementById("profile_img").src = localStorage.getItem('userProfilePic');
                    }
                }else{
                    this.setState({submitError:res.data.errorMessage, submitSuccess:'', pageLoader:false})
                }
                this.setState({ firstNameError:'', lastNameError:'', phoneError:'' });
                
            })
            .catch(err => {
                console.log(err);
            });  
            
            
        } 
    }

    componentDidMount(){

        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            if(localStorage.getItem('currentRole') === -10 || rolesArr.indexOf('-7') !== -1){
                //DO nothing
            }else{
                this.props.history.push('/ihca-als2021/notfound');
            }
        }
       
        /*===Get ExhibitorInfoAPI function===*/

        const details = {
            username: localStorage.getItem('userName'),
            eventId:localStorage.getItem('eventId'),
            role:localStorage.getItem('currentRole').toString()
        }

        Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
                {headers: { "auth-token": localStorage.exhibitorToken }}
            )
            .then(res => {
           
            if(res.data.errorCode){
                this.setState({somethingWrongData:true,pageLoader:false})
            }else{
                let name = res.data.data[0].name;
                let fields = name.split(' ');
                this.setState({
                    firstName: fields[0],
                    lastName:fields[1],
                    phone: res.data.data[0].phone,
                    title: res.data.data[0].designation,
                    imageUrl:res.data.data[0].profilePic,
                    pageLoader:false,
                });
            }
        })
        .catch(err => {
            console.log(err);
        });

       
    }

    render() {
        return (
            <React.Fragment>
                <section className="bg1 signleScreen">
                    {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                        )
                        :
                        null
                    }
                    <form onSubmit={this.profileInfoSubmit}>
                    
                        <div className="container pt-120">
                            <div className="profileDetails pb-4">
                                <div className="row">
                                    <div className="col-12 mb-2">
                                        {/* <div className="formHeader">
                                            <img src={process.env.PUBLIC_URL + '/images/logo-white.png'} alt="" />
                                        </div> */}
                                        <h3>User Information</h3>
                                        <div className="error text-center">{this.state.submitError}</div>
                                        <div className="success text-center">{this.state.submitSuccess}</div>
                                    </div>
                                    <div className="col-md-12 col-lg-12 pr-5">
                                        <div className="formProfile userProfile">
                                            <div className="formProfilePic">
                                                {
                                                    this.state.imageUrl !=="''" && this.state.imageUrl !==""
                                                    ?
                                                    <div className="profile"> <img src={this.state.imageUrl} height="80" alt="" /></div>
                                                    :
                                                    <img src={process.env.PUBLIC_URL + '/images/placeholder-profile.jpg'} alt="" />
                                                }
                                            </div>
                                            <div className="formProfileBtn">
                                                <div className="form-group">
                                                    <label>Main Contact Pic</label>
                                                    <input type="file" className="form-control" name="image" onChange={this.contactPicImageChange} accept="image/x-png,image/gif,image/jpeg"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row pl-4">
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>First Name <sup>*</sup></label>
                                                    <input type="text" name="firstName" className="form-control" onChange={this.onChange} value={this.state.firstName} />
                                                    <div className="errorMessage">{this.state.firstNameError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Last Name <sup>*</sup></label>
                                                    <input type="text" name="lastName" className="form-control" onChange={this.onChange} value={this.state.lastName}/>
                                                    <div className="errorMessage">{this.state.lastNameError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Title <sup>*</sup></label>
                                                    <input type="text" name="title" className="form-control" onChange={this.onChange} value={this.state.title}/>
                                                    <div className="errorMessage">{this.state.titleError}</div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <div className="form-group">
                                                    <label>Phone <sup>*</sup></label>
                                                    <input type="text" name="phone" className="form-control" onChange={this.onChange} value={this.state.phone}/>
                                                    <div className="errorMessage">{this.state.phoneError}</div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 text-center mt-3">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-warning">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    
                </section>
            </React.Fragment>
        )
    }
}
export default UserProfile;