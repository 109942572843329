import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const Maps = () => {
  const history = useHistory();
  const [pageLoader, setPageLoader] = useState(true);
  const [mapsList, setMapsList] = useState([]);
  const eventId = localStorage.getItem("eventId");

  useEffect(() => {
    getMapsData();
  }, []);

  //Get Maps data
  const getMapsData = () => {
    Axios.get(`${global.config.requestUrl}getEventMaps?eventId=${eventId}`, {
      headers: { "auth-token": localStorage.exhibitorToken },
    })

      .then((res) => {
        setPageLoader(false);
        if (!res.data.errorCode) setMapsList(res.data.data);
      })
      .catch((err) => {
        setPageLoader(false);
        console.log(err);
      });
  };

  return (
    <>
      {pageLoader ? (
        <div className="loaderWrp">
          <div className="loaderInr">
            <img src={process.env.PUBLIC_URL + "/images/loader.gif"} alt="" />
          </div>
        </div>
      ) : null}
      <div className="p-3">
        <div className="card p-3 border-none box-shadow">
          <div className="pageHeading">
            <i
              className="fa fa-arrow-left backBtn"
              onClick={() => history.goBack()}
            ></i>
            <h4 className="text-center">Maps</h4>
          </div>
        </div>
        <div className="card p-3 border-none box-shadow">
          <table className="table maps-page">
            <tbody>
              {mapsList.length > 0
                ? mapsList.map((record) => (
                    <tr key={record.doc_id}>
                      <td>
                        {/* <Link
                          to={{
                            pathname: "/fna2022/maps/details",
                            filePath: record.file,
                          }}
                        >
                          {record.name}
                          <span>
                            <i
                              className="fa fa-file-pdf-o"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </Link> */}
                        <a href={record.file} target='_blank' rel='noopener noreferrer'>
                                
                                {record.name} 
                                <span><i
                                    className="fa fa-file-pdf-o"
                                    aria-hidden="true"
                                ></i></span>
                            </a>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </table>
          {/* {mapsList.length > 0 ?
          <embed height="300px" src={mapsList[0].file+'#toolbar=0&navpanes=0&scrollbar=0&zoom=80'} type="application/pdf"/>
          : null} */}
        </div>
      </div>
    </>
  );
};

export default Maps;
