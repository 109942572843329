import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import ChatInput from '../../../TmChat/tobi2022/chat/ChatInput';

const ExhibitorDetails = () => {
  const { exhibitorId, title } = useParams();
  const [pageLoader, setPageLoader] = useState(true);
  const [exhibitorDetails, setExhibitorDetails] = useState(null);
  const currentRole = localStorage.getItem('currentRole');
  console.log("exhibitorDetails",exhibitorDetails);
  const history = useHistory();
  const eventId = localStorage.getItem("eventId");
  //   const eventId = 709;
  //   const exhibitorId = 10888;

  useEffect(() => {
    getExhibitorDetails(exhibitorId);
  }, []);

  const getExhibitorDetails = (id) => {
    Axios.get(
      `${global.config.requestUrl}exhibitor/getExhibitorInfo?exhibitorId=${id}&eventId=${eventId}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        setPageLoader(false);
        if (res.data.errorCode === 0) setExhibitorDetails(res.data.data[0]);
      })
      .catch((error) => {
        setPageLoader(false);
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      {pageLoader ? (
        <div className="loaderWrp">
          <div className="loaderInr">
            <img src={process.env.PUBLIC_URL + "/images/loader.gif"} alt="" />
          </div>
        </div>
      ) : null}
      <div className="p-3">
        <div className="card p-3 border-none box-shadow">
          <div className="pageHeading">
            <i
              className="fa fa-arrow-left backBtn"
              onClick={() => history.goBack()}
            ></i>
            <h4 className="text-center">{title} Details</h4>
          </div>
        </div>
        <div className="card p-3 border-none box-shadow">
          <div className="fullSponsor">
            {exhibitorDetails ? (
              <img
                src={
                  exhibitorDetails.logo
                    ? exhibitorDetails.logo
                    : process.env.PUBLIC_URL + "/images/top-brand-1.png"
                }
                alt=""
                className="fullWidth"
              />
            ) : null}
          </div>
        </div>
        <div className="card p-3 border-none box-shadow">
          <div className="content details-box">
            {exhibitorDetails ? (
              <React.Fragment>
                <div className="row">
                  {/* {title == "Exhibitor" ? (
                    <div className="col-sm-6 order-sm-2">
                      <div className="exhibitor-profile-pic">
                        <img src={exhibitorDetails.profilepic} />
                      </div>
                    </div>
                  ) : null} */}

                  <div className="col-sm-6" style={{paddingBottom:"45px"}}>
                    <p>
                      {exhibitorDetails.firstName
                        ? exhibitorDetails.firstName +
                          " " +
                          exhibitorDetails.lastName
                        : ""}
                    </p>

                    <p>
                      {exhibitorDetails.title ? exhibitorDetails.title : ""}
                    </p>
                    <p>
                      {exhibitorDetails.company ? exhibitorDetails.company : ""}
                    </p>
                    <p>
                      {exhibitorDetails.address1
                        ? exhibitorDetails.address1
                        : ""}
                    </p>
                    <p>
                      {exhibitorDetails.address2
                        ? exhibitorDetails.address2
                        : ""}
                    </p>
                    <p>
                      {exhibitorDetails.city ? exhibitorDetails.city : ""}
                      {exhibitorDetails.state
                        ? ", " + exhibitorDetails.state
                        : ""}
                    </p>
                    <p>{exhibitorDetails.zip ? exhibitorDetails.zip : ""}</p>
                    <p>
                      {exhibitorDetails.country ? exhibitorDetails.country : ""}
                    </p>
                    <p>
                      {exhibitorDetails.phone ? exhibitorDetails.phone : ""}
                    </p>
                    <p>
                      {exhibitorDetails.email ? exhibitorDetails.email : ""}
                    </p>
                    <p>
                      {exhibitorDetails.website ? (
                        <a
                          style={{ textDecoration: "underline" }}
                          href={exhibitorDetails.website}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {exhibitorDetails.website}
                        </a>
                      ) : null}
                    </p>
                    {exhibitorDetails.booth && title == "Exhibitor" ? (
                      <p>Booth No: {exhibitorDetails.booth}</p>
                    ) : null}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        </div>
        {exhibitorDetails && exhibitorDetails.about ? (
          <div className="card p-3 border-none box-shadow">
            <div className="content marginBottomDescription">
              <p>{exhibitorDetails.about}</p>
            </div>
          </div>
        ) : null}
      </div>
      {exhibitorDetails && exhibitorDetails.email?<ChatInput userInfo={{email:exhibitorDetails.email,role: "-1"}} />:''}
    </React.Fragment>
  );
};

export default ExhibitorDetails;
