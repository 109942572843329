import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Axios from 'axios';

class AddToScheduleRenderer extends Component {

    constructor(props){
        super(props);

        this.state={
            pageLoader : true,
        }
        
    }

    addToSessionSchedule = (sess_id,sess_name,sess_startdate,sess_starttime) => {
        document.getElementById('fordatatable').style.display = "block";
        const newBoothContact = {
            sessionId:sess_id,
            eventId:localStorage.getItem('eventId'),
            memberId:localStorage.getItem('userId'),
            role:localStorage.getItem('currentRole'),
            sName: sess_name,
            startDate: sess_startdate,
            startTime:sess_starttime,
            isSession:1
            
        }
        Axios.post(`${global.config.requestUrl}user/addEventToSchedule`, newBoothContact,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                var addedButtonId = 'already_'+sess_id;
                var ButtonId = 'add_'+sess_id;
                //document.getElementById(addedButtonId).style.display = "block";
                document.getElementById(addedButtonId).removeAttribute("style");
                document.getElementById(ButtonId).style.display = "none";
                document.getElementById('fordatatable').style.display = "none";
            })
            .catch(err => {
                console.log(err);
            }); 
    }

    render() {
        const sessId = this.props.valueFormatted ? this.props.valueFormatted : this.props.value;
        const sessName = this.props.data.session_name;
        const sessStartDate = this.props.data.start_date;
        const sessStartTime = this.props.data.start_time;
            return (
                <React.Fragment>
               
                    <section >
                   

                                                    {
                                                        this.props.addedSession.indexOf(sessId) !== -1
                                                        ?
                                                        (
                                                            <React.Fragment><button className="btn btn-disabled">Already Added</button></React.Fragment>
                                                        )
                                                        :
                                                        <React.Fragment><button id={`add_${sessId}`} onClick={() => this.addToSessionSchedule(sessId,sessName,sessStartDate,sessStartTime)} className="btn btn-link">Add to Schedule <em className="fa fa-plus ml-2"></em></button><button className="btn btn-disabled" style={{display: 'none' }} id={`already_${sessId}`}>Added</button>
                                                        </React.Fragment>
                                                    }







                                                    
                                
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default AddToScheduleRenderer;
