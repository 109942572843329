import React, { Component } from 'react';
import Axios from 'axios';
import Slider from "react-slick";
import ForgotPassword from './ForgotPassword';

import { withRouter} from 'react-router-dom';

var md5 = require('md5');

const initialState = {
    
    sponsersImgList : [],
    pageLoader:true,
   
}



class Login extends Component {
    constructor(props){
        super(props);

        this.state = initialState
        if(localStorage.getItem('exhibitorToken')){
            this.props.history.push(`/paos/loginSplash/`)
        }
        
    }





    componentDidMount(){
       
       const {match : {params}} = this.props;
        
       Axios.get(`${global.config.requestUrl}exhibitor?eventId=${params.loginId}&type=1`)
        .then(res => {
            localStorage.setItem('eventId', params.loginId)
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, pageLoader:false})
                
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({pageLoader:false})
            }
            
            /* console.log(res.data.data) */
            
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
        
    }


    render() {
            const settings = {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: true,
            };
            return (
                <React.Fragment>
               
                    <section className="bg2 signleScreen overflow-hidden">
                            <div className="loginWrp">
                                <div className="logInForm">
                                    <div className="loginFormBx">
                                        <div className="tp_logo">
                                            <img src={process.env.PUBLIC_URL + '/images/paos/paos_banner.png'} alt="" />
                                        </div>
                                        <h3>Conference Log In </h3>
                                        
                                        
                                        <p className="text-center">This event is no longer available.  Thank you for attending</p>
                                        
                                    </div>
                                </div>
                               
                                <div className="sponsersSlideBx">
                                    <Slider {...settings}>
                                        { 
                                            this.state.sponsersImgList.map(sponsers => (
                                                <div className="sponserBx" key={sponsers.logo}>
                                                <img src={sponsers.logo} alt="" className="" />
                                                </div>
                                            ))
                                            
                                        }
                                    </Slider>  
                                </div>
                            </div>
                            <div className="recBx bottomRec">
                                <h3>Recommended Web Browsers</h3>
                                <ul>
                                    <li><img src={process.env.PUBLIC_URL + '/images/paos/chrom_icon.png'} alt="" /> Google Chrome</li>
                                    <li><img src={process.env.PUBLIC_URL + '/images/paos/microsoft_edge_ico.png'} alt="" /> Microsoft Edge</li>
                                </ul>
                                <p><b>This website is not optimized for tablets or mobile phones</b></p>
                            </div>
                           
                            
                           
                            
                    </section>

                </React.Fragment>
            )
         
        
         
    }
}
export default withRouter(Login);
