import React, { Component } from 'react';
import BottomNav from './BottomNav'
/* import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css'; */

import Slider from "react-slick";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import {Link} from 'react-router-dom';
import Axios from 'axios';
import LoungeChat from "../../../TmChat/navigate2022/LoungeChat/loungeChat";



class NetworkingLounge extends Component {
    constructor(props){
        super(props);

        this.state = {
            sponsersImgList : [],
            pageLoader:true, 
        }
        
    }

    componentDidMount(){

        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?type=5&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
        
       
         .then(res => {
            
             if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, sponsersUrl:res.data.logo, apierrorCode:res.data.errorCode})
                 this.setState({ pageLoader:false})
             } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},{'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                 this.setState({pageLoader:false})
                 
             }
             
             
             return res.data;
         })
         .catch(err => {
             console.log(err);
         });
         this.updateGoldenTicketStatus();
        
    }

    updateGoldenTicketStatus = () => {
        this.setState({pageLoader:true});
       
        const ticketStatusData = {
            visitorId:localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            ticketValue:0,
            linkPage:'networking',
            sessionId:0,
            exhibitorId:0
            
        }
        Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
        .then(res => {
        if(res.data.errorCode){
            document.getElementById('goldenTkt').classList.add("greyScale");
            this.setState({pageLoader:false});
        }else{
            this.setState({pageLoader:false});
            document.getElementById('goldenTkt').classList.remove("greyScale");
        }
    })
    .catch(err => {
        console.log(err);
    });
}

    render() {
        var slideToShow = 5
        if(this.state.sponsersImgList.length < 5){
            slideToShow = this.state.sponsersImgList.length
        }
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: slideToShow,
            slidesToScroll: 1,
            autoplay: true,
          };
          const mobileSettings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
        };
            return (
                <React.Fragment>
               
                    <section className="bg5 signleScreen paddingTop">
                        <div className="mid-container">
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <div className="midTab">
                                    <div className="chatLoungeBx">
                                    <LoungeChat/>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-12 loungeScroller">
                                    
                                    {/* {
                                        window.innerWidth > 767
                                        ?
                                        <Slider {...settings}>
                                        { 
                                            this.state.sponsersImgList.map(sponsers => (
                                                <div className="sponserBx" key={sponsers.logo}>
                                                <Link to = {`/navigate2022/attendee/exhibitorBooth/${sponsers.exhibitor_id}`} ><img src={sponsers.logo} alt="" className="topBrandLogo" /></Link>
                                                </div>
                                            ))
                                            
                                        }    
                                        </Slider>
                                        :
                                        <Slider {...mobileSettings}>
                                        { 
                                            this.state.sponsersImgList.map(sponsers => (
                                                <div className="sponserBx" key={sponsers.logo}>
                                                <Link to = {`/navigate2022/attendee/exhibitorBooth/${sponsers.exhibitor_id}`} ><img src={sponsers.logo} alt="" className="topBrandLogo" /></Link>
                                                </div>
                                            ))
                                            
                                        }    
                                        </Slider>
                                    } */}
                                    <div className="row">
                                        <div className="col-lg-12 col-12">
                                            <div className="loungeBx mt-0">
                                                <h3>Social Media</h3>
                                                <div className="socialBx">
                                                    <div className="centerContent">
                                                        <div className="selfCenter standardWidth p-2">
                                                            <TwitterTimelineEmbed
                                                                sourceType="profile"
                                                                screenName="COMMONug"
                                                                options={{height: 475}}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                        <div className="sociaMediaList">
                            <ul>
                                <li>
                                    <a href="https://www.facebook.com/COMMONug" target="_blank">
                                        <i className="fa fa-facebook-f"></i>
                                        <span>Facebook</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/common-a-users-group/" target="_blank">
                                        <i className="fa fa-linkedin"></i>
                                        <span>Linkedin</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://podcasts.apple.com/us/podcast/the-incredible-i-show/id1549575685" target="_blank">
                                        <i className="fa fa-podcast"></i>
                                        <span>Podcast</span>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href="https://www.wonder.me/r?id=850e054b-6f1c-4c94-9b1d-76b20fb58c8d" target="_blank">
                                        <img src={process.env.PUBLIC_URL + '/images/navigate2022/wonderme.png'} alt=""/>
                                        <span>Wonder</span>
                                    </a>
                                </li> */}
                            </ul>
                        </div>
                        <BottomNav />
                        {
                             this.state.pageLoader
                             ?
                             (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                            )
                            :
                            null
                           }
                        
                            
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default NetworkingLounge;
