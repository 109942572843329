import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/messaging';
// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCD1DQspemjcMXgyuM_DrUBIQzHXUQWEd8",
    authDomain: "tslvirtual.firebaseapp.com",
    databaseURL: "https://tslvirtual.firebaseio.com",
    projectId: "tslvirtual",
    storageBucket: "tslvirtual.appspot.com",
    messagingSenderId: "651210131746",
    appId: "1:651210131746:web:29b307ce664723afd37d7b",
    measurementId: "G-K4VGVKGJNN"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
/*   firebase.analytics(); */

  const storage = firebase.storage();

  export {storage, firebase as default}