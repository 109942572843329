import React, {Component} from "react";
import dateTimeFormat from "../utility/dateTimeFormat";
import utility from "../utility/utility";
import troopServer from "../utility/troopServer";
import fileUpload from "../utility/firebase";

let activatedUserRole;
class ChatUsers extends Component {
    state={
        searchValue:'',
        groupModal:false,
        isLoading:false,
        groupTitle:'',
        groupDescription:'',
        groupImage:'',
        addUsersModal: false,
        usersList:{},
        checkedUsers:[],
        addedUsers:[],
        searchGroupUsersValue:'',
        showError:false,
        errorMessage:'',
        openStatusModal:false,
        bgColor:['#e2e2e2','#dcfabc','#b19cd9'],
    }
    constructor(prop) {
        super(prop);
        this.activeUser = this.activeUser.bind(this);
        this.searchUsers = this.searchUsers.bind(this);
        this.activeUserRole = this.activeUserRole.bind(this);
        this.activeSearch = this.activeSearch.bind(this);
        this.deactiveSearch = this.deactiveSearch.bind(this);

    }
    profileStatus=(e)=>{
         console.log("open the modal")
        e.stopPropagation();
        this.setState({'openStatusModal':true});
    }
    updateProfileStatus=(e,status)=>{
        console.log("open the modal")
        e.stopPropagation();
        this.setState({'openStatusModal':false});
        this.props.troopConnection.updateStatus(status)
    }

    addGroup=async (e)=>{
        if(!this.state.groupTitle && this.state.groupTitle===''){
                  this.setState({'showError':true,'errorMessage':"Please Enter Group Name"});
                  return  false;
            }
        if(!this.state.addedUsers && this.state.addedUsers.length===0){
            this.setState({'showError':true,'errorMessage':"Please Select Users to add Group"});
            return  false;
        }
        this.setState({'groupAddLoading':true});

       this.state.addedUsers.forEach(async (user)=>{
                let tmDetails = await this.props.troopConnection.register(utility.generateUserUid({'userId':user.userId,role:user.role}), user.name);
           let  tm_user_id=tmDetails.tm_user_id;
               console.log(tmDetails);
            return {'user_id':tm_user_id,'role': this.props.troopConnection.groupMemberRole().ADMIN }
        });
        let usersPromises=[];
        this.state.addedUsers.forEach(async (user)=>{

            usersPromises.push(this.formatTheUsers(user));

        });
        let filePath='';
          if(this.state.groupImage && this.state.groupImage!==''){
             filePath=await this.fileuploadHandler();
          }
          Promise.all(usersPromises).then((result)=>{
              this.props.createGroup({'group_image':filePath,'title':this.state.groupTitle,
                  'description':this.state.groupDescription,
                  'members':result}).then((response)=>{
                          if(!response.success){
                              this.setState({'showError':true,'errorMessage':response.message,'groupAddLoading':true});

                          }else{
                              this.openAddGroupModal();
                          }
              });
          });

       //

    }

    formatTheUsers=(user)=>{
        return new Promise(async (resolve) => {
            let tmDetails = await this.props.troopConnection.register(utility.generateUserUid({'userId':user.userId,role:user.role}), user.name);
            let  tm_user_id=tmDetails.tm_user_id;
          let groupRole=this.props.troopConnection.groupMemberRole().NORMAL;
           if(user.user_role){
               groupRole=user.user_role;
           }
            return resolve({'user_id':tm_user_id,'role': groupRole })

        });
    }
    groupTitleChange = (e) => {
        this.setState({groupTitle: e.target.value});
    }
    groupDescriptionChange = (e) => {
        this.setState({groupDescription: e.target.value});
    }
    componentWillMount(){
      //  window.addEventListener('scroll', this.loadMore);
    }

      componentWillUpdate() {
          if(this.usersFeed) {
              this.usersFeed.addEventListener("scroll", () => {
                    console.log(this.props.activeUserSearch,"userSearch");
                  if (this.isFeedAtBottom() && !this.state.isLoading && this.props.activeRole!=='-4' && this.props.activeUserSearch) {
                      this.loadMore();
                  }
              });
          }
      }
    isFeedAtBottom=(e)=>{
        return  ((this.usersFeed.scrollHeight) <= (this.usersFeed.scrollTop+this.usersFeed.offsetHeight+5));
    }
    isGroupUsersFeedAtBottom=(e)=>{
        return  ((this.groupUsersFeed.scrollHeight) <= (this.groupUsersFeed.scrollTop+this.groupUsersFeed.offsetHeight+5));
    }
     openAddGroupModal=()=>{
        this.setState({'groupModal':!this.state.groupModal,'groupImage':'','groupTitle':'',"groupDescription":'',addedUsers:[]});
     }
    activeUsers=(role)=> {
        this.setState({'addUserListActiveRole':role});
        if (this.state.usersList[role] === undefined) {
            this.setState({'loadingUsers': true});
            this.loadUsersList(role, 10, 0);
        } else {
            this.setState({'loadingUsers': false, 'usersListWithRole': this.state.usersList[role]})

        }
    }
    openAddUsersModal = () => {


        if (!Object.keys(this.state.usersList).length) {
            this.setState({'loadingUsers': true,'addUserListActiveRole':-10});
            this.loadUsersList(-10, 10, 0);
        }
        this.setState({"addUsersModal": !this.state.addUsersModal,"groupModal": !this.state.groupModal})
    }
    addedRoleToUser=(index,role)=>{

            console.log(index,role);
              if(role===this.state.addedUsers[index].user_role){
                  this.state.addedUsers[index].user_role=this.props.troopConnection.groupMemberRole().NORMAL;
              }else{
                  this.state.addedUsers[index].user_role=role;
              }
               this.setState({'addedUsers':this.state.addedUsers});
    }
    loadUsersList(role, limit = 20, offset = 0) {
        let parameters = {"role": role, "offset": offset, "limit":limit};
        troopServer.getUsersList(parameters).then((result) => {
            let users=[];
            if(result.data && result.data.length) {
                users = result.data.map((user) => {
                    if (this.state.checkedUsers.indexOf(user.userId + user.role)!==-1) {
                        user.userSelected = true;
                        return user;
                    } else {
                        return user;
                    }
                })
            }
              if(this.state.usersList[role] && this.state.usersList[role].length){
                  this.state.usersList[role]=this.state.usersList[role].concat(users);
              }else{
                  this.state.usersList[role]=users;
              }

            console.log(this.state.usersList[this.state.addUserListActiveRole],"sdsdsdsd");

            this.setState({
                'loadingUsers': false,
                isGroupUserLoading:false,
                usersList:this.state.usersList,
                'usersListWithRole': this.state.usersList[this.state.addUserListActiveRole]
            })
        });
    }
    addUserToMeeting = (e, index, userId, role) => {
        this.state.usersList[role][index]['userSelected'] = !this.state.usersList[role][index]['userSelected']
        if (this.state.usersList[role][index]['userSelected']) {
            this.state.addedUsers.push(this.state.usersList[role][index]);
            this.state.checkedUsers.push(userId+role);
        } else {
                console.log(this.state.addedUsers,"addedddedUserModel");
            let userIndex = this.state.addedUsers.findIndex((user) => {
                if (user.userId === userId && user.role === role) {
                    return user;
                }
            });
            if (userIndex !== -1) {
                let users=this.state.addedUsers;
                users.splice(userIndex, 1);
                this.setState({addedUsers:users});
            }
            let userSelectedIndex = this.state.checkedUsers.findIndex((user) => {
                if (user === userId+role) {
                    return user;
                }
            });
            if (userSelectedIndex !== -1) {
                this.state.checkedUsers.splice(userSelectedIndex, 1);
            }
        }
        const myNewArray = Object.assign([...this.state.usersListWithRole], {
            [index]: this.state.usersListWithRole[index]
        });
        this.setState({'usersListWithRole': myNewArray,usersList: this.state.usersList});
    }
    removeUserFromMeeting = (e, index, userId, role) => {
                let userIndex = this.state.addedUsers.findIndex((user) => {
                if (user.userId === userId && user.role === role) {
                    return user;
                }
            });
            if (userIndex !== -1) {
                let users=this.state.addedUsers;
                users.splice(userIndex, 1);
                this.setState({addedUsers:users});
            }
            let userSelectedIndex = this.state.checkedUsers.findIndex((user) => {
                if (user === userId+role) {
                    return user;
                }
            });
            if (userSelectedIndex !== -1) {
                this.state.checkedUsers.splice(userSelectedIndex, 1);
            }
        this.setState({usersList: this.state.usersList,checkedUsers:this.state.checkedUsers});
    }

    uploadFile = (event) => {
        this.saveFiles(event.target.files);
        event.target.value = null;
    }
    saveFiles = (files) => {
        var pattern = /image-*/;
        if (!files[0].type.match(pattern)) {
            alert('Invalid format');
            return;
        }
        var reader = new FileReader();
        var url = reader.readAsDataURL(files[0]);

        reader.onloadend = function (e) {
            this.setState({
                groupImageSrc: [reader.result]
            })
        }.bind(this);
        this.setState({'groupImage': files[0]})
    };

    loadMore(){
               this.setState({'isLoading': true});
               if (this.state.isLoading) {
                   this.props.loadMoreUsers()
               }
    }
    loadMoreGroupUsers=()=>{
        this.setState({'isGroupUserLoading':true});
        this.loadUsersList(this.state.addUserListActiveRole,20,this.state.usersList[this.state.addUserListActiveRole].length)
    }
    activeUser(index,entityType){
        if(entityType===this.props.troopConnection.entity().USER || this.props.activeRole!=='-4') {
            if (this.state.groupModal) {
                this.setState({'groupModal': false});
            }
            this.props.activateUser(this.props.usersList[index], index);
        }else{
            this.props.activateGroup(this.props.groupList[index], index);
        }
    }

    deactiveSearch(){
        this.setState({searchValue:''});
        this.props.deactiveSearch(activatedUserRole);
    }
    searchUsers(e){

        this.setState({'searchValue':e.target.value})
        this.props.searchUsers(e.target.value);
    }
    activeSearch(){

        this.props.activeSearch(this.props.activeRole);
    }
    activeUserRole(role){
        this.setState({'searchValue':''})
        activatedUserRole=role;
        this.props.activeUserRole(role)
    }
    componentDidUpdate(prevProps, prevState) {
             if((!this.props.isUsersLoading) && prevProps.isUsersLoading!==this.props.isUsersLoading){
                  console.log("comes Insdeit ehe state");
                 this.setState({'isLoading':false});
             }
        if(this.groupUsersFeed) {
            this.groupUsersFeed.addEventListener("scroll", () => {
                if (this.isGroupUsersFeedAtBottom() && !this.state.isGroupUserLoading) {

                    this.loadMoreGroupUsers();
                }
            });
        }
    }
    removeGroupImage=()=>{
        this.setState({'groupImage':'',groupImageSrc:''});
    }
    searchGroupMeetingUsers=(e)=>{
        this.setState({'searchGroupUsersValue':e.target.value});
        if(e.target.value){
            this.searchUsersList(this.state.addUserListActiveRole,e.target.value);
        }else{
            this.activeUsers(this.state.addUserListActiveRole);
        }
    }
    activeBgColor=(index)=>{
        let colorIndex=this.state.bgColor.length;
        if(colorIndex>index){
         index=0;
        }

        return  this.state.bgColor[index];
    }
    searchUsersList = async (role, keyword) => {
        let parameters = {"eventId": this.state.eventId, "role": role, "offset": 0, "limit": 20, 'keyword': keyword};
        let userListdata = await troopServer.getSearchUsersList(parameters);
        if (userListdata.errorCode === 4) {
            this.setState({"redirect": true});
            return;
        }
        if (userListdata.data) {
            let users=[];
            if(userListdata.data && userListdata.data.length) {
                users = userListdata.data.map((user) => {
                    if (this.state.checkedUsers.indexOf(user.userId + user.role)!==-1) {
                        user.userSelected = true;
                        return user;
                    } else {
                        return user;
                    }
                })
            }
            this.state.usersList[role] =users;
            this.setState({
                'loadingUsers': false,
                'usersListWithRole': this.state.usersList[this.state.addUserListActiveRole]
            })
        } else {
            return [];
        }
    };
    fileuploadHandler = async () => {
        const files = this.state.groupImage;
        return await fileUpload([files]);
    };
    render() {
        return (
            <React.Fragment>
                <div className="col-md-3 py-0 px-0 custom-shadow bg-white h-100 chat-leftsidebar">

                    <div className="d-flex online-user py-3 px-3 justify-content-between" onClick={e=>this.profileStatus(e)}>
                        <div className="d-flex">
                            <div className="user_img d-flex align-items-center justify-content-center position-relative">
                                <img src={(this.props.userDetails.profilePic?this.props.userDetails.profilePic:"images/user.jpg")} className="img-fluid rounded-pill"/>
                                {this.props.userDetails.user_status==='status_1'?<div className="position-absolute user_status_online"/>:''}
                                {this.props.userDetails.user_status==='status_2'?<div className="position-absolute user_status_dnd"/>:''}
                                {this.props.userDetails.user_status==='status_3'?<div className="position-absolute user_status_away"/>:''}
                                {this.props.userDetails.user_status==='offline'?<div className="position-absolute user_status_offline"/>:''}

                                {this.state.openStatusModal? <div className="d-block dropdown-menu position-absolute status-dropdown">
                                    <div className="dropdown-item" onClick={e=>this.updateProfileStatus(e,'status_1')}>
                                        {this.props.userDetails.user_status==='status_1'?<i className="fa fa-check mr-2"/>:''}
                                        Online
                                    </div>
                                    <div className="dropdown-item" onClick={e=>this.updateProfileStatus(e,'status_3')}>
                                        {this.props.userDetails.user_status==='status_3'?<i className="fa fa-check mr-2"/>:''} Away
                                    </div>
                                    <div className="dropdown-item" onClick={e=>this.updateProfileStatus(e,'status_2')}>
                                        {this.props.userDetails.user_status==='status_2'?<i className="fa fa-check mr-2"/>:''}
                                        Do not Disturb
                                    </div>

                                </div>:''}
                            </div>
                            <div className="pl-3 message_div d-flex">
                                <div>
                                    <div className="user_name w-100 name">{this.props.userDetails.name}</div>
                                    {this.props.userDetails.user_status==='status_1'? <div className="status w-100 mt-n1">online</div>:''}
                                    {this.props.userDetails.user_status==='status_2'? <div className="status w-100 mt-n1">Do Not Disturb</div>:''}
                                    {this.props.userDetails.user_status==='status_3'? <div className="status w-100 mt-n1">Away</div>:''}
                                    {this.props.userDetails.user_status==='offline'? <div className="status w-100 mt-n1">Offline</div>:''}


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex online-user2 py-3 px-3 justify-content-between ">
                        <div className={((this.props.activeRole==='-10')?'':'')+" text-center"} onClick={(args)=>this.activeUserRole( '-10')}>
                            <button>
                                {this.props.activeRole==='-10'?<img src={process.env.PUBLIC_URL + "/images/tmChat/chat_attendee_ico_active.png"} className="img-fluid" alt=''/>:<img src={process.env.PUBLIC_URL + "/images/tmChat/chat_attendee_ico.png"} className="img-fluid" alt=''/>}
                                    <span>Attendees</span>
                            </button>
                        </div>
                        <div className={((this.props.activeRole==='-1')?'':'')+" text-center"} onClick={(args)=>this.activeUserRole('-1')}>
                            <button>
                                {this.props.activeRole==='-1'?<img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_exhibitstaff_ico_active.png"} className="img-fluid"/>
                                    :<img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_exhibitstaff_ico.png"} className="img-fluid"/>
                                }
                                    <span>Exhibitor</span>
                            </button>
                        </div>
                        <div className={((this.props.activeRole==='-6')?'':'')+" text-center"} onClick={(args)=>this.activeUserRole('-6')}>
                            <button>
                                {this.props.activeRole==='-6'?<img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_speakers_icon_active.png"} className="img-fluid"/>:<img src={process.env.PUBLIC_URL + "/images/tmChat/chat_speakers_ico.png"} className="img-fluid"/> }
                                    <span>Speakers</span>
                            </button>
                        </div>
                        <div className={((this.props.activeRole==='-4')?'':'')+" text-center"} onClick={(args)=>this.activeUserRole('-4')}>
                            <button>
                                {this.props.activeRole==='-4'?<img alt='' src={process.env.PUBLIC_URL + "/images/tmChat/chat_group_ico_active.png"} className="img-fluid"/>:<img src={process.env.PUBLIC_URL + "/images/tmChat/chat_group_ico.png"} className="img-fluid"/>}
                                    <span>Groups</span>
                            </button>
                        </div>
                    </div>


                    <div className="search-bar py-3 px-3">
                        <div className="search position-relative">
                            <input type="text" placeholder="People" onChange={this.searchUsers} value={this.state.searchValue} className="rounded-pill" onFocus={this.activeSearch}/>
                                <img src={process.env.PUBLIC_URL + "/images/tmChat/search.svg"} />
                            {this.props.activeUserSearch?<span className='search-times' onClick={this.deactiveSearch}> <i className='fa fa-times'></i></span>:''}
                        </div>
                    </div>

                    <div className={(this.props.isUsersLoading)+" user_last_messages mCustomScrollbar"} data-id={this.props.isUserLoading} ref={(el) => {
                        this.usersFeed = el;
                    }}>
                        {  (this.props.usersList && this.props.usersList.length && this.props.activeRole!=='-4' )?this.props.usersList.map((user,index)=>{
                            user.profilePic=user.profilePic==="''"?'':user.profilePic;
                               return (<div className={(this.props.activeUserIndex===index?'active ':'')+" d-flex online-user py-3 px-3" + (user.unread_count?' tm-username--unread':'')} data-index={this.props.activeUserIndex} key={index}  onClick={() =>this.activeUser(index,user.entity_type)}>
                                   <div className="position-relative" data-id={user.user_status}>
                                       <div className="user_img d-flex align-items-center justify-content-center overflow-hidden" style={{background:"#b19cd9"}}>
                                           {user.profilePic?<img alt='' src={user.profilePic} className="img-fluid rounded-pill"/>:<span data-letters="ab">{utility.acronym(user.name)
                                               .toUpperCase()
                                               .slice(0, 2)}</span>}
                                       </div>

                                       {user.user_status==='status_1'?<div className="position-absolute user_status_online"/>:''}
                                       {user.user_status==='status_2'?<div className="position-absolute user_status_dnd"/>:''}
                                       {user.user_status==='status_3'?<div className="position-absolute user_status_away"/>:''}
                                       {user.user_status==='offline'?<div className="position-absolute user_status_offline"/>:''}

                                   </div>
                                   <div className="pl-3 message_div">
                                       <div className="tm-chat-company-name text-truncate">
                                          {/* {this.props.activeRole==='-1'?<div>{user.company}</div>:''}*/}
                                           <div>{user.company}</div>
                                       </div>
                                       <div className='d-flex justify-content-between'>
                                           <div className="user_name name">{user.name}</div>
                                           <div className="time">{user.created_at?dateTimeFormat.getDateDiff(user.created_at):''}</div>
                                       </div>
                                       {user.message_type===1 || user.message_type===0?( <React.Fragment><div className="d-flex align-items-center justify-content-between"><div className="user_message text-truncate">{user.message?(this.props && this.props.troopConnection?user.message:user.message):<span><img src={process.env.PUBLIC_URL + "/images/tmChat/attachment.svg"} className="img-fluid user-list-attachment"/><span>attachment</span></span> }</div>{user.unread_messages&& parseInt(user.unread_messages)!==0?<span className="unreadmessages-count">{user.unread_messages}</span>:''}</div> </React.Fragment>):''}
                                   </div>
                               </div>)
                        }):''}
                        {  this.props.groupList && this.props.groupList.length && this.props.activeRole==='-4'?this.props.groupList.map((user,index)=>{
                            let messageDisplay=''
                            if(user.message_type===this.props.troopConnection.messageType().ATTACHMENT){
                                messageDisplay=<div className="user_message text-truncate"><span><img src={process.env.PUBLIC_URL + "/images/tmChat/attachment.svg"} className="img-fluid user-list-attachment"/><span>attachment</span></span></div>;


                            }else if(user.message_type===this.props.troopConnection.messageType().TEXT){
                                messageDisplay=<div className="user_message text-truncate">{user.message}</div>;

                            }else if(user.message_type===this.props.troopConnection.messageType().TEXT){
                                messageDisplay=<div className="user_message text-truncate">{user.message}</div>;

                            }else if(user.message_type===this.props.troopConnection.messageType().GROUP_MEMBER_ROLE_UPDATED){
                                let formatMessage=JSON.parse(user.message);
                                let newRole= parseInt(formatMessage.new_role);
                                let message='';
                                if(newRole===this.props.troopConnection.groupMemberRole().MODERATOR){
                                       if(user.sender_id===this.props.loginUserDetails.tm_user_id){
                                           message='You Made '+formatMessage.name +"as moderator";
                                       }else{
                                           message=user.sender_name+' Made '+formatMessage.name +"as moderator";

                                       }
                                }else if(newRole===this.props.troopConnection.groupMemberRole().ADMIN){
                                    message=(user.sender_id===this.props.loginUserDetails.tm_user_id)?'You Made':user.sender_name+' Made as Admin';
                                    if(user.sender_id===this.props.loginUserDetails.tm_user_id){
                                        message='You Made '+formatMessage.name +" as Admin";
                                    }else{
                                        message=user.sender_name+' Made '+formatMessage.name +" as Admin";

                                    }
                                }
                                messageDisplay=<div className="user_message text-truncate">{message}</div>;

                            }
                            return (<div className={(this.props.activeUserIndex===index?'active ':'')+"d-flex online-user py-3 px-3" +(user.unread_count?' tm-username--unread':'')}  key={index}  onClick={() =>this.activeUser(index,user.entity_type)}>
                                <div className="user_img d-flex align-items-center justify-content-center" style={{background:this.state.bgColor[Math.floor(Math.random()*this.state.bgColor.length)]}}>
                                    {user.receiver_profile_pic?<img src={user.receiver_profile_pic} className="img-fluid rounded-pill"/>:<span data-letters="ab">{utility.acronym(user.receiver_name)
                                        .toUpperCase()
                                        .slice(0, 2)}</span>}
                                    <div className="position-absolute ">

                                    </div>
                                </div>
                                <div className="pl-3 message_div">
                                    <div className="d-flex justify-content-between">
                                        <div className="user_name name" >{user.receiver_name}</div> <div className="time">{user.created_at?dateTimeFormat.getDateDiff(user.created_at):''}</div>
                                    </div>
                                    { messageDisplay}
                                     </div>
                            </div>)
                        }):''}
                        {this.state.isLoading?<div className='text-center'>Loading...</div>:''}
                        {this.props.activeRole==='-4'?<div className="btn add-groupbtn" onClick={this.openAddGroupModal}>
                            <i className="fa fa-plus"/>
                        </div>:''}
                        {this.props.isUsersLoading && !this.state.isLoading ?<div className=''>Loading....</div>:''}

                    </div>




                </div>
                {this.props.activeRole==='-4' &&  this.state.groupModal?
                    <React.Fragment>
                    <div className="modal-backdrop show"></div>
                        <div className="modal d-block" id="groupinfoModal">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header pl-lg-5">
                                        <h5 className="modal-title">Group Info</h5>
                                        <button type="button" className="close roboto-light" data-dismiss="modal"
                                                aria-label="Close" onClick={this.openAddGroupModal}>
                                            <span aria-hidden="true">[ X ] Close</span>
                                        </button>
                                        <button
                                            className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2" onClick={this.openAddUsersModal}>
                                            Add Member
                                        </button>
                                    </div>
                                    {this.state.showError ? <div className="col-12 alert alert-danger">
                                        <strong>Error!</strong> {this.state.errorMessage}.
                                    </div> : ''}
                                    <div className="modal-body px-lg-5">
                                        <div className="row mx-n2 mb-3">
                                            <div className="col-12 col-lg-8 px-2 mb-4 my-lg-0">
                                                <div className="mb-4">
                                                    <label className="color-333 mb-1">
                                                        <i>Group Title</i>
                                                    </label>
                                                    <div>
                                                        <input type="text" className="form-control"
                                                               placeholder="Enter group title" value={this.state.groupTitle} onChange={this.groupTitleChange}/>
                                                    </div>
                                                </div>
                                                <div className="">
                                                    <label className="color-333 mb-1">
                                                        <i>Group Description</i>
                                                    </label>
                                                    <div>
                                                    <textarea className="form-control" rows="4"
                                                              placeholder="Enter group description" value={this.state.groupDescription} onChange={this.groupDescriptionChange}/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 col-lg-4 px-2 my-0">
                                                <div>
                                                    <div className="text-right">
                                                        {this.state.groupImage? <span className="btn d-inline-block color-333 py-1" onClick={this.removeGroupImage}>
                                            [ X ] Remove
                                        </span>:<div className='mt-4'/>}
                                                    </div>
                                                    <div className="position-relative upload-imgbox" style={{background: 'url(' + this.state.groupImageSrc + ') no-repeat center / cover'}}>
                                                        <input type="file" onChange={this.uploadFile} accept="image/x-png,image/jpeg" />
                                                        {this.state.groupImage?'':<div
                                                                className="d-flex align-items-center justify-content-center roboto-light">
                                                                Click Here
                                                            </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group-usersdetails mCustomScrollbar" data-mcs-theme="minimal-dark">
                                            <div className="row mx-n2 mb-3">

                                                {this.state.addedUsers ?this.state.addedUsers.map((user,index)=>{
                                                       return ( <div className="col-12 col-md-6 col-lg-4 px-2 mb-2 mt-0" key={index}>
                                                           <div>
                                        <span className="btn d-inline-block color-333 p-1" onClick={e=>this.removeUserFromMeeting(e,index, user.userId, user.role)}>
                                            [ X ] Remove
                                        </span>
                                                           </div>
                                                           <div className="meetings-userslist">
                                                               <div className="position-relative d-flex align-items-center">
                                                                   <div className="meetings-userpic mr-3" date-id={user.profilePic }
                                                                        style={{background: 'url(' + user.profilePic + ') no-repeat center / cover'}}>
                                                                       {user.profilePic!=='' ? '' : utility.acronym(user.name)
                                                                           .toUpperCase()
                                                                           .slice(0, 2)}
                                                                   </div>
                                                                   <div
                                                                       className="d-flex justify-content-between align-items-center user-detailsbox">
                                                                       <div className="roboto-bold color-000 text-truncate">
                                                                           {user.name}
                                                                       </div>
                                                                       <div className="user-rolebox">
                                                    <span className={((this.props.troopConnection.groupMemberRole().ADMIN===user.user_role)?'user-role--active':'')+" user-role mb-1 "} onClick={e=>this.addedRoleToUser(index,this.props.troopConnection.groupMemberRole().ADMIN)}>
                                                        A
                                                    </span>
                                                                           <span className={((this.props.troopConnection.groupMemberRole().MODERATOR===user.user_role)?'user-role--active':'')+" user-role "} onClick={e=>this.addedRoleToUser(index,this.props.troopConnection.groupMemberRole().MODERATOR)}>
                                                        M
                                                    </span>
                                                                       </div>
                                                                   </div>
                                                               </div>
                                                           </div>
                                                       </div>)
                                                }):'No Users Selected'}
                                        </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer px-lg-5 pb-lg-4">
                                        <div className="text-right">
                                            <button
                                                className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2" onClick={this.addGroup} disabled={this.state.groupAddLoading}>
                                                {this.state.groupAddLoading?'Please Wait':'Submit'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>:''}
                {this.state.addUsersModal ? <React.Fragment>
                        <div className="modal-backdrop show"></div>
                        <div className="modal d-block" id="addusersModal">
                            <div className="modal-dialog modal-lg modal-dialog-centered">
                                <div className="modal-content rounded-0">
                                    <div className="modal-header pl-lg-5">
                                        <h5 className="modal-title">Add Users</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                onClick={this.openAddUsersModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body px-lg-5">
                                        {this.state.loadingUsers ? 'Loading...' :
                                            <React.Fragment>
                                                <ul className="nav nav-tabs flex-nowrap w-100 overflow-auto mb-4">
                                                    <li className="nav-item" role="presentation">
                                                        <a className={(this.state.addUserListActiveRole === -10 ? 'active' : '') + " nav-link"}
                                                           data-toggle="tab" href="#"
                                                           onClick={e => this.activeUsers(-10)}>
                                                            <div className="addusersModal-tabs">
                                                                <i className="fab fa-slideshare"></i>
                                                            </div>
                                                            Attendees
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className={(this.state.addUserListActiveRole === -1 ? 'active' : '') + " nav-link"}
                                                           data-toggle="tab" href="#"
                                                           onClick={e => this.activeUsers(-1)}>
                                                            <div className="addusersModal-tabs">
                                                                <i className="fa fa-university"/>
                                                            </div>
                                                            Exhibit Staff
                                                        </a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className={(this.state.addUserListActiveRole === -6 ? 'active' : '') + " nav-link"}
                                                           data-toggle="tab" href="#"
                                                           onClick={e => this.activeUsers(-6)}>
                                                            <div className="addusersModal-tabs">
                                                                <i className="fa fa-microphone"/>
                                                            </div>
                                                            Speakers
                                                        </a>
                                                    </li>
                                                </ul>
                                                <div className="tab-content">
                                                    <div className="tab-pane fade show active" id="add-usersAttendees">
                                                        <div className="position-relative add-users--searchbox mb-3">
                                                            <input type="text" className="form-control" value={this.state.searchGroupUsersValue}
                                                                   placeholder="Search People" onChange={e=>this.searchGroupMeetingUsers(e)} />
                                                        </div>
                                                        <div className="addusers-list row mx-n2 mb-3"
                                                             data-mcs-theme="minimal-dark" ref={(el) => {
                                                            this.groupUsersFeed = el;
                                                        }}>

                                                                {this.state.usersListWithRole ? this.state.usersListWithRole.map((user, index) => {
                                                                    return (<div className="col-12 col-md-6 px-2 mb-2"
                                                                                 key={index}>
                                                                        <div className="meetings-userslist">
                                                                            <div
                                                                                className="position-relative d-flex align-items-center">
                                                                                {/* <span className="d-inline-block status-btn"></span>*/}
                                                                                <div className="meetings-userpic mr-3"
                                                                                     style={{background: 'url(' + user.profilePic + ') no-repeat center / cover'}}>
                                                                                    {user.profilePic ? '' : utility.acronym(user.name)
                                                                                        .toUpperCase()
                                                                                        .slice(0, 2)}
                                                                                </div>
                                                                                <div
                                                                                    className="d-flex justify-content-between align-items-center addusers-userbox">
                                                                                    <div
                                                                                        className="roboto-bold color-000">
                                                                                        {user.name}
                                                                                    </div>
                                                                                    <label
                                                                                        className="options-checkbox position-relative pointer">
                                                                                        <div className="d-inline-block">
                                                                                            <input type="checkbox"
                                                                                                   checked={user.userSelected ? 'checked' : ''}
                                                                                                   onChange={(e) => this.addUserToMeeting(e, index, user.userId, user.role)}/>
                                                                                            <span
                                                                                                className="custom-checkbox"></span>
                                                                                        </div>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>)

                                                                }) : 'No Users Found'}



                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="add-usersExhibitstaff">
                                                        Exhibit Staff
                                                    </div>
                                                    <div className="tab-pane fade" id="add-usersSpeakers">
                                                        Speakers
                                                    </div>
                                                    <div className="tab-pane fade" id="add-usersGroups">
                                                        Groups
                                                    </div>
                                                </div>
                                            </React.Fragment>}
                                    </div>
                                    <div className="modal-footer px-lg-5 pb-lg-5">
                                        <div className="text-right">
                                            <button
                                                className="btn bg-blue text-white rounded-pill roboto-bold custom-btn ml-2 py-2"
                                                onClick={this.openAddUsersModal}>
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                    : ''}
            </React.Fragment>
        )
    }
}

export default ChatUsers;