import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import React, { Component } from 'react';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Axios from 'axios';
import {storage} from '../../../Firebase';

/* check this link https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp */

class BannerEditor extends Component {


  constructor(props) {
    super(props);
    
    this.state = {pageLoader:true,errorMessage:'',successMessage:'',boothId:null,bannerFile: null,bannerText:'',bannerData:''};
}

componentDidMount (){
  var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
  Axios.get(`${global.config.requestUrl}exhibitor/getExhibitorBoothInfo?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
  {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
)
.then(res => {
  if(!res.data.errorCode){
      if(res.data.data[0].bannerText === 0){
          const editorState = EditorState.createEmpty();
          this.setState({editorState:editorState});
      }else{
        var banner_text = res.data.data[0].banner;
        const contentBlock = htmlToDraft(banner_text.replace(/\\"/g, '"').replace(/\\n/g, ''));
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        this.setState({editorState:editorState});
      }
    this.setState({
      pageLoader:false,
      boothId:res.data.data[0].boothId.toString(),
      bannerData:res.data.data[0].banner,
      bannerText:res.data.data[0].bannerText,

    });
  }else if(res.data.errorCode === 1){
          const editorState = EditorState.createEmpty();
          this.setState({editorState:editorState,pageLoader:false});
}else{
  this.setState({
      pageLoader:false,
  });
}
})
.catch(err => {
  console.log(err);
});
}
 

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  bannerFileChange = (e) => {
    if(e.target.files[0]){
        const bannerFile = e.target.files[0];
        this.setState(() => ({bannerFile}));
    }
}

  validateBannerForm = (e) =>{
  e.preventDefault();
  this.setState({ pageLoader:true});
  var bannertextForvalidation = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())).replace(/(<([^>]+)>)/gi, "").trim();
  if(bannertextForvalidation === '' && this.state.bannerFile === null && this.state.bannerData === ''){
    this.setState({errorMessage : "Please set one choice!!",pageLoader:false})
    return false;
  }
  if(bannertextForvalidation !== '' && this.state.bannerFile !== null){
    this.setState({errorMessage : "You only allowed to add a banner or text at a time.",pageLoader:false})
    return false;
  }
  if(bannertextForvalidation !== ''){
    this.setState({
      bannerData:draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())),bannerText:'1'
  }, () => {
    this.updateBannerToDB();
  });
    
  }
  else if(this.state.bannerFile !== null){
    console.log(4)
    this.setState({
      bannerText:'0'
  }, () => {
    this.uploadAndSaveBanner();
  });
   
  }else if(this.state.bannerData !== ''){
    this.setState({errorMessage : "Nothing to update!!!"})
    this.setState({ pageLoader:false});
  }
  }

  updateBannerToDB= () => {
    
      this.setState({ pageLoader:true,errorMessage : ""});
      var userId =  localStorage.getItem('userId');
        if(localStorage.getItem('exhibitorId')){
            userId =  localStorage.getItem('exhibitorId');
        }
    const boothData = {
      memberId: userId,
      eventId: localStorage.getItem('eventId'),
      boothId : this.state.boothId,
      bannerText: this.state.bannerText,
      banner: this.state.bannerData,
      
     }
    
    Axios.post(`${global.config.requestUrl}exhibitor/setUpExhibitorBooth`, boothData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
              this.setState({ pageLoader:false});
              if(res.data.errorCode === 0){
                this.setState({
                  successMessage : res.data.errorMessage
                })
                this.props.parentCallback(res.data.result.boothId);

                if(this.state.bannerText == 0 ){
                  document.getElementById("bannerhtml").innerHTML = '<img src="'+this.state.bannerData+'"/>';
              }else{
                var tt = this.state.bannerData;
                  document.getElementById('bannerhtml').innerHTML = tt.replace(/(^|[^\\])(\\\\)*\\$/, "");
              }
               
                setTimeout(() => {document.getElementById("bannermodel").click();}, 800);
              }else{
                this.setState({
                    errorMessage : res.data.errorMessage
                })
              }
              this.setState({
                pageLoader:false
              })
              
            }
    );

    
    
  }

  
    /* ends company bio*/
    /*Upload Company Banner*/
    uploadAndSaveBanner = () => {
          
          // Create a storage reference from our storage service
          var storageRef = storage.ref();
          const uploadTask = storageRef.child(`images/${localStorage.getItem('eventId')}/${localStorage.getItem('userId')}/${this.state.bannerFile.name}`).put(this.state.bannerFile);
          uploadTask.on('state_changed', 
          (snapshot) => {
              //progress func
              const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
              this.setState({progress})
              // console.log(progress)
              if(progress !== 100){
                  this.setState({pageLoader:true})
              }
          }, 
          (error) => {
              //error func
              console.log(error)
          }, 
          () => {
              //complete func  
              storage.ref(`images/${localStorage.getItem('eventId')}/${localStorage.getItem('userId')}`).child(this.state.bannerFile.name).getDownloadURL().then(url=>{
                  console.log(url)
                  this.setState({
                    bannerData:url,bannerText:'0'
                }, () => {
                  this.updateBannerToDB();
                });
                  
                  
              })
          });
             
  }

  render() {
    const { editorState } = this.state;
   
    return (
        <div className="bannereditor" >
          <div className="success">{this.state.successMessage}</div>
          <div className="error">{this.state.errorMessage}</div>
          <form onSubmit={this.validateBannerForm}>
                                                  <div className="form-group">
                                                      <label className="mb-2">Upload Banner</label><br/>
                                                      <input type="file" name="exhibitCompanyLogo" accept="image/x-png,image/gif,image/jpeg" onChange={this.bannerFileChange} />
                                                      <span className="d-block mt-2 mb-2">or</span>
                                                      <Editor
                                                          editorState={editorState}
                                                          wrapperClassName="demo-wrapper"
                                                          editorClassName="demo-editor"
                                                          rawContentState={editorState}
                                                          onEditorStateChange={this.onEditorStateChange}
                                                          toolbar={{
                                                            options:['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history']
                                                          }}
                                                      />
                                                  </div>
                                                  <div className="mt-3 text-center">
                                                    <button className="btn btn-primary" type="submit">Submit</button>
                                                  </div>
                                              </form>
                                              {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                
        
        </div>
    );
  }
}
export default BannerEditor;