import React,{useState, useEffect} from 'react';
import { useParams } from "react-router-dom";
import Axios from 'axios';
import { useHistory } from 'react-router-dom';
import ChatInput from '../../../TmChat/MdAPA/chat/ChatInput';

const AttendeeDetails = () => {
    const history = useHistory();
    const [attendeeDetail, setAttendeeDetail] = useState([]);
    const { emailId } = useParams();
    useEffect(()=>{
        const userListData = {
            eventId: localStorage.getItem('eventId'),
            role:localStorage.getItem("currentRole").toString(),
            username:emailId,
            isDetails:1
            
        }
        Axios.post(`${global.config.requestUrl}user/getUserDetails`, userListData,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            ).then(res => {
               if(res.data.errorCode === 0){
                  setAttendeeDetail(res.data.data[0]);
               }
            })
            .catch(err => {
               console.log(err);
            });
    },[emailId]);
    return (
        <React.Fragment>
            <div className="p-3">
                <div className="card p-3 border-none box-shadow">
                    <div className="pageHeading">
                        <i className="fa fa-arrow-left backBtn" onClick={() => history.goBack()}></i>
                        <h4 className="text-center">Attendee Details</h4>
                    </div>
                </div>

                {/* <div className="card p-3 border-none box-shadow">
                    <div className="justify-content-around align-items-center d-flex mb-3">
                        <ul className="listInline m-0">
                            <li><i class="fa fa-star"></i></li>
                            <li><i class="fa fa-check"></i></li>
                            <li><i class="fa fa-pencil"></i></li>
                            <li><i class="fa fa-calendar"></i></li>
                        </ul>
                    </div>
                </div> */}
                <div className="card p-3 border-none box-shadow">
                    <div className="content details-box">
                        <p>{attendeeDetail.name} {attendeeDetail.credentials}</p>
                        <p>{attendeeDetail.designation}</p>
                        <p>{attendeeDetail.company}</p>
                        <p>{ attendeeDetail.city}, {attendeeDetail.state}{ attendeeDetail.country ? `, ${attendeeDetail.country}` : ''}</p> </div>
                </div>
            </div>
            <ChatInput userInfo={{email:emailId,role: "111"}} />
        </React.Fragment>
    )
}
export default AttendeeDetails;