import React, { Component } from "react";
import {Link} from 'react-router-dom';
import Axios from 'axios';


class FilterableExhibitorList extends Component {
  state = {
    exhibitorList: [],
    categoryList: [],
    pageLoader:true,
    offset:0,
    limit:10,
    searchByKeyword:'',
    searchByCategory:'',
    textToSearch:'',
    showloadmore:false,
    norecord:false,
   
  };
  closeTheExhibitorPopup(){
    document.getElementById('exhibitpopclose').click();
  }
  render() {
    return (
        <div className="exhibitors">
        <h3 className="modalHeading pt-3 pb-3">Exhibitor List</h3>
        <div className="sortBy">
            <div className="sortBx">
                <label>Filter By:</label>
                <select name="categoryfilter" onChange={this.onChangeCategoryHandler}>
                           <option value=''>All</option>
                    { 
                         this.state.categoryList.map(category => (
                           <option value={category.value} key={category.value}>{category.text}</option>
                         ))
                    }
                    
                </select>
            </div>
            <div className="searchBx">
              <form onSubmit={this.searchText}>
                  <input type="text" name="textToSearch" value={this.state.textToSearch}  placeholder="Search Here..." onChange={this.onChange}/>
                  <button><i className="fa fa-search"></i></button>
              </form>
            </div>
        </div>
            <div className="midTableWrp">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th>Exhibitor Logo</th>
                                                    <th>Company Name</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                      this.state.norecord
                                      ?
                                      (
                                        <tr>
                                                <td colSpan="4">No record Found</td>
                                        </tr>
                                      )
                                      :
                                      null
                                    }

                                            

                                            { 
                                            this.state.exhibitorList.map(exhibitor => (
                                                
                                                <tr key={exhibitor.exhibitor_id}>
                                                <td><p><img src={exhibitor.logo} alt="" /></p></td>
                                                <td>
                                                    <h4>{exhibitor.company} </h4>
                                                </td>
                                                <td>
                                                <Link to = {`/common-powerup2021/attendee/exhibitorBooth/${exhibitor.exhibitor_id}`} >
                                                <button onClick={this.closeTheExhibitorPopup}><i className="fa fa-eye"></i><span>View</span></button>
                                                </Link>
                                                    
                                                </td>
                                                </tr>
                                            ))
                                        }
                                            </tbody>
                                        </table>
                                    </div>

                                    {
                                      this.state.showloadmore
                                      ?
                                      (
                                        <div className="loadmore text-center mt-4" onClick={this.renderExhibitorList}><button className="btn btn-success">Load More Exhibitor...</button></div>
                                      )
                                      :
                                      null
                                    }
                                    {
                                      !this.state.showloadmore && !this.state.norecord
                                      ?
                                      (
                                        <div className="allResult">All result are here now.</div>
                                      )
                                      :
                                      null
                                    }


                                   
                                    {
                             this.state.pageLoader
                             ?
                             (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                            )
                            :
                            null
                           }
     </div>
    );
  }

  /*input box value change*/
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
}

  onChangeCategoryHandler = (e) => {
      console.log(e.target.value);
      this.setState({
        searchByCategory:e.target.value,
        offset:0,
        limit:10,
        exhibitorList: [],
      }, () => {
        this.renderExhibitorList();
      });
      
  }

  searchText = (e) => {
    e.preventDefault();
    this.setState({
        searchByKeyword:this.state.textToSearch,
        offset:0,
        limit:10,
        exhibitorList: [],
      }, () => {
        this.renderExhibitorList();
      });
 }

  
  

  componentDidMount() {
    this.renderCategoryList();
    this.renderExhibitorList();
    
  }

  renderCategoryList = () => {
    /* getting exhibitor list with logos */

    Axios.get(`${global.config.requestUrl}exhibitor/getEventCategoryList?eventId=${localStorage.getItem('eventId')}`, 
    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
    
   
     .then(res => {
        
         if(res.data.errorCode === 0){
            this.setState({categoryList:res.data.data})
            this.setState({ pageLoader:false})
         } else{
             this.setState({pageLoader:false})
             
         }
         
     })
     .catch(err => {
         console.log(err);
     });
 }

  renderExhibitorList = () => {
    this.setState({pageLoader:true})
     /* getting exhibitor list with logos */

     const exhibitorData = {
        eventId: localStorage.getItem('eventId'),
        offset: this.state.offset,
        limit:this.state.limit,
        searchByKeyword:this.state.searchByKeyword,
        searchByCategory:this.state.searchByCategory,

        
       }
       Axios.post(`${global.config.requestUrl}user/getAllExhibitors`, exhibitorData,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
      .then(res => {
         
          if(res.data.errorCode === 0){
            var datacount = Object.keys(res.data.data).length;
            var newoffset = this.state.offset
             this.setState({ exhibitorList: [...this.state.exhibitorList, ...res.data.data ] ,pageLoader:false,norecord:false})
             this.setState({offset: newoffset + 10});
             if(datacount < 10){
                this.setState({ showloadmore:false})
             }else{
                this.setState({ showloadmore:true})
             }
             
          }else if(res.data.errorCode === 1){
            this.setState({ pageLoader:false,showloadmore:false,norecord:true})
          
          } else{
            this.setState({ pageLoader:false})
              
          }
          
          
          
      })
      .catch(err => {
          console.log(err);
      });
  }

  

}

export default FilterableExhibitorList;