import React, { Component } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import { storage } from "../../../Firebase";
import SessionsAndPostersEdit from "./SessionsAndPostersEdit";

class SpeakerProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      credentials: "",
      title: "",
      company: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      bio: "",

      image: null,
      imageUrl: "",

      firstNameError: "",
      lastNameError: "",
      emailError: "",
      phoneError: "",
      companyError: "",
      address1Error: "",
      cityError: "",
      stateError: "",
      zipError: "",
      countryError: "",
      bioError: "",

      submitError: "",
      submitSuccess: "",

      exhibitorId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      pageLoader: true,
      authToken: localStorage.getItem("exhibitorToken"),
    };
  }

  /*===Form input field Handle function===*/
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    console.log(e.target.value);
  };
  /*===Upload contact pic Handle function===*/
  contactPicImageChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));
    }
  };
  /*===Form Submit function===*/
  validateForm = (e) => {
    let firstNameError = "";
    let lastNameError = "";
    let emailError = "";
    let phoneError = "";
    let companyError = "";
    let address1Error = "";
    let cityError = "";
    let stateError = "";
    let zipError = "";
    /* let countryError = ""; */
    let bioError = "";

    if (!this.state.firstName) {
      firstNameError = "Invalid Contact First Name";
    }
    if (!this.state.lastName) {
      lastNameError = "Invalid  Contact Last Name";
    }
    if (this.state.email !== "undefined") {
      let lastAtPos = this.state.email.lastIndexOf("@");
      let lastDotPos = this.state.email.lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          this.state.email.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          this.state.email.length - lastDotPos > 2
        )
      ) {
        emailError = "Invalid Email";
      }
    }
    // if(this.state.phone === ''){
    //     phoneError = "Invalid Phone Number"
    // }
    // if(!this.state.company){
    //     companyError = "Invalid Company Name"
    // }
    // if(!this.state.address1){
    //     address1Error = "Invalid Address 1"
    // }

    // if(!this.state.city){
    //     cityError = "Invalid City Name"
    // }
    // if(!this.state.state){
    //     stateError = "Invalid ST Name"
    // }

    // if(!this.state.zip){
    //     zipError = "Invalid ZIP Code"
    // }

    if (
      firstNameError ||
      lastNameError ||
      emailError /*|| phoneError || companyError || address1Error || cityError || stateError || zipError  || bioError*/
    ) {
      this.setState({
        firstNameError,
        lastNameError,
        emailError /*, phoneError, companyError, address1Error, cityError, stateError, zipError,  bioError*/,
      });
      return false;
    }

    return true;
  };

  /*===Company Form Submit function===*/
  profileInfoSubmit = (e) => {
    e.preventDefault();

    const formValidate = this.validateForm();
    if (formValidate) {
      this.setState({ pageLoader: true });
    }

    /*===image upload function===*/
    if (this.state.image !== null) {
      const { image, exhibitorId, eventId } = this.state;
      // Create a storage reference from our storage service
      var storageRef = storage.ref();
      const uploadTask = storageRef
        .child(`images/${eventId}/${exhibitorId}/${image.name}`)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //progress func
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
          // console.log(progress)
          if (progress !== 100) {
            /* this.setState({pageLoader:true}) */
          }
        },
        (error) => {
          //error func
          console.log(error);
        },
        () => {
          //complete func
          storage
            .ref(`images/${eventId}/${exhibitorId}`)
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              //console.log(url)
              this.setState({ imageUrl: url });
              localStorage.setItem("speakerProfilePic", url);
              if (url) {
                this.submitForm();
              }
            });
        }
      );
    } else {
      this.submitForm();
    }
  };

  submitForm = () => {
    const formValidate = this.validateForm();
    /*===update speaker profile API function===*/
    const speakerDetails = {
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      email: this.state.email,
      phone: this.state.phone,
      title: this.state.title,
      company: this.state.company,
      address1: this.state.address1,
      address2: this.state.address2,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
      bio: this.state.bio,
      speakerId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      image: this.state.imageUrl,
    };
    if (formValidate) {
      Axios.post(
        `${global.config.requestUrl}speaker/updateSpeakerInfo`,
        speakerDetails,
        { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
      )
        .then((res) => {
          if (res.data.errorCode === 0) {
            this.setState({
              submitError: "",
              submitSuccess: res.data.errorMessage,
              pageLoader: false,
            });
            if (localStorage.getItem("speakerProfilePic") !== "") {
              document.getElementById("profile_img").src =
                localStorage.getItem("speakerProfilePic");
            }
          } else {
            this.setState({
              submitError: res.data.errorMessage,
              submitSuccess: "",
              pageLoader: false,
            });
          }
          /* removing errors */
          this.setState({
            companyError: "",
            firstNameError: "",
            lastNameError: "",
            address1Error: "",
            cityError: "",
            stateError: "",
            zipError: "",
            phoneError: "",
            emailError: "",
            countryError: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    if (localStorage.getItem("roles")) {
      var rolesArr = localStorage.getItem("roles").split(",");
      if (rolesArr.indexOf("-6") == -1) {
        this.props.history.push("/csda/notfound");
      }
    }

    /*===Get ExhibitorInfoAPI function===*/

    Axios.get(
      `${
        global.config.requestUrl
      }speaker/getSpeakerInfo?eventId=${localStorage.getItem(
        "eventId"
      )}&speakerId=${localStorage.getItem("userId")}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        if (res.data.errorCode > 0) {
          this.setState({ somethingWrongData: true });
        }
        this.setState({
          firstName: res.data.data[0].firstName,
          lastName: res.data.data[0].lastName,
          email: res.data.data[0].email,
          phone: res.data.data[0].phone,
          title: res.data.data[0].title,
          credentials: res.data.data[0].credentials,
          company: res.data.data[0].company,
          address1: res.data.data[0].address1,
          address2: res.data.data[0].address2,
          city: res.data.data[0].city,
          state: res.data.data[0].state,
          zip: res.data.data[0].zip,
          country: res.data.data[0].country,
          bio: res.data.data[0].bio,
          imageUrl: res.data.data[0].image,
          pageLoader: false,
        });
        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <React.Fragment>
        <div className="p-3">
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : null}
            <div className="card p-3 border-none box-shadow">
            <div className="pageHeading">
              <Link to="/fna2022/lobby">
                <i className="fa fa-arrow-left backBtn"></i>
              </Link>
              <h4 className="text-center">Speaker Information</h4>
            </div>
          </div>
          <div className="card p-3 border-none box-shadow userProfile-container">
          <form onSubmit={this.profileInfoSubmit}>
            <div className="container">
              <div className="profileDetails pb-4">
                <div className="row">
                  <div className="col-12 mb-2">
                    <div className="error text-center">
                      {this.state.submitError}
                    </div>
                    <div className="success text-center">
                      {this.state.submitSuccess}
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <div className="formProfile">
                      <div className="formProfilePic">
                        {this.state.imageUrl !== "" ? (
                          <div className="profile">
                            {" "}
                            <img src={this.state.imageUrl} height="80" alt="" />
                          </div>
                        ) : (
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/placeholder-profile.jpg"
                            }
                            alt=""
                          />
                        )}
                      </div>
                      <div className="formProfileBtn">
                        <div className="form-group">
                          <label>Main Contact Pic</label>
                          <input
                            type="file"
                            className="form-control"
                            name="image"
                            onChange={this.contactPicImageChange}
                            accept="image/x-png,image/gif,image/jpeg"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-9 pr-5">
                    <div className="row">
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>
                            First Name <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.firstName}
                          />
                          <div className="errorMessage">
                            {this.state.firstNameError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.lastName}
                          />
                          <div className="errorMessage">
                            {this.state.lastNameError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>
                            Email <sup>*</sup>
                          </label>
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.email}
                            disabled
                          />
                          <div className="errorMessage">
                            {this.state.emailError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Phone </label>
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.phone}
                          />
                          <div className="errorMessage">
                            {this.state.phoneError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Title</label>
                          <input
                            type="text"
                            name="title"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.title}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Company </label>
                          <input
                            type="text"
                            name="company"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.company}
                          />
                          <div className="errorMessage">
                            {this.state.companyError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Address 1 </label>
                          <input
                            type="text"
                            name="address1"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.address1}
                          />
                          <div className="errorMessage">
                            {this.state.address1Error}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Address 2 </label>
                          <input
                            type="text"
                            name="address2"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.address2}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>City </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.city}
                          />
                          <div className="errorMessage">
                            {this.state.cityError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>State </label>
                          <input
                            type="text"
                            name="state"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.state}
                          />
                          <div className="errorMessage">
                            {this.state.stateError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>ZIP </label>
                          <input
                            type="text"
                            name="zip"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.zip}
                          />
                          <div className="errorMessage">
                            {this.state.zipError}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <div className="form-group">
                          <label>Country</label>
                          <input
                            type="text"
                            name="country"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.country}
                          />
                          {/* <div className="errorMessage">{this.state.countryError}</div> */}
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label>Bio </label>
                          <textarea
                            rows="5"
                            name="bio"
                            className="form-control"
                            onChange={this.onChange}
                            value={this.state.bio}
                          ></textarea>
                          <div className="errorMessage">
                            {this.state.bioError}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12 text-center mt-3">
                    <div className="form-group">
                      <button type="submit" className="btn btn-warning">
                      <span>
                          Submit <i class="fa fa-long-arrow-right pl-2"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          </div>
        </div>
        <div className="p-3">
          <div className="card p-3 border-none box-shadow">
            <SessionsAndPostersEdit />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default SpeakerProfile;
