import {storage} from '../../../Firebase';
var getFileBlob = function (url, cb) {
    return new Promise(resolve => {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.addEventListener('load', function() {
            return resolve(xhr.response);
        });
        xhr.send();
    });
};
const fireBaseUpload= (file)=>{
    return new Promise(async (resolve) => {

        var metadata = {
            contentType: file.type,
        };
        let fileData;
        let fileName;
        if(file.fileType==='blod')
        {
            fileData=await getFileBlob(file.blod);
            fileName=file.name;
        }else{
            fileData=file;
            fileName=file.name;
        }
          console.log(fileData,"fileDtaa");
        var uploadTask=  storage.ref().child('tmChat/'+fileName).put(fileData, metadata);

        uploadTask.on('state_changed', // or 'state_changed'
            function(snapshot) {
                var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');
            }, function(error) {
            console.log(error,"errr");
                   return resolve('');
            }, function() {
                // Upload completed successfully, now we can get the download URL
                uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                    console.log(downloadURL,"donwloadUrl");
                  return resolve(downloadURL);
                });
            });
    });
};
const fileUpload=(files)=>{
    return new Promise((resolve => {


    let promises=[];
    files.map( file => {
        promises.push(fireBaseUpload(file));
        return {};
    });
        Promise.all(promises).then((result)=>{
            console.log(result,"resulst PRomises");
              return resolve(result);
        });
    }));
};
export  default fileUpload
