import React, { Component } from 'react'
import Axios from 'axios';


export default class AboutSession extends Component {
    constructor(props){
        super(props);

        this.state={
            aboutSessionContent:[],
            aboutSessionError:"",
            pageLoader:true
        }
    }
    componentDidMount(){
        const {match : {params}}  = this.props.passprops;
        Axios.get(`${global.config.requestUrl}user/getSessionBio?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({aboutSessionContent:res.data.data, aboutSessionError:res.data.errorCode, pageLoader:false})
            console.log(res.data.data)
        })
        .catch(err => {
            console.log(err);
        });
    }
    render() {
        return (
            <React.Fragment>
            {
                this.state.pageLoader
                ?
                (
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                )
                :
                null
            }
            {
                <React.Fragment>
                    {
                        this.state.aboutSessionContent.map(content => (
                            <p key={content.session_id}>{content.description}</p>
                        ))
                    }
                </React.Fragment>
            }
            </React.Fragment>
        )
    }
}
