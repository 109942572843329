import React, {Component} from 'react'
import TroopMessenger from 'troop-messenger-chat-sdk';
import tmChatServer from '../utility/server';
import troopServer from '../utility/troopServer';
import utility from "../utility/utility";
import {Link} from "react-router-dom";
import CattleCall from "Cattle-Call-SDK";

let troopMessenger;
let tmLoginUserDetails;
let cattleCallLoginUserDetails = {};
let cattleCallRemoteUserDetails = {};
let loginUserDetails;
let chatUserList;
let usersList;
let chatConversations = [];
let cattlecall;
let audioState = true;
let activeUserIndex = -1;
let incomingCallData;
let recentUsersWithRoles = {};
let recentUsersRoles = {};
let recentUsersList = [];
let isSearchActive = false;
let activeUserId = '';
let groupList = [];

function checkNotificationPermission() {
    if (!("Notification" in window)) {
        console.log("Notification");
        /*alert("This browser does not support desktop notification");*/
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
        });
    }
}

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

class ChatPage extends Component {

    state = {
        usersList: [],
        isLoading: true,
        videoCall: false,
        incomingCallAction: false,
        onAnswered: false,
        userDetails: {},
        chatUser: {},
        isUsersLoading: false,
        chatConversationsLoading: false,
        chatConversations: [],
        files: [],
        eventId: '',
        groupList: [],
        userInfo: {},
        activeGroup: false,
        activeRole: '-10',
        onlineUsers: [],
        groupInfo: {},
        chatUsersInfo: [],
    };

    constructor() {
        super();
        this.closeWindowPortal = this.closeWindowPortal.bind(this);
        this.state.eventId = localStorage.getItem('eventId')
    }
    getOnlineUsers=()=>{
        return new Promise((resolve) => {
            troopMessenger.getOnlineUsers()
                .on("online_users",  (data) =>{
                    console.log(data.online,"online USer ");
                       if(data.online) {
                           this.setState({'onlineUsers': data.online});
                              console.log(typeof  this.props.onlineUsers);
                           if(typeof  this.props.onlineUsers==='function') {

                               this.props.onlineUsers(data.online.length);
                             }
                       }else{
                           if(typeof  this.props.onlineUsers==='function') {
                               this.props.onlineUsers(0);
                           }
                       }
                    return resolve('');
                })
                .on("tm_error", function (errorMessage) {
                    console.log(errorMessage);
                    return resolve('');
                });

        });
    }

    componentWillUnmount() {
        if (document.querySelector('header')) {
            document.querySelector('header').style.display = 'block';
        }
    }

    closeWindowPortal() {
        this.setState({showWindowPortal: false})
    }

    displayNotification(message) {
        let messageId=localStorage.getItem('notificationMessage');
        let chatWindowOpen=(localStorage.getItem('isChatWindowActive')?localStorage.getItem('isChatWindowActive'):0);
        console.log(localStorage.getItem('isChatWindowActive'),"isChartWnddow",messageId,message['message_id']);
          console.log(chatWindowOpen);
        chatWindowOpen=(chatWindowOpen === 'true');

        console.log(chatWindowOpen,message['message_id'],localStorage.getItem('notificationMessage'));

        if( (chatWindowOpen) && ( (!localStorage.getItem('notificationMessage')) || messageId!==message['message_id'])) {
            localStorage.setItem('notificationMessage',message.message_id);
            let notification = new Notification(message.title, {
                body: message.message,
                icon: process.env.PUBLIC_URL + '/images/tmChat/logo.png',
                vibrate: [100],
                timeout: 300,
                data: {entity_id: message.sender_id},
            });
            notification.onclick = function (event) {
                event.preventDefault();
                window.focus();
                notification.close();
            };
        }
    }

    getUnreadCount = async () => {
       let count=await troopMessenger.getAllUnreadMessagesCount({
            conversation_reference_id: this.state.eventId
        }).on("tm_error", (errorMessage) => {
            console.log(errorMessage,"erroMessage");
        });
       console.log(count,"countttt");
    }
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                console.log(message, "mmessage");
            }).on("individual_unread_messages_count", function(data){
                console.log(data,"unreadd Message Count");
             })
            .on("all_unread_messages_count", (data)=>{
               if(data.unread_messages_count){
                     if(data.unread_messages_count!==0){
                         let unreadCount=localStorage.getItem('unreadCount');
                        // if(''+unreadCount!==''+data.unread_messages_count) {
                             this.setState({'unreadCount':data.unread_messages_count});
                                console.log(data.unread_messages_count,"unreadCount");
                             localStorage.setItem('unreadCount',''+data.unread_messages_count);
                             let notificationData = {
                                 'title': "You Have New Messages",
                                 'message': "You Have Unread Messages in chat",
                                 'sender_id': 0
                             };

                             this.displayNotification(notificationData);
                       //  }
                     }
               }
            })
            .on("connected", async () => {
                this.setState({troopMessengerConnection: troopMessenger});
                this.getOnlineUsers();
                this.getUnreadCount();
            })
            .on("disconnected", function () {
            }).on("message_sent", async (data) => {

        })
            .on("receive_message", async (data) => {


                      if ('' + this.state.eventId === '' + data.conversation_reference_id) {
                          if (data.is_group === 0) {
                              let userInfo = this.state.chatUsersInfo.filter((user) => {
                                  if (user.sender_uid === data.sender_uid) {
                                      return user;
                                  }
                              });
                              if (userInfo.length) {
                                  data.sender_name = userInfo[0]['name'];
                              } else {
                                  let userDetails = await this.getUserDetails(data.sender_uid);
                                  userDetails.sender_uid = data.sender_uid;
                                  data.sender_name = userDetails.name;
                              }
                              let notificationData = {
                                  'title': 'New Message From ' + data.sender_name,
                                  'message': data.message,
                                  'sender_id': data.sender_id,
                                  'message_id': data.message_id
                              };
                              if (data.message_type === 1) {
                                  notificationData['title'] = data.sender_name + ' Sent Attachment';
                                  notificationData['message'] = 'Attachment';
                              }
                              console.log("comes here to check the notification ", notificationData);
                              this.displayNotification(notificationData);
                          } else {
                              if ('' + data.message_type === troopMessenger.messageType().TEXT || '' + data.message_type === troopMessenger.messageType().ATTACHMENT) {

                                  let notificationData = {
                                      'title': 'New Message From ' + data.member_name,
                                      'message': data.message,
                                      'sender_id': data.sender_id,
                                      'message_id': data.message_id
                                  };
                                  if (data.message_type === 1) {
                                      notificationData['title'] = data.member_name + ' Sent Attachment';
                                      notificationData['message'] = 'Attachment';
                                  }
                                  this.displayNotification(notificationData);
                              }
                          }
                      }

            })
            .on("message_delivered", function (data) {
            })
            .on("message_read", function (data) {
            })
            .on("user_online", (data) => {
                let userIndex= this.state.onlineUsers.findIndex((user)=>{ return user===data.user_id});
                if(userIndex===-1) {
                    let onlineUsers=this.state.onlineUsers.concat(data.user_id);
                    this.setState({'onlineUsers': this.state.onlineUsers}, () => {
                        if(typeof  this.props.onlineUsers==='function') {

                            this.props.onlineUsers(this.state.onlineUsers.length);
                        }
                    });                }

            })
            .on("user_dnd", function (data) {
            })
            .on("user_offline", (data) => {
                let userIndex= this.state.onlineUsers.findIndex((user)=>{ return user===data.user_id});
                  if(userIndex!==-1) {
                      this.state.onlineUsers.splice(userIndex, 1);
                      this.setState({'onlineUsers': this.state.onlineUsers}, () => {
                             if(typeof  this.props.onlineUsers==='function') {
                                 this.props.onlineUsers(this.state.onlineUsers.length);
                             }
                      });
                  }
            })
            .on("group_created", (data) => {
            })
            .on("group_updated", function (data) {
            })
            .on("typing", function (data) {
            })
    };
    getUserDetails = async (uid) => {
        let userIdRole = utility.getUserIdAndRole(uid);
        const parameters = {
            'ids': userIdRole['userId'],
            'role': userIdRole['role'],
        };
        let usersList = await troopServer.getUsersListBasedOnRole(parameters);
        usersList = usersList.data;
        if (usersList && usersList.length) {
            return usersList[0];
        }
    }

    handleVisibilityChange = () => {
        if (document[hidden]) {
            this.setState({tabHide: true});
        } else {
            this.setState({tabHide: false});
        }
    }
    connectCattleCall = () => {
        console.log(cattleCallLoginUserDetails, "loginUSer");
        cattlecall = new CattleCall(cattleCallLoginUserDetails.user_id, "L0gJS27u4pHxwbh5L0gJS27u4pHxwbh5", "HGupYTsKbiJWZSDOqw3O01ajKAgNUTnf");
        cattlecall.onReady = ((data) => {
            console.log("onready", data);
            cattlecall.login().then(response => {
                console.log(response, "loginUser");
            }).catch(err => {
                console.log(err, "loginUser Errr");
            });
        });
        cattlecall.incommingCall = (async (data) => {
            console.log(data, "incomingCalll");
            incomingCallData = data;
            const getUserDetailsResponse = await tmChatServer.getUserDetail({'cattle_call_id': data.user_id});
            let videoCallUser = await this.getUserDetails(utility.generateUserUid({
                'userId': getUserDetailsResponse.user_id,
                'role': getUserDetailsResponse.roles
            }));
            let uid = utility.generateUserUid({
                "userId": getUserDetailsResponse.user_id,
                "role": getUserDetailsResponse.roles
            });
            this.setState({'incomingCallAction': true, 'caller_uid': uid, 'videoCallUser': videoCallUser});
            let callData = JSON.stringify({incommingCall: true, caller_uid: uid});
            callData = utility.encodeBase64(callData);
            console.log(callData, "videoCallData");
            this.setState({'videoCallData': callData});
            this.callerTone.loop = true;
            this.callerTone.pause();
            this.callerTone.load();
            this.callerTone.play();
            this.callerTone.muted=false;
        });
        cattlecall.onReject = ((data) => {
            this.setState({'videoCall': false,incommingCall: false});
            this.callerTone.pause();

        });
        cattlecall.onEnd = ((data) => {
            this.setState({'videoCall': false});

        });
        cattlecall.onVideoChange = ((data) => {
            console.log(data, "video changed");
        });
        cattlecall.onAudioChange = ((data) => {
            console.log(data, "audio changed");
        });
        cattlecall.onCallEnd = (data => {
            this.setState({'videoCall': false,incomingCallAction:false});
            this.callerTone.pause();

            console.log(data, "call end -101");
        });
        cattlecall.callAnswered = ((data) => {
            this.setState({"onAnswered": true,incomingCallAction:false});
            this.callerTone.pause();
            console.log(data, "data");
        });
        cattlecall.onCallAnswered = ((data) => {
                console.log(data,"onCallAnswered++++++++");
            this.setState({incomingCallAction:false});
            this.callerTone.pause();
            console.log(data, "data");
        })
        cattlecall.onerror = (data) => {
            console.log(data)
        }
    }
    makeCall=() =>{
        let videoCallWindow = utility.popupWindow(process.env.PUBLIC_URL + "/video-call?data=" + this.state.videoCallData, 'Video Call', 900, 500)
    }

    acceptCall=() =>{
        this.setState({'incomingCallAction': false});

        // this.takeAudioVideoDevices();
        this.callerTone.pause();
        this.makeCall();
    }

    rejectCall=()=> {
        this.setState({'incomingCallAction': false});
        try {
            cattlecall.reject(incomingCallData);
            this.callerTone.pause();

        } catch (e) {
            console.log(e);
        }

    }

    async componentDidMount() {
        //An array of assets
             console.log("coms here to checck didmoount");
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
        checkNotificationPermission();
        const userDetailsdata = await troopServer.getLoginUserDetails();
            console.log("comes here to userDetails",userDetailsdata);
        if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            loginUserDetails = userDetailsdata.data[0];
            this.setState({
                userDetails: loginUserDetails,
                isLoading: false
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
            loginUserDetails.uid = uid;
            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });

            cattleCallLoginUserDetails.user_id = getUserDetails.cattle_call_id;
            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                this.connectTMChat();
                 this.connectCattleCall();

            } catch (e) {
                console.log(e);
            }
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }
    }

    render() {
        const chatIconStyle = {
            position: "fixed",
            right: "20px",
            bottom: "20px",
            zIndex: "999",
            cursor: "pointer",
            '&:after': {
                position: 'absolute',
                content:'',
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                background: '#40a9ff'
            }
        };
       const audioCallWrapper={
           position: "fixed",
           left:"20px",
           bottom: "20px",
           width: "275px",
        height: "150px",
        backgroundColor: "#38a7c3",
           borderRadius:"10px",
           fontSize: "15px",
        color: "#fff",
           fontFamily:"Oswald, sans-serif"
       }
       const audiCallUserImageCss={
           float:"left",
        width: "70px",
        height: "70px",
        borderRadius: "50%",
        backgroundPosition:"center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        margin: "10px",
        backgroundColor: "#fff",
           display: "flex",
           color:'black',
        alignItems: "center",
        justifyContent: "center"
       };
        const AudioCallUserInfo={
            display: "inlineBlock",
        float: "left",
        width: "calc(100% - 90px)",
        paddingTop: "20px"

        }
        const audioCallUserNameCss={
            display: "block",
        color: "#fff",
        fontSize: "14px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%"
        }
         const callingActionsCss={
           bottom:"10px",
           left:"30%"
         }
        return (
            <React.Fragment>
                {this.state.incomingCallAction ?    <div style={audioCallWrapper}>
                    <div className='position-relative w-100 h-100'>
                    <div style={audiCallUserImageCss}>
                        {this.state.videoCallUser.profilePic!==""?utility.acronym(this.state.videoCallUser.name)
                        .toUpperCase()
                        .slice(0, 2): <img className='img-fluid rounded-pill' src={this.state.videoCallUser.profilePic} alt=''/>}
                    </div>
                    <div style={AudioCallUserInfo}><div style={audioCallUserNameCss}>{this.state.videoCallUser.name}</div>
                        <div>Incoming Call</div></div>
                    <div style={callingActionsCss} className='position-absolute'>
                        <span className='call-action accept-call' onClick={this.acceptCall}> <img className='img-fluid' src={process.env.PUBLIC_URL + "/images/tmChat/call_answer.png"} alt=''/></span>
                        <span className='call-action end-call' onClick={this.rejectCall}><img className='img-fluid' src={process.env.PUBLIC_URL + "/images/tmChat/endcall.png"} alt=''/></span></div>
                    </div>
                </div>:''}
                <div style={chatIconStyle}>
                    {this.state.unreadCount?<div  style={chatIconStyle["&:after"]}/>:''}
                    <Link to={process.env.PUBLIC_URL + "/common-powerup2021/chat"} target='_blank'>
                        {this.props.chatIconImage ? <img src={this.props.chatIconImage} alt=""/> :
                            <img src={process.env.PUBLIC_URL + "/images/chat-ico.png"} alt=""/>}
                    </Link>
                </div>
                <audio
                    className='d-none'
                    ref={(el) => {
                        this.callerTone = el;
                    }} src={process.env.PUBLIC_URL + "/images/tmChat/caller_tune.mp3"} muted>
                </audio>
            </React.Fragment>
        )
    }
}

export default ChatPage;
