import React, {Component} from "react";
import utility from "../utility/utility";
import ChatMessages from "../chat/ChatMessages";
import fileUpload from "../utility/firebase";
let isScroll=false;
class BoothChatSection extends Component {
      constructor(){
          super()
          this.state={'message':'',files:[],submited:false}
      }
    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    };

    updateInput=(e)=> {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }

        this.setState({message: e.target.value})
    }
    fileuploadHandler = async () => {
        const files = this.state.files;
        return await fileUpload(files);
    };

    handleSubmit=async ()=> {
        let filesPaths = [];

        this.setState({'submited': true});



        if (this.state.files.length) {
            filesPaths = await this.fileuploadHandler();
        }
        let sendMessage={
            'message': this.state.message,
            filesPaths: filesPaths,

        };
            sendMessage['receiver_id']= this.props.staffDetails.tm_user_id;
            sendMessage['receiver_uid']= this.props.staffDetails.uid;

        this.props.onSendMessage(sendMessage, this.props.staffDetails);
        this.setState({'message':''});
        this.setState({
            message: '',
            files: [],
            audio:'',
            recording: false,
            recordTime:'',
            showRecording:false
        });
        this.setState({'submited': false});
//Send state to the server code
    }
    uploadFile = (event) => {
        this.saveFiles(event.target.files);
        event.target.value = null;
    }
    removeFile = (e) => {
        let files = this.state.files;
        files.splice(e, 1);

        this.setState({'files': files})
    };
    saveFiles = (files) => {
        let stateFiles = this.state.files;
        stateFiles.push(files[0]);
        this.setState({'files': stateFiles})
    };
    componentDidMount() {
        this.scrollToBottom();
        this.setVisible = this.setVisible.bind(this);
    }
    setVisible = (value) => {
        this.setState({'visible': value});
    }
    scrollToBottom = () => {
        if (this.messagesEnd) {
            this.messagesEnd.scrollIntoView({block: "end", inline: "nearest"});
        }
    }
    componentDidUpdate(prevProps) {

        if(!isScroll){
            this.scrollToBottom();
            isScroll=false
        }
        if(this.props.staffDetails!==prevProps.staffDetails){
            isScroll=false;
        }
        if(this.messageFeed) {
            this.messageFeed.addEventListener("scroll", () => {
                if (this.isFeedAtTop() && this.props.chatConversations && this.props.chatConversations.length) {
                    let entityId='';
                    let isGroup=false;
                    if(this.props.activeGroup){
                        entityId=this.props.userInfo.receiver_id;
                        isGroup=true;
                    }else{
                        entityId=this.props.userInfo.tm_user_id;
                    }
                    this.props.loadChatConversations(entityId,isGroup);
                    isScroll=true;
                }
            });
        }
    }

    uploadFileDiv(files) {
        if (files.length === 0) {
            return ('');
        }
        return (<div className="attachments">
            {files.map((file, index) => {
                return (<div className="uploaded-files" key={index}>
                    <div className="bg-white p-3 border-radius12">
                        <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/document.svg"} className="img-fluid"/>
                        <span className="d-block text-black mt-2 upload-file-name">{file.name}</span>
                        <div className="file_size mt-3">
                            {utility.formatBytes(file.size, 1)}
                        </div>
                        <button className="cursor-pointer attachments-close"
                                onClick={this.removeFile.bind(this, index)}>
                            <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/close.svg"} className="img-fluid"/>
                        </button>
                    </div>
                </div>);
            })}

            <div className="add-more">
                <button className="w-100 h-100 m-0">
                    <input type='file' className='file-upload' onChange={this.uploadFile}/>
                    <img  alt='' src={process.env.PUBLIC_URL + "/images/tmChat/add.svg"}/>
                </button>
            </div>
        </div>)
    }

    render() {
        return (
            <React.Fragment>
                <div className="widget-rightsection">
                    <div className="widget-userslist justify-content-between">
                        <div className="d-flex align-items-center">
                            <div className={(this.props.staffDetails.profilePic?'':'widget-userimg-name')+" widget-userimg"}>
                                {this.props.staffDetails.profilePic?'':utility.acronym(this.props.staffDetails.name)}
                            </div>
                            <div className="opensans-regular username color-000">
                                {this.props.staffDetails.name}
                                { (this.props.staffDetails.is_online?<div className="user_message mt-n1">online</div>:'')}
                            </div>
                        </div>
                        <div className="video_call cursor-pointer" >
                            <button type='button' className='cursor-pointer' onClick={this.props.makeVideoCall}><img alt="" src={process.env.PUBLIC_URL +"/images/tmChat/chat_group_call.png"} className="img-fluid"/> Video Call</button>
                        </div>
                    </div>
                    <div className="widget-messagesection">
                        <ChatMessages userInfo={this.props.staffDetails} loginUserDetails={this.props.loginUserDetails}
                                    chatConversations={this.props.chatConversations}
                                    troopConnection={this.props.troopConnection}/>
                        <div className='mb-2'/>

                        <div style={{float: "left", clear: "both"}}
                             ref={(el) => {
                                 this.messagesEnd = el;
                             }}>
                        </div>

                    </div>
                    <div className="widget-chatsection">
                        {this.uploadFileDiv(this.state.files)}
                        <div className="widget-chatinputfile">
                            <img src={process.env.PUBLIC_URL + "/images/tmChat/attach.png"}/>
                                <input type="file" className='boothChatSendMessage' onChange={this.uploadFile}/>
                        </div>
                        <div className="widget-chatinputbox">
                            <input type="text" placeholder="Type your message" className="form-control" value={this.state.message}  onChange={this.updateInput} onKeyDown={this.handleKeyDown} disabled={this.state.submited }/>
                        </div>
                        {this.state.submited ? <span className='loader-spinner'><i
                        className='fa fa-spinner  fa-spin'></i></span> :
                        <div className="widget-chatsendbtn" onClick={this.handleSubmit}>
                        </div>}
                    </div>
                </div>
            </React.Fragment>)
    }
}
export  default  BoothChatSection