import React, {Component} from 'react'
import '../TmStyle.css'
import ChatHeader from "./ChatHeader";
import ChatUsers from "./ChatUsers";
import ChatSection from "./ChatSection";
import TroopMessenger from 'troop-messenger-chat-sdk';
import CattleCall from 'Cattle-Call-SDK';
import tmChat from '../utility/tmChat';
import tmChatServer from '../utility/server';
import troopServer from '../utility/troopServer';
import {Redirect} from 'react-router-dom'
import utility from "../utility/utility";

let troopMessenger;
let tmLoginUserDetails;
let cattleCallLoginUserDetails = {};
let cattleCallRemoteUserDetails = {};
let loginUserDetails;
let chatUserList;
let usersList;
let chatConversations=[];
let cattlecall;
let audioState = true;
let activeUserIndex = -1;
let incomingCallData;
let recentUsersWithRoles = {};
let recentUsersRoles = {};
let recentUsersList = [];
let isSearchActive = false;
let activeUserId = '';
let groupList=[];
function checkNotificationPermission() {
    if (!("Notification" in window)) {
        console.log("Notification");
        /*alert("This browser does not support desktop notification");*/
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
        });
    }
}

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}

class ChatPage extends Component {

    state = {
        usersList: [],
        isLoading: true,
        videoCall: false,
        incomingCallAction: false,
        onAnswered: false,
        userDetails: {},
        chatUser: {},
        isUsersLoading:true,

        chatConversationsLoading:false,
        chatConversations: [],
        files: [],
        eventId:'',
        groupList:[],
        userInfo:{},
        activeGroup:false,
        activeRole: '-10',
        activeUserSearch:false,
        onlineUsers:[],
        groupInfo:{}
    };
    onUnload = e => { // the method that will be used for both add and remove event
        const confirmationMessage = 'Some message';
        e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
        return confirmationMessage;
    }
    constructor() {
        super();
        this.acceptCall = this.acceptCall.bind(this);
        this.rejectCall = this.rejectCall.bind(this);
        this.getUserDetails = this.getUserDetails.bind(this);
        this.closeWindowPortal = this.closeWindowPortal.bind(this);
        this.state.eventId=localStorage.getItem('eventId')
    }
    componentWillUnmount(){
     //   window.removeEventListener("beforeunload", this.onUnload);

        if(document.querySelector('header')){
            document.querySelector('header').style.display='block';
        }
    }
    closeWindowPortal() {
        this.setState({showWindowPortal: false})
    }

    displayNotification(message) {
        if (this.state.tabHide) {
            let notification = new Notification(message.title, {
                body: message.message,
                icon: process.env.PUBLIC_URL + '/images/tmChat/logo.png',
                vibrate: [100],
                timeout: 300,
                data: {entity_id: message.sender_id}
            });
            notification.onclick = function (event) {
                event.preventDefault();
                window.focus();
                notification.close();
            };
        }
    }
    getOnlineUsers=()=>{
        return new Promise((resolve) => {
            troopMessenger.getOnlineUsers()
                .on("online_users",  (data) =>{
                       console.log(data.online,"online USer ");
                    this.setState({'onlineUsers':data.online})
                    return resolve('');
                })
                .on("tm_error", function (errorMessage) {
                    console.log(errorMessage);
                    return resolve('');
                });

        });
    }
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                console.log(message, "mmessage");
            })
            .on("connected", async () => {
                console.log("connected");
                this.setState({troopMessengerConnection: troopMessenger});
                 this.getOnlineUsers();
                await this.syncWithRecentUsers();
                if (recentUsersWithRoles['-10'] && recentUsersWithRoles['-10']['chatListWithUserDetails']) {
                    this.activeUser(recentUsersWithRoles['-10']['chatListWithUserDetails'][0], 0);
                }else{
                    console.log("comes Hereer",this.state.activeRole);
                    this.activeSearch(this.state.activeRole);
                }
            })
            .on("disconnected", function () {
            })
            .on("message_sent", async (data) => {
                if(''+this.state.eventId===''+data.conversation_reference_id) {
                    if (!chatConversations) {
                        chatConversations = [];
                    }
                    if (!data.is_group && data.is_group !== 1) {
                        chatConversations.push(data);
                        let userRoles = '';
                        if (recentUsersRoles[data.receiver_uid] !== undefined) {
                            userRoles = recentUsersRoles[data.receiver_uid].toString();
                        } else {
                            let getChatUser = await tmChatServer.getUsersDetails(data.receiver_uid.toString());
                            if (getChatUser && getChatUser.length) {
                                userRoles = getChatUser[0]['roles'].toString();
                            }
                        }
                        if (userRoles !== '') {
                            let userRole = userRoles.split(',');
                            for (let users of userRole) {
                                if (recentUsersWithRoles[users] === undefined || recentUsersWithRoles[users]['chatListWithUserDetails'] === undefined) {
                                    recentUsersWithRoles[users] = {'chatListWithUserDetails': [], 'users': []}
                                }
                                let userIndex = recentUsersWithRoles[users]['chatListWithUserDetails'].findIndex((user) => {
                                    data.userId = utility.getUserId(data.receiver_uid);
                                    if (parseInt(data.userId) === parseInt(user.userId)) {
                                        return user;
                                    }
                                });
                                let uid = utility.getUserId(data.receiver_uid);
                                if (userIndex === -1) {
                                    const parameters = {
                                        'ids': uid,
                                        'role': users,
                                        'eventId': this.state.eventId
                                    };
                                    let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                                    usersList = usersList.data;
                                    if (usersList && usersList.length) {
                                        usersList = Object.assign(usersList[0], data);
                                        recentUsersWithRoles[users]['chatListWithUserDetails'] = [usersList].concat(recentUsersWithRoles[users]['chatListWithUserDetails']);
                                    }
                                } else {
                                    let userDetails = recentUsersWithRoles[users]['chatListWithUserDetails'][userIndex];
                                    userDetails = Object.assign(userDetails, data);
                                    recentUsersWithRoles[users]['chatListWithUserDetails'].splice(userIndex, 1);
                                    recentUsersWithRoles[users]['chatListWithUserDetails'] = [userDetails].concat(recentUsersWithRoles[users]['chatListWithUserDetails']);
                                }
                            }
                            let updateUsersList = [];
                            if (recentUsersWithRoles[this.state.activeRole] && recentUsersWithRoles[this.state.activeRole]['chatListWithUserDetails']) {
                                updateUsersList = recentUsersWithRoles[this.state.activeRole]['chatListWithUserDetails'];
                            }
                            this.setState({
                                chatConversations: chatConversations,
                                usersList: updateUsersList,
                                activeUserIndex: 0
                            });
                        }
                    } else {
                        if (this.state.activeGroup) {
                            if (activeUserId === data.receiver_id) {
                                console.log(data, "daataaaa");
                                chatConversations = this.state.chatConversations.concat(data);
                                this.setState({'chatConversations': chatConversations});
                            }
                        }
                        if (this.state.groupList && this.state.groupList.length) {
                            let groupIndex = this.state.groupList.findIndex((group) => {
                                if ('' + group.receiver_id === '' + data.receiver_id) {
                                    return group;
                                }
                            });
                            if (groupIndex !== -1) {
                                let groupsList = [...this.state.groupList];
                                let groupDetails = Object.assign(groupsList[groupIndex], data);
                                groupsList.splice(groupIndex, 1);
                                this.setState({'groupList': [groupDetails].concat(groupsList), activeUserIndex: 0});
                            }
                        }
                    }
                }
            })
            .on("receive_message", async (data) => {
                if ('' + this.state.eventId === '' + data.conversation_reference_id) {
                    if (data.is_group === 0) {
                        let userId = utility.getUserId(data.sender_uid);

                        let userDetails;
                            let users = utility.getUserRole(data.sender_uid);
                                if (recentUsersWithRoles[users] === undefined || recentUsersWithRoles[users]['chatListWithUserDetails'] === undefined) {
                                    recentUsersWithRoles[users] = {'chatListWithUserDetails': [], 'users': []}
                                }
                                let userIndex = recentUsersWithRoles[users]['chatListWithUserDetails'].findIndex((user) => {
                                    data.userId = utility.getUserId(data.sender_uid);
                                    if (parseInt(data.userId) === parseInt(user.userId)) {
                                        return user;
                                    }
                                });
                                if (userIndex === -1) {
                                    let uid = utility.getUserId(data.sender_uid);

                                    const parameters = {
                                        'ids': uid,
                                        'role': users,
                                    };

                                    let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                                    usersList = usersList.data;
                                    if (usersList && usersList.length) {
                                        userDetails = usersList[0];
                                        usersList = Object.assign(usersList[0], data);
                                        recentUsersWithRoles[users]['chatListWithUserDetails'] = [usersList].concat(recentUsersWithRoles[users]['chatListWithUserDetails']);
                                    }
                                } else {
                                    userDetails = recentUsersWithRoles[users]['chatListWithUserDetails'][userIndex];
                                    userDetails = Object.assign(userDetails, data);
                                    recentUsersWithRoles[users]['chatListWithUserDetails'].splice(userIndex, 1);
                                    recentUsersWithRoles[users]['chatListWithUserDetails'] = [userDetails].concat(recentUsersWithRoles[users]['chatListWithUserDetails']);
                                }

                            if (recentUsersWithRoles[this.state.activeRole] !== undefined) {
                                this.setState({
                                    usersList: recentUsersWithRoles[this.state.activeRole]['chatListWithUserDetails']
                                });
                            }
                            data.userId = parseInt(userId);
                            activeUserId = parseInt(activeUserId);
                            if (data.userId === activeUserId) {
                                chatConversations.push(data);
                                this.setState({'chatConversations': chatConversations})
                            }
                            if (userDetails) {
                                let notificationData = {
                                    'title': 'New Message From ' + userDetails.name,
                                    'message': data.message,
                                    'sender_id': data.sender_id
                                };
                                if (data.message_type === 1) {
                                    notificationData['title'] = userDetails.name + ' Sent Attachment';
                                    notificationData['message'] = 'Attachment';
                                }
                                this.displayNotification(notificationData);
                            }

                    } else if(data.is_group===1){

                            if (this.state.activeGroup) {
                                if (activeUserId === data.receiver_id) {
                                      let chatData=await this.assignUserInfo(data);
                                    chatConversations = this.state.chatConversations.concat(chatData);
                                    this.setState({'chatConversations': chatConversations});
                                }
                            }

                            if (this.state.groupList && this.state.groupList.length) {
                                let groupIndex = this.state.groupList.findIndex((group) => {
                                    if ('' + group.receiver_id === '' + data.receiver_id) {
                                        return group;
                                    }
                                });
                                if (groupIndex !== -1) {
                                    let groupsList = [...this.state.groupList];
                                    let groupDetails = Object.assign(groupsList[groupIndex], data);
                                    groupsList.splice(groupIndex, 1);
                                    this.state.groupList = [groupDetails].concat(groupsList);
                                    this.setState({'groupList': this.state.groupList, activeUserIndex: 0});
                                }
                                let notificationData = {
                                    'title': 'New Message From ' + data.member_name,
                                    'message': (data.message ? data.message : ''),
                                    'sender_id': data.sender_id
                                }
                                if (data.message_type === 1) {
                                    notificationData['title'] = data.member_name + ' Sent Attachment';
                                    notificationData['message'] = 'Attachment';
                                }
                                if (data.message_type === troopMessenger.messageType().TEXT || data.message_type === troopMessenger.messageType().ATTACHMENT) {
                                    this.displayNotification(notificationData);
                                }
                            }
                        }
                    }
            })
            .on("message_delivered", function (data) {
            })
            .on("message_read", function (data) {
            })
            .on("user_online",  (data)=> {
                   this.state.onlineUsers.push(data.user_id)
                this.setState({'onlineUsers':this.state.onlineUsers});
                    if(this.state.chatUser) {
                        if ('' + this.state.chatUser.tm_user_id === '' + data.user_id) {
                            this.state.chatUser.is_online = true;
                            this.setState({'chatUser': this.state.chatUser});
                        }
                    }
            })
            .on("user_dnd", function (data) {
            })
            .on("user_offline",  (data) =>{
                console.log(data,"userOffline");
                     let userIndex= this.state.onlineUsers.findIndex((user)=>{ return user===data.user_id});
                     this.state.onlineUsers.splice(userIndex,1);
                     this.setState({'onlineUsers':this.state.onlineUsers});
                if(this.state.chatUser) {
                    if ('' + this.state.chatUser.tm_user_id === '' + data.user_id) {
                        this.state.chatUser.is_online = false;
                        this.setState({'chatUser': this.state.chatUser});
                    }
                }
            })
            .on("group_created",  (data) =>{
                   this.syncWithRecentUsers();
            })
            .on("group_updated", function (data) {
            })
            .on("typing", function (data) {
            })
    };
    connectCattleCall = () => {
        console.log(cattleCallLoginUserDetails, "loginUSer");
        cattlecall = new CattleCall(cattleCallLoginUserDetails.user_id, "L0gJS27u4pHxwbh5L0gJS27u4pHxwbh5", "HGupYTsKbiJWZSDOqw3O01ajKAgNUTnf");
        cattlecall.onReady = ((data) => {
            console.log("onready", data);
            cattlecall.login().then(response => {
                console.log(response, "loginUser");
            }).catch(err => {
                console.log(err, "loginUser Errr");
            });
        });
        cattlecall.incommingCall = (async (data) => {
            console.log(data, "incomingCalll");
            incomingCallData = data;
            /* this.setState({'videoCall':true,incommingCall:true});
             data.localVideoElement="#localvideo";
             data.remoteVideoElement="#remoteVideo";
             cattlecall.answerCall(data);*/
            const getUserDetailsResponse = await tmChatServer.getUserDetail({'cattle_call_id': data.user_id});
            let videoCallUser = await this.getUserDetails(utility.generateUserUid({
                'userId': getUserDetailsResponse.user_id,
                'role': getUserDetailsResponse.roles
            }));
            let uid = utility.generateUserUid({
                "userId": getUserDetailsResponse.user_id,
                "role": getUserDetailsResponse.roles
            });
            this.setState({'incomingCallAction': true, 'caller_uid': uid, 'videoCallUser': videoCallUser});
            let callData = JSON.stringify({incommingCall: true, caller_uid: uid});
            callData = utility.encodeBase64(callData);
            console.log(callData, "videoCallData");
            this.setState({'videoCallData': callData});
            this.callerTone.loop = true;
            this.callerTone.pause();
            this.callerTone.load();
            this.callerTone.play();
            this.callerTone.muted=false;

        });
        cattlecall.onReject = ((data) => {
            this.setState({'videoCall': false,incomingCallAction:false});
            this.callerTone.pause();

        });
        cattlecall.onEnd = ((data) => {
            this.setState({'videoCall': false,incomingCallAction:false});
            this.callerTone.pause();

        });
        cattlecall.onVideoChange = ((data) => {
            console.log(data, "video changed");
        });
        cattlecall.onAudioChange = ((data) => {
            console.log(data, "audio changed");
        });
        cattlecall.onCallEnd = (data => {
            this.setState({'videoCall': false});

            console.log(data, "call end -101");
        });
        cattlecall.callAnswered = ((data) => {
            this.setState({"onAnswered": true,incomingCallAction:false});
            this.callerTone.pause();
            console.log(data, "data");
        })
        cattlecall.onCallAnswered = ((data) => {
            this.setState({incomingCallAction:false});
            this.callerTone.pause();
            console.log(data, "data");
        })
        cattlecall.onerror = (data) => {
            console.log(data)
        }
    }
    sendMessage = (message, userDetails) => {
        message.event_id =this.state.eventId;

        message.sender_uid = loginUserDetails.uid;
          if(message.is_group!==1) {
              recentUsersRoles[message['receiver_uid']] = userDetails.role;
              console.log(recentUsersRoles, message['receiver_uid']);

          }
        if (message.filesPaths && message.filesPaths.length) {
            for (const filepath of message.filesPaths) {
                message.attachment = filepath;
                tmChat.sendAttachment(troopMessenger, message);
            }
        } else {
            tmChat.sendTextMessage(troopMessenger, message);
        }
    };
    loadUsers = async (role,offset) => {

        let parameters = {"role": role, "limit": 20,'offset':offset};
        let getUsersList = await troopServer.getUsersList(parameters)
        if (getUsersList.errorCode === 4) {
            this.setState({"redirect": true});
            return;
        }
        usersList = getUsersList.data;
        return usersList;

    };
    groupInfo=async (groupId)=>{
        let details= await troopMessenger.group().details({
            group_id: groupId
        }).then((response)=>{
            if(response.success){
                let groupInfo=response.data;
                groupInfo.group_id=groupId;
                       //  console.log(response.data,"ressponseeeeeeee");
                this.setState({'groupInfo':groupInfo});
                this.getGroupMemberRole(groupInfo.members);
            }
        }).catch((e)=>{
            console.log(e);
        });
    }
    getGroupMemberRole=(members)=>{
        let findIndex=members.findIndex((member)=>{
            if(''+member.member_id === ''+this.state.userDetails.tm_user_id){
                return  member;
            }
        });
        if(findIndex!==-1){
            this.state.groupInfo.member_role=members[findIndex]['member_role'];
                 console.log(this.state.groupInfo.member_role,"groupRoleee");
                this.setState({'groupInfo':this.state.groupInfo});
        }
    }
    searchUsersList = async (role, keyword,offset) => {

        let parameters = {"eventId": this.state.eventId, "role": role, "offset": offset, "limit": 20, 'keyword': keyword};

        let userListdata = await troopServer.getSearchUsersList(parameters);
        if (userListdata.errorCode === 4) {
            this.setState({"redirect": true});
            return;
        }
        if (userListdata.data) {
            usersList = userListdata.data;
            return usersList;
        } else {
            return [];
        }
    };
    syncWithRecentUsers = async () => {
        return new Promise(async (resolve) => {
            recentUsersList = await tmChat.chatList(troopMessenger, this.state.eventId);

            recentUsersList = recentUsersList.recent_list;
            chatUserList = [];
            if (recentUsersList && recentUsersList.length) {
                recentUsersList.map((chatUser, index) => {
                        if(chatUser.entity_type===troopMessenger.entity().USER) {
                            let userIdRole = utility.getUserIdAndRole(chatUser.uid);
                            let userId = userIdRole['userId'];
                            let role = userIdRole['role'];
                            if (recentUsersWithRoles[role] === undefined) {
                                recentUsersWithRoles[role] = {'chatList': [], "users": []};
                            }
                            chatUser.userId = userId;
                            recentUsersWithRoles[role]['chatList'].push(chatUser);
                            recentUsersWithRoles[role]['users'].push(userId);
                        }else if(chatUser.entity_type===troopMessenger.entity().GROUP){
                              this.state.groupList.push(chatUser);
                              this.setState({'groupList':this.state.groupList});
                        }
                });
                for (let users in recentUsersWithRoles) {
                    if (recentUsersWithRoles[users]['users'] && users) {
                        const parameters = {
                            'ids': recentUsersWithRoles[users]['users'].toString(),
                            'role': users,
                            'eventId': this.state.eventId
                        };

                        let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                        usersList = usersList.data;
                        if (usersList && usersList.length) {
                            recentUsersWithRoles[users]['chatList'].map((chatUser, index) => {
                                if (chatUser.id) {
                                    let findUserIndex = usersList.findIndex((user) => {
                                        return (parseInt(user.userId) === parseInt(chatUser.userId));
                                    });
                                    if (findUserIndex !== -1) {
                                        let recentuserDetails = usersList[findUserIndex];
                                        tmLoginUserDetails.tm_user_id = parseInt(tmLoginUserDetails.tm_user_id);
                                        if (chatUser.receiver_id !== tmLoginUserDetails.tm_user_id) {
                                            recentuserDetails.tm_user_id = chatUser.receiver_id;
                                        } else {
                                            recentuserDetails.tm_user_id = parseInt(chatUser.sender_id);
                                        }
                                        chatUser.is_online=(this.state.onlineUsers.findIndex((user)=>{ return user===recentuserDetails.tm_user_id}) !== -1);
                                        recentuserDetails = Object.assign(recentuserDetails, chatUser);
                                        if (recentUsersWithRoles[users]['chatListWithUserDetails'] === undefined) {
                                            recentUsersWithRoles[users]['chatListWithUserDetails'] = [];
                                        }
                                        let userInfo = Object.assign({}, recentuserDetails);
                                        recentUsersWithRoles[users]['chatListWithUserDetails'].push(userInfo);
                                        this.setState({'isUsersLoading':false});
                                        if (this.state.activeRole === users) {
                                            let chatUserList = [];

                                            if (recentUsersWithRoles[this.state.activeRole] && recentUsersWithRoles[this.state.activeRole]['chatListWithUserDetails']) {

                                                chatUserList = recentUsersWithRoles[this.state.activeRole]['chatListWithUserDetails'];

                                                this.setState({usersList: chatUserList});
                                            } else {
                                                this.setState({usersList: chatUserList});

                                            }
                                        }
                                        usersList.splice(findUserIndex, 1);
                                    }
                                }
                            });
                        }
                    }
                }

            } else {
                chatUserList = [];
                   console.log("comesi false");
                this.setState({'isUsersLoading':false});
            }

            return resolve(true);

        });
    };
    loadMoreUsers = async () => {
 let users;
 this.setState({'isUsersLoading':true});
        if(this.state.searchText){
            users= await this.searchUsersList(this.state.activeRole,this.state.searchText,this.state.usersList.length);

        }else{
            users= await this.loadUsers(this.state.activeRole,this.state.usersList.length);
           }
        if(!users){
            users=[];
        }
         this.setState({isUsersLoading:false,'usersList':this.state.usersList.concat(users)});
       // this.syncWithRecentUsers();
    };
    makeVideoCall = async () => {
        let requestOptions = {"user_id": this.state.chatUser.userId};
        console.log(this.state.chatUser, "chatUser");
        let getRemoteUserDetails = await tmChatServer.updateUser({
            'userId': this.state.chatUser.userId,
            'name': this.state.chatUser.name,
            'role': this.state.chatUser.role,
            'uid': this.state.chatUser.uid,
            tm_user_id: this.state.chatUser.tm_user_id
        });
        cattleCallRemoteUserDetails.user_id = getRemoteUserDetails.cattle_call_id;
        //this.setState();
        let data = JSON.stringify({
            caller_uid: utility.generateUserUid({
                'userId': this.state.chatUser.userId,
                'role': this.state.chatUser.role
            }), incommingCall: false, 'call_user_id': cattleCallRemoteUserDetails.user_id
        });
        data = utility.encodeBase64(data);
        this.setState({'videoCallData': data});
        //cattlecall.call('' + cattleCallRemoteUserDetails.user_id, "#localVideo", "#remoteVideo");
        // this.takeAudioVideoDevices();
        this.makeCall();
    };

    takeAudioVideoDevices() {
        cattlecall.getDevices((err, response) => {
            if ((!response.audio) || response.audio.length === 0 || response.video.length === 0) {
                if (response.audio.length === 0 && response.video.length === 0) {
                    alert("Please Connect Audio And Video Device To Make Video Call");
                    return false
                }
                if (response.audio.length === 0) {
                    alert("Please Connect Audio Device To Make Video Call");
                    return false
                }
                if (response.video.length === 0) {
                    alert("Please Connect Video Device To Make Video Call");
                    return false
                }
            }
            this.setState({'audioDevices': response.audio, 'videoDevices': response.video});
            if (response.audio.length > 1) {
                this.setState({'openAudioDeviceModal': true});
            }


            if (response.audio.length === 1 && response.video.length === 1) {
                localStorage.setItem('c2VsZWN0ZWRBdWRvRGV2aWNlSWQ', this.state.audioDevices[0].deviceId)
                localStorage.setItem('c2VsZWN0ZWRWaWRlb0RldmljZUlk', this.state.videoDevices[0].deviceId)
                this.makeCall();
            }
        })

    }

    makeCall() {
        let videoCallWindow = utility.popupWindow(process.env.PUBLIC_URL + "/video-call?data=" + this.state.videoCallData, 'Video Call', 900, 500)
    }

    acceptCall() {
        this.setState({'incomingCallAction': false});

        // this.takeAudioVideoDevices();
        this.callerTone.pause();
        this.makeCall();
    }

    rejectCall() {
        this.setState({'incomingCallAction': false});
        try {
            cattlecall.reject(incomingCallData);
            this.callerTone.pause();

        } catch (e) {
            console.log(e);
        }

    }

    endCall = () => {
        this.setState({videoCall: false});
        cattlecall.endCall();
    }
    toggleAudio = () => {
        cattlecall.toggleAudio();
        audioState = !audioState;
        this.setState({'toggleAudioValue': false}, () => {
            console.log(this.state, "stateValue");
        });
    };
    searchUsers = async (value) => {
        let users = [];
        value = value.trim();
        this.setState({'searchText':value});
           console.log(this.state.activeRole,"sdsdsdsdsd");
        if (value != '') {
            users = await this.searchUsersList(this.state.activeRole, value,0);
        } else {
            users = await this.loadUsers(this.state.activeRole,0);
        }
        this.setState({'usersList': users,'activeGroup':false})
    };
    activeUser = async (userDetails, index) => {
        if (userDetails) {
            this.setState({'activeGroup':false});

            activeUserId = userDetails.userId;
            activeUserIndex = index;

            if (!userDetails.tm_user_id) {
                let registerUser = await tmChatServer.updateUser(userDetails, troopMessenger);
                userDetails.tm_user_id = registerUser.tm_user_id;
                userDetails.uid = registerUser.uid;
            }

            userDetails.is_online=(this.state.onlineUsers.findIndex((user)=>{ return user===userDetails.tm_user_id}) !== -1);
            this.setState({'chatUser': userDetails, activeUserIndex: index, chatConversations: []});
            chatConversations = await tmChat.getConversation(troopMessenger, {
                'tm_user_id': userDetails.tm_user_id,
                authorization_token: tmLoginUserDetails.authorization_token,
                'conversation_reference_id': this.state.eventId
            });
            this.state.usersList[index].unread_messages=0;
            this.setState({'usersList':this.state.usersList});
            this.setState({chatConversations: chatConversations});
        }
    };
    activeGroup = async (userDetails, index) => {
        if (userDetails) {
            this.groupInfo(userDetails.receiver_id);
            activeUserId = userDetails.receiver_id;
            activeUserIndex = index;
            this.setState({'activeGroup':true});
            this.setState({'chatUser': userDetails, activeUserIndex: index, chatConversations: []});
            chatConversations = await tmChat.getConversation(troopMessenger, {
                'group_id': userDetails.receiver_id,
                'is_group':1,

                authorization_token: tmLoginUserDetails.authorization_token,
                'conversation_reference_id': this.state.eventId
            });
               if(chatConversations.length){
                   let promises=[];
                       console.log(chatConversations[0],"chatConversations");
                   for(const chat of chatConversations){
                       promises.push(this.assignUserInfo(chat));
                   };
                   Promise.all(promises).then((response)=>{
                       this.setState({chatConversations: response});
                   });
               }
        }
    };
    assignUserInfo=async (chat)=>{
        return new Promise(async (resolve)=>{
                  console.log(this.state.userInfo[chat.sender_uid],chat.sender_uid);
               if(chat.sender_uid && chat.sender_uid!==loginUserDetails.uid) {
                   if (!this.state.userInfo[chat.sender_uid]) {
                       let uid = utility.getUserIdAndRole(chat.sender_uid);

                       const parameters = {
                           'ids': uid.userId,
                           'role': uid.role,
                       };
                       let usersList = await troopServer.getUsersListBasedOnRole(parameters);
                       console.log(usersList, "userList");
                       this.state.userInfo[chat.sender_uid] = usersList.data[0];
                       chat = Object.assign(chat, usersList.data[0]);


                   } else {
                       chat = Object.assign(chat, this.state.userInfo[chat.sender_uid]);
                   }
               }
                 console.log(chat,"chat Info");
                 return resolve(chat);
        });
    }
     loadChatConversations=async (tmUserId,isGroup)=>{
         if(!this.state.chatConversationsLoading) {
             this.setState({'chatConversationsLoading':true});
             let parameters = {
                 authorization_token: tmLoginUserDetails.authorization_token,
                 'conversation_reference_id': this.state.eventId,
                 is_group:0
             };
             if(isGroup){
                 parameters['group_id']= tmUserId;
                 parameters['is_group']= 1;
             }else{
                 parameters['tm_user_id']= tmUserId;
             }
             if (this.state.chatConversations && this.state.chatConversations.length) {
                 parameters['last_message_id'] = this.state.chatConversations[0].message_id;
             }
             chatConversations = await tmChat.getConversation(troopMessenger, parameters);
             if(!chatConversations){
                 chatConversations=[];
             }
             chatConversations = chatConversations.concat(this.state.chatConversations);
             this.setState({chatConversations: chatConversations,chatConversationsLoading:false});
         }
     }
    async getUserDetails(uid) {
        let userIdRole = utility.getUserIdAndRole(uid);
        const parameters = {
            'ids': userIdRole['userId'],
            'role': userIdRole['role'],
        };
        let usersList = await troopServer.getUsersListBasedOnRole(parameters);
        usersList = usersList.data;
        if (usersList && usersList.length) {
            return usersList[0];
        }
    }

    activeUserRole = async (role) => {
             this.setState({'isUsersLoading':true,'usersList':[]});
           if(role==='-4') {

               this.setState({'groupList':this.state.groupList,'activeUserSearch':false});
           } else{
               if (isSearchActive) {
                   isSearchActive=false;
                   if (recentUsersWithRoles[role] && recentUsersWithRoles[role]['chatListWithUserDetails']) {
                       this.deactiveSearch(role);
                   }else{
                       this.activeSearch(role);
                   }
               } else {
                   if (recentUsersWithRoles[role] && recentUsersWithRoles[role]['chatListWithUserDetails']) {
                   let usersChat = recentUsersWithRoles[role]['chatListWithUserDetails'];
                       this.setState({'usersList': usersChat});

                   }else{
                       this.activeSearch(role);
                   }
               }
           }
        this.setState({'activeRole': '' + role,'isUsersLoading':false});
    };
    activeSearch = async (role) => {
        isSearchActive = true;
        this.setState({'activeUserSearch':true,'isUsersLoading':true});
        this.setState({'activeRole': role});
        const users = await this.loadUsers(role,0);
        if(this.state.activeRole===role) {
            this.setState({'usersList': users, 'isUsersLoading': false});
        }
    };
    deactiveSearch = async (role) => {
        isSearchActive = false;
        let usersChat = [];
        if (recentUsersWithRoles[role] && recentUsersWithRoles[role]['chatListWithUserDetails']) {
            usersChat = recentUsersWithRoles[role]['chatListWithUserDetails'];
        }
        this.setState({'activeRole': role,'activeUserSearch':false});
        this.setState({'usersList': usersChat});

    };
    handleVisibilityChange = () => {
        if (document[hidden]) {
            this.setState({tabHide: true});
        } else {
            this.setState({tabHide: false});
        }
    }
    makeAudioDevice = (e) => {
        this.setState({'selectedAudio': e.target.value})
    }
    closeAudioModal = () => {
        this.setState({"openAudioDeviceModal": false});
    }
    selectAudioModal = () => {
        if (!this.state.selectedAudio) {
            alert("Please Select Audio Device");
            return false;
        }
        this.setState({"openAudioDeviceModal": false});
        localStorage.setItem('c2VsZWN0ZWRBdWRvRGV2aWNlSWQ', this.state.selectedAudio)
        localStorage.setItem('c2VsZWN0ZWRWaWRlb0RldmljZUlk', this.state.videoDevices[0].deviceId)
        this.makeCall();
    }

    async componentDidMount() {
        //An array of assets
       // window.addEventListener("beforeunload", this.onUnload);
        window.addEventListener('storage', function(e) {
            //document.querySelector('.my-storage').textContent = JSON.stringify(e.storageArea);
            if(e.key==='currentRole' && e.oldValue!==e.newValue){
                window.location.reload();
            }
        });
        window.onbeforeunload = function (e) {
            window.onunload = function () {
                window.localStorage.isChatWindowActive = "false";
            }
            return undefined;
        };

        window.onload = function () {
            window.localStorage.isChatWindowActive = "true";
        };
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
        if(document.querySelector('header')){
            document.querySelector('header').style.display='none';

        }        checkNotificationPermission();
        const userDetailsdata = await troopServer.getLoginUserDetails()

        if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            loginUserDetails = userDetailsdata.data[0];
            this.setState({
                userDetails: loginUserDetails,
                isLoading: false
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({'userId': loginUserDetails.userId, 'role': loginUserDetails.role});
            loginUserDetails.uid = uid;
            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': loginUserDetails.userId,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });

            cattleCallLoginUserDetails.user_id = getUserDetails.cattle_call_id;
            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                this.connectTMChat();
                this.connectCattleCall();

            } catch (e) {
                console.log(e);
            }
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }
    }
    createGroup=async (groupInfo)=>{
return new Promise(async (resolve) => {
    await troopMessenger.group().create({
        group_name: groupInfo.title,
        group_description: groupInfo.description,
        members: groupInfo.members,
        group_avatar:groupInfo.group_image,
        conversation_reference_id:this.state.eventId
    }).then((response)=>{
        return resolve(response);
    }).catch(function (e) {
        console.log(e,"error At Join Meett6ing");
        return  resolve({'succcess':false,'message':"Something Went Wrong"});

    });
})

    }
    render() {
        if (this.state.redirect) {
            return (
                <Redirect to={`${process.env.PUBLIC_URL}/login`}/>
            )
        }
        if (this.state.isLoading) return <div>Loading...</div>;
        //if(this.state.videoCall) return  <VideoPage incomingCall={this.state.incommingCall} toggleAudio={this.toggleAudio} toggleAudioValue={this.state.toggleAudioValue} endCall={this.endCall} onAnswered={this.state.onAnswered} index={0}/>;
        return (
            <React.Fragment>
                <div className='tmChat'>
                    <ChatHeader userId={this.state.chatUser.userId} activeUrl={1}/>
                    {this.state.incomingCallAction ? <div className='d-flex justify-content-between calling-bg'>
                        <div className='d-flex'>
                            user is calling you
                            <div className="user_img d-flex justify-content-center">
                                <span className='text-white'
                                      data-letters="ab">{utility.acronym(this.state.videoCallUser.name)
                                    .toUpperCase()
                                    .slice(0, 2)}</span>
                            </div>
                            <div
                                className='d-flex text-white video-call-user-name ml-2'>{this.state.videoCallUser.name}  </div>
                        </div>
                        <div className='d-flex'>
                            <span className='call-action accept-call' onClick={this.acceptCall}> <img className='img-fluid' src={process.env.PUBLIC_URL + "/images/tmChat/call_answer.png"} alt=''/></span>
                            <span className='call-action end-call' onClick={this.rejectCall}><img className='img-fluid' src={process.env.PUBLIC_URL + "/images/tmChat/endcall.png"} alt=''/></span></div>
                    </div>
                    : ''}
                {this.state.openAudioDeviceModal ?
                    <div>
                        <div className="modal-backdrop show"/>
                        <div className="modal d-block" ref={modal => this.modal = modal} id="exampleModal" tabIndex="-1"
                             role="dialog" aria-hidden="true">
                            <div className="modal-dialog" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">Select Audio Devices
                                        </h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                                                onClick={this.closeAudioModal}>
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className='row'>
                                            {this.state.audioDevices.map((device, index) => {
                                                return (<div className='col-12 d-flex flex-row mb-2' key={index}>
                                                    <div className='mr-2'><input type='radio' name='audioDevices'
                                                                                 value={device.deviceId}
                                                                                 onChange={(e) => this.makeAudioDevice(e)}/>
                                                    </div>
                                                    <div>{device.label}</div>
                                                </div>);
                                            })}
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal"
                                                onClick={this.closeAudioModal}>Close
                                        </button>
                                        <button type="button" className="btn btn-primary"
                                                onClick={this.selectAudioModal}>Ok
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
                <div className="widget">
                    <div className="container-fluid h-100">
                        <div className="row h-100">
                            <ChatUsers userDetails={this.state.userDetails}
                                       usersList={this.state.usersList}
                                       activateUser={this.activeUser}
                                       activateGroup={this.activeGroup}
                                       activeUserIndex={this.state.activeUserIndex}
                                       loadMoreUsers={this.loadMoreUsers}
                                       searchUsers={this.searchUsers}
                                       activeRole={this.state.activeRole}
                                       activeUserRole={this.activeUserRole}
                                       activeSearch={this.activeSearch}
                                       isUsersLoading={this.state.isUsersLoading}
                                       activeUserSearch={this.state.activeUserSearch}
                                       createGroup={this.createGroup}
                                       troopConnection={(this.state.troopMessengerConnection ? this.state.troopMessengerConnection : {})}
                                       deactiveSearch={this.deactiveSearch}
                                       groupList={this.state.groupList}
                                       loginUserDetails={this.state.userDetails}
                            />
                            <ChatSection userInfo={this.state.chatUser}
                                         loginUserDetails={this.state.userDetails}
                                         onSendMessage={this.sendMessage}
                                         activeRole={this.state.activeRole}
                                         activeGroup={this.state.activeGroup}
                                         loadChatConversations={this.loadChatConversations}
                                         chatConversations={this.state.chatConversations}
                                         troopConnection={(this.state.troopMessengerConnection ? this.state.troopMessengerConnection : {})}
                                         makeVideoCall={this.makeVideoCall}
                                         groupInfo={this.state.groupInfo}
                            />
                        </div>
                    </div>
                </div>
                {/* {this.state.videoCall?  <MyWindowPortal closeWindowPortal={this.closeWindowPortal}> <VideoPage incomingCall={this.state.incommingCall} toggleAudio={this.toggleAudio} toggleAudioValue={this.state.toggleAudioValue} endCall={this.endCall} onAnswered={this.state.onAnswered} index={0}/>;</MyWindowPortal>:''}*/}
                <audio
                    className='d-none'
                    ref={(el) => {
                        this.callerTone = el;
                    }}
                    src={process.env.PUBLIC_URL + "/images/tmChat/caller_tune.mp3"}
                    muted>
                </audio>
                </div>
            </React.Fragment>
        )
    }
}

export default ChatPage;
