import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from './PrivateRoute'

import Homepage from './Homepage'
import Header from './Components/paos/Header';
import Login from './Components/paos/Login';
import SingleSignOn from './Components/paos/SingleSignOn';
import LoginSplash from './Components/paos/LoginSplash';
import ForgotPassword from './Components/paos/ForgotPassword';
import ResetPassword from './Components/paos/ResetPassword';
import ExhibitorDetails from './Components/paos/Exhibitor/ExhibitorDetails';
import ExhibitorBooth from './Components/paos/Exhibitor/ExhibitorBooth';

import Dashboard from './Components/paos/Exhibitor/Dashboard';
import Lobby from './Components/paos/Attendee/Lobby';
import ExhibitHall from './Components/paos/Attendee/ExhibitHall';
import NetworkingLounge from './Components/paos/Attendee/NetworkingLounge';
import ConferenceCenter from './Components/paos/Attendee/ConferenceCenter';
import ConferenceCenterPage from './Components/paos/Attendee/ConferenceCenterPage';
import TechnicalSessions from './Components/paos/Attendee/TechnicalSessions';
import ExhibitList from './Components/paos/Attendee/ExhibitList';

import HelpDesk from './Components/paos/Attendee/HelpDesk';
import GoldenTicket from './Components/paos/Attendee/GoldenTicket';
import AttendeeExhibitorBooth from './Components/paos/Attendee/AttendeeExhibitorBooth';
import NotFound from './Components/paos/NotFound';

import DashboardSpeaker from './Components/paos/Speaker/Dashboard';
import ProfileDetails from './Components/paos/Speaker/ProfileDetails';
import SessionAttendees from './Components/paos/Speaker/SessionAtendees';
import UserProfile from './Components/paos/UserProfile';

import ChatPage from "./TmChat/paos/chat/ChatPage";
import VideoCall from "./TmChat/paos/video/VideoCall";
import ConferenceMeeting from "./TmChat/paos/videoConference/conferenceMeeting";
import Meetings from "./TmChat/paos/videoConference/meetings";
import WorkProgress from './Components/paos/WorkProgress';
import LogoutSuccess from './Components/paos/LogoutSuccess';
import Disabled from './Components/Disabled';


/* import test from './Components/paos/Exhibitor/test'; */

class Paos extends Component {
constructor(){
  super();

  this.state={
    homePage:""
  }
}
componentDidMount(){
  //console.log(window.location.href)
  
}

  render() {

    const domain = window.location.href
    if(domain === ("http://localhost:3000/" || "https://affectionate-bohr-4d306b.netlify.app/")){
      return(
        <div className="App">
            <Router>
              <Route path="/" exact component={Homepage} />
              {/* <Route path="/home" exact component={Homepage} /> */}
            </Router>
        </div>
      )
    }
    else {
      return (
      <Router>
          <div className="App">
            
            <Header/>
            <Switch>
                
                <Route path="/paos/login/:loginId" exact component={Login} />
                <Route path="/paos/logoutSuccess/:eventId/:emailId/:tokenpass" exact component={LogoutSuccess} />
                <Route path="/paos/single-signon/:eventId/:emailId/:tokenpass" exact component={SingleSignOn} />
                <PrivateRoute path="/paos/loginSplash" exact component={LoginSplash} />
                <Route path="/paos/forgotPassword" component={ForgotPassword} />
                <Route path="/paos/resetPassword" component={ResetPassword} />
                <Route path="/paos/disabled" component={Disabled} />
                <PrivateRoute path="/paos/chat" exact component={ChatPage} />
                <PrivateRoute path="/paos/video-call" exact component={VideoCall} />
                <PrivateRoute path="/paos/video-conference" exact component={Meetings} />
                <PrivateRoute path="/paos/conference-meeting/:id" exact component={ConferenceMeeting} />
                <PrivateRoute path="/paos/exhibitor/dashboard" exact component={Dashboard} />
                <PrivateRoute path="/paos/exhibitor/profile" exact component={ExhibitorDetails} />
                <PrivateRoute path="/paos/exhibitor/booth" component={ExhibitorBooth} />
                <PrivateRoute path="/paos/attendee/lobby" component={Lobby} />
                <PrivateRoute path="/paos/attendee/exhibitHall" component={ExhibitHall} />
                <PrivateRoute path="/paos/attendee/networkingLounge" component={NetworkingLounge} />
                <PrivateRoute path="/paos/attendee/conferenceCenter" component={ConferenceCenter} />
                <PrivateRoute path="/paos/attendee/conferenceCenterDetails/:sessionId" component={ConferenceCenterPage} />
                <PrivateRoute path="/paos/attendee/technicalSessions" component={TechnicalSessions} />
                <PrivateRoute path="/paos/attendee/exhibitList" component={ExhibitList} />
                <PrivateRoute path="/paos/attendee/helpDesk" component={HelpDesk} />
                <PrivateRoute path="/paos/attendee/goldenTicket" component={GoldenTicket} />
                <PrivateRoute path="/paos/attendee/exhibitorBooth/:exhibitorId" component={AttendeeExhibitorBooth} />
                <PrivateRoute path="/paos/speaker/dashboard" exact component={DashboardSpeaker} />
                <PrivateRoute path="/paos/speaker/profile" exact component={ProfileDetails} />
                <PrivateRoute path="/paos/speaker/attendees" exact component={SessionAttendees} />
                <PrivateRoute path="/paos/user/profile" exact component={UserProfile} />
                <Route path="/paos/workProgress" exact component={WorkProgress} />
                <Route path="*" component={NotFound} />
              </Switch>
         
          </div>
      </Router>
    );}
  }  
}

export default Paos;
