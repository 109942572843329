import React, {Component} from 'react';
import TroopMessenger from "troop-messenger-chat-sdk";
import utility from "../utility/utility";
import tmChatServer from "../utility/server";
import VideoPage from './VideoPage';
import CattleCall from "Cattle-Call-SDK";
import tmChat from "../utility/tmChat";
import troopServer from "../utility/troopServer";
import UrlParams from "../utility/UrlParams";

let troopMessenger;
let tmLoginUserDetails;
let cattleCallLoginUserDetails = {};
let loginUserDetails;
let chatConversations=[];
let chatUserDetails;
let cattlecall;
let audioState = true;
let recentUsersRoles = {};
let activeUserId = '';

function checkNotificationPermission() {
    if (!("Notification" in window)) {
        console.log("Notification");
        /*alert("This browser does not support desktop notification");*/
    } else if (Notification.permission !== "denied") {
        Notification.requestPermission(function (permission) {
            // If the user accepts, let's create a notification
        });
    }
}

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
    hidden = 'msHidden';
    visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
    hidden = 'webkitHidden';
    visibilityChange = 'webkitvisibilitychange';
}
let videoParams;

class VideoCall extends Component {
    state = {onAnswered: false,eventId:localStorage.getItem('eventId')};

    constructor(props) {
        super(props);
        videoParams = new UrlParams(props.location.search).get("data");
        if (videoParams) {
            videoParams = JSON.parse(utility.decodeBase64(videoParams));
        }
        this.getUserDetails(videoParams.caller_uid);

    }

    displayNotification(message) {
        if (this.state.tabHide) {
            let notification = new Notification(message.title, {
                body: message.message,
                icon: process.env.PUBLIC_URL + '/images/tmChat/logo.png',
                vibrate: [100],
                timeout: 300,
                data: {entity_id: message.sender_id}
            });
            notification.onclick = function (event) {
                event.preventDefault();
                window.focus();
                notification.close();
            };
        }
    }
    componentWillUnmount(){
        if(document.querySelector('header')){
            document.querySelector('header').style.display='block';
        }
    }
    async componentDidMount() {
        //An array of assets
        document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
        window.addEventListener('load', this.handleLoad);
        if(document.querySelector('header')){
            document.querySelector('header').style.display='none';
        }
        window.onbeforeunload = function (e) {
            window.onunload = function () {
                cattlecall.endCall();
            }
            return undefined;
        };
        checkNotificationPermission();
        let userDetailsdata = await troopServer.getLoginUserDetails();
        if ((userDetailsdata.errorCode === 0)) {
            userDetailsdata.data[0].userId = localStorage.getItem('userId');
            userDetailsdata.data[0].role = localStorage.getItem('currentRole');
            loginUserDetails = userDetailsdata.data[0];
            this.setState({
                userDetails: loginUserDetails,
                isLoading: false
            });
            troopMessenger = new TroopMessenger('THg1LY1hdHDpbW9ueARNZVBU');
            let uid = utility.generateUserUid({
                'userId': loginUserDetails['userId'],
                'role': loginUserDetails['role']
            });
            loginUserDetails.uid = uid;

            tmLoginUserDetails = await troopMessenger.login(uid, loginUserDetails.name);
            let getUserDetails = await tmChatServer.updateUser({
                'userId': uid,
                'name': loginUserDetails.name,
                'role': loginUserDetails.role,
                tm_user_id: tmLoginUserDetails.tm_user_id
            });
            cattleCallLoginUserDetails.user_id = getUserDetails.cattle_call_id;
            this.connectCattleCall();
            try {
                loginUserDetails.tm_user_id = tmLoginUserDetails.tm_user_id;
                this.connectTMChat();
            } catch (e) {
                console.log(e);
            }
            this.setState({'userDetails': loginUserDetails})
        } else if (userDetailsdata.errorCode === 4) {
            this.setState({"redirect": true});
        }

    }

    async getUserDetails(uid) {
        let userIdRole = utility.getUserIdAndRole(uid);
        console.log(userIdRole['userId'], userIdRole, "userIdrole");
        const parameters = {
            'ids': userIdRole['userId'],
            'role': userIdRole['role'],
            'eventId': this.state.eventId
        };
        let usersList = await troopServer.getUsersListBasedOnRole(parameters);
        usersList = usersList.data;
        if (usersList && usersList.length) {
            console.log(usersList, "userList");
            usersList[0].uid = uid;
            this.setState({'callerUserData': usersList[0]});
        }
    }

    connectCattleCall = () => {
        console.log("connection callll",cattleCallLoginUserDetails);
        cattlecall = new CattleCall(cattleCallLoginUserDetails.user_id, "L0gJS27u4pHxwbh5L0gJS27u4pHxwbh5", "HGupYTsKbiJWZSDOqw3O01ajKAgNUTnf");
        cattlecall.onReady = ((data) => {
            console.log(data,"ready");
            cattlecall.getDevices( ).then((response) =>{
                if (videoParams.incommingCall) {
                    this.acceptCall();
                } else {
                    this.makeVideoCall();
                }
            }).catch();

        });
        cattlecall.onReject = ((data) => {
            this.setState({'videoCall': false});
            window.close();
        });
        cattlecall.onEnd = ((data) => {
            this.setState({'videoCall': false});

        });
        cattlecall.onVideoChange = ((data) => {
            console.log(data, "video changed");
        });
        cattlecall.onAudioChange = ((data) => {
            console.log(data, "audio changed");
        });
        cattlecall.onCallEnd = (data => {
            this.setState({'videoCall': false});
            window.close();
        });
        cattlecall.callAnswered = ((data) => {
            this.callerTone.pause();
            this.setState({"onAnswered": true});

            this.loadConversations();
        });
        cattlecall.onCallAnswered = ((data) => {
        })

        cattlecall.onerror = (data) => {
            console.log(data)
        }
    }

    loadConversations = async () => {
        console.log(this.state.eventId,"eventttytyty",chatUserDetails,"beofre");
        if (!chatUserDetails) {
            chatUserDetails = await troopMessenger.register(videoParams.caller_uid, this.state.callerUserData.name);
            this.setState({'chatUserDetails': this.state.callerUserData.tm_user_id = chatUserDetails.tm_user_id});
        }
        console.log(this.state.eventId,"eventttytyty",chatUserDetails);
        chatConversations = await tmChat.getConversation(troopMessenger, {
            'tm_user_id': chatUserDetails.tm_user_id,
            authorization_token: tmLoginUserDetails.authorization_token,
            'conversation_reference_id': this.state.eventId
        });
        this.setState({'conversations': chatConversations})
    }
    connectTMChat = () => {
        troopMessenger.connect(tmLoginUserDetails.tm_user_id, tmLoginUserDetails.authorization_token)
            .on("connect_error", function (message) {
                console.log(message, "mmessage");
            })
            .on("connected", async () => {
                this.setState({'troopMessengerConnection': troopMessenger});
            })
            .on("disconnected", function () {
            })
            .on("message_sent", async (data) => {
                if(this.state.eventId===data.conversation_reference_id) {

                    if (!chatConversations) {
                        chatConversations = [];
                    }
                    chatConversations.push(data);
                    this.setState({
                        chatConversations: chatConversations
                    });
                }
            })
            .on("receive_message", async (data) => {
                if(this.state.eventId===data.conversation_reference_id) {

                    if (data.sender_uid === activeUserId) {
                        chatConversations.push(data);
                        this.setState({'chatConversations': chatConversations})
                    }
                    let notificationData = {
                        'title': 'New Message From ' + this.state.callerUserData.name,
                        'message': data.message,
                        'sender_id': data.sender_id
                    };
                    if (data.message_type === 1) {
                        notificationData['title'] = this.state.callerUserData.name + ' Sent Attachment';
                        notificationData['message'] = 'Attachment';
                    }
                    this.displayNotification(notificationData);
                    this.setState({'chatConversations': chatConversations})
                }
            })
            .on("message_delivered", function (data) {
            })
            .on("message_read", function (data) {
            })
            .on("user_online", function (data) {
            })
            .on("user_dnd", function (data) {
            })
            .on("user_offline", function (data) {
            })
            .on("group_created", function (data) {
            })
            .on("group_updated", function (data) {
            })
            .on("typing", function (data) {
            })
    };

    handleVisibilityChange = () => {
        if (document[hidden]) {
            this.setState({tabHide: true});
        } else {
            this.setState({tabHide: false});
        }
    }
    makeVideoCall = async () => {
        /* let getRemoteUserDetails = await tmChatServer.updateUser({
             'userId': this.state.chatUser.userId,
             'name': this.state.chatUser.name,
             'role':this.state.chatUser.role,
             'uid':this.state.chatUser.uid,
             tm_user_id:this.state.chatUser.tm_user_id
         });
         cattleCallRemoteUserDetails.user_id = getRemoteUserDetails.cattle_call_id;*/
        //this.setState();
        //  let data=JSON.stringify({ incommingCall: false,'call_user_id':cattleCallRemoteUserDetails.user_id});
        //  data=utility.encodeBase64(data);
        // window.open(process.env.PUBLIC_URL+"/video-conference?data="+data,'_blank');
        let audioDevice = 'default';
        let videoDevice = 'default';
        console.log(videoParams.call_user_id,"audiasass")
        cattlecall.call('' + videoParams.call_user_id, "#localVideo", "#remoteVideo", audioDevice, videoDevice);
        // cattlecall.call(''+videoParams.call_user_id, "#localVideo", "#remoteVideo");

        this.callerTone.pause();
        this.callerTone.loop = true;
        this.callerTone.load();
        this.callerTone.play();

    };

    acceptCall() {

        this.setState({
            'videoCall': true,
            incommingCall: true,
            onAnswered: true
        });
        let data = {};

        let audioDevice = 'default';


        let videoDevice = 'default';

        data.audioDevice = audioDevice;
        data.videoDevice = videoDevice;
        data.localVideoElement = "#localVideo";
        data.remoteVideoElement = "#remoteVideo";
        cattlecall.answerCall(data);
        this.loadConversations();
    }

    endCall = () => {
        this.setState({videoCall: false});
        cattlecall.endCall();
        window.close();
    }
    toggleAudio = () => {
        cattlecall.toggleAudio();
        audioState = !audioState;
        this.setState({'toggleAudioValue': false}, () => {
            console.log(this.state, "stateValue");
        });
    };
    toggleVideo = () => {
        cattlecall.toggleVideo();
        audioState = !audioState;
        this.setState({'toggleVideoValue': false}, () => {
            console.log(this.state, "stateValue");
        });
    };
    sendMessage = (message, userDetails) => {
        message.event_id = this.state.eventId;
        message.sender_uid = loginUserDetails.uid;
        console.log(recentUsersRoles, message['receiver_uid']);
        if (message.filesPaths && message.filesPaths.length) {
            for (const filepath of message.filesPaths) {
                message.attachment = filepath;
                tmChat.sendAttachment(troopMessenger, message);
            }
        } else {
            tmChat.sendTextMessage(troopMessenger, message);
        }

    };
    closeChat=()=>{
        this.setState({'chatClose':!this.state.chatClose})
    }
    render() {
        return (
            <React.Fragment>

                <div className='tmChat'>
                    <VideoPage callUserInfo={this.state.callerUserData}
                               incomingCall={this.state.incommingCall}
                               toggleAudio={this.toggleAudio}
                               toggleVideo={this.toggleVideo}
                               toggleAudioValue={this.state.toggleAudioValue}
                               endCall={this.endCall}
                               onAnswered={this.state.onAnswered}
                               onSendMessage={this.sendMessage}
                               chatConversations={this.state.conversations}
                               troopConnection={(this.state.troopMessengerConnection ? this.state.troopMessengerConnection : {})}
                               userDetails={this.state.userDetails}
                               chatClose={this.state.chatClose}
                               closeChat={this.closeChat}
                               index={0}/>
                    <audio
                        className='d-none'
                        ref={(el) => {
                            this.callerTone = el;
                        }}>
                        <source src={process.env.PUBLIC_URL + "/images/tmChat/caller_tune.mp3"}/>
                    </audio>
                </div>
            </React.Fragment>)
    }
}

export default VideoCall