import React, { Component } from 'react';
import BottomNav from './BottomNav';
import FilterableExhibitorList from './FilterableExhibitorList';
import ChatIcon from '../../../TmChat/focus2022/chat/ChatIcon'

import {Link} from 'react-router-dom';

import Axios from 'axios';




class ExhibitHall extends Component {
    constructor(props){
        super(props);

        this.state = {
            sponsersImgList : [],
            exhibitorList : [],
            pageLoader:true,
            exhibitListModal:false,
        }
        
    }
    exhibitListModalHandler = () => {
        this.setState({exhibitListModal:!this.state.exhibitListModal})
    }





    componentDidMount(){
      
        /* getting sponsers logos */
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?type=4&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }})
        
       
         .then(res => {
            
             if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, sponsersUrl:res.data.logo, apierrorCode:res.data.errorCode})
                 this.setState({ pageLoader:false})
                 if(window.location.href.includes('popup=1')){
                    this.setState({exhibitListModal:true})
                 }
             } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                 this.setState({pageLoader:false})
                 
             }
             
         })
         .catch(err => {
             console.log(err);
         });
         
        
    }
   


    render() {

            return (
                <React.Fragment>
               
                    <section className="bg1 signleScreen overflow-hidden exhibitHallSec">
                            <div className="exhibitHallBx">
                                <h2>FOCUS 2022 EXHIBITORS</h2>
                                <div className="exhibitHallBxInr">
                                    <div className="sponSec">
                                        <div className="sponsersList">
                                            { 
                                                this.state.sponsersImgList.map(sponsers => (
                                                    sponsers.logo
                                                    ?
                                                    <Link to = {`/focus2022/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}  key={sponsers.logo} ><img src={sponsers.logo} alt="" /></Link>
                                                    :
                                                    null
                                                ))
                                            }
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            {/* <BottomNav /> */}
                            {
                             this.state.pageLoader
                             ?
                             (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                            )
                            :
                            null
                           }
                           {/* <div className="bootomButton text-center pl-0">
                                <button className="btn btn-success" onClick={this.exhibitListModalHandler}><i className="fa fa-eye"></i> View All Exhibitors</button>
                           </div> */}
                           <div className="chatIco">
                                <ChatIcon chatIconImage={process.env.PUBLIC_URL + '/images/chat-ico2.png'} />
                            </div>
                           
                    
                    {
                        this.state.exhibitListModal
                        ?
                        <div className="midModal">
                            <div className="midTable exhibitorListTable">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.exhibitListModalHandler}><i class="fa fa-times"></i></div>
                                <FilterableExhibitorList/>
                                
                            </div>
                        </div>
                        :
                        null
                    }
                </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default ExhibitHall;
