import React, { Component } from "react";

import { storage } from "../../../Firebase";
import Axios from "axios";

class AddSessionOrPosterDoc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documentsFile: null,
      maxDocumentSelectionError: "",
      docDataServer: [],
      docs: [],
      pageLoader: true,
      sessionId: this.props.sessionId,
      eventId: localStorage.getItem("eventId"),
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  deleteDoc = (e) => {
    //console.log(e.currentTarget.dataset.id );
    this.setState({ pageLoader: true });
    var id = e.currentTarget.dataset.id;
    var url = e.currentTarget.dataset.url;
    var name = e.currentTarget.dataset.filename;
    const docData = {
      eventId: localStorage.getItem("eventId"),
      accountId: localStorage.getItem("accountId"),
      sessionId: this.props.sessionId,
      fileUrl: url.toString(),
      isPoster: this.props.isPoster,
      status: 1,
      fileName: name.toString(),
      docId: id,
    };
    Axios.post(
      `${global.config.requestUrl}speaker/updateSessionOrPosterDocument`,
      docData,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        this.componentDidMount();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onChangeDocuments = (event) => {
    var files = event.target.files;
    this.setState({
      documentsFile: files,
    });
    console.log(files);
  };

  componentDidMount() {
    Axios.get(
      `${
        global.config.requestUrl
      }speaker/getPosterOrSessionDocuments?sessionId=${
        this.props.sessionId
      }&isPoster=${this.props.isPoster}&eventId=${localStorage.getItem(
        "eventId"
      )}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        if (!res.data.errorCode) {
          this.setState({
            docDataServer: res.data.data,
            pageLoader: false,
          });
        } else {
          if (res.data.errorCode === 1) {
            this.setState({
              docDataServer: [],
              pageLoader: false,
            });
          } else {
            this.setState({
              pageLoader: false,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  documentsValidateForm = () => {
    this.setState({ documentSelectionError: "" });

    var countdoc = 0;
    if (this.state.documentsFile !== null) {
      for (const key of Object.keys(this.state.documentsFile)) {
        countdoc = countdoc + 1;
      }
      if (countdoc > 5) {
        this.setState({
          maxDocumentSelectionError: "maximum 4 Doc allow at a time",
          documentSelectionSuccess: "",
        });
        return false;
      } else {
        this.setState({ maxDocumentSelectionError: "" });
      }
    }

    if (this.state.documentsFile === null) {
      this.setState({
        documentSelectionError: "Nothing to update",
        documentSelectionSuccess: "",
      });
      return false;
    } else {
      this.setState({ documentSelectionError: "" });
    }

    return true;
  };

  addDocumentsSubmit = (e) => {
    e.preventDefault();

    if (this.documentsValidateForm()) {
      this.setState({ pageLoader: true });
      if (this.state.documentsFile !== null) {
        var docs = [];
        for (const key of Object.keys(this.state.documentsFile)) {
          var docfiles = this.state.documentsFile[key];
          docs[key] = docfiles;
        }
        let randomString = Math.random().toString(36);
        this.setState({ documentsFilekey: randomString, documentsFile: null });
        for (const key of Object.keys(docs)) {
          var docfiles = docs[key];
          this.uploadFilesAsPromise(docfiles, "session_or_posterdocs");
        }
      }
    }
  };

  //Handle waiting to upload each file using promise
  uploadFilesAsPromise = (docFile, foldername) => {
    const { sessionId, eventId } = this.state;
    this.setState({
      pageLoader: true,
    });
    var that = this;
    return new Promise(function (resolve, reject) {
      var storageRef = storage
        .ref()
        .child(`${foldername}/${eventId}/${sessionId}/${docFile.name}`);

      //Upload file
      var task = storageRef.put(docFile);

      //Update progress bar
      task.on(
        "state_changed",
        function progress(snapshot) {
          var percentage =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //uploader.value = percentage;
        },
        function error(err) {},
        function complete() {
          //var downloadURL = task.snapshot.downloadURL;
          storage
            .ref(`${foldername}/${eventId}/${sessionId}`)
            .child(docFile.name)
            .getDownloadURL()
            .then((url) => {
              if (url) {
                const docData = {
                  eventId: localStorage.getItem("eventId"),
                  accountId: localStorage.getItem("accountId"),
                  sessionId: that.props.sessionId,
                  fileUrl: url.toString(),
                  isPoster: that.props.isPoster,
                  status: 0,
                  fileName: docFile.name,
                };

                Axios.post(
                  `${global.config.requestUrl}speaker/updateSessionOrPosterDocument`,
                  docData,
                  {
                    headers: {
                      "auth-token": localStorage.getItem("exhibitorToken"),
                    },
                  }
                )
                  .then((res) => {
                    if (res.data.errorCode) {
                      //it means error from server
                      that.setState({
                        documentSelectionError: res.data.errorMessage,
                        documentSelectionSuccess: "",
                        pageLoader: false,
                      });
                    } else {
                      that.setState({
                        documentSelectionError: "",
                        documentSelectionSuccess: res.data.errorMessage,
                        documentsVideoLinkShare: "",
                      });
                    }
                    that.componentDidMount();
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            });
        }
      );
    });
  };

  render() {
    const dochtml = this.state.docDataServer.map((anObjectMapped, index) => {
      fileName = anObjectMapped.name;
      if (fileName === "") {
        var fileName = "No Name";
      }
      return (
        <tr key={index}>
          <td>
            <a
              href={`${anObjectMapped.file_name}?rel=0`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {fileName}
            </a>
          </td>

          <td>
            <button
              className="btn-danger"
              data-id={`${anObjectMapped.doc_id}`}
              data-url={`${anObjectMapped.file_name}`}
              data-filename={`${fileName}`}
              data-filetype={`${anObjectMapped.fileType}`}
              onClick={this.deleteDoc.bind(this)}
            >
              <i className="fa fa-trash"></i>
            </button>
          </td>
        </tr>
      );
    });
    return (
      <React.Fragment>
        <div className="infoMsg">
          <p>Upload your handouts or additional materials for attendees to view during the conference. Click “Browse” to select a file and “submit” to save. </p>
          
          <p>Formats include: Word documents, .pdf and Powerpoint, .txt and .zip files.   If using Powerpoint save document as .ppsx before uploading</p>
        </div>
        <div className="modalPopUpFormBx">
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : null}
          <h4 className="mb-3">Add Documents</h4>
          <div className="error">
            {this.state.documentSelectionError}{" "}
            {this.state.maxDocumentSelectionError}
          </div>
          <div className="success">{this.state.documentSelectionSuccess}</div>
          <form onSubmit={this.addDocumentsSubmit}>
            <div className="form-group">
              <label>Documents</label>
              <input
                type="file"
                name="documentsFile"
                multiple
                className="form-control"
                accept=".xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .ppsx, .zip"
                multiple
                onChange={this.onChangeDocuments}
                key={this.state.documentsFilekey || ""}
              />
              <small>Multiple Files Can be Selected</small>
            </div>
            <div className="mt-3">
              <button type="submit">Add</button>
            </div>
          </form>
        </div>

        <div className="modalTableWrp">
          <table className="table modalTable text-center mt-3">
            <thead>
              <tr>
                <th>Documents</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{dochtml}</tbody>
          </table>
        </div>
      </React.Fragment>
    );
  }
}
export default AddSessionOrPosterDoc;
