import React, { Component } from "react";

import jwt_decode from "jwt-decode";
import LeftNavBar from "./LeftNavBar";
import SessionsAndPostersEdit from "./SessionsAndPostersEdit";
import Axios from "axios";
import { Link } from "react-router-dom";
import ChatIcon from "../../../TmChat/csda/chat/ChatIcon";

class DashboardSpeaker extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      pageLoader: true,
      sectionLoader: true,
      showChatIcon: false,
      sessionLists: [],

      resetSuccess: "",
      resetMessage: "",
      watchDemoModal: false,
      showChatIcon:true,
    };
  }
  getAlreadyAddedSessionsInSchedule = () => {
    Axios.get(
      `${
        global.config.requestUrl
      }user/getSessionSchedules?eventId=${localStorage.getItem(
        "eventId"
      )}&memberId=${localStorage.getItem("userId")}`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {

        var scheduleInList = [];
        var schduleInListWithSchId = {}; // {} will create an object

        if(res.data.errorCode === 0){
            res.data.data.forEach((element) => {
                scheduleInList.push(element.session_id);
                var name = element.session_id;
                var val = element.schdeule_id;
                schduleInListWithSchId[name] = val;
              });
        }

        localStorage.setItem('addedSession',JSON.stringify(schduleInListWithSchId));
        
        
      })
      .catch((err) => {
        console.log(err);
      });
  }
  
  componentDidMount() {
    if (localStorage.getItem("roles")) {
      var rolesArr = localStorage.getItem("roles").split(",");
      var currentRole = "";

      if (rolesArr.indexOf("-6") !== -1) {
        currentRole = -6;
        localStorage.setItem("currentRole", currentRole);
        this.getAlreadyAddedSessionsInSchedule();
      } else {
        this.props.history.push("/navigate2022/notfound");
      }
    }
  }

  

  
  render() {
    return (
      <React.Fragment>
        <section className="bg7 signleScreen">
          <div className="dashboardWrapper">
            <LeftNavBar />
            <div className="rightContentAera">
              
              
              
                <SessionsAndPostersEdit />

                {
                    // this.state.showChatIcon
                    // ?
                    // <div className="chatIcon">
                    //   <Link to="/chat" target='_blank'>
                    //       <ChatIcon/>
                    //         <img src={process.env.PUBLIC_URL + '/images/chat-ico.png'} alt="" />
                    //   </Link>
                    // </div>
                    // :
                    // null
                }
              
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}
export default DashboardSpeaker;
