import React, { Component, useState } from "react";
import Axios from "axios";
import { AgGridReact, AgGridColumn } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import AddToScheduleRenderer from "./AddToScheduleRenderer";
import LinkToSessionRenderer from "./LinkToSessionRenderer";

class SessionListGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addedSessions: [],
      trackName: "",
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ trackName: nextProps.passprops });
    this.getAlreadyAddedSessionsInSchedule();
  }
  componentDidMount() {
    this.getAlreadyAddedSessionsInSchedule();
  }
  getAlreadyAddedSessionsInSchedule = () => {
    Axios.get(
      `${
        global.config.requestUrl
      }user/getSessionSchedules?eventId=${localStorage.getItem(
        "eventId"
      )}&memberId=${localStorage.getItem("userId")}`,
      { headers: { "auth-token": localStorage.exhibitorToken } }
    )

      .then((res) => {
        var scheduleInList = [];
        res.data.data.forEach((element) => {
          scheduleInList.push(element.session_id);
        });
        this.setState({ addedSessions: scheduleInList });
        {
          res.data.data.map((startTimeFormat) =>
            console.log(startTimeFormat.start_time.split(" "))
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  currencyFormatter = (params) => {
    return (
      '<a href="https://www.google.com" target="_blank" rel="noopener">' +
      params.value +
      "</a>"
    );
  };

  render() {
    const SessionGrid = () => {
      const [gridApi, setGridApi] = useState(null);
      const [gridColumnApi, setGridColumnApi] = useState(null);
      const [rowData, setRowData] = useState(null);

      const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        const updateData = (data) => {
          setRowData(data);
        };

        const postdata = {
          eventId: localStorage.getItem("eventId"),
          searchByTrack: this.state.trackName,
          searchByName: "",
          sortByDate: "",
        };

        Axios.post(
          `${global.config.requestUrl}user/getTechnicalSessions`,
          postdata,
          { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
        )
          .then((res) => {
            if (!res.data.errorCode) {
              updateData(res.data.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      };

      return (
        <div style={{ width: "100%", height: "100%", align: "center" }}>
          <div
            id="myGrid"
            style={{
              height: "100%",
              width: "100%",
            }}
            className="ag-theme-alpine"
          >
            <AgGridReact
              frameworkComponents={{
                LinkToSessionRenderer: LinkToSessionRenderer,
                AddToScheduleRenderer: AddToScheduleRenderer,
              }}
              defaultColDef={{
                editable: false,
                enableRowGroup: true,
                enablePivot: true,
                enableValue: true,
                sortable: true,
                resizable: false,
                filter: true,
                flex: 0,
                minWidth: 30,
                floatingFilter: true,
              }}
              suppressRowClickSelection={true}
              groupSelectsChildren={true}
              debug={true}
              rowSelection={"multiple"}
              rowGroupPanelShow={"always"}
              pivotPanelShow={"always"}
              enableRangeSelection={true}
              pagination={false}
              // paginationPageSize={10}
              onGridReady={onGridReady}
              rowData={rowData}
            >
              <AgGridColumn
                field="speaker_name"
                headerName="Speaker Name"
                width="180"
              />
              <AgGridColumn
                field="session_name"
                headerName="Session Name"
                width="600"
                cellRenderer="LinkToSessionRenderer"
              />
              <AgGridColumn
                headerName="Start Date"
                field="start_date"
                filter="agDateColumnFilter"
                filterParams={filterParams}
                width="170"
              />
              <AgGridColumn
                field="start_time"
                headerName="Start Time"
                width="130"
              />

              <AgGridColumn
                field="session_id"
                headerName="Action"
                width="150"
                filter={false}
                sortable={false}
                cellRenderer="AddToScheduleRenderer"
                cellRendererParams={{ addedSession: this.state.addedSessions }}
              />
              {/* <AgGridColumn field="session_id" filter={false} valueFormatter={this.currencyFormatter} /> */}
            </AgGridReact>
          </div>
        </div>
      );
    };

    var filterParams = {
      comparator: function (filterLocalDateAtMidnight, cellValue) {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[0]) - 1,
          Number(dateParts[1])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
    };
    return (
      <React.Fragment>
        <SessionGrid />
      </React.Fragment>
    );
  }
}
export default SessionListGrid;
