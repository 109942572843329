import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import profileImage from "../../../assets/images/no-image.png";
import ChatInput from '../../../TmChat/MdAPA2023/chat/ChatInput';

const SpeakerDetails = () => {
  const history = useHistory();
  const [speakerDetail, setSpeakerDetail] = useState([]);
  const [speakerSessions, setSpeakerSessions] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    Axios.get(
      `${
        global.config.requestUrl
      }speaker/getSpeakerInfo?eventId=${localStorage.getItem(
        "eventId"
      )}&speakerId=${id}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    ).then((res) => {
      if (res.data.errorCode === 0) {
        setSpeakerDetail(res.data.data[0]);
      }
    });
    Axios.get(
      `${
        global.config.requestUrl
      }speaker/getSpeakerSessions?eventId=${localStorage.getItem(
        "eventId"
      )}&speakerId=${id}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    ).then((res) => {
      if (res.data.errorCode === 0) {
        setSpeakerSessions(res.data.data);
      }
    });
  }, [id]);
  return (
    <React.Fragment>
      <div className="padding-bottom">
        <div className="card p-3 border-none box-shadow">
          <div className="pageHeading">
            <i
              className="fa fa-arrow-left backBtn"
              onClick={() => history.goBack()}
            ></i>
            <h4 className="text-center">Speakers Details</h4>
          </div>
        </div>
        <div className="card p-3 border-none box-shadow">
          <div className="listInline">
            <div className="userPic mr-3">
              <img
                src={speakerDetail.image ? speakerDetail.image : profileImage}
                alt="No" />
            </div>
            <div className="content">
              <h3>
                {speakerDetail.firstName} {speakerDetail.lastName}
              </h3>
              <h5> {speakerDetail.title ? speakerDetail.title:''}</h5>
              <h5>{speakerDetail.company}</h5>
              <h5>{speakerDetail.city},{speakerDetail.state}</h5>
              <h5>{speakerDetail.country}</h5>
            </div>
          </div>
        </div>

        {speakerDetail.bio ? (
          <div className="card p-3 border-none box-shadow">
            <div className="content">{speakerDetail.bio}</div>
          </div>
        ) : null}

        {speakerSessions.length > 0 ?
        <div className="card p-3 border-none box-shadow">
          <ul className="unOrderList">
            {speakerSessions.map((speakerSession, index) => (
              <li>{speakerSession.session_name}</li>                  
            ))}
          </ul>
        </div>
         : null}
      </div>
      {/* {speakerDetail.email?<ChatInput userInfo={{email:speakerDetail.email,role: "-6"}} />:''} */}
    </React.Fragment>
  );
};
export default SpeakerDetails;
