import React, { Component } from 'react'
import Axios from 'axios';
import {Link} from "react-router-dom";
import AddEventToSchedule from './AddEventToSchedule';
import BlankSessionRelatedHtml from './BlankSessionRelatedHtml';
import jwt_decode from 'jwt-decode';

export default class MySchedule extends Component {
    constructor(){
        super();

        this.state={
            myScheduleData:[],
            filterDates:[],
            isSession:"",
            searchByDate:'',
            pageLoader:true,
            notFound:"",
            addEventToScheduleModal:false,
        }
    }
    addEventToScheduleModalHandlar = (e) => {
        e.preventDefault();
        this.setState({addEventToScheduleModal:!this.state.addEventToScheduleModal})
        if(this.state.addEventToScheduleModal){
            this.renderSchedules();
        }
    }
    componentDidMount(){
        if(localStorage.getItem('exhibitorToken')){
            const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            if (Date.now() >= decoded.exp * 1000) {
                this.setState({pageLoader : false});
                alert('Your sesssion expired, Login again to see your schedule');return;
            }else{
                this.renderSchedules();
                this.renderFilterDates();
            }
        }
        
    }


    getAllSessionRelatedData = (sessId) =>{
        document.getElementById("sessionSpeakerData").innerHTML = '';
        document.getElementById("sessionDocumentData").innerHTML = '';
        document.getElementById("sessionDetailsData").innerHTML = '';
          this.getSessionBio(sessId);
          this.getSessionDocuments(sessId);
          this.getSpeakerBio(sessId);
          document.getElementById("sessionDetailsModal").style.display = "block";
    }
    getSpeakerBio = (sessId) => {
        Axios.get(`${global.config.requestUrl}getSpeakerBio?eventId=${localStorage.getItem('eventId')}&sessionId=${sessId}`
        )
        
        .then(res => {
            var data = '';
            if(!res.data.errorCode){
              if(res.data.data.length === 1){
                res.data.data.map(content => (
                    //console.log(content)
                    data+='<div class="singlespeaker"><div class="speakercard" onclick="toggleSpeakerContent('+content.speaker_id+')">'+content.name+'</div><div id="speakerdetail_'+content.speaker_id+'"  style="display:block;"  > <img src="'+content.profile_pic+'" alt="" class="speakerAbtPic"/>'+content.about+'</div></div>'
                 ))
              }else{
                res.data.data.map(content => (
                    //console.log(content)
                    data+='<div class="singlespeaker"><div class="speakercard" onclick="toggleSpeakerContent('+content.speaker_id+')">'+content.name+'</div><div id="speakerdetail_'+content.speaker_id+'"  style="display:none;"  > <img src="'+content.profile_pic+'" alt="" class="speakerAbtPic"/>'+content.about+'</div></div>'
                 ))
              }
               
            }
            document.getElementById("sessionSpeakerData").innerHTML = data;
        })
        .catch(err => {
            console.log(err);
        });
    }
    getSessionDocuments = (sessId) => {
        Axios.get(`${global.config.requestUrl}getSessionDocuments?eventId=${localStorage.getItem('eventId')}&sessionId=${sessId}`
        )
        
        .then(res => {
            tbl = '';
            if(!res.data.errorCode){
                var tbl = '<div className="modalTableWrp"><table className="table modalTable text-center mt-3"><tbody>';
                res.data.data.map(content => (
                    tbl+= '<tr><td className="text-left"><a href="'+content.file+'" target="_blank" rel="noopener noreferrer">'+content.name+'</a></td></tr>'
                ))
                tbl+= '</tbody></table></div>';
            }
            document.getElementById("sessionDocumentData").innerHTML = tbl;
            
        })
        .catch(err => {
            console.log(err);
        });
    }
    getSessionBio = (sessId) =>{
        Axios.get(`${global.config.requestUrl}getSessionBio?eventId=${localStorage.getItem('eventId')}&sessionId=${sessId}`
        )
        .then(res => {
             //console.log(res.data.data[0].description)
             var desc = '';
             if(!res.data.errorCode){
                var desc = '<p>'+res.data.data[0].description+'</p>';
             }
             document.getElementById("sessionDetailsData").innerHTML = desc;
             

        })
        .catch(err => {
            console.log(err);
        });
    }


    renderFilterDates = () =>{
        Axios.get(`${global.config.requestUrl}user/getScheduleDates?eventId=${localStorage.getItem('eventId')}&memberId=${localStorage.getItem('userId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(!res.data.errorCode)
              this.setState({filterDates:res.data.data});
            this.setState({pageLoader:false});
        })
        .catch(err => {
            console.log(err);
        });
    }
 
    renderSchedules = () => {
        this.setState({pageLoader : true});
        Axios.get(`${global.config.requestUrl}user/getSessionSchedules?eventId=${localStorage.getItem('eventId')}&memberId=${localStorage.getItem('userId')}&searchByDate=${this.state.searchByDate}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({myScheduleData:res.data.data, pageLoader:false, notFound:res.data.errorCode})               
            console.log(res.data.data)
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
    }

    deleteSchedule = (schedule_id,sess_id) => {
        this.setState({pageLoader : true});
        var ButtonIdAdd = 'add_'+sess_id;
        var ButtonIdDel = 'del_'+sess_id;
        const postdata = {
            eventId: localStorage.getItem('eventId'),
            scheduleId:schedule_id,
            memberId:localStorage.getItem('userId'),
           }

        Axios.post(`${global.config.requestUrl}user/deleteSchedule`, postdata,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            if(!res.data.errorCode){
                if(document.getElementById(ButtonIdAdd) && document.getElementById(ButtonIdDel)){
                    document.getElementById(ButtonIdAdd).style.display = "block";
                    document.getElementById(ButtonIdDel).style.display = "none";
                }
                var alreadyAddedSession = JSON.parse(localStorage.getItem('addedSession'));
                delete alreadyAddedSession[sess_id];
                localStorage.setItem('addedSession',JSON.stringify(alreadyAddedSession));
              this.setState({pageLoader : false});
              this.renderSchedules();
            }else{
                this.setState({pageLoader : false});
            }
           console.log(res.data.data) 
            
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader : false})
        });
    }

    onChangeDateHandler = (e) => {
        this.setState({
          searchByDate:e.target.value,
        }, () => {
          this.renderSchedules();
        });
        
    }
    render() {
        var showaddSchedule = false;
        if(localStorage.getItem('exhibitorToken')){
            const token = localStorage.exhibitorToken;
            const decoded = jwt_decode(token);
            if (Date.now() < decoded.exp * 1000) {
                showaddSchedule = true;
            }
        }
        return (
            <React.Fragment>
            {
                this.state.pageLoader
                ?
                (
                    <div className="loaderWrp">
                        <div className="loaderInr">
                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                        </div>
                    </div>
                )
                :
                null
            }
                <div className="midTable exhibitHallBx">
                    <h2>My Schedule</h2>
                    <div className="sortBy">
                        <div className="sortBx">
                            <label>Date Filter :</label>
                            <select name="trackfilter" onChange={this.onChangeDateHandler}  >
                                <option value="">All</option>
                                {
                                    this.state.notFound === 0 
                                    ?
                                    <React.Fragment>
                                    { 
                                   
                                        this.state.filterDates.map(dates => (
                                            <option value={dates.session_date} >{dates.session_date}</option>
                                        ))
                                } 
                                    </React.Fragment>
                                    :
                                    null
                                }
                             
                            </select>
                        </div>
                        {
                            showaddSchedule === true
                            ?
                            <div className="sortBx">
                            <div className="addBtn">
                                <Link onClick={this.addEventToScheduleModalHandlar}>
                                    <span>Add Event to Schedule</span>
                                    <i className="fa fa-plus"></i>
                                </Link>
                            </div>
                            </div>
                            :
                            null
                        }   
                        
                    </div>
                    <div className="midTableWrp">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="width100">Date</th>
                                    <th className="width100">Time</th>
                                    <th>Details</th>
                                    <th className="text-center width100">Action</th>
                                </tr>
                            </thead>
                            <tbody>  
                            {
                                this.state.notFound === 0 
                                ?
                                <React.Fragment>
                                { 
                                    this.state.myScheduleData.map(scheduleData => (
                                        <tr key={scheduleData.schdeule_id} className={`${scheduleData.session_status === 2 ? "cancelled-session" : ""}`}
                                        >
                                            <td>{scheduleData.start_date}</td>
                                            <td>{scheduleData.start_time}</td>
                                            <td>
                                                <p><b>{scheduleData.session_name}</b></p>
                                                {
                                                    scheduleData.is_session === 0 
                                                    ?
                                                    <p>{scheduleData.notes}</p> 
                                                    :
                                                    null
                                                 }
                                            </td>
                                            <td className="text-center">
                                                {
                                                    scheduleData.is_session === 1 
                                                    ?
                                                    
                                                        localStorage.getItem('singleSignOn') === '0' ?
                                                    <React.Fragment>
                                                        <Link onClick={e=> { e.preventDefault(); this.getAllSessionRelatedData(scheduleData.session_id)} }className="btn btn-warning"><i className="fa fa-eye"></i></Link></React.Fragment>
                                                        :
                                                        <React.Fragment><Link to={`/focus2022/attendee/conferenceCenterDetails/${scheduleData.session_id}`} className="btn btn-warning"><i className="fa fa-eye"></i></Link>

                                                        <button onClick={() => this.deleteSchedule(scheduleData.schdeule_id,scheduleData.session_id)} className="btn btn-danger ml-2 blk" style={{'background':'transparent'}}><i style={{'borderRadius':'5px', 'padding':'8px 10px'}} className="fa fa-trash maroonBtn"></i></button>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <button onClick={() => this.deleteSchedule(scheduleData.schdeule_id,scheduleData.session_id)} className="btn btn-danger blk" style={{'background':'transparent'}}><i style={{'borderRadius':'5px', 'padding':'8px 10px'}} className="fa fa-trash maroonBtn"></i></button>
                                                    </React.Fragment>
                                                }
                                                
                                            </td>
                                        </tr>
                                    ))
                                    
                                }
                                </React.Fragment>
                                :
                                <tr>
                                    <td colSpan="4"><h5 className="text-center">No Data Found</h5></td>
                                </tr>
                            }
                                
                            </tbody>
                        </table>
                    </div>
                </div> 
                {
                    this.state.addEventToScheduleModal
                    ?
                    <div className="midModal verticleCenterModal">
                        <div className="midContentWrp">
                            <div class="modalPopUpClose" id="addingSchedulePop" onClick={this.addEventToScheduleModalHandlar}><i className="fa fa-times"></i></div>
                            <h3>Add Event to Schedule</h3>
                            <div className="midContentBx">
                                <AddEventToSchedule />
                            </div>                                
                        </div>
                    </div>
                    :
                    null
                }
               <BlankSessionRelatedHtml/>
            </React.Fragment>
            
        )
    }
}
