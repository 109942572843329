import React, { Component } from "react";
import Axios from "axios";

import { storage } from "../../Firebase";
// import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import profileImage from "../../assets/images/no-image.png";

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: "",
      lastName: "",
      phone: "",
      title: "",
      image: null,
      imageUrl: "",
      company: "",
      city: "",
      state: "",
      country: "",
      firstNameError: "",
      lastNameError: "",
      titleError: "",
      submitError: "",
      submitSuccess: "",
      exhibitorId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      pageLoader: true,
      authToken: localStorage.getItem("exhibitorToken"),
      remove: false,
      profileImage: profileImage
    };
  }

  /*===Form input field Handle function===*/
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  /*===Upload contact pic Handle function===*/
  contactPicImageChange = (e) => {
    if (e.target.files[0]) {
      const image = e.target.files[0];
      this.setState(() => ({ image }));

      const objectUrl = URL.createObjectURL(e.target.files[0])
      this.setState({profileImage: objectUrl})
    }
  };
  /*===Form Submit function===*/
  validateForm = (e) => {
    let firstNameError = "";
    let lastNameError = "";

    if (!this.state.firstName) {
      firstNameError = "Invalid First Name";
    }
    if (!this.state.lastName) {
      lastNameError = "Invalid  Last Name";
    }

    if (firstNameError || lastNameError) {
      this.setState({ firstNameError, lastNameError });
      return false;
    }

    return true;
  };

  /*===Company Form Submit function===*/
  profileInfoSubmit = (e) => {
    e.preventDefault();
    const formValidate = this.validateForm();
    if (formValidate) {
      this.setState({ pageLoader: true });
    }
    console.log("efgege",this.state.image)
    /*===image upload function===*/
    if (this.state.image !== null) {
      console.log("entry");
      const { image, exhibitorId, eventId } = this.state;
      // Create a storage reference from our storage service
      var storageRef = storage.ref();
      const uploadTask = storageRef
        .child(`images/${eventId}/${exhibitorId}/${image.name}`)
        .put(image);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          //progress func
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          this.setState({ progress });
          // console.log(progress)
          if (progress !== 100) {
            /* this.setState({pageLoader:true}) */
          }
        },
        (error) => {
          //error func
          console.log(error);
        },
        () => {
          //complete func
          storage
            .ref(`images/${eventId}/${exhibitorId}`)
            .child(image.name)
            .getDownloadURL()
            .then((url) => {
              console.log("url",url)
              this.setState({ imageUrl: url });
              localStorage.setItem("userProfilePic", url);
              if (url && this.state.imageUrl) {
                this.submitForm();
              }
            });
        }
      );
    } else {
      this.submitForm();
    }
  };

  submitForm = () => {
    const formValidate = this.validateForm();
    const userType = "-100";
    
    /*===update user profile API function===*/
    const userDetails = {
      firstName: this.state.firstName.trim(),
      lastName: this.state.lastName.trim(),
      phone: this.state.phone,
      title: this.state.title,
      userId: localStorage.getItem("userId"),
      type: userType,
      eventId: localStorage.getItem("eventId"),
      username: localStorage.getItem("username"),
      image: (this.state.remove) ? '' : this.state.imageUrl,
      company: this.state.company,
      city: this.state.city,
      state: this.state.state,
      country: this.state.country
    };
  
    if (formValidate) {
      console.log(userDetails)
      Axios.post(
        `${global.config.requestUrl}user/updateUserProfile`,
        userDetails,
        { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
      )
        .then((res) => {
          if (res.data.errorCode === 0) {
            this.setState({
              submitError: "",
              submitSuccess: res.data.errorMessage,
              pageLoader: false,
            });
            if (
              localStorage.getItem("userProfilePic") !== "" &&
              localStorage.getItem("userProfilePic") !== null
            ) {
              document.getElementById("profile_img").src =
                localStorage.getItem("userProfilePic");
            }
          } else {
            this.setState({
              submitError: res.data.errorMessage,
              submitSuccess: "",
              pageLoader: false,
            });
          }
          this.setState({
            firstNameError: "",
            lastNameError: "",
            phoneError: "",
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  componentDidMount() {
    if (localStorage.getItem("roles")) {
      var rolesArr = localStorage.getItem("roles").split(",");
      if (
        localStorage.getItem("currentRole") == -10 ||
        rolesArr.indexOf("-7") !== -1
      ) {
        //DO nothing
      } else {
        this.props.history.push("/fna2024/lobby");
      }
    }

    /*===Get ExhibitorInfoAPI function===*/

    const details = {
      username: localStorage.getItem("username"),
      eventId: localStorage.getItem("eventId"),
      role: localStorage.getItem("currentRole").toString()
    };

    Axios.post(`${global.config.requestUrl}user/getUserDetails`, details, {
      headers: { "auth-token": localStorage.exhibitorToken },
    })
      .then((res) => {
        if (res.data.errorCode) {
          this.setState({ somethingWrongData: true, pageLoader: false });
        } else {
          let name = res.data.data[0].name;
          let fields = name.split(" ");
          this.setState({
            firstName: fields[0],
            lastName: fields[1],
            phone: res.data.data[0].phone,
            title: res.data.data[0].designation,
            company: res.data.data[0].company,
            imageUrl: res.data.data[0].profilePic,
            city: res.data.data[0].city,
            state: res.data.data[0].state,
            country: res.data.data[0].country,
            pageLoader: false,
            profileImage: (res.data.data[0].profilePic !== "''" &&  res.data.data[0].profilePic !== "") ? res.data.data[0].profilePic : profileImage
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  removePhoto = () => {
    this.setState({remove: true, profileImage: profileImage});
  }

  render() {
    return (
      <React.Fragment>
        <div className="p-3">
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : null}
          <div className="card p-3 border-none box-shadow">
            <div className="pageHeading">
              <Link to="/fna2024/lobby">
                <i className="fa fa-arrow-left backBtn"></i>
              </Link>
              <h4 className="text-center">User Information</h4>
            </div>
          </div>
          <div className="card p-3 border-none box-shadow userProfile-container">
            <form onSubmit={this.profileInfoSubmit}>
              <div className="container">
                <div className="pb-4">
                  <div className="row">
                    <div className="col-12 mb-2">
                      {/* <h3>User Information</h3> */}
                      <div className="error text-center">
                        {this.state.submitError}
                      </div>
                      <div className="success text-center">
                        {this.state.submitSuccess}
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-12 pr-5">
                      <div className="formProfile userProfile">
                        <div className="formProfilePic">
                          <img
                              src={
                                this.state.profileImage
                              }
                              alt=""
                            />
                        </div>
                        <div><a style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={()=>this.removePhoto()}>Remove Photo</a></div>
                        <div className="formProfileBtn">
                          <div className="form-group">
                            <label>Main Contact Pic</label>
                            <input
                              type="file"
                              className="form-control"
                              name="image"
                              onChange={this.contactPicImageChange}
                              accept="image/x-png,image/gif,image/jpeg,image/jpg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row pl-4">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>
                              First Name <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              name="firstName"
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.firstName}
                              disabled
                            />
                            <div className="errorMessage">
                              {this.state.firstNameError}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>
                              Last Name <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              name="lastName"
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.lastName}
                              disabled
                            />
                            <div className="errorMessage">
                              {this.state.lastNameError}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>Title</label>
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.title}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label>Company </label>
                            <input
                              type="text"
                              name="company"
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.company}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>City</label>
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.city}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>State</label>
                            <input
                              type="text"
                              name="state"
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.state}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-4">
                          <div className="form-group">
                            <label>Country</label>
                            <input
                              type="text"
                              name="country"
                              className="form-control"
                              onChange={this.onChange}
                              value={this.state.country}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-12 text-center mt-3">
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn submit-profile-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default UserProfile;
