import React, { Component } from "react";

import { storage } from "../../../Firebase";
import Axios from "axios";

class SeeSessionOrPosterVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: true,
      sessionId: this.props.sessionId,
      eventId: localStorage.getItem("eventId"),
      videolink: "",
    };
  }

  componentDidMount() {
    this.getVideoData();
  }
  componentWillReceiveProps(nextProps) {
    this.getVideoData();
  }

  getVideoData = () => {
    Axios.get(
      `${global.config.requestUrl}speaker/getPosterOrSessionVideo?sessionId=${
        this.props.sessionId
      }&eventId=${localStorage.getItem("eventId")}`,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        if (!res.data.errorCode) {
          this.setState({
            videolink: res.data.data[0].video_link,
            pageLoader: false,
          });
        } else {
          this.setState({ pageLoader: false });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <React.Fragment>
        {this.state.videolink !== "" ? (
          <div className="">
            <a href={this.state.videolink} target="_blank">
              View uploaded media
            </a>
          </div>
        ) : (
          <button disabled>No Video uploaded</button>
        )}
        {this.state.pageLoader ? (
          <div className="loaderWrp">
            <div className="loaderInr">
              <img src={process.env.PUBLIC_URL + "/images/loader.gif"} alt="" />
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}
export default SeeSessionOrPosterVideo;
