import React, { Component } from 'react';
import BottomNav from './BottomNav'
import Axios from 'axios';
import {Link} from 'react-router-dom';
import Slider from "react-slick";



class TechnicalSessions extends Component {
    constructor(props){
        super(props);

        this.state={
            sponsersImgList:[],
            mediaPartnerList:[],
            producedByList:[],
            technicalSessionList:[],
            addedSessions:[],
            trackList:[],
            searchByTrack:'',
            searchByName:'',
            norecord:false,
            sortByDate:'ASC',
            sectionLoader:true,
            pageLoader : true,
            startTimeFormat : ''
        }
        
    }
    componentDidMount(){

        /* sponser logos */
          
        Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?eventId=${localStorage.getItem('eventId')}&type=3`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, mediaPartnerList:res.data.mediaPartner, producedByList:res.data.producedBy,sectionLoader:false});
                
            } else{
                this.setState({sectionLoader:false});
            }
            
           
        })
        .catch(err => {
            console.log(err);
        });

        /* track list */

        Axios.get(`${global.config.requestUrl}user/getSessionTrackList?eventId=${localStorage.getItem('eventId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({trackList:res.data.data});
                
            }
            
           
        })
        .catch(err => {
            console.log(err);
        });

        /* Technical session list */
        this.renderSessionList();
        
    }

    getAlreadyAddedSessionsInSchedule = () =>{
        Axios.get(`${global.config.requestUrl}user/getSessionSchedules?eventId=${localStorage.getItem('eventId')}&memberId=${localStorage.getItem('userId')}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            var scheduleInList = [];
           res.data.data.forEach(element => {
                scheduleInList.push(element.session_id);
            });
            this.setState({addedSessions:scheduleInList});
            console.log(this.state.addedSessions);
           console.log(res.data.data)
            
            {res.data.data.map( startTimeFormat => (
                    console.log(startTimeFormat.start_time.split(" ")) 
                ))
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    renderSessionList = () => {
        this.getAlreadyAddedSessionsInSchedule();
        this.setState({pageLoader : true})  
        const postdata = {
            eventId: localStorage.getItem('eventId'),
            searchByTrack:this.state.searchByTrack,
            searchByName:this.state.searchByName,
            sortByDate:this.state.sortByDate
           }

        Axios.post(`${global.config.requestUrl}user/getTechnicalSessions`, postdata,
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
        )
        .then(res => {
            if(!res.data.errorCode){
              this.setState({technicalSessionList:res.data.data,pageLoader : false,norecord:false});
            }else{
                this.setState({technicalSessionList:[], pageLoader : false,norecord:true});
            }
            
            
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader : false})
        });
        
    }

    onChangeTrackHandler = (e) => {
        this.setState({
          searchByTrack:e.target.value,
        }, () => {
          this.renderSessionList();
        });
    }

   
     handleDateSort = (e) =>{
        
        if(this.state.sortByDate == 'ASC'){
            this.setState({
                sortByDate:'DESC'
            }, () => {
                this.renderSessionList();
            });
        } 
        if(this.state.sortByDate == 'DESC'){
            this.setState({
                sortByDate:'ASC'
            }, () => {
                this.renderSessionList();
            }); 
        }
        
     }
     onChange = (e) => {
        this.setState({[e.target.name]: e.target.value})
    }

    addToSessionSchedule = (sess_id,sess_name,sess_startdate,sess_starttime) => {
        
        this.setState({pageLoader : true})  
        const newBoothContact = {
            sessionId:sess_id,
            eventId:localStorage.getItem('eventId'),
            memberId:localStorage.getItem('userId'),
            role:localStorage.getItem('currentRole'),
            sName: sess_name,
            startDate: sess_startdate,
            startTime:sess_starttime,
            isSession:1
            
        }
        Axios.post(`${global.config.requestUrl}user/addEventToSchedule`, newBoothContact,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                this.setState({pageLoader : false})
                var addedButtonId = 'already_'+sess_id;
                var ButtonId = 'add_'+sess_id;
                document.getElementById(addedButtonId).style.display = "block";
                document.getElementById(ButtonId).style.display = "none";
            })
            .catch(err => {
                console.log(err);
            }); 
    }

    render() {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
          };
            return (
                <React.Fragment>
               
                    <section className="bg11 signleScreen paddingTop height1100">
                    {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                            <div className="loaderInr">
                                <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                            </div>
                            </div>
                        )
                        :
                        null
                    }
                            <div className="midTable">
                                <h3>Technical Sessions</h3>
                                <div className="sortBy">
                                    <div className="sortBx">
                                        <label>Filter By Track:</label>
                                        <select name="trackfilter" onChange={this.onChangeTrackHandler}>
                                            <option value="">All</option>
                                        { 
                                        this.state.trackList.map(trackdata => (
                                            
                                            <option value={trackdata.track} key={trackdata.track}>{trackdata.track}</option>
                                        ))
                                        
                                    }
                                        </select>
                                    </div>
                                    <div className="searchBx">
                                        <input type="text" name="searchByName" value={this.state.searchByName}  placeholder="Search Here..." onChange={this.onChange}/>
                                        <button onClick={this.renderSessionList}><i className="fa fa-search"></i></button>
                                    </div>
                                </div>
                                <div className="midTableWrp">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Speaker Name</th>
                                                <th>Session Name</th>
                                                <th className="noWrap" onClick={this.handleDateSort}>Date/Time <i className="fa fa-sort" aria-hidden="true"></i></th>
                                                <th>Track</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        {
                                      this.state.norecord
                                      ?
                                      (
                                        <tr>
                                                <td colSpan="5">No record Found</td>
                                        </tr>
                                      )
                                      :
                                      null
                                    }

                                        { 
                                        this.state.technicalSessionList.map(technicalSession => (
                                            <tr key={technicalSession.session_id}>
                                                <td ><p>{technicalSession.speaker_name}</p></td>
                                                <td>
                                                    
                                                    <p>{technicalSession.session_name}</p>
                                                    <Link to={`/vcd/attendee/conferenceCenterDetails/${technicalSession.session_id}`}>Click to View
                                                    </Link>
                                                </td>
                                                <td><p>{technicalSession.start_date} {technicalSession.start_time}</p></td>
                                                <td>
                                                    <p>{technicalSession.track}</p>
                                                </td>

                                                {
                        this.state.addedSessions.indexOf(technicalSession.session_id) !== -1
                        ?
                        (
                            <td><button className="btn btn-secondary">Already Added</button></td>
                        )
                        :
                        <td><button id={`add_${technicalSession.session_id}`} onClick={() => this.addToSessionSchedule(technicalSession.session_id,technicalSession.session_name,technicalSession.start_date,technicalSession.start_time)} className="btn btn-warning">Add to my Schedule</button><button className="btn btn-secondary" style={{display: 'none' }} id={`already_${technicalSession.session_id}`}>Added</button></td>
                    }



                                                



                                            </tr>
                                        ))
                                        } 
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="bottomScrollerSponsers">
                                <div className="bottomScrollerSponsersHeading">
                                    2020 CONFERENCE SPONSORS
                                </div>
                                <div className="bottomScrollerSponsersBx">
                                {
                                    this.state.sectionLoader
                                    ?
                                    (
                                        <div className="sectionLoader">
                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                        </div>
                                    )
                                    :
                                    null
                                }

                                <Slider {...settings}>
                                { 
                                    this.state.sponsersImgList.map(sponsers => (
                                        <div className="" key={sponsers.exhibitor_id}>
                                            <Link to={`/vcd/attendee/exhibitorBooth/${sponsers.exhibitor_id}`}>
                                                <img src={sponsers.logo} alt=""/>
                                            </Link>
                                        </div>
                                        
                                    ))
                                    
                                }            
                                </Slider>

                                
                                </div>
                            </div>

                            <BottomNav />
                            
                            
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default TechnicalSessions;
