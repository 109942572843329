import React, { Component } from 'react';
import Axios from 'axios';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import '../Material.css';

class BoothContactTable extends Component {
    constructor() {
        super(...arguments);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.editSettings = { allowEditing: true, allowAdding: true, allowDeleting: true, newRowPosition: 'Top', mode:'Dialog' };
        this.editparams = { params: { popupHeight: '300px' } };
        this.validationRule = { required: true };
        this.orderidRules = { required: true, number: true };
        this.pageSettings = { pageCount: 5 };
        this.format = { type: 'dateTime', format: 'M/d/y hh:mm a' };
        this.state = {
            followupData: null,
            pageLoader : true,
            errorMessage :null,
            successMessage : null
          };
    }
    componentDidMount() {
        var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
        Axios.get(`${global.config.requestUrl}exhibitor/getFollowUps?exhibitorId=${userId}&eventId=${localStorage.getItem('eventId')}`, 
        {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
    )
    .then(res => {
        this.setState({
            followupData:res.data.data,
            pageLoader : false
        })
    })
    .catch(err => {
        console.log(err);
    });
   

          
      }
    actionBegin(args) {
        if(args.requestType === 'save' && (args.action === 'add' || args.action === 'edit')){
            this.setState({
                pageLoader : true,
                errorMessage : null,
                successMessage : null
            })
            var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
            
            const newFollowup = {
                followUpId:args.data.followUpId,
                eventId:localStorage.getItem('eventId'),
                memberId:userId,
                title: args.data.title,
                status:0
                
            }
            Axios.post(`${global.config.requestUrl}exhibitor/insertOrUpdateFollowUps`, newFollowup,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode){
                    this.setState({
                        errorMessage : res.data.errorMessage
                    })
                }else{
                    this.setState({
                        successMessage : res.data.errorMessage
                    })
                     
                }
                this.componentDidMount();
            })
            .catch(err => {
                console.log(err);
            }); 

        }

        if(args.requestType === 'delete'){
            this.setState({
                pageLoader : true,
                errorMessage : null,
                successMessage : null
            })
            var userId =  localStorage.getItem('userId');
            if(localStorage.getItem('exhibitorId')){
               userId =  localStorage.getItem('exhibitorId');
            }
           
            const newFollowup = {
                followUpId:args.data[0].followUpId,
                eventId:localStorage.getItem('eventId'),
                memberId:userId,
                title: args.data[0].title,
                status:1
            }
            Axios.post(`${global.config.requestUrl}exhibitor/insertOrUpdateFollowUps`, newFollowup,
                {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
            )
            .then(res => {
                if(res.data.errorCode){
                    this.setState({
                        errorMessage : res.data.errorMessage
                    })
                }else{
                    this.setState({
                        successMessage : res.data.errorMessage
                    })
                     
                }
                this.componentDidMount();
            })
            .catch(err => {
                console.log(err);
            }); 

        }

        if (args.requestType === 'save') {
            if (this.gridInstance.pageSettings.currentPage !== 1 && this.gridInstance.editSettings.newRowPosition === 'Top') {
                args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - this.gridInstance.pageSettings.pageSize;
            }
            else if (this.gridInstance.editSettings.newRowPosition === 'Bottom') {
                args.index = (this.gridInstance.pageSettings.currentPage * this.gridInstance.pageSettings.pageSize) - 1;
            }
        }
    }
    ddChange() {
        this.gridInstance.editSettings.newRowPosition = this.dropDownInstance.value;
    }
    render() {
        return (<div className='control-pane'>
        <div className='control-section'>
          <div className='gridSecWrp'>
          <div className="success">
                  {this.state.successMessage}
              </div>
              <div className="error">
                  {this.state.errorMessage}
              </div>
                    {
                        this.state.pageLoader
                        ?
                        (
                            <div className="loaderWrp">
                                <div className="loaderInr">
                                    <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                </div>
                            </div>
                        )
                        :
                        null
                    }
            <GridComponent dataSource={this.state.followupData} ref={grid => this.gridInstance = grid} toolbar={this.toolbarOptions} allowPaging={true} editSettings={this.editSettings} pageSettings={this.pageSettings} actionBegin={this.actionBegin.bind(this)}>
                <ColumnsDirective>
                    <ColumnDirective field='followUpId' headerText='followUp ID' width='120' textAlign='Right' isPrimaryKey={true} hideAtMedia></ColumnDirective>
                    <ColumnDirective field='title' headerText='Follow Up' validationRules={this.validationRule}></ColumnDirective>
                </ColumnsDirective>
              <Inject services={[Page, Toolbar, Edit]}/>
            </GridComponent>
          </div>
      </div>
      </div>);
    }
}
export default  BoothContactTable;