import React, { Component } from "react";

import jwt_decode from "jwt-decode";
import LeftNavBar from "./LeftNavBar";
import SessionsAndPostersEdit from "./SessionsAndPostersEdit";
import Axios from "axios";
import { Link } from "react-router-dom";
import ChatIcon from "../../../TmChat/csda/chat/ChatIcon";

class DashboardSpeaker extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      pageLoader: true,
      sectionLoader: true,
      showChatIcon: false,
      sessionLists: [],

      resetSuccess: "",
      resetMessage: "",
      watchDemoModal: false,
      showChatIcon:true,
    };
  }
  
  componentDidMount() {
  }

  

  
  render() {
    return (
      <React.Fragment>
        <section className="bg7 signleScreen">
          <div className="dashboardWrapper">
            <LeftNavBar />
            <div className="rightContentAera">
              
              
              
                <SessionsAndPostersEdit />

                {
                                 this.state.showChatIcon
                                 ?
                                 <div className="chatIcon">
                                    <Link to="/chat" target='_blank'>
                                        <ChatIcon/>
                                         <img src={process.env.PUBLIC_URL + '/images/chat-ico.png'} alt="" />
                                    </Link>
                                 </div>
                                 :
                                 null
                             }
              
            </div>
          </div>
        </section>
        
      </React.Fragment>
    );
  }
}
export default DashboardSpeaker;
