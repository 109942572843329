import React, { Component, useState } from "react";
import Axios from 'axios';
import Moment from 'moment';

export default class CustomDateFilter extends Component {
    constructor(props) {
        super(props);
 
        this.state = {
            filterStartDate: "All",
            dateList:[],
        }
    }

    componentDidMount(){
        this.getSessionDates();
    }


 
    doesFilterPass(params) {
        return params.data.start_date === this.state.filterStartDate;
    }

    getSessionDates = () => {
        /* date list */

        Axios.get(`${global.config.requestUrl}getSessionDates?eventId=${localStorage.getItem('eventId')}`
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                var dateList = [];
        
            res.data.data.forEach((element) => {
                if(element.dates !== null){
                    dateList.push(element.dates);
                }
              });
            this.setState({dateList:dateList});
            }
        })
        .catch(err => {
            console.log(err);
        });

    }
 
    isFilterActive() {

        if(this.state.filterStartDate === 'All'){
            return 0;
        }else{
            return 1;
        }
        
        
    }
 
    // this example isn't using getModel() and setModel(),
    // so safe to just leave these empty. don't do this in your code!!!
    getModel() {
    }
 
    setModel() {
    }
 
    onfilterStartDateChange(event) {
        this.setState({filterStartDate: event.target.value},
            () => this.props.filterChangedCallback()
        );
    }
 
    render() {
        return (
            <select name="filterStartDate" onChange={this.onfilterStartDateChange.bind(this)}>
                <option  value="All" defaultValue={this.state.filterStartDate === 'All'}>All</option>
                { 
                                                  this.state.dateList.map(datedata => (
                                                    <option value={Moment(datedata).format('MM/DD/YYYY')} key={datedata}>{Moment(datedata).format('MM/DD/YYYY')}
                                                    </option>
                                                   ))
                                                }
            </select>
        );
    }
 }