import React, { Component } from 'react'
import BottomNav from './BottomNav'
import { Link } from "react-router-dom";
import Axios from 'axios';

export default class TrackList extends Component {
    constructor(){
        super();
        this.state={
            trackListData:[],
            pageLoader:true,
            
        }
    }
    
          
    componentDidMount(){
 
    Axios.get(`${global.config.requestUrl}user/getTrackList?eventId=${localStorage.getItem('eventId')}`,
    {headers: { "auth-token": localStorage.exhibitorToken }}
    )
    
    .then(res => {

        if(res.data.errorCode === 0){
            this.setState({trackListData:res.data.data})
        }
        this.setState({pageLoader:false});
    })
    .catch(err => {
        console.log(err);
    });

    

}
    render() {
        return (
            <React.Fragment>
                <section className="bg12 signleScreen paddingTop">
                {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="trackListWrp">
                                    <div className="trackBtn">
                                        <a href="/">View All Sessions <i className="fa fa-long-arrow-right"></i></a>
                                    </div>
                                    <div className="trackList">

                                            { 
                                                this.state.trackListData.map(trackData => (
                                                        <div className="trackBx" key={trackData.track_id}>
                                                            <Link to={`/vcd/attendee/conferenceCenterDetails/${trackData.track_id}`}>{trackData.track_name}</Link>
                                                        </div>
                                                ))
                                            }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BottomNav />
                </section>
            </React.Fragment>
        )
    }
}
