import  moment from 'moment';

let dateTimeFormat=function () {

};
dateTimeFormat.prependZeros = function(string) {
    if (string.length < 2) {
        return "0" + string;
    }
    return string;
};
dateTimeFormat.getCurrentDate = function(){
    var d = moment().toDate();
    var year = d.getFullYear().toString();
    var month = dateTimeFormat.prependZeros(parseInt(dateTimeFormat.prependZeros(d.getMonth().toString())) + 1);
    var day = dateTimeFormat.prependZeros(d.getDate().toString());
    var hours = dateTimeFormat.prependZeros(d.getHours().toString());
    var minutes = dateTimeFormat.prependZeros(d.getMinutes().toString());
    var seconds = dateTimeFormat.prependZeros(d.getSeconds().toString());

    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
};

dateTimeFormat.getDate = function(createdAt,isMonth){
    var date = moment(createdAt).toDate();
    date = dateTimeFormat.getTimeZoneDate(date);
    var month = date.toLocaleString("en-US", { month: "long"});
    var currentDate = dateTimeFormat.prependZeros(date.getDate().toString());
    var time = dateTimeFormat.prependZeros(date.getHours().toString())+":"+dateTimeFormat.prependZeros(date.getMinutes().toString());
    return (isMonth)?(month+" "+currentDate+"  "+time) : (time);
};
dateTimeFormat.getDateSeprator = function(createdAt){
    var date = moment(createdAt).toDate();
    date = dateTimeFormat.getTimeZoneDate(date);
    var month = date.toLocaleString("en-US", { month: "long"});
    var currentDate = dateTimeFormat.prependZeros(date.getDate().toString());
    var currentFullDate = dateTimeFormat.getCurrentDate();
    currentFullDate = dateTimeFormat.getTimeZoneDate(currentFullDate);
    var currentFullDateString = dateTimeFormat.prependZeros(currentFullDate.getDate().toString())+"/"+dateTimeFormat.prependZeros(currentFullDate.getMonth().toString())+"/"+dateTimeFormat.prependZeros(currentFullDate.getFullYear().toString());

    var yesterDay = dateTimeFormat.getTimeZoneDate(moment((moment(dateTimeFormat.getCurrentDate()).toDate()).valueOf() - 1000*60*60*24).toDate());
    var yesterDayFullDateString = dateTimeFormat.prependZeros(yesterDay.getDate().toString())+"/"+dateTimeFormat.prependZeros(yesterDay.getMonth().toString())+"/"+dateTimeFormat.prependZeros(yesterDay.getFullYear().toString());
    if(currentFullDateString === (dateTimeFormat.prependZeros(date.getDate().toString())+"/"+dateTimeFormat.prependZeros(date.getMonth().toString())+"/"+dateTimeFormat.prependZeros(date.getFullYear().toString()))){
        return "TODAY";
    }else if(yesterDayFullDateString === (dateTimeFormat.prependZeros(date.getDate().toString())+"/"+dateTimeFormat.prependZeros(date.getMonth().toString())+"/"+dateTimeFormat.prependZeros(date.getFullYear().toString()))){
        return "YESTERDAY";
    }

    if(date.getFullYear() === moment(currentFullDate).toDate().getFullYear()){
        return month+" "+currentDate;
    }else{
        return date.getFullYear()+", "+month+" "+currentDate;
    }
};

dateTimeFormat.getDateDiff =function(date) {
    var d1 = moment(date).toDate();
    date = dateTimeFormat.getTimeZoneDate(d1);


    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = Math.floor(seconds / 31536000);

    if (interval > 1) {
        return interval + " years ago";
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + " months ago";
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + " days ago";
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + " hours ago";
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
}
dateTimeFormat.convertDateToUTC = function(date){
    var newDate = moment(date).toDate();
    return moment(newDate.getTime() + (-330) * 60000).toDate();
};
dateTimeFormat.localize = function(date)
{
    // Use moment to parse your UTC date
    var localTime  = moment.utc(moment(date).format('YYYY-MM-DD HH:mm:ss')).toDate();
    localTime = moment(moment(localTime).format('YYYY-MM-DD HH:mm:ss')).toDate();
    return localTime;
};

dateTimeFormat.getTimeZoneDate = function(date){
    return dateTimeFormat.localize(dateTimeFormat.convertDateToUTC(date));
};

export default dateTimeFormat;