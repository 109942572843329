import CattleCall from "Cattle-Call-SDK";
import cattleCallChat from "./cattleCall";
import utility from "./utility";

//let url='https://api.tslvirtualconference.com:3000';
//let url='https://localhost:8081/api';
let url='https://tslvirtual-chat.herokuapp.com';

async function updateUser(userDetails,connection){
    return  new Promise(async (resolve) => {
        let requestOptions={ "user_id":userDetails.userId,'role':userDetails.role.toString()};
        const userDetailsRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json','auth-token': localStorage.getItem('exhibitorToken')  },
            body: JSON.stringify(requestOptions)
        };
        const getDetailsResponse =  await fetch(url+'/troop-messenger/get-user-details', userDetailsRequestOptions);
        let getDetails = await getDetailsResponse.json();
        if(getDetails && getDetails.data && !Object.keys(getDetails.data).length){
            let tm_user_id='';
            userDetails.uid=utility.generateUserUid(userDetails);
            if((!userDetails.tm_user_id) && connection) {
                userDetails.role=userDetails.role.toString();
                let tmDetails = await connection.register(userDetails.uid, userDetails.name);
                tm_user_id=tmDetails.tm_user_id;
                requestOptions['tm_user_id']= tm_user_id;
            }else{
                tm_user_id=userDetails.tm_user_id;
                requestOptions['tm_user_id']= userDetails.tm_user_id;
            }
            let cattlecallAdmin=new CattleCall(1,"L0gJS27u4pHxwbh5L0gJS27u4pHxwbh5","HGupYTsKbiJWZSDOqw3O01ajKAgNUTnf");
            cattlecallAdmin.onReady=(async ()=>{
                let cattleCallResponse= await cattleCallChat.addUser({'name':userDetails.name},cattlecallAdmin);
                requestOptions['cattle_call_id']=cattleCallResponse.user_id;
                requestOptions['role']=userDetails.role;
                const userDetailsRequestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json', 'auth-token': localStorage.getItem('exhibitorToken') },
                    body: JSON.stringify(requestOptions)
                };
               let addResponse=await fetch(url+'/troop-messenger/add-user', userDetailsRequestOptions);
                  if(addResponse){
                      addResponse=addResponse.json();
                      if(addResponse.errorCode===1){
                          cattleCallResponse.user_id=addResponse.cattle_call_id;
                      }
                  }
                cattlecallAdmin=null;
                return  resolve({'tm_user_id':tm_user_id,'cattle_call_id':cattleCallResponse.user_id,uid:userDetails.uid})
            });
        }else{
            getDetails.data.uid=utility.generateUserUid({'userId':getDetails.data.user_id,'role':getDetails.data.roles});
            return  resolve(getDetails.data);
        }
    });
}
async function getUsersDetails(userIds){
    return  new Promise(async (resolve)=>{
        let requestOptions={ "user_ids":userIds};
        const userDetailsRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(requestOptions)
        };
        const getDetailsResponse =  await fetch(url+'/troop-messenger/get-users', userDetailsRequestOptions);
        let getDetails = await getDetailsResponse.json();
        return  resolve(getDetails.data);
    });
}
async function getUserDetail(options){
    return  new Promise(async (resolve)=>{
        const userDetailsRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(options)
        };
        const getDetailsResponse =  await fetch(url+'/troop-messenger/get-user-based-on-id', userDetailsRequestOptions);
        let getDetails = await getDetailsResponse.json();
        return  resolve(getDetails.data);
    });
}
async function addMeeting(options){
    return  new Promise(async (resolve)=>{
        options['user_id']=localStorage.getItem('userId');
        options['role']=localStorage.getItem('currentRole');
        options['event_id']=localStorage.getItem('eventId');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(options)
        };
        const getDetailsResponse =  await fetch(url+'/troop-messenger/add-meeting', requestOptions);
        let getDetails = await getDetailsResponse.json();
        return  resolve(getDetails);
    });
}
async function getSessionRoomInfo(options){
    return  new Promise(async (resolve)=>{
        options['user_id']=localStorage.getItem('userId');
        options['role']=localStorage.getItem('currentRole');
        options['event_id']=localStorage.getItem('eventId');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(options)
        };
       // const getDetailsResponse =  await fetch('https://localhost:8081/api/troop-messenger/get-session-details', requestOptions);
        const getDetailsResponse =  await fetch(url+'/troop-messenger/get-session-details', requestOptions);
        let getDetails = await getDetailsResponse.json();
        return  resolve(getDetails);
    });
}
async function updateMeeting(options){
    return  new Promise(async (resolve)=>{
        options['user_id']=localStorage.getItem('userId');
        options['role']=localStorage.getItem('currentRole');
        options['event_id']=localStorage.getItem('eventId');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(options)
        };
        const getDetailsResponse =  await fetch(url+'/troop-messenger/update-meeting', requestOptions);
        let getDetails = await getDetailsResponse.json();
        return  resolve(getDetails);
    });
}
async function getMeetings(limit,offset){
    return  new Promise(async (resolve)=>{
        let options={};
        options['user_id']=localStorage.getItem('userId');
        options['role']=localStorage.getItem('currentRole');
        options['event_id']=localStorage.getItem('eventId');
         if(limit && offset){
             options['limit']=limit;
             options['offset']=offset;
         }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(options)
        };
        const sendRequest =  await fetch(url+'/troop-messenger/meetings-list', requestOptions);
        let response = await sendRequest.json();
        return  resolve(response);
    });
}
async function getMeetingDetails(options){
    return  new Promise(async (resolve)=>{

        options['user_id']=localStorage.getItem('userId');
        options['role']=localStorage.getItem('currentRole');
        options['event_id']=localStorage.getItem('eventId');

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(options)
        };
        const sendRequest =  await fetch(url+'/troop-messenger/meeting-details', requestOptions);
        let response = await sendRequest.json();
        return  resolve(response);
    });
}
async function getMeetingUsers(meetingId){
    return  new Promise(async (resolve)=>{
        let options={};
        options['meeting_id']=meetingId
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
            body: JSON.stringify(options)
        };
        const sendRequest =  await fetch(url+'/troop-messenger/meeting-users', requestOptions);
        let response = await sendRequest.json();
        return  resolve(response);
    });
}
async function resetMeeting(data){
    return  new Promise(async (resolve)=>{
       data['eventId']=localStorage.getItem('eventId');
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' ,'auth-token': localStorage.getItem('exhibitorToken') },
        };
        const sendRequest =  await fetch(url+`/troop-messenger/reset-session?sessionId=${data.sessionId}&eventId=${data.eventId}&speakerId=${data.speakerId}`, requestOptions);
        let response = await sendRequest.json();
        return  resolve(response);
    });
}
const tmChatServer ={resetMeeting,updateUser,getUsersDetails,getMeetingDetails,getUserDetail,addMeeting,getMeetings,getMeetingUsers,updateMeeting,getSessionRoomInfo};
export  default tmChatServer;