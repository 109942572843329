import React, { Component } from 'react';
import LeftNavBar from './LeftNavBar';


class SessionAttendees extends Component {
    constructor(props){
        super(props);

        this.state = {

        }

    }

    componentDidMount(){
        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            if(rolesArr.indexOf('-6') == -1){
                this.props.history.push('/vcd/notfound');
            }
        }
    }

    render() {


        return (
            <React.Fragment>
                <section className="bg1 signleScreen">
                    <LeftNavBar />
                    <div className="container pt-120">
                        <div className="row">
                            <div className="col-12">
                                <div className="sessionTableList">
                                    <h3>List of Sessions</h3>
                                    <table>
                                        <tr>
                                            <td>
                                                <input type="checkbox" name="" id="id1"/>
                                                <label htmlFor="id1">Session 1</label>
                                            </td>
                                            <td className="text-right"><img src={process.env.PUBLIC_URL + '/images/excel.png'} alt="" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" name="" id="id2"/>
                                                <label htmlFor="id2">Session 1</label>
                                            </td>
                                            <td className="text-right"><img src={process.env.PUBLIC_URL + '/images/excel.png'} alt="" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" name="" id="id3"/>
                                                <label htmlFor="id3">Session 1</label>
                                            </td>
                                            <td className="text-right"><img src={process.env.PUBLIC_URL + '/images/excel.png'} alt="" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" name="" id="id4"/>
                                                <label htmlFor="id4">Session 1</label>
                                            </td>
                                            <td className="text-right"><img src={process.env.PUBLIC_URL + '/images/excel.png'} alt="" /></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <input type="checkbox" name="" id="id5"/>
                                                <label htmlFor="id5">Session 1</label>
                                            </td>
                                            <td className="text-right"><img src={process.env.PUBLIC_URL + '/images/excel.png'} alt="" /></td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </section>
            </React.Fragment>
        )
    }
}
export default SessionAttendees;