import React, { Component } from 'react'

export default class NotFound extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="brokenLink">
                    <img src={process.env.PUBLIC_URL + '/images/error-page.jpg'} alt="" />
                </div>
            </React.Fragment>
        )
    }
}
