import React, { Component } from 'react';
import Axios from 'axios';

import {Link} from 'react-router-dom';
import Slider from "react-slick";



class LoginSplash extends Component {
    constructor(props){
        super(props);

        this.state = {
            sponsersImgList : [],
            sponsersUrl:"",
            pageUrlVarify: false,
            pageLoader:true,
            allowAsExhibitor:false,
            allowAsSpeaker : false,
            allowAsLobbier : true,
        }
        
    }




    componentDidMount(){
        document.getElementById("profile_img").src = '/images/placeholder-profile.jpg';
    if(localStorage.getItem('roles')){
        var rolesArr = localStorage.getItem('roles').split(",");
        if(rolesArr.indexOf('-1') !== -1){
          this.setState({allowAsExhibitor:true});
        }
        // If you want to show speaker dashboard, please uncomment this below line
        // if(rolesArr.indexOf('-6') !== -1){
        //   this.setState({allowAsSpeaker:true});
        // } 
        if(rolesArr.indexOf('-7') !== -1){
          this.setState({allowAsExhibitor:true});
        } 
    }

      
        
       Axios.get(`${global.config.requestUrl}exhibitor?eventId=${localStorage.getItem('eventId')}&type=2`)
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({pageUrlVarify : true, pageLoader:false,sponsersImgList:res.data.data, sponsersUrl:res.data.logo })
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({pageLoader:false})
            }
            
            /* console.log(res.data.data) */
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
        
    }
   


    render() {
        var slideToShow = 5
        if(this.state.sponsersImgList.length < 5){
            slideToShow = this.state.sponsersImgList.length
        }
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: slideToShow,
            slidesToScroll: 1,
            autoplay: true,
          };
            return (
                <React.Fragment>
               
                    <section className="bg2 signleScreen overflow-hidden">
                            {
                               this.state.pageLoader
                               ?
                               (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                               )
                               :
                               <div className="flexWrpClass">
                                    <div className="topLeftSponsers">
                                            <h2 className="levelHeading">SILVER<span>LEVEL</span></h2>
                                            <ul>
                                                {
                                                    this.state.sectionLoader
                                                    ?
                                                    (
                                                        <div className="sectionLoader">
                                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                        </div>
                                                    )
                                                    :
                                                    null
                                                }
                                                { 
                                                    this.state.sponsersImgList.map(sponsers => (

                                                        <div key={sponsers.exhibitor_id}>
                                                        {sponsers.logo_level === 3
                                                            ?
                                                            
                                                                sponsers.exhibitor_id === 0
                                                                ?
                                                                (
                                                                <a href={sponsers.sponsor_link} target="_blank">
                                                                    <img src={sponsers.logo} alt=""/>
                                                                </a>
                                                                )
                                                                :
                                                                
                                                                    <img src={sponsers.logo} alt=""/>
                                                             
                                                            
                                                            :
                                                            null 
                                                        }
                                                        </div>
                                                    ))
                                                }                          
                                            </ul>
                                        </div>
                                    <div className="splashWrp">
                                    <div className="topCenterSponsor"> 
                                        <h2 className="levelHeading">GOLD<span>LEVEL</span></h2>
                                        <ul>
                                            {
                                                this.state.sectionLoader
                                                ?
                                                (
                                                    <div className="sectionLoader">
                                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                    </div>
                                                )
                                                :
                                                null
                                            }
                                            { 
                                                this.state.sponsersImgList.map(sponsers => (
                
                                                    <div key={sponsers.exhibitor_id}>
                                                    {sponsers.logo_level === 1
                                                        ?
                                                        
                                                            sponsers.exhibitor_id === 0
                                                            ?
                                                            (
                                                            <a href={sponsers.sponsor_link} target="_blank">
                                                                <img src={sponsers.logo} alt=""/>
                                                            </a>
                                                            )
                                                            :
                                                            
                                                                <img src={sponsers.logo} alt=""/>
                                                         
                                                        
                                                        :
                                                        null 
                                                    }
                                                    </div>
                                                ))
                                            }                            
                                        </ul>
                                        
                                    </div>
                                            <div className="splashListWrp">
                                                <div className="tp_logo">
                                                    <img src={process.env.PUBLIC_URL + '/images/paos/paos_banner.jpg'} alt="" />
                                                </div>
                                                { 
                                                this.state.allowAsSpeaker
                                                ?
                                                (
                                                <div className="splashList">
                                                    <div className="splashBx">
                                                        <Link to="/paos/speaker/dashboard">
                                                            <img src={process.env.PUBLIC_URL + '/images/splashSpeaker.png'} alt="" />
                                                            <h3>Speaker Dashboard</h3>
                                                        </Link>
                                                    </div>
                                                </div> 
                                                )
                                                :
                                                null
                                                }
                                                { 
                                                this.state.allowAsExhibitor
                                                ?
                                                (
                                                    <div className="splashList">
                                                    <div className="splashBx">
                                                        <Link to="/paos/exhibitor/dashboard">
                                                            <img src={process.env.PUBLIC_URL + '/images/splashExhibit.png'} alt="" />
                                                            <h3>Exhibitor Dashboard</h3>
                                                        </Link>
                                                    </div>
                                                </div>
                                                )
                                                :
                                                null
                                                }
                                                { 
                                                this.state.allowAsLobbier
                                                ?
                                                ( 
                                                <div className="splashList">
                                                    <div className="splashBx">
                                                        <Link to="/paos/attendee/lobby">
                                                            <img src={process.env.PUBLIC_URL + '/images/splashAtandee.png'} alt="" />
                                                            <h3>Attendee Lobby</h3>
                                                        </Link>
                                                    </div>
                                                </div>
                                                )
                                                :
                                                null
                                                }
                                            </div>
                                        </div>
                                        <div className="topRightSponsers">
                                            <h2 className="levelHeading">BRONZE<span>LEVEL</span></h2>
                                            <ul>
                                                {
                                                    this.state.sectionLoader
                                                    ?
                                                    (
                                                        <div className="sectionLoader">
                                                            <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                                        </div>
                                                    )
                                                    :
                                                    null
                                                }
                                                { 
                                                    this.state.sponsersImgList.map(sponsers => (

                                                        <div key={sponsers.exhibitor_id}>
                                                        {sponsers.logo_level === 4
                                                            ?
                                                            
                                                                sponsers.exhibitor_id === 0
                                                                ?
                                                                (
                                                                <a href={sponsers.sponsor_link} target="_blank">
                                                                    <img src={sponsers.logo} alt=""/>
                                                                </a>
                                                                )
                                                                :
                                                                
                                                                    <img src={sponsers.logo} alt=""/>
                                                                
                                                            
                                                            :
                                                            null 
                                                        }
                                                        </div>
                                                    ))
                                                }                            
                                            </ul>
                                            
                                        </div>
                                </div>
                            }
                            
                            
                    </section>
                </React.Fragment>
            )
        
        
         
    }
}
export default LoginSplash;
