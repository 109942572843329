import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactStars from "react-rating-stars-component";
import Axios from "axios";

class SessionEvaluation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sessionEvaluationModal: false,
      pageLoader: false,
      vote: null,
      comment: "",
      listId: "",
      msg: "",
      surveySessionId: null,
    };
  }

  sessionEvaluationModalHandler = async (sessionId) => {
    await this.getFilledSessionSurvey();
    this.setState({
      surveySessionId: sessionId,
      sessionEvaluationModal: true,
      msg: "",
    });
  };

  handleClose = () => {
    this.setState({ sessionEvaluationModal: false });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  getFilledSessionSurvey = () => {
    this.setState({ pageLoader: true });
    const surveyData = {
      regId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      sessionId: this.props.data.session_id,
      role: localStorage.getItem("currentRole"),
    };

    Axios.post(
      `${global.config.requestUrl}user/getUserSessionSurveyData`,
      surveyData,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        if (res.data.errorCode === 0) {
          this.setState({
            vote: res.data.data[0].vote,
            comment: res.data.data[0].comment,
            listId: res.data.data[0].list_id,
          });
        }

        this.setState({ pageLoader: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ pageLoader: false });
      });
  };

  ratingChanged = (newRating) => {
    this.setState({
      vote: newRating,
    });
  };

  submitSurvey = (e) => {
    e.preventDefault();
    this.setState({ pageLoader: true });

    const surveyData = {
      visitorId: localStorage.getItem("userId"),
      eventId: localStorage.getItem("eventId"),
      sessionId: this.props.data.session_id,
      role: localStorage.getItem("currentRole"),
      vote: this.state.vote,
      comment: this.state.comment,
      listId: this.state.listId,
    };

    Axios.post(
      `${global.config.requestUrl}user/insertUserSessionSurvey`,
      surveyData,
      { headers: { "auth-token": localStorage.getItem("exhibitorToken") } }
    )
      .then((res) => {
        this.setState({ pageLoader: false, msg: res.data.errorMessage });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const sessId = this.props.data.session_id;
    return (
      <React.Fragment>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => this.sessionEvaluationModalHandler(sessId)}
        >
          Evaluation
        </div>

        <Modal
          show={this.state.sessionEvaluationModal}
          size="lg"
          animation={false}
          onHide={() => this.handleClose}
        >
          {this.state.pageLoader ? (
            <div className="loaderWrp">
              <div className="loaderInr">
                <img
                  src={process.env.PUBLIC_URL + "/images/loader.gif"}
                  alt=""
                />
              </div>
            </div>
          ) : null}
          <div className="midModal verticleCenterModal">
            <div
              className="outerModal"
              onClick={() => this.handleClose()}
            ></div>
            <div className="midContentWrp">
              {/* <Modal.Header closeButton className=""> */}
              <div
                className="modalPopUpClose"
                onClick={() => this.handleClose()}
              >
                <i className="fa fa-times"></i>
              </div>
              <h3>Session Evaluation</h3>
              <button
                type="button"
                className="close"
                onClick={() => this.handleClose()}
              ></button>
              {/* </Modal.Header> */}
              {/* <Modal.Body className="midContentBx"> */}

              <div className="midContentBx">
                <div className="surveyForm">
                  <form onSubmit={this.submitSurvey}>
                    {this.state.msg !== "" ? <h3>{this.state.msg}</h3> : null}
                    <label className="mb-0">1. Please rate this session.</label>
                    <ReactStars
                      count={5}
                      onChange={this.ratingChanged}
                      size={40}
                      isHalf={false}
                      key={this.state.vote}
                      value={this.state.vote}
                      emptyIcon={<i className="far fa-star"></i>}
                      halfIcon={<i className="fa fa-star-half-alt"></i>}
                      fullIcon={<i className="fa fa-star"></i>}
                      activeColor="#ffd700"
                    />
                    <div className="form-group mt-2">
                      <label>2. Your thoughts about the session.</label>
                      <textarea
                        className="form-control"
                        rows="5"
                        onChange={this.onChange}
                        value={this.state.comment}
                        name="comment"
                      ></textarea>
                      <input
                        type="hidden"
                        ref={this.sessId}
                        id="surveyFormSessionId"
                      />
                    </div>
                    <div className="form-group text-center">
                      <button className="btn btn-warning" type="submit">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              {/* </Modal.Body> */}
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default SessionEvaluation;
