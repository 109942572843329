import React, { Component } from 'react'
import MySchedule from './MySchedule'
import {Link} from "react-router-dom";


export default class MainNav extends Component {
    constructor(){
        super();
        this.state={
            myScheduleModal:false,
            mainNavToggleClass:"centerNav"
        }
    }
    myScheduleModalHandler = (e) => {
        e.preventDefault();
        this.setState({myScheduleModal:!this.state.myScheduleModal})
    }
    mobileMenuHandler = (e) => {
        this.setState({mainNavToggleClass: "centerNav showMenu"})
    }
    closeMobileMenu = (e) => {
        this.setState({mainNavToggleClass: "centerNav"})
    }
    render() {
        return (
            <React.Fragment>
                <div className="mobileMenu" onClick={this.mobileMenuHandler}>
                    <img src={process.env.PUBLIC_URL + '/images/right-arrow-angle.png'} alt="" />
                </div>
                <div className={this.state.mainNavToggleClass}>
                    <div className="modalPopUpClose mobile" onClick={this.closeMobileMenu}>
                        <i className="fa fa-times"></i>
                    </div>
                    <ul>
                        <li>
                            <Link to="/ihca-als2021/attendee/lobby">
                                <img src={process.env.PUBLIC_URL + '/images/ihca-als2021/bottom-nav-lobby.png'} alt="" />
                                <span onClick={this.closeMobileMenu}>Lobby</span>
                            </Link>
                        </li>
                        {/* <li>
                            <Link to="/ihca-als2021/attendee/exhibitHall">
                                <img src={process.env.PUBLIC_URL + '/images/ihca-als2021/bottom-nav-exhibit-hall.png'} alt="" />
                                <span onClick={this.closeMobileMenu}>Exhibit Hall</span>
                            </Link>
                        </li> */}
                        <li>
                            {<Link to="/ihca-als2021/attendee/technicalSessions">
                                <img src={process.env.PUBLIC_URL + '/images/ihca-als2021/bottom-nav-conference-hall.png'} alt="" />
                                <span onClick={this.closeMobileMenu}>Conference Center</span>
                            </Link>}
                        </li>
                        <li>
                            <Link to="/ihca-als2021/attendee/networkingLounge">
                                <img src={process.env.PUBLIC_URL + '/images/ihca-als2021/bottom-nav-networking-lounge.png'} alt="" />
                                <span onClick={this.closeMobileMenu}>Networking lounge</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/" onClick={this.myScheduleModalHandler}>
                                <img src={process.env.PUBLIC_URL + '/images/ihca-als2021/schedule_button.png'} alt="" />
                                <span onClick={this.closeMobileMenu}>My Schedule</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                {
                    this.state.myScheduleModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" id="myschedulepopup" onClick={this.myScheduleModalHandler}><i className="fa fa-times"></i></div>
                            <MySchedule props={this.props} />                             
                        </div>
                    </div>
                    :
                    null
                }
            </React.Fragment>
        )
    }
}
