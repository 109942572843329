import React, { Component } from 'react';
import BottomNav from './BottomNav'
import Axios from 'axios';
import {Link} from 'react-router-dom';
import SessionChatRoom from "../../../TmChat/csda/sessionChatRoom/sessionChatRoom";


import AboutSession from './AboutSession';
import AboutSpeaker from './AboutSpeaker';
import SessionDocuments from './SessionDocuments';
import jwt_decode from 'jwt-decode';



class ConferenceCenterPage extends Component {
    constructor(props){
        super(props);
        var dateNow = new Date();
        this.state={
            sponsersImgList:[],
            sessionBannerList:[],
            mediaPartnerList:[],
            producedByList:[],
            sectionLoader:true,
            aboutSessionModal:false,
            aboutSpeakerModal:false,
            sessionDocumentsModal:false,
            liveVideoUrl:[],
            liveVideoUrlNotFound:"",
            
            liveUrlNull:"",
            ip:'127.0.0.1',
            pageLoader:true,
            upgradeModal:false,
            paidUser:false,
            iframeLoader:true,
            sessionSurveyModal:false,
            timeSpendInSeconds:0,
            minAttendance:0, // in seconds
            
            mainConferenceClass:"conferenceLinks",

            timeWhenConfPageLoadedInSec: dateNow.getTime() / 1000,
            alreadySavedOnAutoLogout:0,
        }

        if(localStorage.getItem('roles')){
            var rolesArr = localStorage.getItem('roles').split(",");
            var currentRole = -10;

            if(rolesArr.length > 2 ){
                currentRole = -10;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') !== -1 && rolesArr.length < 3 ){
                currentRole = -1;
            }
            if(rolesArr.indexOf('-1') == -1 && rolesArr.indexOf('-7') == -1 && rolesArr.indexOf('-6') !== -1 && rolesArr.length == 1 ){
                currentRole = -6;
            }
            if((rolesArr.indexOf('-1') !== -1 || rolesArr.indexOf('-7') !== -1) && rolesArr.indexOf('-6') == -1 && rolesArr.length == 1 ){
                currentRole = -1;
            }
            if((rolesArr.indexOf('-1') !== -1 && rolesArr.indexOf('-7') !== -1) && rolesArr.length === 2  ){
                currentRole = -1;
            }
            localStorage.setItem('currentRole',currentRole);
            
        }

        const {match : {params}} = props;
        this.scannedData = {
            regId: localStorage.getItem('userId'),
            eventId: localStorage.getItem('eventId'),
            sessionId: 0,
            accountId:localStorage.getItem('accountId'),
            serialNumber:this.state.ip,
            duration: this.state.timeSpendInSeconds.toString(),
            role:localStorage.getItem('currentRole'),
        }
        this.token = localStorage.getItem('exhibitorToken');
        
    }

    componentWillUnmount = () => {
        this.insertScannedDataHere();
        console.log(this.state.timeSpendInSeconds);
         //if(this.state.timeSpendInSeconds >= this.state.minAttendance){
          // this.updateScannedData();
          //this.insertScannedDataHere();
         //}
    }
    sessionLinksMobileToggle = () =>{
        this.setState({mainConferenceClass:"conferenceLinks activeToggle"})
        if(this.state.mainConferenceClass === "conferenceLinks activeToggle" ){
            this.setState({mainConferenceClass:"conferenceLinks"})
        }
    }
    componentDidMount(){
        
        localStorage.setItem('conferenceClicked',0);
        const {match : {params}} = this.props;
        this.getSponsersLogos();
        this.onLoadingFn(this.props);
        this.insertScannedDataHere();
        fetch('https://geoip-db.com/json/')
           .then(res => res.json())
           .then(json => this.setState({ip: json.IPv4}));
    }


    componentWillReceiveProps(nextProps){
        localStorage.setItem('conferenceClicked',0);
        document.getElementById('myschedulepopup').click();
        this.onLoadingFn(nextProps);
        
   }
   
   onLoadingFn = (nextProps) =>{
    this.setState({pageLoader:true});
    const details = {
        username: localStorage.getItem('userName'),
        eventId:localStorage.getItem('eventId'),
        role:localStorage.getItem('currentRole'),
    }

    Axios.post(`${global.config.requestUrl}user/getUserDetails`, details,
            {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        .then(res => {
             if(res.data.errorCode === 0){
                 if(localStorage.getItem('currentRole') !== '-10' || res.data.data[0].reg_type !== 1442 ){
                    this.setState({paidUser:true})
                    this.getSessionData(nextProps);
                    this.updateGoldenTicketStatus(nextProps);
                    this.saveAuthForSurvey(nextProps);
                 }else{
                     this.setState({upgradeModal:true})
                 }
             }
             this.setState({pageLoader:false});
            
        })
        .catch(err => {
            console.log(err);
        });
    
}

   getSponsersLogos = () => {
    Axios.get(`${global.config.requestUrl}user/getSponsorsLogos?eventId=${localStorage.getItem('eventId')}&type=3`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data, mediaPartnerList:res.data.mediaPartner, producedByList:res.data.producedBy,sectionLoader:false});
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({sectionLoader:false});
            }
        })
        .catch(err => {
            console.log(err);
        });
}
   sessionSurveyModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionSurveyModal:!this.state.sessionSurveyModal,iframeLoader:true})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSessionModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSessionModal:!this.state.aboutSessionModal})
        localStorage.setItem('conferenceClicked',1)
    }
    aboutSpeakerModalHandler = (e) => {
        e.preventDefault();
        this.setState({aboutSpeakerModal:!this.state.aboutSpeakerModal})
        localStorage.setItem('conferenceClicked',1)
    }
    sessionDocumentsModalHandler = (e) => {
        e.preventDefault();
        this.setState({sessionDocumentsModal:!this.state.sessionDocumentsModal})
        localStorage.setItem('conferenceClicked',1)
    }
    hideIframeLoader = () => {
        this.setState({iframeLoader:false});
    }
    upgradePaymentIframeModelHandeler = (e) => {
        e.preventDefault();
        this.setState({upgradePaymentIframeModel:!this.state.upgradePaymentIframeModel,iframeLoader:true})
        if(this.state.upgradePaymentIframeModel){
            this.onLoadingFn();
        }
    }
    updateScannedData = () => {

        
        const {match : {params}} = this.props;
            const scannedData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                accountId:localStorage.getItem('accountId'),
                serialNumber:this.state.ip,
                duration: this.state.timeSpendInSeconds.toString(),
                role:localStorage.getItem('currentRole'),
            }

            //console.log(scannedData);return false;

            Axios.post(`${global.config.requestUrl}user/insertScanData`, scannedData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }


    insertScannedDataWhenAutoLogOut = () => {
        var eventId = localStorage.getItem('eventId');
        var eventName = localStorage.getItem('eventName');
        const {match : {params}} = this.props;
        this.scannedData.duration = this.state.timeSpendInSeconds.toString();
        this.scannedData.sessionId = params.sessionId;
        Axios.post(`${global.config.requestUrl}user/insertScanData`, this.scannedData,
                    {headers: { "auth-token": this.token }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }else{
                           
                            localStorage.clear();
                            window.location.href = window.location.protocol+'//'+window.location.host+'/'+eventName+'/login/'+eventId;
                         
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }

    insertScannedDataHere = () => {
        const {match : {params}} = this.props;
        this.scannedData.duration = this.state.timeSpendInSeconds.toString();
        this.scannedData.sessionId = params.sessionId;
        Axios.post(`${global.config.requestUrl}user/insertScanData`, this.scannedData,
                    {headers: { "auth-token": this.token }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }else{
                            
                         this.setState({timeSpendInSeconds: 0});
                        }
                })
                .catch(err => {
                    console.log(err);
                });
    }


    updateGoldenTicketStatus = (myprop) => {
            this.setState({pageLoader:true});
            const {match : {params}} = myprop;
            const ticketStatusData = {
                visitorId:localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                ticketValue:0,
                linkPage:'sessionRoom',
                sessionId:params.sessionId,
                exhibitorId:0
                
            }
            Axios.post(`${global.config.requestUrl}user/checkGoldenTicketUsers`, ticketStatusData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
            .then(res => {
            if(res.data.errorCode){
                document.getElementById('goldenTkt').classList.add("greyScale");
                this.setState({pageLoader:false});
            }else{
                this.setState({pageLoader:false});
                document.getElementById('goldenTkt').classList.remove("greyScale");
            }
        })
        .catch(err => {
            console.log(err);
        });
    }
    

    countDown = () => {
        
        /** This code will redirect the user to login splash page before token expires */
        const token = localStorage.exhibitorToken;
        const decoded = jwt_decode(token, {complete: true});
        var clkb = this.state.timeWhenConfPageLoadedInSec;
        this.setState({timeWhenConfPageLoadedInSec: clkb + 1});
        if(decoded.exp-10 <= this.state.timeWhenConfPageLoadedInSec  && this.state.alreadySavedOnAutoLogout === 0){
            //this.props.history.push(`/vcd/login/590`)
            this.setState({
                alreadySavedOnAutoLogout: 1
            }, () => {
                this.insertScannedDataWhenAutoLogOut();
            });
           
        }
       /** This code will save the scanned data if timespend is equal to the minattendance */
      if(this.state.timeSpendInSeconds == this.state.minAttendance && this.state.minAttendance !== 0 ){
        //this.updateScannedData();
        //this.insertScannedDataHere();  // Disabled this line because Tom want the session scanning entry only when someone leaves the session, but not when user achived the minattandance time
      }
      var clk = this.state.timeSpendInSeconds;
      this.setState({timeSpendInSeconds: clk + 1});
       
    }

    getSessionData = (myprop) => {
        this.setState({pageLoader:true});
        const {match : {params}} = myprop;
        Axios.get(`${global.config.requestUrl}user/getSessionVideo?eventId=${localStorage.getItem('eventId')}&sessionId=${params.sessionId}`,
        {headers: { "auth-token": localStorage.exhibitorToken }}
        )
        
        .then(res => {
            this.setState({liveVideoUrl:res.data.data, liveVideoUrlNotFound:res.data.errorCode, liveUrlNull:res.data.data[0].video_url, minAttendance:res.data.data[0].min_attendence*60, pageLoader:false})
            if(res.data.errorCode === 0){
                this.setState({})
                setInterval(this.countDown, 1000);

                if(res.data.data[0].video_url !== null){
                    if(res.data.data[0].video_url.includes('https://success.zoom.us/wc/join/')){
                        document.getElementById('videoconf').innerHTML = res.data.data[0].video_text+'<div class="hoverText"><p>Click below to view live webinar.  Password is csda2020</p><a target="_blank" href ="'+res.data.data[0].video_url+'" > Click here </a></div>';
                    }
                    if(res.data.data[0].video_url.includes('https://event.webinarjam.com/') || res.data.data[0].video_url.includes('https://global.gotomeeting.com/join/')){
                        document.getElementById('videoconf').innerHTML = res.data.data[0].video_text+'<h4>Click Here to Join the Session</h4><div class="hoverText"><a target="_blank" href ="'+res.data.data[0].video_url+'" > Click here </a></div>';
                    }
                }


                if(res.data.data[0].banner !== ''){
                    this.setState({sessionBannerList:[
                        {'logo':res.data.data[0].banner,'banner_link':res.data.data[0].banner_link},
                    ]});
                }else{
                    this.setState({sessionBannerList:[
                        {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','banner_link':null},
                    ]});
                }
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({pageLoader:false});
        });
    }


        saveAuthForSurvey = (myprop) => {
            const {match : {params}} = myprop;
            const authSurveyData = {
                regId: localStorage.getItem('userId'),
                eventId: localStorage.getItem('eventId'),
                sessionId: params.sessionId,
                role: localStorage.getItem('currentRole'),
            }

            Axios.post(`${global.config.requestUrl}user/insertSessionForSurvey`, authSurveyData,
                    {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
                )
                .then(res => {
                        if(res.data.errorCode){  // Error case
                            
                        }
                })
                .catch(err => {
                    console.log(err);
                });
        }

    render() {

            return (
                <React.Fragment>  
                    <section className="bg6 signleScreen paddingTop">
                        {
                            this.state.pageLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                        <div className="bxWrapper">
                            <div className="sessionCHatBx">
                                <div className="msgSession">Please return to this page for LIVE Q&A with the speaker</div>
                                <SessionChatRoom sessionId={this.props.match.params.sessionId} />
                            </div>
                        
                        <div className="conferenceVideoWrp">
                            {
                                this.state.liveVideoUrlNotFound !== 0
                                ?
                                <div className="conferenceVideo videoNotFound">
                                    {/* <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" /> */}
                                </div>
                                :
                                <React.Fragment>
                                    
                                    { 
                                        this.state.liveVideoUrl.map(videoLink => (
                                            <React.Fragment>
                                                <h3>{videoLink.session_name}</h3>
                                                {
                                                    this.state.liveUrlNull === null || this.state.liveUrlNull === ""
                                                    ?
                                                    <div className="conferenceVideo videoNotFound">
                                                        <img src={process.env.PUBLIC_URL + '/images/page-not-found.png'} alt="" />
                                                    </div>
                                                    :
                                                    <div className="conferenceVideo" id="videoconf">
                                                        {
                                                        videoLink.video_url.includes('https://success.zoom.us/wc/join/') ||
                                                        videoLink.video_url.includes('https://event.webinarjam.com') ||
                                                        videoLink.video_url.includes('https://global.gotomeeting.com')
                                                        ?
                                                        null
                                                        :
                                                        <iframe width="100%" height="315" src={`${videoLink.video_url}?rel=0`} key={videoLink.video_url} frameBorder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                                        }
                                                   </div>
                                                }
                                                
                                            </React.Fragment>
                                        ))
                                    }
                                
                                </React.Fragment>

                            }
                                
                      
                        </div>
                        
                        <div className={this.state.mainConferenceClass}>
                            {
                                window.innerWidth <= 767
                                ?
                                <h3 onClick={this.sessionLinksMobileToggle}>Session Information</h3>
                                :
                                <h3>Session Information</h3>
                            }
                            <ul>
                                <li><Link onClick={this.aboutSessionModalHandler} to="/"><i class="fa fa-info"></i> About this session</Link></li>
                                <li><Link onClick={this.aboutSpeakerModalHandler} to="/"><i class="fa fa-volume-up"></i> About the speaker</Link></li>
                                <li><Link onClick={this.sessionDocumentsModalHandler} to="/"><i class="fa fa-file"></i> Session documents</Link></li>
                                <li><Link onClick={this.sessionSurveyModalHandler} to="/"><i class="fa fa-copy"></i> Session survey</Link></li>
                            </ul>
                            
                            { 
                                this.state.sessionBannerList.map(banner => (
                                    <React.Fragment>
                                    
                                        <div key={banner.logo}>
                                        {
                                            banner.banner_link !== null
                                            ?
                                            <div className="sponsersCat">
                                            <div className="sponsersCatInr">
                                                <div className="sponsersCatBx" key={banner.logo}>
                                                    <a href={banner.logo} target="_blank">
                                                        <img src={banner.logo} alt="" className="" />
                                                    </a>
                                                </div>
                                            </div>
                                            </div>
                                            :
                                            null
                                            }
                                        </div>
                                        
                                    </React.Fragment>
                                ))
                                
                            }
                            <div className="rightBarButton">
                                <Link to="/csda/attendee/technicalSessions">
                                    <span>View All Conference Program</span>
                                    <i className="fa fa-eye"></i>
                                </Link>
                                <a href={process.env.PUBLIC_URL + '/pdf/CSDA-CE-Certificate.pdf'} target="_blank"> <span>CSDA CE Verification Form</span><i className="fa fa-address-card"></i></a>
                            </div>
                        
                        </div>

                        </div>
                        <div className="conferenceSponserLogo">
                            <ul>
                                <li><img src={process.env.PUBLIC_URL + '/images/csda/sponsor.jpg'} alt="" /></li>
                            </ul>
                        </div>
                        
                        
                        <BottomNav />

                    </section>
                    {/*=====About Session Modal=====*/}
                    {
                        this.state.aboutSessionModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSessionModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Session</h3>
                                <div className="midModalAreaContent">
                                    <AboutSession passprops= {this.props}/>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====About Speaker Modal=====*/}
                    {
                        this.state.aboutSpeakerModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.aboutSpeakerModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">About Speaker</h3>
                                <div className="midModalAreaContent">
                                    <AboutSpeaker passprops= {this.props}/>
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Documents Modal=====*/}
                    {
                        this.state.sessionDocumentsModal
                        ?
                        <div className="midModal">
                            <div className="midModalArea">
                                <div className="modalPopUpClose" id="exhibitpopclose" onClick={this.sessionDocumentsModalHandler}><i className="fa fa-times"></i></div>
                                <h3 className="modalHeading pt-3 pb-3">Session Documents</h3>
                                <div className="midModalAreaContent">
                                    <SessionDocuments passprops= {this.props} />
                                    
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }
                    {/*=====Session Survey Modal=====*/}
                    {
                        this.state.sessionSurveyModal
                        ?
                        <div className="midModal verticleCenterModal">
                            <div className="midContentWrp">
                                <div className="modalPopUpClose" onClick={this.sessionSurveyModalHandler}><i className="fa fa-times"></i></div>
                                <h3>Session Survey</h3>
                                <div className="midContentBx">
                                {
                            this.state.iframeLoader
                            ?
                            (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                            )
                            :
                            null
                        }
                                    <iframe width="100%" height="450" src={`https://www.tslwebreg.com/sessionSurvey2.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&role=${localStorage.getItem('currentRole')}`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                                </div>                               
                            </div>
                        </div>
                        :
                        null
                    }
                    {
                this.state.upgradeModal
                    ?
                    <div className="midModal">
                        <div className="midContentWrp">
                            <h3>Upgrade</h3>
                            <div className="midContentBx pt-5 pb-5 midForm">
                                <div className="row">
                                        <div className="offset-md-1 col-md-10">
                                            <h5 className="text-center mb-4">Conference Center access only with Full Conference Registration, Click below to upgrade</h5>
                                            <div className="text-center">
                                                <button className="btn btn-warning" onClick={this.upgradePaymentIframeModelHandeler}> <span>Upgrade</span></button>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                {/*=====Certificate Modal=====*/}
                {
                    this.state.upgradePaymentIframeModel
                    ?
                    <div className="midModal verticleCenterModal">
                        <div className="midContentWrp">
                            <div className="modalPopUpClose" onClick={this.upgradePaymentIframeModelHandeler}><i className="fa fa-times"></i></div>
                            <h3>Ugrade Here</h3>
                            <div className="midContentBx">
                              {
                               this.state.iframeLoader
                               ?
                               (
                                <div className="loaderWrp">
                                    <div className="loaderInr">
                                        <img src={process.env.PUBLIC_URL + '/images/loader.gif'} alt="" />
                                    </div>
                                </div>
                               )
                               :
                               null
                              }
                              
                                <iframe width="100%" height="450" src={`https://www.tslwebreg.com/VCPayments.php?user_id=${localStorage.getItem('accountId')}&event_id=${localStorage.getItem('eventId')}&reg_id=${localStorage.getItem('userId')}&pagetype=ATT`} frameBorder="0" allow="accelerometer;  encrypted-media; gyroscope; picture-in-picture" allowFullScreen autoPlay="false" onLoad={this.hideIframeLoader}></iframe> 
                            </div>                               
                        </div>
                    </div>
                    :
                    null
                }
                    
                </React.Fragment>
            )
        
        
         
    }
}
export default ConferenceCenterPage;
