import React, { Component } from 'react';
import Axios from 'axios';
import Slider from "react-slick";
import ForgotPassword from './ForgotPassword';

import { withRouter} from 'react-router-dom';

var md5 = require('md5');

const initialState = {
    email:"",
    password : "",
    emailError: "",
    passwordError : "",
    invalidDetails : false,
    token : "",
    sponsersImgList : [],
    sponsersUrl:"",
    pageUrlVarify: false,
    pageLoader:true,
    reloadUrl:"",
    formSuccessAlert:false,
    submitLoader : false,
    forgotPasswordModal:false,
    troubleLoggingModal:false,
    watchDemoModal:false,
}



class Login extends Component {
    constructor(props){
        super(props);

        this.state = initialState
        if(localStorage.getItem('exhibitorToken')){
            this.props.history.push(`/ihca-als2021/loginSplash/`)
        }
        
    }



/* form validation function */
validateForm = () => {
    this.setState({submitLoader : true });
    let emailError = "";
    let passwordError = "";

    if(this.state.email !== "undefined"){
      let lastAtPos = this.state.email.lastIndexOf('@');
      let lastDotPos = this.state.email.lastIndexOf('.');
      if (!(lastAtPos < lastDotPos && lastAtPos > 0 && this.state.email.indexOf('@@') === -1 && lastDotPos > 2 && (this.state.email.length - lastDotPos) > 2)) {
        emailError= "Invalid Email"
       }
    } 

    if(!this.state.password){
      passwordError = "Invalid Password"
    }
    if(emailError || passwordError){
      this.setState({emailError : emailError, passwordError : passwordError,submitLoader : false });
      return false;
    }
    
    return true;
  }

 /* onchange function */
  onChange = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }
  troubleLoggingModalHandeler = () => {
      this.setState({troubleLoggingModal:!this.state.troubleLoggingModal})
  }
  watchDemoModalHandeler = () => {
        this.setState({watchDemoModal:!this.state.watchDemoModal})
    }
 /* form submit function */
  logInSubmit = (e) => {
    e.preventDefault();
    
    const validForm = this.validateForm();

    var pathArray = window.location.pathname.split('/');
    localStorage.setItem('eventName',pathArray[1]);
    
    const user = {
      email : this.state.email,
      password : this.state.password
    }
   
    if(validForm){
        Axios.post(`${global.config.requestUrl}user/login`, {
            username: user.email,
            pass: md5(user.password),
            eventId:localStorage.getItem('eventId')
          })
        .then(loginRes => {
            if(loginRes.data.errorCode !== 0){
                this.setState({invalidDetails:true, pageUrlVarify : true, submitLoader:false })
                /* this.location.reload(); */
                /* window.location.reload(false); */
            }
           
            if(loginRes.data.errorCode === 0){
                this.setState({formSuccessAlert:true, invalidDetails:false,});
                localStorage.setItem("exhibitorToken", loginRes.data.token);
                localStorage.setItem("userId", loginRes.data.id);
                localStorage.setItem("accountId", loginRes.data.accountId);
                localStorage.setItem("roles", loginRes.data.roles);
                localStorage.setItem('userName', this.state.email );
                this.loginAnalytics(this.state.email,localStorage.getItem('eventId'));
                setTimeout(() => {
                    this.props.history.push('/ihca-als2021/loginSplash')
                }, 800);
            }
            /* console.log(loginRes.data); */
            /* return loginRes.data; */
          })
          .catch(err => {
            console.log(err);
          });    
    }
  }

  loginAnalytics = (emailId,eventId) =>{
    const loginData = {
        email: emailId,
        eventId: eventId,
      }
      Axios.post(`${global.config.requestUrl}user/saveLoginInfo`, loginData,
          {headers: { "auth-token": localStorage.getItem('exhibitorToken') }}
      ).then(res => {
            
      }).catch(err => {
          console.log(err);
      });
  }

    componentDidMount(){
       
       const {match : {params}} = this.props;
        
       Axios.get(`${global.config.requestUrl}exhibitor?eventId=${params.loginId}&type=1`)
        .then(res => {
            localStorage.setItem('eventId', params.loginId)
            if(res.data.errorCode === 0){
                this.setState({sponsersImgList:res.data.data,  sponsersUrl:res.data.logo, pageUrlVarify : true, pageLoader:false})
                
                
            } else{
                this.setState({sponsersImgList:[
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                    {'logo':process.env.PUBLIC_URL + '/images/sponser-dummy.png','exhibitor_id':null},
                ]});
                this.setState({pageLoader:false})
            }
            
            /* console.log(res.data.data) */
            
            return res.data;
        })
        .catch(err => {
            console.log(err);
        });
        
    }

    /*forgotPassword  Modal Handeler*/
    forgotPasswordModalHandeler = (e) => {
        this.setState({forgotPasswordModal: !this.state.forgotPasswordModal})
    }
   


    render() {
            const settings = {
                dots: false,
                infinite: true,
                speed: 500,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
            };
            return (
                <React.Fragment>
               
                    <section className="bg2 signleScreen overflow-hidden">
                            <div className="loginWrp">
                                <div className="logInForm">
                                    <div className="loginFormBx text-center">
                                       
                                        <div className="tp_logo">
                                            <img src={process.env.PUBLIC_URL + '/images/ihca-als2021/ihca_banner.png'} alt="" />
                                        </div>
                                        <div className="loginContent text-left">
                                            <p>The 2021 Assisted Living Symposium has concluded.</p>
                                            <p>You may access session survey and CE certificate here:</p>
                                            <p><a href="https://www.tslwebreg.com/certificate.php?user_id=69&event_id=706" target="_blank" rel="noreferrer">https://www.tslwebreg.com/certificate.php?user_id=69&event_id=706</a></p>
                                            <p>Contact:</p>
                                            <p><a href="mailto:kniehoff@ihca.org">kniehoff@ihca.org</a></p>
                                        </div>

                                        {/* <h3>Conference Log In </h3>
                                        {
                                            this.state.formSuccessAlert
                                            ?
                                            (
                                                <div className="alert alert-success successBx text-center mb-3 mt-3" role="alert">
                                                    <b>Login Successfull</b>
                                                </div>
                                            )
                                            :
                                            null
                                        }
                                        {
                                            this.state.invalidDetails
                                            ?
                                            <div className="alert alert-danger">Invalid Credentials</div>
                                            :
                                            null
                                        }
                                        <form onSubmit={this.logInSubmit} noValidate autoComplete="off">
                                            <div className="form-group">
                                                <input type="email" name="email" id="emailid" placeholder="Email" onChange={this.onChange} value={this.state.email} autoComplete="off"/>
                                                <div className="errorMessage">{this.state.emailError}</div>
                                            </div>
                                            <div className="form-group">
                                                <input type="password" name="password" id="passwordid" placeholder="Password"  onChange={this.onChange} autoComplete="off"/>
                                                <div className="errorMessage">{this.state.passwordError}</div>
                                            </div>
                                            <div className="form-group text-center">
                                                <button type="submit">
                                                <span>Submit</span>
                                                {
                                                this.state.submitLoader
                                                ?
                                                (
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                )
                                                :
                                                <i className="fa fa-long-arrow-right"></i>
                                                }
                                                    
                                                    
                                                </button>
                                            </div>
                                        </form>
                                        <div className="form-group mb-0 text-center">
                                            <div className="cursor-pointer" onClick={this.forgotPasswordModalHandeler}>
                                                Forgot Password?
                                            </div>
                                            <div className="formLink">
                                                <span onClick={this.troubleLoggingModalHandeler}>Trouble logging in?</span>
                                            </div>
                                            
                                        </div>  */}
                                    </div>
                                </div>
                               
                                <div className="sponsersSlideBx">
                                    <Slider {...settings}>
                                        { 
                                            this.state.sponsersImgList.map(sponsers => (
                                                <div className="sponserBx" key={sponsers.logo}>
                                                <img src={sponsers.logo} alt="" className="" />
                                                </div>
                                            ))
                                            
                                        }
                                    </Slider>  
                                </div>
                            </div>
                            {/*<div className="recBx bottomRec">
                                <h3>Recommended Web Browsers</h3>
                                <ul>
                                    <li><img src={process.env.PUBLIC_URL + '/images/ihca-als2021/chrom_icon.png'} alt="" /> Google Chrome</li>
                                    <li><img src={process.env.PUBLIC_URL + '/images/ihca-als2021/microsoft_edge_ico.png'} alt="" /> Microsoft Edge</li>
                                </ul>
                                <p><b>This website is not optimized for tablets or mobile phones</b></p>
                            </div>
                             <div className="teamLink">
                                <a href="https://www.microsoft.com/en-us/microsoft-365/microsoft-teams/download-app" target="_blank">
                                    <img src={process.env.PUBLIC_URL + '/images/ihca-als2021/microsoft_team.png'} alt="" />
                                    <p>Click here to Download, install, and log in to Microsoft Teams for easy viewing of Live Conference Sessions</p>
                                </a>
                            </div> */}
                            {/*=============forgot-password-modal==============*/}
                            {
                                this.state.forgotPasswordModal 
                                ? 
                                <div className="modalPopUp">
                                    <div className="modalPopUpInr">
                                    <div className="modalPopUpClose" onClick={this.forgotPasswordModalHandeler}>
                                        <i className="fa fa-times"></i>
                                    </div>
                                
                                        <ForgotPassword />
                                    </div>
                                </div> 
                                : null
                            }
                            {/*=============trouble-logging-modal==============*/}
                            {
                                this.state.troubleLoggingModal 
                                ? 
                                <div className="modalPopUp">
                                    <div className="modalPopUpInr width1100">
                                        <div className="modalPopUpClose" onClick={this.troubleLoggingModalHandeler}>
                                            <i className="fa fa-times"></i>
                                        </div>
                                        <div className="modalVideoWrapper">
                                            <iframe width="560" height="315" title="trouble login" src="https://www.youtube.com/embed/EMQbcBChNI4?rel=0&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </div>
                                        
                                    </div>
                                </div> 
                                : null
                            }
                            {/*=============watch-demo-modal==============*/}
                            {
                                this.state.watchDemoModal 
                                ? 
                                <div className="modalPopUp">
                                    <div className="modalPopUpInr width1100">
                                        <div className="modalPopUpClose" onClick={this.watchDemoModalHandeler}>
                                            <i className="fa fa-times"></i>
                                        </div>
                                        <div className="modalVideoWrapper">
                                            <iframe width="560" height="315" title="watch demo" src="https://www.youtube.com/embed/NHXbeThb4Gw?rel=0&autoplay=1" frameborder="0" allow="microphone; camera; accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                        
                                    </div>
                                </div> 
                                : null
                            }
                    </section>

                </React.Fragment>
            )
         
        
         
    }
}
export default withRouter(Login);
