const utility=function () {

};
utility.generateUserUid=function (userDetails) {
       userDetails.role=userDetails.role.toString();
    console.log(userDetails,"generateUserId",userDetails.role.split(",")[0]);

    return userDetails.userId+'-r-'+userDetails.role.split(",")[0].replace("-",'');
};
utility.generateMeetingLink=(meetingDetails)=>{
    return window.location.origin+process.env.PUBLIC_URL +"/conference-meeting/"+meetingDetails.room_id+"?pwd="+meetingDetails.password
}
utility.getUserRole=function (uid) {

    let role= uid.split("-")[2];
    role=parseInt(role);
    if(role===1){
        role='-1';
    }else if(role===6){
        role='-6';
    }else if(role===10){
        role='-10';
    }
    return role;
};
utility.getUserIdAndRole=function (uid) {
    uid= uid.split("-");
    let role=uid[2];
    let userId=uid[0];
    role=parseInt(role);
    if(role===1){
        role='-1';
    }else if(role===6){
        role='-6';
    }else if(role===7){
        role='-7';
    }else if(role===10){
        role='-10';
    }

    return {'userId':userId,'role':role};
};
utility.getFileAttachmentIcon = function(fileExt){

    let fileTypeWord = ["doc","docx"];
    let fileTypeExcel = ["xls","xlsx"];
    let fileTypePdf = ["pdf"];
    let fileTypeText = ["txt"];
    let fileTypeZip = ["zip"];
    let fileTypeRar = ["rar"];
    let fileTypeAac = ["aac"];
    let fileTypeAvi = ["avi"];
    let fileTypeCad = ["cad"];
    let fileTypeCss = ["css"];
    let fileTypeDat = ["dat"];
    let fileTypeDll = ["dll"];
    let fileTypeDmg = ["dmg"];
    let fileTypeEps = ["eps"];
    let fileTypeFla = ["fla"];
    let fileTypeFlv = ["flv"];
    let fileTypeHtml = ["html"];
    let fileTypeIso = ["iso"];
    let fileTypeJs = ["js"];
    let fileTypeMidi = ["midi"];
    let fileTypeMov = ["mov"];
    let fileTypeMp3 = ["mp3"];
    let fileTypeMp4 = ["mp4"];
    let fileTypeMpg = ["mpg","mpeg"];
    let fileTypePhp = ["php"];
    let fileTypePpt = ["ppt","pptx"];
    let fileTypePs = ["ps"];
    let fileTypePsd = ["psd"];
    let fileTypeSql = ["sql"];
    let fileTypeWmv = ["wmv"];
    let fileTypeXml = ["xml"];

    let attachmentPath = '';

    if(fileTypeWord.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/doc.svg";
    }else if(fileTypeExcel.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/xls.svg";
    }else if(fileTypePdf.indexOf(fileExt,) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/pdf.svg";
    }else if(fileTypeRar.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/rar.svg";
    }else if(fileTypeZip.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/zip.svg";
    }else if(fileTypeText.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/txt.svg";
    }else if(fileTypeAac.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/aac.svg";
    }else if(fileTypeAvi.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/avi.svg";
    }else if(fileTypeCad.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/cad.svg";
    }else if(fileTypeCss.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/css.svg";
    }else if(fileTypeDat.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/dat.svg";
    }else if(fileTypeDll.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/dll.svg";
    }else if(fileTypeDmg.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/dmg.svg";
    }else if(fileTypeEps.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/eps.svg";
    }else if(fileTypeFla.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/fla.svg";
    }else if(fileTypeFlv.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/flv.svg";
    }else if(fileTypeHtml.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/html.svg";
    }else if(fileTypeIso.indexOf(fileExt) !== -1){
        attachmentPath = "/images/tmChat/attachment_icons/iso.svg";
    }else if(fileTypeJs.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/js.svg";
    }else if(fileTypeMidi.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/midi.svg";
    }else if(fileTypeMov.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/mov.svg";
    }else if(fileTypeMp3.indexOf(fileExt,) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/mp3.svg";
    }else if(fileTypeMp4.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/mp4.svg";
    }else if(fileTypeMpg.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/mpg.svg";
    }else if(fileTypePhp.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/php.svg";
    }else if(fileTypePpt.indexOf(fileExt)!==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/ppt.svg";
    }else if(fileTypePs.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/ps.svg";
    }else if(fileTypePsd.indexOf(fileExt,) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/psd.svg";
    }else if(fileTypeSql.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/sql.svg";
    }else if(fileTypeWmv.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/wmv.svg";
    }else if(fileTypeXml.indexOf(fileExt) !==  -1){
        attachmentPath = "/images/tmChat/attachment_icons/xml.svg";
    }else{
        attachmentPath = "/images/tmChat/attachment_icons/other.svg";
    }
    return attachmentPath;
};
utility.getUserId=function (uid) {
    uid= uid.split("-");

  return uid[0];
};
utility.getFileName = function(path){
    var name = path.split("/");
    name = name[name.length - 1];
    return name;
};
utility.getTrimmedAttachmentNameString = function(filename){
    try{
        var maxFileLength = 26;
        if(filename.length  <= maxFileLength){
            return filename;
        }else{

            var fileSplit = filename.split(".");
            var ext = "."+fileSplit[fileSplit.length - 1];

            var fileWithoutExtension = "";
            for(var i = 0;i < (fileSplit.length - 1);i++){
                fileWithoutExtension += fileSplit[i];
            }
            fileWithoutExtension += ext;
            return fileWithoutExtension
        }
    }catch (e){
        return filename;
    }
};
utility.encodeBase64 = (input) => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars;
         str.charAt(i | 0) || (map = '=', i % 1);
         output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

        charCode = str.charCodeAt(i += 3 / 4);

        if (charCode > 0xFF) {
            console.log("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
        }

        block = block << 8 | charCode;
    }
    return output;
}
utility.decodeBase64 = function (s) {
    let e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
    let A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for (i = 0; i < 64; i++) {
        e[A.charAt(i)] = i;
    }
    for (x = 0; x < L; x++) {
        c = e[s.charAt(x)];
        b = (b << 6) + c;
        l += 6;
        while (l >= 8) {
            ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a));
        }
    }
    return r;
};
utility.addGroupToList=function (data){
    let groupData={ conversation_reference_id: "556",
    created_at: "2020-09-04 20:34:04",
    entity_type: 2,
    group_created_at: "2020-09-04 20:34:04",
    group_created_by: 25,
    id: 818,
    is_delivered: 0,
    is_read: 0,
    member_status: 1,
    message: "",
    message_status: 1,
    message_type: 4,
    receiver_id: 34,
    receiver_name: "testttt",
    receiver_profile_pic: "https://firebasestorage.googleapis.com/v0/b/tslvirtual.appspot.com/o/f2ee668f-59d1-44c2-ac0e-6cfa6bd8e677.jpg?alt=media&token=1f3bb15b-a970-403b-b2be-f63a94604ac2",
    reference_id: "",
    sender_id: 25,
    sender_name: "lokssuyu55 varhny",
    uid: "10106-r-1",
    unread_messages: 0}
}
utility.acronym=function (str) {
    str=str.split("@");
    str=str[0];
    let words = (str || "").replace(/[\(\),\|\.\+\-&]/g, " ").split(/[\s\.]/);
    let acronym =
        words.length > 1
            ? words.reduce((response, word) => (response += word.slice(0, 1)), "")
            : words[0].slice(0, 2);
    return acronym.toUpperCase();
}
utility.popupWindow=(url, title, w, h) => {
    let height = window.height;
    let width = window.width;
    let left, top;

    if (width > 1050) {
        width = width - 200;
    } else {
        width = 850;
    }

    if (height > 850) {
        height = height - 150;
    } else {
        height = 700;
    }

    if (window.screenX < 0) {
        left = (window.screenX - width) / 2;
    } else {
        left = (window.width - width) / 2;
    }

    if (window.screenY < 0) {
        top = (window.screenY + height) / 4;
    } else {
        top = (window.height - height) / 4;
    }

    return window.open( url,"myTarget", "title="+title+"width="+width+", height="+height+",left="+left+",top="+top+"menubar=no, status=no, location=no, resizable=yes, scrollbars=yes");

}
utility.formatBytes=function (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
utility.copyToClipboard=function (text) {
    let selected = false;
    let el = document.createElement('textarea');
    el.value = text;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    if (document.getSelection().rangeCount > 0) {
        selected = document.getSelection().getRangeAt(0)
    }
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
    }
};

export default utility