import React, { Component, useState } from "react";
import Axios from "axios";
import { Link } from "react-router-dom";
import SessionListGrid from "./TechnicalSessions/SessionListGrid";
import ChatIcon from '../../../TmChat/security22/chat/ChatIcon';
import LoungeChat from "../../../TmChat/security22/LoungeChat/loungeChat";

class TechnicalSessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageLoader: true,
      sessionTitle: "Next Session will begin in a moment",
      sessionFound:false,
      sessionEndStamp: "",
      meetingURL:""
    };
  }

  componentDidMount(){
    if(!localStorage.getItem('exhibitorToken')){
      localStorage.setItem('singleSignOn', "0" );
    }
    this.setIInterval()
  }


  setIInterval = () => {
    setInterval(() => {
      //const str = new Date().toLocaleString('en-US', { timeZone: 'America/Matamoros' });
      
      //console.log(new Date(str).getTime() / 1000);
      this.getStartedSession()
    }, 3000);
  }


  getStartedSession = () => {
    const postdata = {
      eventId: localStorage.getItem("eventId"),
      searchByTrack: "",
      searchByName: "",
      sortByDate: "",
      videoUrl:""
    };

    Axios.post(
      `${global.config.requestUrl}getTechnicalSessions`,
      postdata
    )
      .then((res) => {
        if (!res.data.errorCode) {          
          //var currentTimeStamp = new Date('03/29/2022 08:05 AM').getTime() / 1000
          const str = new Date().toLocaleString('en-US', { timeZone: 'America/Matamoros' });
          var currentTimeStamp = new Date(str).getTime() / 1000;
          //var currentTimeStamp = Math.floor(Date.now()/1000);

            res.data.data.forEach((element) => {
              // var stimeRaw = new Date(element.start_date + ' ' + element.start_time).toLocaleString('en-US', { timeZone: 'America/Matamoros' });
              // var startTimeStamp = new Date(stimeRaw).getTime() / 1000;
              // var etimeRaw = new Date(element.start_date + ' ' + element.end_time).toLocaleString('en-US', { timeZone: 'America/Matamoros' });
              // var endTimeStamp = new Date(etimeRaw).getTime() / 1000;
              var startTimeStamp = new Date(element.start_date + ' ' + element.start_time).getTime() / 1000;
              var endTimeStamp = new Date(element.start_date + ' ' + element.end_time).getTime() / 1000;

              //console.log('Start Time :-' + startTimeStamp)
              //console.log('Current Time :-' + currentTimeStamp)
              //console.log('End Time :-' + endTimeStamp)
              if(currentTimeStamp >= startTimeStamp && currentTimeStamp <= endTimeStamp && this.state.sessionFound == false){
                console.log(element.session_name + ' Started')
                this.setState({sessionTitle:element.video_title,meetingURL:element.video_url, sessionEndStamp: endTimeStamp,sessionFound:true });           
              }
            });
            if(currentTimeStamp > this.state.sessionEndStamp && this.state.sessionEndStamp != ""){
              console.log('Session Ends')
              this.setState({sessionTitle:"Next Session will begin in a moment", sessionEndStamp: "",sessionFound:false,meetingURL:"" }); 
            }
        }else{
          //updateData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  render() {
    
    return (
      <React.Fragment>
        <section className="sessionScreen signleScreen">
          <div
            className="loaderWrp"
            id="fordatatable"
            style={{ display: "none" }}
          >
            <div className="loaderInr">
              <img src={process.env.PUBLIC_URL + "/images/loader.gif"} alt="" />
            </div>
          </div>




          {/* <div class="container">
            <div class="row">
              <div class="col-6">
              <div className="sessions-security-logo"><img src={process.env.PUBLIC_URL + "/images/security22/Security22_Reg_Logo.png"} /></div>
              </div>
              <div class="col-6">
              <p className="text-center" style={{color: '#fff', fontWeight: 'bold', borderBottom: '1px solid #fff', paddingBottom: '5px'}}>{this.state.sessionTitle}</p>
              <div
               className="conferenceVideo zoomBanner onestream"
               id="videoconf"
              >
                <iframe width="70%" height="400px" style={{overflow: "hidden"}} src={this.state.videoUrl} key={this.state.videoUrl} scrolling="no" frameborder="0" allow="autoplay" allowFullScreen></iframe>
              </div>
              </div>
            </div>
          </div> */}


          <div className="midTable row pl-2" style={{paddingTop:'130px'}}>
            {/* <div class="col-sm-5 text-center">
              <img src={process.env.PUBLIC_URL + "/images/security22/Security22_Reg_Logo.png"} />
            </div> */}
            <div className="col-sm-5">
              <div className="midTab">
                <div className="chatLoungeBx">
                <LoungeChat/>
                </div>
                </div>
            </div>
            <div className="col-sm-7">
            <p className="text-center" style={{color: '#fff', fontWeight: 'bold', borderBottom: '1px solid #fff', paddingBottom: '5px'}}>{this.state.sessionTitle}</p>
            <div className="conferenceVideo zoomBanner onestream" id="videoconf">
            {
              this.state.meetingURL
              ?
              <iframe width="100%" height="400px" style={{overflow: "hidden"}} src={this.state.meetingURL} key={this.state.meetingURL} scrolling="no" frameborder="0" allow="autoplay" allowFullScreen></iframe>
              :
              <img width="50%" src={process.env.PUBLIC_URL + "/images/security22/Security22_Reg_Logo.png"} />
            }
            
            </div>
            </div>
          </div>          
          <div className="midTable">
            <div className="row p-2">
            <div className="midTableWrp col-sm-12">
              <SessionListGrid />
            </div>

            
            </div>
            
            
          </div>
          {/* <div className="chatIco">
              <ChatIcon chatIconImage={process.env.PUBLIC_URL + '/images/chat-ico2.png'} />
          </div> */}
        </section>
      </React.Fragment>
    );
  }
}
export default TechnicalSessions;
